import React, { useContext, useState } from "react";
import PageTitle from "../../components/PageTitle";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button, ListGroup, ListGroupItem, ProgressBar } from "react-bootstrap";
import { useEffect } from "react";
import Api from "../../api/api";
import { FilialContext } from "../../context/FilialContext";
import { toast } from "react-toastify";
import EditModal from "./EditModal";
import LevelConfig from "./LevelConfig";
import { PanelPoints } from "./PanelPoints";
import { AuthContext } from "../../context/AuthContext";
import { PointsProgramData } from "./PointsProgramData";

const PointsPrograms = () => {
  const { selectedUnitId } = useContext(FilialContext);
  const { permissionGroup } = useContext(AuthContext);
  const isAdmin = permissionGroup === "Administrador";
  const [list, setList] = useState([]);
  const [load, setLoad] = useState(false);
  const [selectedID, setSelectedID] = useState();
  const [openLevelConfig, setOpenLevelConfig] = useState(false);
  const [openPanelPoints, setOpenPanelPoints] = useState(false);

  const getList = () => {
    setLoad(true);
    Api.get(`points_programs`, { params: { unit_id: selectedUnitId } })
      .then((res) => {
        setList(res.data.list);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => setLoad(false));
  };

  useEffect(() => {
    if (selectedUnitId) getList();
  }, [selectedUnitId]);

  const handleOpenLevelConfig = () => {
    setOpenLevelConfig(true);
  };

  useEffect(() => {
    document.getElementById("topbar")?.scrollIntoView();
  }, []);

  return (
    <>
      <PageTitle>
        <h2>Programas de Pontos</h2>
        <div className="d-flex gap-3">
          <Button variant="primary" onClick={() => setOpenPanelPoints(true)}>
            Painel de Pontos
          </Button>
          {isAdmin && (
            <ListGroup>
              {list.map((item) => (
                <ListGroupItem
                  key={`point_program_${item.id}`}
                  action
                  active={item.id === selectedID}
                  variant="primary"
                  onClick={() => setSelectedID(item.id)}
                  className="d-flex justify-content-between align-items-center"
                >
                  Configurações
                </ListGroupItem>
              ))}
            </ListGroup>
          )}
        </div>
      </PageTitle>
      {selectedID && (
        <EditModal
          open={selectedID ? true : false}
          setOpen={setSelectedID}
          id={selectedID}
          getList={getList}
        />
      )}
      {openLevelConfig && (
        <LevelConfig open={openLevelConfig} setOpen={setOpenLevelConfig} />
      )}
      {openPanelPoints && (
        <PanelPoints open={openPanelPoints} setOpen={setOpenPanelPoints} />
      )}
      {isAdmin && (
        <Container fluid>
          <PointsProgramData />
        </Container>
      )}
    </>
  );
};

export default PointsPrograms;
