import { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  InputGroup,
  Row,
  Spinner,
  Card,
} from "react-bootstrap";
import PageTitle from "../../components/PageTitle";
import { FilialContext } from "../../context/FilialContext";
import PopCard from "../Home2/Card";
import moment from "moment";
import Api from "../../api/api";
import { toast } from "react-toastify";
import CSVModal from "../Home2/CSVModal";
import colors from "../../vars.scss";
import SalesList from "../../components/SalesList";
import renamePaymentType from "../../utils/paymentType";

const initReport = {
  day_total: 0,
  week_total: 0,
  month_total: 0,
  year_total: 0,
  local_total: 0,
  online_total: 0,
  total: 0,
};

const UsageReport = () => {
  const { selectedUnitId } = useContext(FilialContext);
  const [filter, setFilter] = useState({
    start_date: moment().format("YYYY-MM-DD"),
    end_date: "",
  });
  const [loadingCSV, setLoadingCSV] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);
  const [loadingValuesByPaymentMethod, setLoadingValuesByPaymentMethod] =
    useState(false);
  const [report, setReport] = useState({ ...initReport });
  const [valuesByPaymentMethod, setValuesByPaymentMethod] = useState([]);
  const [csvHeaders, setCSVHeaders] = useState(null);
  const [allUnitiesData, setAllUnitiesData] = useState(false);

  const handleFilterChange = (e) => {
    setFilter((prev) => ({
      ...prev,
      [e.target.name]: moment(e.target.value).isValid()
        ? moment(e.target.value).format("YYYY-MM-DD")
        : "",
    }));
  };

  const handleAllUnitiesDataChange = (e) => {
    setAllUnitiesData(e.target.checked ? true : false);
  };

  const handleDownloadCSV = () => {
    setLoadingCSV(true);

    Api.get(`orders/csv_headers`)
      .then((res) => {
        setCSVHeaders(res.data);
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingCSV(false));
  };

  const getReport = () => {
    setLoadingReport(true);
    Api.get(`usage/report`, {
      params: { ...filter, unit_id: allUnitiesData ? null : selectedUnitId },
    })
      .then((res) => setReport(res.data))
      .catch((err) => {
        toast.error(err.message);
        setReport({ ...initReport });
      })
      .finally(() => setLoadingReport(false));
  };

  const getValuesByPaymentMethod = () => {
    setLoadingValuesByPaymentMethod(true);
    Api.get(`usage/report/payment_method`, {
      params: { ...filter, unit_id: allUnitiesData ? null : selectedUnitId },
    })
      .then((res) => {
        const funPassDiscount =
          (res.data.find((i) => i.payment_method === "funpass")?.total || 0) *
          0.1;

        const total = res.data.find((i) => i.payment_method === "total");
        total.total -= funPassDiscount;

        setValuesByPaymentMethod(res.data);
      })
      .catch((err) => {
        toast.error(err.message);
        setValuesByPaymentMethod([]);
      })
      .finally(() => setLoadingValuesByPaymentMethod(false));
  };

  useEffect(() => {
    if (selectedUnitId) {
      getReport();
      getValuesByPaymentMethod();
    }
  }, [filter, selectedUnitId, allUnitiesData]);

  const handleCloseCSVModal = () => {
    setCSVHeaders(null);
  };

  useEffect(() => {
    document.getElementById("topbar")?.scrollIntoView();
  }, []);

  const renderValuesByPaymentMethod = () => {
    if (!valuesByPaymentMethod || !valuesByPaymentMethod[0]) return;

    return valuesByPaymentMethod.map((item) => {
      return (
        <Col
          xs={12}
          md={6}
          lg={3}
          key={`payment_method_item_${item.payment_method}`}
        >
          <Card className="border-0 mb-1">
            <Card.Header className="bg-info border-0 fs-5 text-white">
              {renamePaymentType(item.payment_method)}
            </Card.Header>
            <Card.Body className="text-center">
              <Card.Text className="h2">
                {Number(
                  (item.payment_method === "funpass"
                    ? item.total * 0.9
                    : item.total) || 0
                ).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
                {item.payment_method === "funpass" ? "*" : ""}
              </Card.Text>
              {item.payment_method === "funpass" && (
                <Card.Text
                  className="position-absolute text-muted"
                  style={{ right: 8, bottom: 4 }}
                >
                  Total Real
                  <br />
                  {Number(item.total || 0).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </Card.Text>
              )}
            </Card.Body>
          </Card>
          {item.payment_method === "funpass" && (
            <Card.Text
              className="text-muted m-auto"
              style={{ maxWidth: "90%", fontSize: 12 }}
            >
              * Valor a ser recebido, 90% do valor total vendido neste método.
            </Card.Text>
          )}
        </Col>
      );
    });
  };

  const legendComponent = () => {
    return <span className="text-muted">Confirmados / Total</span>;
  };

  return (
    <>
      {csvHeaders && (
        <CSVModal
          handleClose={handleCloseCSVModal}
          headers={csvHeaders}
          filter={{
            ticket_date_start: filter.start_date,
            ticket_date_end: filter.end_date,
            unit_id: selectedUnitId,
          }}
        />
      )}
      <PageTitle className="d-flex flex-column flex-md-row align-items-center justify-content-center">
        <h2 className="text-center">Consolidado de Uso</h2>
        {(loadingReport || loadingValuesByPaymentMethod) && <Spinner />}
        <div className="d-flex flex-column align-items-end gap-2">
          <div
            className="d-flex align-itens-center justify-content-center"
            style={{ gap: 15 }}
          >
            <div>
              <label className="ml-2 mb-0 color-gray" htmlFor="">
                Data inicial
              </label>
              <input
                type="date"
                name="start_date"
                id=""
                className="form-control"
                style={{ width: 160 }}
                value={filter.start_date}
                onChange={handleFilterChange}
              />
            </div>
            <div>
              <label className="ml-2 mb-0" htmlFor="">
                Data final
              </label>
              <input
                type="date"
                name="end_date"
                id=""
                className="form-control"
                style={{ width: 160 }}
                value={filter.end_date}
                onChange={handleFilterChange}
              />
            </div>
          </div>
          <div className="d-flex gap-3">
            <label className="form-label">
              Mostrar dados de todas as unidades
            </label>
            <Form.Check
              checked={allUnitiesData}
              onChange={handleAllUnitiesDataChange}
              className="ms-2"
            />
          </div>
        </div>
      </PageTitle>
      <Row className="g-4">
        <Col xs={12} md={6} lg={4}>
          <Card className="border-0">
            <Card.Header className="bg-info border-0 fs-5 text-white">
              Vendas no Website
            </Card.Header>
            <Card.Body className="text-center">
              <Card.Text className="h2">
                {`${Number(report.confirmed_online_sales || 0)} / ${Number(
                  report.online_sales || 0
                )}`}
              </Card.Text>
              {legendComponent()}
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={6} lg={4}>
          <Card className="border-0">
            <Card.Header className="bg-info border-0 fs-5 text-white">
              Vendas de Festas
            </Card.Header>
            <Card.Body className="text-center">
              <Card.Text className="h2">
                {`${Number(report.confirmed_party_sales || 0)} / ${Number(
                  report.party_sales || 0
                )}`}
              </Card.Text>
              {legendComponent()}
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={6} lg={4}>
          <Card className="border-0">
            <Card.Header className="bg-info border-info fs-5 text-white">
              Vendas no Caixa
            </Card.Header>
            <Card.Body className="text-center">
              <Card.Text className="h2">
                {`${Number(report.confirmed_local_sales || 0)} / ${Number(
                  report.local_sales || 0
                )}`}
              </Card.Text>
              {legendComponent()}
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={6} lg={4}>
          <Card className="border-0">
            <Card.Header className="bg-primary border-primary fs-5 text-white">
              {`Quantos irão brincar`}
            </Card.Header>
            <Card.Body className="text-center">
              <Card.Text className="h2">
                {`${Number(report.confirmed_total_players || 0)} / ${Number(
                  report.total_players || 0
                )}`}
              </Card.Text>
              {legendComponent()}
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={6} lg={4}>
          <Card className="border-0">
            <Card.Header className="bg-primary border-primary fs-5 text-white">
              {`Quantos não irão brincar`}
            </Card.Header>
            <Card.Body className="text-center">
              <Card.Text className="h2">
                {`${Number(report.confirmed_total_not_players || 0)} / ${Number(
                  report.total_not_players || 0
                )}`}
              </Card.Text>
              {legendComponent()}
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={6} lg={4}>
          <Card className="border-0">
            <Card.Header className="bg-primary border-primary fs-5 text-white">
              {`Total`}
            </Card.Header>
            <Card.Body className="text-center">
              <Card.Text className="h2">
                {`${Number(report.confirmed_participants || 0)} / ${Number(
                  report.participants || 0
                )}`}
              </Card.Text>
              {legendComponent()}
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={6} lg={3}>
          <Card className="border-0">
            <Card.Header className="bg-tertiary border-tertiary fs-5 text-white">
              {`Brincantes`}
            </Card.Header>
            <Card.Body className="text-center">
              <Card.Text className="h2">
                {`${Number(report.confirmed_players || 0)} / ${Number(
                  report.players || 0
                )}`}
              </Card.Text>
              {legendComponent()}
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={6} lg={3}>
          <Card className="border-0">
            <Card.Header className="bg-tertiary border-tertiary fs-5 text-white">
              {`Não Brincantes`}
            </Card.Header>
            <Card.Body className="text-center">
              <Card.Text className="h2">
                {`${Number(report.confirmed_not_players || 0)} / ${Number(
                  report.not_players || 0
                )}`}
              </Card.Text>
              {legendComponent()}
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={6} lg={3}>
          <Card className="border-0">
            <Card.Header className="bg-tertiary border-tertiary fs-5 text-white">
              {`Menores de 5 Anos`}
            </Card.Header>
            <Card.Body className="text-center">
              <Card.Text className="h2">
                {`${Number(report.confirmed_minors || 0)} / ${Number(
                  report.minors || 0
                )}`}
              </Card.Text>
              {legendComponent()}
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={6} lg={3}>
          <Card className="border-0">
            <Card.Header className="bg-tertiary border-tertiary fs-5 text-white">
              {`Acomp menores de 5 Anos`}
            </Card.Header>
            <Card.Body className="text-center">
              <Card.Text className="h2">
                {`${Number(
                  report.confirmed_minors_responsibles || 0
                )} / ${Number(report.minors_responsibles || 0)}`}
              </Card.Text>
              {legendComponent()}
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={6} lg={3}>
          <Card className="border-0">
            <Card.Header className="bg-primary border-primary fs-5 text-white">
              {`Cortesias Brincantes`}
            </Card.Header>
            <Card.Body className="text-center">
              <Card.Text className="h2">
                {`${Number(report.confirmed_courtesy_players || 0)} / ${Number(
                  report.courtesy_players || 0
                )}`}
              </Card.Text>
              {legendComponent()}
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={6} lg={3}>
          <Card className="border-0">
            <Card.Header className="bg-primary border-primary fs-5 text-white">
              {`Cortesias Não Brincantes`}
            </Card.Header>
            <Card.Body className="text-center">
              <Card.Text className="h2">
                {`${Number(
                  report.confirmed_courtesy_not_players || 0
                )} / ${Number(report.courtesy_not_players || 0)}`}
              </Card.Text>
              {legendComponent()}
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={6} lg={3}>
          <Card className="border-0">
            <Card.Header className="bg-primary border-primary fs-5 text-white">
              {`PCDs`}
            </Card.Header>
            <Card.Body className="text-center">
              <Card.Text className="h2">
                {`${Number(report.confirmed_dps || 0)} / ${Number(
                  report.dps || 0
                )}`}
              </Card.Text>
              {legendComponent()}
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={6} lg={3}>
          <Card className="border-0">
            <Card.Header className="bg-primary border-primary fs-5 text-white">
              {`Acomp dos PCDs`}
            </Card.Header>
            <Card.Body className="text-center">
              <Card.Text className="h2">
                {`${Number(report.confirmed_dps_responsibles || 0)} / ${Number(
                  report.dps_responsibles || 0
                )}`}
              </Card.Text>
              {legendComponent()}
            </Card.Body>
          </Card>
        </Col>
        {renderValuesByPaymentMethod()}
        <Col xs={12}>
          <Card>
            <Card.Body>
              <Card.Title className="mb-4">
                <h5 className="fw-bold">Consolidado de uso:</h5>
              </Card.Title>
              <SalesList
                ticketStartDate={filter.start_date}
                ticketEndDate={filter.end_date}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12}>
          <div className="d-flex align-items-center justify-content-end h-100">
            <Button
              className="btn-lg"
              disabled={loadingCSV}
              onClick={handleDownloadCSV}
            >
              Baixar CSV
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default UsageReport;
