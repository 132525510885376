import { useContext, useEffect, useState } from "react";
import {
  Button,
  Form,
  FormControl,
  FormGroup,
  InputGroup,
  Modal,
} from "react-bootstrap";
import { toast } from "react-toastify";
import Api from "../../../../api/api";
import Select from "react-select";
import { AuthContext } from "../../../../context/AuthContext";
import moment from "moment";
import {
  getPaymentMethods,
  paymentMethods,
} from "../../../../utils/paymentType";

const selectStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: "1rem",
    borderBottomRightRadius: "1rem",
  }),
  container: (baseStyles, state) => ({
    ...baseStyles,
    alignSelf: "stretch",
    flex: 1,
  }),
};

const paymentStatuses = [
  { value: "pending", label: "Aguardando pagamento" },
  { value: "authorized", label: "Pagamento confirmado" },
];

const AddTransactionModal = ({
  open,
  setOpen,
  onSuccess,
  order_id,
  transaction_id,
}) => {
  const { dashboardId } = useContext(AuthContext);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingVoid, setLoadingVoid] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);

  const loadingLocked =
    loadingData || loadingSave || loadingVoid || loadingRemove;

  const [values, setValues] = useState({
    responsible_id: dashboardId,
    order_id,
    payment_method: "",
    payment_status: "",
    value: 0,
    authorized_at: "",
  });

  const getData = () => {
    setLoadingData(true);
    Api.get(`transactions/${transaction_id}`)
      .then((res) => setValues({ ...res.data, responsible_id: dashboardId }))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingData(false));
  };

  useEffect(() => {
    if (open && transaction_id > 0) getData();
  }, [transaction_id, open]);

  const handleClose = () => {
    if (loadingLocked) return;
    setOpen(false);
  };

  const handleChange = (e) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSelectChange = (newVal, field) => {
    setValues((prev) => ({ ...prev, [field]: newVal }));
  };

  const isAnExistingAuthorizedLocalTransaction =
    !values.transaction_id &&
    values.id > 0 &&
    values.payment_status === "authorized";

  const renderTransactionInputs = () => {
    return (
      <div className="d-flex flex-column gap-1">
        <FormGroup>
          <Form.Label>Valor</Form.Label>
          <FormControl
            value={values.value}
            min={0}
            type={"number"}
            onChange={handleChange}
            name="value"
          />
        </FormGroup>
        <FormGroup className="mt-2">
          <Form.Label className="rounded-box">Forma de Pagamento</Form.Label>
          <Select
            classNamePrefix="react-select"
            value={getPaymentMethods("local", "parties").find(
              (item) => item.value === values.payment_method
            )}
            placeholder="Clique aqui para selecionar"
            onChange={(item) =>
              handleSelectChange(item.value, "payment_method")
            }
            options={getPaymentMethods("local", "parties")}
            styles={selectStyles}
          />
        </FormGroup>
        <FormGroup className="mt-2">
          <Form.Label className="rounded-box">Status de Pagamento</Form.Label>
          <Select
            classNamePrefix="react-select"
            value={[...paymentStatuses].find(
              (item) => item.value === values.payment_status
            )}
            placeholder="Clique aqui para selecionar"
            onChange={(item) =>
              handleSelectChange(item.value, "payment_status")
            }
            options={[...paymentStatuses]}
            styles={selectStyles}
          />
        </FormGroup>
        {isAnExistingAuthorizedLocalTransaction && (
          <FormGroup className="mt-2">
            <Form.Label className="rounded-box">Data de Pagamento</Form.Label>
            <FormControl
              value={moment(values.authorized_at).format("YYYY-MM-DDTHH:mm")}
              onChange={handleChange}
              type={"datetime-local"}
              name={"authorized_at"}
            />
          </FormGroup>
        )}
      </div>
    );
  };

  const handleAddTransactionToOrder = () => {
    setLoadingSave(true);
    if (transaction_id > 0)
      return Api.put(`/transactions/party/${transaction_id}`, values)
        .then(() => {
          if (onSuccess) onSuccess();
          toast.success("Pagamento atualizado com sucesso!");
        })
        .catch((err) => toast.error(err.message))
        .finally(() => setLoadingSave(false));
    Api.post(`/transactions/party`, values)
      .then(() => {
        if (onSuccess) onSuccess();
        toast.success("Pagamento lançado com sucesso!");
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingSave(false));
  };

  const handleVoidTransaction = () => {
    if (window.confirm("Deseja realmente estornar este pagamento?")) {
      setLoadingVoid(true);
      Api.post(`/transactions/void/${transaction_id}`)
        .then(() => {
          if (onSuccess) onSuccess();
          toast.success("Pagamento estornado com sucesso!");
        })
        .catch((err) => toast.error(err.message))
        .finally(() => setLoadingVoid(false));
    }
  };

  const handleRemoveTransaction = () => {
    if (window.confirm("Deseja realmente remover este pagamento?")) {
      setLoadingRemove(true);
      Api.post(`/transactions/remove/${transaction_id}`)
        .then(() => {
          if (onSuccess) onSuccess();
          toast.success("Pagamento removido com sucesso!");
        })
        .catch((err) => toast.error(err.message))
        .finally(() => setLoadingRemove(false));
    }
  };

  return (
    <Modal show={open} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Lançar pagamento à venda</Modal.Title>
      </Modal.Header>
      <Modal.Body>{renderTransactionInputs()}</Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose} disabled={loadingLocked}>
          Cancelar
        </Button>
        {transaction_id > 0 && values.payment_status === "authorized" && (
          <Button
            onClick={handleVoidTransaction}
            variant="outline-danger"
            disabled={loadingLocked}
          >
            Estornar
          </Button>
        )}
        {transaction_id > 0 && (
          <Button
            onClick={handleRemoveTransaction}
            variant="danger"
            disabled={loadingLocked}
          >
            Remover
          </Button>
        )}
        <Button onClick={handleAddTransactionToOrder} disabled={loadingLocked}>
          {`${transaction_id > 0 ? "Atualizar" : "Confirmar"}`}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddTransactionModal;
