import { useEffect, useState } from "react";
import Api from "../../api/api";
import { toast } from "react-toastify";
import { Button, Form, InputGroup } from "react-bootstrap";

const SaleCoupon = ({ data, onChange }) => {
  const [code, setCode] = useState("");
  const [coupon, setCoupon] = useState(null);
  const [loadingCoupon, setLoadingCoupon] = useState(false);

  const getCouponByCode = () => {
    setLoadingCoupon(true);
    Api.post(`coupons/use`, { ...data, code })
      .then((res) => {
        toast.success("Cupom aplicado com sucesso!");
        setCoupon(res.data.data);
      })
      .catch((err) => {
        toast.error(err.message);
        if (onChange) onChange(null);
      })
      .finally(() => setLoadingCoupon(false));
  };

  const handleChange = (e) => {
    setCode(e.target.value ? e.target.value.toUpperCase() : "");
  };

  useEffect(() => {
    if (onChange) onChange(coupon);
  }, [coupon]);

  return (
    <div className="m-0">
      <label>Aplicar cupom</label>
      <div className="d-flex justify-content-center m-0">
        <div className="w-100 m-0">
          <Form.Control
            onChange={handleChange}
            value={code}
            placeholder="CÓDIGO DO CUPOM"
          />
        </div>
        <Button
          variant="outline-primary"
          onClick={getCouponByCode}
          disabled={loadingCoupon}
        >
          Aplicar desconto
        </Button>
      </div>
    </div>
  );
};

export default SaleCoupon;
