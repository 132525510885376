import { useContext, useEffect, useState } from "react";
import moment from "moment";
import Api from "../../../api/api";
import { FilialContext } from "../../../context/FilialContext";
import { toast } from "react-toastify";
import { ProgressBar } from "react-bootstrap";
import MonthlyPartiesWeekChart from "./MonthlyPartiesWeekChart";

const WeeklyTicketsPerMonth = () => {
  const { selectedUnitId } = useContext(FilialContext);
  const [weeklyTicketsPerMonthFilter, setWeeklyTicketsPerMonthFilter] =
    useState({
      date: moment().startOf("month").format("YYYY-MM-DD"),
    });
  const [loadingWeeklyTicketsPerMonth, setLoadingWeeklyTicketsPerMonth] =
    useState(false);
  const [weeklyTicketsPerMonth, setWeeklyTicketsPerMonth] = useState([]);
  const [weeklyTicketsPerMonthTotalizer, setWeeklyTicketsPerMonthTotalizer] =
    useState(0);
  const [
    weeklyTicketsPerMonthTicketTotalizer,
    setWeeklyTicketsPerMonthTicketTotalizer,
  ] = useState(0);

  const getWeeklyTicketsPerMonth = () => {
    setLoadingWeeklyTicketsPerMonth(true);
    Api.get(
      `orders_tickets/weekly_per_month?${new URLSearchParams({
        ...weeklyTicketsPerMonthFilter,
        unit_id: selectedUnitId,
        ticket_type: "parties",
      })}`
    )
      .then((res) => {
        setWeeklyTicketsPerMonth(res.data.list);
        setWeeklyTicketsPerMonthTotalizer(res.data.total);
        setWeeklyTicketsPerMonthTicketTotalizer(res.data.ticket_total);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => setLoadingWeeklyTicketsPerMonth(false));
  };

  useEffect(() => {
    if (selectedUnitId) getWeeklyTicketsPerMonth();
  }, [weeklyTicketsPerMonthFilter, selectedUnitId]);

  const handleMonthlyPartiesWeekFilterChange = (e) => {
    setWeeklyTicketsPerMonthFilter((prev) => ({
      ...prev,
      [e.target.name]: e.target.value ? e.target.value + "-01" : null,
    }));
  };

  return (
    <>
      <h3>Festas Vendidas por Mês</h3>
      <div className="d-flex gap-1 mb-1 flex-wrap align-items-center justify-content-center align-items-sm-start justify-content-sm-start">
        <div className="input-group w-auto flex-0">
          <span className="input-group-text">Mês</span>
          <input
            value={
              weeklyTicketsPerMonthFilter.date
                ? moment(weeklyTicketsPerMonthFilter.date).format("YYYY-MM")
                : null
            }
            onChange={handleMonthlyPartiesWeekFilterChange}
            name="date"
            type="month"
            className="form-control"
            placeholder="Data inicial"
          />
        </div>
      </div>
      {loadingWeeklyTicketsPerMonth && (
        <ProgressBar animated now={100} className="mb-1" />
      )}
      <MonthlyPartiesWeekChart data={weeklyTicketsPerMonth} />
      <h4>{`Total: ${weeklyTicketsPerMonthTotalizer}`}</h4>
      <h4>{`Total Financeiro: ${(
        weeklyTicketsPerMonthTicketTotalizer || 0
      ).toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      })}`}</h4>
    </>
  );
};

export default WeeklyTicketsPerMonth;
