import { useEffect, useState } from "react";
import { Button, Form, InputGroup, Modal, ProgressBar } from "react-bootstrap";
import { toast } from "react-toastify";
import Api from "../../api/api";
import ImageUpload from "../../components/ImageUpload";
import PhotosModal from "./PhotosModal";

const EditModal = ({ open, setOpen, item, getList }) => {
  const [values, setValues] = useState({});
  const [load, setLoad] = useState(false);
  const [openPhotosModal, setOpenPhotosModal] = useState(false);

  useEffect(() => {
    if (open) setValues(item ? { ...item } : {});
  }, [item, open]);

  const closeModal = () => {
    setOpen(null);
  };

  const handleChange = (e) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSwitchChange = (e) => {
    setValues((prev) => ({ ...prev, status: e.target.checked ? 1 : 2 }));
  };

  const remove = () => {
    if (window.confirm("Deseja realmente remover este álbum?")) {
      setLoad(true);
      Api.delete(`albums/${values.id}`)
        .then((res) => {
          toast.success("Álbum removido com sucesso!");
          getList();
          closeModal();
        })
        .catch((err) => {
          toast.error(err.message);
        })
        .finally(() => {
          setLoad(false);
        });
    }
  };

  const save = () => {
    if (!values.name) return toast.warning("Nome do álbum indefinido!");
    if (!values.cover_url) return toast.warning("Capa do álbum indefinida!");

    setLoad(true);
    if (values.id)
      return Api.put(`albums/${values.id}`, values)
        .then((res) => {
          toast.success("Álbum atualizado com sucesso!");
          getList();
          closeModal();
        })
        .catch((err) => {
          toast.error(err.message);
        })
        .finally(() => {
          setLoad(false);
        });
    Api.post(`albums`, values)
      .then((res) => {
        toast.success("Álbum criado com sucesso!");
        getList();
        closeModal();
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setLoad(false);
      });
  };

  const handleOpenPhotosModal = () => {
    setOpenPhotosModal(true);
  };

  return (
    <>
      <Modal show={open} onHide={load ? null : closeModal} size={"md"}>
        {openPhotosModal && (
          <PhotosModal
            open={openPhotosModal}
            setOpen={setOpenPhotosModal}
            albums_id={values?.id}
          />
        )}
        <Modal.Body className="d-flex flex-column gap-1">
          <h5>{`${values.id ? "Editar" : "Adicionar"} Álbum`}</h5>
          <div className="d-flex flex-column">
            <InputGroup>
              <InputGroup.Text>Nome do Álbum</InputGroup.Text>
              <Form.Control
                value={values.name}
                name={"name"}
                onChange={handleChange}
                autoFocus
                maxLength={30}
              />
            </InputGroup>
            <span className="ml-auto">{`${
              values?.name?.length || 0
            }/30 caracteres`}</span>
          </div>
          <div className="d-flex flex-column">
            <InputGroup>
              <InputGroup.Text>Descrição</InputGroup.Text>
              <Form.Control
                value={values.description}
                name={"description"}
                onChange={handleChange}
                as="textarea"
                maxLength={200}
              />
            </InputGroup>
            <span className="ml-auto">{`${
              values?.description?.length || 0
            }/200 caracteres`}</span>
          </div>
          <InputGroup>
            <InputGroup.Text>Mostrar no site</InputGroup.Text>
            <div className="form-control">
              <Form.Check
                type="checkbox"
                label="Ativo"
                checked={Number(values.status) === 1}
                name="display"
                onChange={handleSwitchChange}
              />
            </div>
          </InputGroup>
          <div className="d-flex flex-column">
            <InputGroup className="flex-column align-items-center justify-content-center">
              <InputGroup.Text className="rounded-0 rounded-top w-100 justify-content-center">
                Capa
              </InputGroup.Text>
              <div
                className={
                  values.cover_url
                    ? "border border-primary rounded-0 rounded-bottom m-0 w-100 h-100 pt-1 overflow-hidden"
                    : "border border-primary rounded-0 rounded-bottom m-0 w-100 h-100 overflow-hidden"
                }
              >
                <ImageUpload
                  value={values.cover_url}
                  onChange={handleChange}
                  name="cover_url"
                  folderName={"albums"}
                  aspectRatio={3 / 2}
                  resizeOptions={{ maxHeight: 600 }}
                />
              </div>
            </InputGroup>
            <span className="ml-auto text-right">{`Dimensões ideais: 600px/400px`}</span>
            <span className="ml-auto text-right">{`Tamanho ideal: 200Kb`}</span>
            <span className="ml-auto text-right">{`Imagens com dimensões e tamanho maiores serão reduzidas até o tamanho ideal`}</span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={load} variant="light" onClick={closeModal}>
            Cancelar
          </Button>
          {values.id && (
            <Button disabled={load} variant="danger" onClick={remove}>
              Remover
            </Button>
          )}
          {values.id && (
            <Button
              disabled={load}
              variant="outline-primary"
              onClick={handleOpenPhotosModal}
            >
              Imagens
            </Button>
          )}
          <Button disabled={load} variant="primary" onClick={save}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditModal;
