import React from "react";
import { Card, Row, Col, Spinner } from "react-bootstrap";

export const CustomCard = ({ data, titleName }) => {
  return (
    <Card className="border-0">
      <Card.Header className="bg-info border-0 fs-5 text-white">
        {titleName}:
      </Card.Header>
      <Card.Body className="text-center">
        {data === undefined ? (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : (
          data.map((item, index) => (
            <Row
              key={index}
              className={`p-2 ${
                item.label === "Outros descontos" ? "text-muted" : ""
              }`}
            >
              <Col
                xs={12}
                md={4}
                className="d-flex align-items-center justify-content-center"
              >
                <strong className="text-nowrap">{item.label}</strong>
              </Col>
              <Col
                xs={12}
                md={4}
                className="d-flex align-items-center justify-content-center"
              >
                <span className="mr-1">R${item.value}</span>
                {item.value_percent && (
                  <span
                    className={
                      parseFloat(item.value_percent) < 0
                        ? "text-danger"
                        : "text-success"
                    }
                    style={{ fontSize: "0.6rem" }}
                  >
                    {item.value_percent === "Infinity%" ||
                    item.value_percent === "NaN%"
                      ? "-"
                      : parseFloat(item.value_percent) >= 0
                      ? "+" + item.value_percent
                      : item.value_percent}
                  </span>
                )}
              </Col>
              <Col
                xs={12}
                md={4}
                className="d-flex align-items-center justify-content-center"
              >
                {item.percentage}
              </Col>
            </Row>
          ))
        )}
      </Card.Body>
    </Card>
  );
};
