import { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormSelect,
  InputGroup,
  Modal,
} from "react-bootstrap";
import { toast } from "react-toastify";
import Api from "../../../../api/api";
import { genderList } from "../../../../utils/functions";
import moment from "moment";
import { getCPFCNPJData } from "../../../../utils/cpfcnpjValidation";

const AddBirthdayPersonModal = ({
  open,
  setOpen,
  onSuccess,
  order_ticket_id,
  ticket_birthday_people_id,
}) => {
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);
  const [isWithinDiscountPeriod, setIsWithinDiscountPeriod] = useState(false);
  const [cpfValue, setCPFValue] = useState("");
  const [userData, setUserData] = useState(null);
  const [showDiscountPeriodError, setShowDiscountPeriodError] = useState(false);

  const loadingLocked = loadingData || loadingSave || loadingRemove;

  const [values, setValues] = useState({
    order_ticket_id,
    name: "",
    age: 0,
    birth_date: "",
    gender: "",
    responsibles: 0,
  });

  const getData = () => {
    setLoadingData(true);
    Api.get(`tickets_birthday_people/${ticket_birthday_people_id}`)
      .then((res) => setValues({ ...res.data.data }))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingData(false));
  };

  useEffect(() => {
    if (open && ticket_birthday_people_id > 0) getData();
  }, [ticket_birthday_people_id, open]);

  const handleClose = () => {
    if (loadingLocked) return;
    setOpen(false);
  };

  const handleChange = (e) => {
    setValues((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleButtonClick = async () => {
    try {
      const response = await getCPFCNPJData(cpfValue);
      setUserData(response);
      setValues((prev) => ({
        ...prev,
        name: response.nome,
        birth_date: moment(response.nascimento, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        ),
        age: calculateAge(response.nascimento),
      }));

      const birthday = validateBirthdayDiscount(response.nascimento);
      setIsWithinDiscountPeriod(birthday);
    } catch (error) {
      setUserData(null);
    }
  };

  function calculateAge(nascimento) {
    const birthDate = moment(nascimento, "DD/MM/YYYY");
    const today = moment();
    let age = today.diff(birthDate, "years");
    return age;
  }

  const validateBirthdayDiscount = (birthDate) => {
    const today = moment();
    const birthMoment = moment(birthDate, "DD/MM");

    const birthMonth = birthMoment.month();
    const currentMonth = today.month();

    const isWithinDiscountPeriod =
      currentMonth === birthMonth &&
      birthMoment.isBetween(
        today.clone().subtract(7, "days"),
        today.clone().add(7, "days"),
        "day",
        "[]"
      );

    return isWithinDiscountPeriod;
  };

  const renderBirthdayPeopleInputs = () => {
    return (
      <div className="d-flex flex-column gap-1">
        {ticket_birthday_people_id <= 0 && (
          <div className="text-center">
            <InputGroup>
              <InputGroup.Text>CPF:</InputGroup.Text>
              <FormControl
                value={cpfValue}
                onChange={(e) => setCPFValue(e.target.value)}
                type="text"
                maxLength={11}
              />
            </InputGroup>
            <Button className="ml-2 mb-2 mt-2" onClick={handleButtonClick}>
              Consultar
            </Button>
          </div>
        )}

        <InputGroup>
          <InputGroup.Text>Nome</InputGroup.Text>
          <FormControl value={values.name} type={"name"} name="name" disabled />
        </InputGroup>
        <InputGroup>
          <InputGroup.Text>Data de Nascimento</InputGroup.Text>
          <FormControl
            value={moment(values.birth_date).format("DD/MM/YYYY")}
            name="birth_date"
            disabled
          />
        </InputGroup>
        {showDiscountPeriodError &&
        !validateBirthdayDiscount(values.birth_date) ? (
          <p className="text-danger">
            O aniversariante não está no período de desconto.
          </p>
        ) : null}

        <InputGroup>
          <InputGroup.Text>Idade</InputGroup.Text>
          <FormControl
            value={values.age}
            min={1}
            type={"number"}
            disabled
            name="age"
          />
        </InputGroup>
        <InputGroup>
          <InputGroup.Text>Gênero</InputGroup.Text>
          <FormSelect
            value={values.gender}
            name={"gender"}
            onChange={handleChange}
          >
            <option hidden>Clique aqui para selecionar</option>
            {genderList.map((g) => (
              <option key={`gender_item_${g.value}`} value={g.value}>
                {g.label}
              </option>
            ))}
          </FormSelect>
        </InputGroup>

        <InputGroup>
          <InputGroup.Text>Responsáveis</InputGroup.Text>
          <FormControl
            value={values.responsibles}
            type={"number"}
            name="responsibles"
            onChange={handleChange}
          />
        </InputGroup>
      </div>
    );
  };

  const handleAddBirthdayPersonToOrder = () => {
    setLoadingSave(true);

    if (!isWithinDiscountPeriod) {
      toast.error("O aniversariante não está no período de desconto.");
      setLoadingSave(false);
    }

    if (ticket_birthday_people_id > 0)
      return Api.put(
        `/tickets_birthday_people/${ticket_birthday_people_id}`,
        values
      )
        .then(() => {
          toast.success("Aniversariante atualizado com sucesso!");
          if (onSuccess) onSuccess();
        })
        .catch((err) => {
          toast.error(err.message);
          setLoadingSave(false);
        });
    Api.post(`/tickets_birthday_people`, values)
      .then(() => {
        toast.success("Aniversariante adicionado com sucesso!");
        if (onSuccess) onSuccess();
      })
      .catch((err) => {
        toast.error(err.message);
        setLoadingSave(false);
      });
  };

  const handleRemoveBirthdayPersonFromOrder = () => {
    if (
      window.confirm("Deseja realmente remover este aniversariante da venda?")
    ) {
      setLoadingRemove(true);
      Api.delete(`/tickets_birthday_people/${values.id}`)
        .then(() => {
          toast.success("Aniversariante removido com sucesso!");
          if (onSuccess) onSuccess();
        })
        .catch((err) => {
          toast.error(err.message);
          setLoadingSave(false);
        });
    }
  };

  return (
    <Modal show={open} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Adicionar aniversariante à venda</Modal.Title>
      </Modal.Header>
      <Modal.Body>{renderBirthdayPeopleInputs()}</Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose} disabled={loadingLocked}>
          Cancelar
        </Button>
        {values?.id > 0 && (
          <Button
            variant="danger"
            onClick={handleRemoveBirthdayPersonFromOrder}
            disabled={loadingLocked}
          >
            Remover
          </Button>
        )}
        <Button
          onClick={handleAddBirthdayPersonToOrder}
          disabled={loadingLocked}
        >
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddBirthdayPersonModal;
