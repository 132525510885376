import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const DailyInsightChart = ({ data }) => {
  const labels = data.map((item) =>
    moment(item.authorized_at_date).format("DD/MM/YYYY")
  );

  const getTooltipLabel = (item) => {
    return Number(item.dataset.data[item.dataIndex]).toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    });
  };

  return (
    <Bar
      options={{
        type: "bar",
        plugins: {
          tooltip: {
            callbacks: { label: getTooltipLabel },
          },
          legend: { display: false },
        },
      }}
      data={{
        labels,
        datasets: [
          {
            data: data.map((item) => item.total),
            backgroundColor: "#6F3B8CCC",
          },
        ],
      }}
    />
  );
};

export default DailyInsightChart;
