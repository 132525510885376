import { maskBr } from "js-brasil";
import moment from "moment";
import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  Button,
  Col,
  Form,
  InputGroup,
  ProgressBar,
  Row,
  Card,
} from "react-bootstrap";
import { toast } from "react-toastify";
import Api from "../../api/api";
import PageTitle from "../../components/PageTitle";
import Table from "../../components/Table";
import { FilialContext } from "../../context/FilialContext";
import renamePaymentType from "../../utils/paymentType";
import {
  HiEye,
  HiOutlineDocumentText,
  HiPencilSquare,
  HiTrash,
} from "react-icons/hi2";
import ViewSaleModal from "./ViewSaleModal";
import EditSaleModal from "./EditSaleModal";
import ResendTermsModal from "./ResendTermsModal";
import RemoveModal from "./RemoveModal";
import { cleanNumberString, getTransactionStatus } from "../../utils/functions";

const TicketsSales = () => {
  const { selectedUnitId } = useContext(FilialContext);

  const [search, setSearch] = useState("");

  const [filter, setFilter] = useState({
    limit: 10,
    page: 0,
    ticket_date_start: "",
    ticket_date_end: "",
    transaction_date_start: "",
    transaction_date_end: "",
    type: "ticket",
  });

  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [loadingList, setLoadingList] = useState(false);

  const [openViewSale, setOpenViewSale] = useState(false);
  const [openEditSale, setOpenEditSale] = useState(false);
  const [openResendTerms, setOpenResendTerms] = useState(false);
  const [openRemove, setOpenRemove] = useState(false);

  const getList = () => {
    setLoadingList(true);
    Api.get(`/orders`, {
      params: { ...filter, unit_id: selectedUnitId, search },
    })
      .then((res) => {
        setList(res.data.list);
        setTotal(res.data.total);
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingList(false));
  };

  useEffect(() => {
    if (selectedUnitId) getList();
  }, [selectedUnitId, filter]);

  const handleFilterChange = (e) => {
    setFilter((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSearchKeyDown = (e) => {
    if (e.keyCode === 13) getList();
  };

  const columns = [
    {
      label: "ID",
      field: "code",
      renderValue: (item) => item.code.split("-")[0],
    },
    {
      label: "Status",
      field: "status",
      renderValue: (item) => getTransactionStatus(item.status),
    },
    {
      label: "Status Termos",
      field: "terms_signed",
      renderValue: (item) =>
        item.terms_signed
          ? `Assinados (${item.terms_manually_signed ? "manual" : "digital"})`
          : "Pendentes",
    },
    {
      label: "Data Uso",
      field: "date",
      renderValue: (item) => moment(item.date).format("DD/MM/YYYY"),
    },
    {
      label: "Início",
      field: "ticket_time",
      renderValue: (item) =>
        moment(item.ticket_time, "HH:mm:ss").format("HH:mm"),
    },
    {
      label: "Fim",
      field: "end_time",
      renderValue: (item) => moment(item.end_time, "HH:mm:ss").format("HH:mm"),
    },
    { label: "E-mail", field: "email" },
    {
      label: "Documento",
      field: "cpf",
      renderValue: (item) =>
        [true, "true"].includes(item.brazilian_doc)
          ? cleanNumberString(item.cpf)?.length > 11
            ? maskBr.cnpj(item.cpf)
            : maskBr.cpf(item.cpf)
          : item.cpf,
    },
    {
      label: "Nº Telefone",
      field: "phone",
      renderValue: (item) =>
        item?.user_info?.phone ? item?.user_info?.phone : "N/A",
    },
    {
      label: "Data Compra",
      field: "transaction_created_at",
      renderValue: (item) =>
        moment(item.transaction_created_at).format("DD/MM/YYYY HH:mm:ss"),
    },
    // { label: "Vendedor", field: "seller_email" },
    {
      label: "Valor Total",
      field: "total_value",
      renderValue: (item) =>
        item.total_value.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
    },
    // {
    //   label: "Origem",
    //   field: "online_sale",
    //   renderValue: (item) => (item.online_sale ? "Online" : "Local"),
    // },
    // {
    //   label: "Método Pagamento",
    //   field: "payment_method",
    //   renderValue: (item) => renamePaymentType(item.payment_method),
    // },
  ];

  const renderRows = (field, item) => {
    if (!item) return null;
    const data = columns.find((col) => col.field === field);
    if (data?.renderValue) return data.renderValue(item);
    return item[field];
  };

  const handleLimitChange = (newLimit) => {
    setFilter((prev) => ({ ...prev, limit: newLimit }));
  };

  const handlePageChange = (newPage) => {
    setFilter((prev) => ({ ...prev, page: newPage }));
  };

  useEffect(() => {
    document.getElementById("topbar")?.scrollIntoView();
  }, []);

  return (
    <>
      {openViewSale && (
        <ViewSaleModal
          open={!!openViewSale}
          setOpen={setOpenViewSale}
          code={openViewSale}
        />
      )}
      {openEditSale && (
        <EditSaleModal
          open={!!openEditSale}
          setOpen={setOpenEditSale}
          code={openEditSale}
          onSuccess={getList}
        />
      )}
      {openResendTerms && (
        <ResendTermsModal
          open={!!openResendTerms}
          setOpen={setOpenResendTerms}
          order_ticket_id={openResendTerms}
          onSuccess={getList}
        />
      )}
      {openRemove && (
        <RemoveModal
          open={!!openRemove}
          setOpen={setOpenRemove}
          code={openRemove}
          onSuccess={getList}
        />
      )}

      <PageTitle className="d-flex flex-column flex-md-row">
        <h2>Ingressos Vendidos</h2>
      </PageTitle>
      <Row className="g-4">
        <Col xs={12} md={8}>
          <InputGroup>
            <InputGroup.Text>Buscar</InputGroup.Text>
            <Form.Control
              value={search}
              name={"search"}
              placeholder="Pesquisar por ID, nome, e-mail, CPF"
              onChange={handleSearchChange}
              onKeyDown={handleSearchKeyDown}
            />
          </InputGroup>
        </Col>
        <Col xs={12} md={8} xl={6}>
          <div className="d-flex flex-column gap-1">
            <h5 className="fw-bold">Filtrar por data de uso:</h5>
            <div className="d-flex gap-1 flex-wrap flex-sm-nowrap">
              <InputGroup>
                <InputGroup.Text>Data inicial</InputGroup.Text>
                <Form.Control
                  value={filter?.ticket_date_start}
                  name={"ticket_date_start"}
                  onChange={handleFilterChange}
                  type="date"
                />
              </InputGroup>
              <InputGroup>
                <InputGroup.Text>Data final</InputGroup.Text>
                <Form.Control
                  value={filter?.ticket_date_end}
                  name={"ticket_date_end"}
                  onChange={handleFilterChange}
                  type="date"
                />
              </InputGroup>
            </div>
          </div>
        </Col>
        <Col xs={12} md={8} xl={6}>
          <div className="d-flex flex-column gap-1">
            <h5 className="fw-bold">Filtrar por data de criação:</h5>
            <div className="d-flex gap-1 flex-wrap flex-sm-nowrap">
              <InputGroup>
                <InputGroup.Text>Data inicial</InputGroup.Text>
                <Form.Control
                  value={filter?.transaction_date_start}
                  name={"transaction_date_start"}
                  onChange={handleFilterChange}
                  type="date"
                />
              </InputGroup>
              <InputGroup>
                <InputGroup.Text>Data final</InputGroup.Text>
                <Form.Control
                  value={filter?.transaction_date_end}
                  name={"transaction_date_end"}
                  onChange={handleFilterChange}
                  type="date"
                />
              </InputGroup>
            </div>
          </div>
        </Col>

        <Col xs={12}>
          {loadingList && <ProgressBar className="mb-1" animated now={100} />}
          <Card>
            <Card.Body>
              <Card.Title>
                <h5 className="fw-bold">Lista ingressos vendidos:</h5>
              </Card.Title>
              <Table
                emptyListLabel={
                  loadingList
                    ? null
                    : "Nenhum ingresso vendido com base no filtro."
                }
                limit={filter.limit}
                limitOptions={[10, 30, 50]}
                page={filter.page}
                paginated
                total={total}
                columns={columns}
                renderRow={renderRows}
                renderRowItem
                data={list}
                handleLimitChange={handleLimitChange}
                handlePageChange={handlePageChange}
                actions={(item) => (
                  <div className="d-flex align-items-center gap-1">
                    <Button
                      data-toggle="tooltip"
                      title="Visualizar"
                      variant="outline-primary"
                      className="rounded-circle"
                      size="sm"
                      onClick={() => setOpenViewSale(item.code.split("-")[0])}
                    >
                      <HiEye size={"100%"} />
                    </Button>
                    <Button
                      data-toggle="tooltip"
                      title="Editar"
                      variant="outline-secondary"
                      className="rounded-circle"
                      size="sm"
                      onClick={() => setOpenEditSale(item.code.split("-")[0])}
                    >
                      <HiPencilSquare size={"100%"} />
                    </Button>
                    <Button
                      data-toggle="tooltip"
                      title="Reenviar Termos"
                      variant="outline-info"
                      className="rounded-circle"
                      size="sm"
                      disabled={item.terms_signed}
                      onClick={() => setOpenResendTerms(item.order_ticket_id)}
                    >
                      <HiOutlineDocumentText size={"100%"} />
                    </Button>
                    <Button
                      data-toggle="tooltip"
                      title="Remover"
                      variant="outline-danger"
                      className="rounded-circle"
                      size="sm"
                      disabled={["removed", "deleted"].includes(item.status)}
                      onClick={() => setOpenRemove(item.code.split("-")[0])}
                    >
                      <HiTrash size={"100%"} />
                    </Button>
                  </div>
                )}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default TicketsSales;
