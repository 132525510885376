import { useEffect, useState } from "react";
import { Button, Form, FormLabel, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import Api from "../../api/api";

const RemoveModal = ({ open, setOpen, code, onSuccess }) => {
  const [loadingRemove, setLoadingRemove] = useState(false);
  const [deletionReason, setDeletionReason] = useState("");

  useEffect(() => {
    if (open) setDeletionReason("");
  }, [open]);

  const handleRemove = () => {
    if (!deletionReason)
      return toast.warning("Preencha o motivo da remoção do ticket!");

    setLoadingRemove(true);
    Api.delete(`orders/code/${code}`, {
      data: { deletion_reason: deletionReason },
    })
      .then(() => {
        toast.success("Ticket removido com sucesso!");
        setOpen(false);
        if (onSuccess) onSuccess();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingRemove(false));
  };

  const handleClose = () => {
    if (loadingRemove) return;
    setOpen(false);
  };

  const handleChange = (e) => {
    setDeletionReason(e.target.value);
  };

  return (
    <Modal show={open} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Confirmação</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span className="fs-3 text-danger">
          {`Deseja realmente remover esta venda?`}
        </span>
        <div className="d-flex flex-column gap-1 mt-3">
          <FormLabel>Motivo da remoção</FormLabel>
          <Form.Control
            as="textarea"
            value={deletionReason}
            onChange={handleChange}
            rows={5}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose} disabled={loadingRemove}>
          Cancelar
        </Button>
        <Button
          variant="danger"
          onClick={handleRemove}
          disabled={loadingRemove}
        >
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RemoveModal;
