import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const MonthlyPartiesChart = ({ data }) => {
  const labels = data.map((item) =>
    moment(item.month_year + "-01").format("MM/YYYY")
  );

  return (
    <Bar
      options={{
        type: "bar",
        plugins: {
          legend: { display: false },
        },
      }}
      data={{
        labels,
        datasets: [
          {
            data: data.map((item) => item.total),
            backgroundColor: "#6F3B8CCC",
          },
        ],
      }}
    />
  );
};

export default MonthlyPartiesChart;
