import React, { useEffect, useState } from "react";
import { Button, Form, Card } from "react-bootstrap";
import Select from "react-select";
import TicketDP from "../../../NewSalesTicket/TicketDP";
import { toast } from "react-toastify";
import moment from "moment";
import { getCPFCNPJData } from "../../../../utils/cpfcnpjValidation";
import { v4 } from "uuid";

const selectStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: "2rem",
    borderBottomRightRadius: "2rem",
  }),
  container: (baseStyles, state) => ({
    ...baseStyles,
    alignSelf: "stretch",
    flex: 1,
  }),
};

const Participants = ({
  handleNext,
  handleCancel,
  sale,
  setSale,
  yesNo,
  setDisableBirthday,
}) => {
  const [hasCourtesy, setHasCourtesy] = useState(
    sale.courtesy_players > 0 ||
      sale.courtesy_not_players > 0 ||
      sale.is_birthday === 1 ||
      false
  );

  const borderColor = sale.quantity_birthdays ? "#fdc028" : "";

  const [consultedCPFs, setConsultedCPFs] = useState([]);
  const [userDatas, setUserDatas] = useState([...sale.ticket_birthday_people]);
  const [isWithinDiscountPeriod, setIsWithinDiscountPeriod] = useState(false);
  const [loading, setLoading] = useState(
    Array.from({ length: Number(sale.quantity_birthdays) }, () => false)
  );
  const [userDatasMinors, setUserDatasMinors] = useState([
    ...sale.minors_infos,
  ]);

  const [consultedCPFsMinors, setConsultedCPFsMinors] = useState([]);

  const handleButtonClick = async () => {
    setLoading((prevLoading) => prevLoading.map(() => true));
    try {
      const uniqueCPFValues = [...new Set(sale.cpf_birthday)];
      if (uniqueCPFValues.length !== sale.cpf_birthday.length) {
        toast.error(`Não são permitidos CPFs duplicados`);
        return;
      }

      if (!sale || !sale.date) {
        return toast.error("Por favor, selecione a data na aba de ingresso.");
      }

      const updatedUserDatas = [];

      if (sale.cpf_birthday.length === 0) {
        console.log("Não há mais CPFs a serem processados");
        return;
      }

      for (let i = 0; i < sale.cpf_birthday.length; i++) {
        const cpfValue = sale.cpf_birthday[i];

        if (consultedCPFs[cpfValue]) {
          toast.warning(`O CPF ${cpfValue} já foi consultado anteriormente`);
          updatedUserDatas[i] = { ...consultedCPFs[cpfValue] };

          if (!updatedUserDatas[i].nascimento) {
            const response = await getCPFCNPJData(cpfValue);
            if (response instanceof Error) {
              throw response;
            }

            setConsultedCPFs((prevConsultedCPFs) => ({
              ...prevConsultedCPFs,
              [cpfValue]: response,
            }));
            updatedUserDatas[i] = { ...response };
          }

          continue;
        }

        if (cpfValue.length === 0) {
          toast.error(
            `Campo de CPF do aniversariante ${i + 1} deve ser preenchido`
          );
          continue;
        }

        if (cpfValue.length !== 11) {
          toast.error(
            `CPF do aniversariante ${i + 1} deve conter exatamente 11 dígitos`
          );
          continue;
        }

        const response = await getCPFCNPJData(cpfValue);
        if (response instanceof Error) {
          throw response;
        }

        if (response && response.nascimento) {
          const birthday = validateBirthdayDiscount(response.nascimento);
          if (!consultedCPFs[cpfValue]) {
            setConsultedCPFs((prevConsultedCPFs) => ({
              ...prevConsultedCPFs,
              [cpfValue]: response,
            }));
          }

          if (!userDatas[i]) {
            setUserDatas((prevUserDatas) => {
              const updatedUserDatas = [...prevUserDatas];
              updatedUserDatas[i] = { ...response };
              return updatedUserDatas;
            });

            updatedUserDatas.push({ ...response });
          }
        }
      }

      updateTicketBirthdayPeople([...updatedUserDatas]);
    } catch (error) {
      console.log("Error", error);
      toast.error(
        error.message ||
          `Você excedeu o número máximo de tentativas. Tente novamente em alguns minutos.`
      );
      setUserDatas((prevUserDatas) => prevUserDatas.map(() => null));
    } finally {
      setLoading((prevLoading) => prevLoading.map(() => false));
    }
  };

  const updateTicketBirthdayPeople = (userDatas) => {
    setSale((prev) => {
      const newQuantityBirthdays = Math.min(
        sale.quantity_birthdays,
        userDatas.length
      );

      const newObjects = userDatas
        .slice(0, newQuantityBirthdays)
        .map((userData, index) => ({
          id: v4(),
          name: userData ? userData.nome : "",
          birth_date: userData.nascimento,
          age: calculateAge(userData.nascimento),
          gender: "rather_not_inform",
          responsibles: 0,
        }));

      const updatedTicketBirthdayPeople = newObjects;

      return {
        ...prev,
        ticket_birthday_people: updatedTicketBirthdayPeople,
        quantity_birthdays: newQuantityBirthdays,
      };
    });
  };

  const validateBirthdayDiscount = (birthDate) => {
    const birthMoment = moment(birthDate, "DD/MM/YYYY");
    const selectedMoment = moment(sale.date, "YYYY-MM-DD");
    const year = selectedMoment.year();

    const birthThisYear = moment(birthMoment).year(year);

    const startDiscountPeriod = birthThisYear.clone().subtract(7, "days");
    const endDiscountPeriod = birthThisYear.clone().add(7, "days");

    const discountPeriod = selectedMoment.isBetween(
      startDiscountPeriod,
      endDiscountPeriod,
      undefined,
      "[]"
    );

    setDisableBirthday(!discountPeriod);

    return discountPeriod;
  };

  useEffect(() => {
    if (userDatas && userDatas.length > 0) {
      const isWithin = userDatas.some(
        (person) =>
          person &&
          person.nascimento &&
          validateBirthdayDiscount(person.nascimento)
      );
      setIsWithinDiscountPeriod(isWithin);
    } else {
      setIsWithinDiscountPeriod(false);
    }
  }, [userDatas, sale.date]);

  function calculateAge(nascimento) {
    const birthDate = moment(nascimento, "DD/MM/YYYY");
    const today = moment();
    let age = today.diff(birthDate, "years");
    return age;
  }

  const handleChange = (e, index) => {
    let { name, value, type } = e.target;
    value = type === "number" ? Number(value) : value;
  
    if (type === "number" && value < 0) value = 0;
  
    setSale((prev) => {
      let newSale = { ...prev, [name]: value };
  
      if (name === "minors") {
        if (value < prev.minors_responsibles) {
          newSale.minors_responsibles = value;
        }
      }
  
      if (name === "minors_responsibles" && value > newSale.minors) {
        newSale.minors_responsibles = newSale.minors;
      }

      if (name === "dps") {
        if (value < prev.dps_responsibles) {
          newSale.dps_responsibles = value;
        }
      }
      if (name === "dps_responsibles" && value > newSale.dps) {
        newSale.dps_responsibles = newSale.dps;
      }
  
      return newSale;
    });
  };
  

  const handleHasKidsChange = (item) => {
    const kidsValue = item.value ? 1 : 0;
    setSale((prev) => ({ ...prev, kids_value: kidsValue }));

    if (!item.value) {
      setSale((prev) => ({
        ...prev,
        minors: 0,
        minors_responsibles: 0,
        cpf_minors: [],
        minors_infos: [],
      }));
    } else {
      setSale((prev) => ({
        ...prev,
        minors: 1,
        minors_responsibles: 1,
      }));
    }
  };

  const handleHasPCDChange = (item) => {
    const pcdValue = item.value ? 1 : 0;
    setSale((prev) => ({ ...prev, pcd_value: pcdValue }));

    if (!item.value) {
      setSale((prev) => ({
        ...prev,
        dps: 0,
        dps_responsibles: 0,
        ticket_disabled_people: [],
      }));
    } else {
      setSale((prev) => ({ ...prev, dps: prev.dps + 1 }));
    }
  };

  const handleTicketDPChange = (ticket_disabled_people) => {
    setSale((prev) => ({ ...prev, ticket_disabled_people }));
  };

  const handleHasCourtesyChange = (item) => {
    setHasCourtesy(item.value);
    if (!item.value)
      setSale((prev) => ({
        ...prev,
        courtesy_players: 0,
        courtesy_not_players: 0,
      }));
  };

  const handleIsBirthdayChange = (item) => {
    const newQuantity = item.value ? 1 : 0;
    setSale((prev) => ({
      ...prev,
      is_birthday: newQuantity,
      quantity_birthdays: newQuantity,
      cpf_birthday: item.value ? prev.cpf_birthday : [],
      courtesy_players: item.value ? 1 : prev.courtesy_players,
      ticket_birthday_people: item.value ? prev.ticket_birthday_people : [],
    }));

    if (item.value) {
      setHasCourtesy(true);
    } else {
      setHasCourtesy(false);
    }
  };

  const handleCPFChange = (e, index) => {
    if (e.target.name.startsWith("cpf_birthday_")) {
      const cpfValue = e.target.value.replace(/\D/g, "");
      const newCPFValues = [...sale.cpf_birthday];
      newCPFValues[index] = cpfValue;
      setSale((prev) => ({
        ...prev,
        cpf_birthday: newCPFValues,
      }));

      if (consultedCPFs[cpfValue]) {
        setUserDatas((prevUserDatas) => {
          const updatedUserDatas = [...prevUserDatas];
          updatedUserDatas[index] = { ...consultedCPFs[cpfValue] };
          return updatedUserDatas;
        });
      } else {
        setUserDatas((prevUserDatas) => {
          const updatedUserDatas = [...prevUserDatas];
          updatedUserDatas[index] = null;
          return updatedUserDatas;
        });
      }
    }
  };

  const handleCPFminorsChange = (e, index) => {
    if (e.target.name.startsWith("cpf_minors_")) {
      const cpfValue = e.target.value.replace(/\D/g, "");
      const newCPFValues = [...sale.cpf_minors];
      newCPFValues[index] = cpfValue;
      setSale((prev) => ({
        ...prev,
        cpf_minors: newCPFValues,
      }));

      if (consultedCPFs[cpfValue]) {
        setUserDatasMinors((prevUserDatas) => {
          const updatedUserDatas = [...prevUserDatas];
          updatedUserDatas[index] = { ...consultedCPFs[cpfValue] };
          return updatedUserDatas;
        });
      } else {
        setUserDatasMinors((prevUserDatas) => {
          const updatedUserDatas = [...prevUserDatas];
          updatedUserDatas[index] = null;
          return updatedUserDatas;
        });
      }
    }
  };

  const handleQuantityChange = (newQuantity) => {
    let updatedCourtesyPlayers = sale.courtesy_players;

    if (newQuantity < sale.quantity_birthdays) {
      const updatedCpfBirthday = sale.cpf_birthday.slice(0, newQuantity);
      setSale((prev) => ({
        ...prev,
        cpf_birthday: updatedCpfBirthday,
        ticket_birthday_people: [],
      }));

      const difference = sale.quantity_birthdays - newQuantity;
      updatedCourtesyPlayers -= difference;
    }

    if (newQuantity > sale.quantity_birthdays) {
      const difference = newQuantity - sale.quantity_birthdays;
      updatedCourtesyPlayers += difference;
    }

    if (sale.is_birthday && newQuantity === 0) {
      newQuantity = 1;
    }

    setSale((prev) => ({
      ...prev,
      quantity_birthdays: newQuantity,
      courtesy_players: updatedCourtesyPlayers,
    }));
  };

  const totalPlayers =
    Number(sale.players) +
    Number(sale.minors) +
    Number(sale.minors_responsibles) +
    Number(sale.dps) +
    Number(sale.dps_responsibles) +
    Number(sale.courtesy_players) +
    Number(sale.quantity_birthdays);

  const totalNotPlayers =
    Number(sale.not_players) + Number(sale.courtesy_not_players);

  useEffect(() => {
    setSale((prev) => ({
      ...prev,
      total_players: totalPlayers,
      total_not_players: totalNotPlayers,
    }));
  }, [totalPlayers, totalNotPlayers]);

  const hasNewDisabilityType = sale?.ticket_disabled_people?.some(
    (item) => item.disability_type_id === "new"
  );

  const handleMinorsInfos = async () => {
    setLoading((prevLoading) => prevLoading.map(() => true));
    try {
      const uniqueCPFValues = [...new Set(sale.cpf_minors)];
      if (uniqueCPFValues.length !== sale.cpf_minors.length) {
        toast.error(`Não são permitidos CPFs duplicados`);
        return;
      }

      const updatedMinors = [];

      if (sale.cpf_minors.length === 0) {
        console.log("Não há mais CPFs a serem processados");
        return;
      }

      for (let i = 0; i < sale.cpf_minors.length; i++) {
        const cpfValue = sale.cpf_minors[i];

        if (consultedCPFsMinors[cpfValue]) {
          toast.warning(`O CPF ${cpfValue} já foi consultado anteriormente`);
          updatedMinors[i] = { ...consultedCPFsMinors[cpfValue] };

          if (!updatedMinors[i].nascimento) {
            const response = await getCPFCNPJData(cpfValue);
            if (response instanceof Error) {
              throw response;
            }

            setConsultedCPFsMinors((prevConsultedCPFs) => ({
              ...prevConsultedCPFs,
              [cpfValue]: response,
            }));
            updatedMinors[i] = { ...response };
          }

          continue;
        }

        if (cpfValue.length === 0) {
          toast.error(`Campo de CPF da criança ${i + 1} deve ser preenchido`);
          continue;
        }

        if (cpfValue.length !== 11) {
          toast.error(
            `CPF da criança ${i + 1} deve conter exatamente 11 dígitos`
          );
          continue;
        }

        const response = await getCPFCNPJData(cpfValue);
        if (response instanceof Error) {
          throw response;
        }

        if (response && response.nascimento) {
          if (!consultedCPFsMinors[cpfValue]) {
            setConsultedCPFsMinors((prevConsultedCPFs) => ({
              ...prevConsultedCPFs,
              [cpfValue]: response,
            }));
          }

          if (!userDatasMinors[i]) {
            setUserDatasMinors((prevUserDatas) => {
              const updatedUserDatas = [...prevUserDatas];
              updatedUserDatas[i] = { ...response };
              return updatedUserDatas;
            });

            updatedMinors.push({ ...response });
          }
        }
      }

      updatedMinorsInfos([...updatedMinors]);
    } catch (error) {
      console.log("Error", error);
      toast.error(
        error.message ||
          `Você excedeu o número máximo de tentativas. Tente novamente em alguns minutos.`
      );
      setUserDatas((prevUserDatas) => prevUserDatas.map(() => null));
    } finally {
      setLoading((prevLoading) => prevLoading.map(() => false));
    }
  };

  const updatedMinorsInfos = (userDataMinors) => {
    setSale((prev) => {
      const updatedMinors = userDataMinors
        .map((userDataMinors, index) => ({
          name: userDataMinors ? userDataMinors.nome || "" : "",
          age: userDataMinors ? calculateAge(userDataMinors.nascimento) : "",
          date: userDataMinors ? userDataMinors.nascimento || "" : "",
        }))
        .filter((minor) => minor.name !== "" && minor.age !== "");

      return {
        ...prev,
        minors_infos: updatedMinors,
      };
    });
  };

  const handleClickButtonNext = () => {
    const hasChildOverFive = userDatasMinors.some(
      (minor) => calculateAge(minor.nascimento) >= 5
    );

    if (hasChildOverFive) {
      toast.error(
        "Não é possível prosseguir porque há crianças com mais de 5 anos."
      );
      return;
    }

    if (
      sale.is_birthday &&
      (sale.quantity_birthdays === 0 ||
        sale.courtesy_players === 0 ||
        sale.players === 0)
    ) {
      toast.error(
        "É necessário pelo menos um brincante e uma cortesia para os aniversariantes"
      );
    } else if (sale.is_birthday && sale.quantity_birthdays > sale.players) {
      toast.error(
        "Por favor, insira uma quantidade de brincantes maior ou igual à quantidade de aniversariantes."
      );
    } else if (sale.quantity_birthdays > 0 && sale.cpf_birthday.length === 0) {
      toast.error("Informe o CPF do aniversariante");
    } else if (hasNewDisabilityType) {
      toast.error("Selecione as deficiências do PCD");
    } else if (sale.is_birthday && sale.players < sale.quantity_birthdays) {
      toast.error(
        "A quantidade de brincantes deve ser maior ou igual à quantidade de aniversariantes."
      );
    } else {
      handleNext();
    }
  };

  const handleKeyPress = (e) => {
    const inputValue = e.target.value;
    const keyCode = e.keyCode || e.which;

    if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105)) {
      if (inputValue.length >= 1) {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  };

  return (
    <Card>
      <Card.Header className="border-0 bg-white">
        <span className="fs-5 fw-bold">
          {"Configurações de Participantes:"}
        </span>
      </Card.Header>
      <Card.Body className="d-flex flex-column gap-3">
        <div className="row">
          <div className="col">
            <label>Ingressos Brincantes:</label>
            <Form.Control
              autoFocus
              value={sale.players}
              name={"players"}
              type={"number"}
              onChange={handleChange}
            />
          </div>

          <div className="col">
            <label>Ingressos Não Brincantes:</label>
            <Form.Control
              value={sale.not_players}
              name={"not_players"}
              type={"number"}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <label>Participantes Aniversariantes?</label>
            <Select
              classNamePrefix="react-select"
              value={yesNo.find(
                (item) => item.value === (sale.is_birthday === 1 ? true : false)
              )}
              onChange={(val) => {
                handleIsBirthdayChange(val);
              }}
              options={yesNo}
              styles={selectStyles}
            />
          </div>

          {sale.is_birthday === 1 && (
            <div className="col">
              <label>Ingressos Aniversariantes</label>
              <Form.Control
                type="number"
                value={sale.quantity_birthdays}
                name={"quantity_birthdays"}
                onChange={(e) => handleQuantityChange(Number(e.target.value))}
                min="0"
                style={{ border: `3px solid ${borderColor}` }}
              />
            </div>
          )}
        </div>

        {sale.quantity_birthdays > 0 && (
          <>
            {[...Array(sale.quantity_birthdays)].map((_, index) => (
              <div className="row">
                <div className="col" key={index}>
                  <label>CPF do aniversariante {index + 1}:</label>
                  <Form.Control
                    onChange={(e) => {
                      handleCPFChange(e, index);
                    }}
                    value={sale.cpf_birthday[index] || ""}
                    name={`cpf_birthday_${index}`}
                    maxLength={11}
                  />
                </div>
              </div>
            ))}
            <div className="row">
              <div className="col mt-2 text-center">
                <Button variant="primary" onClick={handleButtonClick}>
                  Consultar
                </Button>
              </div>
            </div>
          </>
        )}

        {sale.ticket_birthday_people.some((data) => !!data) &&
          [...Array(Number(sale.quantity_birthdays))].map((_, index) => (
            <div className="row" key={index}>
              <div className="col" xs={12}>
                {loading[index] && (
                  <p>
                    Carregando informações, por favor aguarde um instante...
                  </p>
                )}
                {sale.cpf_birthday[index]?.length === 11 && (
                  <Form.Group>
                    <label>CPF do aniversariante {index + 1}:</label>
                    <Form.Control
                      readOnly
                      className={
                        sale.ticket_birthday_people[index] &&
                        !validateBirthdayDiscount(
                          sale.ticket_birthday_people[index]?.birth_date
                        )
                      }
                      value={`Nome: ${
                        sale.ticket_birthday_people[index]?.name ||
                        "Não encontrado"
                      }, Data de Nascimento: ${
                        sale.ticket_birthday_people[index]?.birth_date ||
                        "Não encontrado"
                      }, Idade: ${
                        sale.ticket_birthday_people[index]?.birth_date
                          ? calculateAge(
                              sale.ticket_birthday_people[index]?.birth_date
                            )
                          : "Não encontrado"
                      }`}
                    />
                    {sale.ticket_birthday_people[index] &&
                      !validateBirthdayDiscount(
                        sale.ticket_birthday_people[index]?.birth_date
                      ) && (
                        <small className="text-danger">
                          O ingresso selecionado para a data{" "}
                          {moment(sale.date).format("DD/MM/YYYY")} não está no
                          período de desconto do aniversariante.
                        </small>
                      )}
                  </Form.Group>
                )}
              </div>
            </div>
          ))}

        <div className="row">
          <label>Participantes menores de 5 anos?</label>
          <Select
            classNamePrefix="react-select"
            value={yesNo.find(
              (item) => item.value === (sale.kids_value === 1 ? true : false)
            )}
            onChange={(val) => handleHasKidsChange(val)}
            options={yesNo}
          />
        </div>
        {sale.kids_value > 0 && (
          <div className="row">
            <div className="col">
              <label>Ingressos Crianças</label>
              <Form.Control
                value={sale.minors}
                name={"minors"}
                type={"number"}
                onChange={handleChange}
              />
            </div>
            <div className="col">
              <label>Ingressos Acompanhantes</label>
              <Form.Control
                value={sale.minors_responsibles}
                name={"minors_responsibles"}
                type={"number"}
                onChange={handleChange}
              />
            </div>
          </div>
        )}

        {sale.minors > 0 && (
          <>
            {[...Array(sale.minors)].map((_, index) => (
              <div className="row">
                <div className="col" key={index}>
                  <label>CPF da criança {index + 1}:</label>
                  <Form.Control
                    onChange={(e) => {
                      handleCPFminorsChange(e, index);
                    }}
                    value={sale.cpf_minors[index] || ""}
                    name={`cpf_minors_${index}`}
                    maxLength={11}
                  />
                </div>
              </div>
            ))}
            <div className="row">
              <div className="col mt-2 text-center">
                <Button variant="primary" onClick={handleMinorsInfos}>
                  Consultar
                </Button>
              </div>
            </div>
          </>
        )}

        {sale.minors_infos.some((data) => !!data) &&
          [...Array(Number(sale.minors))].map((_, index) => (
            <div className="row" key={index} xs={12}>
              {loading[index] && (
                <p>Carregando informações, por favor aguarde um instante...</p>
              )}
              {sale.minors_infos[index] && (
                <Form.Group>
                  <label>{`Informações da criança ${index + 1}`}</label>
                  <Form.Control
                    readOnly
                    className={
                      calculateAge(sale.minors_infos[index]?.date) >= 5
                        ? "age-error"
                        : ""
                    }
                    value={`Nome: ${
                      sale.minors_infos[index]?.name || "Não encontrado"
                    }, Data de Nascimento: ${
                      sale.minors_infos[index]?.date || "Não encontrado"
                    }, Idade: ${
                      sale.minors_infos[index]?.date
                        ? calculateAge(sale.minors_infos[index]?.date)
                        : "Não encontrado"
                    }`}
                    type="text"
                  />

                  {calculateAge(sale.minors_infos[index]?.date) >= 5 && (
                    <p className="text-danger">
                      Os dados exibidos pertencem a uma pessoa maior de 5 anos.
                    </p>
                  )}
                </Form.Group>
              )}
            </div>
          ))}

        <div className="row">
          <label>Participantes PCD?</label>
          <Select
            classNamePrefix="react-select"
            value={yesNo.find(
              (item) => item.value === (sale.pcd_value === 1 ? true : false)
            )}
            onChange={handleHasPCDChange}
            options={yesNo}
            styles={selectStyles}
          />
        </div>
        {sale.pcd_value > 0 && (
          <>
            <div className="row">
              <div className="col">
                <label>Ingressos PCD:</label>
                <Form.Control
                  value={sale.dps}
                  name={"dps"}
                  type={"number"}
                  onChange={handleChange}
                  onPaste={(e) => e.preventDefault()}
                  onKeyDown={handleKeyPress}
                />
              </div>
              <div className="col">
                <label>Ingressos Acompanhantes:</label>
                <Form.Control
                  value={sale.dps_responsibles}
                  name={"dps_responsibles"}
                  type={"number"}
                  onChange={handleChange}
                />
              </div>
            </div>
            <TicketDP
              prevList={sale.ticket_disabled_people}
              onChange={handleTicketDPChange}
              totalNumberDps={sale.dps}
            />
          </>
        )}
        <div className="row">
          <label>Cortesias?</label>
          <Select
            classNamePrefix="react-select"
            value={yesNo.find((item) => item.value === hasCourtesy)}
            onChange={(val) => handleHasCourtesyChange(val)}
            options={yesNo}
            styles={selectStyles}
          />
        </div>
        {hasCourtesy && (
          <div className="row">
            <div className="col">
              <label>Cortesias Brincantes</label>
              <Form.Control
                value={sale.courtesy_players}
                name={"courtesy_players"}
                type={"number"}
                onChange={handleChange}
                style={{
                  border: sale.is_birthday ? `3px solid ${borderColor}` : "",
                }}
              />
            </div>
            <div className="col">
              <label>Cortesias Não Brincantes</label>
              <Form.Control
                value={sale.courtesy_not_players}
                name={"courtesy_not_players"}
                type={"number"}
                onChange={handleChange}
              />
            </div>
          </div>
        )}
        {(handleCancel || handleNext) && (
          <div className="d-flex gap-1 ml-auto">
            {handleCancel && (
              <Button variant="light" onClick={handleCancel}>
                Cancelar
              </Button>
            )}
            {handleNext && (
              <Button onClick={handleClickButtonNext}>Avançar</Button>
            )}
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default Participants;
