import { useContext, useEffect, useState } from "react";
import moment from "moment";
import Api from "../../../api/api";
import { FilialContext } from "../../../context/FilialContext";
import { toast } from "react-toastify";
import DailyInsightChart from "./DailyInsightChart";
import { ProgressBar } from "react-bootstrap";

const TransactionTotalPerDay = () => {
  const { selectedUnitId } = useContext(FilialContext);

  const [transactionTotalPerDayFilter, setTransactionTotalPerDayFilter] =
    useState({
      authorized_at_start_date: moment()
        .subtract(6, "days")
        .format("YYYY-MM-DD"),
      authorized_at_end_date: moment().format("YYYY-MM-DD"),
    });
  const [loadingTransactionTotalPerDay, setLoadingTransactionTotalPerDay] =
    useState(false);
  const [transactionTotalPerDay, setTransactionTotalPerDay] = useState([]);
  const [transactionTotalPerDayTotalizer, setTransactionTotalPerDayTotalizer] =
    useState(0);

  const getTransactionTotalPerDay = () => {
    setLoadingTransactionTotalPerDay(true);
    Api.get(
      `transactions/value_per_day?${new URLSearchParams({
        ...transactionTotalPerDayFilter,
        unit_id: selectedUnitId,
        ticket_type: "parties",
      })}`
    )
      .then((res) => {
        setTransactionTotalPerDay(res.data.list);
        setTransactionTotalPerDayTotalizer(res.data.total);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => setLoadingTransactionTotalPerDay(false));
  };

  useEffect(() => {
    if (selectedUnitId) getTransactionTotalPerDay();
  }, [transactionTotalPerDayFilter, selectedUnitId]);

  const handleDailyFilterChange = (e) => {
    setTransactionTotalPerDayFilter((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <>
      <h3>Totalizadores Diários</h3>
      <div className="d-flex gap-1 mb-1 flex-wrap align-items-center justify-content-center align-items-sm-start justify-content-sm-start">
        <div className="input-group w-auto flex-0">
          <span className="input-group-text">Início</span>
          <input
            value={
              transactionTotalPerDayFilter.authorized_at_start_date
                ? moment(
                    transactionTotalPerDayFilter.authorized_at_start_date
                  ).format("YYYY-MM-DD")
                : null
            }
            onChange={handleDailyFilterChange}
            name="authorized_at_start_date"
            type="date"
            className="form-control"
            placeholder="Data inicial"
          />
        </div>
        <div className="input-group w-auto flex-0">
          <span className="input-group-text">Fim</span>
          <input
            value={
              transactionTotalPerDayFilter.authorized_at_end_date
                ? moment(
                    transactionTotalPerDayFilter.authorized_at_end_date
                  ).format("YYYY-MM-DD")
                : null
            }
            onChange={handleDailyFilterChange}
            name="authorized_at_end_date"
            type="date"
            className="form-control"
            placeholder="Data final"
          />
        </div>
      </div>
      {loadingTransactionTotalPerDay && (
        <ProgressBar animated now={100} className="mb-1" />
      )}
      <DailyInsightChart data={transactionTotalPerDay} />
      <h4>{`Total: ${(transactionTotalPerDayTotalizer || 0).toLocaleString(
        "pt-br",
        {
          style: "currency",
          currency: "BRL",
        }
      )}`}</h4>
    </>
  );
};

export default TransactionTotalPerDay;
