import { v4 } from "uuid";
import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { HiTrash } from "react-icons/hi2";
import moment from "moment";
import { genderList } from "../../../../utils/functions";

const BirthdayPeople = ({ handleNext, handleCancel, sale, setSale }) => {
  const participants =
    Number(sale?.total_players || 0) + Number(sale?.total_not_players || 0);

  const renderBirthdayPerson = (birthdayPerson) => {
    const handleChange = (e) => {
      birthdayPerson[e.target.name] = e.target.value;
      if (e.target.name === "birth_date")
        birthdayPerson.age = Number(
          moment().diff(moment(e.target.value), "years")
        ).toFixed(0);
      setSale({ ...sale });
    };

    const handleRemove = () => {
      setSale((prev) => ({
        ...sale,
        ticket_birthday_people: prev.ticket_birthday_people.filter(
          (item) => item.id !== birthdayPerson.id
        ),
      }));
    };

    return (
      <Col xs={12} sm={6} key={`birthday_person_item_${birthdayPerson.id}`}>
        <div className="d-flex flex-column gap-1 border border-primary rounded-box p-1 pt-3">
          <Button
            variant="outline-danger"
            size="sm"
            className="rounded-circle position-absolute end-0 top-0 me-3 mt-1"
            onClick={handleRemove}
          >
            <HiTrash />
          </Button>
          <div className="d-flex flex-column gap-1">
            <span>Nome</span>
            <Form.Control
              value={birthdayPerson.name}
              name={"name"}
              type={"name"}
              onChange={handleChange}
            />
          </div>
          <div className="d-flex align-items-center gap-1">
            <div className="d-flex flex-column gap-1 w-100">
              <span>Data de Nascimento</span>
              <Form.Control
                value={moment(birthdayPerson.birth_date).format("YYYY-MM-DD")}
                name={"birth_date"}
                type={"date"}
                onChange={handleChange}
              />
            </div>
            <div className="d-flex flex-column gap-1">
              <span>Idade</span>
              <Form.Control
                value={birthdayPerson.age}
                name={"age"}
                type={"number"}
                min={1}
                disabled
              />
            </div>
          </div>
          <div className="d-flex flex-column gap-1">
            <span>Gênero</span>
            <Form.Select
              value={birthdayPerson.gender}
              name={"gender"}
              onChange={handleChange}
            >
              <option hidden>Clique aqui para selecionar</option>
              {genderList.map((g) => (
                <option key={`gender_item_${g.value}`} value={g.value}>
                  {g.label}
                </option>
              ))}
            </Form.Select>
          </div>
        </div>
      </Col>
    );
  };

  const renderBirthdayPeople = () => {
    if (!sale || !sale.ticket_birthday_people) return;

    return sale.ticket_birthday_people.map((bp) => renderBirthdayPerson(bp));
  };

  const handleAddBirthdayPerson = () => {
    setSale((prev) => ({
      ...prev,
      ticket_birthday_people: [
        ...prev.ticket_birthday_people,
        { id: v4(), name: "", age: 1 },
      ],
    }));
  };

  const canAddBP = sale?.ticket_birthday_people.length < participants;

  return (
    <div className="d-flex flex-column gap-1 bg-white rounded p-1">
      <Row className="g-4">
        {renderBirthdayPeople()}
        <Col xs={12}>
          <Button
            onClick={handleAddBirthdayPerson}
            variant="outline-primary"
            className="w-100"
            disabled={!canAddBP}
          >
            Adicionar aniversariante
          </Button>
        </Col>
      </Row>
      {(handleCancel || handleNext) && (
        <div className="d-flex gap-1 ml-auto">
          {handleCancel && (
            <Button variant="light" onClick={handleCancel}>
              Cancelar
            </Button>
          )}
          {handleNext && <Button onClick={handleNext}>Avançar</Button>}
        </div>
      )}
    </div>
  );
};

export default BirthdayPeople;
