import React from "react";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import { BsFillTrashFill } from "react-icons/bs";

const Row = ({
  item,
  setSelectedItem,
  selectedItem,
  handleRemove,
  loadRemove,
}) => {
  const handleItemClick = () => {
    setSelectedItem(item);
  };

  const handleRemoveItem = (e) => {
    e.stopPropagation();
    handleRemove(item);
  };

  return (
    <ListGroup.Item
      active={item.id === selectedItem?.id}
      action
      onClick={handleItemClick}
      style={{ display: "flex", alignItems: "center" }}
    >
      {item.name}
      <Button
        size="sm"
        variant="danger"
        style={{ marginLeft: "auto" }}
        onClick={handleRemoveItem}
        disabled={loadRemove === item.id}
      >
        <BsFillTrashFill />
      </Button>
    </ListGroup.Item>
  );
};

export default Row;
