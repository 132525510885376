import { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormLabel,
  InputGroup,
  Modal,
  ProgressBar,
} from "react-bootstrap";
import { toast } from "react-toastify";
import Api from "../../../api/api";
import Select from "react-select";
import TicketDP from "../../NewSalesTicket/TicketDP";
import moment from "moment";
import EditSaleProductsModal from "./EditSaleProductsModal";
import { HiPencilSquare, HiTrash } from "react-icons/hi2";
import SaleUpdateLogModal from "../../../components/SaleUpdateLogModal";
import SaleBirthdayPeople from "../../ManagerParty2/EditSaleModal/BirthdayPeople";
import { getCPFCNPJData } from "../../../utils/cpfcnpjValidation";
import RescheduleModal from "./RescheduleModal";
import UpdateAuthorizationModal from "./UpdateAuthorizationModal";
import { getPaymentMethods, paymentMethods } from "../../../utils/paymentType";
//import SaleBirthdayPeople from "../../ManagerParty2/EditSaleModal/BirthdayPeople";

const selectStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: "2rem",
    borderBottomRightRadius: "2rem",
  }),
  container: (baseStyles, state) => ({
    ...baseStyles,
    alignSelf: "stretch",
    flex: 1,
  }),
};

const EditSaleModal = ({ open, setOpen, code, onSuccess }) => {
  const [sale, setSale] = useState({});
  const [loadingData, setLoadingData] = useState(false);
  const [hasCourtesy, setHasCourtesy] = useState(
    sale.courtesy_players > 0 || sale.courtesy_not_players > 0 || false
  );
  const [loadingTickets, setLoadingTickets] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [loadHours, setLoadHours] = useState(false);
  const [hours, setHours] = useState([]);
  const [prevPlayers, setPrevPlayers] = useState(0);
  const [prevNotPlayers, setPrevNotPlayers] = useState(0);
  const [loadingSave, setLoadingSave] = useState(false);
  const [openEditSaleProductsModal, setOpenEditSaleProductsModal] =
    useState(false);
  const [loadingRemoveProduct, setLoadingRemoveProduct] = useState(false);
  const [openUpdateLog, setOpenUpdateLog] = useState(false);
  const [loadingRemoveConfirmedStatus, setLoadingRemoveConfirmedStatus] =
    useState(false);
  const [ticketBirthdayPeopleCount, setTicketBirthdayPeopleCount] = useState(0);
  const [borderColor, setBorderColor] = useState();

  const birthdayDiscount =
    ticketBirthdayPeopleCount * Number(sale.player_price || 0);

  const showBirthdayDiscount = birthdayDiscount > 0;

  const [userDatas, setUserDatas] = useState(
    Array.from({ length: Number(sale.minors) }, () => null)
  );
  const [loading, setLoading] = useState(
    Array.from({ length: Number(sale.minors) }, () => false)
  );

  const [consultedCPFs, setConsultedCPFs] = useState([]);
  const [cpfInputs, setCpfInputs] = useState([]);
  const [previousMinors, setPreviousMinors] = useState(sale.minors);

  const [loadingCouponData, setLoadingCouponData] = useState(false);
  const [couponData, setCouponData] = useState(null);
  const [prevSale, setPrevSale] = useState(null);
  const [openRescheduleModal, setOpenRescheduleModal] = useState(false);
  const [openUpdateAuthorizationModal, setOpenUpdateAuthorizationModal] =
    useState(null);

  const handleSelectChange = (e, field) => {
    setSale((prev) => ({
      ...prev,
      [field]: field === "park_areas_ids" ? e : e.value,
    }));
  };

  const getTickets = () => {
    setLoadingTickets(true);
    Api.get("tickets", {
      params: {
        unit_id: sale.unit_id,
        type: "ticket",
        date: sale.date ? moment(sale.date).format("YYYY-MM-DD") : null,
        areas_amount: sale.park_areas_ids?.length,
      },
    })
      .then((res) => {
        setTickets(res.data.list);

        if (
          !res.data.list.find(
            (ticket) => Number(ticket.id) === Number(sale.ticket_id)
          )
        )
          setSale((prev) => ({
            ...prev,
            areas_amount: 0,
            player_price: 0,
            not_player_price: 0,
            minor_price: 0,
            minor_responsible_price: 0,
            dp_price: 0,
            dp_responsible_price: 0,
            additional_hour_price: 0,
            ticket_id: null,
            duration: 0,
          }));
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingTickets(false));
  };

  useEffect(() => {
    const minorsDifference = sale.minors - previousMinors;

    if (minorsDifference > 0) {
      const newInputs = Array(minorsDifference).fill("");
      setCpfInputs((prevInputs) => [...prevInputs, ...newInputs]);
    } else if (minorsDifference < 0) {
      setCpfInputs((prevInputs) => prevInputs.slice(0, minorsDifference));
    }

    setPreviousMinors(sale.minors);
  }, [sale.minors, previousMinors]);

  const handleCpf = (e, index) => {
    const newCpfInputs = [...cpfInputs];
    newCpfInputs[index] = e.target.value;
    setCpfInputs(newCpfInputs);
  };

  useEffect(() => {
    if (sale?.unit_id && !loadHours) getTickets();
  }, [sale?.unit_id, sale?.date, sale?.park_areas_ids]);

  const getData = () => {
    setLoadingData(true);
    Api.get(`orders/code/${code}/editing`)
      .then((res) => {
        setSale(res.data.data);
        setHasCourtesy(
          res.data.data.courtesy_players > 0 ||
            res.data.data.courtesy_not_players > 0
            ? true
            : false
        );
        setPrevPlayers(sumAllPlayers(res.data.data));
        setPrevNotPlayers(sumAllNotPlayers(res.data.data));
        setPrevSale(res.data.data);
      })
      .catch((err) => {
        handleClose();
        toast.error(err.message);
      })
      .finally(() => setLoadingData(false));
  };

  useEffect(() => {
    if (code && open) getData();
  }, [code, open]);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const newTicketBirthdayPeopleCount = sale.ticket_birthday_people
      ? sale.ticket_birthday_people.length
      : 0;
    setTicketBirthdayPeopleCount(newTicketBirthdayPeopleCount);
  }, [sale]);

  useEffect(() => {
    if (sale.ticket_birthday_people) {
      const newBirthdayPeopleCount = sale.ticket_birthday_people.length;
      setTicketBirthdayPeopleCount(newBirthdayPeopleCount);

      if (newBirthdayPeopleCount > sale.courtesy_players) {
        setSale((prevSale) => ({
          ...prevSale,
          courtesy_players: newBirthdayPeopleCount,
        }));
        setBorderColor("#fdc028");
      } else if (newBirthdayPeopleCount < sale.courtesy_players) {
        setSale((prevSale) => ({
          ...prevSale,
          courtesy_players: newBirthdayPeopleCount,
        }));
        setBorderColor("#fdc028");
      } else {
        setBorderColor();
      }
    }
  }, [sale.ticket_birthday_people]);

  const handleHasKidsChange = (item) => {
    const kidsValue = item.value ? 1 : 0;
    setSale((prev) => ({ ...prev, kids_value: kidsValue }));

    if (!item.value) {
      setSale((prev) => ({
        ...prev,
        minors: 0,
        minors_responsibles: 0,
      }));
    } else {
      setSale((prev) => ({
        ...prev,
        minors: 1,
        minors_responsibles: 1,
      }));
    }
  };
  const handleHasPCDChange = (item) => {
    setSale((prev) => ({ ...prev, dps: item.value ? 1 : 0 }));
    if (!item.value) {
      setSale((prev) => ({
        ...prev,
        dps: 0,
        dps_responsibles: 0,
      }));
    }
  };

  const yesNo = [
    { value: false, label: "Não" },
    { value: true, label: "Sim" },
  ];

  const handleTicketDPChange = (ticket_disabled_people) => {
    setSale((prev) => ({ ...prev, ticket_disabled_people }));
  };

  const handleHasCourtesyChange = (item) => {
    setHasCourtesy(item.value);
    if (!item.value)
      setSale((prev) => ({
        ...prev,
        courtesy_players: 0,
        courtesy_not_players: 0,
      }));
  };

  const sumAllPlayers = (prev) => {
    return Number(
      (Number((prev || sale).players) || 0) +
        (Number((prev || sale).minors_responsibles) || 0) +
        (Number((prev || sale).minors) || 0) +
        (Number((prev || sale).dps) || 0) +
        (Number((prev || sale).dps_responsibles) || 0) +
        (Number((prev || sale).courtesy_players) || 0) +
        ticketBirthdayPeopleCount
    );
  };

  const sumAllNotPlayers = (prev) => {
    return Number(
      (Number((prev || sale).not_players) || 0) +
        (Number((prev || sale).courtesy_not_players) || 0)
    );
  };

  useEffect(() => {
    const getHours = () => {
      let players = sumAllPlayers() - prevPlayers;
      if (players < 0) players = 0;

      setLoadHours(true);
      Api.get(`/calendar_dates_hours/available`, {
        params: {
          date: sale.date,
          duration: sale.duration,
          unit_id: sale.unit_id,
          players,
          type: sale.type,
          editing: true,
        },
      })
        .then((res) => {
          setHours(res?.data.hours);
        })
        .catch((err) => {
          toast.error(err.message);
          setHours([]);
        })
        .finally(() => {
          setLoadHours(false);
        });
    };

    if (
      (prevPlayers >= 0 || prevNotPlayers >= 0) &&
      (sumAllPlayers() > 0 || sumAllNotPlayers() > 0) &&
      sale.date &&
      sale.unit_id &&
      sale.ticket_id > 0 &&
      sale.duration > 0 &&
      sale.type &&
      !loadingTickets &&
      tickets &&
      tickets[0]
    )
      getHours();
  }, [
    sale.unit_id,
    sale.duration,
    sale.ticket_id,
    sumAllPlayers(),
    sale.type,
    prevPlayers,
    prevNotPlayers,
    sumAllNotPlayers(),
    loadingTickets,
    tickets,
  ]);

  const getHourSuffix = (item) => {
    if (!item || !item.available_spaces) return "Esgotado";

    let suffix = "";

    if (Number(item.available_spaces) <= 15)
      suffix = `${item.available_spaces} vaga${
        item.available_spaces > 1 ? "s" : ""
      } disponíveis`;
    else if (Number(item.available_spaces) <= Number(item.total_spaces) / 2)
      suffix = "Últimas vagas";
    else suffix = "Disponível";

    return suffix;
  };

  const renderHoursOptions = () => {
    return [
      <option>Select...</option>,
      hours.map((item, index) => {
        return (
          <option key={`time_${item.time}`} value={item.time}>
            {`${moment(item.time, "HH:mm:SS").format("HH:mm")} ${getHourSuffix(
              item
            )}`}
          </option>
        );
      }),
    ];
  };

  useEffect(() => {
    const selectedTicket = tickets.find(
      (ticket) => Number(ticket.id) === Number(sale.ticket_id)
    );

    if (selectedTicket)
      setSale((prev) => ({
        ...prev,
        areas_amount: selectedTicket?.areas_amount,
        player_price: selectedTicket?.player_price,
        not_player_price: selectedTicket?.not_player_price,
        minor_price: selectedTicket?.minor_price,
        minor_responsible_price: selectedTicket?.minor_responsible_price,
        dp_price: selectedTicket?.dp_price,
        dp_responsible_price: selectedTicket?.dp_responsible_price,
        additional_hour_price: selectedTicket?.additional_hour_price,
        ticket_id: selectedTicket?.id,
        duration: selectedTicket?.duration,
      }));
  }, [sale.ticket_id]);

  const handleButtonClick = async () => {
    setLoading((prevLoading) => prevLoading.map(() => true));
    try {
      const uniqueCPFValues = [...new Set(cpfInputs)];
      if (uniqueCPFValues.length !== cpfInputs.length) {
        toast.error(`Não são permitidos CPFs duplicados`);
        return;
      }

      for (let i = 0; i < cpfInputs.length; i++) {
        const cpfValue = cpfInputs[i];

        if (consultedCPFs[cpfInputs]) {
          toast.warning(`O CPF ${cpfInputs} já foi consultado anteriormente`);
          setUserDatas((prevUserDatas) => {
            const updatedUserDatas = [...prevUserDatas];
            updatedUserDatas[i] = { ...consultedCPFs[cpfValue] };
            return updatedUserDatas;
          });
          continue;
        }

        if (cpfValue.length === 0) {
          toast.error(`Campo de CPF da criança ${i + 1} deve ser preenchido`);
          continue;
        }

        if (cpfValue.length !== 11) {
          toast.error(
            `CPF da criança ${i + 1} deve conter exatamente 11 dígitos`
          );
          continue;
        }

        const response = await getCPFCNPJData(cpfValue);
        if (response instanceof Error) {
          throw response;
        }

        if (response && response.nascimento) {
          setConsultedCPFs((prevConsultedCPFs) => ({
            ...prevConsultedCPFs,
            [cpfValue]: response,
          }));

          setUserDatas((prevUserDatas) => {
            const updatedUserDatas = [...prevUserDatas];
            updatedUserDatas[i] = { ...response };
            return updatedUserDatas;
          });

          const age = calculateAge(response.nascimento);
        }
      }
    } catch (error) {
      console.log("Error", error);
      toast.error(
        error.message ||
          `Você excedeu o número máximo de tentativas. Tente novamente em alguns minutos.`
      );
      setUserDatas((prevUserDatas) => prevUserDatas.map(() => null));
    } finally {
      setLoading((prevLoading) => prevLoading.map(() => false));
    }
  };

  function calculateAge(nascimento) {
    const birthDate = moment(nascimento, "DD/MM/YYYY");
    const today = moment();
    let age = today.diff(birthDate, "years");
    return age;
  }

  const ticketsValue =
    Number(sale.player_price || 0) * Number(sale.players || 0) +
    Number(sale.not_player_price || 0) * Number(sale.not_players || 0) +
    Number(sale.minor_price || 0) * Number(sale.minors || 0) +
    Number(sale.dp_price || 0) * Number(sale.dps || 0) +
    Number(sale.dp_responsible_price || 0) *
      Number(sale.dps_responsibles || 0) +
    birthdayDiscount;

  const productsValue = Number(sale?.products_value || 0);

  const couponDiscountRate = Number(
    sale?.coupon?.discount_type === "PERCENTAGE"
      ? sale.coupon?.discount_value / 100
      : 0
  );

  const couponProductsDiscount =
    couponDiscountRate > 0 && sale.coupon.products && sale.coupon_id > 0
      ? productsValue * couponDiscountRate
      : 0;

  let couponTicketsDiscount =
    couponDiscountRate > 0 && sale.coupon.tickets && sale.coupon_id > 0
      ? ticketsValue * couponDiscountRate
      : 0;

  let couponDiscountValue = Number(
    (couponTicketsDiscount + couponProductsDiscount).toFixed(2)
  );
  if (
    sale?.coupon?.discount_max_value &&
    couponDiscountValue > Number(sale.coupon.discount_max_value)
  ) {
    couponTicketsDiscount =
      Number(sale?.coupon?.discount_max_value) - couponProductsDiscount;
    couponDiscountValue = Number(sale?.coupon?.discount_max_value);
  }

  const ticketTotal = Number(
    (
      productsValue +
      ticketsValue -
      couponDiscountValue -
      Number(sale.points_discount_value) -
      birthdayDiscount
    ).toFixed(2)
  );

  let promotionDiscountValue =
    sale?.promotion?.discount_type === "FIXED"
      ? Number(sale.promotion.discount_value)
      : sale?.promotion?.discount_type === "PERCENTAGE"
      ? ticketTotal * (Number(sale.promotion.discount_value) / 100)
      : 0;

  if (
    sale?.promotion?.discount_max_value &&
    promotionDiscountValue > Number(sale.promotion.discount_max_value)
  )
    promotionDiscountValue = sale?.promotion?.discount_max_value;

  const totalWithPromoDiscount = Number(
    (ticketTotal - promotionDiscountValue).toFixed(2)
  );

  const total =
    totalWithPromoDiscount -
    (sale?.coupon_id ? 0 : Number(sale?.dash_discount_value || 0));

  const previousTotal = Number((prevSale?.value || 0).toFixed(2));

  const differenceTotal = total - previousTotal;

  const differenceTotalClassName =
    differenceTotal > 0
      ? "text-success"
      : differenceTotal === 0
      ? "text-muted"
      : "text-danger";

  const isOnlineSale = [true, "true"].includes(sale?.online_sale);

  const handleSave = () => {
    if (isOnlineSale && (!(sale.ticket_id > 0) || !(sale.duration > 0)))
      return toast.warning(
        "Você deve selecionar a duração do ingresso para atualizar a venda!"
      );

    if (
      isOnlineSale &&
      ![true, "true", false, "false"].includes(sale.points_sent) &&
      differenceTotal < 0
    )
      return toast.warning(
        "Favor selecione se você enviou PopPontos da diferença!"
      );

    const hasChildrenOverFive = userDatas.some(
      (userData) => userData && calculateAge(userData?.nascimento) >= 5
    );

    if (
      sale.players < ticketBirthdayPeopleCount ||
      sale.courtesy_players < ticketBirthdayPeopleCount
    )
      return toast.warning(
        "A quantidade de brincantes ou cortesias deve ser maior ou igual à quantidade de aniversariantes."
      );

    if (hasChildrenOverFive)
      return toast.warning(
        "Não é possível salvar porque há crianças maiores de 5 anos."
      );

    if (differenceTotal > 0 && !sale?.payment_method)
      return toast.warning(
        "Você deve selecionar a forma de pagamento para o custo adicional!"
      );

    const updateData = {
      ...sale,
      ticket_total: total >= 0 ? total : 0,
      value: total >= 0 ? total : 0,
      tickets_value: ticketsValue,
      coupon_discount_value:
        sale?.coupon_id > 0 ? couponDiscountValue : sale.dash_discount_value,
      promotion_discount_value: promotionDiscountValue,
    };

    if (isOnlineSale) return setOpenUpdateAuthorizationModal(updateData);

    setLoadingSave(true);
    Api.put(`orders/code/${code}/editing`, updateData)
      .then(() => {
        toast.success("Ticket atualizado com sucesso!");
        handleClose();
        if (onSuccess) onSuccess();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingSave(false));
  };

  const renderSaleProduct = (product) => {
    if (!product || !product.id) return <></>;

    const handleRemove = () => {
      if (window.confirm("Deseja realmente remover este produto da venda?")) {
        setLoadingRemoveProduct(true);
        Api.delete(`/orders/${sale.id}/remove_product/${product.id}`)
          .then((res) => {
            if (res.data.message) toast.success(res.data.message);
            getData();
            if (onSuccess) onSuccess();
          })
          .catch((err) => toast.error(err.message))
          .finally(() => setLoadingRemoveProduct(false));
      }
    };

    return (
      <div className="border border-primary rounded p-1 px-2 d-flex align-items-center justify-content-between">
        <span className="fw-bold">{`${product.name} x${
          product.charge_type === "participants"
            ? Number(sale?.total_players || 0) +
              Number(sale?.total_not_players || 0)
            : product.amount
        }`}</span>
        <div className="d-flex align-items-center gap-3">
          <span className="fw-bold">
            {product.total_value.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })}
          </span>
          {!product.gateway_transaction && !product.coupon_id && (
            <Button
              variant="danger"
              size="sm"
              className="rounded-circle"
              onClick={handleRemove}
              disabled={loadingRemoveProduct}
            >
              <HiTrash />
            </Button>
          )}
        </div>
      </div>
    );
  };

  const renderSaleProducts = () => {
    if (!sale || !sale.products || !sale.products[0])
      return <span className="text-muted">Nenhum produto na venda.</span>;

    return (
      <div className="d-flex flex-column gap-1">
        {sale.products.map((product) => renderSaleProduct(product))}
      </div>
    );
  };

  const handleOpenAddProductModal = () => {
    setOpenEditSaleProductsModal(true);
  };

  const onProductsEditted = () => {
    setOpenEditSaleProductsModal(false);
    getData();
    if (onSuccess) onSuccess();
  };

  const handleOpenUpdateLog = () => {
    setOpenUpdateLog(true);
  };

  const handleRemoveConfirmedStatus = () => {
    if (window.confirm("Deseja realmente desconfirmar este ingresso?")) {
      setLoadingRemoveConfirmedStatus(true);
      Api.post(`orders_tickets/remove/confirmed/status/${sale.order_ticket_id}`)
        .then((res) => {
          setSale((prev) => ({
            ...prev,
            order_ticket_status: res.data.status,
          }));
          toast.success("Status alterado com sucesso!");
          if (onSuccess) onSuccess();
        })
        .catch((err) => toast.error(err.message))
        .finally(() => setLoadingRemoveConfirmedStatus(false));
    }
  };

  const loadingLocked = loadingSave || loadingRemoveConfirmedStatus;

  const onDataUpdate = () => {
    getData();
    if (onSuccess) onSuccess();
  };

  const getCouponByCode = () => {
    setLoadingCouponData(true);
    Api.post(`coupons/use`, {
      ticket_value: ticketsValue,
      products_value: productsValue,
      code: sale?.coupon?.code,
      date: sale?.date,
      filial: sale?.unit_var_name,
      ticket_time: sale?.time,
      cpf: sale?.user_cpf,
      total_value: totalWithPromoDiscount + couponDiscountValue,
      ticket_id: sale?.ticket_id,
      promotion_id: sale?.promotion_id,
      editing: true,
    })
      .then((res) => {
        setCouponData(res.data.data);
        setSale((prev) => ({
          ...prev,
          coupon_id: res.data.data.coupon_id,
        }));
      })
      .catch((err) => {
        toast.error(err.message);
        setCouponData(null);
        setSale((prev) => ({
          ...prev,
          coupon_id: null,
          coupon_discount_value: 0,
          value: Number(prev.value) + Number(prev.coupon_discount_value),
        }));
      })
      .finally(() => setLoadingCouponData(false));
  };

  useEffect(() => {
    if (sale?.coupon?.code && sale?.ticket_id > 0) getCouponByCode();
  }, [
    sale?.coupon?.code,
    sale?.date,
    sale?.time,
    sumAllPlayers(),
    sumAllNotPlayers(),
    sale?.ticket_id,
  ]);

  const handleChange = (e) => {
    if (e.target.name === "dash_discount_rate") {
      if (e.target.value > 100) e.target.value = 100;

      return setSale((prev) => {
        const discountValue =
          (ticketsValue + productsValue) * (Number(e.target.value) / 100);

        return {
          ...prev,
          dash_discount_value: discountValue,
          dash_discount_rate: e.target.value,
        };
      });
    }

    if (e.target.name === "dash_discount_value") {
      if (e.target.value < 0) e.target.value = 0;
      if (e.target.value > ticketsValue + productsValue)
        e.target.value = ticketsValue + productsValue;

      const discountRate = Number(
        (Number(e.target.value || 0) * 100) / (ticketsValue + productsValue)
      );

      return setSale((prev) => ({
        ...prev,
        dash_discount_value: e.target.value,
        dash_discount_rate: discountRate,
      }));
    }

    if (e.target.name === "date") {
      if (moment("2100-12-31").isBefore(moment(e.target.value)))
        e.target.value = moment().format("YYYY-MM-DD");
      if (moment("1900-01-01").isAfter(moment(e.target.value)))
        e.target.value = moment().format("YYYY-MM-DD");
    }

    if (e.target.type === "number" && e.target.value < 0) e.target.value = 0;

    if (
      e.target.name === "minors_responsibles" &&
      Number(e.target.value) > sale.minors
    )
      e.target.value = sale.minors;

    if (
      e.target.name === "dps_responsibles" &&
      Number(e.target.value) > sale.dps
    )
      e.target.value = sale.dps;

    setSale((prev) => ({
      ...prev,
      [e.target.name]:
        e.target.type === "number" ? Number(e.target.value) : e.target.value,
    }));
  };

  const handleOpenRescheduleModal = () => {
    setOpenRescheduleModal(true);
  };

  const onUpdateAuthorizedEditted = () => {
    setOpenEditSaleProductsModal(false);
    getData();
    if (onSuccess) onSuccess();
  };

  return (
    <Modal show={open} onHide={handleClose} size="lg">
      {openUpdateAuthorizationModal && (
        <UpdateAuthorizationModal
          open={openUpdateAuthorizationModal ? true : false}
          setOpen={setOpenUpdateAuthorizationModal}
          updateData={openUpdateAuthorizationModal}
          onSuccess={onUpdateAuthorizedEditted}
        />
      )}
      {openUpdateLog && (
        <SaleUpdateLogModal
          open={openUpdateLog}
          setOpen={setOpenUpdateLog}
          order_id={sale?.id}
        />
      )}
      {openEditSaleProductsModal && (
        <EditSaleProductsModal
          open={openEditSaleProductsModal}
          setOpen={setOpenEditSaleProductsModal}
          unit_id={sale?.unit_id}
          onSuccess={onProductsEditted}
          order_id={sale?.id}
          participants={
            Number(sale?.total_players || 0) +
            Number(sale?.total_not_players || 0)
          }
          currentProducts={sale?.products}
          ticketType={sale?.type}
        />
      )}
      {openRescheduleModal && (
        <RescheduleModal
          open={openRescheduleModal}
          setOpen={setOpenRescheduleModal}
          order_ticket_id={sale?.order_ticket_id}
        />
      )}
      <Modal.Header closeButton>
        <Modal.Title>
          Editar dados da venda {isOnlineSale ? "online" : "local"} ID{" "}
          {sale?.code?.split("-")[0]}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loadingData && <ProgressBar animated now={100} className="mb-1" />}
        <div className="d-flex flex-column gap-1">
          <span className="fs-5 text-primary">
            Dados do ingresso e participantes
          </span>
          <div className="row">
            <div className="col">
              <label>Data:</label>
              <Form.Control
                value={moment(sale.date).format("YYYY-MM-DD")}
                name={"date"}
                type={"date"}
                onChange={handleChange}
                disabled={loadingTickets || loadHours}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label>Ingressos Brincantes:</label>
              <Form.Control
                value={sale.players}
                name={"players"}
                type={"number"}
                onChange={handleChange}
              />
            </div>
            <div className="col">
              <label>Ingressos Não Brincantes:</label>
              <Form.Control
                value={sale.not_players}
                name={"not_players"}
                type={"number"}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label>Participantes menores de 5 anos?</label>
              <Select
                classNamePrefix="react-select"
                value={yesNo.find(
                  (item) => item.value === (sale.minors > 0 ? true : false)
                )}
                onChange={(val) => handleHasKidsChange(val)}
                options={yesNo}
                styles={selectStyles}
              />
            </div>
          </div>
          {sale.minors > 0 && (
            <div className="row">
              <div className="col">
                <label>Ingressos Crianças:</label>
                <Form.Control
                  value={sale.minors}
                  name={"minors"}
                  type={"number"}
                  onChange={handleChange}
                />
              </div>
              <div className="col">
                <label>Ingressos Acompanhantes:</label>
                <Form.Control
                  value={sale.minors_responsibles}
                  name={"minors_responsibles"}
                  type={"number"}
                  onChange={handleChange}
                />
              </div>
            </div>
          )}
          {cpfInputs.length > 0 && (
            <>
              {cpfInputs.map((value, index) => (
                <div className="row" key={index}>
                  <div className="col">
                    <label>CPF da criança:</label>
                    <Form.Control
                      name={`cpf_${index}`}
                      onChange={(e) => handleCpf(e, index)}
                      value={value}
                      type="text"
                      placeholder="Digite o CPF da criança"
                      maxLength={11}
                    />
                  </div>
                </div>
              ))}

              <div className="row">
                <div className="col mt-2 text-center">
                  <Button variant="primary" onClick={handleButtonClick}>
                    Consultar
                  </Button>
                </div>
              </div>
            </>
          )}

          {userDatas.some((data) => !!data) &&
            [...Array(Number(sale.minors))].map((_, index) => (
              <div className="row">
                <div className="col" key={index} xs={12}>
                  {loading[index] && (
                    <p>
                      Carregando informações, por favor aguarde um instante...
                    </p>
                  )}
                  {cpfInputs[index]?.length === 11 && (
                    <Form.Group>
                      <label>Informações da criança: </label>
                      <Form.Control
                        readOnly
                        className={
                          userDatas[index] &&
                          calculateAge(userDatas[index]?.nascimento) >= 5
                            ? "age-error"
                            : ""
                        }
                        value={`Nome: ${
                          userDatas[index]?.nome || "Não encontrado"
                        }, Data de Nascimento: ${
                          userDatas[index]?.nascimento || "Não encontrado"
                        }, Idade: ${
                          userDatas[index]?.nascimento
                            ? calculateAge(userDatas[index]?.nascimento)
                            : "Não encontrado"
                        }`}
                        type="text"
                      />

                      {userDatas[index] &&
                        calculateAge(userDatas[index]?.nascimento) >= 5 && (
                          <small className="text-danger">
                            Os dados exibidos pertencem a uma pessoa maior de 5
                            anos.
                          </small>
                        )}
                    </Form.Group>
                  )}
                </div>
              </div>
            ))}

          <div className="row">
            <label>Participantes PCD?</label>
            <Select
              classNamePrefix="react-select"
              value={yesNo.find(
                (item) => item.value === (sale.dps > 0 ? true : false)
              )}
              onChange={handleHasPCDChange}
              options={yesNo}
              styles={selectStyles}
            />
          </div>
          {sale.dps > 0 && (
            <>
              <div className="row">
                <div className="col">
                  <label>Ingressos PCD:</label>
                  <Form.Control
                    value={sale.dps}
                    name={"dps"}
                    type={"number"}
                    onChange={handleChange}
                  />
                </div>
                <div className="col">
                  <label>Ingressos Acompanhantes:</label>
                  <Form.Control
                    value={sale.dps_responsibles}
                    name={"dps_responsibles"}
                    type={"number"}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <FormLabel style={{ margin: 0, padding: 0 }}>
                Deficiências
              </FormLabel>
              <TicketDP
                prevList={sale.ticket_disabled_people}
                onChange={handleTicketDPChange}
                totalNumberDps={sale.dps}
              />
            </>
          )}
          <div className="row">
            <label>Cortesias?</label>
            <Select
              classNamePrefix="react-select"
              value={yesNo.find((item) => item.value === hasCourtesy)}
              onChange={(val) => handleHasCourtesyChange(val)}
              options={yesNo}
              styles={selectStyles}
            />
          </div>
          {hasCourtesy && (
            <div className="row">
              <div className="col">
                <label>Cortesias Brincantes:</label>
                <Form.Control
                  value={sale.courtesy_players}
                  name={"courtesy_players"}
                  type={"number"}
                  onChange={handleChange}
                  style={{ borderColor: borderColor }}
                />
              </div>
              <div className="col">
                <label>Cortesias Não Brincantes:</label>
                <Form.Control
                  value={sale.courtesy_not_players}
                  name={"courtesy_not_players"}
                  type={"number"}
                  onChange={handleChange}
                />
              </div>
            </div>
          )}
          <div className="row">
            <div className="col">
              <label>Duração:</label>
              <Select
                classNamePrefix="react-select"
                value={
                  sale.duration > 0
                    ? {
                        value: sale.duration,
                        label: `${sale.duration}hr${
                          sale?.duration > 1 ? "s" : ""
                        } de diversão (${sale?.player_price.toLocaleString(
                          "pt-br",
                          {
                            style: "currency",
                            currency: "BRL",
                          }
                        )})`,
                      }
                    : { label: "Selecione a duração do cliente no parque" }
                }
                onChange={(val) => handleSelectChange(val, "ticket_id")}
                options={tickets.map((ticket) => ({
                  label: `${ticket.duration}hr${
                    ticket.duration > 1 ? "s" : ""
                  } de diversão (${ticket.player_price.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })})`,
                  value: ticket.id,
                }))}
                isDisabled={loadingTickets || loadHours}
                styles={selectStyles}
              />
            </div>
            {sale.duration > 0 && (
              <div className="col">
                <label>Horário:</label>
                <select
                  className={`ticket-schedule form-select `}
                  name="time"
                  onChange={handleChange}
                  disabled={loadHours || loadingTickets}
                  value={sale.time}
                >
                  {renderHoursOptions()}
                </select>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col">
              <label>Informações Adicionais:</label>
              <Form.Control
                as="textarea"
                value={sale.additional_info}
                name={"additional_info"}
                onChange={handleChange}
              />
            </div>
          </div>
          {(differenceTotal !== 0 ||
            sale?.coupon_discount_value !==
              prevSale?.coupon_discount_value) && (
            <>
              <span>{`Valor em tickets: ${Number(
                prevSale?.tickets_value
              ).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })}`}</span>
              {showBirthdayDiscount && (
                <span>{`Desconto aniversariante: -${Number(
                  birthdayDiscount
                ).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}`}</span>
              )}
              <span>{`Novo valor em tickets: ${ticketsValue.toLocaleString(
                "pt-br",
                {
                  style: "currency",
                  currency: "BRL",
                }
              )}`}</span>
              <span>{`Novo subtotal: ${(
                ticketsValue + productsValue
              ).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })}`}</span>
              {prevSale?.points_discount_value > 0 && (
                <span>{`Desconto Pop Pontos: ${prevSale.points_discount_value.toLocaleString(
                  "pt-br",
                  {
                    style: "currency",
                    currency: "BRL",
                  }
                )}`}</span>
              )}
              {prevSale?.coupon_discount_value > 0 && (
                <span>{`Desconto do cupom: ${Number(
                  prevSale?.coupon_discount_value || 0
                ).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}`}</span>
              )}
              {couponDiscountValue !==
                Number(prevSale?.coupon_discount_value) && (
                <span>{`Novo desconto do cupom: ${couponDiscountValue.toLocaleString(
                  "pt-br",
                  {
                    style: "currency",
                    currency: "BRL",
                  }
                )}`}</span>
              )}
              {promotionDiscountValue > 0 && (
                <span>{`Novo desconto da promo: ${promotionDiscountValue.toLocaleString(
                  "pt-br",
                  {
                    style: "currency",
                    currency: "BRL",
                  }
                )}`}</span>
              )}
              {!sale?.coupon_id && (
                <div className="row">
                  <div className="col">
                    <label>Desconto porcentagem:</label>
                    <Form.Control
                      value={sale?.dash_discount_rate || 0}
                      name={"dash_discount_rate"}
                      type={"number"}
                      step={1}
                      min={0}
                      max={100}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col">
                    <label>Desconto valor fixo:</label>
                    <Form.Control
                      value={sale?.dash_discount_value || 0}
                      name={"dash_discount_value"}
                      type={"number"}
                      min={0}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              )}
              <span>{`Novo valor total da venda: ${total.toLocaleString(
                "pt-br",
                {
                  style: "currency",
                  currency: "BRL",
                }
              )}`}</span>
              <span className={`fw-bold ${differenceTotalClassName}`}>{`${
                differenceTotal > 0 ? "Receber" : "Devolver"
              }: ${(differenceTotal > 0
                ? differenceTotal
                : differenceTotal * -1
              ).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })}`}</span>
              {differenceTotal > 0 && (
                <div className="row">
                  <label>Forma de Pagamento:</label>
                  <Select
                    classNamePrefix="react-select"
                    value={
                      getPaymentMethods("local", "ticket").find(
                        (item) => item.value === sale.payment_method
                      ) || {
                        label: "Selecione a forma de pagamento",
                        value: "",
                      }
                    }
                    onChange={(item) =>
                      handleSelectChange(item, "payment_method")
                    }
                    options={getPaymentMethods("local", "ticket")}
                    styles={selectStyles}
                  />
                </div>
              )}
              {isOnlineSale && differenceTotal < 0 && (
                <div className="row">
                  <label>Pop Pontos Enviados?</label>
                  <Select
                    classNamePrefix="react-select"
                    value={
                      yesNo.find((item) => item.value === sale.points_sent) || {
                        label: "Informe se os pontos foram enviados",
                        value: undefined,
                      }
                    }
                    onChange={(item) => handleSelectChange(item, "points_sent")}
                    options={yesNo}
                    styles={selectStyles}
                  />
                </div>
              )}
            </>
          )}

          <SaleBirthdayPeople onSuccess={onDataUpdate} sale={sale} />

          <span className="fs-5 text-primary">Produtos</span>
          {renderSaleProducts()}
          <Button
            className="fw-bold"
            variant={"outline-primary"}
            onClick={handleOpenAddProductModal}
          >
            Editar produtos da venda
          </Button>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose} disabled={loadingLocked}>
          Cancelar
        </Button>
        <Button
          variant="outline-secondary"
          onClick={handleOpenUpdateLog}
          disabled={loadingLocked}
        >
          Log de Alterações
        </Button>
        {Number(sale.order_ticket_status) === 2 && (
          <Button
            variant="secondary"
            onClick={handleRemoveConfirmedStatus}
            disabled={loadingLocked}
          >
            Desconfirmar
          </Button>
        )}
        {Number(sale.order_ticket_id) > 0 && (
          <Button
            variant="outline-primary"
            disabled={loadingLocked}
            onClick={handleOpenRescheduleModal}
          >
            Reagendamento
          </Button>
        )}
        <Button variant="primary" onClick={handleSave} disabled={loadingLocked}>
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditSaleModal;
