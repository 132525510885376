import { useContext, useEffect, useState } from "react";
import moment from "moment";
import Api from "../../../api/api";
import { FilialContext } from "../../../context/FilialContext";
import { toast } from "react-toastify";
import List from "./List";
import { Button } from "react-bootstrap";
import PartiesPaymentsCSVModal from "./CSVDownloadModal";

const TransactionsList = () => {
  const { selectedUnitId } = useContext(FilialContext);
  const [transactionsFilter, setTransactionsFilter] = useState({
    limit: 10,
    page: 0,
    authorized_at_start_date: moment().subtract(6, "days").format("YYYY-MM-DD"),
    authorized_at_end_date: moment().format("YYYY-MM-DD"),
  });
  const [transactionsList, setTransactions] = useState([]);
  const [loadingTransactions, setLoadingTransactions] = useState(false);
  const [transactionsTotal, setTransactionsTotal] = useState(0);
  const [openCSVDownload, setOpenCSVDownload] = useState(false);

  const getTransactions = () => {
    setLoadingTransactions(true);
    Api.get(
      `transactions?${new URLSearchParams({
        ...transactionsFilter,
        unit_id: selectedUnitId,
        ticket_type: "parties",
      })}`
    )
      .then((res) => {
        setTransactions(res.data.list);
        setTransactionsTotal(res.data.total);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => setLoadingTransactions(false));
  };

  useEffect(() => {
    if (selectedUnitId) getTransactions();
  }, [transactionsFilter, selectedUnitId]);

  const handleOpenCSVDownload = () => {
    setOpenCSVDownload(true);
  };

  const handleCloseCSVDownload = () => {
    setOpenCSVDownload(false);
  };

  return (
    <>
      {openCSVDownload && (
        <PartiesPaymentsCSVModal
          handleClose={handleCloseCSVDownload}
          filter={{
            authorized_at_start_date:
              transactionsFilter.authorized_at_start_date,
            authorized_at_end_date: transactionsFilter.authorized_at_end_date,
            unit_id: selectedUnitId,
            ticket_type: "parties",
          }}
        />
      )}
      <div className="d-flex align-items-center justify-content-end">
        <Button onClick={handleOpenCSVDownload} disabled={loadingTransactions}>
          Baixar CSV dos Pagamentos
        </Button>
      </div>
      <List
        list={transactionsList}
        total={transactionsTotal}
        load={loadingTransactions}
        filter={transactionsFilter}
        setFilter={setTransactionsFilter}
      />
    </>
  );
};

export default TransactionsList;
