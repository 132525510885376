import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import Api from "../../../api/api";

const UpdateAuthorizationModal = ({ open, setOpen, updateData, onSuccess }) => {
  const [authUserForm, setAuthUserForm] = useState({
    email: "",
    password: "",
    reason: "",
  });
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const handleClose = () => {
    setOpen(null);
  };

  const loading = loadingUpdate;

  const handleUpdate = () => {
    if (!authUserForm.reason)
      return toast.warning(
        "É necessário informar o motivo da atualização da venda online!"
      );

    setLoadingUpdate(true);

    Api.put(`orders/code/${updateData.code}/editing`, {
      ...updateData,
      auth_reason: authUserForm.reason,
    })
      .then(() => {
        toast.success("Ticket atualizado com sucesso!");
        handleClose();
        if (onSuccess) onSuccess();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingUpdate(false));
  };

  const handleChange = (e) => {
    setAuthUserForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  return (
    <Modal show={open} onHide={loading ? null : handleClose}>
      <Modal.Title>Confirmação de atualização de venda online</Modal.Title>
      <Modal.Body>
        <div className="d-flex flex-column gap-2">
          <Form.Label>Motivo da atualização</Form.Label>
          <Form.Control
            type="text"
            value={authUserForm.reason}
            onChange={handleChange}
            name="reason"
            as="textarea"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" disabled={loading} onClick={handleClose}>
          Cancelar
        </Button>
        <Button disabled={loading} onClick={handleUpdate}>
          Confirmar Atualização
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateAuthorizationModal;
