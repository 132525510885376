import React, { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle";
import List from "./List";
import Edit from "./Edit";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import Api from "../../api/api";

const Doubts = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [list, setList] = useState([]);
  const [loadList, setLoadList] = useState(false);
  const [loadRemove, setLoadRemove] = useState(null);

  if (typeof window !== "undefined") {
    injectStyle();
  }

  const getList = () => {
    setLoadList(true);
    Api.get(`${process.env.REACT_APP_BASE_URL}doubts_categories`)
      .then((res) => {
        setLoadList(false);
        setList(res.data.list);
      })
      .catch((err) => {
        setLoadList(false);
        toast.error(err.message);
      });
  };

  const handleRemove = (item) => {
    if (window.confirm("Deseja realmente remover esta categoria de dúvidas?")) {
      setLoadRemove(item.id);
      Api.delete(
        `${process.env.REACT_APP_BASE_URL}doubts_categories/${item.id}`
      )
        .then((res) => {
          setLoadRemove(null);
          toast.success("Categoria de dúvidas removida com sucesso!");
          getList();
          if (selectedItem.id === item.id) setSelectedItem(null);
        })
        .catch((err) => {
          setLoadRemove(null);
          toast.error(err.message);
        });
    }
  };

  useEffect(() => {
    document.getElementById("topbar")?.scrollIntoView();
  }, []);

  return (
    <>
      <PageTitle>
        <h2 className="font-weight-bold">Dúvidas</h2>
      </PageTitle>
      <Container fluid>
        <Row>
          <Col xs="12" md="5">
            <List
              setSelectedItem={setSelectedItem}
              selectedItem={selectedItem}
              getList={getList}
              list={list}
              load={loadList}
              handleRemove={handleRemove}
              loadRemove={loadRemove}
            />
          </Col>
          {selectedItem && (
            <Col xs="12" md="7" lg="6" xxl={5}>
              <Edit
                item={selectedItem}
                setItem={setSelectedItem}
                getList={getList}
              />
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default Doubts;
