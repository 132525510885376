import { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import Api from "../../api/api";
import { injectStyle } from "react-toastify/dist/inject-style";
import PermissionGroupSelect from "./PermissionGroupSelect";
import DepartmentSelect from "./DepartmentSelect";
import Form from "react-bootstrap/Form";
import { validateCPF } from "../../utils/validateCPF";

export const EditUserModal = ({ open, close, user, setUpdated }) => {
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [cpf, setCpf] = useState("");
  const [birth, setBirth] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [permissionGroup, setPermissionGroup] = useState("");
  const [department, setDepartment] = useState("");
  const [unities, setUnities] = useState([]);
  const [brazilianDoc, setBrazilianDoc] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (user && user.actions) {
      setName(user.actions.name);
      setLastName(user.actions.lastName);
      setCpf(user.actions.cpf);
      setBirth(user.actions.birth);
      setPhone(user.actions.phone);
      setGender(user.actions.gender);
      setEmail(user.actions.email);
      setDepartment(user.actions.department_id);
      setBrazilianDoc(user.actions.brazilian_doc);
      setIsAdmin(Number(user.actions.dashboard_row_status) === 1);

      if (user.actions.permission_group_id) {
        setPermissionGroup(user.actions.permission_group_id);
      }

      if (user.actions.unities) {
        setUnities(user.actions.unities);
      }
    }
  }, [user]);

  useEffect(() => {
    if (!isAdmin) {
      setPermissionGroup("");
      setDepartment("");
      setUnities([]);
    }
  }, [isAdmin]);

  if (typeof window !== "undefined") {
    injectStyle();
  }

  function editUser() {
    const requiredFields = [
      "name",
      "lastName",
      "gender",
      "birth",
      "email",
      "phone",
      "cpf",
    ];
    const emptyFields = requiredFields.filter((field) => {
      return !eval(field);
    });

    if (emptyFields.length > 0) {
      return toast.error("Todos os campos obrigatórios devem ser preenchidos.");
    }

    if (
      isAdmin &&
      (permissionGroup === "" ||
        department === "" ||
        !unities ||
        unities.length === 0)
    ) {
      return toast.error("Todos os campos obrigatórios devem ser preenchidos.");
    }

    if (!validateCPF(cpf)) {
      return toast.error("CPF inválido. Por favor, insira um CPF válido.");
    }

    Api.put(`/user/list/${user.id}`, {
      name,
      lastName,
      birth,
      phone,
      email,
      cpf,
      gender,
      permission_group_id: permissionGroup,
      department_id: department,
      unities,
      dashboard_row_status: isAdmin ? 1 : 2,
    })
      .then((res) => {
        setUpdated((prevState) => prevState + 1);
        toast.success("Usuário Editado");
        close();
      })
      .catch((e) => console.log(e));
  }

  const handleUnitiesChange = (e) => {
    if (e.target.checked) {
      if (unities.find((unit) => unit === e.target.name)) return;
      setUnities([...unities, e.target.name]);
    } else setUnities(unities.filter((unit) => unit !== e.target.name));
  };

  return (
    <>
      <Modal show={open} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Editar usuário</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row className="gap-3">
            <Row>
              <label htmlFor="" className="text-primary font-weight-bold">
                Acesso ao Dashboard? <b className="text-danger">*</b>
              </label>
              <Col>
                <button
                  className={`${
                    isAdmin === true ? "btn-primary" : "btn-light"
                  } btn btn-sm font-weight-bold mr-1`}
                  onClick={() => setIsAdmin(true)}
                >
                  Sim
                </button>
                <button
                  className={`${
                    isAdmin === false ? "btn-primary" : "btn-light"
                  } btn btn-sm font-weight-bold`}
                  onClick={() => setIsAdmin(false)}
                >
                  Não
                </button>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} xl={6}>
                <label htmlFor="">
                  Nome<b className="text-danger">*</b>
                </label>
                <Form.Control
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  maxLength={60}
                />
              </Col>

              <Col xs={12} md={6} xl={6}>
                <label htmlFor="">
                  Sobrenome<b className="text-danger">*</b>{" "}
                </label>
                <Form.Control
                  type="text"
                  onChange={(e) => setLastName(e.target.value)}
                  value={lastName}
                  maxLength={60}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <label htmlFor="">
                  Documento brasileiro? (CPF/CNPJ)
                  <b className="text-danger">*</b>{" "}
                </label>
                <Form.Control
                  as="select"
                  onChange={(e) => setBrazilianDoc(e.target.value)}
                  value={[true, "true"].includes(brazilianDoc)}
                >
                  <option value={true}>Sim</option>
                  <option value={false}>Não</option>
                </Form.Control>
              </Col>

              <Col xs={12} md={6}>
                <label htmlFor="">
                  Documento<b className="text-danger">*</b>{" "}
                </label>
                <Form.Control
                  type="text"
                  onChange={(e) => setCpf(e.target.value)}
                  value={cpf}
                  maxLength={14}
                />
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <label htmlFor="">
                  Telefone<b className="text-danger">*</b>{" "}
                </label>
                <Form.Control
                  type="tell"
                  onChange={(e) => setPhone(e.target.value)}
                  value={phone}
                  maxLength={15}
                />
              </Col>
              <Col lg="6">
                <label>
                  Aniversário<b className="text-danger">*</b>
                </label>
                <Form.Control
                  type={"date"}
                  onChange={(e) => setBirth(e.target.value)}
                  value={birth}
                  maxLength={60}
                />
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <label htmlFor="">
                  Gênero<b className="text-danger">*</b>{" "}
                </label>
                <Form.Control
                  as="select"
                  onChange={(e) => setGender(e.target.value)}
                  value={gender}
                  maxLength={60}
                >
                  <option value="">Selecione</option>
                  <option value="male">Masculino</option>
                  <option value="female">Feminino</option>
                  <option value="other">Outro</option>
                </Form.Control>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <label htmlFor="">
                  Email<b className="text-danger">*</b>{" "}
                </label>
                <Form.Control
                  type={"email"}
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  maxLength={30}
                />
              </Col>
            </Row>

            {isAdmin && (
              <>
                <Row>
                  <Col lg="6">
                    <label htmlFor="">
                      Grupo de Permissões<b className="text-danger">*</b>{" "}
                    </label>
                    <PermissionGroupSelect
                      value={permissionGroup}
                      onChange={setPermissionGroup}
                    />
                  </Col>
                  <Col lg="6">
                    <label htmlFor="">
                      Departamento<b className="text-danger">*</b>{" "}
                    </label>
                    <DepartmentSelect
                      value={department}
                      onChange={setDepartment}
                    />
                  </Col>
                </Row>
                <Col lg="6">
                  <label htmlFor="">
                    Unidades<b className="text-danger">*</b>{" "}
                  </label>
                  <Form.Check
                    onChange={handleUnitiesChange}
                    name="SANTO_AMARO"
                    checked={unities.find((unit) => unit === "SANTO_AMARO")}
                    label="Santo Amaro"
                  />
                  <Form.Check
                    onChange={handleUnitiesChange}
                    name="TATUAPE"
                    checked={unities.find((unit) => unit === "TATUAPE")}
                    label="Tatuapé"
                  />
                  <Form.Check
                    onChange={handleUnitiesChange}
                    name="BRASILIA"
                    checked={unities.find((unit) => unit === "BRASILIA")}
                    label="Brasília"
                  />
                </Col>
              </>
            )}
          </Row>

          <Modal.Footer>
            <Button variant="secondary" onClick={close}>
              Cancelar
            </Button>
            <Button variant="primary" onClick={() => editUser()}>
              Salvar
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </>
  );
};
