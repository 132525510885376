import { maskBr } from "js-brasil";
import moment from "moment";
import React from "react";
import { Row } from "react-bootstrap";

const UserListRow = ({ item, onClick }) => {
  const handleClick = () => {
    if (onClick) onClick(item);
  };

  return (
    <Row
      onClick={handleClick}
      className="d-flex flex-column flex-sm-row justify-content-center justify-content-sm-start w-100 align-self-center p-1"
      style={{ cursor: onClick ? "pointer" : "default" }}
    >
      <div
        className="d-flex flex-column align-items-start justify-content-center flex-wrap font-weight-bold"
        style={{ flex: 1 }}
      >
        <span style={{ flex: 1 }}>{`ID: ${item.id}`}</span>
      </div>
      <div className="d-flex flex-wrap flex-column flex-sm-row justify-content-between">
        <div className="d-flex flex-column align-items-start justify-content-center flex-wrap">
          <span style={{ flex: 1 }}>{`Nome: ${item.name || "Indefinido"} ${
            item.lastName || ""
          }`}</span>
          <span style={{ flex: 1 }}>{`Nascimento: ${
            item.birth
              ? moment(item.birth, "DDMMYYYY").isValid()
                ? moment(item.birth, "DDMMYYYY").format("DD/MM/YYYY")
                : moment(item.birth).isValid()
                ? moment(item.birth).format("DD/MM/YYYY")
                : "Indefinido"
              : "Indefinido"
          }`}</span>
        </div>
        <div className="d-flex flex-column align-items-start justify-content-center flex-wrap align-items-sm-end text-align-sm-end">
          <span style={{ flex: 1 }}>{`E-mail: ${item.email}`}</span>
          <span style={{ flex: 1 }}>{`CPF/CNPJ: ${
            item.cpf
              ? item.cpf.length > 11
                ? maskBr.cnpj(item.cpf)
                : maskBr.cpf(item.cpf)
              : "Indefinido"
          }`}</span>
        </div>
      </div>
    </Row>
  );
};

export default UserListRow;
