import { useEffect, useState } from "react";
import { Button, Form, FormControl, InputGroup, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import Api from "../../../../api/api";
import Select from "react-select";
import axios from "axios";

const selectStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: "2rem",
    borderBottomRightRadius: "2rem",
  }),
  container: (baseStyles, state) => ({
    ...baseStyles,
    alignSelf: "stretch",
    flex: 1,
  }),
};

const AddBirthdayPersonModal = ({
  open,
  setOpen,
  onSuccess,
  order_ticket_id,
  ticket_attachment_id,
}) => {
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);

  const loadingLocked = loadingData || loadingSave || loadingRemove;

  const [values, setValues] = useState({
    order_ticket_id,
    type: "",
    file_url: "",
  });

  const getData = () => {
    setLoadingData(true);
    Api.get(`tickets_attachments/${ticket_attachment_id}`)
      .then((res) => setValues({ ...res.data.data }))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingData(false));
  };

  useEffect(() => {
    if (open && ticket_attachment_id > 0) getData();
  }, [ticket_attachment_id, open]);

  const handleClose = () => {
    if (loadingLocked) return;
    setOpen(false);
  };

  const renderAttachmentsInputs = () => {
    const handleChange = (e) => {
      setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const handleFileChange = (e) => {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      formData.append("folder_name", "attachments");

      setLoadingUpload(true);
      const token = localStorage.getItem("token");
      axios
        .post(`${process.env.REACT_APP_BASE_URL}upload/file`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          values.file_url = res.data.data;
          setValues({ ...values });
        })
        .catch((err) => toast.error(err.response.data.message))
        .finally(() => setLoadingUpload(false));
    };

    return (
      <div className="d-flex flex-column gap-1">
        <InputGroup>
          <InputGroup.Text>Nome</InputGroup.Text>
          <Form.Select
            value={values.type}
            name={"type"}
            onChange={handleChange}
          >
            <option hidden>Selecione o tipo do anexo</option>
            <option value="party_contract">Contrato de Festa</option>
            <option value="menu_contract">Contrato de Cardápio</option>
            <option value="guests_list">Lista de Convidados</option>
          </Form.Select>
        </InputGroup>
        <InputGroup>
          <InputGroup.Text>Subir novo arquivo</InputGroup.Text>
          <Form.Control
            value={values.file}
            name={"file"}
            type={"file"}
            onChange={handleFileChange}
            disabled={loadingUpload}
          />
        </InputGroup>
      </div>
    );
  };

  const handleAddAttachmentToOrder = () => {
    setLoadingSave(true);
    if (ticket_attachment_id > 0)
      return Api.put(`/tickets_attachments/${ticket_attachment_id}`, values)
        .then(() => {
          toast.success("Anexo atualizado com sucesso!");
          if (onSuccess) onSuccess();
        })
        .catch((err) => {
          toast.error(err.message);
          setLoadingSave(false);
        });
    Api.post(`/tickets_attachments`, values)
      .then(() => {
        toast.success("Anexo adicionado com sucesso!");
        if (onSuccess) onSuccess();
      })
      .catch((err) => {
        toast.error(err.message);
        setLoadingSave(false);
      });
  };

  const handleRemoveAttachmentFromOrder = () => {
    if (window.confirm("Deseja realmente remover este anexo da venda?")) {
      setLoadingRemove(true);
      Api.delete(`/tickets_attachments/${values.id}`)
        .then(() => {
          toast.success("Anexo removido com sucesso!");
          if (onSuccess) onSuccess();
        })
        .catch((err) => {
          toast.error(err.message);
          setLoadingSave(false);
        });
    }
  };

  return (
    <Modal show={open} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Adicionar anexo à venda</Modal.Title>
      </Modal.Header>
      <Modal.Body>{renderAttachmentsInputs()}</Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose} disabled={loadingLocked}>
          Cancelar
        </Button>
        {values?.id > 0 && (
          <Button
            variant="danger"
            onClick={handleRemoveAttachmentFromOrder}
            disabled={loadingLocked}
          >
            Remover
          </Button>
        )}
        <Button onClick={handleAddAttachmentToOrder} disabled={loadingLocked}>
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddBirthdayPersonModal;
