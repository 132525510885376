import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageTitle from "../../components/PageTitle";
import Table from "../../components/Tables";
import renamePaymentType from "../../utils/paymentType";
import { dateFormated } from "../../utils/dateFormated";
import { useTickets } from "./getTransactions";
import { PagesBtn } from "../../components/Pagination/PagesBtn";

export default function UserTransactions() {
  const { id } = useParams();
  const {
    getTickets,
    transaction,
    currentPage,
    setCurrentPage,
    ticketsTotal,
    perPage,
    setPerPage,
    getTicketsSearch,
  } = useTickets(id);
  const navigate = useNavigate();
  const [transactionsData, setTransactionsData] = useState([]);

  useEffect(() => {
    //GET API USER INFORMATION

    getTickets({ page: currentPage, perPage: perPage, id: id });
  }, [currentPage, perPage, id]);

  useEffect(() => {
    const dataFiltered = transaction.map((ticket) => {
      return {
        id: ticket.id.split("-")[0],
        date: dateFormated(ticket?.date.substr(0, 10)),
        createdAt: dateFormated(ticket?.created_at.substr(0, 10)),
        status: ticket,
        start: ticket.ticket_time,
        end: ticket,
        origin: ticket.payment_from,
        filial: ticket.filial,
        duration: `${ticket.duration}`,
        total: `R$ ${ticket.ticket_total}`,
        paymentType: renamePaymentType(ticket.payment_type),
      };
    });
    setTransactionsData(dataFiltered);
  }, [transaction]);
  const usersColumns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Filial",
        accessor: "filial",
      },
      {
        Header: "Data Criação",
        accessor: "createdAt",
      },
      {
        Header: "Data de Uso",
        accessor: "date",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell: { value } }) => {
          if (value.payment_from === "local" && value.status === "AUTHORIZED") {
            return <p className="text-primary font-weight-bold m-0">Válido</p>;
          } else if (
            value.status === "AUTHORIZED" ||
            value.status === "authorized"
          ) {
            return <p className="text-success font-weight-bold m-0">Válido</p>;
          } else if (
            value.status === "PRE_AUTHORIZED" ||
            value.status === "PENDING"
          ) {
            return (
              <p className="text-warning font-weight-bold m-0">Pendente</p>
            );
          } else if (value.status === "CREATED")
            return <p className="text font-weight-bold m-0">Criado</p>;
          else if (value.status === "Cancelado") {
            return (
              <p className="text-danger font-weight-bold m-0">Cancelado</p>
            );
          } else if (value.status === "USED") {
            return (
              <p className="text-primary font-weight-bold m-0">Confirmado</p>
            );
          } else if (value.status === "FAILED") {
            return <p className="text-danger font-weight-bold m-0">Falhou</p>;
          } else if (
            value.status === "DELETED" &&
            (value.payment_type === "credit_online" ||
              value.payment_type === "credit_local")
          ) {
            return (
              <p className="text-danger font-weight-bold m-0">Estornado</p>
            );
          } else if (
            value.status === "DELETED" &&
            value.payment_type === "pix"
          ) {
            return <p className="text-danger font-weight-bold m-0">Expirado</p>;
          } else if (value.status === "REMOVED" || value.status === "DELETED") {
            return <p className="text-danger font-weight-bold m-0">Deletado</p>;
          } else {
            return <p className="m-0 font-weight-bold">{value.status}</p>;
          }
        },
      },
      {
        Header: "Inicio",
        accessor: "start",
      },
      {
        Header: "Fim",
        accessor: "end",
        Cell: ({ cell: { value } }) => {
          const splitEd = value?.ticket_time.split(":");
          const sum = Number(splitEd[0]) + value?.duration;
          return <p>{sum}:00</p>;
        },
      },
      {
        Header: "Duração",
        accessor: "duration",
      },
      {
        Header: "Método Pagamento",
        accessor: "paymentType",
      },
      {
        Header: "Origem",
        accessor: "origin",
      },
      {
        Header: "Total",
        accessor: "total",
      },
    ],
    []
  );

  useEffect(() => {
    document.getElementById("topbar")?.scrollIntoView();
  }, []);

  return (
    <>
      <PageTitle>
        <h2 className="font-weight-bold">Transações</h2>
      </PageTitle>
      <button
        className="bg-primary font-weight-bold btn btn-sm mt-0 mb-2 text-white"
        onClick={() => navigate("/users")}
      >
        {" "}
        VOLTAR{" "}
      </button>

      {transactionsData && (
        <Table data={transactionsData} columns={usersColumns} sizeTable={500} />
      )}
      <PagesBtn
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        ticketsTotal={ticketsTotal}
        perPage={perPage}
        setPerPage={setPerPage}
      />
    </>
  );
}
