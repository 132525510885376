import React, { useEffect, useState } from "react";
import Select from "react-select";

const TicketDPList = ({ list, setList, disabilityTypes }) => {

  const handleChange = (item, newType) => {
    const updatedList = list.map((listItem) => {
      if (listItem.id === item.id) {
        listItem.disability_type_id = newType;
      }
      return listItem;
    });

    setList(updatedList);
    localStorage.setItem("ticket_disabled_people", JSON.stringify(updatedList));
  };

  const options = disabilityTypes.map((dt) => ({
    value: dt.id,
    label: dt.name,
  }));

  return (
    <>
      {list.map((item) => (
        <div key={"ticket_dp_" + item.id} className="mb-2">
          <Select
            classNamePrefix="react-select"
            value={options.find((opt) => opt.value === item.disability_type_id)}
            onChange={(val) => handleChange(item, val ? val.value : "new")}
            options={options}
            placeholder={"Clique aqui para escolher a deficiência"}
          />
        </div>
      ))}
    </>
  );
};

export default TicketDPList;