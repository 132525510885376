import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { injectStyle } from "react-toastify/dist/inject-style";
import Api from '../../api/api'

export default function UserNewPassModal({ open, close, user, setUpdated }) {
  const navigate = useNavigate()

  if (typeof window !== "undefined") {
    injectStyle();
  }
  function sendNewPassword() {
    Api.post('/dashboard/recover', { email: user?.email }).then(
      (res) => {
        setUpdated(prevState => prevState + 1)
        toast.success('Nova Senha Enviada por Email')
        close()
      }
    ).catch(e => {
      toast.error('Este Email não Pertece a um usuário Dashboard')
    })
  }

  return (
    <>
      <Modal show={open} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title >
            Reenviar senha
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h5>Enviaremos uma nova senha para o email abaixo.</h5>
          <p className='text-primary font-weight-bold'>{user?.email}</p>
          <p className='text-warning'>Você receberá um email com a nova senha temporária. Para alterar sua senha, entre no Dashboard com a senha temporária.</p>
          <div class="d-flex gap-2">


          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={close}>
            Cancelar
          </Button>
          <Button
            variant="primary"
            onClick={() => sendNewPassword()} >
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal></>
  )
}