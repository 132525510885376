import React, { useEffect, useState } from "react";
import PageTitle from "../../../components/PageTitle";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import ProgressBar from "react-bootstrap/ProgressBar";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import Api from "../../../api/api";
import HomeCarouselContentModal from "./HomeCarouselContentModal";
import HomeCarouselPreviewModal from "./HomeCarouselPreviewModal";
import PromosCarouselContentModal from "./PromosCarouselContentModal";
import PromosCarouselPreviewModal from "./PromosCarouselPreviewModal";

const WebsiteContent = () => {
  const [values, setValues] = useState(null);
  const [load, setLoad] = useState(false);
  const [openHomeCarouselContentModal, setOpenHomeCarouselContentModal] =
    useState(false);
  const [openHomeCarouselPreviewModal, setOpenHomeCarouselPreviewModal] =
    useState(false);
  const [openPromosCarouselContentModal, setOpenPromosCarouselContentModal] =
    useState(false);
  const [openPromosCarouselPreviewModal, setOpenPromosCarouselPreviewModal] =
    useState(false);

  if (typeof window !== "undefined") {
    injectStyle();
  }

  const getData = () => {
    setLoad(true);
    Api.get(`website_content`)
      .then((res) => {
        setLoad(false);
        setValues(res.data.data);
      })
      .catch((err) => {
        setLoad(false);
        toast.error(err.message);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleChange = (e) => {
    if (e.target.name === "home_carousel_item_duration")
      e.target.value = e.target.value.replace(/[^\d]/g, "");
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSave = () => {
    setLoad(true);
    Api.put("website_content", values)
      .then((res) => {
        toast.success("Conteúdo atualizado com sucesso!");
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoad(false));
  };

  return (
    <>
      <PageTitle>
        <h2 className="font-weight-bold">Conteúdo do Website</h2>
      </PageTitle>
      <HomeCarouselContentModal
        open={openHomeCarouselContentModal}
        setOpen={setOpenHomeCarouselContentModal}
        values={values}
        setValues={setValues}
      />
      <HomeCarouselPreviewModal
        open={openHomeCarouselPreviewModal}
        setOpen={setOpenHomeCarouselPreviewModal}
        values={values}
      />
      <PromosCarouselContentModal
        open={openPromosCarouselContentModal}
        setOpen={setOpenPromosCarouselContentModal}
        values={values}
        setValues={setValues}
      />
      <PromosCarouselPreviewModal
        open={openPromosCarouselPreviewModal}
        setOpen={setOpenPromosCarouselPreviewModal}
        values={values}
      />
      <Container fluid>
        <Row className="g-2">
          <Col xs={12} md={10} xl={7} xxl={6}>
            <div className="d-flex flex-column gap-1">
              {load && <ProgressBar animated now={100} />}
              <div className="d-flex flex-column">
                <InputGroup>
                  <InputGroup.Text className="rounded-box">
                    Título do carrossel da home
                  </InputGroup.Text>
                  <Form.Control
                    value={values?.home_carousel_title}
                    name={"home_carousel_title"}
                    onChange={handleChange}
                    as="textarea"
                    maxLength={100}
                    className="rounded-box"
                  />
                </InputGroup>
                <span className="ml-auto">{`${
                  values?.home_carousel_title?.length || 0
                }/100 caracteres`}</span>
              </div>
              <InputGroup>
                <InputGroup.Text>
                  Duração do item do carrossel (segundos)
                </InputGroup.Text>
                <Form.Control
                  value={values?.home_carousel_item_duration}
                  name={"home_carousel_item_duration"}
                  onChange={handleChange}
                  type={"number"}
                  min={1}
                  step={1}
                  pattern="\d+"
                />
              </InputGroup>
              <Button
                variant="primary"
                className="align-self-end"
                disabled={load}
                onClick={handleSave}
              >
                Salvar
              </Button>
            </div>
          </Col>
          <Col xs={12}>
            <div className="d-flex flex-column gap-1">
              <InputGroup>
                <InputGroup.Text>Carrossel da Home</InputGroup.Text>
                <Button
                  variant="outline-primary"
                  onClick={() => setOpenHomeCarouselContentModal(true)}
                >
                  Conteúdo
                </Button>
                <Button
                  variant="outline-primary"
                  onClick={() => setOpenHomeCarouselPreviewModal(true)}
                >
                  Prévia
                </Button>
              </InputGroup>
              <InputGroup>
                <InputGroup.Text>Carrossel de Promoções</InputGroup.Text>
                <Button
                  variant="outline-primary"
                  onClick={() => setOpenPromosCarouselContentModal(true)}
                >
                  Conteúdo
                </Button>
                <Button
                  variant="outline-primary"
                  onClick={() => setOpenPromosCarouselPreviewModal(true)}
                >
                  Prévia
                </Button>
              </InputGroup>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default WebsiteContent;
