import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import Api from "../../api/api";

const ResendStatementsModal = ({
  open,
  setOpen,
  order_ticket_id,
  onSuccess,
}) => {
  const [loadingResend, setLoadingResend] = useState(false);

  const handleResend = () => {
    setLoadingResend(true);
    Api.post(
      `responsibility_statements/resend/mails/order_ticket_id/${order_ticket_id}`
    )
      .then((res) => {
        toast.success(res?.message || "Termos reenviados com sucesso!");
        setOpen(false);
        if (onSuccess) onSuccess();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingResend(false));
  };

  const handleClose = () => {
    if (loadingResend) return;
    setOpen(false);
  };

  return (
    <Modal show={open} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Confirmação</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span className="fs-3">
          Deseja realmente remover as assinaturas atuais e reenviar os termos
          aos e-mails?
        </span>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose} disabled={loadingResend}>
          Cancelar
        </Button>
        <Button onClick={handleResend} disabled={loadingResend}>
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ResendStatementsModal;
