import { Button, ProgressBar } from "react-bootstrap";
import Table from "../../components/Table";
import moment from "moment";
import { BiSearchAlt } from "react-icons/bi";
import { useContext, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { HiOutlineInformationCircle } from "react-icons/hi2";

const List = ({ list, total, setSelectedItem, load, filter, setFilter }) => {
  const { dashboardId } = useContext(AuthContext);
  const [search, setSearch] = useState("");

  const columns = [
    {
      field: "name",
      label: "Nome",
      renderValue: (item) => (
        <div className="d-flex flex-row align-items-center gap-2">
          <span className="fs-5">{item.name}</span>
          {item.description ? (
            <HiOutlineInformationCircle
              size={"1.5rem"}
              data-toggle="tooltip"
              title={item.description}
            />
          ) : null}
        </div>
      ),
    },
    {
      field: "discount_rule",
      label: "Regra Desconto",
      renderValue: (item) =>
        item.discount_type === "PLAYERS"
          ? `${item.with_players} Brincantes`
          : (item.ticket_min_value || 0).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            }),
    },
    {
      field: "discount_value",
      label: "Valor Desconto",
      renderValue: (item) =>
        item.discount_type === "PLAYERS"
          ? item.players_discount_type === "PLAYERS"
            ? `${item.discount_players} Brincantes`
            : item.players_discount_type === "PERCENTAGE"
            ? `${item.discount_value}% ${`(${(
                item.discount_max_value || 0
              ).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })})`}`
            : (item.discount_value || 0).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })
          : item.discount_type === "PERCENTAGE"
          ? `${item.discount_value}% ${`(${(
              item.discount_max_value || 0
            ).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })})`}`
          : (item.discount_value || 0).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            }),
    },
    {
      field: "created_at",
      label: "Criada em",
      renderValue: (item) => moment(item.created_at).format("DD/MM/YYYY HH:mm"),
    },
    {
      field: "created_by_email",
      label: "Criada por",
      renderValue: (item) => item.created_by_email || "Indefinido",
    },
    {
      field: "start_date",
      label: "Data Inicial",
      renderValue: (item) => moment(item.start_date).format("DD/MM/YYYY"),
    },
    {
      field: "end_date",
      label: "Data Final",
      renderValue: (item) => moment(item.end_date).format("DD/MM/YYYY"),
    },
    {
      field: "unities",
      label: "Unidades",
      renderValue: (item) => getUnities(item.unities),
    },
    {
      field: "status",
      label: "Status",
      renderValue: (item) => getStatus(item.status),
    },
    { field: "creation_count", label: "Vendas" },
  ];

  const getUnities = (unities) => {
    return unities
      .map((un) => {
        if (un === "BRASILIA") return "Brasília";
        if (un === "SANTO_AMARO") return "Santo Amaro";
        return "Tatuapé";
      })
      .join(", ");
  };

  const getStatus = (status) => {
    let itemStatus = null;

    switch (status) {
      case 1:
        itemStatus = "ATIVA";
        break;
      case 2:
        itemStatus = "INATIVA";
        break;
      case 3:
        itemStatus = "REMOVIDA";
        break;
      case 4:
        itemStatus = "ESGOTADA";
        break;
      case 5:
        itemStatus = "EXPIRADA";
        break;

      default:
        break;
    }

    return itemStatus;
  };

  const getDiscountType = (discountType) => {
    let type = "";

    switch (discountType) {
      case "PLAYERS":
        type = "Brincantes";
        break;
      case "PERCENTAGE":
        type = "Porcentagem";
        break;
      case "FIXED":
        type = "Valor Fixo";
        break;
      default:
        break;
    }

    return type;
  };

  const handleNewPromotion = () => {
    const defaultData = {
      name: "",
      description: "",
      unities: [],
      ticket_times: [],
      creation_times: [
        "00:00",
        "01:00",
        "02:00",
        "03:00",
        "04:00",
        "05:00",
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
      ],
      ticket_week_days: [0, 1, 2, 3, 4, 5, 6],
      creation_week_days: [0, 1, 2, 3, 4, 5, 6],
      creation_limit: 10000,
      start_date: moment().format("YYYY-MM-DD"),
      end_date: moment().add(30, "days").format("YYYY-MM-DD"),
      start_time: moment().format("HH:mm:ss"),
      end_time: moment().add(30, "days").format("HH:mm:ss"),
      ticket_min_value: 0,
      discount_type: "PERCENTAGE",
      discount_value: 10,
      discount_max_value: 50,
      cpf_lock: false,
      ticket_usage_days_limit_after_end_date: 30,
      created_by: dashboardId,
      with_players: 0,
      discount_players: 0,
      suggestion_banner_activated: false,
      suggestion_banner_time: 0,
      suggestion_banner_mobile: "",
      suggestion_banner_desktop: "",
      cart_suggestion_title: "",
      cart_suggestion_activated: false,
      cart_suggestion_description: "",
      cart_suggestion_trigger_times: [],
    };

    setSelectedItem(defaultData);
  };

  const handleSearch = () => {
    setFilter((prev) => ({ ...prev, search }));
  };

  const onSearchKeyDown = (e, x) => {
    const enter = 13;
    if (e.keyCode === enter) handleSearch();
  };

  const getPromotionStatusOptions = () => {
    const statuses = [1, 2, 3, 4, 5];

    return statuses.map((status) => (
      <option key={`status_${status}`} value={status}>
        {getStatus(status)}S
      </option>
    ));
  };

  const handleFilterChange = (e) => {
    setFilter((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleDuplicate = (promo) => {
    const newPromo = { ...promo };
    newPromo.ticket_ids = [];
    newPromo.pre_order_banner = "";
    newPromo.id = null;
    newPromo.name += " (Cópia)";
    setSelectedItem(newPromo);
  };

  const renderRows = (field, item) => {
    if (!item) return null;
    const data = columns.find((col) => col.field === field);
    if (data?.renderValue) return data.renderValue(item);
    return item[field];
  };

  return (
    <>
      <div className="d-flex gap-1 mb-1 flex-wrap align-items-center justify-content-center align-items-sm-start justify-content-sm-start">
        <h5>Lista de Promoções:</h5>
        <div className="input-group w-auto flex-0">
          <input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="form-control"
            placeholder="Pesquisar Promoção"
            onKeyDown={onSearchKeyDown}
          />
          <span
            className="input-group-text cursor-pointer"
            onClick={handleSearch}
          >
            <BiSearchAlt />
          </span>
        </div>
        <div className="input-group w-auto flex-0">
          <select
            value={filter.status}
            onChange={(e) =>
              setFilter((prev) => ({ ...prev, status: e.target.value }))
            }
            className="form-control"
            placeholder="Filtrar Status"
          >
            <option value={""}>Filtrar Status</option>
            {getPromotionStatusOptions()}
          </select>
        </div>
        <Button style={{ marginLeft: "auto" }} onClick={handleNewPromotion}>
          Nova Promoção
        </Button>
      </div>
      <div className="d-flex gap-1 mb-1 flex-wrap align-items-center justify-content-center align-items-sm-start justify-content-sm-start">
        <h5>Filtrar estatísticas:</h5>
        <div className="input-group w-auto flex-0">
          <span className="input-group-text">Início</span>
          <input
            value={
              filter.start_date
                ? moment(filter.start_date).format("YYYY-MM-DD")
                : null
            }
            onChange={handleFilterChange}
            name="start_date"
            type="date"
            className="form-control"
            placeholder="Data inicial"
          />
        </div>
        <div className="input-group w-auto flex-0">
          <span className="input-group-text">Fim</span>
          <input
            value={
              filter.end_date
                ? moment(filter.end_date).format("YYYY-MM-DD")
                : null
            }
            onChange={handleFilterChange}
            name="end_date"
            type="date"
            className="form-control"
            placeholder="Data final"
          />
        </div>
      </div>
      {load && <ProgressBar animated now={100} className="mb-1" />}
      <Table
        data={list}
        columns={columns}
        actions={(item) => (
          <div className="d-flex gap-1">
            <Button onClick={() => setSelectedItem(item)}>Editar</Button>
            <Button
              className="btn-secondary"
              onClick={() => handleDuplicate(item)}
            >
              Duplicar
            </Button>
          </div>
        )}
        renderRow={renderRows}
        renderRowItem
        page={filter.page}
        limit={filter.limit}
        total={total}
        paginated
        handlePageChange={(page) => setFilter({ ...filter, page })}
        handleLimitChange={(limit) => setFilter({ ...filter, limit })}
        limitOptions={[10, 20, 30]}
        emptyListLabel={"Não há promoções."}
      />
    </>
  );
};

export default List;
