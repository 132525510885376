import React from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { getPartyStatus, getTicketType } from '../../utils/functions';

const FiltersModal = ({
    open,
    setOpen,
    handleStatusCheckboxChange,
    handleTypeCheckboxChange,
    handleMinValueChange,
    handleMaxValueChange,
    handleApplyFilters,
    handleClearFilters,
    minValue,
    maxValue,
    selectedStatus,
    selectedTypes
}) => {

    return (
        <Modal show={open} onHide={() => setOpen(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Mais Filtros</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5>Filtrar por Status</h5>
                <Form.Group>
                    {Array.from(Array(4), (e, i) => i + 1).map(statusValue => (
                        <Form.Check
                            key={statusValue}
                            type="checkbox"
                            label={getPartyStatus(statusValue)}
                            value={statusValue}
                            checked={selectedStatus.includes(String(statusValue))}
                            onChange={handleStatusCheckboxChange}
                        />
                    ))}
                </Form.Group>
                <h5 className="mt-4">Filtrar por Tipo de Festa</h5>
                <Form.Group>
                    {["single", "exclusive", "demi_exclusive"].map(type => (
                        <Form.Check
                            key={type}
                            type="checkbox"
                            label={getTicketType(type)}
                            value={type}
                            checked={selectedTypes.includes(type)}
                            onChange={handleTypeCheckboxChange}
                        />
                    ))}
                </Form.Group>
                <h5 className="mt-4">Filtrar por Valor</h5>
                <Row className="g-4">
                    <Col>
                        <Form.Control
                            placeholder="Valor mínimo"
                            value={minValue}
                            onChange={handleMinValueChange}
                            maxLength={8}
                        />
                    </Col>
                    <Col>
                        <Form.Control
                            placeholder="Valor máximo"
                            value={maxValue}
                            onChange={handleMaxValueChange}
                            maxLength={8}
                        />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setOpen(false)}>Fechar</Button>
                <Button variant="danger" onClick={handleClearFilters}>Limpar Filtros</Button>
                <Button variant="primary" onClick={handleApplyFilters}>Aplicar Filtros</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default FiltersModal;
