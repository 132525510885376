import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, ListGroup, ListGroupItem, ProgressBar } from "react-bootstrap";
import { toast } from "react-toastify";
import Api from "../../../api/api";
import EditModal from "./EditModal";

const Levels = ({ point_program_id }) => {
  const [list, setList] = useState([]);
  const [load, setLoad] = useState(false);
  const [selectedID, setSelectedID] = useState();

  const getList = () => {
    setLoad(true);
    Api.get(`points_programs_levels`, { params: { point_program_id } })
      .then((res) => {
        setList(res.data.list);
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoad(false));
  };

  useEffect(() => {
    if (point_program_id > 0) getList();
  }, [point_program_id]);

  return (
    <>
      {selectedID && (
        <EditModal
          open={selectedID ? true : false}
          setOpen={setSelectedID}
          id={selectedID}
          getList={getList}
          point_program_id={point_program_id}
        />
      )}
      {/* <div className="d-flex align-items-center justify-content-between mt-1 mb-1">
        <h5>Níveis do Programa:</h5>
        <Button variant="primary" onClick={() => setSelectedID("new")}>
          Novo Nível
        </Button>
      </div> */}
      {load && <ProgressBar animated now={100} className="mb-1" />}
      <ListGroup>
        {list.map((item, index) => (
          <ListGroupItem
            action
            active={item.id === selectedID}
            variant="primary"
            onClick={() => setSelectedID(item.id)}
            className="d-flex justify-content-between align-items-center"
            key={`level_${item.id}`}
          >
            <span>{item.name}</span>
            <span>{`${item.experience_required} XP`}</span>
          </ListGroupItem>
        ))}
      </ListGroup>
      {!load && !list[0] && (
        <div className="w-100 d-flex justify-content-center">
          <span>Sem níveis cadastrados no programa.</span>
        </div>
      )}
    </>
  );
};

export default Levels;
