import { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import Api from "../../../api/api";
import EditModal from "./EditModal";
import List from "./List";

const PhotosModal = ({ open, setOpen, albums_id }) => {
  const [load, setLoad] = useState(false);
  const [list, setList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleNewItem = () => {
    const defaultData = {
      name: "",
      description: "",
      file_url: "",
      status: 2,
      albums_id,
    };

    setSelectedItem(defaultData);
  };

  const getList = () => {
    setLoad(true);
    Api.get("photos/list", { params: { albums_id, from_dashboard: true } })
      .then((res) => {
        setList(res.data.photos);
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoad(false));
  };

  const handleListChange = (changedList) => {
    setLoad(true);

    const orderList = changedList.map((item, index) => ({
      id: item.id,
      item_order: index + 1,
    }));

    Api.post("photos/reorder", orderList)
      .then((res) => {
        toast.success("Ordem das imagens atualizada com sucesso!");
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoad(false));
  };

  useEffect(() => {
    if (albums_id && open) getList();
  }, [albums_id, open]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Modal show={open} onHide={handleClose} size="xl">
        <EditModal
          open={selectedItem ? true : false}
          setOpen={setSelectedItem}
          item={selectedItem}
          getList={getList}
        />
        <Modal.Header>
          <Modal.Title>Imagens do álbum</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column gap-1">
            <List
              list={list}
              onChange={handleListChange}
              setSelectedItem={setSelectedItem}
              load={load}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleClose}>
            Fechar
          </Button>
          <Button disabled={load} onClick={handleNewItem}>
            Nova Imagem
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PhotosModal;
