import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Form, InputGroup, Modal, ProgressBar } from "react-bootstrap";
import { toast } from "react-toastify";
import Api from "../../api/api";
import Levels from "./Levels";
import { FilialContext } from "../../context/FilialContext";

const EditModal = ({ open, setOpen, id, getList }) => {
  const { selectedUnitId } = useContext(FilialContext);
  const [loadInfo, setLoadInfo] = useState(false);
  const [load, setLoad] = useState(false);
  const [values, setValues] = useState({});

  const getInfo = () => {
    setLoadInfo(true);
    Api.get(`points_programs/${id}`)
      .then((res) => {
        setValues(res.data);
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadInfo(false));
  };

  useEffect(() => {
    if (id !== "new") getInfo();
    else setLoad(false);
  }, [id]);

  const handleClose = () => setOpen(null);

  const handleChange = (e) =>
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const save = () => {
    setLoad(true);
    if (id === "new")
      return Api.post(`points_programs`, { ...values, unit_id: selectedUnitId })
        .then((res) => {
          setValues(res.data.data);
          getList();
        })
        .catch((err) => toast.error(err.message))
        .finally(() => setLoad(false));
    Api.put(`points_programs/${id}`, { ...values })
      .then((res) => {
        setValues(res.data.data);
        getList();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoad(false));
  };

  // const remove = () => {
  //   if (window.confirm("Deseja realmente remover este programa de pontos?")) {
  //     setLoad(true);
  //     Api.delete(`points_programs/${values.id}`)
  //       .then((res) => {
  //         getList();
  //         handleClose();
  //       })
  //       .catch((err) => toast.error(err.message))
  //       .finally(() => setLoad(false));
  //   }
  // };

  const activate = () => {
    if (
      window.confirm(
        "Deseja realmente ativar este programa de pontos? Lembrando que este será o único programa rodando, se já existe um programa ativo, ele será desativado."
      )
    ) {
      setLoad(true);
      Api.put(`points_programs/${values.id}/status`, { status: 1 })
        .then((res) => {
          setValues(res.data.data);
          getList();
        })
        .catch((err) => toast.error(err.message))
        .finally(() => setLoad(false));
    }
  };

  const deactivate = () => {
    if (window.confirm("Deseja realmente desativar este programa de pontos?")) {
      setLoad(true);
      Api.put(`points_programs/${values.id}/status`, { status: 2 })
        .then((res) => {
          setValues(res.data.data);
          getList();
        })
        .catch((err) => toast.error(err.message))
        .finally(() => setLoad(false));
    }
  };

  return (
    <Modal show={open} onHide={load ? null : handleClose}>
      <Modal.Title className="align-self-center">{`${
        id === "new" ? "Novo" : "Editar"
      } Programa de Pontos`}</Modal.Title>
      <Modal.Body>
        {loadInfo ? (
          <ProgressBar animated now={100} />
        ) : (
          <>
            {/* <InputGroup>
              <InputGroup.Text>Nome do Programa</InputGroup.Text>
              <Form.Control
                value={values.name}
                name={"name"}
                onChange={handleChange}
                autoFocus
              />
            </InputGroup> */}
            {values.id > 0 && <Levels point_program_id={values.id} />}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={load} onClick={handleClose} variant="light">
          Fechar
        </Button>
        {values.id > 0 && (
          <>
            {values.status === 2 && (
              <Button onClick={activate} disabled={load} variant="success">
                Ativar
              </Button>
            )}
            {values.status === 1 && (
              <Button onClick={deactivate} disabled={load} variant="secondary">
                Desativar
              </Button>
            )}
          </>
        )}
        <Button disabled={load} onClick={save} variant="primary">
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditModal;
