import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { Button, ButtonGroup, Card, SplitButton } from "react-bootstrap";
import { toast } from "react-toastify";
import Api from "../../../api/api";
import { FilialContext } from "../../../context/FilialContext";
import General from "./General";
import Participants from "./Participants";
import Payment from "./Payment";
import Products from "./Products";
import moment from "moment";

const Sale = ({
  user,
  handleChangeUser,
  sale,
  initSale,
  setSale,
  parkAreas,
}) => {
  const { selectedUnitId } = useContext(FilialContext);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [disableBirthday, setDisableBirthday] = useState(false);
  const [tab, setTab] = useState(user.id ? "participants" : "products");
  const [load, setLoad] = useState(false);
  const yesNo = [
    { value: false, label: "Não" },
    { value: true, label: "Sim" },
  ];

  const handleConfirmSale = () => {
    if (!sale.payment_method)
      return toast.warning(
        "É necessário informar a forma de pagamento para continuar!"
      );
    if (
      sale.payment_method === "installments" &&
      (!sale.total_breakdown[0].name || !sale.total_breakdown[1].name)
    )
      return toast.warning(
        "É necessário informar as formas de pagamento para continuar!"
      );
    if (
      sale.payment_method === "installments" &&
      Number(sale.total_breakdown[0].value) +
        Number(sale.total_breakdown[1].value) !==
        sale.total_value
    )
      return toast.warning(
        "É necessário informar os valores das formas de pagamento para continuar!"
      );

    if (
      window.confirm(
        `Confirmar lançamento de venda ${
          user.id ? ` para ${user.name} ${user.lastName}` : ""
        } no valor de ${sale.total_value.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        })}?`
      )
    ) {
      const saleToSubmit = {
        ...sale,
        ticket_birthday_people: sale.ticket_birthday_people.map((person) => ({
          ...person,
          birth_date: moment(person.birth_date, [
            "DD/MM/YYYY",
            "YYYY/MM/DD",
          ]).format("YYYY-MM-DD"),
        })),
      };

      setLoad(true);
      setTimeout(() => {
        Api.post(`/orders/local`, saleToSubmit)
          .then((res) => {
            alert("Venda lançada com sucesso!");
            handleChangeUser();
            setSale(JSON.parse(JSON.stringify(initSale)));
          })
          .catch((err) => {
            toast.error(err.message);
          })
          .finally(() => {
            setLoad(false);
          });
      }, 1000);
    }
  };

  const hasParticipants =
    Number(sale.players) +
      Number(sale.not_players) +
      Number(sale.minors) +
      Number(sale.minors_responsibles) +
      Number(sale.dps) +
      Number(sale.dps_responsibles) +
      Number(sale.courtesy_players) +
      Number(sale.courtesy_not_players) >
    0;

  const validateParticipants = () => {
    if (sale.minors > 0 && !sale.minors_responsibles)
      return (
        toast.warning(
          "As crianças menores de 5 anos devem ter pelo menos 1 acompanhante!"
        ) || true
      );
  };

  const handleGeneralTab = () => {
    if (validateParticipants()) return;
    setTab(hasParticipants ? "general" : "products");
  };

  const validateGeneral = () => {
    if (disableBirthday) {
      return (
        toast.warning(
          " O aniversariante não está no período de desconto, verifique a aba de participantes."
        ) || true
      );
    }
    if (hasParticipants) {
      if (!sale.date)
        return (
          toast.warning(
            "É necessário informar a data do ingresso para continuar!"
          ) || true
        );
      if (!sale.duration)
        return (
          toast.warning(
            "É necessário informar o tempo de duração no parque para continuar!"
          ) || true
        );
      if (!sale.ticket_time)
        return (
          toast.warning(
            "É necessário informar a hora de entrada no parque para continuar!"
          ) || true
        );
      if (
        sale.filial === "SANTO_AMARO" &&
        (!sale.ticket_type || !sale.ticket_type[1])
      )
        return (
          toast.warning(
            "É necessário informar pelo menos dois espaços no parque para continuar!"
          ) || true
        );

      if (sale.is_birthday === 1 && sale.ticket_birthday_people.length === 0)
        return (
          toast.warning(
            "É necessário fazer a consulta do aniversariante na aba de participantes!"
          ) || true
        );
    }
  };

  const handlePaymentTab = () => {
    if (validateParticipants()) return;
    if (validateGeneral()) return;
    setTab("payment");
  };

  const handleCancel = () => {
    setTab("participants");
    handleChangeUser();
    setSale(JSON.parse(JSON.stringify(initSale)));
  };

  const handleProductsTab = () => {
    if (validateParticipants()) return;
    if (validateGeneral()) return;
    setTab("products");
  };

  const getProducts = () => {
    setLoadingProducts(true);
    Api.get(`products`, {
      params: { unit_id: selectedUnitId, ticket_type: "ticket" },
    })
      .then((res) => {
        setSale((prev) => ({ ...prev, products: res.data.data }));
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingProducts(false));
  };

  useEffect(() => {
    if (selectedUnitId) getProducts();
  }, [selectedUnitId]);

  const hasNewDisabilityType = sale?.ticket_disabled_people?.some(
    (item) => item.disability_type_id === "new"
  );

  return (
    <div className="d-flex flex-column">
      <Card>
        <Card.Body className="d-flex justify-content-around">
          <span className="fs-4">
            {user.id ? `Cliente: ${user.name} ${user.lastName}` : "Sem cliente"}
          </span>
          {handleChangeUser && (
            <Button
              variant="link"
              className="ml-auto p-0"
              onClick={handleChangeUser}
            >
              Trocar cliente
            </Button>
          )}
        </Card.Body>
      </Card>
      <div className="overflow-auto p-1 w-100">
        <ButtonGroup className="w-100">
          <Button
            variant={tab === "participants" ? "primary" : "outline-primary"}
            onClick={() => setTab("participants")}
            disabled={!user.id}
          >
            Participantes
          </Button>
          <Button
            variant={tab === "general" ? "primary" : "outline-primary"}
            onClick={handleGeneralTab}
            disabled={!hasParticipants || hasNewDisabilityType}
          >
            Ingresso
          </Button>
          <Button
            variant={tab === "products" ? "primary" : "outline-primary"}
            onClick={() => setTab("products")}
            disabled={hasNewDisabilityType || disableBirthday}
          >
            Produtos
          </Button>
          <Button
            variant={tab === "payment" ? "primary" : "outline-primary"}
            onClick={handlePaymentTab}
            disabled={hasNewDisabilityType}
          >
            Pagamento
          </Button>
        </ButtonGroup>
      </div>
      {tab === "participants" && (
        <Participants
          handleNext={handleGeneralTab}
          handleCancel={handleCancel}
          sale={sale}
          setSale={setSale}
          yesNo={yesNo}
          setDisableBirthday={setDisableBirthday}
        />
      )}
      {tab === "general" && (
        <General
          handleNext={handleProductsTab}
          handleCancel={handleCancel}
          sale={sale}
          setSale={setSale}
          parkAreas={parkAreas}
        />
      )}
      {tab === "products" && (
        <Products
          handleNext={handlePaymentTab}
          handleCancel={handleCancel}
          sale={sale}
          setSale={setSale}
          loading={loadingProducts}
          setDisableBirthday={setDisableBirthday}
        />
      )}
      {tab === "payment" && (
        <Payment
          nextLabel={"Confirmar Venda"}
          handleCancel={handleCancel}
          handleNext={handleConfirmSale}
          nextDisabled={load}
          sale={sale}
          setSale={setSale}
          yesNo={yesNo}
          user={user}
        />
      )}
    </div>
  );
};

export default Sale;
