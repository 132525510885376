/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  FormLabel,
  Modal,
  Row,
} from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import { CSVLink } from "react-csv";
import { AiFillDelete, AiOutlineEdit } from "react-icons/ai";
import { BiSearchAlt } from "react-icons/bi";
import { GiPartyPopper, GiReceiveMoney } from "react-icons/gi";
import { RiUserSearchLine } from "react-icons/ri";
import Select from "react-select";
import { toast } from "react-toastify";
import PageTitle from "../../components/PageTitle";
import Table from "../../components/Tables";
import {
  checkAvailability,
  checkExclusivaParty,
  deletPlayer,
  handleHourTicket,
  handleTicketSpace,
  handleUpload,
  sumAllTickets,
} from "../../utils/mockupSimulator";
import { handlePartyTypeColl } from "../ManagerParty/UtilsParty";
import { InputBtnGroup } from "../NewParties/InputBtnGroup";
import { FilialContext } from "../../context/FilialContext";
import { Products } from "../ManagerParty/Products";
import { useSimulator, useTickets } from "../../components/Pagination";
import { injectStyle } from "react-toastify/dist/inject-style";
import { filialOptions } from "../ManagerParty/hours";
import { ModalPartyInfoSimulator } from "./ModalView";
import { ModalConfirmParty } from "./ModalConfirm";
import Api from "../../api/api";
import { dateFormated } from "../../utils/dateFormated";
import { format } from "date-fns";
import { PulseLoader } from "react-spinners";
import { PagesBtn } from "../../components/Pagination/PagesBtn";
import { ModalDeletSimulator } from "./ModalDelete";
import DonutChart from "../../components/Chart/DonutChart";
import {
  handleFilialHours,
  handleFilialOptions,
} from "../../utils/handleOptionsFilial";
import set from "date-fns/esm/fp/set/index.js";
import SimulatorMetrics from "./SimulatorMetrics";
import axios from "axios";

import { AuthContext } from "../../context/AuthContext";
import moment from "moment";

export const Simulator = () => {
  const { filial } = useContext(FilialContext);
  const { permissionGroup } = useContext(AuthContext);

  if (typeof window !== "undefined") {
    injectStyle();
  }
  const [openModalDelet, setOpenModalDelet] = useState(false);
  const [availableHours, setAvailableHours] = useState([]);
  const [collumn, setCollumn] = useState();
  const [collumnOff, setCollumnOff] = useState();
  const [collumnNotUndefined, setCollumnNotUndefined] = useState([]);
  const [userInfo, setUserInfo] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [userEdit, setUserEdit] = useState();
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [products, setProducts] = useState([]);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [cleanProducts, setCleanProducts] = useState(false);
  const [productsTotalPrice, setProductsTotalPrice] = useState(0);
  const [cortesia, setCortesia] = useState(0);
  const [plusOneCortesia, setPlusOneCortesia] = useState(0);
  const [reservType, setReservType] = useState();
  const [openModalPayment, setOpenModalPayment] = useState(false);
  const [partiesType, setPartiesType] = useState();
  const [regularTicket, setRegularTicket] = useState(0);
  const [plusOneTicket, setPlusOneTicket] = useState(0);
  const [ticketTime, setTicketTime] = useState();
  const [addtionalInformation, setAddtionalInformation] = useState();
  const [duration, setDuration] = useState(userEdit?.duration | 0);
  const [ticketTotal, setTicketTotal] = useState(0);
  const [sumTicketsPreEdit, setSumTicketsPreEdit] = useState(0);
  const [hourPreEdit, sethourPreEdit] = useState();
  const [date, setDate] = useState("");
  const inpTicketTimeRef = useRef(null);
  const [ticketType, setTicketType] = useState();
  const [searchTicket, setSearchTicket] = useState("");
  const [kidsTickets, setKidsTickets] = useState(0);
  const [kidsPlusOneTickets, setKidsPlusOneTickets] = useState(0);
  const [pcdTickets, setPcdTickets] = useState(0);
  const [dataCSV, setDataCSV] = useState([]);
  const [pcdPlusOneTickets, setPcdPlusOneTickets] = useState(0);
  const [openModalStatusPayment, setOpenModalStatusPayment] = useState(false);
  const [message, setMessage] = useState();
  const [contracts, setContracts] = useState([]);
  const [menus, setMenus] = useState([]);
  const [players, setPlayers] = useState([]);
  const [totalCost, setTotalCost] = useState([]);
  const [contractFile, setContractFile] = useState(null);
  const [response, setResponse] = useState(null);
  const [update, setUpdate] = useState(0);
  const [newSimulationsColls, setNewSimulationsColls] = useState(null);
  const [scheduledSimulationsColls, setScheduledSimulationsColls] =
    useState(null);
  const [newTableSimulationsColls, setNewTableSimulationsColls] =
    useState(null);
  const [newTableSimulations, setNewTableSimulations] = useState(null);
  const [sentSimulationsColls, setSentSimulationsColls] = useState(null);
  const [newSimulations, setNewSimulations] = useState(null);
  const [scheduledSimulations, setScheduledSimulations] = useState(null);
  const [sentSimulations, setSentSimulations] = useState(null);
  const [page, setPage] = useState(1);
  const [perPageSimulator, setPerPageSimulator] = useState(10);
  const [pageSc, setPageSc] = useState(1);
  const [perPageSimulatorSc, setPerPageSimulatorSc] = useState(10);
  const [pageSt, setPageSt] = useState(1);
  const [pageNewTable, setPageNewTable] = useState(1);
  const [perPageNewTable, setPerPageNewTable] = useState(10);
  const [perPageSimulatorSt, setPerPageSimulatorSt] = useState(10);
  const [prodMeiaAntiDerrapante, setProdMeiaAntiDerrapante] = useState(0);
  const [prodMeia, setProdMeia] = useState(0);
  const [prodCopo, setProdCopo] = useState(0);
  const [towel, setTowel] = useState(0);
  const [phone, setPhone] = useState();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [emailSc, setEmailSc] = useState();
  const [menu, setMenu] = useState(false);
  const [menu2, setMenu2] = useState(false);
  const [menu3, setMenu3] = useState(false);
  const [menu4, setMenu4] = useState(false);
  const [kiosk, setKiosk] = useState([]);
  const [cottonCandy, setCottonCandy] = useState(0);
  const [cottonCandyMa, setCottonCandyMa] = useState();
  const [armario, setArmario] = useState(0);
  const [monitorExclusive, setMonitorExclusive] = useState(0);
  const [kioskTarget, setKioskTarget] = useState();
  const [durationKiosk, setDurationKiosk] = useState();
  const [buffet, setBuffet] = useState();
  const [handleBuffet, setHandleBuffet] = useState(true);
  const [arrayMoreinfo, setArrayMoreinfo] = useState();

  const [filter, setFilter] = useState({
    creation_start_date: "",
    creation_end_date: "",
  });

  const [loadCSV, setLoadCSV] = useState({
    schedule: false,
    sent: false,
    doubts: false,
    lost: false,
  });

  const arrayKiosks = [
    {
      label: "3hrs",
      value: 3,
    },
    {
      label: "4hrs",
      value: 4,
    },
    {
      label: "5hrs",
      value: 5,
    },
    {
      label: "6hrs",
      value: 6,
    },
  ];
  const arrayKiosksSingle = [
    {
      label: "3hrs de diversão",
      value: 3,
    },
    {
      label: "4hrs de diversão",
      value: 4,
    },
  ];
  function notify(text) {
    toast.success(text);
  }
  function notifyError(text) {
    toast.error(text);
  }
  function handlePage() {
    setPage(page + 1);
  }
  function handleTicketSpace(value) {
    if (filial === "TATUAPE") {
      return;
    }
    setTicketType(value);
  }
  function handlePartyType(value) {
    if (filial === "TATUAPE") {
      return;
    }
    setPartiesType(value);
  }
  function handleKiosk(event) {
    setDurationKiosk({
      label: `${event.value}hrs`,
      value: event.value,
    });
  }
  function handleHourTicket(event) {
    setDuration({
      label: `${event.value}hrs de diversão`,
      value: event.value,
    });
  }

  function handleKioskObj(event) {
    setKioskTarget({
      label: `${event?.label}`,
      price: +event?.price,
      value: `${event?.value}`,
    });
  }
  function dateFormated(date) {
    const dataInput = date;
    const data = new Date(dataInput);
    const dataFormatada = data.toLocaleDateString("pt-BR", { timeZone: "UTC" });
    return dataFormatada;
  }

  function handlePartyTypes(value) {
    if (value === "single") {
      return { label: "Festa Avulsa", value: "single" };
    } else if (value === "exclusive") {
      return { label: "Festa Exclusiva", value: "exclusive" };
    } else {
      return { label: value, value: value };
    }
  }
  // useEffect(() => {
  //   Api.get(`/ticket/template/${date}/${filial}`).then((res) => {
  //     setAvailableHours(res?.data.hours);
  //   });
  // }, [date, filial, duration]);
  function checkExclusivaParty(item) {
    if (partiesType?.value === "exclusive" && item > 0) {
      return true;
    }
    return;
  }

  const sumAllTickets = () => {
    return Number(
      +regularTicket +
        kidsTickets +
        kidsPlusOneTickets +
        pcdTickets +
        pcdPlusOneTickets +
        cortesia
    );
  };
  useEffect(() => {
    if (kioskTarget?.value === "kiosk_0") {
      setHandleBuffet(false);
    } else {
      setHandleBuffet(true);
    }
  }, [kioskTarget]);

  useEffect(() => {
    if (menu) {
      setMenu2(false);
      setMenu3(false);
      setMenu4(false);
    } else if (menu2) {
      setMenu(false);
      setMenu3(false);
      setMenu4(false);
    } else if (menu3) {
      setMenu(false);
      setMenu2(false);
      setMenu4(false);
    } else if (menu4) {
      setMenu(false);
      setMenu2(false);
      setMenu3(false);
    }
  }, [menu, menu2, menu3, menu4]);
  // useEffect(() => {
  //   Api.post(`/party_simulator/party_price`, {
  //     filial,
  //     spaces: ticketType,
  //     duration: +duration?.value,
  //     regular: +regularTicket,
  //     plus_one: +plusOneTicket,
  //     kids: +kidsTickets,
  //     kids_plus_one: +kidsPlusOneTickets,
  //     pcd: +pcdTickets,
  //     pcd_plus_one: +pcdPlusOneTickets,
  //     date,
  //     time: ticketTime === "noite" ? "19:00" : "15:00",
  //     kiosk_1: false,
  //     kiosk_2: false,
  //     kiosk_3: false,
  //     kiosk_4: false,
  //     kiosk_5: false,
  //     kiosk_6: false,
  //     kiosk_indoor: false,
  //     buffet: buffet,
  //     menu_1: menu,
  //     menu_2: menu2,
  //     menu_3: menu3,
  //     menu_4: menu4,
  //     common_sock: +prodMeia,
  //     non_slip_sock: +prodMeiaAntiDerrapante,
  //     cup: +prodCopo,
  //     towel: +towel,
  //     cabinet_rental: +armario,
  //     cotton_candy: +cottonCandy,
  //     cotton_candy_machine: +cottonCandyMa,
  //     exclusive_monitor: +monitorExclusive,
  //     kiosks_target: kioskTarget,
  //     model: partiesType ? partiesType : "single",
  //     duration_kiosk: +durationKiosk?.value,
  //   })
  //     .then((res) => {
  //       setTicketTotal(res.data.data?.price_total);
  //     })
  //     .catch((err) => {
  //       return err;
  //     });
  // }, [
  //   filial,
  //   ticketType,
  //   duration,
  //   durationKiosk,
  //   regularTicket,
  //   plusOneTicket,
  //   kidsTickets,
  //   kidsPlusOneTickets,
  //   pcdTickets,
  //   pcdPlusOneTickets,
  //   date,
  //   ticketTime,
  //   buffet,
  //   menu,
  //   menu2,
  //   menu3,
  //   menu4,
  //   prodCopo,
  //   prodMeia,
  //   prodMeiaAntiDerrapante,
  //   armario,
  //   towel,
  //   cottonCandy,
  //   cottonCandyMa,
  //   kioskTarget,
  //   partiesType,
  // ]);
  useEffect(() => {
    Api.post(`party_simulator/kiosk`, {
      filial,
      date,
      regular: +regularTicket,
      plus_one: +plusOneTicket,
      kids: +kidsTickets,
      kids_plus_one: +kidsPlusOneTickets,
      pcd: +pcdTickets,
      pcd_plus_one: +pcdPlusOneTickets,
      duration_kiosk: +durationKiosk?.value,
      model: partiesType ? partiesType : "single",
    })
      .then((res) => {
        setKiosk(res.data?.data);
      })
      .catch((err) => {
        return err;
      });
  }, [
    filial,
    date,
    regularTicket,
    plusOneTicket,
    kidsTickets,
    kidsPlusOneTickets,
    pcdTickets,
    pcdPlusOneTickets,
    durationKiosk,
    partiesType,
  ]);
  const getSimulationsList = (url, filial, param, callback = console.log) => {
    const params = new URLSearchParams({ ...param, ...filter }).toString();
    Api.get(`${url}/${filial}?${params}`)
      .then((response) => {
        const data = response?.data;
        setResponse(data?.party?.data);
        callback(data?.party?.data);
      })
      .catch((err) => {
        console.error(err);
        callback(null, { err });
      });
  };

  const getNewSimulations = () => {
    getSimulationsList(
      "party_simulator/list_party",
      `${filial}`,
      {
        per_page: perPageSimulator,
        current_page: page,
        crm: false,
        scheduled: false,
        doubts: false,
        q: searchTicket,
      },
      (res, error) => {
        if (res) setNewSimulations(res);
      }
    );
  };

  const getScheduledSimulations = () => {
    getSimulationsList(
      "party_simulator/list_party",
      `${filial}`,
      {
        per_page: perPageSimulatorSc,
        current_page: pageSc,
        crm: false,
        scheduled: true,
        doubts: false,
        q: searchTicket,
      },
      (res, error) => {
        if (res) setScheduledSimulations(res);
      }
    );
  };

  const getSentSimulations = () => {
    getSimulationsList(
      "party_simulator/list_party",
      `${filial}`,
      {
        per_page: perPageSimulatorSt,
        current_page: pageSt,
        crm: true,
        scheduled: true,
        doubts: false,
        q: searchTicket,
      },
      (res, error) => {
        if (res) setSentSimulations(res);
      }
    );
  };
  const getNewTableSimulations = () => {
    getSimulationsList(
      "party_simulator/list_party",
      `${filial}`,
      {
        per_page: perPageNewTable,
        current_page: pageNewTable,
        crm: false,
        scheduled: false,
        doubts: true,
        q: searchTicket,
      },
      (res, error) => {
        if (res) setNewTableSimulations(res);
      }
    );
  };

  const getAllSimulationsLists = () => {
    getNewSimulations();
    getScheduledSimulations();
    getSentSimulations();
    getNewTableSimulations();
  };

  useEffect(() => {
    getNewSimulations();
  }, [filial, filter, page, perPageSimulator]);

  useEffect(() => {
    getScheduledSimulations();
  }, [filial, filter, pageSc, perPageSimulatorSc]);

  useEffect(() => {
    getSentSimulations();
  }, [filial, filter, pageSt, perPageSimulatorSt]);

  useEffect(() => {
    getNewTableSimulations();
  }, [filial, filter, pageNewTable, perPageNewTable]);

  const setCols = (setter, list) => {
    const coll = list?.map((item) => {
      const dateCreated = item?.created_at ? new Date(item?.created_at) : false;

      return {
        col14: item,
        col2: item,
        col3: item?.date && `${dateFormated(item?.date)}`,
        col4: `${item?.email}`,
        col5: item?.phone,
        col6: `${item?.price_total?.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        })}`,
        col8: item?.name,
        col9: item?.duration,
        col10: dateCreated && `${format(dateCreated, "dd/MM/yyyy (H:mm)")}`,
        col11: item?.filial,
        col12: item,
        col13: item?.spaces,
        col1: `${item?.id?.split("-")[0]}`,
        col16: `${item?.model}`,
        col17: item?.time,
        col18: item?.date,
      };
    });
    setter(coll);
  };

  useEffect(() => {
    setCols(setNewSimulationsColls, newSimulations);
  }, [newSimulations, page, perPageSimulator]);

  useEffect(() => {
    setCols(setScheduledSimulationsColls, scheduledSimulations);
  }, [scheduledSimulations, page, perPageSimulator]);

  useEffect(() => {
    setCols(setSentSimulationsColls, sentSimulations);
  }, [sentSimulations, page, perPageSimulator]);

  useEffect(() => {
    setCols(setNewTableSimulationsColls, newTableSimulations);
  }, [newTableSimulations, page, perPageSimulator]);

  function onCloseModalDelet() {
    setOpenModalDelet(false);
  }

  function onCloseModalConfirm() {
    setOpenModalConfirm(false);
  }

  function onCloseModalEdit() {
    setOpenModalEdit(false);
  }

  function onClose() {
    setOpenModal(false);
  }

  function handleModalView(value) {
    setOpenModal(true);
    setUserInfo(value);
  }

  function handleModalEdit(value) {
    setUserInfo(value);
    setTicketTime(value?.time);
    setDate(value?.date);
    setTicketType(value?.spaces);
    setPartiesType(value?.model);
    setDuration({
      label: `${value?.duration}hrs de diversão`,
      value: value?.duration,
    });
    setTicketTotal(value?.price_total);
    setName(value?.name);
    setPhone(value?.phone);
    setEmail(value?.email);
    setEmailSc(value?.scheduled_by);
    setOpenModalEdit(true);
    setUserEdit(value);
    // sethourPreEdit(value?.ticket_time);
    setRegularTicket(value?.regular);
    setPlusOneTicket(value?.plus_one);
    setKidsTickets(value?.kids);
    // setTotalCost(value?.total_cost);
    setKidsPlusOneTickets(value?.kids_plus_one);
    setPcdPlusOneTickets(value?.pcd_plus_one);
    setPcdTickets(value?.pcd);
    setAddtionalInformation(value?.additional_info);
    // setCortesia(value?.courtesy_players);
    // setPlusOneCortesia(value?.courtesy_not_players);
    // setReservType();
    // setPartiesType(handlePartyTypeColl(value?.model));
    // setPlayers(value?.party_details.players);
    setBuffet(value?.buffet);
    setMenu(value?.menu_1);
    setMenu2(value?.menu_2);
    setMenu3(value?.menu_3);
    setMenu4(value?.menu_4);
    setTowel(value?.towel);
    setCottonCandy(value?.cotton_candy);
    setCottonCandyMa(value?.cotton_candy_machine);
    setArmario(value?.cabinet_rental);
    setMonitorExclusive(value?.exclusive_monitor);
    setProdMeia(value?.common_sock);
    setArmario(value?.cabinet_rental);
    setMonitorExclusive(value?.exclusive_monitor);
    setProdMeiaAntiDerrapante(value?.non_slip_sock);
    setKioskTarget({
      label: `${value?.kiosks_target?.label}`,
      price: +value?.kiosks_target?.price,
      value: `${value?.kiosks_target?.value}`,
    });
    setDurationKiosk({
      label: `${value?.duration_kiosk}hrs`,
      value: +value?.duration_kiosk,
    });
  }
  // function validateHours(hrs){
  //   if(hrs === "Manhã"){
  //     return "09:00";
  //   }else if(hrs === "Tarde" ){
  //     return "15:00";
  //   }else {
  //     return "19:00";
  //   }
  // }
  function handleModalApiEdit(id) {
    Api.put(`/party_simulator/update_info_party/${id}`, {
      scheduled: userInfo?.scheduled,
      scheduled_by: emailSc,
      crm: userInfo?.crm,
      name,
      email,
      phone,
      filial,
      model: partiesType ? partiesType : "single",
      spaces: ticketType,
      duration: +duration.value,
      duration_kiosk: +durationKiosk?.value,
      regular: +regularTicket,
      plus_one: +plusOneTicket,
      kids: +kidsTickets,
      kids_plus_one: +kidsPlusOneTickets,
      pcd: +pcdTickets,
      pcd_plus_one: +pcdPlusOneTickets,
      date,
      time: `${ticketTime}`,
      kiosks_target: kioskTarget
        ? kioskTarget
        : {
            value: "kiosk_1",
            label: "1 Quiosque",
            price: 0,
          },
      buffet: buffet ? buffet : false,
      menu_1: menu,
      menu_2: menu2,
      menu_3: menu3,
      menu_4: menu4,
      common_sock: +prodMeia,
      non_slip_sock: +prodMeiaAntiDerrapante,
      cup: +prodCopo,
      towel: +towel,
      cabinet_rental: +armario,
      cotton_candy: +cottonCandy,
      cotton_candy_machine: +cottonCandyMa,
      exclusive_monitor: +monitorExclusive,
      price_total: +ticketTotal,
      total_kiosk: +userInfo?.total_kiosk,
      total_number_of_guests: +userInfo?.total_number_of_guests,
      additional_info: addtionalInformation,
      created_at: "",
      updated_at: "",
    })
      .then((res) => {
        toast.success("Festa Editada");
        getAllSimulationsLists();
        setOpenModalEdit(false);
      })
      .catch((e) => {
        toast.error("Ocorreu um erro");
      });
  }
  function handleModalDeletTicket(value) {
    setOpenModalDelet(true);
    setUserInfo(value);
  }

  function handleReservType(value) {
    if (value?.status === "REMOVED") {
      return "Removido";
    } else if (value?.crm && value?.scheduled) {
      return "Reserva";
    } else {
      return "Pré-Reserva";
    }
  }

  const col = [
    {
      Header: "Ações",
      accessor: "col12",
      Cell: ({ cell: { value } }) => {
        return (
          <>
            <button
              className="btn  m-0 btn-outline-primary p-1 "
              onClick={() => handleModalView(value)}
            >
              <RiUserSearchLine />
            </button>
            {value.status !== "REMOVED" && (
              <>
                {/* <button
                  className="btn m-0 btn-outline-primary p-1"
                  onClick={() => handleModalEdit(value)}
                >
                  <AiOutlineEdit />
                </button> */}

                <button
                  className="btn  m-0 btn-outline-primary p-1 "
                  onClick={() => handleModalDeletTicket(value)}
                >
                  <AiFillDelete />
                </button>
              </>
            )}
          </>
        );
      },
    },
    {
      width: 120,
      Header: "Status",
      accessor: "col2",
      Cell: ({ cell: { value } }) => {
        return handleReservType(value) === "Removido" ? (
          <p className="text-danger font-weight-bold">Removido</p>
        ) : (
          handleReservType(value)
        );
      },
    },
    {
      Header: "Nome",
      accessor: "col8",
    },
    {
      Header: "Telefone",
      accessor: "col5",
    },
    {
      width: 200,
      Header: "E-mail",
      accessor: "col4",
    },
    {
      Header: "Data de Criação",
      accessor: "col10",
    },
  ];

  const getTableCSV = (params, loader) => {
    setLoadCSV((prev) => ({ ...prev, [loader]: true }));
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}party_simulator/download/csv`,
      responseType: "blob",
      data: { ...params, filial },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        const blob = new Blob([response.data], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "file.csv";
        link.click();
      })
      .finally(() => {
        setLoadCSV((prev) => ({ ...prev, [loader]: false }));
      });
  };

  const handleSearchKeyDown = (e) => {
    const enter = 13;
    if (e.keyCode === enter) getAllSimulationsLists();
  };

  const handleFilterChange = (e) => {
    setFilter((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    document.getElementById("topbar")?.scrollIntoView();
  }, []);

  return (
    <>
      <PageTitle>
        <h1>Gerenciador de Leads</h1>
      </PageTitle>
      <Row className="mb-2">
        <Col lg={5} className="d-flex flex-column">
          <h5>Buscar:</h5>
          <div className="input-group">
            <input
              className="form-control"
              placeholder="Pesquisar (nome, e-mail)"
              value={searchTicket}
              onChange={(e) => setSearchTicket(e.target.value)}
              onKeyDown={handleSearchKeyDown}
            />
            <span
              className="input-group-text cursor-pointer"
              onClick={getAllSimulationsLists}
            >
              <BiSearchAlt />
            </span>
          </div>
        </Col>
        <Col lg={7} className="d-flex flex-column">
          <h5>Filtrar por data de criação:</h5>
          <div className="d-flex gap-1 align-items-center">
            <div className="input-group">
              <span className="input-group-text">Início</span>
              <input
                value={
                  filter.creation_start_date
                    ? moment(filter.creation_start_date).format("YYYY-MM-DD")
                    : null
                }
                onChange={handleFilterChange}
                name="creation_start_date"
                type="date"
                className="form-control"
                placeholder="Data inicial"
              />
            </div>
            <div className="input-group">
              <span className="input-group-text">Fim</span>
              <input
                value={
                  filter.creation_end_date
                    ? moment(filter.creation_end_date).format("YYYY-MM-DD")
                    : null
                }
                onChange={handleFilterChange}
                name="creation_end_date"
                type="date"
                className="form-control"
                placeholder="Data final"
              />
            </div>
          </div>
        </Col>
      </Row>

      {/* <Row>
        <Col lg={5}>
          {collumn && (
          
            <Table data={collumn} columns={col} sizeTable={1000} isSearchable={false}/>
          
        )}
        </Col>
          <Col lg={2}/>
        <Col lg={5}>
          {collumn && (
          
            <Table data={collumn} columns={col} sizeTable={1000} isSearchable={false}/>
          
        )}
        </Col>
      </Row> */}
      <Row>
        <Col xl={6}>
          <Button
            className="btn btn-primary mb-2"
            onClick={() =>
              getTableCSV(
                { crm: false, scheduled: false, doubts: false },
                "doubts"
              )
            }
            disabled={loadCSV["doubts"]}
          >
            {loadCSV["doubts"] ? "Carregando..." : "BAIXAR CSV DESSA TABELA"}
          </Button>
          <Card>
            <Card.Header className="bg-primary">
              <h4 className="text-white">Quer Tirar Dúvidas</h4>
            </Card.Header>
            <Card.Body>
              {newSimulationsColls && (
                <Table data={newSimulationsColls} columns={col} />
              )}
            </Card.Body>
          </Card>
          {/* <button onClick={handlePage}>HandlePage</button> */}
          <PagesBtn
            currentPage={page}
            setCurrentPage={setPage}
            ticketsTotal={newSimulations?.length}
            perPage={perPageSimulator}
            setPerPage={setPerPageSimulator}
          />
        </Col>

        <Col xl={6}>
          <Button
            className="btn btn-primary mb-2"
            onClick={() =>
              getTableCSV(
                { crm: false, scheduled: true, doubts: false },
                "schedule"
              )
            }
            disabled={loadCSV["schedule"]}
          >
            {loadCSV["schedule"] ? "Carregando..." : "BAIXAR CSV DESSA TABELA"}
          </Button>
          <Card>
            <Card.Header className="bg-primary">
              <h4 className="text-white">Agendamento Solicitado</h4>
            </Card.Header>
            <Card.Body>
              {scheduledSimulationsColls && (
                <Table data={scheduledSimulationsColls} columns={col} />
              )}
            </Card.Body>
          </Card>
          <PagesBtn
            currentPage={pageSc}
            setCurrentPage={setPageSc}
            ticketsTotal={scheduledSimulations?.length}
            perPage={perPageSimulatorSc}
            setPerPage={setPerPageSimulatorSc}
          />
        </Col>
      </Row>
      <Row>
        <Col xl={6}>
          <Button
            className="btn btn-primary mb-2"
            onClick={() =>
              getTableCSV({ crm: true, scheduled: true, doubts: false }, "sent")
            }
            disabled={loadCSV["sent"]}
          >
            {loadCSV["sent"] ? "Carregando..." : "BAIXAR CSV DESSA TABELA"}
          </Button>
          <Card>
            <Card.Header className="bg-primary">
              <h4 className="text-white">Enviado ao CRM</h4>
            </Card.Header>
            <Card.Body>
              {sentSimulationsColls && (
                <Table data={sentSimulationsColls} columns={col} />
              )}
            </Card.Body>
          </Card>
          <PagesBtn
            currentPage={pageSt}
            setCurrentPage={setPageSt}
            ticketsTotal={sentSimulations?.length}
            perPage={perPageSimulatorSt}
            setPerPage={setPerPageSimulatorSt}
          />
        </Col>

        <Col xl={6}>
          <Button
            className="btn btn-primary mb-2"
            onClick={() =>
              getTableCSV(
                { crm: false, scheduled: false, doubts: true },
                "lost"
              )
            }
            disabled={loadCSV["lost"]}
          >
            {loadCSV["lost"] ? "Carregando..." : "BAIXAR CSV DESSA TABELA"}
          </Button>
          <Card>
            <Card.Header className="bg-primary">
              <h4 className="text-white">Oportunidade Perdida</h4>
            </Card.Header>
            <Card.Body>
              {newTableSimulationsColls && (
                <Table data={newTableSimulationsColls} columns={col} />
              )}
            </Card.Body>
          </Card>
          <PagesBtn
            currentPage={pageNewTable}
            setCurrentPage={setPageNewTable}
            ticketsTotal={newTableSimulationsColls?.length}
            perPage={perPageNewTable}
            setPerPage={setPerPageNewTable}
          />
        </Col>
      </Row>

      <ModalDeletSimulator
        openModalView={openModalDelet}
        onCloseModalView={onCloseModalDelet}
        userInfo={userInfo}
        setUpdate={setUpdate}
        getAllSimulationsLists={getAllSimulationsLists}
      />
      <ModalPartyInfoSimulator
        user={userInfo}
        isOpen={openModal}
        onClose={onClose}
        setUpdate={setUpdate}
        getAllSimulationsLists={getAllSimulationsLists}
      />
      <ModalConfirmParty
        openModalConfirm={openModalConfirm}
        onCloseModalConfirm={onCloseModalConfirm}
        userInfo={userInfo}
        setUpdate={setUpdate}
      />

      <Modal
        show={openModalEdit}
        onHide={onCloseModalEdit}
        size="xl"
        userInfo={userInfo}
      >
        <Modal.Header className="bg-primary">
          <h1 className="text-white">Alterar informações de Leads</h1>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col lg={3}>
                <FormLabel>Adiar Festa</FormLabel>
                <input
                  type="date"
                  className="form-control"
                  value={date.substr(0, 10)}
                  onChange={(e) => setDate(e.target.value)}
                />
              </Col>
              <Col lg={3}>
                <FormLabel>Email resp. agendamento</FormLabel>
                <input
                  type="text"
                  className="form-control"
                  value={emailSc}
                  onChange={(e) => setEmailSc(e.target.value)}
                />
              </Col>

              <Col lg={3}>
                <FormLabel>Horário</FormLabel>
                <select
                  ref={inpTicketTimeRef}
                  id="ticket-time-test"
                  className={`ticket-schedule form-select`}
                  onChange={(e) => {
                    setTicketTime(`${e.currentTarget.value}`);
                  }}
                  value={ticketTime}
                >
                  <option value="" selected>
                    Selecione o Horário
                  </option>
                  <option value="Manha">Manhã</option>
                  <option value="Tarde">Tarde</option>
                  <option value="Noite">Noite</option>
                </select>
              </Col>
              <Col lg={3}>
                <FormLabel>Espaços</FormLabel>
                <Select
                  classNamePrefix="react-select"
                  type="text"
                  isMulti
                  value={ticketType}
                  options={handleFilialOptions(filial)}
                  onChange={handleTicketSpace}
                  styles={{
                    control: (base) => ({ ...base, borderRadius: "2rem" }),
                    value: (base) => ({
                      ...base,
                      borderRadius: "2rem",
                    }),
                    multiValue: (base) => ({
                      ...base,
                      borderRadius: "2rem",
                    }),
                  }}
                />
              </Col>

              <Col lg={3}>
                <FormLabel>Nome</FormLabel>
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Col>
              <Col lg={3}>
                <FormLabel>Telefone</FormLabel>
                <input
                  type="text"
                  className="form-control"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </Col>

              <Col lg={3}>
                <FormLabel>Email do cliente</FormLabel>
                <input
                  type="text"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Col>
              <Col lg={3}>
                <FormLabel>Duração:</FormLabel>
                <Select
                  classNamePrefix="react-select"
                  name="duration"
                  className={` m-0 p-0 `}
                  options={
                    userInfo?.model === "exclusive"
                      ? arrayKiosksSingle
                      : handleFilialHours(filial)
                  }
                  onChange={handleHourTicket}
                  value={duration}
                  placeholder={"Tempo de diversão"}
                  isSearchable={false}
                  styles={{
                    control: (base) => ({ ...base, borderRadius: "2rem" }),
                    value: (base) => ({
                      ...base,
                      borderRadius: "2rem",
                    }),
                    multiValue: (base) => ({
                      ...base,
                      borderRadius: "2rem",
                    }),
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col lg={3}>
                <FormLabel>Quiosques</FormLabel>
                <Select
                  classNamePrefix="react-select"
                  name="Kiosks"
                  className={` m-0 p-0 `}
                  options={kiosk}
                  value={kioskTarget}
                  onChange={handleKioskObj}
                  placeholder={"Selecione os Quiosques"}
                  isSearchable={false}
                  styles={{
                    control: (base) => ({ ...base, borderRadius: "2rem" }),
                    value: (base) => ({
                      ...base,
                      borderRadius: "2rem",
                    }),
                    multiValue: (base) => ({
                      ...base,
                      borderRadius: "2rem",
                    }),
                  }}
                />
              </Col>
              {userInfo?.model !== "exclusive" && (
                <>
                  {handleBuffet && (
                    <Col lg={3}>
                      <FormLabel>Duração Quiosque</FormLabel>
                      <Select
                        classNamePrefix="react-select"
                        name="duration kiosk"
                        className={` m-0 p-0 `}
                        options={arrayKiosks}
                        onChange={handleKiosk}
                        value={durationKiosk}
                        placeholder={"Duração Kiosk"}
                        isSearchable={false}
                        styles={{
                          control: (base) => ({
                            ...base,
                            borderRadius: "2rem",
                          }),
                        }}
                      />
                    </Col>
                  )}
                </>
              )}

              {handleBuffet && (
                <Col lg={3}>
                  <FormLabel>Buffet?</FormLabel>
                  <select
                    id="ticket-time-test"
                    className={`form-select`}
                    value={buffet}
                    onChange={(e) => {
                      setBuffet(e.currentTarget.value);
                    }}
                  >
                    <option value="" selected>
                      Selecione o buffet
                    </option>
                    <option value={true}>Sim</option>
                    <option value="">Não</option>
                  </select>
                </Col>
              )}

              {handleBuffet && (
                <Col lg={3}>
                  <FormLabel>Menus</FormLabel>
                  <div className=" d-flex justify-content-center align-center gap-1">
                    <label type="text">
                      Menu 1
                      <input
                        type="checkbox"
                        checked={menu}
                        onChange={(e) => setMenu(!menu)}
                      />
                    </label>
                    <label type="text">
                      Menu 2
                      <input
                        type="checkbox"
                        checked={menu2}
                        onChange={(e) => setMenu2(!menu2)}
                      />
                    </label>

                    <label type="text">
                      Menu 3
                      <input
                        type="checkbox"
                        checked={menu3}
                        onChange={(e) => setMenu3(!menu3)}
                      />
                    </label>
                    <label type="text">
                      Menu 4
                      <input
                        type="checkbox"
                        checked={menu4}
                        onChange={(e) => setMenu4(!menu4)}
                      />
                    </label>
                  </div>
                </Col>
              )}
              <Col lg={3}>
                <FormLabel>Valor Total</FormLabel>
                <input
                  type="number"
                  className="form-control"
                  value={ticketTotal}
                  disabled
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col lg={4} className="border-right">
                <InputBtnGroup
                  state={regularTicket}
                  stateInput={setRegularTicket}
                  text={"Quantos irão brincar?"}
                />

                <InputBtnGroup
                  state={plusOneTicket}
                  stateInput={setPlusOneTicket}
                  text={"Quantos Não irão brincar?"}
                />
              </Col>
              <Col lg={4} className="border-right">
                <InputBtnGroup
                  state={pcdTickets}
                  stateInput={setPcdTickets}
                  text={"PCD Brincantes"}
                />

                <InputBtnGroup
                  state={pcdPlusOneTickets}
                  stateInput={setPcdPlusOneTickets}
                  text={"Acompanhantes PCD"}
                />
              </Col>
              <Col lg={4}>
                <InputBtnGroup
                  state={kidsTickets}
                  stateInput={setKidsTickets}
                  text={"Crianças Brincantes ?"}
                />

                <InputBtnGroup
                  state={kidsPlusOneTickets}
                  stateInput={setKidsPlusOneTickets}
                  text={"Acompanhantes de Crianças?"}
                />
              </Col>
            </Row>
            <Row>
              {filial !== "BRASILIA" && (
                <Col lg={"4"} md="6">
                  <InputBtnGroup
                    state={prodMeia}
                    stateInput={setProdMeia}
                    text={`Meia Comum $${
                      filial === "TATUAPE" ? "20.00" : "15.00"
                    }`}
                  />
                </Col>
              )}
              {filial === "SANTO_AMARO" && (
                <Col lg={4} md="6">
                  <InputBtnGroup
                    state={prodMeiaAntiDerrapante}
                    stateInput={setProdMeiaAntiDerrapante}
                    text={"Meia Antiderrapante R$20.00"}
                  />
                </Col>
              )}

              <Col lg={"4"} md="6">
                <InputBtnGroup
                  state={towel}
                  stateInput={setTowel}
                  text="Toalha Comum R$20.00"
                />
              </Col>
              {filial !== "BRASILIA" && (
                <Col lg={"4"} md="6">
                  <InputBtnGroup
                    state={prodCopo}
                    stateInput={setProdCopo}
                    text="Copo R$10.00"
                  />
                </Col>
              )}
              {filial !== "BRASILIA" && (
                <Col lg={"4"} md="6">
                  <InputBtnGroup
                    state={cottonCandy}
                    stateInput={setCottonCandy}
                    text="Algodão Doce R$5.00"
                  />
                </Col>
              )}
              {filial !== "BRASILIA" && (
                <Col lg={"4"} md="6">
                  <InputBtnGroup
                    state={cottonCandyMa}
                    stateInput={setCottonCandyMa}
                    text="Maquina de algodão doce R$250"
                  />
                </Col>
              )}
              <Col lg={"4"} md="6">
                <InputBtnGroup
                  state={armario}
                  stateInput={setArmario}
                  text="Locação de Armário R$5.00"
                />
              </Col>
              {filial !== "BRASILIA" && (
                <Col lg={"4"} md="6">
                  <InputBtnGroup
                    state={monitorExclusive}
                    stateInput={setMonitorExclusive}
                    text="Monitor exclusivo R$250"
                  />
                </Col>
              )}
            </Row>

            <Row className="mt-2">
              <Col lg="12">
                <FormLabel>Informações Adicionais</FormLabel>
                <textarea
                  value={addtionalInformation}
                  onChange={(e) => setAddtionalInformation(e.target.value)}
                  name=""
                  className="w-100 form-control"
                  id=""
                  cols="10"
                  rows="10"
                ></textarea>
              </Col>
            </Row>

            <Button
              type="button"
              className="mt-4"
              onClick={() => handleModalApiEdit(userInfo?.id)}
            >
              Confirmar
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      {permissionGroup === "Administrador" && <SimulatorMetrics />}
    </>
  );
};
