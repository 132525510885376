import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { injectStyle } from "react-toastify/dist/inject-style";
import Api from '../../api/api'

export default function DeletUser({ open, close, user, setUpdated }) {

  if (typeof window !== "undefined") {
    injectStyle();
  }

  function deletUser() {
    Api.delete(`dashboard/delete/${user.id}`).then(
      (res) => {
        setUpdated(prevState => prevState + 1)
        toast.success('Usuário Deletado')
        close()
      }
    ).catch(e => {
      toast.error('Erro')
    })
  }

  return (
    <>
      <Modal show={open} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title >
           Excluir usuário
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h5> Tem certeza de que deseja apagar a conta relacionada ao email abaixo?</h5>
          <p className='text-primary font-weight-bold text-danger'>{user?.email}</p>
          <p className='text-warning'>Observação: os dados relacionados a esse usuário serão apagados.</p>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={close}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={() => deletUser()} >
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal></>
  )
}