import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import Api from "../../api/api";
import { FilialContext } from "../../context/FilialContext";
import Select from "react-select";
import {
  getTicketType,
  getTicketTypes,
  weekDaysList,
} from "../../utils/functions";

const EditModal = ({ open, setOpen, id, setID, onSuccess }) => {
  const { selectedUnitId } = useContext(FilialContext);
  const init = {
    unit_id: selectedUnitId,
    name: "",
    week_days: [],
    areas_amount: 1,
    duration: 1,
    event_duration: 1,
    player_price: 0,
    not_player_price: 0,
    minor_price: 0,
    minor_responsible_price: 0,
    dp_price: 0,
    dp_responsible_price: 0,
    additional_hour_price: 0,
    type: "ticket",
    exclusive_party_limit: 0,
  };
  const [values, setValues] = useState(init);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);

  const getData = () => {
    setValues(init);
    setLoadingData(true);
    Api.get(`/tickets/${id}`)
      .then((res) => {
        setValues(res.data.data);
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingData(false));
  };

  useEffect(() => {
    if (id) getData();
  }, [id]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setValues((prev) => {
      if (prev.type !== "exclusive" && e.target.value === "exclusive") {
        prev.not_player_price = 0;
        prev.minor_price = 0;
        prev.minor_responsible_price = 0;
        prev.dp_price = 0;
        prev.dp_responsible_price = 0;
        prev.additional_hour_price = 0;
      }

      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleSave = () => {
    setLoadingSave(true);
    if (id)
      return Api.put(`/tickets/${id}`, values)
        .then((res) => {
          toast.success("Ticket atualizado com sucesso!");
          setOpen(false);
          if (onSuccess) onSuccess();
        })
        .catch((err) => toast.error(err.message))
        .finally(() => setLoadingSave(false));
    Api.post("/tickets", values)
      .then((res) => {
        toast.success("Ticket criado com sucesso!");
        setOpen(false);
        if (onSuccess) onSuccess();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingSave(false));
  };

  const handleMultiSelectChange = (e, field) => {
    setValues((prev) => ({ ...prev, [field]: e.map((un) => un.value) }));
  };

  const handleDuplicate = () => {
    setValues((prev) => ({ ...prev, id: null, name: `${prev.name} (Cópia)` }));
    if (setID) setID(null);
  };

  const handleRemove = () => {
    if (
      window.confirm(
        "Deseja realmente remover este ticket? Ele não será mais listado no momento da compra."
      )
    ) {
      setLoadingRemove(true);
      Api.delete(`tickets/${values.id}`)
        .then(() => {
          toast.success("Ticket removido com sucesso!");
          setOpen(false);
          if (onSuccess) onSuccess();
        })
        .catch((err) => {
          setLoadingRemove(false);
          toast.error(err.message);
        });
    }
  };

  const handleSwitchChange = (e) => {
    setValues((prevValues) => ({
      ...prevValues,
      enable_on_checkout: e.target.checked,
    }));
  };

  const loadingLocked = loadingSave || loadingRemove || loadingData;

  return (
    <Modal show={!!open} onHide={loadingLocked ? null : handleClose}>
      <Modal.Title className="text-center">{`${
        values?.id ? "Atualizar dados do" : "Cadastrar novo"
      } ticket`}</Modal.Title>
      <Modal.Body className="d-flex flex-column gap-1">
        <InputGroup>
          <InputGroup.Text>Nome</InputGroup.Text>
          <Form.Control
            value={values?.name}
            name={"name"}
            onChange={handleChange}
            autoFocus
            disabled={loadingLocked}
          />
        </InputGroup>
        <InputGroup>
          <InputGroup.Text>Tipo de ticket</InputGroup.Text>
          <Form.Select
            value={values?.type}
            name={"type"}
            onChange={handleChange}
            disabled={loadingLocked}
          >
            {getTicketTypes().map((type) => (
              <option key={`type_item_${type}`} value={type}>
                {getTicketType(type)}
              </option>
            ))}
          </Form.Select>
        </InputGroup>
        <InputGroup className="d-flex">
          <InputGroup.Text>Dias da semana</InputGroup.Text>
          <Select
            classNamePrefix="react-select"
            closeMenuOnSelect={false}
            value={values?.week_days?.map((ticketWeekDay) => ({
              value: ticketWeekDay,
              label: weekDaysList.find((wDay) => wDay.value === ticketWeekDay)
                .label,
            }))}
            onChange={(val) => handleMultiSelectChange(val, "week_days")}
            options={weekDaysList}
            isMulti
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                borderTopRightRadius: "2rem",
                borderBottomRightRadius: "2rem",
              }),
              container: (baseStyles, state) => ({
                ...baseStyles,
                alignSelf: "stretch",
                flex: 1,
              }),
            }}
            disabled={loadingLocked}
          />
        </InputGroup>
        <InputGroup>
          <InputGroup.Text>Quantidade de áreas</InputGroup.Text>
          <Form.Control
            value={values?.areas_amount}
            name={"areas_amount"}
            onChange={handleChange}
            type={"number"}
            min={1}
            max={3}
            disabled={loadingLocked}
          />
        </InputGroup>
        <InputGroup>
          <InputGroup.Text>Duração do ingresso (horas)</InputGroup.Text>
          <Form.Control
            value={values?.duration}
            name={"duration"}
            onChange={handleChange}
            type={"number"}
            min={1}
            step={1}
            disabled={loadingLocked}
          />
        </InputGroup>
        {!["ticket"].includes(values?.type) && (
          <InputGroup>
            <InputGroup.Text>Duração do evento (horas)</InputGroup.Text>
            <Form.Control
              value={values?.event_duration}
              name={"event_duration"}
              onChange={handleChange}
              type={"number"}
              min={1}
              disabled={loadingLocked}
            />
          </InputGroup>
        )}
        <InputGroup>
          <InputGroup.Text>Preço brincante</InputGroup.Text>
          <Form.Control
            value={values?.player_price}
            name={"player_price"}
            onChange={handleChange}
            type={"number"}
            min={0}
            disabled={loadingLocked}
          />
        </InputGroup>
        {values?.type === "exclusive" && (
          <>
            <InputGroup>
              <InputGroup.Text>Limite de ocupação</InputGroup.Text>
              <Form.Control
                value={values?.exclusive_party_limit}
                name={"exclusive_party_limit"}
                onChange={handleChange}
                type={"number"}
                min={0}
                disabled={loadingLocked}
              />
            </InputGroup>
          </>
        )}
        {values?.type !== "exclusive" && (
          <>
            <InputGroup>
              <InputGroup.Text>Preço não brincante</InputGroup.Text>
              <Form.Control
                value={values?.not_player_price}
                name={"not_player_price"}
                onChange={handleChange}
                type={"number"}
                min={0}
                disabled={loadingLocked}
              />
            </InputGroup>
            <InputGroup>
              <InputGroup.Text>Preço menor de 5 anos</InputGroup.Text>
              <Form.Control
                value={values?.minor_price}
                name={"minor_price"}
                onChange={handleChange}
                type={"number"}
                min={0}
                disabled={loadingLocked}
              />
            </InputGroup>
            <InputGroup>
              <InputGroup.Text>
                Preço acompanhante menor de 5 anos
              </InputGroup.Text>
              <Form.Control
                value={values?.minor_responsible_price}
                name={"minor_responsible_price"}
                onChange={handleChange}
                type={"number"}
                min={0}
                disabled={loadingLocked}
              />
            </InputGroup>
            <InputGroup>
              <InputGroup.Text>Preço PCD</InputGroup.Text>
              <Form.Control
                value={values?.dp_price}
                name={"dp_price"}
                onChange={handleChange}
                type={"number"}
                min={0}
                disabled={loadingLocked}
              />
            </InputGroup>
            <InputGroup>
              <InputGroup.Text>Preço acompanhante PCD</InputGroup.Text>
              <Form.Control
                value={values?.dp_responsible_price}
                name={"dp_responsible_price"}
                onChange={handleChange}
                type={"number"}
                min={0}
                disabled={loadingLocked}
              />
            </InputGroup>
            <InputGroup>
              <InputGroup.Text>Preço da hora adicional</InputGroup.Text>
              <Form.Control
                value={values?.additional_hour_price}
                name={"additional_hour_price"}
                onChange={handleChange}
                type={"number"}
                min={0}
                disabled={loadingLocked}
              />
            </InputGroup>
            {values?.type === "ticket" && (
              <InputGroup>
                <InputGroup.Text className="">
                  Mostrar Ticket no checkout?
                </InputGroup.Text>
                <div className="form-control d-flex">
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    checked={[true, "true"].includes(
                      values?.enable_on_checkout
                    )}
                    onChange={handleSwitchChange}
                    className="font-20"
                    style={{ marginTop: "-4px" }}
                  />
                </div>
              </InputGroup>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose} disabled={loadingLocked}>
          Cancelar
        </Button>
        {values.id && (
          <>
            <Button
              variant="outline-primary"
              onClick={handleDuplicate}
              disabled={loadingLocked}
            >
              {`Duplicar`}
            </Button>
            <Button
              variant="danger"
              onClick={handleRemove}
              disabled={loadingLocked}
            >
              {`Remover`}
            </Button>
          </>
        )}
        <Button onClick={handleSave} disabled={loadingLocked}>
          {`${values?.id ? "Atualizar" : "Cadastrar"}`}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditModal;
