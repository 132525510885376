import React, { useEffect, useState } from "react";
import { Button, Form, FormLabel, InputGroup, Row, Col } from "react-bootstrap";
import Select from "react-select";
import TicketDP from "../../../NewSalesTicket/TicketDP";

const selectStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: "2rem",
    borderBottomRightRadius: "2rem",
  }),
  container: (baseStyles, state) => ({
    ...baseStyles,
    alignSelf: "stretch",
    flex: 1,
  }),
};

const Participants = ({
  handleNext,
  handleCancel,
  sale,
  setSale,
  yesNo,
  bundlesList,
  handleBundleChange,
}) => {
  const [hasCourtesy, setHasCourtesy] = useState(
    sale.courtesy_players > 0 || sale.courtesy_not_players > 0 || false
  );

  const handleChange = (e) => {
    if (e.target.type === "number" && e.target.value < 0) {
      e.target.value = 0;
    }

    if (
      e.target.name === "minors_responsibles" &&
      Number(e.target.value) > sale.minors
    ) {
      e.target.value = sale.minors;
    }

    if (
      e.target.name === "dps_responsibles" &&
      Number(e.target.value) > sale.dps
    ) {
      e.target.value = sale.dps;
    }

    setSale((prev) => {
      let newSale = { ...prev, [e.target.name]: e.target.value };

      if (e.target.name === "minors") {
        if (Number(e.target.value) < prev.minors_responsibles) {
          newSale.minors_responsibles = Number(e.target.value);
        }

        if (Number(e.target.value) === 0) {
          newSale.kids_value = 0;
        }
      }

      if (e.target.name === "minors_responsibles") {
        if (
          Number(e.target.value) === prev.minors &&
          prev.minors > newSale.minors
        ) {
          newSale.minors_responsibles = newSale.minors;
        }
      }

      if (e.target.name === "dps") {
        if (Number(e.target.value) < prev.dps_responsibles) {
          newSale.dps_responsibles = Number(e.target.value);
        }

        if (Number(e.target.value) === 0) {
          newSale.pcd_value = 0;
        }
      }

      if (e.target.name === "dps_responsibles") {
        if (Number(e.target.value) === prev.dps && prev.dps > newSale.dps) {
          newSale.dps_responsibles = newSale.dps;
        }
      }

      return newSale;
    });
  };

  const handleHasKidsChange = (item) => {
    const kidsValue = item.value ? 1 : 0;
    setSale((prev) => ({
      ...prev,
      kids_value: kidsValue,
      minors: kidsValue,
      minors_responsibles: kidsValue,
    }));

    if (!item.value) {
      setSale((prev) => ({
        ...prev,
        kids_value: 0,
        minors: 0,
        minors_responsibles: 0,
      }));
    }
  };

  const handleHasPCDChange = (item) => {
    const pcdValue = item.value ? 1 : 0;
    setSale((prev) => ({
      ...prev,
      pcd_value: pcdValue,
      dps: pcdValue,
    }));

    if (!item.value) {
      setSale((prev) => ({
        ...prev,
        dps: 0,
        dps_responsibles: 0,
        ticket_disabled_people: [],
      }));
    }
  };

  const handleTicketDPChange = (ticket_disabled_people) => {
    setSale((prev) => ({ ...prev, ticket_disabled_people }));
  };

  const handleHasCourtesyChange = (item) => {
    setHasCourtesy(item.value);
    if (!item.value)
      setSale((prev) => ({
        ...prev,
        courtesy_players: 0,
        courtesy_not_players: 0,
      }));
  };

  useEffect(() => {
    setSale((prev) => ({
      ...prev,
      total_players:
        Number(sale.players) +
        Number(sale.minors) +
        Number(sale.minors_responsibles) +
        Number(sale.dps) +
        Number(sale.dps_responsibles) +
        Number(sale.courtesy_players),
      total_not_players:
        Number(sale.not_players) + Number(sale.courtesy_not_players),
    }));
  }, [
    sale.players,
    sale.minors,
    sale.minors_responsibles,
    sale.dps,
    sale.dps_responsibles,
    sale.not_players,
    sale.courtesy_players,
    sale.courtesy_not_players,
  ]);

  return (
    <div className="d-flex flex-column gap-3">
      <Row>
        <Col>
          <label>Ingressos Brincantes</label>
          <Form.Control
            value={sale.players}
            name={"players"}
            type={"number"}
            onChange={handleChange}
          />
        </Col>
        <Col>
          <label>Ingressos Não Brincantes</label>
          <Form.Control
            value={sale.not_players}
            name={"not_players"}
            type={"number"}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row>
        <label>Participantes menores de 5 anos?</label>
        <Select
          classNamePrefix="react-select"
          value={yesNo.find(
            (item) => item.value === (sale.kids_value === 1 ? true : false)
          )}
          onChange={(val) => handleHasKidsChange(val)}
          options={yesNo}
          styles={selectStyles}
        />
      </Row>
      {sale.kids_value > 0 && (
        <div className="d-flex flex-column gap-3">
          <Row>
            <Col>
              <label>Ingressos Crianças</label>
              <Form.Control
                value={sale.minors}
                name={"minors"}
                type={"number"}
                onChange={handleChange}
              />
            </Col>
            <Col>
              <label>Ingressos Acompanhantes</label>
              <Form.Control
                value={sale.minors_responsibles}
                name={"minors_responsibles"}
                type={"number"}
                onChange={handleChange}
              />
            </Col>
          </Row>
        </div>
      )}
      <Row>
        <label>Participantes PCD?</label>
        <Select
          classNamePrefix="react-select"
          value={yesNo.find(
            (item) => item.value === (sale.pcd_value === 1 ? true : false)
          )}
          onChange={handleHasPCDChange}
          options={yesNo}
          styles={selectStyles}
        />
      </Row>
      {sale.pcd_value > 0 && (
        <>
          <Row className="mb-3">
            <Col>
              <label>Ingressos PCD</label>
              <Form.Control
                value={sale.dps}
                name="dps"
                type="number"
                onChange={handleChange}
              />
            </Col>
            <Col>
              <label>Ingressos Acompanhantes</label>
              <Form.Control
                value={sale.dps_responsibles}
                name="dps_responsibles"
                type="number"
                onChange={handleChange}
              />
            </Col>
          </Row>
          <FormLabel style={{ margin: 0, padding: 0 }}>Deficiências</FormLabel>
          <TicketDP
            prevList={sale.ticket_disabled_people}
            onChange={handleTicketDPChange}
            totalNumberDps={sale.dps}
          />
        </>
      )}

      <Row>
        <label>Cortesias?</label>
        <Select
          classNamePrefix="react-select"
          value={yesNo.find((item) => item.value === hasCourtesy)}
          onChange={(val) => handleHasCourtesyChange(val)}
          options={yesNo}
          styles={selectStyles}
        />
      </Row>
      {hasCourtesy && (
        <div className="d-flex flex-column gap-3">
          <Row>
            <Col>
              <label>Cortesias Brincantes</label>
              <Form.Control
                value={sale.courtesy_players}
                name={"courtesy_players"}
                type={"number"}
                onChange={handleChange}
              />
            </Col>
            <Col>
              <label>Cortesias Não Brincantes</label>
              <Form.Control
                value={sale.courtesy_not_players}
                name={"courtesy_not_players"}
                type={"number"}
                onChange={handleChange}
              />
            </Col>
          </Row>
        </div>
      )}
      {(handleCancel || handleNext) && (
        <div className="d-flex gap-1 ml-auto">
          {handleCancel && (
            <Button variant="light" onClick={handleCancel}>
              Cancelar
            </Button>
          )}
          {handleNext && <Button onClick={handleNext}>Avançar</Button>}
        </div>
      )}
    </div>
  );
};

export default Participants;
