import React from "react";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import BackgroundSlider from "../../../../components/BackgroundSlider";
import { CenterContainer, Container, TitleWrapper } from "./styles";
import BootstrapCarousel from "../../../../components/BootstrapCarousel";

const PromosCarouselPreviewModal = ({ open, setOpen, values }) => {
  const [previewType, setPreviewType] = useState("desktop");
  const deskPreview = previewType === "desktop";

  const handleClose = () => {
    setOpen(false);
  };

  const togglePreview = () => {
    setPreviewType((prev) => (prev === "desktop" ? "mobile" : "desktop"));
  };

  return (
    <Modal
      fullscreen
      show={open}
      onHide={handleClose}
      contentClassName="bg-primary"
    >
      <Modal.Title className="align-self-center bg-white w-100 text-center m-0">
        Prévia do Carrossel de Promoções
      </Modal.Title>
      <div
        className="position-relative align-self-center w-100"
        style={{
          maxWidth: deskPreview ? "100%" : 400,
        }}
      >
        <BootstrapCarousel
          images={values?.promo_banner_content.map((item) => ({
            src: deskPreview ? item.desktop : item.mobile,
            tooltip: item.tooltip,
          }))}
        />
      </div>
      <Modal.Footer className="bg-white">
        <Button variant="light" onClick={handleClose}>
          Fechar
        </Button>
        <Button onClick={togglePreview}>
          {deskPreview ? "Visualização Mobile" : "Visualização Desktop"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PromosCarouselPreviewModal;
