import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Form, InputGroup, Modal, ProgressBar } from "react-bootstrap";
import { toast } from "react-toastify";
import Api from "../../../api/api";

const EditModal = ({ open, setOpen, id, getList, point_program_id }) => {
  const [loadInfo, setLoadInfo] = useState(false);
  const [load, setLoad] = useState(false);
  const [values, setValues] = useState({});

  const getInfo = () => {
    setLoadInfo(true);
    Api.get(`points_programs_levels/${id}`)
      .then((res) => {
        setValues(res.data);
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadInfo(false));
  };

  useEffect(() => {
    if (id !== "new") getInfo();
    else setLoad(false);
  }, [id]);

  const handleClose = () => setOpen(null);

  const handleChange = (e) =>
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const save = () => {
    setLoad(true);
    if (id === "new")
      return Api.post(`points_programs_levels`, {
        ...values,
        point_program_id,
      })
        .then((res) => {
          getList();
          handleClose();
        })
        .catch((err) => toast.error(err.message))
        .finally(() => setLoad(false));
    Api.put(`points_programs_levels/${id}`, { ...values })
      .then((res) => {
        getList();
        handleClose();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoad(false));
  };

  const remove = () => {
    if (
      window.confirm(
        "Deseja realmente remover este nível do programa de pontos?"
      )
    ) {
      setLoad(true);
      Api.delete(`points_programs_levels/${values.id}`)
        .then((res) => {
          getList();
          handleClose();
        })
        .catch((err) => toast.error(err.message))
        .finally(() => setLoad(false));
    }
  };

  return (
    <Modal show={open} onHide={load ? null : handleClose}>
      <Modal.Title className="align-self-center">{`${
        id === "new" ? "Novo" : "Editar"
      } Nível do Programa de Pontos`}</Modal.Title>
      <Modal.Body>
        {loadInfo ? (
          <ProgressBar animated now={100} />
        ) : (
          <div className="d-flex flex-column gap-1">
            <InputGroup>
              <InputGroup.Text>Nome do Nível</InputGroup.Text>
              <Form.Control
                value={values.name}
                name={"name"}
                onChange={handleChange}
                autoFocus
              />
            </InputGroup>
            <InputGroup>
              <InputGroup.Text>1 Real em Pontos</InputGroup.Text>
              <Form.Control
                value={values.real_points}
                name={"real_points"}
                onChange={handleChange}
                type={"number"}
                min={0}
              />
            </InputGroup>
            <InputGroup>
              <InputGroup.Text>1 Ponto em Reais</InputGroup.Text>
              <Form.Control
                value={values.point_value}
                name={"point_value"}
                onChange={handleChange}
                type={"number"}
                min={0}
              />
            </InputGroup>
            <InputGroup>
              <InputGroup.Text>1 Real de Festas em Pontos</InputGroup.Text>
              <Form.Control
                value={values.party_real_points}
                name={"party_real_points"}
                onChange={handleChange}
                type={"number"}
                min={0}
              />
            </InputGroup>
            <InputGroup>
              <InputGroup.Text>1 Ponto de Festa em Reais</InputGroup.Text>
              <Form.Control
                value={values.party_point_value}
                name={"party_point_value"}
                onChange={handleChange}
                type={"number"}
                min={0}
              />
            </InputGroup>
            <InputGroup>
              <InputGroup.Text>Dias para Expirar Pontos</InputGroup.Text>
              <Form.Control
                value={values.days_to_expire_points}
                name={"days_to_expire_points"}
                onChange={handleChange}
                type={"number"}
                min={0}
              />
            </InputGroup>
            <InputGroup>
              <InputGroup.Text>
                Pontos Utilizáveis em Múltiplos de
              </InputGroup.Text>
              <Form.Control
                value={values.multiples_of}
                name={"multiples_of"}
                onChange={handleChange}
                type={"number"}
                min={0}
              />
            </InputGroup>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={load} onClick={handleClose} variant="light">
          Fechar
        </Button>
        {values.id > 0 && (
          <Button onClick={remove} disabled={load} variant="danger">
            Remover
          </Button>
        )}
        <Button disabled={load} onClick={save} variant="primary">
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditModal;
