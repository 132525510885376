import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  Button,
  Col,
  Form,
  InputGroup,
  Modal,
  ProgressBar,
  Row,
  Card,
} from "react-bootstrap";
import { toast } from "react-toastify";
import Api from "../../api/api";
import { FilialContext } from "../../context/FilialContext";
import moment from "moment";
import TimesModal from "./TimesModal";
import RescheduleModal from "./RescheduleModal";
import ReactSelect from "react-select";
import { getTicketType, getTicketTypes } from "../../utils/functions";
import OccupationPerHourChart from "../../components/CalendarDateHoursChart/OccupationPerHourChart";
import AvailableSpaces from "../../components/CalendarDateHoursChart/AvailableSpaces";
import RenderNotPlayers from "../../components/CalendarDateHoursChart/RenderNotPlayers";
import CalendarDatesHoursUpdateLogModal from "./CalendarDatesHoursUpdateLogModal";

const selectStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderRadius: "1rem",
  }),
  container: (baseStyles, state) => ({
    ...baseStyles,
    alignSelf: "stretch",
    flex: 1,
  }),
  value: (base) => ({ ...base, borderRadius: "1rem" }),
  multiValue: (base) => ({ ...base, borderRadius: "1rem" }),
};

const EditModal = ({ open, setOpen, date, onSuccess }) => {
  const { selectedUnitId } = useContext(FilialContext);
  const [values, setValues] = useState({
    unit_id: selectedUnitId,
    date,
  });
  const [loadingData, setLoadingData] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [openTimesModal, setOpenTimesModal] = useState(false);
  const [openRescheduleModal, setOpenRescheduleModal] = useState(false);
  const [loadingUpdateOpenFor, setLoadingUpdateOpenFor] = useState(false);
  const [newOpenFor, setNewOpenFor] = useState([]);
  const [
    openCalendarDatesHoursUpdateLogModal,
    setOpenCalendarDatesHoursUpdateLogModal,
  ] = useState(null);

  const ticketTypesList = getTicketTypes().map((type) => ({
    value: type,
    label: getTicketType(type),
  }));

  const getData = () => {
    setValues(null);
    setLoadingData(true);
    Api.get(`/calendar_dates/date/just_get`, { params: values })
      .then((res) => {
        setLoadingData(false);
        if (res.data.data) return setValues(res.data.data);

        generateCalendarDate();
      })
      .catch((err) => {
        setLoadingData(false);
        toast.error(err.message);
        if (err.message.includes("não configurada")) setOpen(false);
      });
  };

  const generateCalendarDate = () => {
    if (
      window.confirm(
        "Atenção, a data escolhida ainda não foi gerada, deseja fazer isso agora?"
      )
    ) {
      setValues(null);
      setLoadingData(true);
      Api.get(`/calendar_dates/date`, { params: values })
        .then((res) => {
          setValues(res.data.data);
        })
        .catch((err) => {
          toast.error(err.message);
          if (err.message.includes("não configurada")) setOpen(false);
        })
        .finally(() => setLoadingData(false));
    } else {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (date && selectedUnitId) getData();
  }, [date, selectedUnitId]);

  const handleClose = () => {
    if (loadingSave || loadingData) return;
    setOpen(false);
  };

  const handleChange = (e) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSave = () => {
    setLoadingSave(true);
    Api.put(`/calendar_dates/${values.id}`, values)
      .then((res) => {
        toast.success("Data atualizada com sucesso!");
        setOpen(false);
        if (onSuccess) onSuccess();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingSave(false));
  };

  useEffect(() => {
    setValues((prev) => ({ ...prev, unit_id: selectedUnitId }));
  }, [selectedUnitId]);

  const handleOpenTimesModal = () => {
    setOpenTimesModal(true);
  };

  const handleOpenRescheduleModal = () => {
    setOpenRescheduleModal(true);
  };

  const handleNewOpenForChange = (types) => {
    setNewOpenFor(types.map((item) => item.value));
  };

  const handleUpdateOpenFor = () => {
    setLoadingUpdateOpenFor(true);
    Api.put(`calendar_dates/hours_open_for/${values.id}`, {
      open_for: newOpenFor,
    })
      .then(() =>
        toast.success("Abertura dos horários atualizadas com sucesso!")
      )
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingUpdateOpenFor(false));
  };

  const handleOpenUpdateLog = () => {
    setOpenCalendarDatesHoursUpdateLogModal(values?.date);
  };

  return (
    <Modal show={!!open} size={"xl"} onHide={handleClose}>
      {openTimesModal && (
        <TimesModal
          open={openTimesModal}
          setOpen={setOpenTimesModal}
          calendar_date={values}
        />
      )}
      {openRescheduleModal && (
        <RescheduleModal
          open={openRescheduleModal}
          setOpen={setOpenRescheduleModal}
          calendar_date={values}
        />
      )}
      {openCalendarDatesHoursUpdateLogModal && (
        <CalendarDatesHoursUpdateLogModal
          open={!!openCalendarDatesHoursUpdateLogModal}
          setOpen={setOpenCalendarDatesHoursUpdateLogModal}
          date={openCalendarDatesHoursUpdateLogModal}
        />
      )}
      <Modal.Header closeButton>
        <Modal.Title className="text-center">{`${
          values?.id ? "Gerenciar" : "Cadastrar nova"
        } data`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="g-2">
          {loadingData && (
            <Col xs={12}>
              <ProgressBar animated now={100} />
            </Col>
          )}
          <Col xs={12}>
            {values?.id && (
              <>
                <Row className="justify-content-center">
                  <Col xs={12} lg={8}>
                    <Card className="text-center">
                      <Card.Body>
                        <span className="d-flex fs-5 fw-bold">
                          {"Ocupação por horário:"}
                        </span>
                        <OccupationPerHourChart
                          date={moment(date).format("YYYY-MM-DD")}
                        />
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <AvailableSpaces date={moment(date).format("YYYY-MM-DD")} />
                <RenderNotPlayers date={moment(date).format("YYYY-MM-DD")} />
              </>
            )}
          </Col>
          <Col xs={12} className="d-flex flex-column gap-1">
            <div className="d-flex gap-1 flex-column flex-lg-row">
              <InputGroup>
                <InputGroup.Text>Data</InputGroup.Text>
                <Form.Control
                  value={
                    values?.date
                      ? moment(values?.date).format("YYYY-MM-DD")
                      : null
                  }
                  name={"date"}
                  type="date"
                  readOnly
                  disabled
                />
              </InputGroup>
              <InputGroup>
                <InputGroup.Text>Feriado</InputGroup.Text>
                <Form.Select
                  value={values?.holiday}
                  name={"holiday"}
                  onChange={handleChange}
                  disabled={loadingSave || loadingData}
                >
                  <option value={false}>Não</option>
                  <option value={true}>Sim</option>
                </Form.Select>
              </InputGroup>
            </div>
            <Button variant="outline-primary" onClick={handleOpenTimesModal}>
              Gerenciar horários
            </Button>
            <div className="d-flex flex-column gap-1 border border-primary rounded-box p-1">
              <span>Atualizar abertura de todos os horários</span>
              <ReactSelect
                classNamePrefix="react-select"
                isMulti
                onChange={handleNewOpenForChange}
                value={newOpenFor.map((type) => ({
                  value: type,
                  label: ticketTypesList.find(
                    (ticketType) => ticketType === type
                  ),
                }))}
                name={"open_for"}
                disabled={loadingSave || loadingData || loadingUpdateOpenFor}
                styles={selectStyles}
                getOptionLabel={(opt) =>
                  ticketTypesList.find((type) => type.value === opt.value)
                    ?.label
                }
                isOptionSelected={(opt) =>
                  (values?.open_for || []).find((item) => item === opt.value)
                }
                options={ticketTypesList}
              />
              {newOpenFor[0] && (
                <Button
                  variant="secondary"
                  disabled={loadingSave || loadingData || loadingUpdateOpenFor}
                  onClick={handleUpdateOpenFor}
                >
                  Atualizar abertura
                </Button>
              )}
            </div>
            <Button
              variant="outline-primary"
              onClick={handleOpenRescheduleModal}
            >
              Liberar reagendamento de ingressos
            </Button>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="light"
          onClick={handleClose}
          disabled={loadingSave || loadingData}
        >
          Cancelar
        </Button>
        <Button
          variant="outline-secondary"
          onClick={handleOpenUpdateLog}
          disabled={!values?.date}
        >
          Log de alterações
        </Button>
        <Button onClick={handleSave} disabled={loadingSave || loadingData}>
          {`Atualizar`}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditModal;
