import React, { useContext, useEffect } from "react";
import PageTitle from "../../components/PageTitle";
import FindUser from "./FindUser";
import { toast } from "react-toastify";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Sale from "./Sale";
import { FilialContext } from "../../context/FilialContext";
import { AuthContext } from "../../context/AuthContext";
import moment from "moment";
import ConfirmUsage from "./ConfirmUsage";
import Api from "../../api/api";
import { Button } from "react-bootstrap";

const NewSale2 = () => {
  const { selectedUnitId } = useContext(FilialContext);
  const { dashboardId } = useContext(AuthContext);

  const [user, setUser] = useState(null);
  const [loadingParkAreas, setLoadingParkAreas] = useState(false);
  const [parkAreas, setParkAreas] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [showButton, setShowButton] = useState(true);

  const initSale = {
    unit_id: selectedUnitId,
    date: "",
    seller_id: dashboardId,
    user_id: user?.id,
    online_sale: false,
    players: 0,
    not_players: 0,
    minors: 0,
    minors_responsibles: 0,
    dps: 0,
    dps_responsibles: 0,
    courtesy_players: 0,
    courtesy_not_players: 0,
    type: "ticket",
    additional_info: "",
    products: [],
    payment_status: "AUTHORIZED",
    players_price: 0,
    not_players_price: 0,
    minor_price: 0,
    minor_responsible_price: 0,
    dp_price: 0,
    dp_responsible_price: 0,
    additional_hour_price: 0,
    coupon_discount_rate: 0,
    total_value: 0,
    coupon_discount_value: 0,
    tickets_value: 0,
    products_value: 0,
    status: 2,
    customer_type: "default",
    park_areas_ids: [],
    ticket_birthday_people: [],
    is_birthday: false,
    quantity_birthdays: 0,
    cpf_birthday: [],
    cpf_minors: [],
    minors_infos: [],
  };

  const [sale, setSale] = useState(JSON.parse(JSON.stringify(initSale)));

  const handleUserAccepted = (acceptedUser) => {
    setSale((prev) => ({
      ...prev,
      user_id: acceptedUser.id,
    }));
    setUser(acceptedUser);
  };

  const handleUserChange = () => {
    setUser(null);
  };

  const getParkAreas = () => {
    setLoadingParkAreas(true);
    Api.get(`park_areas`, {
      params: { unit_id: selectedUnitId, on_sale: true },
    })
      .then((res) => setParkAreas(res.data.list))
      .catch((err) => toast.error(err.message))
      .finally(setLoadingParkAreas(false));
  };

  useEffect(() => {
    if (selectedUnitId) {
      setSale((prev) => ({
        ...prev,
        unit_id: selectedUnitId,
      }));
      getParkAreas();
    }
  }, [selectedUnitId]);

  useEffect(() => {
    document.getElementById("topbar")?.scrollIntoView();
  }, []);

  useEffect(() => {
    setSale((prev) => ({
      ...prev,
      park_areas_ids: parkAreas.map((item) => item.id),
    }));
  }, [parkAreas]);

  return (
    <>
      <PageTitle>
        <h2>Nova Venda</h2>
        {showButton && (
          <Button
            className={"mb-1 ml-auto align-self-end"}
            onClick={() => {
              setOpenModal(true);
            }}
          >
            Cadastrar novo cliente
          </Button>
        )}
      </PageTitle>
      <Row className="d-flex justify-content-center">
        <Col xs={12} lg={9} xxl={7}>
          {!user && (
            <FindUser
              onUserAccepted={handleUserAccepted}
              openModal={openModal}
              setOpenModal={setOpenModal}
              setShowButton={setShowButton}
            />
          )}
          {user && (
            <Sale
              user={user}
              handleChangeUser={handleUserChange}
              sale={sale}
              initSale={initSale}
              setSale={setSale}
              parkAreas={parkAreas}
            />
          )}
        </Col>
      </Row>
      {!user && (
        <Row className="d-flex justify-content-center">
          <Col xs={12} lg={9} xxl={7} className="mt-2">
            <ConfirmUsage />
          </Col>
        </Row>
      )}
    </>
  );
};

export default NewSale2;
