import { useEffect, useState } from "react";
import moment from "moment";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import Api from "../../api/api";
import { injectStyle } from "react-toastify/dist/inject-style";
import PermissionGroupSelect from "./PermissionGroupSelect";
import DepartmentSelect from "./DepartmentSelect";
import Form from "react-bootstrap/Form";
import { validateCPF } from "../../utils/validateCPF";
import Select from "react-select";
import { genderList } from "../../utils/functions";
import validator from "validator";
import InputMask from "react-input-mask";
import { validateBr } from "js-brasil";

const initUser = {
  name: "",
  lastName: "",
  cpf: "",
  zipcode: "",
  state: "",
  city: "",
  neighborhood: "",
  address: "",
  number: "",
  phone: "",
  complement: "",
  gender: "",
  birth: "",
  brazilian_doc: true,
  permissionGroup: "",
  is_admin: false,
  department: "",
  unities: [],
  pass: "",
  confirmPass: "",
};

export function CreateUser({
  open,
  userData,
  handleClose,
  modalTitle,
  handleUserCreated,
}) {
  const [user, setUser] = useState(initUser);
  const [loadCep, setLoadCep] = useState(false);

  const [showPasswordFields, setShowPasswordFields] = useState(false);

  const handleTogglePasswordFields = () => {
    setShowPasswordFields((prevState) => {
      const newState = !prevState;

      if (!newState) {
        setUser((prevUser) => ({
          ...prevUser,
          pass: "",
          confirmPass: "",
        }));
      }

      return newState;
    });
  };

  if (typeof window !== "undefined") {
    injectStyle();
  }

  const options = [
    { value: "SANTO_AMARO", label: "Santo Amaro" },
    { value: "TATUAPE", label: "Tatuapé" },
    { value: "BRASILIA", label: "Brasília" },
  ];

  const handleUnitiesChange = (selectedOptions) => {
    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setUser((prevUser) => ({
      ...prevUser,
      unities: selectedValues,
    }));
  };

  // const handleUnitiesChange = (e) => {
  //   if (e.target.checked) {
  //     if (unities.find((unit) => unit === e.target.name)) return;
  //     setUnities([...unities, e.target.name]);
  //   } else setUnities(unities.filter((unit) => unit !== e.target.name));
  // };

  const setName = (string) => {
    let array = string.split("");
    if (!array[0]) return "";
    array[0] = array[0].toUpperCase();
    array.forEach((item, index) =>
      index > 0 ? (array[index] = item.toLowerCase()) : null
    );
    array = array.filter((item) => item !== " ");
    return array.join("");
  };

  const setNameWithSpaces = (string) => {
    return string
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (["name", "lastName"].includes(name)) {
      setUser((prev) => ({
        ...prev,
        [name]: setNameWithSpaces(value),
      }));
    } else {
      setUser((prev) => ({ ...prev, [name]: value }));
    }

    if (["number", "phone", "cpf"].includes(name)) {
      let formattedValue = value.replace(/\D/g, "");

      if (name === "zipcode" && formattedValue.length > 8) {
        formattedValue = user[name];
      }
      if (name === "phone" && formattedValue.length > 11) {
        formattedValue = user[name];
      }
      if (name === "cpf") {
        if (formattedValue.length > 11 && formattedValue.length <= 14) {
          formattedValue = formattedValue.slice(0, 14);
        } else if (formattedValue.length > 11) {
          formattedValue = formattedValue.slice(0, 11);
        }
      }
      e.target.value = formattedValue;
    }

    if (["address", "city", "neighborhood"].includes(name)) {
      let wordArray = value.split(" ");
      wordArray = wordArray.map((word) => setName(word));
      e.target.value = wordArray.join(" ");
    }

    if (name === "email") {
      e.target.value = value.replaceAll(" ", "").toLowerCase();
    }

    if (name === "zipcode" && !value) {
      setUser((prev) => ({
        ...prev,
        state: "",
        city: "",
        neighborhood: "",
        address: "",
        number: "",
        complement: "",
      }));
    }

    // if (name === "is_admin") {
    //   e.target.value = e.target.checked;
    // }

    setUser((prev) => ({ ...prev, [name]: e.target.value }));
  };

  const handleAdminChange = (e) => {
    if (e.target.name === "is_admin") {
      const newValue = e.target.checked;

      setUser((prev) => ({
        ...prev,
        is_admin: newValue,
        permissionGroup: newValue ? prev.permissionGroup : "",
        department: newValue ? prev.department : "",
        unities: newValue ? prev.unities : [],
      }));
    }
  };

  useEffect(() => {
    if (user?.zipcode && validator.isPostalCode(user?.zipcode, "BR"))
      checkCEP();
  }, [user?.zipcode]);

  useEffect(() => {
    if (open && userData) setUser({ ...userData });
    else setUser({ ...initUser });
  }, [userData, open]);

  const checkCEP = () => {
    setLoadCep(true);
    fetch(`https://viacep.com.br/ws/${user?.zipcode}/json/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.erro) return toast.error("CEP não encontrado!");
        setUser((prev) => ({
          ...prev,
          address: data.logradouro,
          city: data.localidade,
          neighborhood: data.bairro,
          state: data.uf,
        }));
      })
      .finally(() => {
        setLoadCep(false);
      });
  };

  function submitPostUser() {
    const requiredFields = {
      name: "Nome",
      lastName: "Sobrenome",
      cpf: "CPF",
      zipcode: "CEP",
      state: "Estado",
      city: "Cidade",
      neighborhood: "Bairro",
      address: "Endereço",
      number: "Número",
      phone: "Telefone",
      gender: "Gênero",
      birth: "Data de Nascimento",
    };

    if (!user.id || showPasswordFields) {
      requiredFields.pass = "Senha";
      requiredFields.confirmPass = "Confirmação de Senha";
    }

    for (const [field, fieldName] of Object.entries(requiredFields)) {
      if (
        !user[field] ||
        (Array.isArray(user[field]) && user[field].length === 0)
      ) {
        toast.error(`${fieldName} é obrigatório.`);
        return;
      }
    }

    if (user.is_admin) {
      const adminFields = {
        permissionGroup: "Grupo de Permissão",
        department: "Departamento",
        unities: "Unidades",
      };

      for (const [field, fieldName] of Object.entries(adminFields)) {
        if (
          !user[field] ||
          (Array.isArray(user[field]) && user[field].length === 0)
        ) {
          toast.error(`${fieldName} é obrigatório para administradores.`);
          return;
        }
      }

      if (!user.id || showPasswordFields) {
        if (user.pass.length < 6) {
          return toast.error("A senha deve ter no mínimo 6 caracteres.");
        }

        if (user.pass !== user.confirmPass) {
          return toast.error("As senhas não coincidem.");
        }
      }
    }

    if (
      [true, "true"].includes(user.brazilian_doc) &&
      !validateBr.cpf(user.cpf) &&
      !validateBr.cnpj(user.cpf)
    ) {
      return toast.error(
        "Documento inválido. Por favor, insira um documento válido."
      );
    }

    const successMessage = user.id ? "Usuário Atualizado" : "Usuário Criado";

    if (user.id) {
      Api.put(`/dashboard/seed/run/${user.id}`, user)
        .then((res) => {
          handleUserCreated(res.data);
          toast.success(successMessage);
          setShowPasswordFields(false);
          handleClose();
        })
        .catch((err) => {
          toast.error(err.message);
        });
    } else {
      Api.post(`/dashboard/seed/run`, user)
        .then((res) => {
          handleUserCreated(res.data);
          toast.success(successMessage);
          setShowPasswordFields(false);
          handleClose();
        })
        .catch((err) => {
          toast.error(err.message);
        });
    }
  }
  return (
    <>
      <Modal
        show={open}
        onHide={handleClose}
        size="xl"
        dialogClassName="modal-dialog-scrollable"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Novo Usuário</Modal.Title> */}
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row justify-content-end">
              <div className="col-auto">
                <small>
                  Campos obrigatórios(<b className="text-danger">*</b>)
                </small>
              </div>
            </div>
          </div>
          <Row className="gap-3">
            <Row>
              <div>
                <h4 className="fw-bold">Dados Pessoais</h4>
                <hr />
              </div>
              <Col lg="4">
                <label>
                  Nome<b className="text-danger">*</b>
                </label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  value={user?.name}
                  name={"name"}
                  maxLength={60}
                  placeholder="Informe o nome"
                />
              </Col>
              <Col lg="4">
                <label>
                  Sobrenome<b className="text-danger">*</b>
                </label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  value={user?.lastName}
                  name={"lastName"}
                  maxLength={60}
                  placeholder="Informe o sobrenome"
                />
              </Col>
              <Col lg="4">
                <label>
                  Data de Nascimento<b className="text-danger">*</b>
                </label>
                <input
                  className="form-control"
                  type={"date"}
                  onChange={handleChange}
                  value={
                    moment(user?.birth).isValid()
                      ? moment(user?.birth).format("YYYY-MM-DD")
                      : moment(user?.birth, "DD/MM/YYYY").format("YYYY-MM-DD")
                  }
                  name={"birth"}
                  maxLength={15}
                />
              </Col>
            </Row>
            <Row>
              <Col lg="4">
                <label>
                  Documento brasileiro? (CPF/CNPJ)
                  <b className="text-danger">*</b>{" "}
                </label>
                <Form.Control
                  as="select"
                  onChange={handleChange}
                  value={[true, "true"].includes(user?.brazilian_doc)}
                  name={"brazilian_doc"}
                >
                  <option value={true}>Sim</option>
                  <option value={false}>Não</option>
                </Form.Control>
              </Col>
              <Col>
                <label lg="4">
                  Documento<b className="text-danger">*</b>
                </label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  value={user?.cpf}
                  name={"cpf"}
                  maxLength={
                    [true, "true"].includes(user?.brazilian_doc)
                      ? 14
                      : undefined
                  }
                  placeholder="Informe o documento (somente números)"
                />
              </Col>
              <Col lg="4">
                <label>
                  Gênero<b className="text-danger">*</b>
                </label>
                <Form.Control
                  as="select"
                  onChange={handleChange}
                  value={user?.gender}
                  name={"gender"}
                >
                  {genderList.map((g) => (
                    <option key={`gender_item_${g.value}`} value={g.value}>
                      {g.label}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Row>

            <Row>
              <Col lg="6">
                <label>
                  E-mail<b className="text-danger">*</b>
                </label>
                <input
                  value={user?.email}
                  name={"email"}
                  onChange={handleChange}
                  type="email"
                  className="form-control"
                  placeholder="email@email.com"
                />
              </Col>
              <Col lg="6">
                <label>
                  Telefone<b className="text-danger">*</b>
                </label>
                <input
                  className="form-control "
                  onChange={handleChange}
                  value={user?.phone}
                  name={"phone"}
                  type="phone"
                  maxLength={15}
                  placeholder="Informe o telefone (somente números)"
                />
              </Col>
            </Row>

            <Row>
              <div className="mt-2">
                <h4 className="fw-bold">Endereço</h4>
                <hr />
              </div>
              <Col lg="3">
                <label>
                  CEP<b className="text-danger">*</b>
                </label>

                <InputMask
                  inputMode="numeric"
                  className="form-control"
                  maskChar=""
                  mask="99999-999"
                  value={user?.zipcode}
                  onChange={handleChange}
                  disabled={loadCep}
                  placeholder="Informe o CEP"
                >
                  {(inputProps) => (
                    <Form.Control
                      {...inputProps}
                      name={"zipcode"}
                      isInvalid={
                        user?.zipcode &&
                        !validator.isPostalCode(user?.zipcode, "BR")
                      }
                      isValid={
                        user?.zipcode &&
                        validator.isPostalCode(user?.zipcode, "BR")
                      }
                    />
                  )}
                </InputMask>
              </Col>
              <Col lg="3">
                <label>
                  UF<b className="text-danger">*</b>
                </label>
                <input
                  className="form-control "
                  value={user?.state}
                  name={"state"}
                  onChange={handleChange}
                  placeholder="Informe o UF"
                />
              </Col>
              <Col lg="3">
                <label>
                  Cidade<b className="text-danger">*</b>
                </label>
                <input
                  className="form-control "
                  value={user?.city}
                  name={"city"}
                  onChange={handleChange}
                  placeholder="Informe a cidade"
                />
              </Col>
              <Col lg="3">
                <label>
                  Bairro<b className="text-danger">*</b>
                </label>
                <input
                  className="form-control "
                  value={user?.neighborhood}
                  name={"neighborhood"}
                  onChange={handleChange}
                  placeholder="Informe o bairro"
                />
              </Col>
            </Row>
            <Row>
              <Col lg="4">
                <label>
                  Logradouro<b className="text-danger">*</b>
                </label>
                <input
                  className="form-control "
                  value={user?.address}
                  name={"address"}
                  onChange={handleChange}
                  placeholder="Informe o  logradouro"
                />
              </Col>
              <Col>
                <label lg="4">
                  Número<b className="text-danger">*</b>
                </label>
                <input
                  className="form-control "
                  value={user?.number}
                  name={"number"}
                  onChange={handleChange}
                  placeholder="Informe o número"
                  maxLength={4}
                />
              </Col>
              <Col lg="4">
                <label>Complemento</label>
                <input
                  className="form-control "
                  value={user?.complement}
                  name={"complement"}
                  onChange={handleChange}
                  placeholder="Informe o complemento"
                />
              </Col>
            </Row>

            {modalTitle === "Editar Cliente" ||
              (modalTitle === "Editar Usuário" && (
                <Row className="mt-3 align-items-center">
                  <Col lg="12">
                    <hr />
                    <div className="d-flex align-items-center">
                      <div className="form-switch mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          name="showPasswordFields"
                          checked={showPasswordFields}
                          onChange={handleTogglePasswordFields}
                        />
                        <label className="form-check-label">
                          {showPasswordFields}
                        </label>
                      </div>
                      <label>Alterar a Senha</label>
                    </div>
                  </Col>
                </Row>
              ))}
            {((modalTitle !== "Editar Cliente" &&
              modalTitle !== "Editar Usuário") ||
              showPasswordFields) && (
              <>
                <Row>
                  <div className="mt-2">
                    <h4 className="fw-bold">Configuração de Senha</h4>
                    <hr />
                  </div>
                </Row>
                <Row>
                  <Col lg="6">
                    <label>
                      Senha<b className="text-danger">*</b>
                    </label>
                    <input
                      type="password"
                      value={user?.pass}
                      name="pass"
                      className="form-control"
                      onChange={handleChange}
                      maxLength={30}
                      placeholder="Senha (mínimo 6 caracteres)"
                    />
                  </Col>
                  <Col lg="6">
                    <label>
                      Confirmar Senha<b className="text-danger">*</b>
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      onChange={handleChange}
                      value={user?.confirmPass}
                      name="confirmPass"
                      maxLength={30}
                      placeholder="Confirme a senha (mínimo 6 caracteres)"
                    />
                  </Col>
                </Row>
              </>
            )}
            {modalTitle !== "Editar Cliente" &&
              modalTitle !== "Novo Cliente" && (
                <Row className="mt-3 align-items-center">
                  <Col lg="12">
                    <hr />
                    <div className="d-flex align-items-center">
                      <div className="form-switch mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          name="is_admin"
                          checked={user?.is_admin}
                          onChange={handleAdminChange}
                        />
                        <label className="form-check-label">
                          {user?.is_admin}
                        </label>
                      </div>
                      <label className="">Acesso ao Dashboard</label>
                    </div>
                  </Col>
                </Row>
              )}

            {user?.is_admin ? (
              <>
                <Row>
                  <div>
                    <h4 className="fw-bold">
                      Configurações de Acesso do Usuário
                    </h4>
                    <hr />
                  </div>

                  <Col lg="3">
                    <label>
                      Departamento<b className="text-danger">*</b>
                    </label>
                    <DepartmentSelect
                      value={user?.department}
                      name={"department"}
                      onChange={(selectedOption) =>
                        handleChange({
                          target: {
                            name: "department",
                            value: selectedOption,
                          },
                        })
                      }
                    />
                  </Col>
                  <Col lg="3">
                    <label>
                      Grupo de Permissões<b className="text-danger">*</b>
                    </label>
                    <PermissionGroupSelect
                      value={user?.permissionGroup}
                      name={"permissionGroup"}
                      onChange={(selectedOption) =>
                        handleChange({
                          target: {
                            name: "permissionGroup",
                            value: selectedOption,
                          },
                        })
                      }
                    />
                  </Col>
                  <Col lg="6">
                    <label>
                      Unidades<b className="text-danger">*</b>
                    </label>
                    <Select
                      classNamePrefix="react-select"
                      isMulti
                      options={options}
                      value={options.filter((option) =>
                        user?.unities?.includes(option.value)
                      )}
                      onChange={handleUnitiesChange}
                      placeholder="Selecione as unidades"
                    />
                  </Col>
                </Row>
              </>
            ) : (
              <></>
            )}
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="light" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={() => submitPostUser()}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
