import React, { useState } from "react";
import { Button, Form, InputGroup, Card } from "react-bootstrap";
import moment from "moment";
import Select from "react-select";
import {
  handleFilialHours,
  handleFilialOptions,
} from "../../../../utils/handleOptionsFilial";
import { useEffect } from "react";
import Api from "../../../../api/api";
import { toast } from "react-toastify";
import { useContext } from "react";
import { FilialContext } from "../../../../context/FilialContext";

const selectStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: "2rem",
    borderBottomRightRadius: "2rem",
  }),
  container: (baseStyles, state) => ({
    ...baseStyles,
    alignSelf: "stretch",
    flex: 1,
  }),
  value: (base) => ({ ...base, borderRadius: "2rem" }),
  multiValue: (base) => ({ ...base, borderRadius: "2rem" }),
};

const General = ({ handleNext, handleCancel, sale, setSale }) => {
  const { unities } = useContext(FilialContext);
  const [loadHours, setLoadHours] = useState(false);
  const [hours, setHours] = useState([]);
  const [loadingTickets, setLoadingTickets] = useState(false);
  let [tickets, setTickets] = useState([]);
  const [loadingParkAreas, setLoadingParkAreas] = useState(false);
  const [parkAreas, setParkAreas] = useState([]);

  const sumAllTickets = () => {
    return Number(
      (Number(sale.players) || 0) +
        (Number(sale.not_players) || 0) +
        (Number(sale.minors_responsibles) || 0) +
        (Number(sale.minors) || 0) +
        (Number(sale.dps) || 0) +
        (Number(sale.dps_responsibles) || 0) +
        (Number(sale.courtesy_players) || 0) +
        (Number(sale.courtesy_not_players) || 0)
    );
  };

  const getTickets = () => {
    tickets = [];
    setLoadingTickets(true);
    Api.get("tickets/config", {
      params: {
        unit_id: sale.unit_id,
        date: sale.date,
        areas_amount: sale.park_areas_ids.length,
        type: "ticket",
      },
    })
      .then((res) => setTickets(res.data.list))
      .catch((err) => {
        toast.error(err.message);
        setTickets([]);
      })
      .finally(() => setLoadingTickets(false));
  };

  useEffect(() => {
    if (
      sumAllTickets() > 0 &&
      sale?.unit_id &&
      sale?.park_areas_ids?.length > 0 &&
      sale?.date
    )
      getTickets();
  }, [sale?.unit_id, sale?.park_areas_ids, sale?.date, sumAllTickets()]);

  const handleChange = (e) => {
    if (e.target.name === "date") {
      if (moment("2100-12-31").isBefore(moment(e.target.value)))
        e.target.value = moment().format("YYYY-MM-DD");
      if (moment("1900-01-01").isAfter(moment(e.target.value)))
        e.target.value = moment().format("YYYY-MM-DD");
    }
    setSale((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSelectChange = (e, field) => {
    setSale((prev) => ({
      ...prev,
      [field]: e.value,
    }));
  };

  const getHourSuffix = (item) => {
    if (!item || !item.available_spaces) return "Esgotado";

    let suffix = "";

    if (Number(item.available_spaces) <= Number(item.total_spaces))
      suffix = `${item.available_spaces} vaga${
        item.available_spaces > 1 ? "s" : ""
      } disponíveis`;
    else suffix = "Disponível";

    return suffix;
  };

  const renderHoursOptions = () => {
    if (loadHours) return <option>Carregando...</option>;
    return [
      <option>
        {loadHours ? "Carregando..." : "Selecione o horário de entrada"}
      </option>,
      hours.map((item, index) => {
        return (
          <option key={`time_${item.time}`} value={item.time}>
            {`${moment(item.time, "HH:mm:SS").format("HH:mm")} ${getHourSuffix(
              item
            )}`}
          </option>
        );
      }),
    ];
  };

  useEffect(() => {
    const getHours = () => {
      setLoadHours(true);
      Api.get(`/calendar_dates_hours/available`, {
        params: {
          date: sale.date,
          players: sale.total_players,
          duration: sale.duration,
          unit_id: sale.unit_id,
          type: "ticket",
          editing: true,
        },
      })
        .then((res) => {
          setHours(res?.data.hours);
        })
        .catch((err) => {
          toast.error(err.message);
          setHours([]);
        })
        .finally(() => {
          setLoadHours(false);
        });
    };

    if (
      !loadingTickets &&
      tickets[0] &&
      sumAllTickets() > 0 &&
      sale.date &&
      sale.unit_id &&
      sale.duration &&
      sale.park_areas_ids.length
    )
      getHours();
  }, [
    sale.date,
    sale.unit_id,
    sale.duration,
    sumAllTickets(),
    sale.park_areas_ids,
    loadingTickets,
    tickets,
  ]);

  const selectedTicket = tickets.find(
    (ticket) => Number(ticket.duration) === Number(sale.duration)
  );

  const unit = unities.find((item) => item.id === sale.unit_id);

  useEffect(() => {
    if (unit?.id) getParkAreas();
  }, [sale.unit_id, unit?.id]);

  const getParkAreas = () => {
    setLoadingParkAreas(true);
    Api.get(`/park_areas/${unit.var_name}`, { params: { on_sale: true } })
      .then((res) => {
        setParkAreas(res.data.list);
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingParkAreas(false));
  };

  useEffect(() => {
    setSale((prev) => ({
      ...prev,
      park_areas_ids: parkAreas
        .map((item) => item.id)
        .filter((item) => item > 0),
    }));
  }, [parkAreas]);

  const parkAreasList = parkAreas.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const handleTypeChange = (park_areas_ids) => {
    let ids = [...park_areas_ids.map((item) => item.value)];

    parkAreas.forEach((pa) => {
      if (pa.mandatory_on_sale === "true" && !ids.includes(pa.id))
        ids.unshift(pa.id);
    });

    setSale((prev) => ({
      ...prev,
      park_areas_ids: ids,
    }));
  };

  return (
    <Card>
      <Card.Header className="border-0 bg-white">
        <span className="fs-5 fw-bold">{"Configurações de Ingresso:"}</span>
      </Card.Header>
      <Card.Body className="d-flex flex-column gap-3">
        <div className="row">
          <div className="col">
            <label>Unidade:</label>
            <Form.Control
              value={unities.find((unit) => unit.id === sale?.unit_id).name}
              name={"unit_id"}
              disabled
            />
          </div>
          <div className="col">
            <label>Data:</label>
            <Form.Control
              value={moment(sale.date).format("YYYY-MM-DD")}
              name={"date"}
              type={"date"}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row">
          {unities.find((unit) => unit.id === sale.unit_id)?.var_name ===
            "SANTO_AMARO" && (
            <div className="row">
              <label>Áreas do Parque:</label>
              <Select
                classNamePrefix="react-select"
                isMulti
                onChange={handleTypeChange}
                value={sale.park_areas_ids.map((item) => ({
                  value: item,
                  label: parkAreas.find(
                    (area) => Number(area.id) === Number(item)
                  ),
                }))}
                isOptionSelected={(opt) =>
                  sale.park_areas_ids.find(
                    (item) => Number(item) === Number(opt.value)
                  )
                }
                options={parkAreasList}
                getOptionLabel={(opt) =>
                  parkAreasList.find(
                    (area) => Number(area.value) === Number(opt.value)
                  )?.label
                }
                disabled={loadingParkAreas}
                styles={selectStyles}
              />
            </div>
          )}
        </div>
        <div className="row">
          <div className="col">
            <label>Duração:</label>
            <Select
              classNamePrefix="react-select"
              value={
                loadingTickets
                  ? {
                      label: "Carregando...",
                    }
                  : selectedTicket
                  ? {
                      value: selectedTicket.duration,
                      label: `${selectedTicket.duration}hr${
                        selectedTicket?.duration > 1 ? "s" : ""
                      } de diversão`,
                    }
                  : {
                      label: "Selecione a duração do cliente no parque",
                    }
              }
              onChange={(val) => handleSelectChange(val, "duration")}
              options={tickets.map((ticket) => ({
                label: `${ticket.duration}hr${
                  ticket.duration > 1 ? "s" : ""
                } de diversão`,
                value: ticket.duration,
              }))}
              disabled={loadingTickets}
              styles={selectStyles}
            />
          </div>
          <div className="col">
            <label>Horário:</label>
            <select
              className={`ticket-schedule form-select `}
              name="ticket_time"
              onChange={handleChange}
              disabled={loadHours || !sale.duration}
              value={sale.ticket_time}
            >
              {renderHoursOptions()}
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label>Informações Adicionais</label>
            <Form.Control
              as="textarea"
              value={sale.additional_info}
              name={"additional_info"}
              onChange={handleChange}
            />
          </div>
        </div>
        {(handleCancel || handleNext) && (
          <div className="d-flex gap-1 ml-auto">
            {handleCancel && (
              <Button variant="light" onClick={handleCancel}>
                Cancelar
              </Button>
            )}
            {handleNext && <Button onClick={handleNext}>Avançar</Button>}
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default General;
