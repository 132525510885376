import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import List from "./List";
import { v4 as uuidv4 } from "uuid";
import Api from "../../../api/api";

const TicketDP = ({ onChange, prevList, totalNumberDps }) => {
  const [list, setList] = useState(prevList ? [...prevList] : []);
  const [load, setLoad] = useState(false);
  const [disabilityTypes, setDisabilityTypes] = useState([]);

  const getList = () => {
    setLoad(true);
    Api.get(`/disability_types`)
      .then((res) => {
        setLoad(false);
        setDisabilityTypes(res.data.data);
      })
      .catch((err) => {
        setLoad(false);
        toast.error(err.message);
      });
  };

  const updateList = () => {
    const diff = totalNumberDps - list.length;

    if (diff > 0) {
      setList((prevList) => [
        ...prevList,
        ...Array(diff).fill({
          id: uuidv4(),
          disability_type_id: "new",
          amount: 1,
        }),
      ]);
    } else if (diff < 0) {
      setList((prevList) => prevList.slice(0, totalNumberDps));
    }
  };

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    onChange(list);
  }, [list]);

  useEffect(() => {
    updateList();
  }, [totalNumberDps]);

  if (load) return <></>;

  return (
    <div>
      <List list={list} setList={setList} disabilityTypes={disabilityTypes} />
    </div>
  );
};

export default TicketDP;
