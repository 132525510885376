import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Button, Form, InputGroup, Modal, ProgressBar } from "react-bootstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import Api from "../../api/api";
import { AuthContext } from "../../context/AuthContext";
import {
  couponDiscountTypes,
  getTicketType,
  ticketTimes,
  weekDaysList,
} from "../../utils/functions";

const EditModal = ({ open, setOpen, item, getList }) => {
  const { dashboardId } = useContext(AuthContext);
  const [values, setValues] = useState({});
  const [load, setLoad] = useState(false);
  const [loadUnities, setLoadUnities] = useState(false);
  const [unities, setUnities] = useState([]);
  const [loadPartners, setLoadPartners] = useState(false);
  const [partners, setPartners] = useState([]);
  const [loadingTickets, setLoadingTickets] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [loadingPromotions, setLoadingPromotions] = useState(false);
  const [promotions, setPromotions] = useState([]);

  const getUnities = () => {
    setLoadUnities(true);
    Api.get(`unities`)
      .then((res) => {
        setUnities(res.data.data);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setLoadUnities(false);
      });
  };

  const getPartners = () => {
    setLoadPartners(true);
    Api.get(`partners/all`)
      .then((res) => {
        setPartners(res.data.list);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setLoadPartners(false);
      });
  };

  const getTickets = () => {
    setLoadingTickets(true);
    Api.get(`tickets`, { params: {} })
      .then((res) => {
        setTickets(res.data.list);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setLoadingTickets(false);
      });
  };

  const getPromotions = () => {
    setLoadingPromotions(true);
    Api.get(`promotions/all`)
      .then((res) => {
        setPromotions(res.data);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setLoadingPromotions(false);
      });
  };

  useEffect(() => {
    getUnities();
    getPartners();
    getPromotions();
    getTickets();
  }, []);

  useEffect(() => {
    setValues({ ...item });
  }, [item]);

  const closeModal = () => {
    setOpen(null);
  };

  const handleChange = (e) => {
    if (e.target.type === "number" && e.target.value < 0) e.target.value = 0;
    if (e.target.name === "code" && e.target.value) {
      e.target.value = e.target.value
        .toUpperCase()
        .replace(/([^\w]+|\s+)/g, "");
    }
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleMultiSelectChange = (e, field) => {
    setValues((prev) => ({ ...prev, [field]: e.map((un) => un.value) }));
  };

  const handleSelectChange = (e, field) => {
    if (field === "partner_id") e.value = e.id;
    setValues((prev) => ({ ...prev, [field]: e.value }));
  };

  const handleSwitchChange = (e) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.checked }));
  };

  const handleStatusChange = (e) => {
    setValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked ? 1 : 2,
    }));
  };

  const remove = () => {
    if (window.confirm("Deseja realmente remover este cupom?")) {
      setLoad(true);
      Api.delete(`coupons/${values.id}`)
        .then((res) => {
          toast.success("Cupom removido com sucesso!");
          getList();
          closeModal();
        })
        .catch((err) => {
          toast.error(err.message);
        })
        .finally(() => {
          setLoad(false);
        });
    }
  };

  const save = () => {
    if (!values.code) return toast.warning("Código do cupom indefinido!");
    if (!values.usage_limit) return toast.warning("Limite de uso indefinido!");
    if (!values.unities[0])
      return toast.warning("Selecione pelo menos uma unidade para o cupom!");
    if (!values.ticket_week_days[0] && values.ticket_week_days[0] !== 0)
      return toast.warning("Selecione pelo menos um dia para os ingressos!");
    if (!values.ticket_times[0])
      return toast.warning(
        "Selecione pelo menos um horário para os ingressos!"
      );
    if (!values.usage_week_days[0] && values.usage_week_days[0] !== 0)
      return toast.warning(
        "Selecione pelo menos um dia para aplicação do cupom!"
      );
    if (!values.usage_times[0])
      return toast.warning(
        "Selecione pelo menos um horário para aplicação do cupom!"
      );
    if (!values.tickets && !values.products)
      return toast.warning(
        "O cupom deve funcionar para ingressos e/ou produtos!"
      );
    if (!values.start_date) return toast.warning("Selecione a data inicial!");
    if (!values.end_date) return toast.warning("Selecione a data final!");
    if (!values.discount_value)
      return toast.warning("Configure a quantidade de desconto!");
    if (
      !values.discount_max_value &&
      values.ticket_usage_days_limit_after_end_date !== 0
    )
      return toast.warning("Configure a quantidade máxima de desconto!");
    if (
      !values.ticket_usage_days_limit_after_end_date &&
      values.ticket_usage_days_limit_after_end_date !== 0
    )
      return toast.warning(
        "Configure o limite de dias para uso dos ingressos após a data final do cupom!"
      );

    setLoad(true);
    if (values.id)
      return Api.put(`coupons/${values.id}`, values)
        .then((res) => {
          toast.success("Cupom atualizado com sucesso!");
          getList();
          closeModal();
        })
        .catch((err) => {
          toast.error(err.message);
        })
        .finally(() => {
          setLoad(false);
        });
    Api.post(`coupons`, { ...values, created_by: dashboardId })
      .then((res) => {
        toast.success("Cupom criado com sucesso!");
        getList();
        closeModal();
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setLoad(false);
      });
  };

  const modalSize = window.innerWidth < 1000 ? "fullscreen" : "xl";

  return (
    <>
      <Modal show={open} onHide={load ? null : closeModal} size={modalSize}>
        <Modal.Header closeButton>
          <Modal.Title>
            {`${values.id ? "Editar" : "Adicionar"} Cupom`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column gap-3">
          <h5>Informações do Cupom:</h5>
          <div className="row">
            <div className="col">
              <label>Nome do Cupom:</label>
              <input
                autoFocus
                className="form-control"
                type="text"
                value={values.code}
                name={"code"}
                onChange={handleChange}
                placeholder={"Digite o nome..."}
              />
            </div>
            <div className="col">
              <label htmlFor="statusCheckbox">Status do cupom</label>
              <div className="form-check">
                <label className="form-check-label" htmlFor="statusCheckbox">
                  {values.status === 1 ? "Ativado" : "Desativado"}
                </label>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="statusCheckbox"
                  name="status"
                  checked={values.status === 1}
                  onChange={handleStatusChange}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label>Descrição:</label>
              <Form.Control
                className="form-control"
                type="text"
                value={values.description}
                name={"description"}
                onChange={handleChange}
                placeholder={"Digite a descrição..."}
                as="textarea"
                rows={5}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label>Ingressos:</label>
              <Form.Check
                type="checkbox"
                label={values.tickets ? "Ativado" : "Desativado"}
                checked={values.tickets}
                name="tickets"
                onChange={handleSwitchChange}
              />
            </div>
            <div className="col">
              <label>Produtos</label>
              <Form.Check
                type="checkbox"
                label={values.products ? "Ativado" : "Desativado"}
                checked={values.products}
                name="products"
                onChange={handleSwitchChange}
              />
            </div>
          </div>
          <div className="row">
            <h5>Válido para:</h5>
            <div className="col">
              <label>Data inicial do cupom:</label>
              <Form.Control
                value={moment(values.start_date).format("YYYY-MM-DD")}
                name={"start_date"}
                type={"date"}
                onChange={handleChange}
              />
            </div>
            <div className="col">
              <label>Data final do cupom:</label>
              <Form.Control
                value={moment(values.end_date).format("YYYY-MM-DD")}
                name={"end_date"}
                type={"date"}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label>Hora inicial:</label>
              <Form.Control
                value={values.start_time}
                name={"start_time"}
                type={"time"}
                onChange={handleChange}
              />
            </div>
            <div className="col">
              <label>Hora final:</label>
              <Form.Control
                value={values.end_time}
                name={"end_time"}
                type={"time"}
                onChange={handleChange}
              />
            </div>
          </div>
          <div>
            <label>Dias de aplicação do cupom</label>
            <Select
              classNamePrefix="react-select"
              closeMenuOnSelect={false}
              value={values.usage_week_days?.map((usageWeekDay) => ({
                value: usageWeekDay,
                label: weekDaysList.find((wDay) => wDay.value === usageWeekDay)
                  .label,
              }))}
              onChange={(val) =>
                handleMultiSelectChange(val, "usage_week_days")
              }
              options={weekDaysList}
              isMulti
            />
          </div>
          <div className="d-flex flex-column">
            <label>Horários de aplicação do cupom</label>
            <Select
              classNamePrefix="react-select"
              closeMenuOnSelect={false}
              value={values.usage_times?.map((usageTime) => ({
                value: usageTime,
                label: ticketTimes.find((uTime) => uTime.value === usageTime)
                  .label,
              }))}
              onChange={(val) => handleMultiSelectChange(val, "usage_times")}
              options={ticketTimes}
              isMulti
            />
          </div>
          <div className="row">
            <div className="col">
              <label>Tipo de desconto</label>
              <Select
                classNamePrefix="react-select"
                value={couponDiscountTypes.find(
                  (dType) => dType.value === values.discount_type
                )}
                onChange={(val) => handleSelectChange(val, "discount_type")}
                options={couponDiscountTypes}
              />
            </div>
            <div className="col">
              <label>
                {values.discount_type === "PERCENTAGE"
                  ? "Porcentagem"
                  : "Valor"}{" "}
                do desconto
              </label>
              <Form.Control
                value={values.discount_value}
                name={"discount_value"}
                type={"number"}
                onChange={handleChange}
                min={0}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label>Valor mínimo da compra</label>
              <Form.Control
                value={values.ticket_min_value}
                name={"ticket_min_value"}
                type={"number"}
                min={0}
                onChange={handleChange}
              />
            </div>
            {values.discount_type === "PERCENTAGE" && (
              <div className="col">
                <label>Valor máximo do desconto:</label>
                <Form.Control
                  value={values.discount_max_value}
                  name={"discount_max_value"}
                  type={"number"}
                  onChange={handleChange}
                  min={0}
                />
              </div>
            )}
          </div>
          <div className="row">
            <div className="col">
              <label>Limite de uso:</label>
              <Form.Control
                value={values.usage_limit}
                name={"usage_limit"}
                type={"number"}
                min={0}
                onChange={handleChange}
              />
            </div>
            <div className="col">
              <label>Uso unico por CPF:</label>
              <Form.Check
                type="checkbox"
                label={values.cpf_lock ? "Ativado" : "Desativado"}
                checked={values.cpf_lock}
                name="cpf_lock"
                onChange={handleSwitchChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label>Dias para liberação de 1 uso por CPF</label>
              <Form.Control
                value={values.cpf_lock_reset_days}
                name={"cpf_lock_reset_days"}
                type={"number"}
                onChange={handleChange}
              />
            </div>
          </div>
          <h5>Informações de visita:</h5>
          {loadUnities ? (
            <ProgressBar animated now={100} />
          ) : (
            <div>
              <label>Unidades</label>
              <Select
                classNamePrefix="react-select"
                closeMenuOnSelect={false}
                value={values.unities?.map((unit) => ({
                  value: unit,
                  label: unities.find((un) => un.var_name === unit)?.name,
                }))}
                onChange={(val) => handleMultiSelectChange(val, "unities")}
                options={unities?.map((unit) => ({
                  value: unit.var_name,
                  label: unit.name,
                }))}
                isMulti
              />
            </div>
          )}
          <div className="row">
            <div className="col">
              <div>
                <label>Data de ingresso inicial</label>
                <Form.Control
                  value={moment(values.ticket_start_date).format("YYYY-MM-DD")}
                  name={"ticket_start_date"}
                  type={"date"}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col">
              <div>
                <label>Data de ingresso final</label>
                <Form.Control
                  value={moment(values.ticket_end_date).format("YYYY-MM-DD")}
                  name={"ticket_end_date"}
                  type={"date"}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div>
            <label>Dias de uso dos ingressos:</label>
            <Select
              classNamePrefix="react-select"
              closeMenuOnSelect={false}
              value={values.ticket_week_days?.map((ticketWeekDay) => ({
                value: ticketWeekDay,
                label: weekDaysList.find((wDay) => wDay.value === ticketWeekDay)
                  .label,
              }))}
              onChange={(val) =>
                handleMultiSelectChange(val, "ticket_week_days")
              }
              options={weekDaysList}
              isMulti
            />
          </div>
          <div>
            <label>Horários de uso dos ingressos:</label>
            <Select
              classNamePrefix="react-select"
              closeMenuOnSelect={false}
              value={values.ticket_times?.map((ticketTime) => ({
                value: ticketTime,
                label: ticketTimes.find((tTime) => tTime.value === ticketTime)
                  .label,
              }))}
              onChange={(val) => handleMultiSelectChange(val, "ticket_times")}
              options={ticketTimes}
              isMulti
            />
          </div>
          <div className="row">
            <div className="col">
              <label>Agendar visita em ATÉ ____ dias após a compra</label>
              <Form.Control
                value={values.days_before_ticket_date}
                name={"days_before_ticket_date"}
                type={"number"}
                onChange={handleChange}
              />
            </div>
          </div>
          <div>
            <label>Agendar visita A PARTIR de ____ dias após a compra</label>
            <Form.Control
              value={values.days_after_usage_date}
              name={"days_after_usage_date"}
              type={"number"}
              onChange={handleChange}
            />
          </div>
          <div className="row">
            <div className="col">
              <label>
                Limite de dias para uso dos ingressos após a data final
              </label>
              <Form.Control
                value={values.ticket_usage_days_limit_after_end_date}
                name={"ticket_usage_days_limit_after_end_date"}
                type={"number"}
                onChange={handleChange}
              />
            </div>
          </div>
          {loadPartners ? (
            <ProgressBar animated now={100} />
          ) : (
            <div>
              <label>Validar parceiro</label>
              <Select
                classNamePrefix="react-select"
                value={[{ id: null, name: "Nenhum" }, ...partners].find(
                  (partner) => partner.id === values.partner_id
                )}
                onChange={(val) => handleSelectChange(val, "partner_id")}
                options={[{ id: null, name: "Nenhum" }, ...partners]}
                getOptionLabel={(opt) => opt.name}
              />
            </div>
          )}
          {loadingTickets ? (
            <ProgressBar animated now={100} />
          ) : (
            <div>
              <label>Tickets</label>
              <Select
                classNamePrefix="react-select"
                closeMenuOnSelect={false}
                value={values?.ticket_ids?.map((ticket_id) => ({
                  value: ticket_id,
                  label: `${
                    tickets?.find((item) => item.id === ticket_id)?.name
                  } - ${
                    tickets?.find((item) => item.id === ticket_id)?.unit_name
                  } (${getTicketType(
                    tickets?.find((item) => item.id === ticket_id)?.type
                  )})`,
                }))}
                onChange={(val) => handleMultiSelectChange(val, "ticket_ids")}
                options={tickets.map((ticket) => ({
                  label: `${ticket.name} - ${ticket.unit_name} (${getTicketType(
                    ticket.type
                  )})`,
                  value: ticket.id,
                }))}
                isMulti
              />
              <small>Deixar em branco para habilitar em todos os tickets</small>
            </div>
          )}
          <div className="row">
            <div className="col">
              <label>Acumular desconto com promoções?</label>
              <Form.Check
                type="checkbox"
                label={values.can_stack_promos ? "Ativado" : "Desativado"}
                checked={values.can_stack_promos}
                name="can_stack_promos"
                onChange={handleSwitchChange}
              />
            </div>
            <div className="col">
              <label>Ocultar nome do cupom no resumo para o cliente?</label>
              <Form.Check
                type="checkbox"
                label={
                  values.hide_code_in_order_summary ? "Ativado" : "Desativado"
                }
                checked={values.hide_code_in_order_summary}
                name="hide_code_in_order_summary"
                onChange={handleSwitchChange}
              />
            </div>
          </div>
          {[true, "true"].includes(values.can_stack_promos) && (
            <>
              {loadingPromotions ? (
                <ProgressBar animated now={100} />
              ) : (
                <div>
                  <label>Promoções</label>
                  <Select
                    classNamePrefix="react-select"
                    closeMenuOnSelect={false}
                    value={values?.available_promos_ids?.map((promo_id) => ({
                      value: promo_id,
                      label: promotions?.find((item) => item.id === promo_id)
                        ?.name,
                    }))}
                    onChange={(val) =>
                      handleMultiSelectChange(val, "available_promos_ids")
                    }
                    options={promotions.map((promo) => ({
                      label: promo.name,
                      value: promo.id,
                    }))}
                    isMulti
                  />
                  <small>
                    Deixar em branco para acumular com todas as promoções
                  </small>
                </div>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={load} variant="light" onClick={closeModal}>
            Cancelar
          </Button>
          {values.id && (
            <Button disabled={load} variant="danger" onClick={remove}>
              Remover
            </Button>
          )}
          <Button disabled={load} variant="primary" onClick={save}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditModal;
