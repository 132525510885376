import { useEffect } from "react";
import { useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  InputGroup,
  Modal,
  ProgressBar,
} from "react-bootstrap";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { toast } from "react-toastify";
import Api from "../../../api/api";
import Select from "react-select";
import { getPaymentMethods, paymentMethods } from "../../../utils/paymentType";

const selectStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: "2rem",
    borderBottomRightRadius: "2rem",
  }),
  container: (baseStyles, state) => ({
    ...baseStyles,
    alignSelf: "stretch",
    flex: 1,
  }),
};

const EditSaleProductsModal = ({
  open,
  setOpen,
  unit_id,
  onSuccess,
  order_id,
  participants,
  currentProducts,
  ticketType,
}) => {
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [products, setProducts] = useState([]);
  const [loadingSave, setLoadingSave] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");

  participants = Number(participants || 0);

  const handleClose = () => {
    if (loadingSave) return;
    setOpen(false);
  };

  const getProducts = () => {
    setLoadingProducts(true);
    Api.get(`products`, {
      params: {
        unit_id,
        ticket_type: ticketType === "ticket" ? "ticket" : "parties",
      },
    })
      .then((res) => handleProducts(res.data.data))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingProducts(false));
  };

  const handleProducts = (list) => {
    for (const item of list) {
      const existing = currentProducts?.find(
        (cp) => cp.unit_product_id === item.unit_product_id
      );
      if (existing) item.existing_amount = existing.amount;
    }

    setProducts(list);
  };

  useEffect(() => {
    if (unit_id) getProducts();
  }, [unit_id]);

  const renderProduct = (product) => {
    if (!product || !product.unit_product_id) return <></>;

    const incrementAmount = () => {
      product.amount = Number(product.amount || 0) + 1;
      product.total_value =
        product.charge_type === "participants"
          ? product.amount * Number(product.value) * participants
          : product.amount * Number(product.value);
      setProducts([...products]);
    };

    const decrementAmount = () => {
      if (!(product.amount > 0)) return;
      product.amount = Number(product.amount || 0) - 1;
      product.total_value =
        product.charge_type === "participants"
          ? product.amount * Number(product.value) * participants
          : product.amount * Number(product.value);
      setProducts([...products]);
    };

    const handleChange = (e) => {
      if ([true, "true"].includes(e.target.checked)) incrementAmount();
      else decrementAmount();
    };

    return (
      <div
        key={`product_${product.unit_product_id}`}
        className={
          "d-flex align-items-center justify-content-between rounded p-1 px-2 border border-primary bg-primary text-white"
        }
      >
        <span className="fw-bold">{product.name}</span>
        <div className="d-flex align-items-center gap-2">
          <span className="fw-bold text-secondary">
            {(product.value * (product.amount || 1)).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })}
          </span>
          {product.input_type === "switch" ? (
            <Form.Switch
              checked={Number(product.amount) === 1}
              onChange={handleChange}
              style={{ marginTop: -19 }}
              isValid={false}
              className="color-secondary"
            />
          ) : (
            <div className="d-flex align-items-center gap-2">
              <Button
                variant="secondary"
                className="text-primary rounded-circle"
                size="sm"
                onClick={decrementAmount}
              >
                <AiOutlineMinus />
              </Button>
              <span className="fs-5">
                {Number(product.existing_amount || 0) +
                  Number(product.amount || 0)}
              </span>
              <Button
                variant="secondary"
                className="text-primary rounded-circle"
                size="sm"
                onClick={incrementAmount}
              >
                <AiOutlinePlus />
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  };

  const transactionValue = (products || [])
    .map((product) =>
      product.charge_type === "participants"
        ? Number(product.amount || 0) * Number(product.value) * participants
        : Number(product.amount || 0) * Number(product.value)
    )
    .reduce((acum, value) => (acum += value), 0);

  const renderProducts = () => {
    if (!products || !products[0])
      return <span className="text-muted">Nenhum produto na unidade.</span>;

    return (
      <div className="d-flex flex-column gap-1">
        {products.map((product) => renderProduct(product))}
        <span>
          Valor total da transação:{" "}
          {transactionValue.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          })}
        </span>

        <FormGroup className="mt-3">
          <Form.Label>Forma de Pagamento</Form.Label>
          <Select
            classNamePrefix="react-select"
            value={
              getPaymentMethods("local", "ticket").find(
                (item) => item.value === paymentMethod
              ) || {
                label: "Selecione a forma de pagamento",
                value: "",
              }
            }
            onChange={(item) => setPaymentMethod(item.value)}
            options={getPaymentMethods("local", "ticket")}
            styles={selectStyles}
          />
        </FormGroup>
      </div>
    );
  };

  const handleEditOrderProducts = () => {
    setLoadingSave(true);
    Api.post(`orders/${order_id}/edit_products`, {
      payment_method: paymentMethod,
      products,
      transaction_value: transactionValue,
    })
      .then(() => {
        if (onSuccess) onSuccess();
        toast.success("Produtos da venda editados com sucesso!");
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingSave(false));
  };

  return (
    <Modal show={open} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Editar produtos da venda</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loadingProducts && <ProgressBar animated now={100} className="mb-1" />}
        {renderProducts()}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose} disabled={loadingSave}>
          Cancelar
        </Button>
        <Button onClick={handleEditOrderProducts} disabled={loadingSave}>
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditSaleProductsModal;
