import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, ProgressBar, Row } from "react-bootstrap";
import Api from "../../../api/api";
import { toast } from "react-toastify";

const PartiesPaymentsCSVModal = ({ handleClose, filter }) => {
  const [loadingCSV, setLoadingCSV] = useState(false);
  const [values, setValues] = useState([]);
  const [loadingHeaders, setLoadingHeaders] = useState(false);
  const [headers, setHeaders] = useState([]);

  const getHeaders = () => {
    setLoadingHeaders(true);
    Api.get(`/parties_payments/csv_headers`)
      .then((res) => {
        setHeaders(res.data);
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingHeaders(false));
  };

  useEffect(() => {
    getHeaders();
  }, []);

  const onClose = () => {
    if (handleClose) handleClose();
  };

  const renderHeader = (header) => {
    if (!header) return <></>;

    const handleItemChange = (e) => {
      const list = [...headers];
      list.find((item) => item.field === header.field).download =
        e.target.checked;

      setValues(list);
    };

    return (
      <Col key={`csv_header_${header.field}`} xs={12} sm={6}>
        <Form.Check
          checked={[true, "true"].includes(
            values.find((h) => h.field === header.field)?.download
          )}
          className="ms-2"
          onChange={handleItemChange}
          label={header.label}
        />
      </Col>
    );
  };

  const renderHeaders = () => {
    if (!headers || !headers[0]) return <></>;

    return (
      <Row className="g-0">{headers.map((header) => renderHeader(header))}</Row>
    );
  };

  const handleSubmit = () => {
    setLoadingCSV(true);
    Api.post(`/parties_payments/csv`, {
      headers: values.filter((item) => item.download),
      filter,
    })
      .then((res) => {
        const blob = new Blob([res.data], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        window.open(url, "_top");
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingCSV(false));
  };

  const handleCheckAll = () => {
    setValues([...headers.map((h) => ({ ...h, download: true }))]);
  };

  const handleUncheckAll = () => {
    setValues([...headers.map((h) => ({ ...h, download: false }))]);
  };

  return (
    <Modal onHide={onClose} show={true} size="lg">
      <Modal.Header>
        <Modal.Title>Baixar CSV dos pagamentos</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loadingHeaders ? <ProgressBar animated now={100} /> : renderHeaders()}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose} disabled={loadingCSV} variant="light">
          Fechar
        </Button>
        <Button
          variant={"outline-primary"}
          onClick={handleUncheckAll}
          disabled={loadingCSV}
        >
          Desmarcar Tudo
        </Button>
        <Button
          variant={"outline-primary"}
          onClick={handleCheckAll}
          disabled={loadingCSV}
        >
          Marcar Tudo
        </Button>
        <Button onClick={handleSubmit} disabled={loadingCSV}>
          Baixar CSV
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PartiesPaymentsCSVModal;
