import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, ProgressBar, Row, Card } from "react-bootstrap";
import { getTicketTypes } from "../../../../utils/functions";
import Api from "../../../../api/api";
import { FilialContext } from "../../../../context/FilialContext";
import { toast } from "react-toastify";

const Products = ({
  handleNext,
  handleCancel,
  sale,
  setSale,
  loadingProducts,
}) => {
  const { selectedUnitId } = useContext(FilialContext);

  const products = sale.products;

  const [loadingBuffets, setLoadingBuffets] = useState(false);
  const [buffets, setBuffets] = useState([]);

  useEffect(() => {
    const getBuffets = () => {
      setLoadingBuffets(true);
      Api.get(`buffets`, { params: { unit_id: selectedUnitId } })
        .then((res) => {
          setBuffets(res.data.list);
        })
        .catch((err) => toast.error(err.message))
        .finally(() => setLoadingBuffets(false));
    };

    if (sale?.type && selectedUnitId) getBuffets();
  }, [sale?.type, selectedUnitId]);

  const hasKiosk = !!sale.products.find(
    (prod) => prod.name.toLowerCase().includes("quiosque") && prod.amount > 0
  );

  useEffect(() => {
    if (!hasKiosk) {
      sale.products.forEach((prod) => {
        if (prod.name.toLowerCase().includes("cardápio") && !prod.amount)
          prod.amount = prod.min_amount || 0;
      });
      setSale({ ...sale });
    }
  }, [hasKiosk]);

  const getPartyBuffetInput = () => {
    const handleChange = (e) => {
      setSale((prev) => ({
        ...prev,
        buffet_id: e.target.value,
        buffet_value: buffets.find(
          (b) => Number(b.id) === Number(e.target.value)
        )?.value,
      }));
    };

    if (sale && getTicketTypes("parties").includes(sale.type))
      return (
        <Col xs={12}>
          <div className={`d-flex flex-column gap-1`}>
            <span>Buffet</span>
            <Form.Select
              value={sale.buffet_id}
              disabled={loadingBuffets}
              onChange={handleChange}
            >
              <option value={0}>Sem buffet</option>
              {buffets.map((buffet) => (
                <option key={`buffet_item_${buffet.id}`} value={buffet.id}>{`${
                  buffet.name
                } (${buffet.value.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}/participante)`}</option>
              ))}
            </Form.Select>
          </div>
        </Col>
      );
  };

  return (
    <div>
      <span className="fs-5 fw-bold">{"Configurações de produtos:"}</span>

      {loadingProducts && <ProgressBar animated now={100} />}
      <Row className="g-3">
        {getPartyBuffetInput()}
        {products.map((prod, index) => {
          const handleChange = (e) => {
            if (e.target.type === "checkbox") {
              if ([true, "true"].includes(e.target.checked))
                prod[e.target.name] = 1;
              else prod[e.target.name] = 0;
            } else {
              prod[e.target.name] = e.target.value;
            }
            setSale({ ...sale });
          };

          const getInput = () => {
            switch (prod.input_type) {
              case "switch":
                return (
                  <Form.Switch
                    checked={Number(prod.amount) === 1}
                    onChange={handleChange}
                    name="amount"
                    style={{ marginTop: -18 }}
                  />
                );
              default:
                return (
                  <Form.Control
                    type={"number"}
                    min={0}
                    value={prod.amount || 0}
                    onChange={handleChange}
                    name="amount"
                  />
                );
            }
          };

          return (
            <Col
              xs={12}
              sm={6}
              key={
                "product_" + prod.name + "_" + moment().valueOf() + "_" + index
              }
            >
              <div
                className={`d-flex ${
                  prod.input_type === "switch"
                    ? "form-control flex-row-reverse justify-content-end h-100 align-items-center"
                    : "flex-column"
                } gap-1`}
              >
                <span>{`${prod.name} (${prod.value.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}${
                  prod.charge_type === "participants" ? "/participante" : ""
                })`}</span>
                {getInput()}
              </div>
            </Col>
          );
        })}
      </Row>
      {(handleCancel || handleNext) && (
        <div className="d-flex gap-1 ml-auto">
          {handleCancel && (
            <Button variant="light" onClick={handleCancel}>
              Cancelar
            </Button>
          )}
          {handleNext && <Button onClick={handleNext}>Avançar</Button>}
        </div>
      )}
    </div>
  );
};

export default Products;
