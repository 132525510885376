import React from "react";
import { Button, ListGroup } from "react-bootstrap";
import { AiFillEye } from "react-icons/ai";

const ListRow = ({ item, setSelectedItem = console.log }) => {
  const handleClick = () => {
    setSelectedItem(item);
  };

  return (
    <ListGroup.Item
      variant="primary"
      className="d-flex p-1 justify-content-between align-items-center rounded user-select-none"
    >
      <span>{item.title}</span>
      <div className="d-flex gap-1 align-items-center justify-content-center">
        {item.display && (
          <AiFillEye data-toggle="tooltip" title="Mostrando no site" />
        )}
        <Button className="" onClick={handleClick}>
          Editar
        </Button>
      </div>
    </ListGroup.Item>
  );
};

export default ListRow;
