import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormLabel,
  InputGroup,
  Modal,
  ProgressBar,
  Row,
} from "react-bootstrap";
import { toast } from "react-toastify";
import Api from "../../../api/api";
import Select from "react-select";
import TicketDP from "../../NewSalesTicket/TicketDP";
import moment from "moment";
import SaleProducts from "./Products";
import SaleTransactions from "./Transactions";
import SaleBirthdayPeople from "./BirthdayPeople";
import SaleAttachments from "./Attachments";
import SaleBuffet from "./Buffet";
import SaleUpdateLogModal from "../../../components/SaleUpdateLogModal";

const selectStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: "2rem",
    borderBottomRightRadius: "2rem",
  }),
  container: (baseStyles, state) => ({
    ...baseStyles,
    alignSelf: "stretch",
    flex: 1,
  }),
};

const partyStatuses = [
  {
    value: 1,
    label: "Pré-reserva",
  },
  {
    value: 4,
    label: "Reserva",
  },
  {
    value: 2,
    label: "Realizada",
  },
];

const EditSaleModal = ({ open, setOpen, code, onSuccess }) => {
  const [sale, setSale] = useState({});
  const [loadingData, setLoadingData] = useState(false);
  const [hasCourtesy, setHasCourtesy] = useState(
    sale.courtesy_players > 0 || sale.courtesy_not_players > 0 || false
  );
  const [loadingTickets, setLoadingTickets] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [loadHours, setLoadHours] = useState(false);
  const [hours, setHours] = useState([]);
  const [prevPlayers, setPrevPlayers] = useState(0);
  const [loadingSave, setLoadingSave] = useState(false);
  const [openUpdateLog, setOpenUpdateLog] = useState(false);

  const getTickets = () => {
    setLoadingTickets(true);
    Api.get("tickets", {
      params: {
        unit_id: sale.unit_id,
        type: sale.type,
        date: sale.date ? moment(sale.date).format("YYYY-MM-DD") : null,
        areas_amount: sale.park_areas_ids?.length,
      },
    })
      .then((res) => setTickets(res.data.list))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingTickets(false));
  };

  useEffect(() => {
    if (sale?.unit_id && sale?.type) getTickets();
  }, [sale?.unit_id, sale?.type, sale?.date, sale?.park_areas_ids]);

  const getData = () => {
    setLoadingData(true);
    Api.get(`orders/code/${code}/editing`)
      .then((res) => {
        setSale(res.data.data);
        setHasCourtesy(
          res.data.data.courtesy_players > 0 ||
            res.data.data.courtesy_not_players > 0
            ? true
            : false
        );
        setPrevPlayers(sumAllPlayers(res.data.data));
      })
      .catch((err) => {
        handleClose();
        toast.error(err.message);
      })
      .finally(() => setLoadingData(false));
  };

  useEffect(() => {
    if (code && open) getData();
  }, [code, open]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    if (e.target.name === "date") {
      if (moment("2100-12-31").isBefore(moment(e.target.value)))
        e.target.value = moment().format("YYYY-MM-DD");
      if (moment("1900-01-01").isAfter(moment(e.target.value)))
        e.target.value = moment().format("YYYY-MM-DD");
    }

    if (e.target.type === "number" && e.target.value < 0) e.target.value = 0;

    if (
      e.target.name === "minors_responsibles" &&
      Number(e.target.value) > sale.minors
    )
      e.target.value = sale.minors;

    if (
      e.target.name === "dps_responsibles" &&
      Number(e.target.value) > sale.dps
    )
      e.target.value = sale.dps;

    setSale((prev) => {
      let newSale = {
        ...prev,
        [e.target.name]:
          e.target.type === "number" ? Number(e.target.value) : e.target.value,
      };

      if (e.target.name === "minors") {
        if (Number(e.target.value) < prev.minors_responsibles) {
          newSale.minors_responsibles = Number(e.target.value);
        }

        if (Number(e.target.value) === 0) {
          newSale.kids_value = 0;
        }
      }

      if (e.target.name === "minors_responsibles") {
        if (
          Number(e.target.value) === prev.minors &&
          prev.minors > newSale.minors
        ) {
          newSale.minors_responsibles = newSale.minors;
        }
      }

      if (e.target.name === "dps") {
        if (Number(e.target.value) < prev.dps_responsibles) {
          newSale.dps_responsibles = Number(e.target.value);
        }
        if (Number(e.target.value) === 0) {
          newSale.pcd_value = 0;
        }
      }

      if (e.target.name === "dps_responsibles") {
        if (Number(e.target.value) === prev.dps && prev.dps > newSale.dps) {
          newSale.dps_responsibles = newSale.dps;
        }
      }

      return newSale;
    });
  };

  const handleHasKidsChange = (item) => {
    const kidsValue = item.value ? 1 : 0;
    setSale((prev) => ({
      ...prev,
      kids_value: kidsValue,
      minors: kidsValue,
      minors_responsibles: kidsValue,
    }));

    if (!item.value) {
      setSale((prev) => ({
        ...prev,
        kids_value: 0,
        minors: 0,
        minors_responsibles: 0,
      }));
    }
  };

  const handleHasPCDChange = (item) => {
    const pcdValue = item.value ? 1 : 0;
    setSale((prev) => ({
      ...prev,
      pcd_value: pcdValue,
      dps: pcdValue,
    }));

    if (!item.value) {
      setSale((prev) => ({
        ...prev,
        dps: 0,
        dps_responsibles: 0,
        ticket_disabled_people: [],
      }));
    }
  };

  const yesNo = [
    { value: false, label: "Não" },
    { value: true, label: "Sim" },
  ];

  const handleTicketDPChange = (ticket_disabled_people) => {
    setSale((prev) => ({ ...prev, ticket_disabled_people }));
  };

  const handleHasCourtesyChange = (item) => {
    setHasCourtesy(item.value);
    if (!item.value)
      setSale((prev) => ({
        ...prev,
        courtesy_players: 0,
        courtesy_not_players: 0,
      }));
  };

  const handleSelectChange = (e, field) => {
    setSale((prev) => ({
      ...prev,
      [field]: field === "park_areas_ids" ? e : e.value,
    }));
  };

  const sumAllPlayers = (prev) => {
    return Number(
      (Number((prev || sale).players) || 0) +
        (Number((prev || sale).minors_responsibles) || 0) +
        (Number((prev || sale).minors) || 0) +
        (Number((prev || sale).dps) || 0) +
        (Number((prev || sale).dps_responsibles) || 0) +
        (Number((prev || sale).courtesy_players) || 0)
    );
  };

  useEffect(() => {
    const getHours = () => {
      let players = sumAllPlayers() - prevPlayers;
      if (players < 0) players = 0;

      setLoadHours(true);
      Api.get(`/calendar_dates_hours/available`, {
        params: {
          date: sale.date,
          duration: sale.duration,
          unit_id: sale.unit_id,
          players,
          type: sale.type,
          editing: true,
          exclusive_party_id: sale.id,
        },
      })
        .then((res) => {
          setHours(res?.data.hours);
        })
        .catch((err) => {
          toast.error(err.message);
          setHours([]);
        })
        .finally(() => {
          setLoadHours(false);
        });
    };

    if (
      prevPlayers >= 0 &&
      sumAllPlayers() > 0 &&
      sale.date &&
      sale.unit_id &&
      sale.duration &&
      sale.type &&
      sale.id > 0
    )
      getHours();
  }, [
    sale.date,
    sale.unit_id,
    sale.duration,
    sumAllPlayers(),
    sale.type,
    prevPlayers,
    sale.id,
  ]);

  const getHourSuffix = (item) => {
    if (!item || !item.available_spaces) return "Esgotado";

    let suffix = "";

    if (Number(item.available_spaces) <= 15)
      suffix = `${item.available_spaces} vaga${
        item.available_spaces > 1 ? "s" : ""
      } disponíveis`;
    else if (Number(item.available_spaces) <= Number(item.total_spaces) / 2)
      suffix = "Últimas vagas";
    else suffix = "Disponível";

    return suffix;
  };

  const renderHoursOptions = () => {
    return [
      <option>Select...</option>,
      hours.map((item, index) => {
        return (
          <option key={`time_${item.time}`} value={item.time}>
            {`${moment(item.time, "HH:mm:SS").format("HH:mm")} ${getHourSuffix(
              item
            )}`}
          </option>
        );
      }),
    ];
  };

  const selectedTicket = tickets.find(
    (ticket) => Number(ticket.id) === Number(sale.ticket_id)
  );

  useEffect(() => {
    if (selectedTicket)
      setSale((prev) => ({
        ...prev,
        player_price: selectedTicket.player_price,
        not_player_price: selectedTicket.not_player_price,
        minor_price: selectedTicket.minor_price,
        minor_responsible_price: selectedTicket.minor_responsible_price,
        dp_price: selectedTicket.dp_price,
        dp_responsible_price: selectedTicket.dp_responsible_price,
        exclusive_party_limit: selectedTicket.exclusive_party_limit,
        additional_hour_price: selectedTicket.additional_hour_price,
        ticket_id: selectedTicket.id,
        duration: selectedTicket.duration,
      }));
  }, [sale.ticket_id, selectedTicket]);

  const totalPlayers =
    Number(sale.players || 0) +
    Number(sale.minors || 0) +
    Number(sale.minors_responsibles || 0) +
    Number(sale.dps || 0) +
    Number(sale.dps_responsibles || 0) +
    Number(sale.courtesy_players || 0);

  const totalNotPlayers =
    Number(sale.not_players || 0) + Number(sale.courtesy_not_players || 0);

  const participants = totalPlayers + totalNotPlayers;

  const handleSave = () => {
    setLoadingSave(true);
    Api.put(`orders/code/${code}/editing`, {
      ...sale,
    })
      .then(() => {
        toast.success("Festa atualizada com sucesso!");
        handleClose();
        if (onSuccess) onSuccess();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingSave(false));
  };

  const onDataUpdate = () => {
    getData();
    if (onSuccess) onSuccess();
  };

  useEffect(() => {
    setSale((prev) => ({ ...prev, ticket_total: prev.value }));
  }, [sale?.value]);

  const handleOpenUpdateLog = () => {
    setOpenUpdateLog(true);
  };

  return (
    <Modal show={open} onHide={handleClose} size="lg">
      {openUpdateLog && (
        <SaleUpdateLogModal
          open={openUpdateLog}
          setOpen={setOpenUpdateLog}
          order_id={sale?.id}
        />
      )}
      <Modal.Header closeButton>
        <Modal.Title>
          Editar dados da venda ID {sale?.code?.split("-")[0]}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loadingData && <ProgressBar animated now={100} />}
        <div className="d-flex flex-column gap-1">
          <span className="fs-5 text-primary">
            Dados do ingresso e participantes
          </span>

          <Row>
            <Col lg="6">
              <label>Data:</label>
              <Form.Control
                value={moment(sale.date).format("YYYY-MM-DD")}
                name={"date"}
                type={"date"}
                onChange={handleChange}
              />
            </Col>

            <Col lg="6">
              <label>Status da Festa</label>
              <Select
                classNamePrefix="react-select"
                value={partyStatuses.find(
                  (item) => item.value === sale.order_ticket_status
                )}
                onChange={(val) =>
                  handleSelectChange(val, "order_ticket_status")
                }
                options={partyStatuses}
                styles={selectStyles}
              />
            </Col>
          </Row>

          <Row>
            <Col lg="6">
              <label>Ingressos Brincantes</label>
              <Form.Control
                value={sale.players}
                name={"players"}
                type={"number"}
                onChange={handleChange}
              />
            </Col>
            <Col lg="6">
              <label>Ingressos Não Brincantes</label>
              <Form.Control
                value={sale.not_players}
                name={"not_players"}
                type={"number"}
                onChange={handleChange}
              />
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <label>Participantes menores de 5 anos?</label>
              <Select
                classNamePrefix="react-select"
                value={yesNo.find(
                  (item) => item.value === (sale.minors > 0 ? true : false)
                )}
                onChange={(val) => handleHasKidsChange(val)}
                options={yesNo}
                styles={selectStyles}
              />
            </Col>

            {sale.minors > 0 && (
              <Col lg="12">
                <Row>
                  <Col lg="6">
                    <label>Ingressos Crianças</label>
                    <Form.Control
                      value={sale.minors}
                      name={"minors"}
                      type={"number"}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col lg="6">
                    <label>Ingressos Acompanhantes</label>
                    <Form.Control
                      value={sale.minors_responsibles}
                      name={"minors_responsibles"}
                      type={"number"}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
              </Col>
            )}

            <Col lg="12">
              <label>Participantes PCD?</label>
              <Select
                classNamePrefix="react-select"
                value={yesNo.find(
                  (item) => item.value === (sale.dps > 0 ? true : false)
                )}
                onChange={handleHasPCDChange}
                options={yesNo}
                styles={selectStyles}
              />
            </Col>

            {sale.dps > 0 && (
              <>
                <Col lg="12">
                  <Row>
                    <Col lg="6">
                      <label>Ingressos PCD</label>
                      <Form.Control
                        value={sale.dps}
                        name={"dps"}
                        type={"number"}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col lg="6">
                      <label>Ingressos Acompanhantes</label>
                      <Form.Control
                        value={sale.dps_responsibles}
                        name={"dps_responsibles"}
                        type={"number"}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>

                  <label style={{ margin: 0, padding: 0 }}>Deficiências</label>
                  <TicketDP
                    prevList={sale.ticket_disabled_people}
                    onChange={handleTicketDPChange}
                    totalNumberDps={sale.dps}
                  />
                </Col>
              </>
            )}
          </Row>

          <Row>
            <Col lg="12">
              <label>Cortesias?</label>
              <Select
                classNamePrefix="react-select"
                value={yesNo.find((item) => item.value === hasCourtesy)}
                onChange={(val) => handleHasCourtesyChange(val)}
                options={yesNo}
                styles={selectStyles}
              />
            </Col>

            {hasCourtesy && (
              <Col lg="12">
                <Row>
                  <Col lg="6">
                    <label>Cortesias Brincantes</label>
                    <Form.Control
                      value={sale.courtesy_players}
                      name={"courtesy_players"}
                      type={"number"}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col lg="6">
                    <label>Cortesias Não Brincantes</label>
                    <Form.Control
                      value={sale.courtesy_not_players}
                      name={"courtesy_not_players"}
                      type={"number"}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
              </Col>
            )}
          </Row>

          <Row>
            <Col lg="6">
              <label>Duração</label>
              <Select
                classNamePrefix="react-select"
                value={
                  sale.duration > 0
                    ? {
                        value: sale.duration,
                        label: `${sale.duration}hr${
                          sale?.duration > 1 ? "s" : ""
                        } de diversão (${sale?.player_price.toLocaleString(
                          "pt-br",
                          {
                            style: "currency",
                            currency: "BRL",
                          }
                        )})`,
                      }
                    : { label: "Selecione a duração do cliente no parque" }
                }
                onChange={(val) => handleSelectChange(val, "ticket_id")}
                options={tickets.map((ticket) => ({
                  label: `${ticket.duration}hr${
                    ticket.duration > 1 ? "s" : ""
                  } de diversão (${ticket.player_price.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })})`,
                  value: ticket.id,
                }))}
                styles={selectStyles}
              />
            </Col>
            {sale.duration && (
              <Col lg="6">
                <label>Horário</label>
                <select
                  className={`ticket-schedule form-select `}
                  name="time"
                  onChange={handleChange}
                  disabled={loadHours}
                  value={sale.time}
                >
                  {renderHoursOptions()}
                </select>
              </Col>
            )}
          </Row>

          <Row>
            <Col>
              <label>Informações Adicionais</label>
              <Form.Control
                as="textarea"
                value={sale.additional_info}
                name={"additional_info"}
                onChange={handleChange}
              />
            </Col>
          </Row>

          <Row>
            <Col lg="6">
              <label>Valor de Desconto</label>
              <Form.Control
                value={sale.coupon_discount_value}
                name={"coupon_discount_value"}
                type={"number"}
                onChange={handleChange}
              />
            </Col>
            <Col lg="6">
              <label>Valor Total</label>
              <Form.Control
                value={sale.value}
                name={"value"}
                type={"number"}
                onChange={handleChange}
              />
            </Col>
          </Row>

          <Button variant="primary" onClick={handleSave} disabled={loadingSave}>
            Salvar
          </Button>
          <div className="d-flex flex-column gap-2">
            <div className="d-flex flex-column gap-1" style={{ flex: 1 }}>
              <SaleBirthdayPeople onSuccess={onDataUpdate} sale={sale} />
            </div>
            <div className="d-flex flex-column gap-1" style={{ flex: 1 }}>
              <SaleProducts
                onSuccess={onDataUpdate}
                sale={sale}
                participants={participants}
              />
            </div>
            <div className="d-flex flex-column gap-1" style={{ flex: 1 }}>
              <SaleAttachments onSuccess={onDataUpdate} sale={sale} />
            </div>
            <div className="d-flex flex-column gap-1" style={{ flex: 1 }}>
              <SaleTransactions onSuccess={onDataUpdate} sale={sale} />
            </div>
            <div className="d-flex flex-column gap-1" style={{ flex: 1 }}>
              <SaleBuffet onSuccess={onDataUpdate} sale={sale} />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={handleClose}
          disabled={loadingSave}
        >
          Cancelar
        </Button>
        <Button
          variant="outline-secondary"
          onClick={handleOpenUpdateLog}
          disabled={loadingSave}
        >
          Log de Alterações
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditSaleModal;
