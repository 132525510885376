import {
  Button,
  Col,
  Form,
  InputGroup,
  Row,
  Spinner,
  Card,
} from "react-bootstrap";
import PageTitle from "../../components/PageTitle";
import { useContext, useEffect, useState } from "react";
import moment from "moment";
import { FilialContext } from "../../context/FilialContext";
import Api from "../../api/api";
import { toast } from "react-toastify";
import CSVModal from "../Home2/CSVModal";
import SalesList from "../../components/SalesList";
import TransactionsList from "../../components/TransactionsList";
import TransactionsCSVModal from "./TransactionsCSVModal";

const SalesReport = () => {
  const { selectedUnitId } = useContext(FilialContext);

  const [loadingReport, setLoadingReport] = useState(false);
  const [loadingCSV, setLoadingCSV] = useState(false);
  const [loadingTransactionsCSV, setLoadingTransactionsCSV] = useState(false);
  const [filter, setFilter] = useState({
    start_date: moment().format("YYYY-MM-DD"),
    end_date: "",
    unit_id: selectedUnitId,
  });
  const [report, setReport] = useState([]);
  const [csvHeaders, setCSVHeaders] = useState(null);
  const [allUnitiesData, setAllUnitiesData] = useState(false);
  const [transactionsCsvHeaders, setTransactionsCSVHeaders] = useState(null);
  const [usingTicketDate, setUsingTicketDate] = useState(false);

  const handleFilterChange = (e) => {
    setFilter((prev) => ({
      ...prev,
      [e.target.name]: moment(e.target.value).isValid()
        ? moment(e.target.value).format("YYYY-MM-DD")
        : "",
    }));
  };

  const handleDownloadCSV = () => {
    setLoadingCSV(true);

    Api.get(`orders/csv_headers`)
      .then((res) => {
        setCSVHeaders(res.data);
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingCSV(false));
  };

  const handleDownloadTransactionsCSV = () => {
    setLoadingTransactionsCSV(true);

    Api.get(`transactions/csv_headers`)
      .then((res) => {
        setTransactionsCSVHeaders(res.data);
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingTransactionsCSV(false));
  };

  const getReport = () => {
    setLoadingReport(true);
    Api.get(`usage/report`, { params: filter })
      .then((res) => setReport(res.data))
      .catch((err) => {
        toast.error(err.message);
        setReport([]);
      })
      .finally(() => setLoadingReport(false));
  };

  useEffect(() => {
    if (filter.unit_id || allUnitiesData) getReport();
  }, [filter]);

  const handleCloseCSVModal = () => {
    setCSVHeaders(null);
  };

  const handleCloseTransactionsCSVModal = () => {
    setTransactionsCSVHeaders(null);
  };

  const handleAllUnitiesDataChange = (e) => {
    setAllUnitiesData(e.target.checked ? true : false);
  };

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      unit_id: allUnitiesData ? null : selectedUnitId,
    }));
  }, [allUnitiesData, selectedUnitId]);

  useEffect(() => {
    document.getElementById("topbar")?.scrollIntoView();
  }, []);

  const handleUsingTicketDateChange = (e) => {
    setUsingTicketDate(e.target.checked ? true : false);
  };

  return (
    <>
      {csvHeaders && (
        <CSVModal
          handleClose={handleCloseCSVModal}
          headers={csvHeaders}
          filter={{
            order_date_start: usingTicketDate ? null : filter.start_date,
            order_date_end: usingTicketDate ? null : filter.end_date,
            ticket_date_start: usingTicketDate ? filter.start_date : null,
            ticket_date_end: usingTicketDate ? filter.end_date : null,
            unit_id: allUnitiesData ? null : selectedUnitId,
          }}
        />
      )}
      {transactionsCsvHeaders && (
        <TransactionsCSVModal
          handleClose={handleCloseTransactionsCSVModal}
          headers={transactionsCsvHeaders}
          filter={{
            start_date: usingTicketDate ? null : filter.start_date,
            end_date: usingTicketDate ? null : filter.end_date,
            ticket_date_start: usingTicketDate ? filter.start_date : null,
            ticket_date_end: usingTicketDate ? filter.end_date : null,
            unit_id: allUnitiesData ? null : selectedUnitId,
          }}
        />
      )}
      <PageTitle className="d-flex flex-column flex-md-row">
        <h2>Relatório de Vendas</h2>
        {loadingReport && <Spinner />}
        <div
          className="d-flex align-itens-center justify-content-center"
          style={{ gap: 15 }}
        >
          <div>
            <label className="ml-2 mb-0 color-gray" htmlFor="">
              Data inicial
            </label>
            <input
              type="date"
              name="start_date"
              id=""
              className="form-control"
              style={{ width: 160 }}
              value={filter.start_date}
              onChange={handleFilterChange}
            />
          </div>
          <div>
            <label className="ml-2 mb-0" htmlFor="">
              Data final
            </label>
            <input
              type="date"
              name="end_date"
              id=""
              className="form-control"
              style={{ width: 160 }}
              value={filter.end_date}
              onChange={handleFilterChange}
            />
          </div>
        </div>
      </PageTitle>
      <InputGroup>
        <label>Mostrar dados de todas as unidades</label>
        <Form.Check
          type="switch"
          checked={allUnitiesData}
          onChange={handleAllUnitiesDataChange}
          className="ml-2 switch-lg"
        />
      </InputGroup>
      <InputGroup>
        <label>Filtrar por data de ingresso/uso</label>
        <Form.Check
          type="switch"
          checked={usingTicketDate}
          onChange={handleUsingTicketDateChange}
          className="ml-2 switch-lg"
        />
      </InputGroup>
      <Row className="g-4">
        <Col xs={12}>
          <Card>
            <Card.Body>
              <SalesList
                orderStartDate={usingTicketDate ? null : filter.start_date}
                orderEndDate={usingTicketDate ? null : filter.end_date}
                allUnitiesData={allUnitiesData}
                ticketStartDate={usingTicketDate ? filter.start_date : null}
                ticketEndDate={usingTicketDate ? filter.end_date : null}
              />
            </Card.Body>
          </Card>
          <div className="d-flex align-items-center justify-content-end mt-2">
            <Button
              className="btn-md"
              disabled={loadingCSV}
              onClick={handleDownloadCSV}
            >
              Baixar CSV das Vendas
            </Button>
          </div>
        </Col>
        <Col xs={12}>
          <TransactionsList
            startDate={usingTicketDate ? null : filter.start_date}
            endDate={usingTicketDate ? null : filter.end_date}
            allUnitiesData={allUnitiesData}
            ticketStartDate={usingTicketDate ? filter.start_date : null}
            ticketEndDate={usingTicketDate ? filter.end_date : null}
          />
          <div className="d-flex align-items-center justify-content-end mt-2">
            <Button
              className="btn-md"
              disabled={loadingTransactionsCSV}
              onClick={handleDownloadTransactionsCSV}
            >
              Baixar CSV das Transações
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default SalesReport;
