import { useEffect } from "react";
import { useContext, useState } from "react";

import Api from "../../api/api";
import { FilialContext } from "../../context/FilialContext";

export function useTickets() {
  const [currentPage, setCurrentPage] = useState(1);
  const { filial } = useContext(FilialContext);
  const [perPage, setPerPage] = useState(15);

  useEffect(() => {
    setCurrentPage(1);
  }, [filial, perPage]);

  const [users, setUser] = useState([]);
  const [ticketsTotal, setTicketsTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    search
      ? Api.get(`user/list?per_page=${perPage}&q=${search}`).then((res) => {
          setTicketsTotal(res.data.users?.pagination.lastPage);
        })
      : Api.get(`user/list?per_page=${perPage}`).then((res) => {
          setTicketsTotal(res.data.users.pagination.lastPage);
        });
  }, [perPage, search]);

  function getTickets({ page, perPage, creationDate }) {
    setPerPage(perPage);
    setLoading(true);
    Api.get(
      `user/list?per_page=${perPage}${
        page !== 1 ? `&current_page=${page}` : ""
      }&creation_date=${creationDate}`
    )
      .then((res) => {
        setLoading(false);
        const filialData = res.data.users.data;
        setUser(filialData);
      })
      .catch(() => setLoading(false));
  }
  
  function getTicketsSearch({ searchPage, page, perPage, creationDate }) {
    setSearch(searchPage);
    setPerPage(perPage);
    setLoading(true);
    Api.get(
      `user/list?per_page=${perPage}${
        page !== 1 ? `&current_page=${page}` : ""
      }&q=${searchPage}&creation_date=${creationDate}`
    )
      .then((res) => {
        const filialData = res.data.users.data;
        setLoading(false);
        setUser(filialData);
      })
      .catch(() => setLoading(false));
  }

  return {
    getTickets,
    users,
    currentPage,
    setCurrentPage,
    perPage,
    setPerPage,
    loading,
    getTicketsSearch,
    ticketsTotal,
  };
}
