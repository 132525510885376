import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import Api from "../../../../api/api";
import EditModal from "./EditModal";
import List from "./List";

const CarouselContentModal = ({ open, setOpen, values, setValues }) => {
  const [list, setList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [load, setLoad] = useState(false);

  const closeModal = () => setOpen(false);

  const handleListChange = (newList) => {
    setList(newList);
  };

  const handleSave = () => {
    setLoad(true);
    Api.put(`website_content`, { home_carousel_content: list })
      .then((res) => {
        toast.success("Banner atualizado com sucesso!");
        setValues(res.data.data);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => setLoad(false));
  };

  const handleNewItem = () => {
    setSelectedItem("new");
  };

  useEffect(() => {
    setList(open ? [...values.home_carousel_content] : []);
  }, [open]);

  const handleSaveItem = (item, index) => {
    if (index === "new") setList((prev) => [...prev, item]);
    else setList((prev) => prev.splice(index, 0, item));
    setSelectedItem(null);
  };

  const handleRemoveItem = (index) => {
    const newList = [...list];
    newList.splice(index, 1);
    setList([...newList]);
    setSelectedItem(null);
  };

  return (
    <>
      <Modal show={open} onHide={closeModal} size={"md"}>
        <EditModal
          open={selectedItem !== null ? true : false}
          setIndex={setSelectedItem}
          index={selectedItem}
          list={list}
          handleSave={handleSaveItem}
          handleRemove={handleRemoveItem}
        />
        <Modal.Title className="align-self-center">
          Conteúdo do Carrossel da Home
        </Modal.Title>
        <Modal.Body className="d-flex flex-column gap-1">
          <List
            list={list}
            onChange={handleListChange}
            setSelectedItem={setSelectedItem}
          />
          <Button variant="outline-primary" onClick={handleNewItem}>
            Novo item
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={closeModal}>
            Fechar
          </Button>
          <Button onClick={handleSave}>Salvar</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CarouselContentModal;
