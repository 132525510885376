import { useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";

export const PagesBtn = ({
  setCurrentPage,
  currentPage,
  ticketsTotal,
  setPerPage,
  perPage,
}) => {
  return (
    <>
      <Pagination size="sm" className="d-flex align-items-center">
        {currentPage > 2 && (
          <Pagination.Item number={1} onClick={() => setCurrentPage(1)}>
            1
          </Pagination.Item>
        )}
        {currentPage > 1 && (
          <Pagination.Item
            number={currentPage - 1}
            onClick={() => setCurrentPage(currentPage - 1)}
          >
            {currentPage - 1}
          </Pagination.Item>
        )}
        <Pagination.Item
          number={currentPage}
          active
          onClick={() => setCurrentPage(currentPage)}
        >
          {currentPage}
        </Pagination.Item>
        {ticketsTotal >= perPage && (
          <Pagination.Item
            number={currentPage + 1}
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            {currentPage + 1}
          </Pagination.Item>
        )}
        <select
          className="border-secondary bg-white ml-2"
          style={{ width: 50, height: 25, borderRadius: "2rem" }}
          onChange={(e) => {
            setPerPage(e.target.value);
          }}
          id=""
        >
          <option value={15}>15</option>
          <option value={30}>30</option>
          <option value={50}>50</option>
          <option value={80}>80</option>
          <option value={100}>100</option>
        </select>
      </Pagination>
    </>
  );
};
