import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import Api from "../../../api/api";
import { toast } from "react-toastify";
import { Col, ProgressBar, Row } from "react-bootstrap";
import SimulatorMetricsChart from "./SimulatorMetricsChart";
import { FilialContext } from "../../../context/FilialContext";
import SimulatorMetricsTotalizerChart from "./SimulatorMetricsTotalizerChart";
import SimulatorMetricsWeekDayTotalizerChart from "./SimulatorMetricsWeekDayTotalizerChart";

const SimulatorMetrics = () => {
  const { filial } = useContext(FilialContext);
  const [load, setLoad] = useState(false);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({
    start_date: moment().subtract(6, "days").format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
  });

  const [loadWeek, setLoadWeek] = useState(false);
  const [dataWeek, setDataWeek] = useState([]);
  const [filterWeek, setFilterWeek] = useState({
    start_date: moment().subtract(1, "month").format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
  });

  const getData = () => {
    setLoad(true);
    Api.get(`simulations_metrics/totalizers`, {
      params: { ...filter, unit: filial },
    })
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setLoad(false);
      });
  };

  useEffect(() => {
    getData();
  }, [filter, filial]);

  const handleChange = (e) => {
    setFilter((prev) => ({
      ...prev,
      [e.target.name]: moment(e.target.value).format("YYYY-MM-DD"),
    }));
  };

  const handleWeekChange = (e) => {
    setFilterWeek((prev) => ({
      ...prev,
      [e.target.name]: moment(e.target.value).format("YYYY-MM-DD"),
    }));
  };

  const getDataWeek = () => {
    setLoadWeek(true);
    Api.get(`simulations_metrics/totalizers/week_day`, {
      params: { ...filterWeek, unit: filial },
    })
      .then((res) => {
        setDataWeek(res.data.data);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setLoadWeek(false);
      });
  };

  useEffect(() => {
    getDataWeek();
  }, [filterWeek, filial]);

  return (
    <Row>
      <Col xs={12} lg={6}>
        <div className="p-2 d-flex flex-column">
          <h2>Métricas das Simulações</h2>
          <div className="d-flex gap-1 mb-1 flex-wrap">
            <div className="input-group w-auto flex-0">
              <span className="input-group-text">Data Inicial</span>
              <input
                defaultValue={filter.start_date}
                onChange={handleChange}
                name="start_date"
                className="form-control"
                type="date"
              />
            </div>
            <div className="input-group w-auto flex-0">
              <span className="input-group-text">Data Final</span>
              <input
                defaultValue={filter.end_date}
                onChange={handleChange}
                name="end_date"
                className="form-control"
                type="date"
              />
            </div>
          </div>
          {load && <ProgressBar now={100} animated />}
          <SimulatorMetricsChart data={data} />
        </div>
      </Col>
      <Col xs={12} lg={6}>
        <div className="p-2 d-flex flex-column">
          <h2>Totalizadores das Simulações no Período</h2>
          <SimulatorMetricsTotalizerChart data={data} />
        </div>
      </Col>
      <Col xs={12} lg={6}>
        <div className="p-2 d-flex flex-column">
          <h2>Totalizadores das Simulações por Dia da Semana</h2>
          <div className="d-flex gap-1 mb-1 flex-wrap">
            <div className="input-group w-auto flex-0">
              <span className="input-group-text">Data Inicial</span>
              <input
                defaultValue={filterWeek.start_date}
                onChange={handleWeekChange}
                name="start_date"
                className="form-control"
                type="date"
              />
            </div>
            <div className="input-group w-auto flex-0">
              <span className="input-group-text">Data Final</span>
              <input
                defaultValue={filterWeek.end_date}
                onChange={handleWeekChange}
                name="end_date"
                className="form-control"
                type="date"
              />
            </div>
          </div>
          {loadWeek && <ProgressBar now={100} animated />}
          <SimulatorMetricsWeekDayTotalizerChart data={dataWeek} />
        </div>
      </Col>
    </Row>
  );
};

export default SimulatorMetrics;
