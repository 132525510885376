import React, { useState } from "react";
import { Button, Form, Col, Row } from "react-bootstrap";
import moment from "moment";
import Select from "react-select";
import {
  handleFilialHours,
  handleFilialOptions,
} from "../../../../utils/handleOptionsFilial";
import { useEffect } from "react";
import Api from "../../../../api/api";
import { toast } from "react-toastify";
import { useContext } from "react";
import { FilialContext } from "../../../../context/FilialContext";
import { getTicketType, getTicketTypes } from "../../../../utils/functions";

const selectStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: "2rem",
    borderBottomRightRadius: "2rem",
  }),
  container: (baseStyles, state) => ({
    ...baseStyles,
    alignSelf: "stretch",
    flex: 1,
  }),
  value: (base) => ({ ...base, borderRadius: "2rem" }),
  multiValue: (base) => ({ ...base, borderRadius: "2rem" }),
};

const General = ({ handleNext, handleCancel, sale, setSale }) => {
  const { unities } = useContext(FilialContext);
  const [loadHours, setLoadHours] = useState(false);
  const [hours, setHours] = useState([]);
  const [loadingTickets, setLoadingTickets] = useState(false);
  let [tickets, setTickets] = useState([]);
  const [loadingParkAreas, setLoadingParkAreas] = useState(false);
  const [parkAreas, setParkAreas] = useState([]);

  const sumAllTickets = () => {
    return Number(
      (Number(sale.players) || 0) +
        (Number(sale.minors_responsibles) || 0) +
        (Number(sale.minors) || 0) +
        (Number(sale.dps) || 0) +
        (Number(sale.dps_responsibles) || 0) +
        (Number(sale.courtesy_players) || 0)
    );
  };

  // useEffect(() => {
  //   setSale((prev) => ({ ...prev, ticket_time: "" }));
  // }, [sale.type]);

  const getTickets = () => {
    tickets = [];
    setLoadingTickets(true);
    Api.get("tickets/config", {
      params: {
        unit_id: sale.unit_id,
        date: sale.date,
        areas_amount: sale.park_areas_ids.length,
        type: sale.type,
      },
    })
      .then((res) => setTickets(res.data.list))
      .catch((err) => {
        toast.error(err.message);
        setTickets([]);
      })
      .finally(() => setLoadingTickets(false));
  };

  useEffect(() => {
    if (
      sumAllTickets() > 0 &&
      sale?.unit_id &&
      sale?.park_areas_ids?.length > 0 &&
      moment(sale?.date).isValid() &&
      sale?.type
    )
      getTickets();
  }, [
    sale?.unit_id,
    sale?.park_areas_ids,
    sale?.date,
    sumAllTickets(),
    sale?.type,
  ]);

  const handleChange = (e) => {
    if (e.target.name === "date") {
      if (moment("2100-12-31").isBefore(moment(e.target.value)))
        e.target.value = moment().format("YYYY-MM-DD");
      if (moment("1900-01-01").isAfter(moment(e.target.value)))
        e.target.value = moment().format("YYYY-MM-DD");
    }
    setSale((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleDurationChange = (e) => {
    setSale((prev) => ({
      ...prev,
      ticket_id: e.value.id,
      duration: e.value.duration,
      player_price: e.value.player_price,
      not_player_price: e.value.not_player_price,
      minor_price: e.value.minor_price,
      minor_responsible_price: e.value.minor_responsible_price,
      dp_price: e.value.dp_price,
      dp_responsible_price: e.value.dp_responsible_price,
    }));
  };

  const getHourSuffix = (item) => {
    if (!item || !(item.available_spaces > 0)) return "Esgotado";

    const suffix = `${item.available_spaces} vaga${
      item.available_spaces > 1 ? "s" : ""
    } disponíveis`;

    return suffix;
  };

  const renderHoursOptions = () => {
    if (loadHours) return <option>Carregando...</option>;
    return [
      <option>Selecione o horário de entrada</option>,
      hours.map((item, index) => {
        return (
          <option key={`time_item_${item.time}`} value={item.time}>
            {`${moment(item.time, "HH:mm:SS").format("HH:mm")} ${getHourSuffix(
              item
            )}`}
          </option>
        );
      }),
    ];
  };

  useEffect(() => {
    const getHours = () => {
      setLoadHours(true);
      Api.get(`/calendar_dates_hours/available`, {
        params: {
          date: sale.date,
          players: sale.total_players,
          duration: sale.duration,
          unit_id: sale.unit_id,
          type: sale.type,
          editing: true,
        },
      })
        .then((res) => {
          setHours(res?.data.hours);
        })
        .catch((err) => {
          toast.error(err.message);
          setHours([]);
        })
        .finally(() => {
          setLoadHours(false);
        });
    };

    if (
      !loadingTickets &&
      tickets[0] &&
      sumAllTickets() > 0 &&
      moment(sale.date).isValid() &&
      sale.unit_id &&
      sale.duration > 0 &&
      sale.park_areas_ids.length > 0
    )
      getHours();
  }, [
    sale.date,
    sale.unit_id,
    sale.duration,
    sumAllTickets(),
    sale.park_areas_ids,
    loadingTickets,
    tickets,
  ]);

  const selectedTicket = tickets.find(
    (ticket) => Number(ticket.id) === Number(sale.ticket_id)
  );

  const unit = unities.find((item) => item.id === sale.unit_id);

  useEffect(() => {
    if (unit?.id) getParkAreas();
  }, [sale.unit_id, unit?.id]);

  const getParkAreas = () => {
    setLoadingParkAreas(true);
    Api.get(`/park_areas/${unit.var_name}`, { params: { on_sale: true } })
      .then((res) => {
        setParkAreas(res.data.list);
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingParkAreas(false));
  };

  useEffect(() => {
    if (unit?.var_name !== "SANTO_AMARO")
      setSale((prev) => ({
        ...prev,
        park_areas_ids: parkAreas
          .map((item) => item.id)
          .filter((item) => item > 0),
      }));
  }, [parkAreas]);

  const parkAreasList = parkAreas.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const handleTypeChange = (park_areas_ids) => {
    setSale((prev) => ({
      ...prev,
      park_areas_ids: park_areas_ids.map((item) => item.value),
    }));
  };

  useEffect(() => {
    if (sale.type && sale.duration > 0)
      setSale((prev) => ({
        ...prev,
        exclusive_party_limit:
          sale.type === "exclusive"
            ? selectedTicket?.exclusive_party_limit || 0
            : 0,
      }));
  }, [sale.duration, sale.type]);

  const hasBundle = sale.bundle_id > 0;

  return (
    <div className="d-flex flex-column gap-3 bg-white rounded p-1">
      <Row>
        <Col>
          <label>Unidade</label>
          <Form.Control
            value={unities.find((unit) => unit.id === sale?.unit_id).name}
            name={"unit_id"}
            disabled
          />
        </Col>
        <Col>
          <label>Tipo</label>
          <Form.Select
            value={sale.type}
            onChange={handleChange}
            name="type"
            disabled={hasBundle}
          >
            <option hidden>Selecione o tipo de festa</option>
            {getTicketTypes("parties").map((type) => (
              <option key={`ticket_type_item_${type}`} value={type}>
                {getTicketType(type)}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>
      <Row>
        <Col>
          <label>Data</label>
          <Form.Control
            value={moment(sale.date).format("YYYY-MM-DD")}
            name={"date"}
            type={"date"}
            onChange={handleChange}
            disabled={sale.bundle_id > 0}
          />
        </Col>
      </Row>
      <Row>
        {unities.find((unit) => unit.id === sale.unit_id)?.var_name ===
          "SANTO_AMARO" && (
          <Col>
            <label>Espaços</label>
            <Select
              classNamePrefix="react-select"
              isMulti
              onChange={handleTypeChange}
              value={sale.park_areas_ids?.map((item) => ({
                value: item,
                label: parkAreas.find(
                  (area) => Number(area.id) === Number(item)
                ),
              }))}
              isOptionSelected={(opt) =>
                sale.park_areas_ids?.find(
                  (item) => Number(item) === Number(opt.value)
                )
              }
              options={parkAreasList}
              getOptionLabel={(opt) =>
                parkAreasList.find(
                  (area) => Number(area.value) === Number(opt.value)
                )?.label
              }
              disabled={loadingParkAreas}
              styles={selectStyles}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col>
          <label>Duração</label>
          <Select
            classNamePrefix="react-select"
            value={
              loadingTickets
                ? {
                    label: "Carregando...",
                  }
                : selectedTicket
                ? {
                    value: selectedTicket,
                    label: `${selectedTicket.duration}hr${
                      selectedTicket?.duration > 1 ? "s" : ""
                    } (${selectedTicket.player_price.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })})`,
                  }
                : {
                    label: "Selecione a duração da festa",
                  }
            }
            onChange={handleDurationChange}
            options={tickets.map((ticket) => ({
              label: `${ticket.duration}hr${
                ticket.duration > 1 ? "s" : ""
              } (${ticket.player_price.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })})`,
              value: ticket,
            }))}
            isDisabled={loadingTickets || hasBundle}
            styles={selectStyles}
          />
        </Col>
        {sale.duration && (
          <Col>
            <label>Horário</label>
            <select
              className={`ticket-schedule form-select `}
              name="ticket_time"
              onChange={handleChange}
              disabled={loadHours}
              value={sale.ticket_time}
            >
              {renderHoursOptions()}
            </select>
          </Col>
        )}
      </Row>
      <Row>
        <Col>
          <label>Informações Adicionais</label>
          <Form.Control
            as="textarea"
            value={sale.additional_info}
            name={"additional_info"}
            onChange={handleChange}
          />
        </Col>
      </Row>
      {(handleCancel || handleNext) && (
        <div className="d-flex gap-1 ml-auto">
          {handleCancel && (
            <Button variant="light" onClick={handleCancel}>
              Cancelar
            </Button>
          )}
          {handleNext && <Button onClick={handleNext}>Avançar</Button>}
        </div>
      )}
    </div>
  );
};

export default General;
