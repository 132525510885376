export const handleModuleRoute = (module) => {
  switch (module) {
    case "Departamentos":
      return "/departamentos";
    case "Gerenciar Cupons":
      return "/gerenciar-cupons";
    case "Gerenciador de Leads":
      return "/gerenciar-leads";
    case "Relatório de Vendas":
      return "/csv-page";
    case "Consolidado de Uso":
      return "/vendas";
    case "Gerenciar Calendário":
      return "/calendar-managerment";
    case "Usuários":
      return "/users";
    case "Grupos de Permissões":
      return "/grupos-permissoes";
    case "Nova Festa":
      return "/festas";
    case "Gerenciador de Festas":
      return "/gerenciar-festa";
    case "Calendário":
      return "/calendar";
    case "Ingressos Vendidos":
      return "/ingressos-vendidos";
    case "Nova Venda":
      return "/nova-venda";
    case "Consolidado de Vendas":
      return "/";

    default:
      return null;
  }
};
