import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const SimulatorMetricsChart = ({ data }) => {
  const labels = data.map((item) => moment(item.date).format("DD/MM/YYYY"));

  const getTooltipLabel = (item) => {
    if (item.dataset.label === "Iniciaram")
      return `Iniciaram: ${item.formattedValue}`;
    if (item.dataset.label === "Não terminaram")
      return `Não terminaram: ${item.formattedValue} (${data[
        item.dataIndex
      ].unfinished_rate.toFixed(2)}%)`;
    if (item.dataset.label === "Terminaram")
      return `Terminaram: ${item.formattedValue} (${data[
        item.dataIndex
      ].finished_rate.toFixed(2)}%)`;
    if (item.dataset.label === "Enviaram")
      return `Enviaram: ${item.formattedValue} (${data[
        item.dataIndex
      ].sent_rate.toFixed(2)}%)`;
    if (item.dataset.label === "Querem tirar dúvidas")
      return `Querem tirar dúvidas: ${item.formattedValue} (${data[
        item.dataIndex
      ].sent_doubts_rate.toFixed(2)}%)`;
    if (item.dataset.label === "Enviaram para agendamento")
      return `Enviaram para agendamento: ${item.formattedValue} (${data[
        item.dataIndex
      ].sent_schedule_rate.toFixed(2)}%)`;
    if (item.dataset.label === "Oportunidade perdida")
      return `Oportunidade perdida: ${item.formattedValue} (${data[
        item.dataIndex
      ].finished_didnt_sent_rate.toFixed(2)}%)`;
  };

  return (
    <Line
      options={{
        type: "line",
        plugins: { tooltip: { callbacks: { label: getTooltipLabel } } },
        scales: {
          yAxis: {
            type: "linear",
            ticks: {
              stepSize: 1,
            },
          },
        },
      }}
      data={{
        labels,
        datasets: [
          {
            label: "Iniciaram",
            data: data.map((item) => item.simulations),
            borderColor: "#00f",
          },
          {
            label: "Terminaram",
            data: data.map((item) => item.finished),
            borderColor: "#F47D41",
          },
          {
            label: "Enviaram para agendamento",
            data: data.map((item) => item.sent_schedule),
            borderColor: "#6F3B8C",
          },
          {
            label: "Querem tirar dúvidas",
            data: data.map((item) => item.sent_doubts),
            borderColor: "#FDC028",
          },
          {
            label: "Não terminaram",
            data: data.map((item) => item.unfinished),
            borderColor: "#aaa",
          },
          {
            label: "Oportunidade perdida",
            data: data.map((item) => item.finished_didnt_sent),
            borderColor: "#f00",
          },
        ],
      }}
    />
  );
};

export default SimulatorMetricsChart;
