import React from "react";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import BackgroundSlider from "../../../../components/BackgroundSlider";
import { CenterContainer, Container, TitleWrapper } from "./styles";

const CarouselPreviewModal = ({ open, setOpen, values }) => {
  const [previewType, setPreviewType] = useState("desktop");
  const deskPreview = previewType === "desktop";

  const handleClose = () => {
    setOpen(false);
  };

  const togglePreview = () => {
    setPreviewType((prev) => (prev === "desktop" ? "mobile" : "desktop"));
  };

  return (
    <Modal
      fullscreen
      show={open}
      onHide={handleClose}
      contentClassName="bg-primary"
    >
      <Modal.Title className="align-self-center bg-white w-100 text-center m-0">
        Prévia do Carrossel da Home
      </Modal.Title>
      <div
        className="position-relative align-self-center w-100"
        style={{
          maxWidth: deskPreview ? "100%" : 400,
        }}
      >
        <Container mobile={!deskPreview}>
          <CenterContainer>
            <TitleWrapper mobile={!deskPreview}>
              {values?.home_carousel_title}
            </TitleWrapper>
          </CenterContainer>
        </Container>
        <BackgroundSlider
          id={"ReactBackgroundSlider"}
          images={values?.home_carousel_content?.map((item) =>
            deskPreview ? item.desktop : item.mobile
          )}
          duration={4}
          transition={2}
          style={{ minHeight: 650, zIndex: 2 }}
        />
      </div>
      <Modal.Footer className="bg-white">
        <Button variant="light" onClick={handleClose}>
          Fechar
        </Button>
        <Button onClick={togglePreview}>
          {deskPreview ? "Visualização Mobile" : "Visualização Desktop"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CarouselPreviewModal;
