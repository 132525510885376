import React, { useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import { AuthContext } from "../../context/AuthContext";
import "simplebar-react/dist/simplebar.min.css";

import changeBodyAttribute from "../../utils/changeBodyAttribute";
import { useBootstrapBreakpoint } from "../../utils/functions";
import { routes } from "./routes";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const LeftSidebar = ({ open, setOpen }) => {
  const { allowedModules } = useContext(AuthContext);
  const breakpoint = useBootstrapBreakpoint();
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    changeBodyAttribute("data-sidebar-size", "condensed");
  }, []);

  const toggleSidebarExpanded = () => {
    if (setOpen) setOpen((prev) => !prev);
  };

  const smallBreakpoint = ["xxs", "xs", "sm", "md"].includes(breakpoint);

  useEffect(() => {
    if (smallBreakpoint && open) toggleSidebarExpanded();
  }, [smallBreakpoint]);

  return (
    <div
      className="tlf-left-sidebar test-scroll border-right bg-white"
      style={{
        minWidth: open ? 275 : 70,
        top: 60,
        bottom: 0,
        position: "fixed",
        zIndex: 100
      }}
      id="main-side-menu"
    >
      {!smallBreakpoint && (
        <div
          className={`d-flex align-items-center justify-content-center border-bottom border-3 p-2 text-white`}
          data-toggle="tooltip"
          title={`${open ? "Encolher" : "Expandir"} menu`}
          onClick={toggleSidebarExpanded}
          role="button"
          style={{
            position: "sticky",
            top: 0,
            backgroundColor: "white",
            zIndex: 1,
          }}
        >
          {!smallBreakpoint && open && (
            <span
              className="fw-bold text-primary text-center color-gray fs-5"
              style={{ flex: 1 }}
            >
              {"Menu"}
            </span>
          )}
          {open ? (
            <AiOutlineDoubleLeft className="fw-bold text-black" />
          ) : (
            <AiOutlineDoubleRight className="fw-bold text-black" />
          )}
        </div>
      )}

      {routes
        .filter(
          (item) => !allowedModules[0] || allowedModules.includes(item.title)
        )
        .map((item) => {
          const currentRoute = item.route === pathname;

          const tooltip = (
            <Tooltip id={`tooltip-${item.route}`}>{item.title}</Tooltip>
          );

          return (
            <React.Fragment key={`route_item_${item.route}`}>
              {open ? (
                <Link className={open ? "" : "m-auto"} to={item.route}>
                  <item.icon
                    size={currentRoute ? "1.5rem" : "1rem"}
                    className={
                      currentRoute
                        ? "bg-secondary rounded color-gray p-1 cursor-pointer"
                        : "color-gray cursor-pointer"
                    }
                  />
                  <span
                    className={`d-none ${open ? "d-lg-block color-gray" : ""} ${
                      currentRoute ? "fw-bold" : ""
                    }`}
                  >
                    {item.title}
                  </span>
                </Link>
              ) : (
                <OverlayTrigger placement="right" overlay={tooltip}>
                  <Link className={open ? "" : "m-auto"} to={item.route}>
                    <item.icon
                      size={currentRoute ? "1.5rem" : "1rem"}
                      className={
                        currentRoute
                          ? "bg-secondary rounded color-gray p-1 cursor-pointer"
                          : "color-gray cursor-pointer"
                      }
                    />
                    <span
                      className={`d-none ${
                        open ? "d-lg-block color-gray" : ""
                      } ${currentRoute ? "fw-bold" : ""}`}
                    >
                      {item.title}
                    </span>
                  </Link>
                </OverlayTrigger>
              )}
            </React.Fragment>
          );
        })}
    </div>
  );
};
export default LeftSidebar;