import React, { useState, useEffect, useContext } from "react";
import { Form, InputGroup } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import Api from "../../../api/api";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./EditDoubtModal.css";
import { FilialContext } from "../../../context/FilialContext";

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ align: [] }],
    ["link"],
    ["clean"],
  ],
};

const EditDoubtModal = ({ item, getList, doubtCategory, open, setOpen }) => {
  const { selectedUnitId } = useContext(FilialContext);
  const [unities, setUnities] = useState([]);
  const [loadUnities, setLoadUnities] = useState(false);
  const [load, setLoad] = useState(false);
  const [values, setValues] = useState({});

  useEffect(() => {
    if (open)
      setValues(
        item
          ? { ...item }
          : {
              question: "",
              answer: "",
              doubt_category_id: doubtCategory.id,
              unit_id: selectedUnitId,
            }
      );
  }, [open, item, doubtCategory]);

  const openModal = () => {
    setOpen({
      question: "",
      answer: "",
      doubt_category_id: doubtCategory.id,
      unit_id: selectedUnitId,
    });
  };

  const closeModal = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const handleSwitchChange = (e) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.checked }));
  };

  const save = () => {
    setLoad(true);
    if (values.id)
      return Api.put(`doubts/${values.id}`, values)
        .then((res) => {
          if (getList) getList();
          closeModal();
        })
        .catch((err) => toast.error(err.message))
        .finally(() => setLoad(false));
    Api.post(`doubts`, values)
      .then((res) => {
        if (getList) getList();
        closeModal();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoad(false));
  };

  const remove = () => {
    if (window.confirm("Deseja realmente remover esta dúvida?")) {
      setLoad(true);
      Api.delete(`doubts/${values.id}`)
        .then((res) => {
          if (getList) getList();
          closeModal();
        })
        .catch((err) => toast.error(err.message))
        .finally(() => setLoad(false));
    }
  };

  const getUnities = () => {
    setLoadUnities(true);
    Api.get("unities")
      .then((res) => setUnities(res.data.data))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadUnities(false));
  };

  useEffect(() => {
    getUnities();
  }, []);

  return (
    <>
      <Button className="w-100" onClick={openModal}>
        Adicionar Dúvida
      </Button>
      <Modal show={open} onHide={load ? null : closeModal} size="lg">
        <Modal.Title className="align-self-center">{`${
          values.id
            ? "Editar"
            : !values.id && item?.question
            ? "Duplicar"
            : "Adicionar"
        } Dúvida`}</Modal.Title>
        <Modal.Body className="d-flex flex-column gap-1">
          <div className="d-flex gap-1 flex-wrap flex-lg-nowrap">
            <InputGroup>
              <InputGroup.Text>Unidade</InputGroup.Text>
              <Form.Control
                value={values.unit_id}
                name={"unit_id"}
                onChange={handleChange}
                as="select"
                disabled={loadUnities}
              >
                {unities.map((item) => (
                  <option key={`unit_${item.id}`} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Form.Control>
            </InputGroup>
            <InputGroup>
              <InputGroup.Text>Mostrar na página principal</InputGroup.Text>
              <div className="form-control">
                <Form.Check
                  type="checkbox"
                  label="Ativo"
                  checked={values.main_page}
                  name="main_page"
                  onChange={handleSwitchChange}
                />
              </div>
            </InputGroup>
          </div>
          <InputGroup>
            <InputGroup.Text>Título</InputGroup.Text>
            <Form.Control
              value={values.question}
              name={"question"}
              onChange={handleChange}
            />
          </InputGroup>
          <InputGroup className="d-flex flex-column">
            <InputGroup.Text
              className="rounded-0 rounded-top"
              style={{ marginBottom: -2 }}
            >
              Descrição
            </InputGroup.Text>
            <ReactQuill
              theme="snow"
              className="ml-0"
              value={values.answer}
              onChange={(value) =>
                handleChange({ target: { name: "answer", value } })
              }
              modules={modules}
            />
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={load} variant="light" onClick={closeModal}>
            Cancelar
          </Button>
          {values.id && (
            <Button onClick={remove} disabled={load} variant="danger">
              Remover
            </Button>
          )}
          <Button onClick={save} disabled={load} variant="primary">
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditDoubtModal;
