import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import ProgressBar from "react-bootstrap/ProgressBar";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "./Row";
import AddModal from "./AddModal";
import Api from "../../../api/api";

const List = ({ department }) => {
  const [list, setList] = useState([]);
  const [load, setLoad] = useState(false);
  const [loadRemove, setLoadRemove] = useState(null);

  if (typeof window !== "undefined") {
    injectStyle();
  }

  const getList = () => {
    setLoad(true);
    Api.get(
      `departments_modules/department/modules?${new URLSearchParams({
        department_id: department?.id,
      })}`
    )
      .then((res) => {
        setLoad(false);
        setList(res.data.data);
      })
      .catch((err) => {
        setLoad(false);
        toast.error(err.message);
      });
  };

  useEffect(() => {
    if (department?.id) getList();
  }, [department]);

  const handleRemove = (item) => {
    if (
      window.confirm("Deseja realmente remover este módulo do departamento?")
    ) {
      setLoadRemove(item.id);
      Api.delete(
        `${process.env.REACT_APP_BASE_URL}departments_modules/${item.id}`
      )
        .then((res) => {
          setLoadRemove(null);
          toast.success("Módulo removido do departamento com sucesso!");
          getList();
        })
        .catch((err) => {
          setLoadRemove(null);
          toast.error(err.message);
        });
    }
  };

  return (
    <div className="d-flex flex-column gap-1">
      <AddModal department={department} getDepartmentModules={getList} />
      {load && <ProgressBar animated now={100} className="mb-1" />}
      {!list[0] && !load && (
        <p style={{ textAlign: "center" }}>
          Não há módulos adicionados ao departamento.
        </p>
      )}
      <div style={{ maxHeight: "300px", overflow: "auto" }}>
        {list.map((item, index) => (
          <Row
            key={"department_module_" + item.id}
            item={item}
            handleRemove={handleRemove}
            loadRemove={loadRemove}
          />
        ))}
      </div>
    </div>
  );
};

export default List;
