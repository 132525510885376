import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import Api from "../../../api/api";
import EditModal from "./EditModal";
import List from "./List";

const AttractionsModal = ({ open, setOpen, data, getParentList }) => {
  const [load, setLoad] = useState(false);
  const [list, setList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleNewItem = () => {
    const defaultData = {
      title: "",
      image: "",
      description: "",
      display: false,
      park_area_id: data.id,
    };

    setSelectedItem(defaultData);
  };

  const closeModal = () => {
    setOpen(null);
  };

  const getList = () => {
    setLoad(true);
    Api.get("attractions", { params: { park_area_id: data.id } })
      .then((res) => {
        setList(res.data.list);
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoad(false));
  };

  const handleListChange = (changedList) => {
    setLoad(true);

    const orderList = changedList.map((item, index) => ({
      id: item.id,
      item_order: index + 1,
    }));

    Api.post("attractions/reorder", orderList)
      .then((res) => {
        toast.success("Ordem das atrações atualizada com sucesso!");
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoad(false));
  };

  useEffect(() => {
    if (data.id && open) getList();
  }, [data.id, open]);

  return (
    <>
      <Modal show={open} onHide={load ? null : closeModal} size={"md"}>
        <EditModal
          open={selectedItem ? true : false}
          setOpen={setSelectedItem}
          item={selectedItem}
          getList={() => {
            getList();
            getParentList();
          }}
        />
        <Modal.Title className="align-self-center">Atrações</Modal.Title>
        <Modal.Body className="d-flex flex-column gap-1">
          <List
            list={list}
            onChange={handleListChange}
            setSelectedItem={setSelectedItem}
            load={load}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={load} variant="light" onClick={closeModal}>
            Fechar
          </Button>
          <Button disabled={load} onClick={handleNewItem}>
            Nova Atração
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AttractionsModal;
