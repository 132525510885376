export function dateFormated(date) {
  if (date === '' || date === null || date === undefined) {
    return '';
  }

  const dataInput = date;
  const data = new Date(dataInput);

  if (isNaN(data.getTime())) {
    return '';
  }

  const dataFormatada = data.toLocaleDateString('pt-BR', { timeZone: 'UTC' });
  return dataFormatada;
}
