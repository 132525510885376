import { maskBr } from "js-brasil";
import moment from "moment";
import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Col, ProgressBar, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import Api from "../../api/api";
import PageTitle from "../PageTitle";
import Table from "../Table";
import { FilialContext } from "../../context/FilialContext";
import renamePaymentType from "../../utils/paymentType";
import { HiCurrencyDollar, HiEye } from "react-icons/hi2";
import ViewSaleModal from "../../pages/TicketsSales2/ViewSaleModal";
import RefundModal from "./RefundModal";
import {
  cleanNumberString,
  getPartyStatus,
  getTransactionStatus,
} from "../../utils/functions";

const SalesList = ({
  transactionStartDate,
  transactionEndDate,
  ticketStartDate,
  ticketEndDate,
  orderStartDate,
  orderEndDate,
  allUnitiesData,
}) => {
  const { selectedUnitId } = useContext(FilialContext);

  const [filter, setFilter] = useState({
    limit: 10,
    page: 0,
    transaction_date_start: transactionStartDate || "",
    transaction_date_end: transactionEndDate || "",
    ticket_date_start: ticketStartDate || "",
    ticket_date_end: ticketEndDate || "",
    order_date_start: orderStartDate || "",
    order_date_end: orderEndDate || "",
  });

  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [loadingList, setLoadingList] = useState(false);

  const [openViewSale, setOpenViewSale] = useState(false);
  const [openRefundOrder, setOpenRefundOrder] = useState(false);

  const getList = () => {
    setLoadingList(true);
    Api.get(`/orders`, {
      params: { ...filter, unit_id: allUnitiesData ? null : selectedUnitId },
    })
      .then((res) => {
        setList(res.data.list);
        setTotal(res.data.total);
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingList(false));
  };

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      transaction_date_start: transactionStartDate || "",
      transaction_date_end: transactionEndDate || "",
      ticket_date_start: ticketStartDate || "",
      ticket_date_end: ticketEndDate || "",
      order_date_start: orderStartDate || "",
      order_date_end: orderEndDate || "",
    }));
  }, [
    transactionStartDate,
    transactionEndDate,
    ticketStartDate,
    ticketEndDate,
    orderStartDate,
    orderEndDate,
  ]);

  useEffect(() => {
    if (selectedUnitId || allUnitiesData) getList();
  }, [selectedUnitId, filter, allUnitiesData]);

  const columns = [
    {
      label: "ID",
      field: "code",
      renderValue: (item) => item.code.split("-")[0],
    },
    {
      label: "Status",
      field: "status",
      renderValue: (item) =>
        !item.type || item.type === "ticket"
          ? getTransactionStatus(item.status)
          : getPartyStatus(item.order_ticket_status),
    },
    {
      label: "Status Termos",
      field: "terms_signed",
      renderValue: (item) =>
        [true, "true"].includes(item.terms_signed)
          ? `Assinados (${item.terms_manually_signed ? "manual" : "digital"})`
          : [false, "false"].includes(item.terms_signed)
          ? "Pendentes"
          : "-",
    },
    {
      label: "Data Uso",
      field: "date",
      renderValue: (item) =>
        item.date ? moment(item.date).format("DD/MM/YYYY") : "-",
    },
    {
      label: "Início",
      field: "ticket_time",
      renderValue: (item) =>
        item.ticket_time
          ? moment(item.ticket_time, "HH:mm:ss").format("HH:mm")
          : "-",
    },
    {
      label: "Fim",
      field: "end_time",
      renderValue: (item) =>
        item.end_time ? moment(item.end_time, "HH:mm:ss").format("HH:mm") : "-",
    },
    {
      label: "E-mail",
      field: "email",
      renderValue: (item) => item.email || "-",
    },
    {
      label: "Documento",
      field: "cpf",
      renderValue: (item) =>
        item.cpf
          ? [true, "true"].includes(item.brazilian_doc)
            ? cleanNumberString(item.cpf)?.length > 11
              ? maskBr.cnpj(item.cpf)
              : maskBr.cpf(item.cpf)
            : item.cpf
          : "Indefinido",
    },
    {
      label: "Data Compra",
      field: "transaction_created_at",
      renderValue: (item) =>
        moment(item.transaction_created_at).format("DD/MM/YYYY HH:mm:ss"),
    },
    // { label: "Vendedor", field: "seller_email" },
    {
      label: "Valor Total",
      field: "total_value",
      renderValue: (item) =>
        item.total_value.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
    },
    {
      label: "Origem",
      field: "online_sale",
      renderValue: (item) => (item.online_sale ? "Online" : "Local"),
    },
    // {
    //   label: "Método Pagamento",
    //   field: "payment_method",
    //   renderValue: (item) => renamePaymentType(item.payment_method),
    // },
  ];

  if (allUnitiesData)
    columns.unshift({
      label: "Unidade",
      field: "unit.name",
      renderValue: (item) => item.unit,
    });

  const renderRows = (field, item) => {
    if (!item) return null;
    const data = columns.find((col) => col.field === field);
    if (data?.renderValue) return data.renderValue(item);
    return item[field];
  };

  const handleLimitChange = (newLimit) => {
    setFilter((prev) => ({ ...prev, limit: newLimit }));
  };

  const handlePageChange = (newPage) => {
    setFilter((prev) => ({ ...prev, page: newPage }));
  };

  return (
    <>
      {openViewSale && (
        <ViewSaleModal
          open={!!openViewSale}
          setOpen={setOpenViewSale}
          code={openViewSale}
        />
      )}
      {openRefundOrder && (
        <RefundModal
          open={!!openRefundOrder}
          setOpen={setOpenRefundOrder}
          order_id={openRefundOrder}
          onSuccess={getList}
        />
      )}

      {loadingList && <ProgressBar className="mb-1" animated now={100} />}
      <Table
        emptyListLabel={
          loadingList ? null : "Nenhuma venda com base no filtro."
        }
        limit={filter.limit}
        limitOptions={[10, 30, 50]}
        page={filter.page}
        paginated
        total={total}
        columns={columns}
        renderRow={renderRows}
        renderRowItem
        data={list}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
        containerClassName={"p-1 overflow-hidden d-flex flex-column gap-1"}
        actions={(item) => (
          <div className="d-flex align-items-center gap-1">
            <Button
              data-toggle="tooltip"
              title="Visualizar"
              variant="outline-primary"
              className="rounded-circle"
              size="sm"
              onClick={() => setOpenViewSale(item.code.split("-")[0])}
            >
              <HiEye size={"100%"} />
            </Button>
            {["authorized", "used"].includes(item.status) && (
              <Button
                data-toggle="tooltip"
                title="Estornar"
                variant="outline-danger"
                className="rounded-circle"
                size="sm"
                onClick={() => setOpenRefundOrder(item.id)}
              >
                <HiCurrencyDollar size={"100%"} />
              </Button>
            )}
          </div>
        )}
      />
    </>
  );
};

export default SalesList;
