import React, { useState } from "react";
import { useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Button, ListGroup, ProgressBar } from "react-bootstrap";
import { useBootstrapBreakpoint } from "../../utils/functions";
import Row from "./Row";

const List = ({
  list = [],
  onChange = console.log,
  setSelectedItem = () => {},
  load,
}) => {
  const breakpoint = useBootstrapBreakpoint();

  const [itemList, setItemList] = useState([]);

  useEffect(() => {
    const defaultList = JSON.parse(JSON.stringify(list));
    setItemList(defaultList);
  }, [list]);

  const handleDrop = (droppedItem) => {
    if (!droppedItem.destination) return;
    var updatedList = [...itemList];
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    setItemList(updatedList);
    onChange(updatedList);
  };

  const smallBreakpoint = ["xxs", "xs"].includes(breakpoint);

  return (
    <div className="d-flex flex-column gap-1">
      Arraste um item para alterar a ordem.
      {load && <ProgressBar animated now={100} />}
      {!itemList[0] && !load && <span>Não há álbuns cadastrados.</span>}
      <DragDropContext onDragEnd={handleDrop}>
        <Droppable
          droppableId="list-container"
          direction={smallBreakpoint ? "vertical" : "horizontal"}
        >
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={
                "d-flex gap-1" +
                (smallBreakpoint ? " flex-column" : " flex-wrap")
              }
            >
              {itemList.map((item, index) => (
                <Draggable
                  key={"attraction_" + item.id}
                  draggableId={"attraction_" + item.id}
                  index={index}
                >
                  {(provided) => (
                    <ListGroup
                      className="rounded-box"
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                    >
                      <Row item={item} setSelectedItem={setSelectedItem} />
                    </ListGroup>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default List;
