import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import Api from "../../../api/api";
import { toast } from "react-toastify";
import CSVReader from "react-csv-reader";
import validator from "validator";
import { useContext } from "react";
import { FilialContext } from "../../../context/FilialContext";

export const PointsTransfer = ({ open, setOpen }) => {
  const { selectedUnitId } = useContext(FilialContext);

  const [single, setSingle] = useState(true);
  const [values, setValues] = useState({ email: "", points: 0 });
  const [list, setList] = useState([]);
  const [loadingTransfer, setLoadingTransfer] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleTransfer = () => {
    setLoadingTransfer(true);
    if (single)
      return Api.post(`/user/points/transfer`, {
        ...values,
        unit_id: selectedUnitId,
      })
        .then(() => {
          setValues({ email: "", points: 0 });
          toast.success("Pontos enviados com sucesso!");
        })
        .catch((err) => toast.error(err.message))
        .finally(() => {
          setLoadingTransfer(false);
        });
    Api.post(`/user/points/mass/transfer`, { list, unit_id: selectedUnitId })
      .then(() => {
        setList([]);
        toast.success("Pontos enviados com sucesso!");
      })
      .catch((err) => toast.error(err.message))
      .finally(() => {
        setLoadingTransfer(false);
      });
  };

  const handleChange = (e) => {
    if (e.target.name === "email" && e.target.value)
      e.target.value = e.target.value.toLowerCase();

    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const toggleSingle = () => {
    setSingle((prev) => !prev);
  };

  const handleCSVChange = (data, fileInfo) => {
    if (!data || !data[0]) return toast.error("Arquivo está vazio!");
    const fields = data[0];

    if (
      !["email", "e-mail"].includes(fields[0].toLowerCase().trim()) ||
      !["points", "pontos"].includes(fields[1].toLowerCase().trim())
    )
      return toast.error("Arquivo está mal formatado!");

    const rows = data.filter((item, i) => i > 0);

    if (!rows[0]) return toast.error("Arquivo está mal formatado!");

    const list = [];

    rows.forEach((row) => {
      list.push({ email: row[0], points: row[1] });
    });

    list.forEach((item) => {
      if (
        !item.email ||
        !validator.isEmail(item.email) ||
        Number.isNaN(Number(item.points))
      )
        return toast.error("Arquivo está mal formatado!");
    });

    setList(list);
    toast.success(`Lista com ${list.length} e-mails carregada com sucesso!`);
  };

  return (
    <div>
      <h5 className="align-self-center">{`Envio de Pontos`}</h5>
      <Modal.Body>
        {single ? (
          <div className="d-flex flex-column gap-2">
            <Form.Group>
              <Form.Label>E-mail do cliente:</Form.Label>
              <Form.Control
                placeholder="Digite o e-mail"
                onChange={handleChange}
                name="email"
                type="email"
                disabled={loadingTransfer}
                value={values.email}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Quantidade de pontos:</Form.Label>
              <Form.Control
                placeholder="Quantidade de pontos"
                onChange={handleChange}
                name="points"
                type="number"
                disabled={loadingTransfer}
                value={values.points}
                min={0}
              />
            </Form.Group>
            <Button
              disabled={loadingTransfer}
              onClick={toggleSingle}
              variant="light"
              className="w-100 text-primary"
            >
              Enviar pontos para vários clientes
            </Button>
          </div>
        ) : (
          <div className="d-flex flex-column gap-2">
            <Form.Group>
              <Form.Label>CSV com e-mail e pontos</Form.Label>
              <CSVReader
                cssClass="form-control"
                onFileLoaded={handleCSVChange}
              />
            </Form.Group>
            <span className="text-muted">
              A planilha deve ter 2 colunas, a primeira sendo o e-mail do
              cliente (cabeçalho e-mail ou email), e a segunda sendo a
              quantidade de pontos para enviar ao cliente (cabeçalho pontos ou
              points).
            </span>
            {list && list[0] && (
              <span className="text-success">
                Lista carregada com sucesso, pressione o botão Confirmar para
                enviar os pontos aos clientes.
              </span>
            )}
            <Button
              disabled={loadingTransfer}
              onClick={toggleSingle}
              variant="light"
              className="w-100 mt-2 text-primary"
            >
              Enviar pontos para apenas um cliente
            </Button>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={loadingTransfer}
          onClick={handleClose}
          variant="light"
        >
          Fechar
        </Button>
        <Button
          disabled={loadingTransfer}
          onClick={handleTransfer}
          variant="primary"
        >
          Confirmar
        </Button>
      </Modal.Footer>
    </div>
  );
};

export default PointsTransfer;
