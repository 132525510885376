import { useEffect, useState } from "react";
import { Button, Form, InputGroup, Modal, ProgressBar } from "react-bootstrap";
import Api from "../../../api/api";
import { toast } from "react-toastify";
import Select from "react-select";
import { ticketTimes } from "../EditModal";
import ImageUpload from "../../../components/ImageUpload";

const initValues = {
  status: 1,
  title: "",
  description: "",
  time: "",
  banner: "",
};

const EditModal = ({ open, setOpen, itemID, promotionID, onSuccess }) => {
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);

  useEffect(() => {
    const getData = () => {
      setLoading(true);
      Api.get(`promotions_cart_suggestion_times/${itemID}`)
        .then((res) => setValues({ ...res.data }))
        .catch((err) => toast.error(err.message))
        .finally(() => setLoading(false));
    };

    if (open && itemID === 0 && promotionID)
      setValues({ ...initValues, promotion_id: promotionID });
    if (open && itemID !== 0) getData();
  }, [open, itemID, promotionID]);

  const handleClose = () => {
    if (setOpen) setOpen(null);
  };

  const handleSave = () => {
    setLoadingSave(true);
    if (itemID !== 0)
      return Api.put(`/promotions_cart_suggestion_times/${itemID}`, values)
        .then(() => {
          if (onSuccess) onSuccess();
          toast.success("Recomendação atualizada com sucesso!");
        })
        .catch((err) => toast.error(err.message))
        .finally(() => setLoadingSave(false));
    Api.post(`/promotions_cart_suggestion_times`, values)
      .then(() => {
        if (onSuccess) onSuccess();
        toast.success("Recomendação criada com sucesso!");
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingSave(false));
  };

  const handleChange = (e) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSelectChange = (e, field) => {
    setValues((prev) => ({ ...prev, [field]: e.value }));
  };

  const handleStatusChange = (e) => {
    setValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked ? 1 : 2,
    }));
  };

  const handleRemove = () => {
    if (
      window.confirm("Deseja realmente remover este horário das recomendações?")
    ) {
      setLoadingRemove(true);
      Api.delete(`/promotions_cart_suggestion_times/${values?.id}`)
        .then(() => {
          if (onSuccess) onSuccess();
          toast.success("Recomendação removida com sucesso!");
        })
        .catch((err) => toast.error(err.message))
        .finally(() => setLoadingRemove(false));
    }
  };

  const isLoading = loadingSave || loadingRemove;

  return (
    <Modal show={open}>
      <Modal.Header closeButton onHide={isLoading ? null : handleClose}>
        <Modal.Title>{`${
          values.id ? "Editar" : "Nova"
        } Recomendação`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <ProgressBar animated now={100} />
        ) : (
          <div className="d-flex flex-column gap-2">
            <InputGroup>
              <InputGroup.Text>Status</InputGroup.Text>
              <div className="form-control">
                <Form.Check
                  type="checkbox"
                  label="Ativo"
                  checked={values?.status === 1}
                  name="status"
                  onChange={handleStatusChange}
                />
              </div>
            </InputGroup>
            <InputGroup>
              <InputGroup.Text>Título</InputGroup.Text>
              <Form.Control
                value={values?.title}
                name={"title"}
                onChange={handleChange}
              />
            </InputGroup>
            <InputGroup>
              <InputGroup.Text>Descrição</InputGroup.Text>
              <Form.Control
                value={values?.description}
                name={"description"}
                onChange={handleChange}
                as="textarea"
              />
            </InputGroup>
            <InputGroup>
              <InputGroup.Text>Horário Recomendado</InputGroup.Text>
              <Select
                classNamePrefix="react-select"
                value={ticketTimes.find((time) => time.value === values?.time)}
                onChange={(val) => handleSelectChange(val, "time")}
                options={ticketTimes}
                className="form-control"
              />
            </InputGroup>
            <InputGroup className="flex-column align-items-center justify-content-center">
              <InputGroup.Text className="rounded-0 rounded-top w-100 justify-content-center">
                Capa
              </InputGroup.Text>
              <div
                className={
                  values.banner
                    ? "border border-primary rounded-0 rounded-bottom m-0 w-100 h-100 pt-1 overflow-hidden"
                    : "border border-primary rounded-0 rounded-bottom m-0 w-100 h-100 overflow-hidden"
                }
              >
                <ImageUpload
                  value={values.banner}
                  onChange={handleChange}
                  name="banner"
                  folderName={"promotions"}
                  aspectRatio={2}
                  resizeOptions={{ maxWidth: 200, maxHeight: 100 }}
                />
              </div>
            </InputGroup>
            <div className="d-flex flex-column text-muted">
              <span className="ml-auto text-right me-1">{`Dimensões ideais: 200px/100px`}</span>
              <span className="ml-auto text-right me-1">{`Tamanho ideal: 200Kb`}</span>
              <span className="ml-auto text-right me-1">{`Imagens com dimensões e tamanho maiores serão reduzidas até o tamanho ideal`}</span>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose} disabled={isLoading}>
          Fechar
        </Button>
        {values?.id && (
          <Button variant="danger" onClick={handleRemove} disabled={isLoading}>
            Remover
          </Button>
        )}
        <Button onClick={handleSave} disabled={isLoading}>
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditModal;
