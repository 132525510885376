import React, { useState } from "react";
import { useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Button, ProgressBar } from "react-bootstrap";
import Row from "./Row";

const List = ({
  list = [],
  onChange = console.log,
  setSelectedItem = () => {},
  load,
}) => {
  const [itemList, setItemList] = useState([]);

  useEffect(() => {
    const defaultList = JSON.parse(JSON.stringify(list));
    setItemList(defaultList);
  }, [list]);

  const handleDrop = (droppedItem) => {
    if (!droppedItem.destination) return;
    var updatedList = [...itemList];
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    setItemList(updatedList);
    onChange(updatedList);
  };

  return (
    <>
      Arraste um item para alterar a ordem.
      {load && <ProgressBar animated now={100} />}
      {!itemList[0] && !load && (
        <span>Não há itens cadastrados para o carrossel.</span>
      )}
      <DragDropContext onDragEnd={handleDrop}>
        <Droppable droppableId="list-container">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="d-flex flex-column gap-1"
            >
              {itemList.map((item, index) => (
                <Draggable
                  key={"carousel_item_" + index}
                  draggableId={"carousel_item_" + index}
                  index={index}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                    >
                      <Row
                        item={item}
                        setSelectedItem={() => setSelectedItem(index)}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default List;
