import React from "react";
import { Button, Image, ListGroup } from "react-bootstrap";
import { AiFillEye } from "react-icons/ai";
import { useBootstrapBreakpoint } from "../../utils/functions";

const ListRow = ({ item, setSelectedItem = console.log }) => {
  const breakpoint = useBootstrapBreakpoint();

  const handleClick = () => {
    setSelectedItem(item);
  };

  const smallBreakpoint = ["xxs", "xs"].includes(breakpoint);

  return (
    <ListGroup.Item
      variant="primary"
      className="d-flex flex-column p-1 gap-1 justify-content-between align-items-center user-select-none"
    >
      <div>
        <Image
          src={item.cover_url}
          width={smallBreakpoint ? "100%" : 225}
          height={150}
          style={{ objectFit: "cover" }}
          className="rounded-box"
        />
      </div>
      <span className="fw-bold">{item.name}</span>
      <div className="d-flex gap-1 align-items-center justify-content-end align-self-stretch">
        {Number(item.status) === 1 && (
          <AiFillEye data-toggle="tooltip" title="Mostrando no site" />
        )}
        <span>
          <span data-toggle="tooltip" title="Itens mostrados no site">
            {item.pictures_count}
          </span>
          /
          <span data-toggle="tooltip" title="Total de itens no álbum">
            {item.site_pictures_count}
          </span>
        </span>
        <Button variant="outline-primary" onClick={handleClick}>
          Editar
        </Button>
      </div>
    </ListGroup.Item>
  );
};

export default ListRow;
