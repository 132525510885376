import React, { useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { BiSearchAlt } from "react-icons/bi";
import { toast } from "react-toastify";
import Api from "../../../api/api";
import ConfirmModal from "./ConfirmModal";

const ConfirmUsage = () => {
  const [code, setCode] = useState("");
  const [load, setLoad] = useState(false);
  const [order, setOrder] = useState(null);

  const handleChange = (e) => {
    if (e.target.value) {
      e.target.value = e.target.value.toLowerCase();
      e.target.value = e.target.value.replaceAll(" ", "");
    }

    setCode(e.target.value);
  };

  const getOrder = () => {
    setLoad(true);
    Api.get(`orders/code/${code}`)
      .then((res) => {
        setOrder(res.data.data);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setLoad(false);
      });
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) getOrder();
  };

  const handleModalClose = () => {
    setOrder(null);
    setCode("");
  };

  const handleSetTicket = (data) => {
    setOrder((prev) => ({ ...prev, ticket: data }));
  };

  return (
    <>
      <h4>Confirmar uso de ticket</h4>
      <div className="input-group mb-1">
        <input
          className="form-control"
          placeholder="Pesquisar ticket (ID ou QRCode)"
          value={code}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />
        <span className="input-group-text cursor-pointer" onClick={getOrder}>
          <BiSearchAlt />
        </span>
      </div>
      {load && <ProgressBar animated now={100} />}
      <ConfirmModal
        open={order ? true : false}
        order={order || {}}
        handleClose={handleModalClose}
        setTicket={handleSetTicket}
      />
    </>
  );
};

export default ConfirmUsage;
