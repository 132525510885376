import { Button, ProgressBar } from "react-bootstrap";
import Table from "../../components/Table";
import moment from "moment";
import { BiSearchAlt } from "react-icons/bi";
import { useState } from "react";
import { HiOutlineInformationCircle } from "react-icons/hi2";

const List = ({ list, total, setSelectedItem, load, filter, setFilter }) => {
  const [search, setSearch] = useState("");

  const columns = [
    {
      field: "code",
      label: "Código",
      renderValue: (item) => (
        <div className="d-flex flex-row align-items-center gap-2">
          <span className="fs-5">{item.code}</span>
          {item.description ? (
            <HiOutlineInformationCircle
              size={"1.5rem"}
              data-toggle="tooltip"
              title={item.description}
            />
          ) : null}
        </div>
      ),
    },
    {
      field: "created_at",
      label: "Criado em",
      renderValue: (item) => moment(item.created_at).format("DD/MM/YYYY HH:mm"),
    },
    {
      field: "created_by_email",
      label: "Criado por",
      renderValue: (item) => item.created_by_email || "Indefinido",
    },
    {
      field: "start_date",
      label: "Data Inicial",
      renderValue: (item) => moment(item.start_date).format("DD/MM/YYYY"),
    },
    {
      field: "end_date",
      label: "Data Final",
      renderValue: (item) => moment(item.end_date).format("DD/MM/YYYY"),
    },
    {
      field: "unities",
      label: "Unidades",
      renderValue: (item) => getUnities(item.unities),
    },
    {
      field: "status",
      label: "Status",
      renderValue: (item) => getStatus(item.status),
    },
    {
      field: "usage_count",
      label: "Usado",
      renderValue: (item) =>
        `${item.usage_count} vez${
          item.usage_count > 1 || Number(item.usage_count) === 0 ? "es" : ""
        }`,
    },
  ];

  const getUnities = (unities) => {
    return unities
      .map((un) => {
        if (un === "BRASILIA") return "Brasília";
        if (un === "SANTO_AMARO") return "Santo Amaro";
        return "Tatuapé";
      })
      .join(", ");
  };

  const getStatus = (status) => {
    let itemStatus = null;

    switch (status) {
      case 1:
        itemStatus = "ATIVO";
        break;
      case 2:
        itemStatus = "INATIVO";
        break;
      case 3:
        itemStatus = "REMOVIDO";
        break;
      case 4:
        itemStatus = "ESGOTADO";
        break;
      case 5:
        itemStatus = "EXPIRADO";
        break;

      default:
        break;
    }

    return itemStatus;
  };

  const handleNewCoupon = () => {
    const defaultCouponData = {
      code: "",
      unities: [],
      ticket_times: [],
      usage_times: [
        "00:00",
        "01:00",
        "02:00",
        "03:00",
        "04:00",
        "05:00",
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
      ],
      ticket_week_days: [0, 1, 2, 3, 4, 5, 6, 7],
      usage_week_days: [0, 1, 2, 3, 4, 5, 6, 7],
      tickets: true,
      products: false,
      usage_limit: 10000,
      start_date: moment().format("YYYY-MM-DD"),
      end_date: moment().add(30, "days").format("YYYY-MM-DD"),
      discount_type: "PERCENTAGE",
      discount_value: 10,
      discount_max_value: 50,
      cpf_lock: false,
      ticket_usage_days_limit_after_end_date: 30,
      ticket_min_value: 0,
      cpf_lock_reset_days: 0,
      days_before_ticket_date: 0,
      ticket_start_date: "",
      ticket_end_date: "",
      days_after_usage_date: 0,
    };

    setSelectedItem(defaultCouponData);
  };

  const handleSearch = () => {
    setFilter((prev) => ({ ...prev, search }));
  };

  const onSearchKeyDown = (e, x) => {
    const enter = 13;
    if (e.keyCode === enter) handleSearch();
  };

  const getCouponStatusOptions = () => {
    const statuses = [1, 2, 3, 4, 5];

    return statuses.map((status) => (
      <option key={`status_${status}`} value={status}>
        {getStatus(status)}S
      </option>
    ));
  };

  const handleFilterChange = (e) => {
    setFilter((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const renderRows = (field, item) => {
    if (!item) return null;
    const data = columns.find((col) => col.field === field);
    if (data?.renderValue) return data.renderValue(item);
    return item[field];
  };

  return (
    <>
      <div className="d-flex gap-1 mb-1 flex-wrap align-items-center justify-content-center justify-content-sm-start">
        <span className="fs-5">Lista de Cupons:</span>
        <div className="input-group w-auto flex-0">
          <input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="form-control"
            placeholder="Pesquisar Cupom"
            onKeyDown={onSearchKeyDown}
          />
          <span
            className="input-group-text cursor-pointer"
            onClick={handleSearch}
          >
            <BiSearchAlt />
          </span>
        </div>
        <div className="input-group w-auto flex-0">
          <select
            value={filter.status}
            onChange={(e) =>
              setFilter((prev) => ({ ...prev, status: e.target.value }))
            }
            className="form-control"
            placeholder="Filtrar Status"
          >
            <option value={""}>Filtrar Status</option>
            {getCouponStatusOptions()}
          </select>
        </div>
        <Button style={{ marginLeft: "auto" }} onClick={handleNewCoupon}>
          Novo Cupom
        </Button>
      </div>
      <div className="d-flex gap-1 mb-1 flex-wrap align-items-center justify-content-center justify-content-sm-start">
        <span className="fs-5">Filtrar estatísticas:</span>
        <div className="input-group w-auto flex-0">
          <span className="input-group-text">Início</span>
          <input
            value={
              filter.start_date
                ? moment(filter.start_date).format("YYYY-MM-DD")
                : null
            }
            onChange={handleFilterChange}
            name="start_date"
            type="date"
            className="form-control"
            placeholder="Data inicial"
          />
        </div>
        <div className="input-group w-auto flex-0">
          <span className="input-group-text">Fim</span>
          <input
            value={
              filter.end_date
                ? moment(filter.end_date).format("YYYY-MM-DD")
                : null
            }
            onChange={handleFilterChange}
            name="end_date"
            type="date"
            className="form-control"
            placeholder="Data final"
          />
        </div>
      </div>
      {load && <ProgressBar animated now={100} className="mb-1" />}
      <Table
        data={list}
        columns={columns}
        actions={(item) => (
          <Button onClick={() => setSelectedItem(item)}>Editar</Button>
        )}
        renderRow={renderRows}
        renderRowItem
        page={filter.page}
        limit={filter.limit}
        total={total}
        paginated
        handlePageChange={(page) => setFilter({ ...filter, page })}
        handleLimitChange={(limit) => setFilter({ ...filter, limit })}
        limitOptions={[10, 20, 30]}
        emptyListLabel={"Não há cupons."}
      />
    </>
  );
};

export default List;
