import React, { useState, useContext, useEffect } from "react";
import PageTitle from "../../components/PageTitle";
import Container from "react-bootstrap/Container";
import { Button, Spinner, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import { AddItemModal } from "./AddItemModal";
import { ManageBuffet } from "./ManageBuffet";
import { FilialContext } from "../../context/FilialContext";
import { AddBuffet } from "./AddBuffet";
import { HiPencilSquare } from "react-icons/hi2";
import Api from "../../api/api";
import "./styles.scss";

export const Buffets = () => {
  const [buffetModalOpen, setBuffetModalOpen] = useState(false);
  const [buffetData, setBuffetData] = useState([]);
  const [selectedBuffetItem, setSelectedBuffetItem] = useState(null);
  const [itemModalOpen, setItemModalOpen] = useState(false);
  const [editedModalOpen, setEditedModalOpen] = useState(false);
  const [dataModalManager, setDataModalManager] = useState(0);
  const [loading, setLoading] = useState(false);

  const { selectedUnitId } = useContext(FilialContext);

  const handleUpdateList = () => {
    setLoading(true);
    Api.get(`/buffets?unit_id=${selectedUnitId}`)
      .then((res) => {
        setBuffetData(res.data.list);
      })
      .catch((e) => {
        toast.error(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleUpdateList();
  }, [selectedUnitId]);

  const handleItemClick = (item) => {
    setSelectedBuffetItem(item);
    setItemModalOpen(true);
  };

  const handleManageBuffet = async (item) => {
    await setDataModalManager(item);
    setEditedModalOpen(true);
  };

  return (
    <>
      <PageTitle>
        <h2 className="text-center">Gerenciar Buffets</h2>
        {loading && <Spinner />}
        <Button variant="primary" onClick={() => setBuffetModalOpen(true)}>
          Adicionar Buffet
        </Button>
      </PageTitle>
      <Container fluid>
        <div className="d-flex align-items-center justify-content-between"></div>
        <Card>
          <Card.Body>
            <Card.Title>
              <h5>Lista de Buffets:</h5>
            </Card.Title>
            <table className="list__container-table mt-4">
              <thead>
                <tr>
                  <th>Nome do Buffet</th>
                  <th>Valor por Pessoa</th>
                  <th>Gerenciar Itens</th>
                  <th>Menu</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan="5">
                    <hr />
                  </td>
                </tr>
                {buffetData.length === 0 ? (
                  <tr>
                    <td colSpan="4" className="text-center">
                      Nenhum Buffet encontrado
                    </td>
                  </tr>
                ) : (
                  buffetData.map((item, index) => (
                    <>
                      <tr key={index}>
                        <td>{item.name}</td>
                        <td>R$ {item.value}</td>
                        <td>
                          <Button
                            variant="secondary"
                            onClick={() => handleItemClick(item)}
                          >
                            Gerenciar itens
                          </Button>
                        </td>
                        <td>
                          <div className="d-flex align-items-center gap-1">
                            <Button
                              data-toggle="tooltip"
                              title="Editar"
                              variant="outline-primary"
                              className="rounded-circle"
                              size="sm"
                              onClick={() => {
                                handleManageBuffet(item);
                              }}
                            >
                              <HiPencilSquare size={"100%"} />
                            </Button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="5">
                          <hr />
                        </td>
                      </tr>
                    </>
                  ))
                )}
              </tbody>
            </table>
          </Card.Body>
        </Card>
        <AddBuffet
          buffetModalOpen={buffetModalOpen}
          setBuffetModalOpen={setBuffetModalOpen}
          handleUpdateList={handleUpdateList}
        />
        {itemModalOpen && (
          <AddItemModal
            itemModalOpen={itemModalOpen}
            setItemModalOpen={setItemModalOpen}
            selectedBuffetItemId={selectedBuffetItem.id}
          />
        )}
        {editedModalOpen && (
          <ManageBuffet
            editedModalOpen={editedModalOpen}
            setEditedModalOpen={setEditedModalOpen}
            dataModalManager={dataModalManager}
            handleUpdateList={handleUpdateList}
          />
        )}
      </Container>
    </>
  );
};
