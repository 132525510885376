import React from "react";
import { Button, ListGroup } from "react-bootstrap";
import { AiFillEye } from "react-icons/ai";

const Row = ({ item, setSelectedItem, reordering }) => {
  const handleClick = () => {
    setSelectedItem(item);
  };

  const handleDuplicate = () => {
    setSelectedItem({ ...item, id: null });
  };

  return (
    <ListGroup.Item
      variant="primary"
      className="d-flex p-1 justify-content-center justify-content-sm-between align-items-center user-select-none flex-wrap flex-sm-nowrap"
    >
      <span>{item.question}</span>
      {!reordering && (
        <div className="d-flex align-items-center justify-content-center gap-1 align-self-center">
          {item.main_page && (
            <AiFillEye
              data-toggle="tooltip"
              title="Mostrando na página principal"
              color="#6f3b8c"
            />
          )}
          <Button variant="outline-primary" onClick={handleDuplicate}>
            Duplicar
          </Button>
          <Button variant="secondary" onClick={handleClick}>
            Editar
          </Button>
        </div>
      )}
    </ListGroup.Item>
  );
};

export default Row;
