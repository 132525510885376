import { useRef, useState } from "react";
import { Table as RBTable, Pagination, Form } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";

const Table = ({
  data,
  columns,
  actions,
  renderRow,
  page,
  limit,
  limitOptions,
  total,
  paginated,
  handlePageChange,
  handleLimitChange,
  emptyListLabel,
  renderRowItem,
  containerClassName,
}) => {
  if (!data) throw new Error("undefined data!");

  const tableRef = useRef();

  const getColumns = () => {
    return columns.map((col) => {
      const itemID = uuidv4();
      const isSortable = col.sortable === undefined || col.sortable;
      const isSorted = sortConfig.key === col.field;
      let arrowIcon = null;

      if (
        col.field === "ticket_time" ||
        col.field === "end_time" ||
        col.field === "type" ||
        col.field === "order_ticket_status_name" ||
        col.field === "total_value"
      ) {
        arrowIcon = isSorted ? (
          sortConfig.direction === "asc" ? (
            <AiFillCaretUp />
          ) : (
            <AiFillCaretDown />
          )
        ) : (
          <AiFillCaretDown />
        );
      }

      return (
        <th
          key={`table_head_col_${col.field}_${itemID}`}
          onClick={() => isSortable && handleColumnClick(col.field)}
          style={{ cursor: isSortable ? "pointer" : "default" }}
        >
          <div className={`d-flex`}>
            <span>{col.label}</span>
            {arrowIcon && <span>{arrowIcon}</span>}
          </div>
        </th>
      );
    });
  };

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "asc",
  });

  const handleColumnClick = (key) => {
    const allowedColumns = [
      "ticket_time",
      "end_time",
      "type",
      "order_ticket_status_name",
      "total_value",
    ];

    if (allowedColumns.includes(key)) {
      const direction =
        key === sortConfig.key && sortConfig.direction === "asc"
          ? "desc"
          : "asc";
      setSortConfig({ key, direction });
    }
  };
  const getSortedData = () => {
    if (!sortConfig.key) {
      return data;
    }

    return data.slice().sort((a, b) => {
      const getValue = (item) => {
        const value = item[sortConfig.key];
        return typeof value === "string" ? value.trim() : value;
      };

      const aValue = getValue(a);
      const bValue = getValue(b);

      if (!isNaN(parseInt(aValue)) && !isNaN(parseInt(bValue))) {
        return sortConfig.direction === "asc"
          ? parseInt(aValue) - parseInt(bValue)
          : parseInt(bValue) - parseInt(aValue);
      } else {
        const result =
          sortConfig.direction === "asc"
            ? aValue.localeCompare(bValue)
            : bValue.localeCompare(aValue);
        return result;
      }
    });
  };

  const getRows = () => {
    const sortedData = getSortedData();

    return sortedData.map((row) => {
      const items = [];

      columns.forEach((col) => {
        items.push(
          <td
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
            key={`row_td_${col.field} `}
          >
            {renderRow
              ? renderRow(col.field, renderRowItem ? row : row[col.field])
              : row[col.field]}
          </td>
        );
      });

      if (actions) items.push(<td key={`row_td_actions`}>{actions(row)}</td>);

      const itemID = uuidv4();

      return <tr key={`table_body_col_${itemID}`}>{items}</tr>;
    });
  };

  const getPagesCount = () => {
    let count = Math.floor(Number(Number(total) / Number(limit)));
    return count + 1;
  };

  const getPaginationItems = () => {
    let items = [];

    for (let index = page - 2; index < page + 3; index++) {
      if (index < 0 || index >= getPagesCount()) continue;
      const pageLabel = index + 1;
      items.push(
        <Pagination.Item
          onClick={() => handlePageChange(index)}
          active={index === page}
        >
          {pageLabel}
        </Pagination.Item>
      );
    }

    return items;
  };

  const renderLimitOptions = () => {
    return limitOptions.map((limit) => (
      <option key={`limit_item_${limit}`} value={limit}>
        {limit}
      </option>
    ));
  };

  return (
    <div className={containerClassName || ""}>
      <RBTable responsive ref={tableRef} className="border-top-0">
        <thead>
          <tr>
            {getColumns()}
            {actions && <th>Menu</th>}
          </tr>
        </thead>
        <tbody>{getRows()}</tbody>
      </RBTable>
      {emptyListLabel && (!data || !data[0]) && (
        <p style={{ textAlign: "center" }}>{emptyListLabel}</p>
      )}
      {paginated && (
        <Pagination className="d-flex align-items-center mt-2 px-2">
          {getPaginationItems()}
          {limitOptions && (
            <Form.Select
              value={limit}
              onChange={(e) => handleLimitChange(e.target.value)}
              className="w-auto ml-1"
            >
              {renderLimitOptions()}
            </Form.Select>
          )}
        </Pagination>
      )}
    </div>
  );
};

export default Table;
