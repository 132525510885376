import { HiPencilSquare } from "react-icons/hi2";
import renamePaymentType from "../../../../utils/paymentType";
import AddTransactionModal from "./AddTransactionModal";
import moment from "moment";
import { getTransactionStatus } from "../../../../utils/functions";

const { useState } = require("react");
const { Button } = require("react-bootstrap");

const SaleTransactions = ({ sale, onSuccess }) => {
  const [openAddTransactionModal, setOpenAddTransactionModal] = useState(false);

  const renderSaleTransaction = (transaction) => {
    if (!transaction || !transaction.id) return <></>;

    const getStatus = () => {
      if (transaction.payment_status === "authorized")
        return `PAGO (${moment(transaction.authorized_at).format(
          "DD/MM/YYYY HH:mm:SS"
        )})`;
      return getTransactionStatus(transaction.payment_status);
    };

    return (
      <div className="border border-primary rounded-box p-1 px-2 d-flex align-items-center justify-content-between">
        <div className="d-flex flex-column">
          <span className="fw-bold">{`#${transaction.id} ${renamePaymentType(
            transaction.payment_method
          )}`}</span>
          <span>{`Criado em: ${moment(transaction.created_at).format(
            "DD/MM/YYYY HH:mm:SS"
          )}`}</span>
        </div>
        <div className="d-flex align-items-center gap-1">
          <div className="d-flex flex-column text-end">
            <span className="fw-bold">
              {transaction.value.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })}
            </span>
            {transaction.authorized_at && <span>{getStatus()}</span>}
          </div>
          <Button
            variant="outline-secondary"
            size="sm"
            className="rounded-circle"
            onClick={() => handleOpenAddTransactionModal(transaction.id)}
            data-toggle="tooltip"
            title="Editar"
          >
            <HiPencilSquare />
          </Button>
        </div>
      </div>
    );
  };

  const renderSaleTransactions = () => {
    if (!sale || !sale.transactions || !sale.transactions[0])
      return <span className="text-muted">Nenhuma transação na venda.</span>;

    return (
      <div className="d-flex flex-column gap-1">
        {sale.transactions.map((transaction) =>
          renderSaleTransaction(transaction)
        )}
      </div>
    );
  };

  const handleOpenAddTransactionModal = (transaction_id) => {
    setOpenAddTransactionModal(transaction_id || true);
  };

  const onTransactionAdded = () => {
    setOpenAddTransactionModal(false);
    if (onSuccess) onSuccess();
  };

  return (
    <>
      {openAddTransactionModal && (
        <AddTransactionModal
          open={!!openAddTransactionModal}
          setOpen={setOpenAddTransactionModal}
          unit_id={sale?.unit_id}
          onSuccess={onTransactionAdded}
          order_id={sale?.id}
          transaction_id={
            openAddTransactionModal > 0 ? openAddTransactionModal : null
          }
        />
      )}
      <span className="fs-5 text-primary fw-bold">Pagamentos</span>
      {renderSaleTransactions()}
      <Button
        className="fw-bold"
        variant={"outline-primary"}
        onClick={handleOpenAddTransactionModal}
      >
        Lançar pagamento
      </Button>
    </>
  );
};

export default SaleTransactions;
