import React, { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle";
import List from "./List";
import Edit from "./Edit";
import { Container, Spinner, Col, Button, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import Api from "../../api/api";
import { HiPencilSquare } from "react-icons/hi2";
import { ManageGroup } from "./ManageGroup";

const PermissionsGroups = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [list, setList] = useState([]);
  const [loadList, setLoadList] = useState(false);
  const [loadRemove, setLoadRemove] = useState(null);
  const [shouldCreateGroup, setShouldCreateGroup] = useState(false);
  const [editedModalOpen, setEditedModalOpen] = useState(false);

  if (typeof window !== "undefined") {
    injectStyle();
  }

  const getList = () => {
    setLoadList(true);
    Api.get(`permission_groups`)
      .then((res) => {
        setLoadList(false);
        setList(res.data.data);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setLoadList(false);
      });
  };

  useEffect(() => {
    document.getElementById("topbar")?.scrollIntoView();
    getList();
  }, []);

  const handleNewItem = () => {
    setSelectedItem({ name: "" });
    setShouldCreateGroup(true);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setShouldCreateGroup(true);
  };

  const handleManageGroup = async (item) => {
    setSelectedItem(item);
    setEditedModalOpen(true);
  };

  return (
    <>
      <PageTitle>
        <h2>Grupos de Permissões</h2>
        {loadList && <Spinner />}
        <Button onClick={handleNewItem}>Adicionar Grupo</Button>
      </PageTitle>
      <Container fluid className="d-flex justify-content-center flex-column">
        <div className="list__container-table">
          <Card>
            <Card.Body>
              <Card.Title>
                <h5 className="fw-bold">Lista de Grupos de Permissões:</h5>
              </Card.Title>
              <table className="list__container-table mt-4">
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th>Gerenciar Permissões</th>
                    <th>Menu</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan="5">
                      <hr />
                    </td>
                  </tr>
                  {list.map((item, index) => (
                    <>
                      <tr key={index}>
                        <td>{item.name}</td>
                        <td>
                          <Button
                            variant="secondary"
                            onClick={() => handleItemClick(item)}
                          >
                            Gerenciar itens
                          </Button>
                        </td>
                        <td>
                          <div className="d-flex align-items-center gap-1">
                            <Button
                              data-toggle="tooltip"
                              title="Editar"
                              variant="outline-primary"
                              className="rounded-circle"
                              size="sm"
                              onClick={() => {
                                handleManageGroup(item);
                              }}
                            >
                              <HiPencilSquare size={"100%"} />
                            </Button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="5">
                          <hr />
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </Card.Body>
          </Card>
          {editedModalOpen && (
            <ManageGroup
              editedModalOpen={editedModalOpen}
              setEditedModalOpen={setEditedModalOpen}
              dataModalManager={selectedItem}
              setLoadRemove={setLoadRemove}
              getList={getList}
            />
          )}
        </div>
        {selectedItem && (
          <Edit
            item={selectedItem}
            setItem={setSelectedItem}
            getList={getList}
            shouldCreateGroup={shouldCreateGroup}
            setShouldCreateGroup={setShouldCreateGroup}
          />
        )}
      </Container>
    </>
  );
};

export default PermissionsGroups;
