import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import Api from "../../api/api";

const RefundModal = ({ open, setOpen, id, onSuccess }) => {
  const [loadingRefund, setLoadingRefund] = useState(false);

  const handleRemove = () => {
    setLoadingRefund(true);
    Api.post(`transactions/void/${id}`)
      .then(() => {
        toast.success("Transação estornada com sucesso!");
        setOpen(false);
        if (onSuccess) onSuccess();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingRefund(false));
  };

  const handleClose = () => {
    if (loadingRefund) return;
    setOpen(false);
  };

  return (
    <Modal show={open} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Confirmação</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span className="fs-3 text-danger">
          Deseja realmente estornar esta transação?
        </span>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose} disabled={loadingRefund}>
          Cancelar
        </Button>
        {id > 0 && (
          <Button
            variant="danger"
            onClick={handleRemove}
            disabled={loadingRefund}
          >
            Confirmar
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default RefundModal;
