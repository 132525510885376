import React, { useContext, useEffect, useState } from "react";
import { Button, Form, InputGroup, Card } from "react-bootstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import Api from "../../../../api/api";
import SaleCoupon from "../../../../components/SaleCoupon";
import { FilialContext } from "../../../../context/FilialContext";
import { customerTypesList } from "../../../../utils/functions";
import {
  getPaymentMethods,
  paymentMethods,
} from "../../../../utils/paymentType";

const selectStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: "2rem",
    borderBottomRightRadius: "2rem",
  }),
  container: (baseStyles, state) => ({
    ...baseStyles,
    alignSelf: "stretch",
    flex: 1,
  }),
};

const Payment = ({
  handleNext,
  handleCancel,
  sale,
  setSale,
  nextLabel = "Avançar",
  nextDisabled,
  yesNo,
  user,
}) => {
  const { filial } = useContext(FilialContext);
  const [loadPrice, setLoadPrice] = useState(false);
  const [couponData, setCouponData] = useState(null);

  const participants =
    Number(sale.players || 0) +
    Number(sale.not_players || 0) +
    Number(sale.minors || 0) +
    Number(sale.minors_responsibles || 0) +
    Number(sale.dps || 0) +
    Number(sale.dps_responsibles || 0) +
    Number(sale.courtesy_players || 0) +
    Number(sale.quantity_birthdays || 0) +
    Number(sale.courtesy_not_players || 0);

  console.log("Pagamento", sale);
  const productsValue = sale.products
    .filter((item) => Number(item.amount) > 0)
    .map((item) =>
      item.charge_type === "participants"
        ? Number(item.amount) * Number(item.value) * participants
        : Number(item.amount) * Number(item.value)
    )
    .reduce((acum, value) => (acum += Number(value)), 0);

  const showBirthdayDiscount = sale.players && sale.quantity_birthdays > 0;

  const ticketsValue =
    Number(sale.player_price || 0) * Number(sale.players || 0) +
    Number(sale.not_player_price || 0) * Number(sale.not_players || 0) +
    Number(sale.minor_price || 0) * Number(sale.minors || 0) +
    Number(sale.dp_price || 0) * Number(sale.dps || 0) +
    Number(sale.dp_responsible_price || 0) *
      Number(sale.dps_responsibles || 0) +
    Number(sale.player_price || 0) * Number(sale.quantity_birthdays || 0);

  const birthdayDiscount = showBirthdayDiscount
    ? Number(sale.player_price || 0) * Number(sale.quantity_birthdays || 0)
    : 0;

  const ticketWithProductsValue =
    ticketsValue + productsValue - birthdayDiscount;
  const discountValue = Number(
    (
      ticketWithProductsValue *
      Number(Number(sale.coupon_discount_rate || 0) / 100)
    ).toFixed(2)
  );
  const totalValue = ticketWithProductsValue - discountValue;

  const handleChange = (e, index) => {
    if (e.target.type === "number" && Number(e.target.value) < 0)
      e.target.value = 0;

    if (e.target.name === "coupon_discount_rate" && e.target.value > 100)
      e.target.value = 100;

    if (e.target.name === "coupon_discount_value") {
      if (e.target.value < 0) e.target.value = 0;
      if (e.target.value > ticketWithProductsValue)
        e.target.value = ticketWithProductsValue;

      const discountRate = Number(
        (Number(e.target.value || 0) * 100) / ticketWithProductsValue
      );

      return setSale((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
        coupon_discount_rate: discountRate,
      }));
    }

    if (e.target.name === "total_breakdown") {
      return setSale((prev) => ({
        ...prev,
        total_breakdown: [
          {
            ...prev.total_breakdown[0],
            value:
              index === 0
                ? Number(e.target.value)
                : totalValue - Number(e.target.value),
          },
          {
            ...prev.total_breakdown[1],
            value:
              index === 1
                ? Number(e.target.value)
                : totalValue - Number(e.target.value),
          },
        ],
      }));
    }

    setSale((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSelectChange = (item, field, index) => {
    setSale((prev) => ({
      ...prev,
      [field]: index >= 0 ? item.value[index] : item.value,
      total_breakdown:
        field === "payment_method" &&
        item.value === "installments" &&
        !prev.total_breakdown
          ? [
              { name: "", value: 0 },
              { name: "", value: 0 },
            ]
          : field === "total_breakdown"
          ? [
              {
                name: index === 0 ? item.value : prev.total_breakdown[0]?.name,
                value: prev.total_breakdown[0]?.value || 0,
              },
              {
                name: index === 1 ? item.value : prev.total_breakdown[1]?.name,
                value: prev.total_breakdown[1]?.value || 0,
              },
            ]
          : null,
    }));
  };

  useEffect(() => {
    setSale((prev) => ({
      ...prev,
      total_value: totalValue,
      coupon_discount_value: discountValue,
      tickets_value: ticketsValue,
      products_value: productsValue,
      payment_method:
        totalValue > 0
          ? prev.payment_method === "courtesy"
            ? ""
            : prev.payment_method
          : "courtesy",
    }));
  }, [totalValue, ticketsValue, setSale, discountValue, productsValue]);

  useEffect(() => {
    handleChange({
      target: {
        name: "coupon_discount_value",
        value: couponData ? couponData.discount : 0,
      },
    });
    handleChange({
      target: {
        name: "coupon_id",
        value: couponData ? couponData.coupon_id : null,
      },
    });
  }, [couponData]);

  useEffect(() => {
    const playersForPricing = () => {
      return Number(sale.players) + Number(sale.minors) + Number(sale.dps);
    };

    const setTicketValue = (value) => {
      setSale((prev) => ({
        ...prev,
        player_price: value.player_price,
        not_player_price: value.not_player_price,
        minor_price: value.minor_price,
        minor_responsible_price: value.minor_responsible_price,
        dp_price: value.dp_price,
        dp_responsible_price: value.dp_responsible_price,
        additional_hour_price: value.additional_hour_price,
        ticket_id: value.id,
      }));
    };

    const getTicketPrice = () => {
      setLoadPrice(true);
      Api.post(`/tickets/config`, {
        unit_id: sale.unit_id,
        date: sale.date,
        duration: sale.duration,
        type: sale.type,
        players: playersForPricing(),
        areas_amount: sale.park_areas_ids.length,
      })
        .then((res) => {
          setTicketValue(res.data.data);
        })
        .catch((err) => {
          toast.error(err.message);
        })
        .finally(() => {
          setLoadPrice(false);
        });
    };

    if (participants > 0) getTicketPrice();
  }, [
    sale.type,
    sale.unit_id,
    sale.date,
    sale.duration,
    participants,
    setLoadPrice,
    setSale,
  ]);

  const handleStatusChange = (item) => {
    if (!item.value)
      return setSale((prev) => ({
        ...prev,
        status: 1,
      }));
    setSale((prev) => ({
      ...prev,
      status: 2,
    }));
  };

  return (
    <Card>
      <Card.Header className="border-0 bg-white">
        <span className="fs-5 fw-bold">
          {"Configurações de Participantes:"}
        </span>
      </Card.Header>
      <Card.Body className="d-flex flex-column gap-3">
        <div className="d-flex justify-content-between">
          <label>Valor dos Ingressos:</label>
          <span>
            {ticketsValue.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })}
          </span>
        </div>
        <hr className="m-0 p-0" />
        <div className="d-flex justify-content-between">
          <label>Valor dos Produtos:</label>
          <span>
            {productsValue.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })}
          </span>
        </div>
        {showBirthdayDiscount && (
          <>
            <hr className="m-0 p-0" />
            <div className="d-flex justify-content-between">
              <label>Desconto Aniversariante</label>
              <span>
                -
                {birthdayDiscount.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
              </span>
            </div>
          </>
        )}
        <hr className="m-0 p-0" />
        <div className="d-flex justify-content-between">
          <label>Subtotal:</label>
          <span>
            {ticketWithProductsValue.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })}
          </span>
        </div>
        <hr className="m-0 p-0" />
        {sale.coupon_discount_rate > 0 && (
          <>
            <div className="d-flex justify-content-between">
              <label>Valor do Desconto:</label>
              <span>
                {discountValue.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
              </span>
            </div>
            <hr className="m-0 p-0" />
          </>
        )}
        <div className="d-flex justify-content-between">
          <label>Valor Total:</label>
          <span>
            {totalValue.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })}
          </span>
        </div>
        <div className="row">
          <label>Forma Pagamento:</label>
          <Select
            classNamePrefix="react-select"
            value={
              [
                { label: "Selecione a forma de pagamento", value: "" },
                ...getPaymentMethods("local", "ticket"),
                {
                  label: "Diversas formas de pagamento",
                  value: "installments",
                },
              ].find((item) => item.value === sale.payment_method) || {
                label: "Selecione a forma de pagamento",
                value: "",
              }
            }
            onChange={(item) => handleSelectChange(item, "payment_method")}
            options={[
              { label: "Selecione a forma de pagamento", value: "" },
              ...getPaymentMethods("local", "ticket"),
              { label: "Diversas formas de pagamento", value: "installments" },
            ]}
            styles={selectStyles}
          />
        </div>
        <div className="row">
          <div className="col">
            <label>Desconto porcentagem:</label>
            <Form.Control
              value={sale.coupon_discount_rate}
              name={"coupon_discount_rate"}
              type={"number"}
              step={1}
              min={0}
              max={100}
              onChange={handleChange}
            />
          </div>
          <div className="col">
            <label>Desconto valor fixo:</label>
            <Form.Control
              value={sale.coupon_discount_value}
              name={"coupon_discount_value"}
              type={"number"}
              min={0}
              onChange={handleChange}
            />
          </div>
        </div>
        <SaleCoupon
          data={{
            ticket_value: ticketsValue,
            products_value: productsValue,
            date: sale.date,
            ticket_time: sale.ticket_time,
            filial,
            cpf: user?.cpf,
            total_value: totalValue,
            ticket_id: sale.ticket_id,
          }}
          onChange={setCouponData}
        />
        {sale.payment_method === "installments" && (
          <>
            <div className="row">
              <div className="col">
                <label>Forma Pagamento 1:</label>
                <Select
                  classNamePrefix="react-select"
                  value={
                    sale.total_breakdown && sale.total_breakdown.length > 0
                      ? getPaymentMethods("local", "ticket").find(
                          (item) => item.value === sale.total_breakdown[0]?.name
                        )
                      : { label: "Selecione a forma de pagamento", value: "" }
                  }
                  onChange={(item) =>
                    handleSelectChange(item, "total_breakdown", 0)
                  }
                  options={getPaymentMethods("local", "ticket").filter(
                    (item) => !item.label.endsWith("Online")
                  )}
                  styles={selectStyles}
                />
              </div>
              {(sale.total_breakdown || [])[0]?.name && (
                <div className="row">
                  <label>Valor 1</label>
                  <Form.Control
                    value={sale.total_breakdown[0]?.value}
                    name={"total_breakdown"}
                    type={"number"}
                    onChange={(e) => handleChange(e, 0)}
                    disabled={loadPrice}
                  />
                </div>
              )}
            </div>
            <div className="row">
              <div className="col">
                <label>Forma Pagamento 2:</label>
                <Select
                  classNamePrefix="react-select"
                  value={
                    sale.total_breakdown
                      ? getPaymentMethods("local", "ticket").find(
                          (item) => item.value === sale.total_breakdown[1]?.name
                        )
                      : { label: "Selecione a forma de pagamento", value: "" }
                  }
                  onChange={(item) =>
                    handleSelectChange(item, "total_breakdown", 1)
                  }
                  options={getPaymentMethods("local", "ticket")}
                  styles={selectStyles}
                />
              </div>
              {(sale.total_breakdown || [])[1]?.name && (
                <div className="row">
                  <label>Valor 2:</label>
                  <Form.Control
                    value={sale.total_breakdown[1]?.value}
                    name={"total_breakdown"}
                    type={"number"}
                    onChange={(e) => handleChange(e, 1)}
                    disabled={loadPrice}
                  />
                </div>
              )}
            </div>
          </>
        )}
        {sale.ticket_id > 0 && (
          <div className="row">
            <label>O ticket será usado agora?</label>
            <Select
              classNamePrefix="react-select"
              value={yesNo.find(
                (item) => item.value === (Number(sale.status) === 2)
              )}
              onChange={(val) => handleStatusChange(val)}
              options={yesNo}
              styles={selectStyles}
            />
          </div>
        )}

        <div className="row">
          <label>Venda para:</label>
          <div className="col">
            <Form.Select
              value={sale.customer_type}
              onChange={handleChange}
              name={"customer_type"}
            >
              {customerTypesList.map((ct) => (
                <option value={ct.value} key={`customer_type_item_${ct.value}`}>
                  {ct.label}
                </option>
              ))}
            </Form.Select>
          </div>
        </div>

        {(handleCancel || handleNext) && (
          <div className="d-flex gap-1 ml-auto">
            {handleCancel && (
              <Button variant="light" onClick={handleCancel}>
                Cancelar
              </Button>
            )}
            {handleNext && (
              <Button
                className="ml-auto"
                onClick={handleNext}
                disabled={loadPrice || nextDisabled}
              >
                {nextLabel}
              </Button>
            )}
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default Payment;
