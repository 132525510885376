import { Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { ContainerFixed, CardContainer } from "./style";
import { injectStyle } from "react-toastify/dist/inject-style";
import { toast } from "react-toastify";
import LogoLight from "../../assets/images/pophausLogo.png";
import { useEffect, useState } from "react";
import Api from "../../api/api";

export default function NewPassword() {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [isInvalidPass, setIsInvalidPass] = useState(false);
  const { id } = useParams();

  function handlePass() {
    if (password !== confirmPass || password === "") {
      return setIsInvalidPass(true);
    }
    Api.put(`/dashboard/user/${id}/update_password`, { pass: password })
      .then((res) => {
        navigate("/");
      })
      .catch((e) => toast.error("Error"));
  }
  if (typeof window !== "undefined") {
    injectStyle();
  }

  useEffect(() => {
    document.getElementById("topbar")?.scrollIntoView();
  }, []);

  return (
    <ContainerFixed>
      <CardContainer>
        <div className="logo-center">
          <img src={LogoLight} alt="" height="100" />
          <p className="text-warning font-weight-bold">
            Você entrou com uma senha temporária! <br />
          </p>
          <p className="text-warning font-weight-bold">
            Insira uma nova senha para continuar no Dashboard.
          </p>
          <form>
            <div className="mb-2 field">
              <label className="form-label">Nova Senha: </label>
              <input
                className="form-control"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="field">
              <label className="form-label">Confirmar Nova Senha: </label>
              <input
                className="form-control"
                type="password"
                value={confirmPass}
                onChange={(e) => setConfirmPass(e.target.value)}
              />
              {isInvalidPass && (
                <p className="text-danger font-weight-bold">
                  Confirmação de senha inválida
                </p>
              )}
            </div>
            <div className="actions text-center d-grid mt-3">
              <Button onClick={() => handlePass()}> Confirmar </Button>
            </div>
            <p className="text-primary font-weight-bold mb-0 mt-1">
              Confirmando você será logado no Dashboard com a nova senha.
            </p>
          </form>
        </div>
      </CardContainer>
    </ContainerFixed>
  );
}
