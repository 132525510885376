import { useContext, useEffect, useState } from "react";
import Api from "../../../../api/api";
import { FilialContext } from "../../../../context/FilialContext";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  FormControl,
  FormGroup,
  FormLabel,
  ProgressBar,
  Row,
} from "react-bootstrap";
import {
  ticketTypesList,
  getTicketTypes,
  getTicketType,
} from "../../../../utils/functions";
import moment from "moment";

const Bundles = ({ onChange, value, onBundlesList, onDateChange }) => {
  const { selectedUnitId } = useContext(FilialContext);
  const [bundles, setBundles] = useState([]);
  const [loadingBundles, setLoadingBundles] = useState(false);
  const [selectedTicketType, setSelectedTicketType] = useState(null);
  const [selectedDate, setSelectedDate] = useState("");

  useEffect(() => {
    if (onChange) onChange(selectedTicketType || {});
    setSelectedDate("");
  }, [selectedTicketType]);

  useEffect(() => {
    if (onDateChange) onDateChange(selectedDate);
  }, [selectedDate]);

  useEffect(() => {
    const getBundles = () => {
      setLoadingBundles(true);
      Api.get(`bundles`, {
        params: {
          unit_id: selectedUnitId,
          ticket_type: selectedTicketType,
          date: selectedDate,
        },
      })
        .then((res) => {
          setBundles(res.data.list);
          if (onBundlesList) onBundlesList(res.data.list);
        })
        .catch((err) => toast.error(err.message))
        .finally(() => setLoadingBundles(false));
    };

    if (selectedUnitId && selectedTicketType && moment(selectedDate).isValid())
      getBundles();
  }, [selectedUnitId, selectedTicketType, selectedDate]);

  const renderBundle = (bundle) => {
    const renderProducts = () => {
      if (!bundle.products) return <></>;

      return (
        <span>
          {`${bundle.products
            .map((prod) => `${prod.name} x${prod.min_amount}`)
            .join(", ")}`}
        </span>
      );
    };

    const ticketsValue =
      Number(bundle.players || 0) * Number(bundle.player_price || 0);

    const productsValue = bundle.products.reduce(
      (acum, item) =>
        (acum +=
          Number(item.dont_charge ? 0 : item.single_value || 0) *
          Number(
            item.min_amount > 0
              ? item.min_amount
              : item.charge_type === "participants"
              ? bundle.players || 1
              : 1
          )),
      0
    );

    const buffetValue =
      Number(bundle.players || 0) * Number(bundle.buffet_value || 0);

    const totalValue = ticketsValue + productsValue + buffetValue;

    const onBundleSelected = () => {
      if (onChange)
        onChange({
          ...bundle,
          tickets_value: ticketsValue,
          products_value: productsValue,
          total_value: totalValue,
        });
    };

    const selectedBundle = value?.id === bundle.id;

    return (
      <Col
        key={`bundle_item_${bundle.id}`}
        xs={12}
        sm={6}
        md={4}
        xxl={3}
        className="p-1"
      >
        <div
          className={`h-100 rounded-box p-2 d-flex flex-column ${
            selectedBundle ? "bg-primary text-white" : "border border-primary"
          }`}
          role="button"
          onClick={onBundleSelected}
        >
          <span
            className={`fw-bold fs-5 ${
              selectedBundle ? "text-secondary" : "text-primary"
            }`}
          >
            {bundle.name}
          </span>
          <span>{`${bundle.duration} horas`}</span>
          <span>{`${Number(bundle.players || 0)} participantes`}</span>
          <span>
            {`${Number(bundle.player_price || 0).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })} por brincante`}
          </span>
          {renderProducts()}
          {bundle.buffet_value > 0 && (
            <span>
              {`${Number(bundle.buffet_value || 0).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })} por buffet "${bundle.buffet_name}"`}
            </span>
          )}
          <span className="mx-auto mt-auto fw-bold text-success">{`${Number(
            totalValue || 0
          ).toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          })}`}</span>
        </div>
      </Col>
    );
  };

  const renderBundles = () => {
    if (!selectedTicketType)
      return (
        <span className="text-center text-muted">
          Selectione um tipo de festa acima para vender um pacote de festa.
        </span>
      );
    if (!selectedDate)
      return (
        <span className="text-center text-muted">
          Selectione a data da festa.
        </span>
      );
    if (loadingBundles) return <ProgressBar animated now={100} />;
    if (!bundles || !bundles[0])
      return (
        <span className="text-center text-muted">
          Nenhum pacote disponível para este tipo de festa e data.
        </span>
      );

    return <Row className="g-1">{bundles.map(renderBundle)}</Row>;
  };

  const renderType = (type) => {
    const handleClick = (e) => {
      setSelectedTicketType(type);
    };

    return (
      <Button
        variant={selectedTicketType === type ? "secondary" : "primary"}
        className="fw-bold"
        disabled={loadingBundles}
        onClick={handleClick}
        key={`ticket_type_item_${type}`}
      >
        {getTicketType(type)}
      </Button>
    );
  };

  const renderTicketTypes = () => {
    return (
      <div className="d-flex flex-column gap-2">
        <span className="text-muted">
          Selecione um tipo de festa abaixo para carregar os pacotes.
        </span>
        {getTicketTypes("parties").map(renderType)}
      </div>
    );
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  return (
    <div className="d-flex flex-column gap-3">
      {renderTicketTypes()}
      {selectedTicketType && (
        <FormGroup>
          <FormLabel>Data da festa</FormLabel>
          <FormControl
            type={"date"}
            value={selectedDate}
            onChange={handleDateChange}
          />
        </FormGroup>
      )}
      {renderBundles()}
    </div>
  );
};

export default Bundles;
