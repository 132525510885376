import { Button, ListGroup, ProgressBar } from "react-bootstrap";
import moment from "moment";
import { BiSearchAlt } from "react-icons/bi";
import { useState } from "react";
import Row from "./Row";

const List = ({ list, total, setSelectedItem, load, filter, setFilter }) => {
  const [search, setSearch] = useState("");

  const handleNewItem = () => {
    const defaultData = {
      name: "",
      avatar: "",
      display: false,
      validation_text: "",
    };

    setSelectedItem(defaultData);
  };

  const handleSearch = () => {
    setFilter((prev) => ({ ...prev, search }));
  };

  const onSearchKeyDown = (e, x) => {
    const enter = 13;
    if (e.keyCode === enter) handleSearch();
  };

  const handleFilterChange = (e) => {
    setFilter((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  return (
    <>
      <div className="d-flex gap-1 mb-1 flex-wrap align-items-center justify-content-center align-items-sm-start justify-content-sm-start">
        <h5>Lista de parceiros:</h5>
        <div className="input-group w-auto flex-0">
          <input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="form-control"
            placeholder="Pesquisar parceiro"
            onKeyDown={onSearchKeyDown}
          />
          <span
            className="input-group-text cursor-pointer"
            onClick={handleSearch}
          >
            <BiSearchAlt />
          </span>
        </div>
        <Button className="ml-sm-auto" onClick={handleNewItem}>
          Novo Parceiro
        </Button>
      </div>
      <div className="d-flex gap-1 mb-1 flex-wrap align-items-center justify-content-center align-items-sm-start justify-content-sm-start">
        <h5>Filtrar estatísticas:</h5>
        <div className="input-group w-auto flex-0">
          <span className="input-group-text">Início</span>
          <input
            value={
              filter.start_date
                ? moment(filter.start_date).format("YYYY-MM-DD")
                : null
            }
            onChange={handleFilterChange}
            name="start_date"
            type="date"
            className="form-control"
            placeholder="Data inicial"
          />
        </div>
        <div className="input-group w-auto flex-0">
          <span className="input-group-text">Fim</span>
          <input
            value={
              filter.end_date
                ? moment(filter.end_date).format("YYYY-MM-DD")
                : null
            }
            onChange={handleFilterChange}
            name="end_date"
            type="date"
            className="form-control"
            placeholder="Data final"
          />
        </div>
      </div>
      {load && <ProgressBar animated now={100} className="mb-1" />}
      {!list[0] && !load && (
        <p style={{ textAlign: "center" }}>Não há parceiros cadastrados.</p>
      )}
      <ListGroup className="rounded-box gap-1">
        {list.map((item, index) => (
          <Row
            key={"partner_" + item.id}
            item={item}
            setSelectedItem={setSelectedItem}
          />
        ))}
      </ListGroup>
    </>
  );
};

export default List;
