import React, { useState, useContext } from "react";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { FilialContext } from "../../context/FilialContext";
import Api from "../../api/api";
import "./styles.scss";

export const AddBuffet = ({
  buffetModalOpen,
  setBuffetModalOpen,
  handleUpdateList,
}) => {

  const { selectedUnitId } = useContext(FilialContext);

  const [name, setName] = useState("");
  const [value, setValue] = useState("");

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleValueChange = (event) => {
    setValue(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (name && value) {
        postBuffets();
      } else {
        toast.error("Por favor, preencha todos os campos.");
      }
    }
  };

  const handleCloseModal = (event) => {
    setName("")
    setValue("")
    setBuffetModalOpen(false)
  };

  const postBuffets = () => {
    if (name && value) {
      const newItem = {
        unit_id: selectedUnitId,
        name: name,
        value: value,
      };

      Api.post("/buffets", newItem)
        .then((res) => {
          setName("");
          setValue("");
          setBuffetModalOpen(false);
          handleUpdateList();
          toast.success("Buffet Criado com sucesso!");
          setTimeout(() => {
            toast.warning("Adicione os itens do Buffet!");
          }, 3000);
        })
        .catch((e) => {
          toast.error(e.message);
        });
    } else {
      toast.error("Por favor, preencha todos os campos.");
    }
  };

  return (
    <Modal show={buffetModalOpen} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Adicionar Buffet</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <label>Nome do Buffet:</label>
          <input
            autoFocus
            className="form-control"
            type="text"
            value={name}
            onChange={handleNameChange}
            onKeyDown={handleKeyDown}
            placeholder={"Digite o nome..."}
          />
          <br />
          <label>Valor por Pessoa:</label>
          <input
            className="form-control"
            type="number"
            value={value}
            onChange={handleValueChange}
            onKeyDown={handleKeyDown}
            placeholder={"Digite o valor..."}
          />
          <br />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={postBuffets}>
          Cadastrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};