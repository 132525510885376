import { useEffect, useState } from "react";

export const cleanNumberString = (str) => {
  if (!str) return null;

  return str.replace(/[^0-9]/g, "");
};

export const customerTypesList = [
  {
    value: "default",
    label: "Cliente",
  },
  {
    value: "employee",
    label: "Funcionário",
  },
  {
    value: "press",
    label: "Imprensa",
  },
  {
    value: "influencer",
    label: "Influencer",
  },
];

export const genderList = [
  {
    value: "default",
    label: "Selecione o gênero",
  },

  {
    value: "male",
    label: "Masculino",
  },
  {
    value: "female",
    label: "Feminino",
  },
  {
    value: "other",
    label: "Outro",
  },
  {
    value: "rather_not_inform",
    label: "Prefiro não informar",
  },
];

export const weekDaysList = [
  { value: 0, label: "Domingo" },
  { value: 1, label: "Segunda-feira" },
  { value: 2, label: "Terça-feira" },
  { value: 3, label: "Quarta-feira" },
  { value: 4, label: "Quinta-feira" },
  { value: 5, label: "Sexta-feira" },
  { value: 6, label: "Sábado" },
  { value: 7, label: "Feriados" },
];

export const getStatementParticipantType = (type) => {
  switch (type) {
    case "adult":
      return "Adulto";
    case "minor_responsible":
      return "Acompanhante dos menores";
    case "minor":
      return "Menor de 18 anos";

    default:
      return "Indefinido";
  }
};

export const ticketTimes = [
  { value: "00:00", label: "00:00" },
  { value: "01:00", label: "01:00" },
  { value: "02:00", label: "02:00" },
  { value: "03:00", label: "03:00" },
  { value: "04:00", label: "04:00" },
  { value: "05:00", label: "05:00" },
  { value: "06:00", label: "06:00" },
  { value: "07:00", label: "07:00" },
  { value: "08:00", label: "08:00" },
  { value: "09:00", label: "09:00" },
  { value: "10:00", label: "10:00" },
  { value: "11:00", label: "11:00" },
  { value: "12:00", label: "12:00" },
  { value: "13:00", label: "13:00" },
  { value: "14:00", label: "14:00" },
  { value: "15:00", label: "15:00" },
  { value: "16:00", label: "16:00" },
  { value: "17:00", label: "17:00" },
  { value: "18:00", label: "18:00" },
  { value: "19:00", label: "19:00" },
  { value: "20:00", label: "20:00" },
  { value: "21:00", label: "21:00" },
  { value: "22:00", label: "22:00" },
  { value: "23:00", label: "23:00" },
];

export const getTicketAttachmentType = (type) => {
  switch (type) {
    case "party_contract":
      return "Contrato de Festa";
    case "menu_contract":
      return "Contrato de Cardápio";
    case "guests_list":
      return "Lista de Convidados";

    default:
      return "Indefinido";
  }
};

export const getTicketTypes = (type) => {
  if (type === "parties")
    return ["single", "exclusive", "demi_exclusive", "pop_night"];
  return ["ticket", "single", "exclusive", "demi_exclusive"];
};

export const getTicketType = (type) => {
  switch (type) {
    case "ticket":
      return "Ticket Avulso";
    case "single":
      return "Festa Compartilhada";
    case "exclusive":
      return "Festa Exclusiva";
    case "demi_exclusive":
      return "Festa Semi-Exclusiva";
    case "pop_night":
      return "PopNight";

    default:
      return type;
  }
};

export const ticketTypesList = getTicketTypes().map((type) => ({
  value: type,
  label: getTicketType(type),
}));

export const removeAccents = (str) => {
  const accentsMap = {
    á: "a",
    é: "e",
    í: "i",
    ó: "o",
    ú: "u",
    à: "a",
    è: "e",
    ì: "i",
    ò: "o",
    ù: "u",
    â: "a",
    ê: "e",
    î: "i",
    ô: "o",
    û: "u",
    ã: "a",
    õ: "o",
    ñ: "n",
    ç: "c",
    ü: "u",
    ý: "y",
    ä: "a",
    ë: "e",
    ï: "i",
    ö: "o",
    ÿ: "y",
    æ: "ae",
    œ: "oe",
    ß: "ss",
  };

  return str.replace(/[^\u0000-\u007E]/g, (char) => {
    return accentsMap[char] || char;
  });
};

export const useBootstrapBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState("");

  useEffect(() => {
    const handleResize = () => {
      const { innerWidth } = window;
      let currentBreakpoint = "";

      if (innerWidth < 400) currentBreakpoint = "xxs";
      else if (innerWidth >= 400 && innerWidth < 576) {
        currentBreakpoint = "xs";
      } else if (innerWidth >= 576 && innerWidth < 768) {
        currentBreakpoint = "sm";
      } else if (innerWidth >= 768 && innerWidth < 992) {
        currentBreakpoint = "md";
      } else if (innerWidth >= 992 && innerWidth < 1200) {
        currentBreakpoint = "lg";
      } else if (innerWidth >= 1200 && innerWidth < 1400) {
        currentBreakpoint = "xl";
      } else currentBreakpoint = "xxl";

      setBreakpoint(currentBreakpoint);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return breakpoint;
};

export const couponDiscountTypes = [
  {
    value: "PERCENTAGE",
    label: "Porcentagem",
  },
  {
    value: "FIXED",
    label: "Valor Fixo",
  },
];

export const getPartyStatus = (itemStatus) => {
  let status = "INDEFINIDO";

  switch (itemStatus) {
    case 1:
      status = "PRÉ-RESERVA";
      break;
    case 2:
      status = "FESTA REALIZADA";
      break;
    case 3:
      status = "EXCLUÍDA";
      break;
    case 4:
      status = "RESERVA";
      break;
    default:
      break;
  }

  return status;
};

export const getTransactionStatus = (itemStatus) => {
  let status = "INDEFINIDO";

  switch (itemStatus) {
    case "pending":
      status = "AGUARDANDO PAGAMENTO";
      break;
    case "used":
      status = "USO CONFIRMADO";
      break;
    case "authorized":
      status = "PAGAMENTO CONFIRMADO";
      break;
    case "voided":
      status = "ESTORNADO";
      break;
    case "pending_void":
      status = "AGUARDANDO ESTORNO";
      break;
    case "canceled":
      status = "CANCELADO";
      break;
    case "expired":
      status = "EXPIRADO";
      break;
    case "transaction_failed":
      status = "FALHA NA TRANSAÇÃO";
      break;
    case "failed":
      status = "FALHA NO PAGAMENTO";
      break;
    default:
      break;
  }

  return status;
};
