import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  Button,
  Col,
  ProgressBar,
  Row,
  Card,
  FormLabel,
} from "react-bootstrap";
import { toast } from "react-toastify";
import Api from "../../api/api";
import PageTitle from "../../components/PageTitle";
import { FilialContext } from "../../context/FilialContext";
import EditModal from "./EditModal";
import Table from "../../components/Table";
import { HiPencilSquare } from "react-icons/hi2";
import { getTicketType } from "../../utils/functions";

const Tickets = () => {
  const { selectedUnitId } = useContext(FilialContext);
  const [list, setList] = useState([]);
  const [loadingList, setLoadingList] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [data, setData] = useState(null);
  const [typeFilter, setTypeFilter] = useState("");

  console.log(typeFilter);

  const getList = () => {
    setLoadingList(true);
    Api.get(`/tickets`, {
      params: { unit_id: selectedUnitId, type: typeFilter },
    })
      .then((res) => {
        setList(res.data.list);
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingList(false));
  };

  useEffect(() => {
    if (selectedUnitId) getList();
  }, [selectedUnitId, typeFilter]);

  const handleNewData = () => {
    setOpenEditModal(true);
  };

  useEffect(() => {
    if (!openEditModal) setData(null);
  }, [openEditModal]);

  const setID = (newID) => {
    setData((prev) => ({ ...prev, id: newID }));
  };

  const columns = [
    {
      field: "name",
      label: "Nome",
    },
    {
      field: "type",
      label: "Tipo",
      renderValue: (item) => getTicketType(item.type),
    },
    {
      field: "duration",
      label: "Duração",
      renderValue: (item) =>
        `${item.duration} hora${item.duration > 1 ? "s" : ""}`,
    },
    {
      field: "player_price",
      label: "Preço Brincante",
      renderValue: (item) =>
        item.player_price.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
    },
    {
      field: "not_player_price",
      label: "Preço Não Brincante",
      renderValue: (item) =>
        item.type === "exclusive"
          ? "-"
          : item.not_player_price.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            }),
    },
    {
      field: "enable_on_checkout",
      label: "Habilitado no checkout",
      renderValue: (item) =>
        [true, "true"].includes(item.enable_on_checkout) ? "Sim" : "Não",
    },
    {
      field: "additional_hour_price",
      label: "Preço Hora Adicional",
      renderValue: (item) =>
        item.type === "exclusive"
          ? "-"
          : item.additional_hour_price.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            }),
    },
  ];

  const renderRow = (field, item) => {
    if (!item) return null;
    const data = columns.find((col) => col.field === field);
    if (data?.renderValue) return data.renderValue(item);
    return item[field];
  };

  const getTypeOptions = () => {
    const statuses = [
      { value: "ticket", label: "Ingressos Avulsos" },
      { value: "parties", label: "Festas" },
    ];

    return statuses.map((status) => (
      <option key={`type_${status.value}`} value={status.value}>
        {status.label}
      </option>
    ));
  };

  const handleTypeFilterChange = (e) => setTypeFilter(e.target.value);

  return (
    <>
      {openEditModal && (
        <EditModal
          open={openEditModal}
          setOpen={setOpenEditModal}
          id={data?.id}
          onSuccess={getList}
          setID={setID}
        />
      )}
      <PageTitle>
        <h2>Tickets</h2>
        <Button variant="primary" onClick={handleNewData}>
          Novo Ticket
        </Button>
      </PageTitle>
      <Row className="g-2">
        <Col xs={12} sm={6} md={4} xxl={3}>
          <div>
            <FormLabel>Filtrar Tipo de Ticket</FormLabel>
            <select
              value={typeFilter}
              onChange={handleTypeFilterChange}
              className="form-control"
              placeholder="Filtrar Tipo de Ticket"
            >
              <option value={""}>Todos os tickets</option>
              {getTypeOptions()}
            </select>
          </div>
        </Col>
        <Col xs={12} className="d-flex flex-column gap-1">
          {loadingList && <ProgressBar animated now={100} />}
          <Card>
            <Card.Body>
              <Card.Title>
                <h5 className="fw-bold">Lista de tickets:</h5>
              </Card.Title>
              <Table
                data={list}
                columns={columns}
                renderRow={renderRow}
                renderRowItem
                actions={(item) => (
                  <div className="d-flex align-items-center gap-1">
                    <Button
                      data-toggle="tooltip"
                      title="Editar"
                      variant="outline-primary"
                      className="rounded-circle"
                      size="sm"
                      onClick={() => {
                        setData(item);
                        setOpenEditModal(true);
                      }}
                    >
                      <HiPencilSquare size={"100%"} />
                    </Button>
                  </div>
                )}
              />
            </Card.Body>
          </Card>
          {!loadingList && (!list || !list[0]) && (
            <span className="text-muted">Nenhum ticket para mostrar.</span>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Tickets;
