import { Modal, Card, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { useState } from "react";
import { Link } from "react-router-dom";
export const ModalConfirmParty = ({
  userInfo,
  onCloseModalConfirm,
  openModalConfirm,
  setUpdate,
}) => {
  const [message, setMessage] = useState("");
  function notify(text) {
    toast.success(text);
  }

  function handleConfirmParty(id) {
    setMessage("");
    onCloseModalConfirm();
    setUpdate((prevState) => prevState + 1);
  }
  return (
    <Modal show={openModalConfirm} onHide={onCloseModalConfirm} size="md">
      <Modal.Header className="bg-primary">
        <h4 className="text-white">Confirmar Festa</h4>
      </Modal.Header>
      <Modal.Body>
        <Card.Body>
          <h3 className="text-center">
            Atenção! A transação será confirmada junto com o agendamento no
            parque.
          </h3>
          <div className="d-flex justify-content-between mt-4">
            <Link
              to="/gerenciar-festa"
              className="btn btn-success"
              onClick={() => handleConfirmParty("")}
            >
              Confirmar Festa
            </Link>
            <Button onClick={onCloseModalConfirm}>Fechar</Button>
          </div>
        </Card.Body>
      </Modal.Body>
    </Modal>
  );
};
