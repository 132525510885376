import React, { useState, useEffect } from "react";
import { Modal, Button, Spinner, Pagination } from "react-bootstrap";
import Api from "../../api/api";
import { toast } from "react-toastify";
import "./styles.scss";

export const AddItemModal = ({
  itemModalOpen,
  setItemModalOpen,
  selectedBuffetItemId,
}) => {
  const [selectedBuffetItem, setSelectedBuffetItem] = useState(null);
  const [itemName, setItemName] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0); // Página atual começa em 0
  const [totalPages, setTotalPages] = useState(1); // Total de páginas
  const [activePage, setActivePage] = useState(0); // Estado para controlar a página ativa

  const handleUpdateList = (newPage) => {
    setLoading(true);
    const limit = 10;

    Api.get(
      `/buffets_items?buffet_id=${selectedBuffetItemId}&page=${newPage}&limit=${limit}`
    )
      .then((res) => {
        setSelectedBuffetItem(res.data);
        const totalItems = res.data.total;
        const totalPages = Math.ceil(totalItems / limit);
        setTotalPages(totalPages);
        setActivePage(newPage);
      })
      .catch((e) => {
        toast.error(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handDeleteItems = (itemId) => {
    const confirmDelete = window.confirm(
      `Tem certeza de que deseja deletar o item?`
    );

    if (confirmDelete) {
      Api.delete(`/buffets_items/${itemId}`)
        .then((res) => {
          handleUpdateList(page);
        })
        .catch((e) => {
          toast.error(e.message);
        });
    }
  };

  const postBuffets = () => {
    if (itemName) {
      const newItem = {
        buffet_id: selectedBuffetItemId,
        name: itemName,
      };

      Api.post("/buffets_items", newItem)
        .then((res) => {
          toast.success("Item criado com sucesso");
          handleUpdateList(page); // Atualiza a lista após a criação
          setItemName("");
        })
        .catch((e) => {
          toast.error(e.message);
        });
    } else {
      toast.error("Por favor, preencha todos os campos.");
    }
  };

  useEffect(() => {
    handleUpdateList(page);
  }, [page]); // Atualize a lista quando a página mudar

  const handleItemNameChange = (event) => {
    setItemName(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (itemName) {
        postBuffets();
      } else {
        toast.error("Por favor, preencha todos os campos.");
      }
    }
  };

  return (
    <Modal show={itemModalOpen} onHide={() => setItemModalOpen(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Adicionar Item</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <label>Nome do Item:</label>
        <input
          autoFocus
          type="text"
          className="form-control"
          value={itemName}
          onChange={handleItemNameChange}
          onKeyDown={handleKeyDown}
          placeholder="Digite o nome do item..."
        />
        <br />
        <Modal.Footer>
          <Button variant="primary" onClick={() => postBuffets()}>
            Cadastrar
          </Button>
        </Modal.Footer>

        <>
          <Modal.Title>Itens:</Modal.Title>
          {loading && (
            <div className="d-flex justify-content-center align-items-center m-6">
              <Spinner />
            </div>
          )}
          {!loading && (
            <ul className="item-list list-unstyled">
              {selectedBuffetItem?.list.length === 0 && (
                <div className="d-flex justify-content-center mt-5">
                  <span>Ainda não foi cadastrado nenhum item</span>
                </div>
              )}
              {selectedBuffetItem?.list.map((item, index) => (
                <li key={index}>
                  <div className="item-info">
                    <div className="d-flex flex-column">
                      <span>
                        <strong>Nome:</strong> {item.name}{" "}
                      </span>
                    </div>
                    <Button
                      variant="danger"
                      onClick={() => handDeleteItems(item.id)}
                    >
                      Deletar
                    </Button>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </>

        {totalPages > 1 && (
          <div className="d-flex justify-content-center mt-3">
            <Pagination>
              {Array.from({ length: totalPages }, (_, index) => (
                <Pagination.Item
                  key={index}
                  active={activePage === index} // Defina a classe active com base em activePage
                  onClick={() => handleUpdateList(index)}
                >
                  {index + 1}
                </Pagination.Item>
              ))}
            </Pagination>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
