import React from "react";
import { Col } from "react-bootstrap";
import {
  useTable,
  useAsyncDebounce,
  useFilters,
  useGlobalFilter,
  useBlockLayout,
  useSortBy,
} from "react-table";
import { FixedSizeList } from "react-window";
import scrollbarWidth from "./scrollbarWidth";

import { BiSearchAlt } from "react-icons/bi";
import { dateFormated } from "../../utils/dateFormated";
import "./styles.scss"

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  isSearchableData,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const [valueData, setValueData] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <>
      <Col lg={6} className="p-0">
        <label className="form-label">Procurar </label>
        <div className=" mb-3 input-group">
          <input
            value={value || ""}
            onChange={(e) => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
            placeholder={`${count} records...`}
            className="form-control"
          />
          <span className="input-group-text ">
            <BiSearchAlt />
          </span>
        </div>
      </Col>

      {isSearchableData && (
        <Col lg={6} className="p-0">
          <label className="form-label">Procurar Pela Data </label>
          <div className=" mb-3 input-group">
            <input
              onChange={(e) => {
                setValueData(dateFormated(e.target.value));
                onChange(dateFormated(e.target.value));
              }}
              placeholder={`${count} records...`}
              className="form-control"
              type={"date"}
            />
            <span className="input-group-text ">
              <BiSearchAlt />
            </span>
          </div>
        </Col>
      )}
    </>
  );
}
function GlobalFilterData({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <Col lg={6} className="p-0">
      <label className="form-label">Procurar Pela Data </label>
      <div className=" mb-3 input-group">
        <input
          value={value || ""}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={`${count} records...`}
          className="form-control"
          type={"date"}
        />
        <span className="input-group-text ">
          <BiSearchAlt />
        </span>
      </div>
    </Col>
  );
}

const Table = ({
  columns,
  data,
  isSearchable = false,
  isSearchableData = false,
  sizeTable = 400,
  isSorted = true,
}) => {
  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    totalColumnsWidth,
  } = useTable(
    { columns, data },
    useFilters,
    useGlobalFilter,
    useBlockLayout,
    useSortBy
  );
  const scrollBarSize = React.useMemo(() => scrollbarWidth(), []);

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);
      return (
        <div
          {...row.getRowProps({
            style,
          })}
        >
          {row.cells.map((cell) => {
            return (
              <td className="customTableCell"
                {...cell.getCellProps()}
              >
                {cell.render("Cell")}
              </td>
            );
          })}
        </div>
      );
    },
    [prepareRow, rows]
  );

  return (
    <>
      {isSearchable && (
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
          isSearchable={isSearchable}
          isSearchableData={isSearchableData}
        />
      )}

      <div className="table table-responsive table-centered  dt-responsive nowrap w-100">
        <table {...getTableProps()} className="mb-0 ">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  scope="row"
                >
                  {column.render("Header")}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔼"
                        : " 🔽"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}

          <FixedSizeList
            height={sizeTable}
            itemCount={rows.length}
            itemSize={60}
            width={totalColumnsWidth + scrollBarSize}
          >
            {RenderRow}
          </FixedSizeList>
        </table>
      </div>
    </>
  );
};
export default Table;
