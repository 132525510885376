import moment from "moment";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  Button,
  Col,
  Form,
  InputGroup,
  ListGroup,
  Modal,
  ProgressBar,
  Row,
} from "react-bootstrap";
import { toast } from "react-toastify";
import Api from "../../api/api";
import "moment/locale/pt-br";
import ReactSelect from "react-select";
import { getTicketType, getTicketTypes } from "../../utils/functions";

const selectStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderBottomRightRadius: "1rem",
    borderTopRightRadius: "1rem",
  }),
  container: (baseStyles, state) => ({
    ...baseStyles,
    alignSelf: "stretch",
    flex: 1,
  }),
  value: (base) => ({ ...base, borderRadius: "1rem" }),
  multiValue: (base) => ({ ...base, borderRadius: "1rem" }),
};

const TimesModal = ({ open, setOpen, calendar_date }) => {
  const handleClose = () => setOpen(false);
  const [list, setList] = useState([]);
  const [loadingList, setLoadingList] = useState(false);
  const [values, setValues] = useState({});
  const [loadingData, setLoadingData] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);

  const getData = (id) => {
    setLoadingData(id);
    Api.get(`calendar_dates_hours/${id}`)
      .then((res) => setValues(res.data.data))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingData(false));
  };

  const ticketTypesList = getTicketTypes().map((type) => ({
    value: type,
    label: getTicketType(type),
  }));

  const renderList = () => {
    if (!loadingList && (!list || !list[0]))
      return <span className="text-muted">Nenhum horário para mostrar.</span>;
    return (list || []).map((item) => {
      const handleClick = () => {
        setValues({ ...item });
        getData(item.id);
      };

      const renderOpenFor = () => {
        return (
          <div className="d-flex flex-column">
            {item.open_for[0] ? "Aberto para" : "Fechado"}
            {item.open_for[0] &&
              item.open_for.map((typeValue) => (
                <span key={`open_for_item_${typeValue}`}>
                  {
                    ticketTypesList.find((type) => type.value === typeValue)
                      ?.label
                  }
                </span>
              ))}
          </div>
        );
      };

      return (
        <ListGroup.Item
          key={`default_date_item_${item.id}`}
          action
          active={item.id === values.id}
          onClick={handleClick}
          disabled={loadingSave || loadingData}
        >
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex flex-column">
              <span>{moment(item.time, "HH:mm").format("HH:mm")}</span>
              <span>{`${item.total_spaces} vagas`}</span>
            </div>
            <div>{renderOpenFor()}</div>
          </div>
        </ListGroup.Item>
      );
    });
  };

  const roundToNearestHour = (time) => {
    if (!time) return null;
    const timeDate = new Date(`2000-01-01T${time}`);
    const minutes = timeDate.getMinutes();
    const roundedMinutes = Math.round(minutes / 60) * 60;
    timeDate.setMinutes(roundedMinutes);
    return moment(timeDate).format("HH:mm");
  };

  const handleChange = (e) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const getList = () => {
    setLoadingList(true);
    Api.get("calendar_dates_hours", {
      params: { calendar_date_id: calendar_date?.id },
    })
      .then((res) => setList(res.data.list))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingList(false));
  };

  useEffect(() => {
    if (calendar_date?.id && open) getList();
  }, [open, calendar_date]);

  const handleNewData = () => {
    setValues({
      id: "new",
      time: "",
      total_spaces: 100,
      calendar_date_id: calendar_date?.id,
    });
  };

  const handleSave = () => {
    setLoadingSave(true);
    if (values.id === "new")
      return Api.post(`calendar_dates_hours`, values)
        .then((res) => {
          setValues({ ...res.data });
          toast.success("Horário criado com sucesso!");
          getList();
        })
        .catch((err) => toast.error(err.message))
        .finally(() => setLoadingSave(false));
    Api.put(`calendar_dates_hours/${values.id}`, values)
      .then((res) => {
        setValues({ ...res.data });
        toast.success("Horário atualizado com sucesso!");
        getList();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingSave(false));
  };

  const timeOptions = () => {
    const list = [];

    for (let index = 0; index < 24; index++) {
      const hour = index < 10 ? "0" + index : index.toString();
      list.push(<option value={`${hour}:00:00`}>{`${hour}:00`}</option>);
    }

    return list;
  };

  const handleOpenForChange = (types) => {
    setValues((prev) => ({
      ...prev,
      open_for: types.map((item) => item.value),
    }));
  };

  return (
    <Modal size={values?.id ? "lg" : null} show={open} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>
          Horários para {moment(calendar_date.date).format("dddd, DD/MM/YYYY")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="g-2">
          <Col xs={12} md={values?.id ? 6 : 12}>
            <div className="d-flex align-items-center justify-content-between mb-2">
              <span className="fw-bold">Lista de horários</span>
              <Button onClick={handleNewData}>Novo Horário</Button>
            </div>
            {loadingList && <ProgressBar className="mb-1" animated now={100} />}
            <ListGroup>{renderList()}</ListGroup>
          </Col>
          {values?.id && (
            <Col xs={12} md={6}>
              <div className="d-flex flex-column gap-1">
                <span className="fw-bold">
                  {values.id === "new" ? "Novo horário" : "Editando horário"}
                </span>
                <InputGroup>
                  <InputGroup.Text>Horário</InputGroup.Text>
                  <Form.Select
                    value={values?.time}
                    name={"time"}
                    onChange={handleChange}
                    disabled={
                      loadingSave ||
                      loadingData ||
                      (values?.id && values.id !== "new")
                    }
                  >
                    <option hidden>Selecione o horário</option>
                    {timeOptions()}
                  </Form.Select>
                </InputGroup>
                <InputGroup>
                  <InputGroup.Text>Limite de vagas</InputGroup.Text>
                  <Form.Control
                    value={values?.total_spaces}
                    name={"total_spaces"}
                    onChange={handleChange}
                    type="number"
                    min={0}
                    disabled={loadingSave || loadingData}
                  />
                </InputGroup>
                <InputGroup>
                  <InputGroup.Text>Aberto para</InputGroup.Text>
                  <ReactSelect
                    classNamePrefix="react-select"
                    isMulti
                    onChange={handleOpenForChange}
                    value={(values?.open_for || []).map((type) => ({
                      value: type,
                      label: ticketTypesList.find(
                        (ticketType) => ticketType === type
                      ),
                    }))}
                    name={"open_for"}
                    disabled={loadingSave || loadingData}
                    styles={selectStyles}
                    getOptionLabel={(opt) =>
                      ticketTypesList.find((type) => type.value === opt.value)
                        ?.label
                    }
                    isOptionSelected={(opt) =>
                      (values?.open_for || []).find(
                        (item) => item === opt.value
                      )
                    }
                    options={ticketTypesList}
                  />
                </InputGroup>
                <div className="d-flex align-items-center align-self-end gap-1">
                  <Button variant="light" onClick={() => setValues({})}>
                    Cancelar
                  </Button>
                  <Button onClick={handleSave}>Salvar</Button>
                </div>
              </div>
            </Col>
          )}
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="light" onClick={handleClose}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TimesModal;
