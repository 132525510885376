import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import Api from "../../api/api";
import { FilialContext } from "../../context/FilialContext";
import BundleTicket from "./BundleTicket";
import BundleProducts from "./BundleProducts";

const EditModal = ({ open, setOpen, id, setID, onSuccess }) => {
  const { selectedUnitId } = useContext(FilialContext);
  const init = {
    unit_id: selectedUnitId,
    name: "",
    buffet_id: "",
  };
  const [values, setValues] = useState(init);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);
  const [ticketsValue, setTicketsValue] = useState(0);
  const [productsValue, setProductsValue] = useState(0);
  const [loadingBuffets, setLoadingBuffets] = useState(false);
  const [buffets, setBuffets] = useState([]);
  const [buffetValue, setBuffetValue] = useState(0);

  const getBuffets = () => {
    setLoadingBuffets(true);
    Api.get(`/buffets`, { params: { unit_id: selectedUnitId } })
      .then((res) => {
        setBuffets(res.data.list);
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingBuffets(false));
  };

  useEffect(() => {
    if (selectedUnitId) getBuffets();
  }, [selectedUnitId]);

  const getData = () => {
    setValues(init);
    setLoadingData(true);
    Api.get(`/bundles/${id || values.id}`)
      .then((res) => {
        setValues(res.data);
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingData(false));
  };

  useEffect(() => {
    if (id) getData();
  }, [id]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setValues((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleSave = () => {
    setLoadingSave(true);
    if (values.id)
      return Api.put(`/bundles/${values.id}`, values)
        .then((res) => {
          toast.success("Pacote atualizado com sucesso!");
          setValues(res.data);
          if (onSuccess) onSuccess();
        })
        .catch((err) => toast.error(err.message))
        .finally(() => setLoadingSave(false));
    Api.post("/bundles", values)
      .then((res) => {
        toast.success("Pacote criado com sucesso!");
        setValues(res.data);
        if (onSuccess) onSuccess();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingSave(false));
  };

  const handleRemove = () => {
    if (
      window.confirm(
        "Deseja realmente remover este pacote? Ele não será mais listado no momento da venda."
      )
    ) {
      setLoadingRemove(true);
      Api.delete(`bundles/${values.id}`)
        .then(() => {
          toast.success("Pacote removido com sucesso!");
          setOpen(false);
          if (onSuccess) onSuccess();
        })
        .catch((err) => {
          setLoadingRemove(false);
          toast.error(err.message);
        });
    }
  };

  const loadingLocked =
    loadingSave || loadingRemove || loadingData || loadingBuffets;

  const selectedBuffet = buffets.find((b) => b.id === values.buffet_id);
  const totalValue =
    Number(ticketsValue || 0) +
    Number(productsValue || 0) +
    Number(buffetValue || 0);

  const handleSuccess = () => {
    getData();
    if (onSuccess) onSuccess();
  };

  return (
    <Modal show={!!open} size="lg" onHide={loadingLocked ? null : handleClose}>
      <Modal.Header>
        <Modal.Title>{`${
          values?.id ? "Atualizar dados do" : "Cadastrar novo"
        } pacote`}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column gap-1">
        <div className="border border-primary rounded-box p-1 d-flex flex-column gap-1">
          <span className="fs-5 fw-bold">Dados do pacote</span>
          <InputGroup>
            <InputGroup.Text>Nome</InputGroup.Text>
            <Form.Control
              value={values?.name}
              name={"name"}
              onChange={handleChange}
              autoFocus
              disabled={loadingLocked}
            />
          </InputGroup>
          <InputGroup>
            <InputGroup.Text>Buffet</InputGroup.Text>
            <Form.Select
              value={values?.buffet_id}
              name={"buffet_id"}
              onChange={handleChange}
              disabled={loadingLocked}
            >
              <option value={0}>Selecione o buffet para o pacote</option>
              {buffets.map((buffet) => (
                <option key={`buffet_item_${buffet.id}`} value={buffet.id}>
                  {`${buffet.name} (${buffet.value.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })})`}
                </option>
              ))}
            </Form.Select>
          </InputGroup>
          {values?.id && (
            <Button
              onClick={handleSave}
              variant="outline-primary"
              disabled={loadingLocked}
            >
              Atualizar
            </Button>
          )}
        </div>
        {values?.id && (
          <>
            <div className="border border-primary rounded-box p-1">
              <BundleTicket
                bundle_id={values?.id}
                onSuccess={handleSuccess}
                onTicketsTotalValueChange={setTicketsValue}
                selectedBuffet={selectedBuffet}
                onBuffetTotalValueChange={setBuffetValue}
              />
            </div>
            <div className="border border-primary rounded-box p-1">
              <BundleProducts
                bundle_id={values?.id}
                onSuccess={handleSuccess}
                onProductsTotalValueChange={setProductsValue}
              />
            </div>
            <span className="fw-bold fs-5">{`Valor total do pacote: ${totalValue.toLocaleString(
              "pt-br",
              {
                style: "currency",
                currency: "BRL",
              }
            )}`}</span>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose} disabled={loadingLocked}>
          {values.id ? "Fechar" : "Cancelar"}
        </Button>
        {values.id && (
          <>
            <Button
              variant="danger"
              onClick={handleRemove}
              disabled={loadingLocked}
            >
              {`Remover`}
            </Button>
          </>
        )}
        {!values.id && (
          <Button onClick={handleSave} disabled={loadingLocked}>
            Cadastrar
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default EditModal;
