import React from "react";
import Calendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import bootstrap5Plugin from "@fullcalendar/bootstrap5";
import { useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  ProgressBar,
  Row,
  Card,
} from "react-bootstrap";
import { toast } from "react-toastify";
import PageTitle from "../../components/PageTitle";
import TemplatesInsightModal from "./TemplatesInsightModal";
import moment from "moment";
import { useEffect } from "react";
import Api from "../../api/api";
import { useContext } from "react";
import { FilialContext } from "../../context/FilialContext";
import { useRef } from "react";
import EditModal from "./EditModal";
import CalendarDefaultDatesModal from "./CalendarDefaultDatesModal";
import { AuthContext } from "../../context/AuthContext";

const CalendarManagement = () => {
  const { permissionGroup } = useContext(AuthContext);
  const { selectedUnitId } = useContext(FilialContext);
  const [openTemplatesInsight, setOpenTemplatesInsight] = useState(false);
  const [date, setDate] = useState(null);
  const [loadingList, setLoadingList] = useState(false);
  const [list, setList] = useState([]);
  const [filter, setFilter] = useState({
    unit_id: selectedUnitId,
    start_date: moment().startOf("month").format("YYYY-MM-DD"),
    end_date: moment().endOf("month").format("YYYY-MM-DD"),
  });
  const calendarRef = useRef(null);
  const [openCalendarDefaultDates, setOpenCalendarDefaultDates] =
    useState(false);

  const handleDateClick = (arg) => {
    setDate(moment(arg.dateStr).format("YYYY-MM-DD"));
  };

  const handleEventClick = ({ el, event, jsEvent, view }) => {
    setDate(moment(event.startStr).format("YYYY-MM-DD"));
  };

  const getList = () => {
    setLoadingList(true);
    Api.get(`calendar_dates`, { params: filter })
      .then((res) => setList(res.data.list))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingList(false));
  };

  useEffect(() => {
    if (selectedUnitId)
      setFilter((prev) => ({
        ...prev,
        unit_id: selectedUnitId,
      }));
  }, [selectedUnitId]);

  useEffect(() => {
    if (filter.unit_id && filter.start_date && filter.end_date) getList();
    const interval = setInterval(() => {
      if (filter.unit_id && filter.start_date && filter.end_date) getList();
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [filter]);

  const prevBtn =
    document.getElementsByClassName("fc-button-group")[0]?.children[0];

  const nextBtn =
    document.getElementsByClassName("fc-button-group")[0]?.children[1];

  useEffect(() => {
    if (prevBtn)
      prevBtn.onclick = () => {
        const startDate = moment(
          calendarRef.current._calendarApi.view.currentStart
        )
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD");
        const endDate = moment(
          calendarRef.current._calendarApi.view.currentStart
        )
          .subtract(1, "month")
          .endOf("month")
          .format("YYYY-MM-DD");

        setList([]);

        setFilter((prev) => ({
          ...prev,
          start_date: startDate,
          end_date: endDate,
        }));
      };
    if (nextBtn)
      nextBtn.onclick = () => {
        const startDate = moment(
          calendarRef.current._calendarApi.view.currentStart
        )
          .add(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD");

        const endDate = moment(
          calendarRef.current._calendarApi.view.currentStart
        )
          .add(1, "month")
          .endOf("month")
          .format("YYYY-MM-DD");

        setList([]);

        setFilter((prev) => ({
          ...prev,
          start_date: startDate,
          end_date: endDate,
        }));
      };
  }, [prevBtn, nextBtn]);

  const events = [
    ...list
      .filter((date) => date.holiday)
      .map((date) => ({
        title: "Feriado",
        date: moment(date.date).format("YYYY-MM-DD"),
      })),
    ...list.map((date) => ({
      title: `${date.occupation_rate}% Ocupado`,
      date: moment(date.date).format("YYYY-MM-DD"),
    })),
    ...list
      .filter((date) => date.has_dp)
      .map((date) => ({
        title: `PCD`,
        date: moment(date.date).format("YYYY-MM-DD"),
      })),
  ];

  const dows = [1, 2, 3, 4, 5, 6];
  const dowNotSet =
    !loadingList &&
    dows.find(
      (dow) => !events.find((e) => Number(moment(e.date).format("e")) === dow)
    );

  useEffect(() => {
    document.getElementById("topbar")?.scrollIntoView();
  }, []);

  return (
    <>
      <PageTitle className="d-flex flex-column flex-md-row">
        <h2> Gerenciar Calendário</h2>
        <div className="d-flex align-items-center gap-1 flex-wrap">
          <Button
            className="align-self-center"
            onClick={() => setOpenTemplatesInsight(true)}
          >
            Relatório de Ocupação
          </Button>
          {["Administrador", "Coordenador", "Operador Qualidade"].includes(
            permissionGroup
          ) && (
            <Button
              variant="outline-primary"
              onClick={() => setOpenCalendarDefaultDates(true)}
            >
              Datas Padrão
            </Button>
          )}
        </div>
      </PageTitle>
      {openTemplatesInsight && (
        <TemplatesInsightModal
          open={openTemplatesInsight}
          setOpen={setOpenTemplatesInsight}
        />
      )}
      {date && (
        <EditModal
          date={date}
          open={!!date}
          setOpen={setDate}
          onSuccess={getList}
        />
      )}
      {openCalendarDefaultDates && (
        <CalendarDefaultDatesModal
          open={openCalendarDefaultDates}
          setOpen={setOpenCalendarDefaultDates}
        />
      )}
      <Container className="p-0 m-0">
        <Row>
          {dowNotSet && (
            <Col xs={12}>
              <Alert variant="danger">
                Atenção, os cabeçalhos do calendário que possuem cor de fundo
                vermelha demonstram que as datas da semana ainda não foram
                configuradas.
              </Alert>
            </Col>
          )}
          {loadingList && (
            <Col xs={12}>
              <ProgressBar animated now={100} />
            </Col>
          )}
          <Col xs={12}>
            <div className="w-100 overflow-auto p-1">
              <div style={{ minWidth: 600 }}>
                <Card>
                  <Card.Body>
                    <Calendar
                      plugins={[
                        dayGridPlugin,
                        interactionPlugin,
                        bootstrap5Plugin,
                      ]}
                      ref={calendarRef}
                      initialView="dayGridMonth"
                      themeSystem="bootstrap"
                      dateClick={handleDateClick}
                      eventClick={handleEventClick}
                      locale={"pt-br"}
                      headerToolbar={{
                        left: "",
                        center: "title",
                        right: "today prev,next",
                      }}
                      height={"75vh"}
                      dayHeaderClassNames={(header) => {
                        if (
                          dowNotSet &&
                          (!events[0] ||
                            !events.find(
                              (e) =>
                                Number(moment(e.date).format("e")) ===
                                Number(header.dow)
                            ))
                        )
                          return "bg-danger text-white";

                        return "";
                      }}
                      events={events}
                    />
                  </Card.Body>
                </Card>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CalendarManagement;
