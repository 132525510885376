import React, { useContext, useState, useEffect } from "react";
import Topbar from "./components/Topbar/TopBar";
import LeftSidebar from "./components/LeftsideBar/Leftside";
import { Col, Row } from "react-bootstrap";
import AllRoutes from "./routes";
import { AuthContext } from "./context/AuthContext";
import LoginPage from "./pages/Login";
import { useBootstrapBreakpoint } from "./utils/functions";
import { ToastContainer } from "react-toastify";

const App = (props) => {
  const { authenticated } = useContext(AuthContext);
  const [mobileVersion, setMobileVersion] = useState(false);
  const [openLeftSideBar, setOpenLeftSideBar] = useState(() => {
    const storedOpenLeftSideBar = localStorage.getItem("openLeftSideBar");
    return storedOpenLeftSideBar ? JSON.parse(storedOpenLeftSideBar) : false;
  });

  const breakpoint = useBootstrapBreakpoint();
  const largeBreakpoint = !["xxs", "xs", "sm", "md"].includes(breakpoint);

  useEffect(() => {
    localStorage.setItem("openLeftSideBar", JSON.stringify(openLeftSideBar));
  }, [openLeftSideBar]);

  if (!authenticated) return <LoginPage />;

  return (
    <>
      <ToastContainer
        autoClose={10000}
        bodyStyle={{ whiteSpace: "pre-wrap" }}
      />
      <Row className="g-0">
        <Col xs={12} style={{ minHeight: 60 }}>
          <Topbar
            setMobileVersion={setMobileVersion}
            mobileVersion={mobileVersion}
          />
        </Col>
        <Col
          xs={12}
          className="bobyContentBackgroundColor"
          style={{ minHeight: "100vh" }}
        >
          <div className="d-flex w-100 h-100">
            <div
              style={{
                display: !largeBreakpoint && mobileVersion ? "none" : "block",
              }}
            >
              <LeftSidebar
                open={openLeftSideBar}
                setOpen={setOpenLeftSideBar}
              />
            </div>
            <div
              className="p-1 p-md-3 overflow-hidden w-100 h-100"
              style={{
                marginLeft: largeBreakpoint ? (openLeftSideBar ? 270 : 72) : "",
              }}
            >
              <AllRoutes />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default App;
