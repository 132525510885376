import { HiLink, HiPencilSquare } from "react-icons/hi2";
import AddAttachmentModal from "./AddAttachmentModal";
import moment from "moment";
import { getTicketAttachmentType } from "../../../../utils/functions";

const { useState } = require("react");
const { Button } = require("react-bootstrap");

const SaleAttachments = ({ sale, onSuccess }) => {
  const [openAddAttachmentModal, setOpenAddAttachmentModal] = useState(false);

  const renderAttachment = (attachment) => {
    if (!attachment || !attachment.id) return <></>;

    return (
      <div
        key={`ticket_attachments_item_${attachment.id}`}
        className="border border-primary rounded p-1 px-2 d-flex align-items-center justify-content-between"
      >
        <div className="d-flex flex-column">
          <span className="fw-bold">
            {getTicketAttachmentType(attachment.type)}
          </span>
          <span>{moment(attachment.created_at).format("DD/MM/YY HH:mm")}</span>
        </div>
        <div className="d-flex align-items-center gap-1">
          <Button
            variant="outline-secondary"
            size="sm"
            className="rounded-circle"
            onClick={() => handleOpenAddAttachmentModal(attachment.id)}
            data-toggle="tooltip"
            title="Editar"
          >
            <HiPencilSquare />
          </Button>
          <Button
            variant="outline-primary"
            size="sm"
            className="rounded-circle"
            onClick={() => window.open(attachment.file_url)}
            data-toggle="tooltip"
            title="Abrir link"
          >
            <HiLink />
          </Button>
        </div>
      </div>
    );
  };

  const renderSaleAttachments = () => {
    if (!sale || !sale.ticket_attachments || !sale.ticket_attachments[0])
      return <span className="text-muted">Nenhum anexo na venda.</span>;

    return (
      <div className="d-flex flex-column gap-1">
        {sale.ticket_attachments.map((attachment) =>
          renderAttachment(attachment)
        )}
      </div>
    );
  };

  const handleOpenAddAttachmentModal = (transaction_id) => {
    setOpenAddAttachmentModal(transaction_id || true);
  };

  const onBirthdayPersonAdded = () => {
    setOpenAddAttachmentModal(false);
    if (onSuccess) onSuccess();
  };

  return (
    <>
      {openAddAttachmentModal && (
        <AddAttachmentModal
          open={!!openAddAttachmentModal}
          setOpen={setOpenAddAttachmentModal}
          onSuccess={onBirthdayPersonAdded}
          order_ticket_id={sale?.order_ticket_id}
          ticket_attachment_id={
            openAddAttachmentModal > 0 ? openAddAttachmentModal : null
          }
        />
      )}
      <span className="fs-5 text-primary fw-bold">Anexos</span>
      {renderSaleAttachments()}
      <Button
        className="fw-bold"
        variant={"outline-primary"}
        onClick={handleOpenAddAttachmentModal}
      >
        Adicionar anexo
      </Button>
    </>
  );
};

export default SaleAttachments;
