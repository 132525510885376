import { useContext, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import moment from "moment";
import Api from "../../api/api";
import { toast } from "react-toastify";
import { FilialContext } from "../../context/FilialContext";

const AvailableSpaces = ({ date }) => {
  const [data, setData] = useState([]);
  const { selectedUnitId } = useContext(FilialContext);

  const getData = () => {
    Api.get(`calendar_dates_hours`, {
      params: { unit_id: selectedUnitId, date },
    })
      .then((res) => setData(res.data.list))
      .catch((err) => toast.error(err.message));
  };

  useEffect(() => {
    if (date && selectedUnitId) getData();
  }, [date, selectedUnitId]);

  const renderItem = (item, className) => {
    return (
      <div className="d-flex flex-column align-items-center justify-content-center p-1">
        <span className="fw-bold">{item.time}</span>
        <div
          className={
            "text-center text-white fs-5 fw-bold rounded-pill w-100 p-1 px-3 " +
            (className || "")
          }
        >
          {item.spaces}
        </div>
      </div>
    );
  };

  const renderHeader = (title) => {
    return <span className="fw-bold fs-5">{title}</span>;
  };

  return (
    <div className="mt-4">
      <Card>
        <Card.Body>
          <Card.Title>
            {renderHeader(
              `Vagas restantes para Brincantes:${
                !data || !data[0] ? " sem dados para mostrar" : ""
              }`
            )}
          </Card.Title>
          <div className="d-flex justify-content-around s w-100 flex-wrap">
            {data.map((item) =>
              renderItem(
                {
                  time: moment(item.time, "HH:mm:SS").format("HH:mm"),
                  spaces: item.available_spaces,
                },
                "bg-success"
              )
            )}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default AvailableSpaces;
