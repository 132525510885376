import React, { useContext, useState, useEffect } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { ImExit } from "react-icons/im";

import pophausLogo from "../../assets/images/logoPophaus2.png";
import { FilialContext } from "../../context/FilialContext";
import { AuthContext } from "../../context/AuthContext";
import { useBootstrapBreakpoint } from "../../utils/functions";
import { CiMenuBurger, CiMenuFries } from "react-icons/ci";

const Topbar = ({ setMobileVersion, mobileVersion }) => {
  const breakpoint = useBootstrapBreakpoint();
  const largeBreakpoint = !["xxs", "xs", "sm", "md"].includes(breakpoint);
  
  const { filial, setFilial } = useContext(FilialContext);
  const { name, logout, userUnities } = useContext(AuthContext);
  
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const openMenu = () => {
    setIsMenuOpened((prevState) => !prevState);
    if (document.body) {
      if (isMenuOpened) {
        document.body.classList.add("sidebar-enable");
      } else {
        document.body.classList.remove("sidebar-enable");
      }
    }
  };
  function handleTitleFilial(filial) {
    if (filial === "TATUAPE") {
      return "Tatuapé";
    } else if (filial === "BRASILIA") {
      return "Brasília";
    } else {
      return "Santo Amaro";
    }
  }

  useEffect(() => {
    if(largeBreakpoint) {
      setMobileVersion(!mobileVersion)
    }
  }, []);

  const HandleClick = () => {
    setMobileVersion(!mobileVersion);
  };

  return (
    <div
      className="navbar-custom"
      id="topbar"
      style={{
        left: 0,
        right: 0,
        position: "fixed",
        height: 60,
      }}
    >
      <div
        className="float-end"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          gap: 12,
        }}
      >
        {largeBreakpoint && (
          <>
            {userUnities[0] ? (
              userUnities.map((unit) => (
                <Button
                  variant={filial === unit ? "secondary" : "outline-secondary"}
                  onClick={() => setFilial(unit)}
                  key={`unit_desk_${unit}`}
                >
                  {handleTitleFilial(unit)}
                </Button>
              ))
            ) : (
              <>
                <Button
                  variant={
                    filial === "SANTO_AMARO" ? "secondary" : "outline-secondary"
                  }
                  onClick={() => setFilial("SANTO_AMARO")}
                  key={`unit_desk_${"SANTO_AMARO"}`}
                >
                  {handleTitleFilial("SANTO_AMARO")}
                </Button>
                <Button
                  variant={
                    filial === "TATUAPE" ? "secondary" : "outline-secondary"
                  }
                  onClick={() => setFilial("TATUAPE")}
                  key={`unit_desk_${"TATUAPE"}`}
                >
                  {handleTitleFilial("TATUAPE")}
                </Button>
                <Button
                  variant={
                    filial === "BRASILIA" ? "secondary" : "outline-secondary"
                  }
                  onClick={() => setFilial("BRASILIA")}
                  key={`unit_desk_${"BRASILIA"}`}
                >
                  {handleTitleFilial("BRASILIA")}
                </Button>
              </>
            )}
          </>
        )}
        <Dropdown className="mx-auto">
          <Dropdown.Toggle className="cursor-pointer btn-sm">
            {largeBreakpoint ? "USUÁRIO" : "MENU"}
          </Dropdown.Toggle>
          <Dropdown.Menu className="user-pro-dropdown">
            <div className="d-flex justify-content-between ml-3">
              <span>{name}</span>
            </div>
            <div
              className="dropdown-item notify-item cursor-pointer"
              onClick={logout}
            >
              <ImExit className="me-2" />
              <span>Logout</span>
            </div>
            {!largeBreakpoint && (
              <div className="d-flex align-items-center justify-content-center gap-1 flex-wrap">
                {userUnities[0] ? (
                  userUnities.map((unit) => (
                    <Button
                      variant={
                        filial === unit ? "secondary" : "outline-secondary"
                      }
                      key={`unit_mob_${unit}`}
                    >
                      <span onClick={() => setFilial(unit)}>
                        {handleTitleFilial(unit)}
                      </span>
                    </Button>
                  ))
                ) : (
                  <>
                    <Button
                      variant={
                        filial === "SANTO_AMARO"
                          ? "secondary"
                          : "outline-secondary"
                      }
                      key={`unit_mob_${"SANTO_AMARO"}`}
                    >
                      <span onClick={() => setFilial("SANTO_AMARO")}>
                        {handleTitleFilial("SANTO_AMARO")}
                      </span>
                    </Button>
                    <Button
                      variant={
                        filial === "TATUAPE" ? "secondary" : "outline-secondary"
                      }
                      key={`unit_mob_${"TATUAPE"}`}
                    >
                      <span onClick={() => setFilial("TATUAPE")}>
                        {handleTitleFilial("TATUAPE")}
                      </span>
                    </Button>
                    <Button
                      variant={
                        filial === "BRASILIA"
                          ? "secondary"
                          : "outline-secondary"
                      }
                      key={`unit_mob_${"BRASILIA"}`}
                    >
                      <span onClick={() => setFilial("BRASILIA")}>
                        {handleTitleFilial("BRASILIA")}
                      </span>
                    </Button>
                  </>
                )}
              </div>
            )}
          </Dropdown.Menu>
        </Dropdown>
        {!largeBreakpoint && (
          <div onClick={HandleClick} className="text-white">
            {!mobileVersion ? (
              <CiMenuFries className="bi bi-fries text-white fs-4" />
              ) : (
              <CiMenuBurger className="bi bi-list text-white fs-4" />
            )}
          </div>
        )}
      </div>
      <div
        className="float-start"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          gap: largeBreakpoint ? 12 : 4,
        }}
      >
        <>
          <img src={pophausLogo} style={{ maxHeight: "100%" }} alt="" />
          {largeBreakpoint && (
            <span
              className={`${largeBreakpoint ? "fs-3" : ""} fw-bold text-white`}
            >
              {breakpoint !== "xxs" ? "Pophaus " : ""}
              {handleTitleFilial(filial)}
            </span>
          )}
        </>
      </div>
    </div>
  );
};

export default Topbar;
