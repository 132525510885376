import React, { useEffect, useState, useContext } from "react";
import moment from "moment";
import { Row, Col, Button, ProgressBar, Spinner } from "react-bootstrap";
import { CustomCard } from "./CustomCard";
import { FilialContext } from "../../../context/FilialContext";
import Api from "../../../api/api";

const CardData = ({ setObjComparison, finalData }) => {
  const { selectedUnitId } = useContext(FilialContext);
  const [data, setData] = useState({});
  const [apiFinished, setApiFinished] = useState(false); // Estado para controlar se a chamada da API está concluída
  const [loading, setLoading] = useState(false);

  const [filter, setFilter] = useState({
    start_date: moment().subtract(7, "days").format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
  });

  useEffect(() => {
    if (selectedUnitId) {
      getData();
    }
  }, [filter, selectedUnitId]);

  useEffect(() => {
    if (finalData !== undefined && apiFinished) {
      setData(finalData);
    }
  }, [finalData, apiFinished]);

  const getData = () => {
    setLoading(true);
    Api.get(`user/points/report`, {
      params: { ...filter, unit_id: selectedUnitId },
    })
      .then((res) => {
        setData(res.data.data);
        setObjComparison(res.data.data);
        setApiFinished(true);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  const handleFilterChange = (e) => {
    setFilter((prev) => ({
      ...prev,
      [e.target.name]: moment(e.target.value).isValid()
        ? moment(e.target.value).format("YYYY-MM-DD")
        : "",
    }));
  };

  return (
    <>
      <div className="d-flex" style={{ gap: 15 }}>
        <div>
          <label className="ml-2 mb-0 color-gray" htmlFor="">
            Data inicial
          </label>
          <input
            type="date"
            name="start_date"
            id=""
            className="form-control"
            style={{ width: 160 }}
            value={filter.start_date}
            onChange={handleFilterChange}
          />
        </div>
        <div>
          <label className="ml-2 mb-0" htmlFor="">
            Data final
          </label>
          <input
            type="date"
            name="end_date"
            id=""
            className="form-control"
            style={{ width: 160 }}
            value={filter.end_date}
            onChange={handleFilterChange}
          />
        </div>
      </div>
      {loading && <ProgressBar className="mt-3" animated now={100} />}
      <Row className="mt-3">
        <>
          <Col xs={12} md={6} lg={4}>
            <CustomCard data={data.voucher} titleName="Voucher" />
          </Col>
          <Col xs={12} md={6} lg={4}>
            <CustomCard data={data.fidelity} titleName="Fidelidade" />
          </Col>
          <Col xs={12} md={6} lg={4}>
            <CustomCard data={data.total} titleName="Total" />
          </Col>
        </>
      </Row>
    </>
  );
};

export function PointsProgramData() {
  const [hasComparison, setHasComparison] = useState(false);
  const [objData, setObjData] = useState({});
  const [objDataComparison, setObjDataComparison] = useState({});

  const handleComparison = () => {
    setHasComparison((prev) => !prev);
  };

  const isObjectEmpty = (obj) => {
    return Object.keys(obj).length !== 0;
  };

  let finalData = {};
  if (isObjectEmpty(objDataComparison)) {
    function compareObjects(obj1, obj2) {
      const comparação = {};

      for (const key in obj1) {
        comparação[key] = obj1[key].map((item, index) => {
          const value1 = item.value;
          const value2 = obj2[key][index].value;

          const diferencaPercentual = ((value2 - value1) / value1) * 100;

          return {
            value_percent: diferencaPercentual.toFixed(2) + "%",
          };
        });
      }

      return comparação;
    }

    function joinObjects(primaryData, secondaryData) {
      // Criar um novo objeto para armazenar os dados unidos
      const result = {};

      // Iterar sobre as chaves do objeto principal
      for (const key in primaryData) {
        // Verificar se a chave existe no objeto secundário
        if (secondaryData.hasOwnProperty(key)) {
          // Unir os arrays de objetos usando map para adicionar a propriedade value_percent
          result[key] = primaryData[key].map((item, index) => {
            return {
              ...item,
              value_percent: secondaryData[key][index].value_percent,
            };
          });
        } else {
          // Se a chave não existir no objeto secundário, manter os dados do objeto principal intactos
          result[key] = primaryData[key];
        }
      }

      return result;
    }

    finalData = joinObjects(
      objDataComparison,
      compareObjects(objData, objDataComparison)
    );
  }

  return (
    <>
      <CardData setObjComparison={setObjData} />
      <Button
        variant="primary"
        className="mb-3"
        onClick={() => handleComparison()}
      >
        Comparação por período
      </Button>
      {hasComparison && (
        <>
          <div className="mt-3">
            <CardData
              setObjComparison={setObjDataComparison}
              finalData={finalData}
            />
          </div>
        </>
      )}
    </>
  );
}
