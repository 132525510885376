import React, { createContext, useEffect, useState } from "react";
import Api from "../api/api";
import { decodeToken, isExpired } from "react-jwt";
import { handleModuleRoute } from "../utils/modules";
import { useNavigate } from "react-router-dom";

export const AuthContext = createContext();

export default function AuthProvider({ children }) {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const decodedTokenm = decodeToken(token);
  const isExpiredToken = isExpired(token);
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("");
  const [emailLogged, setEmailLogged] = useState("");
  const [dashboardId, setDashboardId] = useState("");
  const [allowedModules, setAllowedModules] = useState([]);
  const [userUnities, setUserUnities] = useState([]);
  const [permissionGroup, setPermissionGroup] = useState(null);
  const [department, setDepartment] = useState(null);
  const [loadModules, setLoadModules] = useState(null);

  const logout = () => {
    setAuthenticated(false);
    Api.defaults.headers.Authorization = undefined;
    localStorage.removeItem("token");
    localStorage.removeItem("name");
    localStorage.removeItem("dashboard_id");
    localStorage.removeItem("email");
    setDashboardId("");
    setName("");
    setEmailLogged("");
    setAllowedModules([]);
    navigate("/");
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      if (decodeToken && !isExpiredToken) {
        setAuthenticated(true);
        Api.defaults.headers.Authorization = `Bearer ${token}`;
        const nameToken = localStorage.getItem("name");
        const email = localStorage.getItem("email");
        const dashboardIdToken = localStorage.getItem("dashboard_id");
        setDashboardId(dashboardIdToken);
        setEmailLogged(email);
        setName(nameToken);
      } else {
        logout();
        navigate("/login");
      }
    }
    setLoading(false);
  }, [decodedTokenm, isExpiredToken]);

  const getUserModules = () => {
    setLoadModules(true);
    Api.get(`/users_permission_group/${dashboardId}`)
      .then((res) => {
        if (res?.data?.data) {
          const allowed_modules =
            res.data.data.permission_group_allowed_modules &&
            res.data.data.permission_group_allowed_modules[0]
              ? res.data.data.permission_group_allowed_modules
                  .map(
                    (item) =>
                      (!res.data.data.department_allowed_modules && item) ||
                      res.data.data.department_allowed_modules.find(
                        (mod) => mod && item && mod === item
                      )
                  )
                  .filter((item) => item)
              : (res.data.data.department_allowed_modules || [])
                  .map((mod) =>
                    res.data.data.permission_group_allowed_modules.find(
                      (item) => item && mod && item === mod
                    )
                  )
                  .filter((item) => item);

          setAllowedModules(allowed_modules);
          setPermissionGroup(res.data.data.permission_group_name);
          setDepartment(res.data.data.department_name);

          if (
            allowed_modules[0] &&
            !allowed_modules.find(
              (mod) => handleModuleRoute(mod) === window.location.pathname
            )
          )
            navigate(handleModuleRoute(allowed_modules[0]));
        }
      })
      .catch((err) => {
        alert(err.message);
        logout();
      })
      .finally(() => setLoadModules(false));
  };

  const getUserUnities = () => {
    Api.get(`/dashboard/user/${dashboardId}`).then((res) => {
      if (res.data[0]?.unities && res.data[0]?.unities[0])
        setUserUnities(res.data[0]?.unities);
      else setUserUnities([]);
    });
  };

  useEffect(() => {
    if (dashboardId) {
      getUserModules();
      getUserUnities();
    }
  }, [dashboardId]);

  if (loading || loadModules)
    return (
      <div className="position-absolute top-0 left-0 right-0 bottom-0 d-flex w-100 h-100 align-items-center justify-content-center bg-primary">
        <h1 style={{ color: "white" }}>Loading</h1>
      </div>
    );

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        setAuthenticated,
        logout,
        name,
        setName,
        dashboardId,
        setDashboardId,
        setEmailLogged,
        emailLogged,
        allowedModules,
        getUserModules,
        userUnities,
        permissionGroup,
        department,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
