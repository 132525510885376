import React, { useContext } from "react";
import { Button, ListGroup, ProgressBar } from "react-bootstrap";
import { BiSearchAlt } from "react-icons/bi";
import { useState } from "react";
import Row from "./Row";
import { FilialContext } from "../../context/FilialContext";

const List = ({ list, total, setSelectedItem, load, filter, setFilter }) => {
  const { selectedUnitId } = useContext(FilialContext);
  const [search, setSearch] = useState("");

  const handleNewItem = () => {
    const defaultData = {
      name: "",
      avatar: "",
      display: false,
      unit_id: selectedUnitId,
      mandatory_on_sale: false,
      on_sale: false,
    };

    setSelectedItem(defaultData);
  };

  const handleSearch = () => {
    setFilter((prev) => ({ ...prev, search }));
  };

  const onSearchKeyDown = (e, x) => {
    const enter = 13;
    if (e.keyCode === enter) handleSearch();
  };

  return (
    <>
      <div className="d-flex gap-1 mb-1 flex-wrap align-items-center justify-content-center align-items-sm-start justify-content-sm-start">
        <span className="fs-5">Lista de áreas do parque:</span>
        <div className="input-group w-auto">
          <input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="form-control"
            placeholder="Pesquisar área"
            onKeyDown={onSearchKeyDown}
          />
          <span
            className="input-group-text cursor-pointer"
            onClick={handleSearch}
          >
            <BiSearchAlt />
          </span>
        </div>
        <Button className="ml-sm-auto" onClick={handleNewItem}>
          Nova Área
        </Button>
      </div>
      {load && <ProgressBar animated now={100} className="mb-1" />}
      {!list[0] && !load && (
        <p style={{ textAlign: "center" }}>
          Não há áreas cadastradas nesta unidade.
        </p>
      )}
      <ListGroup className="rounded-box gap-1">
        {list.map((item, index) => (
          <Row
            key={"park_area_" + item.id}
            item={item}
            setSelectedItem={setSelectedItem}
          />
        ))}
      </ListGroup>
    </>
  );
};

export default List;
