import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import { ProgressBar, InputGroup, Form, Button, Modal } from "react-bootstrap";
import DepartmentModulesList from "./DepartmentModules/List";
import Api from "../../api/api";

const Edit = ({
  item,
  getList,
  setItem,
  shouldCreateGroup,
  setShouldCreateGroup,
}) => {
  const [load, setLoad] = useState(false);
  const [values, setValues] = useState({ ...(item || { name: "" }) });
  const [loadSave, setLoadSave] = useState(false);

  if (typeof window !== "undefined") {
    injectStyle();
  }

  useEffect(() => {
    setValues({ ...(item || { name: "" }) });
  }, [item]);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const save = () => {
    setShouldCreateGroup(false);
    if (values.id) {
      update();
    } else {
      create();
    }
  };

  const create = () => {
    setLoadSave(true);
    Api.post(`departments`, values)
      .then((res) => {
        setLoadSave(false);
        getList();
        setItem({ ...res.data.data });
      })
      .catch((err) => {
        setLoadSave(false);
        toast.error(err.message);
      });
  };

  const update = () => {
    setLoadSave(true);
    Api.put(`departments/${values.id}`, values)
      .then((res) => {
        setLoadSave(false);
        getList();
        setItem({ ...res.data.data });
      })
      .catch((err) => {
        setLoadSave(false);
        toast.error(err.message);
      });
  };

  const handleCloseModal = (event) => {
    setShouldCreateGroup(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (values.name.trim() === "") {
        toast.warning("Digite o nome do Departamento");
      } else {
        save();
      }
    }
  };

  const renderGroupNameInput = () => {
    if (!values.id) {
      return (
        <>
          <label>Nome do Departamento</label>
          <input
            autoFocus
            className="form-control"
            type="text"
            name="name"
            value={values.name}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            placeholder={"Digite o nome..."}
          />
          <br />
        </>
      );
    }
    return null;
  };

  return (
    <Modal show={shouldCreateGroup} onHide={handleCloseModal}>
      {load && <ProgressBar animated now={100} />}
      <Modal.Header closeButton>
        <Modal.Title>
          {values.id ? "Editar Departamento" : "Novo Departamento"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>{renderGroupNameInput()}</div>
        {values.id && <DepartmentModulesList department={item} />}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={save} disabled={loadSave}>
          Cadastrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Edit;
