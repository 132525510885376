import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const SimulatorMetricsWeekDayTotalizerChart = ({ data }) => {
  const labels = [
    "Domingo",
    "Segunda",
    "Terça",
    "Quarta",
    "Quinta",
    "Sexta",
    "Sábado",
  ];

  const getValue = (field, week_day) => {
    return data
      .map((item) =>
        Number(item.week_day) === Number(week_day) ? item[field] : 0
      )
      .reduce((acum, item) => (acum = acum + Number(item)), 0);
  };

  const getTooltipLabel = (item) => {
    if (item.datasetIndex === 0) return `Iniciaram: ${item.formattedValue}`;
    if (item.datasetIndex === 1)
      return `Terminaram: ${item.formattedValue} (${(
        (getValue("finished", item.dataIndex) /
          getValue("simulations", item.dataIndex)) *
        100
      ).toFixed(2)}%)`;
    if (item.datasetIndex === 2)
      return `Enviaram para agendamento: ${item.formattedValue} (${(
        (getValue("sent_schedule", item.dataIndex) /
          getValue("simulations", item.dataIndex)) *
        100
      ).toFixed(2)}%)`;
    if (item.datasetIndex === 3)
      return `Querem tirar dúvidas: ${item.formattedValue} (${(
        (getValue("sent_doubts", item.dataIndex) /
          getValue("simulations", item.dataIndex)) *
        100
      ).toFixed(2)}%)`;
    if (item.datasetIndex === 4) {
      return `Não terminaram: ${item.formattedValue} (${(
        (getValue("unfinished", item.dataIndex) /
          getValue("simulations", item.dataIndex)) *
        100
      ).toFixed(2)}%)`;
    }
    if (item.datasetIndex === 5)
      return `Oportunidade perdida: ${item.formattedValue} (${(
        (getValue("finished_didnt_sent", item.dataIndex) /
          getValue("simulations", item.dataIndex)) *
        100
      ).toFixed(2)}%)`;
  };

  return (
    <Bar
      options={{
        type: "bar",
        plugins: {
          tooltip: {
            callbacks: { label: getTooltipLabel },
          },
        },
        scales: {
          yAxis: {
            type: "linear",
            ticks: {
              stepSize: 1,
            },
          },
        },
      }}
      data={{
        labels,
        datasets: [
          {
            data: [...data.map((item) => item.simulations)],
            backgroundColor: "#00f",
            label: "Iniciaram",
          },
          {
            data: [...data.map((item) => item.finished)],
            backgroundColor: "#F47D41",
            label: "Terminaram",
          },
          {
            data: [...data.map((item) => item.sent_schedule)],
            backgroundColor: "#6F3B8C",
            label: "Enviaram para agendamento",
          },
          {
            data: [...data.map((item) => item.sent_doubts)],
            backgroundColor: "#FDC028",
            label: "Querem tirar dúvidas",
          },
          {
            data: [...data.map((item) => item.unfinished)],
            backgroundColor: "#aaa",
            label: "Não terminaram",
          },
          {
            data: [...data.map((item) => item.finished_didnt_sent)],
            backgroundColor: "#f00",
            label: "Oportunidade perdida",
          },
        ],
      }}
    />
  );
};

export default SimulatorMetricsWeekDayTotalizerChart;
