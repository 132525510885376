import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const MonthlyPartiesWeekChart = ({ data }) => {
  const labels = data.map((item) => "Semana " + item.month_week);

  const getTooltipLabel = (item) => {
    return `Festas Vendidas: ${item.raw}`;
  };

  const getTooltipFooter = (item) => {
    return `Faturamento Total: ${data[
      item[0].dataIndex
    ].ticket_total.toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    })}`;
  };

  return (
    <Bar
      options={{
        type: "bar",
        plugins: {
          tooltip: {
            callbacks: { label: getTooltipLabel, footer: getTooltipFooter },
          },
          legend: { display: false },
        },
      }}
      data={{
        labels,
        datasets: [
          {
            data: data.map((item) => item.total),
            backgroundColor: "#6F3B8CCC",
          },
        ],
      }}
    />
  );
};

export default MonthlyPartiesWeekChart;
