import React, { useEffect, useState, useContext } from "react";
import { toast } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import ProgressBar from "react-bootstrap/ProgressBar";
import Api from "../../../api/api";
import EditDoubtModal from "./EditDoubtModal";
import Row from "./Row";
import { Button, Form, ListGroup, Pagination } from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FilialContext } from "../../../context/FilialContext";

const List = ({ doubtCategory }) => {
  const { selectedUnitId } = useContext(FilialContext);
  const [list, setList] = useState([]);
  const [total, setTotal] = useState([]);
  const [filter, setFilter] = useState({ limit: 5, page: 0 });
  const [load, setLoad] = useState(false);
  const [loadReordering, setLoadReordering] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(null);
  const [itemList, setItemList] = useState([]);
  const [reordering, setReordering] = useState(false);

  if (typeof window !== "undefined") {
    injectStyle();
  }

  const getList = () => {
    setLoad(true);
    Api.get(`doubts`, {
      params: {
        ...(reordering ? {} : filter),
        doubt_category_id: doubtCategory.id,
        unit_id: selectedUnitId,
      },
    })
      .then((res) => {
        setLoad(false);
        setList(res.data.list);
        if (reordering) setItemList(res.data.list);
        setTotal(res.data.total);
      })
      .catch((err) => {
        setLoad(false);
        toast.error(err.message);
      });
  };

  useEffect(() => {
    if (doubtCategory?.id && selectedUnitId) getList();
  }, [doubtCategory, filter, reordering, selectedUnitId]);

  const handleDrop = (droppedItem) => {
    if (!droppedItem.destination) return;
    var updatedList = [...itemList];
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    setItemList(updatedList);
  };

  const toggleReordering = () => {
    setItemList([]);
    setList([]);
    setTotal(0);
    setReordering((prev) => !prev);
  };

  const reorder = () => {
    setLoadReordering(true);

    const orderList = itemList.map((item, index) => ({
      id: item.id,
      item_order: index + 1,
    }));

    Api.post("doubts/reorder", orderList)
      .then((res) => {
        toast.success("Ordem das dúvidas atualizada com sucesso!");
        setReordering(false);
        getList();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadReordering(false));
  };

  const getPagesCount = () => {
    let count = Math.floor(Number(Number(total) / Number(filter.limit)));

    return count + 1;
  };

  const getPaginationItems = () => {
    const items = [];

    for (let index = 0; index < getPagesCount(); index++) {
      const currentPage = index + 1;
      items.push(
        <Pagination.Item
          onClick={() => setFilter((prev) => ({ ...prev, page: index }))}
          active={index === filter.page}
        >
          {currentPage}
        </Pagination.Item>
      );
    }

    return items;
  };

  const handleLimitChange = (e) => {
    setFilter((prev) => ({ ...prev, limit: e.target.value }));
  };

  return (
    <div className="d-flex flex-column gap-1">
      <span style={{ display: "flex", alignItems: "center" }}>
        Lista de dúvidas cadastradas na categoria:
      </span>
      <EditDoubtModal
        open={openEditModal ? true : false}
        setOpen={setOpenEditModal}
        getList={getList}
        item={openEditModal}
        doubtCategory={doubtCategory}
      />
      {load && <ProgressBar animated now={100} className="mb-1" />}
      {!list[0] && !load && (
        <p style={{ textAlign: "center" }}>
          Não há dúvidas cadastradas nesta categoria.
        </p>
      )}
      {!reordering ? (
        <>
          <ListGroup className="rounded-box gap-1">
            {list.map((item) => (
              <Row
                key={"doubt_" + item.id}
                item={item}
                setSelectedItem={setOpenEditModal}
              />
            ))}
          </ListGroup>
          <Pagination className="d-flex align-items-center mt-1">
            {getPaginationItems()}
            <Form.Select
              value={filter.limit}
              onChange={handleLimitChange}
              className="w-auto ml-1"
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
            </Form.Select>
          </Pagination>
        </>
      ) : (
        <>
          <span>Arraste um item para alterar a ordem.</span>
          <DragDropContext onDragEnd={handleDrop}>
            <Droppable droppableId="list-container">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="d-flex flex-column gap-1"
                >
                  {itemList.map((item, index) => (
                    <Draggable
                      key={"doubt_" + item.id}
                      draggableId={"doubt_" + item.id}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.dragHandleProps}
                          {...provided.draggableProps}
                        >
                          <Row reordering item={item} />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </>
      )}
      {!reordering ? (
        <Button
          onClick={toggleReordering}
          className="align-self-end mt-1"
          variant="outline-primary"
        >
          Alterar ordem
        </Button>
      ) : (
        <div className="d-flex gap-1 align-self-end mt-1">
          <Button
            onClick={toggleReordering}
            variant="light"
            disabled={loadReordering}
          >
            Cancelar
          </Button>
          {itemList[0] && (
            <Button
              onClick={reorder}
              variant="primary"
              disabled={loadReordering}
            >
              Salvar ordem
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default List;
