import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import Api from "../../api/api";
import "../../pages/Buffets/styles.scss";

export const ManageGroup = ({
  editedModalOpen,
  setEditedModalOpen,
  dataModalManager,
  setLoadRemove,
  getList
}) => {
  const [itemName, setItemName] = useState(dataModalManager?.name);

  const handleDeleteGroup = (id) => {
    const confirmDelete = window.confirm(
      `Tem certeza de que deseja deletar o ${dataModalManager?.name}?`
    );

    if (confirmDelete) {
      toast.warning(`Deletando ${dataModalManager?.name}.`);
      Api.delete(`departments/${dataModalManager.id}`)
        .then((res) => {
          setLoadRemove(null);
          getList();
          setEditedModalOpen(false)
          toast.success("Departamento removido com sucesso!");
        })
        .catch((err) => {
          setLoadRemove(null);
          toast.error(err.message);
        });
    }
  };

  const handleUpdateGroup = () => {
    if (itemName === dataModalManager?.name) {
      toast.error(
        "Por favor, altere o valore do campo Nome do Departamento"
      );
      return;
    }

    const confirmUpdate = window.confirm(
      "Tem certeza de que deseja atualizar nome do Departamento?"
    );

    if (confirmUpdate) {
      const updatedGroup = {
        unit_id: dataModalManager.unit_id,
        name: itemName,
      };
      Api.put(`departments/${dataModalManager.id}`, updatedGroup)
        .then((res) => {
          getList();
          toast.success("Departamento atualizado com sucesso!");
          setEditedModalOpen(false)
        })
        .catch((err) => {
          toast.error(err.message);
        });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (itemName) {
        handleUpdateGroup();
      } else {
        toast.error("Por favor, preencha todos os campos.");
      }
    }
  };

  return (
    <Modal show={editedModalOpen} onHide={() => setEditedModalOpen(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Editar Departamento</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <label>Nome do Departamento:</label>
        <input
          type="text"
          className="form-control"
          value={itemName}
          onChange={(e) => setItemName(e.target.value)}
          onKeyDown={handleKeyDown}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={() => handleDeleteGroup(dataModalManager.id)}
        >
          Deletar
        </Button>
        <Button variant="primary" onClick={handleUpdateGroup}>
          Atualizar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
