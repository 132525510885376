import React, { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import { Card } from "react-bootstrap";
import { injectStyle } from "react-toastify/dist/inject-style";
import Api from "../../api/api";
import List from "./List";
import EditModal from "./EditModal";
import moment from "moment";

const Promotions = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [filter, setFilter] = useState({
    limit: 10,
    page: 0,
    start_date: moment().subtract(6, "days").format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
  });
  const [list, setList] = useState([]);
  const [loadList, setLoadList] = useState(false);
  const [total, setTotal] = useState(0);

  if (typeof window !== "undefined") {
    injectStyle();
  }

  const getList = () => {
    setLoadList(true);
    Api.get(`promotions?${new URLSearchParams(filter)}`)
      .then((res) => {
        setList(res.data.list);
        setTotal(res.data.total);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setLoadList(false);
      });
  };

  useEffect(() => {
    getList();
  }, [filter]);

  useEffect(() => {
    document.getElementById("topbar")?.scrollIntoView();
  }, []);

  return (
    <>
      <PageTitle>
        <h2>Promoções</h2>
      </PageTitle>
      {selectedItem && (
        <EditModal
          open={selectedItem ? true : false}
          setOpen={setSelectedItem}
          item={selectedItem}
          getList={getList}
        />
      )}
      <Container fluid>
        <Row>
          <Col xs="12">
            <Card>
              <Card.Body>
                <List
                  list={list}
                  total={total}
                  load={loadList}
                  setSelectedItem={setSelectedItem}
                  filter={filter}
                  setFilter={setFilter}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Promotions;
