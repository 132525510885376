import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import ProgressBar from "react-bootstrap/ProgressBar";
import Card from "react-bootstrap/Card";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import DoubtsList from "./DoubtsList/List";
import Api from "../../api/api";

const Edit = ({ item, getList, setItem }) => {
  const [load, setLoad] = useState(false);
  const [values, setValues] = useState({ ...(item || { name: "" }) });
  const [loadSave, setLoadSave] = useState(false);

  if (typeof window !== "undefined") {
    injectStyle();
  }

  useEffect(() => {
    setValues({ ...(item || { name: "" }) });
  }, [item]);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const save = () => {
    if (values.id) return update();
    create();
  };

  const create = () => {
    setLoadSave(true);
    Api.post(`${process.env.REACT_APP_BASE_URL}doubts_categories`, values)
      .then((res) => {
        setLoadSave(false);
        getList();
        setItem({ ...res.data.data });
      })
      .catch((err) => {
        setLoadSave(false);
        toast.error(err.message);
      });
  };

  const update = () => {
    setLoadSave(true);
    Api.put(
      `${process.env.REACT_APP_BASE_URL}doubts_categories/${values.id}`,
      values
    )
      .then((res) => {
        setLoadSave(false);
        getList();
        setItem({ ...res.data.data });
      })
      .catch((err) => {
        setLoadSave(false);
        toast.error(err.message);
      });
  };

  return (
    <div className="d-flex flex-column gap-1">
      <span className="fs-5">Detalhes da Categoria de Dúvidas:</span>
      {load && <ProgressBar animated now={100} />}
      <Card style={styles.card} className="rounded-box">
        <Card style={styles.card} className="rounded-box">
          <InputGroup className="mb-3">
            <InputGroup.Text>Nome da Categoria</InputGroup.Text>
            <Form.Control
              value={values.name}
              name={"name"}
              onChange={handleChange}
            />
          </InputGroup>

          <Button disabled={loadSave} onClick={save}>
            Salvar
          </Button>
        </Card>

        {values.id && <DoubtsList doubtCategory={item} />}
      </Card>
    </div>
  );
};

const styles = {
  card: { width: "100%", padding: 4 },
};

export default Edit;
