import React from "react";
import { Col, Row } from "react-bootstrap";

const PageTitle = ({ title, children, className }) => {
  return (
    <Row>
      <Col>
        <div
          className={
            "page-title-box d-flex justify-content-between mt-3 mb-3 " +
            className
          }
        >
          {children}
        </div>
      </Col>
    </Row>
  );
};
export default PageTitle;
