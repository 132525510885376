import { toast } from "react-toastify";
import Api from "../../../api/api";
import { useContext, useEffect, useState } from "react";
import { FilialContext } from "../../../context/FilialContext";
import moment from "moment";
import { getTicketType } from "../../../utils/functions";
import { Button, ProgressBar } from "react-bootstrap";
import AddBundleProductModal from "./AddBundleProductModal";
import { HiTrash } from "react-icons/hi2";

const BundleProducts = ({ bundle_id, onProductsTotalValueChange }) => {
  const { selectedUnitId } = useContext(FilialContext);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [openEditBundleProductModal, setOpenEditBundleProductModal] =
    useState(null);
  const [productsList, setProductsList] = useState([]);
  const [openAddProductModal, setOpenAddProductModal] = useState(false);
  const [loadingTicket, setLoadingTicket] = useState(false);
  const [ticket, setTicket] = useState(null);
  const [loadingRemoveBundleProduct, setLoadingRemoveBundleProduct] =
    useState(false);

  const loadingLocked = loadingProducts || loadingTicket;

  const getProducts = () => {
    setLoadingProducts(true);
    Api.get(`bundle/products/${bundle_id}`)
      .then((res) => {
        if (res?.data[0]) return setProductsList(res.data);
        toast.warning("Este pacote ainda não possui produtos!");
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingProducts(false));
  };

  const getTicket = () => {
    setLoadingTicket(true);
    Api.get(`bundle/ticket/${bundle_id}`)
      .then((res) => {
        if (res.data) return setTicket(res.data);
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingTicket(false));
  };

  useEffect(() => {
    if (bundle_id > 0) {
      getProducts();
      getTicket();
    }
  }, [bundle_id]);

  const handleRemoveBundleProduct = (bundle_product_id) => {
    if (window.confirm("Deseja realmente remover este produto do pacote?")) {
      setLoadingRemoveBundleProduct(bundle_product_id);
      Api.delete(`bundles_products/${bundle_product_id}`)
        .then(() => {
          toast.success("Produto removido do pacote com sucesso!");
        })
        .catch((err) => toast.error(err.message))
        .finally(() => {
          setLoadingRemoveBundleProduct(false);
          getProducts();
        });
    }
  };

  const renderProduct = (product) => {
    return (
      <div
        key={`bundle_prod_item_${product.id}`}
        className="border border-primary bg-primary rounded text-white p-1 px-2 d-flex align-items-center justify-content-between"
      >
        <span className="fw-bold">{`${product.name} x${
          product.min_amount > 0
            ? product.min_amount
            : product.charge_type === "participants"
            ? Number(ticket?.players || 1)
            : Number(product.amount || 1)
        }`}</span>
        <div className="d-flex align-items-center gap-2">
          <span className="fw-bold text-secondary">
            {(
              Number(product.dont_charge ? 0 : product.single_value || 0) *
              Number(
                product.min_amount > 0
                  ? product.min_amount
                  : product.charge_type === "participants"
                  ? ticket?.players || 1
                  : 1
              )
            ).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })}
          </span>
          <Button
            data-toggle="tooltip"
            title="Remover"
            variant="outline-danger"
            className="rounded-circle"
            size="sm"
            disabled={loadingRemoveBundleProduct}
            onClick={() => handleRemoveBundleProduct(product.id)}
          >
            <HiTrash size={"100%"} />
          </Button>
        </div>
      </div>
    );
  };

  const handleOpenAddProductModal = () => {
    setOpenAddProductModal(true);
  };

  const renderTicketList = () => {
    if (!productsList || !productsList[0]) return <></>;

    return (
      <div className="d-flex flex-column gap-1">
        {productsList.map(renderProduct)}
      </div>
    );
  };

  const productsTotalValue = productsList.reduce(
    (acum, item) =>
      (acum +=
        Number(item.dont_charge ? 0 : item.single_value || 0) *
        Number(
          item.min_amount > 0
            ? item.min_amount
            : item.charge_type === "participants"
            ? ticket?.players || 1
            : 1
        )),
    0
  );

  useEffect(() => {
    if (onProductsTotalValueChange)
      onProductsTotalValueChange(productsTotalValue);
  }, [productsTotalValue]);

  return (
    <div className="d-flex flex-column gap-1">
      {openAddProductModal && (
        <AddBundleProductModal
          open={!!openAddProductModal}
          setOpen={setOpenAddProductModal}
          bundle_id={bundle_id}
          bundle_product_id={openAddProductModal}
          ticket_type={ticket?.type}
          onSuccess={getProducts}
        />
      )}
      <span className="fs-5 fw-bold">Produtos do pacote</span>
      {loadingProducts && <ProgressBar animated now={100} />}
      {renderTicketList()}
      {!productsList[0] && (
        <span className="text-muted">
          Clique abaixo para adicionar um produto ao pacote!
        </span>
      )}
      <span className="fw-bold">{`Valor total em produtos: ${productsTotalValue.toLocaleString(
        "pt-br",
        {
          style: "currency",
          currency: "BRL",
        }
      )}`}</span>
      <Button
        variant="outline-primary"
        disabled={loadingLocked}
        onClick={handleOpenAddProductModal}
      >
        Adicionar Produto
      </Button>
    </div>
  );
};

export default BundleProducts;
