import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import Api from "../../api/api";
import "./styles.scss";

export const ManageBuffet = ({
  editedModalOpen,
  setEditedModalOpen,
  dataModalManager,
  handleUpdateList,
}) => {
  const [itemName, setItemName] = useState(dataModalManager?.name);
  const [itemValue, setItemValue] = useState(dataModalManager?.value);

  const handleDeleteBuffet = (id) => {
    const confirmDelete = window.confirm(
      `Tem certeza de que deseja deletar o ${dataModalManager?.name}?`
    );

    if (confirmDelete) {
      toast.warning(`Deletando ${dataModalManager?.name}.`);
      Api.delete(`/buffets/${id}`)
        .then((res) => {
          handleUpdateList();
          toast.success(`${dataModalManager?.name}, deletado com sucesso!`);
          setEditedModalOpen(false);
        })
        .catch((e) => {
          toast.error(e.message);
        });
    }
  };

  const handleUpdateBuffet = () => {
    if (
      itemName === dataModalManager?.name &&
      itemValue === dataModalManager?.value
    ) {
      toast.error(
        "Por favor, altere os valores dos campos Nome do Item ou Valor do Item para atualizar."
      );
      return;
    }

    const confirmUpdate = window.confirm(
      "Tem certeza de que deseja atualizar o buffet?"
    );

    if (confirmUpdate) {
      const updatedBuffet = {
        unit_id: dataModalManager.unit_id,
        name: itemName,
        value: itemValue,
        status: dataModalManager.status,
      };

      Api.put(`/buffets/${dataModalManager.id}`, updatedBuffet)
        .then((res) => {
          toast.success("Buffet atualizado com sucesso!");
          setEditedModalOpen(false);
          handleUpdateList();
        })
        .catch((e) => {
          toast.error(e.message);
        });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (itemName && itemValue) {
        handleUpdateBuffet();
      } else {
        toast.error("Por favor, preencha todos os campos.");
      }
    }
  };

  return (
    <Modal show={editedModalOpen} onHide={() => setEditedModalOpen(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Editar Buffet</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <label>Nome do Buffet:</label>
        <input
          type="text"
          className="form-control"
          value={itemName}
          onChange={(e) => setItemName(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <br />
        <label>Valor por Pessoa:</label>
        <input
          type="number"
          className="form-control"
          value={itemValue}
          onChange={(e) => setItemValue(e.target.value)}
          onKeyDown={handleKeyDown}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={() => handleDeleteBuffet(dataModalManager.id)}
        >
          Deletar
        </Button>
        <Button variant="primary" onClick={handleUpdateBuffet}>
          Atualizar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
