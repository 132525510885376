import { FormLabel, InputGroup } from "react-bootstrap";
import InputMask from "react-input-mask";

export function InputBtnGroup({ state, stateInput, text, editButton }) {
  return (
    <>
      <FormLabel>{text}</FormLabel>
      <InputGroup>
        <InputMask
          inputMode="numeric"
          className="form-control"
          maskChar=""
          mask="999"
          onChange={(e) => stateInput(e.target.value)}
          value={Number(state)}
        />
        {editButton ? editButton : null}
        <button
          className="btn btn-outline-secondary "
          type="button"
          onClick={() =>
            stateInput((prevState) =>
              Number(prevState) + 1 < 1000
                ? Number(prevState) + 1
                : Number(prevState)
            )
          }
          style={{ zIndex: 0, borderColor: "#ced4da" }}
        >
          +
        </button>
        <button
          className="btn btn-outline-secondary "
          type="button"
          onClick={() =>
            stateInput((prevState) =>
              Number(prevState) - 1 >= 0
                ? Number(prevState) - 1
                : Number(prevState)
            )
          }
          style={{ zIndex: 0, borderColor: "#ced4da" }}
        >
          -
        </button>
      </InputGroup>
    </>
  );
}
