import { useEffect, useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import Api from "../../api/api";
import ImageUpload from "../../components/ImageUpload";

const EditModal = ({ open, setOpen, item, getList }) => {
  const [values, setValues] = useState({});
  const [load, setLoad] = useState(false);

  useEffect(() => {
    setValues({ ...item });
  }, [item]);

  const closeModal = () => {
    setOpen(null);
  };

  const handleChange = (e) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSwitchChange = (e) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.checked }));
  };

  const remove = () => {
    if (window.confirm("Deseja realmente remover este parceiro?")) {
      setLoad(true);
      Api.delete(`partners/${values.id}`)
        .then((res) => {
          toast.success("Parceiro removido com sucesso!");
          getList();
          closeModal();
        })
        .catch((err) => {
          toast.error(err.message);
        })
        .finally(() => {
          setLoad(false);
        });
    }
  };

  const save = () => {
    if (!values.name) return toast.warning("Nome do parceiro indefinido!");
    if (
      values.client_validation_url &&
      !isValidURL(values.client_validation_url)
    )
      return toast.warning("Link para documento/lista inválido!");

    setLoad(true);
    if (values.id)
      return Api.put(`partners/${values.id}`, values)
        .then((res) => {
          toast.success("Parceiro atualizado com sucesso!");
          getList();
          closeModal();
        })
        .catch((err) => {
          toast.error(err.message);
        })
        .finally(() => {
          setLoad(false);
        });
    Api.post(`partners`, values)
      .then((res) => {
        toast.success("Parceiro criado com sucesso!");
        getList();
        closeModal();
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setLoad(false);
      });
  };

  const isValidURL = (url) => {
    let valid = null;

    try {
      new URL(url);
      valid = true;
    } catch (error) {
      valid = false;
    }

    return valid;
  };

  return (
    <>
      <Modal show={open} onHide={load ? null : closeModal} size={"md"}>
        <Modal.Body className="d-flex flex-column gap-1">
          <h5>{`${values.id ? "Editar" : "Adicionar"} Parceiro`}</h5>
          <InputGroup>
            <InputGroup.Text>Nome</InputGroup.Text>
            <Form.Control
              value={values.name}
              name={"name"}
              onChange={handleChange}
              autoFocus
            />
          </InputGroup>
          <InputGroup>
            <InputGroup.Text>Mostrar no site</InputGroup.Text>
            <div className="form-control">
              <Form.Check
                type="checkbox"
                label="Ativo"
                checked={values.display}
                name="display"
                onChange={handleSwitchChange}
              />
            </div>
          </InputGroup>
          <InputGroup className="flex-column align-items-center justify-content-center">
            <InputGroup.Text className="rounded-0 rounded-top w-100 justify-content-center">
              Logo do(a) parceiro(a)
            </InputGroup.Text>
            <div
              className={
                values.avatar
                  ? "border border-primary rounded-0 rounded-bottom m-0 w-100 h-100 pt-1 overflow-hidden"
                  : "border border-primary rounded-0 rounded-bottom m-0 w-100 h-100 overflow-hidden"
              }
            >
              <ImageUpload
                value={values.avatar}
                onChange={handleChange}
                name="avatar"
                variant={"avatar"}
                folderName={"partners"}
              />
            </div>
          </InputGroup>
          <div className="p-2 d-flex flex-column gap-1 rounded-box bg-secondary bg-opacity-25">
            <span className="font-weight-bold">Dados para a recepção</span>
            <InputGroup className="flex-column align-items-center justify-content-center">
              <InputGroup.Text className="rounded-0 rounded-top w-100 justify-content-center">
                Informações para a recepção
              </InputGroup.Text>
              <Form.Control
                value={values.validation_text}
                name={"validation_text"}
                onChange={handleChange}
                as="textarea"
                className="w-100 m-0 rounded-0 rounded-bottom"
              />
            </InputGroup>
            <InputGroup>
              <InputGroup.Text>Link para documento/lista</InputGroup.Text>
              <Form.Control
                value={values.client_validation_url}
                name={"client_validation_url"}
                onChange={handleChange}
                isInvalid={
                  values.client_validation_url &&
                  !isValidURL(values.client_validation_url)
                }
                isValid={
                  values.client_validation_url &&
                  isValidURL(values.client_validation_url)
                }
                style={{
                  borderTopRightRadius: "2rem",
                  borderBottomRightRadius: "2rem",
                }}
              />
              <Form.Control.Feedback type="invalid">
                Você precisa entrar com uma URL válida, verifique a ausência do
                protocolo [http(s)].
              </Form.Control.Feedback>
            </InputGroup>
          </div>
          <InputGroup className="flex-column align-items-center justify-content-center">
            <InputGroup.Text className="rounded-0 rounded-top w-100 justify-content-center">
              Modelo para conferência
            </InputGroup.Text>
            <div
              className={
                values.client_validation_example
                  ? "border border-primary rounded-0 rounded-bottom m-0 w-100 h-100 pt-1 overflow-hidden"
                  : "border border-primary rounded-0 rounded-bottom m-0 w-100 h-100 overflow-hidden"
              }
            >
              <ImageUpload
                value={values.client_validation_example}
                onChange={handleChange}
                name="client_validation_example"
                folderName={"partners"}
                aspectRatio={null}
              />
            </div>
          </InputGroup>
          <InputGroup className="flex-column align-items-center justify-content-center">
            <InputGroup.Text className="rounded-0 rounded-top w-100 justify-content-center">
              Informações para o cliente
            </InputGroup.Text>
            <Form.Control
              value={values.client_validation_text}
              name={"client_validation_text"}
              onChange={handleChange}
              as="textarea"
              className="w-100 m-0 rounded-0 rounded-bottom"
            />
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={load} variant="light" onClick={closeModal}>
            Cancelar
          </Button>
          {values.id && (
            <Button disabled={load} variant="danger" onClick={remove}>
              Remover
            </Button>
          )}
          <Button disabled={load} variant="primary" onClick={save}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditModal;
