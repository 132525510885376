import { useEffect, useState } from "react";
import { Button, InputGroup, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import ImageUpload from "../../../../components/ImageUpload";

const EditModal = ({
  open,
  setIndex,
  index,
  handleSave,
  list,
  handleRemove,
}) => {
  const [values, setValues] = useState(null);

  useEffect(() => {
    if (open) setValues(index >= 0 ? list[index] : null);
  }, [index, open]);

  const closeModal = () => {
    setIndex(null);
  };

  const handleChange = (e) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const save = () => {
    if (!values.desktop)
      return toast.warning("Imagem versão desktop indefinida!");
    if (!values.mobile)
      return toast.warning("Imagem versão mobile indefinida!");

    handleSave(values, index);
  };

  return (
    <>
      <Modal show={open} onHide={closeModal} size={"md"}>
        <Modal.Body className="d-flex flex-column gap-1">
          <h5>{`${values ? "Editar" : "Adicionar"} Item do Banner`}</h5>
          <div className="d-flex flex-column">
            <InputGroup className="flex-column align-items-center justify-content-center">
              <InputGroup.Text className="rounded-0 rounded-top w-100 justify-content-center">
                Imagem desktop
              </InputGroup.Text>
              <div
                className={
                  values
                    ? "border border-primary rounded-0 rounded-bottom m-0 w-100 h-100 pt-1 overflow-hidden"
                    : "border border-primary rounded-0 rounded-bottom m-0 w-100 h-100 overflow-hidden"
                }
              >
                <ImageUpload
                  value={values?.desktop}
                  onChange={handleChange}
                  name="desktop"
                  folderName={"home_carousel_items"}
                  aspectRatio={16 / 9}
                  resizeOptions={{ maxWidth: 2000 }}
                />
              </div>
            </InputGroup>
            <span className="ml-auto text-right">{`Dimensões ideais: 2000px/1125px`}</span>
            <span className="ml-auto text-right">{`Tamanho ideal: 200Kb`}</span>
            <span className="ml-auto text-right">{`Imagens com dimensões e tamanho maiores serão reduzidas até o tamanho ideal`}</span>
          </div>
          <div className="d-flex flex-column">
            <InputGroup className="flex-column align-items-center justify-content-center">
              <InputGroup.Text className="rounded-0 rounded-top w-100 justify-content-center">
                Imagem mobile
              </InputGroup.Text>
              <div
                className={
                  values
                    ? "border border-primary rounded-0 rounded-bottom m-0 w-100 h-100 pt-1 overflow-hidden"
                    : "border border-primary rounded-0 rounded-bottom m-0 w-100 h-100 overflow-hidden"
                }
              >
                <ImageUpload
                  value={values?.mobile}
                  onChange={handleChange}
                  name="mobile"
                  folderName={"home_carousel_items"}
                  aspectRatio={9 / 18}
                  resizeOptions={{ maxHeight: 2000 }}
                />
              </div>
            </InputGroup>
            <span className="ml-auto text-right">{`Dimensões ideais: 1000px/2000px`}</span>
            <span className="ml-auto text-right">{`Tamanho ideal: 200Kb`}</span>
            <span className="ml-auto text-right">{`Imagens com dimensões e tamanho maiores serão reduzidas até o tamanho ideal`}</span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={closeModal}>
            Cancelar
          </Button>
          {index !== "new" && handleRemove && (
            <Button variant="danger" onClick={() => handleRemove(index)}>
              Remover
            </Button>
          )}
          <Button
            disabled={!values?.desktop || !values?.mobile}
            variant="primary"
            onClick={save}
          >
            {list[index] ? "Atualizar Item" : "Adicionar Item"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditModal;
