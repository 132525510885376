import React from "react";
import { maskBr } from "js-brasil";
import moment from "moment";
import { Button, Card, Form } from "react-bootstrap";
import { genderList } from "../../../utils/functions";

const UserDetails = ({
  user,
  handleUserInfoChange,
  userInfo,
  handleReject,
  handleAccept,
  handleEdit,
}) => {
  const onChange = (e) => {
    e.target.value = e.target.value.replaceAll(/\D/g, "");
    if (e.target.name === "cpf") {
      if (e.target.value.length > 11) e.target.value = userInfo[e.target.name];
    }
    if (e.target.name === "zipcode") {
      if (e.target.value.length > 8) e.target.value = userInfo[e.target.name];
    }

    handleUserInfoChange(e);
  };

  return (
    <Card>
      <Card.Body>
        <Card.Title>
          <h5 className="fw-bold">Usuário encontrado</h5>
        </Card.Title>
        <div className="d-flex flex-column p-1 gap-2">
          <div className="d-flex flex-row justify-content-between">
            <span>Nome:</span>
            <span>{`${user.name} ${user.lastName}`}</span>
          </div>
          <hr style={{ margin: 0 }} />
          <div className="d-flex flex-row justify-content-between">
            <span>ID:</span>
            <span>{user.id}</span>
          </div>
          <hr style={{ margin: 0 }} />
          <div className="d-flex flex-row justify-content-between">
            <span>E-mail:</span>
            <span>{user.email}</span>
          </div>
          <hr style={{ margin: 0 }} />
          <div className="d-flex flex-row justify-content-between align-items-center">
            <span>Documento:</span>
            {user.cpf ? (
              <span>{`${
                user.cpf
                  ? [true, "true"].includes(user.brazilian_doc)
                    ? user.cpf.length > 11
                      ? maskBr.cnpj(user.cpf)
                      : maskBr.cpf(user.cpf)
                    : user.cpf
                  : "Indefinido"
              }`}</span>
            ) : (
              <Form.Control
                type="text"
                placeholder="Digite aqui o CPF do usuário"
                onChange={onChange}
                name="cpf"
                className="w-50"
              />
            )}
          </div>
          <hr style={{ margin: 0 }} />
          <div className="d-flex flex-row justify-content-between">
            <span>Data de nascimento:</span>
            <span>{`${
              user.birth
                ? moment(user.birth, "DD/MM/YYYY").isValid()
                  ? user.birth
                  : moment(user.birth).format("DD/MM/YYYY")
                : "Indefinido"
            }`}</span>
          </div>
          <hr style={{ margin: 0 }} />
          <div className="d-flex flex-row justify-content-between">
            <span>Gênero:</span>
            <span>{`${
              user.gender && genderList.find((g) => g.value === user.gender)
                ? genderList.find((g) => g.value === user.gender).label
                : "Indefinido"
            }`}</span>
          </div>
          <hr style={{ margin: 0 }} />
          <div className="d-flex flex-row justify-content-between align-items-center">
            <span>CEP:</span>
            {user.zipcode ? (
              <span>{`${user.zipcode || "Indefinido"}`}</span>
            ) : (
              <Form.Control
                type="text"
                placeholder="Digite aqui o CEP do usuário"
                onChange={onChange}
                maxLength={8}
                className="w-50"
              />
            )}
          </div>
          <hr style={{ margin: 0 }} />
          <div className="d-flex flex-row justify-content-between">
            <span>Endereço:</span>
            <span>{`${user.address || "Indefinido"}`}</span>
          </div>
          <hr style={{ margin: 0 }} />
          <div className="d-flex flex-row justify-content-between">
            <span>Número:</span>
            <span>{`${user.number || "Indefinido"}`}</span>
          </div>
          <hr style={{ margin: 0 }} />
          <div className="d-flex flex-row justify-content-between">
            <span>Cidade:</span>
            <span>{`${user.city || "Indefinida"}`}</span>
          </div>
          <hr style={{ margin: 0 }} />
          <div className="d-flex flex-row justify-content-between">
            <span>Bairro:</span>
            <span>{`${user.neighborhood || "Indefinido"}`}</span>
          </div>
        </div>
      </Card.Body>
      <Card.Footer className="bg-white">
        <div className="d-flex gap-1 justify-content-end">
          {handleReject && (
            <Button variant="light" onClick={handleReject}>
              Cancelar
            </Button>
          )}
          {handleEdit && (
            <Button variant="secondary" onClick={handleEdit}>
              Editar Cliente
            </Button>
          )}
          {handleAccept && (
            <Button className="btn btn-primary" onClick={handleAccept}>
              Confirmar
            </Button>
          )}
        </div>
      </Card.Footer>
    </Card>
  );
};

export default UserDetails;
