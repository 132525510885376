import { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import Api from "../../api/api";
import PageTitle from "../../components/PageTitle";
import EditModal from "./EditModal";
import List from "./List";

const Albums = () => {
  const [load, setLoad] = useState(false);
  const [list, setList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleNewItem = () => {
    const defaultData = { name: "", description: "", cover_url: "", status: 2 };

    setSelectedItem(defaultData);
  };

  const getList = () => {
    setLoad(true);
    Api.get("albums/list", { params: { from_dashboard: true } })
      .then((res) => {
        setList(res.data.albums);
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoad(false));
  };

  const handleListChange = (changedList) => {
    setLoad(true);

    const orderList = changedList.map((item, index) => ({
      id: item.id,
      item_order: index + 1,
    }));

    Api.post("albums/reorder", orderList)
      .then((res) => {
        toast.success("Ordem dos álbums atualizada com sucesso!");
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoad(false));
  };

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    document.getElementById("topbar")?.scrollIntoView();
  }, []);

  return (
    <>
      <EditModal
        open={selectedItem ? true : false}
        setOpen={setSelectedItem}
        item={selectedItem}
        getList={getList}
      />
      <Container fluid>
        <PageTitle>
          <h2 className="font-weight-bold">Álbuns</h2>
        </PageTitle>
        <Row>
          <Col xs={12} xxl={10}>
            <div className="d-flex flex-column gap-1">
              <List
                list={list}
                onChange={handleListChange}
                setSelectedItem={setSelectedItem}
                load={load}
              />
              <Button
                disabled={load}
                onClick={handleNewItem}
                className="align-self-end"
              >
                Novo Álbum
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Albums;
