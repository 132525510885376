import { useEffect, useState } from "react";
import Api from "../../../api/api";
import { toast } from "react-toastify";
import { Button, ProgressBar } from "react-bootstrap";
import EditModal from "./EditModal";

const CartSuggestions = ({ promotionID }) => {
  const [loadingData, setLoadingData] = useState(false);
  const [data, setData] = useState({ list: [] });
  const [open, setOpen] = useState(null);

  const getData = () => {
    setLoadingData(true);
    Api.get(`/promotions_cart_suggestion_times`, {
      params: { promotion_id: promotionID },
    })
      .then((res) => setData(res.data))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingData(false));
  };

  useEffect(() => {
    if (promotionID) getData();
  }, [promotionID]);

  const handleNewItem = () => {
    setOpen(0);
  };

  const renderTimes = () => {
    if (!data.list[0]) return <></>;

    return (
      <div className="d-flex gap-2">
        {data.list.map((i) => (
          <Button
            key={`cart_suggestion_item_${i.id}`}
            onClick={() => setOpen(i.id)}
            variant={i.id === open ? "primary" : "outline-primary"}
          >
            {i.title}
          </Button>
        ))}
      </div>
    );
  };

  const handleSuccess = () => {
    setOpen(null);
    getData();
  };

  return (
    <>
      {open !== null && (
        <EditModal
          open={open !== null ? true : false}
          itemID={open}
          promotionID={promotionID}
          setOpen={setOpen}
          onSuccess={handleSuccess}
        />
      )}
      <div className="form-control d-flex flex-column gap-2">
        <span className="fs-5">Recomendações de Carrinho</span>
        {loadingData ? (
          <ProgressBar animated now={100} />
        ) : data.list[0] ? (
          renderTimes()
        ) : (
          <span className="text-muted">
            Nenhuma recomendação nesta promoção.
          </span>
        )}
        <Button className="fw-bold align-self-end" onClick={handleNewItem}>
          Adicionar Recomendação
        </Button>
      </div>
    </>
  );
};

export default CartSuggestions;
