import { HiPencilSquare } from "react-icons/hi2";
import AddBirthdayPersonModal from "./AddBirthdayPersonModal";
import moment from "moment";
import { genderList } from "../../../../utils/functions";

const { useState } = require("react");
const { Button } = require("react-bootstrap");

const SaleBirthdayPeople = ({ sale, onSuccess }) => {
  const [openAddBirthdayPersonModal, setOpenAddBirthdayPersonModal] =
    useState(false);

  const renderSaleBirthdayPerson = (birthdayPerson) => {
    if (!birthdayPerson || !birthdayPerson.id) return <></>;

    return (
      <div className="border border-primary rounded-box p-1 px-2 d-flex align-items-center justify-content-between">
        <div className="d-flex flex-column">
          <span className="fw-bold">{birthdayPerson.name}</span>
          <span>
            {genderList.find((g) => g.value === birthdayPerson.gender)?.label}
          </span>
        </div>
        <div className="d-flex aling-items-center gap-2">
          <div className="d-flex flex-column align-items-end">
            <span>
              {moment(birthdayPerson.birth_date).format("DD/MM/YYYY")}
            </span>
            <span>{`${birthdayPerson.age} anos`}</span>
          </div>
          <Button
            variant="outline-secondary"
            size="sm"
            className="rounded-circle"
            onClick={() => handleOpenAddBirthdayPersonModal(birthdayPerson.id)}
          >
            <HiPencilSquare />
          </Button>
        </div>
      </div>
    );
  };

  const renderSaleBirthdayPeople = () => {
    if (
      !sale ||
      !sale.ticket_birthday_people ||
      !sale.ticket_birthday_people[0]
    )
      return (
        <span className="text-muted">Nenhum aniversariante na venda.</span>
      );

    return (
      <div className="d-flex flex-column gap-1">
        {sale.ticket_birthday_people.map((bdayPerson) =>
          renderSaleBirthdayPerson(bdayPerson)
        )}
      </div>
    );
  };

  const handleOpenAddBirthdayPersonModal = (transaction_id) => {
    setOpenAddBirthdayPersonModal(transaction_id || true);
  };

  const onBirthdayPersonAdded = () => {
    setOpenAddBirthdayPersonModal(false);
    if (onSuccess) onSuccess();
  };

  return (
    <>
      {openAddBirthdayPersonModal && (
        <AddBirthdayPersonModal
          open={!!openAddBirthdayPersonModal}
          setOpen={setOpenAddBirthdayPersonModal}
          onSuccess={onBirthdayPersonAdded}
          order_ticket_id={sale?.order_ticket_id}
          ticket_birthday_people_id={
            openAddBirthdayPersonModal > 0 ? openAddBirthdayPersonModal : null
          }
        />
      )}
      <span className="fs-5 text-primary fw-bold">Aniversariantes</span>
      {renderSaleBirthdayPeople()}
      <Button
        className="fw-bold"
        variant={"outline-primary"}
        onClick={handleOpenAddBirthdayPersonModal}
      >
        Adicionar aniversariante
      </Button>
    </>
  );
};

export default SaleBirthdayPeople;
