import { useContext, useEffect, useState } from "react";
import { Button, Col, Row, Spinner, Card } from "react-bootstrap";
import PageTitle from "../../components/PageTitle";
import { FilialContext } from "../../context/FilialContext";
import PopCard from "./Card";
import moment from "moment";
import CalendarDateHoursChart from "../../components/CalendarDateHoursChart";
import Api from "../../api/api";
import { toast } from "react-toastify";
import SalesReportByPaymentMethod from "../../components/SalesReportByPaymentMethod";
import SalesList from "../../components/SalesList";
import CSVModal from "./CSVModal";
import AvailableSpaces from "../../components/CalendarDateHoursChart/AvailableSpaces";
import RenderNotPlayers from "../../components/CalendarDateHoursChart/RenderNotPlayers";
import OccupationPerHourChart from "../../components/CalendarDateHoursChart/OccupationPerHourChart";

const initReport = {
  day_total: 0,
  week_total: 0,
  month_total: 0,
  year_total: 0,
  local_total: 0,
  online_total: 0,
  total: 0,
};

const SalesReport = () => {
  const { selectedUnitId } = useContext(FilialContext);
  const [filter, setFilter] = useState({
    start_date: moment().format("YYYY-MM-DD"),
    end_date: "",
  });
  const [loadingCSV, setLoadingCSV] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);
  const [report, setReport] = useState({ ...initReport });
  const [csvHeaders, setCSVHeaders] = useState(null);

  const handleFilterChange = (e) => {
    setFilter((prev) => ({
      ...prev,
      [e.target.name]: moment(e.target.value).isValid()
        ? moment(e.target.value).format("YYYY-MM-DD")
        : "",
    }));
  };

  const handleDownloadCSV = () => {
    setLoadingCSV(true);

    Api.get(`orders/csv_headers`)
      .then((res) => {
        setCSVHeaders(res.data);
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingCSV(false));
  };

  const getReport = () => {
    setLoadingReport(true);
    Api.get(`sales/report`, { params: { ...filter, unit_id: selectedUnitId } })
      .then((res) => setReport(res.data))
      .catch((err) => {
        toast.error(err.message);
        setReport({ ...initReport });
      })
      .finally(() => setLoadingReport(false));
  };

  useEffect(() => {
    if (selectedUnitId) getReport();
  }, [filter, selectedUnitId]);

  const handleCloseCSVModal = () => {
    setCSVHeaders(null);
  };

  const occupationEnabled =
    !filter.end_date || filter.end_date === filter.start_date;

  useEffect(() => {
    document.getElementById("topbar")?.scrollIntoView();
  }, []);

  return (
    <>
      {csvHeaders && (
        <CSVModal
          handleClose={handleCloseCSVModal}
          headers={csvHeaders}
          filter={{
            transaction_date_start: filter.start_date,
            transaction_date_end: filter.end_date,
            unit_id: selectedUnitId,
          }}
        />
      )}
      <PageTitle className="d-flex flex-column flex-md-row">
        <h2 className="color-gray">Consolidado de Vendas</h2>
        {loadingReport && <Spinner />}
        <div
          className="d-flex align-itens-center justify-content-center"
          style={{ gap: 15 }}
        >
          <div>
            <label className="ml-2 mb-0 color-gray" htmlFor="">
              Data inicial
            </label>
            <input
              type="date"
              name="start_date"
              id=""
              className="form-control"
              style={{ width: 160 }}
              value={filter.start_date}
              onChange={handleFilterChange}
            />
          </div>
          <div>
            <label className="ml-2 mb-0" htmlFor="">
              Data final
            </label>
            <input
              type="date"
              name="end_date"
              id=""
              className="form-control"
              style={{ width: 160 }}
              value={filter.end_date}
              onChange={handleFilterChange}
            />
          </div>
        </div>
      </PageTitle>
      <Row className="g-2">
        <Col xs={12} md={6} lg={3}>
          <Card className="border-0">
            <Card.Header className="bg-primary border-primary fs-5 text-white">
              {`Vendas ${filter.end_date ? "Locais" : "do Dia"}`}
            </Card.Header>
            <Card.Body className="text-center">
              <Card.Text className="h2">
                {Number(
                  filter.end_date ? report.local_total : report.day_total
                ).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={6} lg={3}>
          <Card className="border-0">
            <Card.Header className="bg-primary border-primary fs-5 text-white">
              {`Vendas ${filter.end_date ? "Online" : "da Semana"}`}
            </Card.Header>
            <Card.Body className="text-center">
              <Card.Text className="h2">
                {Number(
                  filter.end_date ? report.online_total : report.week_total
                ).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={6} lg={3}>
          <Card className="border-0">
            <Card.Header className="bg-primary border-primary fs-5 text-white">
              {`Vendas${filter.end_date ? "" : " do Mês"}`}
            </Card.Header>
            <Card.Body className="text-center">
              <Card.Text className="h2">
                {Number(
                  filter.end_date ? report.total : report.month_total
                ).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={6} lg={3}>
          {filter.end_date ? (
            <div className="d-flex align-items-center justify-content-center h-100">
              <Button
                className="btn-lg"
                disabled={loadingCSV}
                onClick={handleDownloadCSV}
              >
                Baixar CSV
              </Button>
            </div>
          ) : (
            <Card className="border-0">
              <Card.Header className="bg-primary border-primary fs-5 text-white">
                {`Vendas do Ano`}
              </Card.Header>
              <Card.Body className="text-center">
                <Card.Text className="h2">
                  {Number(report.year_total).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </Card.Text>
              </Card.Body>
            </Card>
          )}
        </Col>
        {occupationEnabled && (
          <>
            <Col xs={12} md={6} className="d-flex flex-row">
              <Card className="w-100">
                <Card.Body>
                  <Card.Title>
                    <h5 className="fw-bold">
                    Ocupação por horário:
                    </h5>
                  </Card.Title>
                  <OccupationPerHourChart date={filter.start_date} />
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={6} className="d-flex flex-row">
              <Card className="w-100">
                <Card.Body>
                  <Card.Title>
                    <h5 className="fw-bold">
                      Total em vendas por forma de pagamento:
                    </h5>
                  </Card.Title>
                  <SalesReportByPaymentMethod filter={filter} />
                </Card.Body>
              </Card>
            </Col>
          </>
        )}
        <AvailableSpaces date={filter.start_date} />
        <RenderNotPlayers date={filter.start_date} />
        <Col xs={12} className="mt-4">
          <Card>
            <Card.Body>
              <Card.Title>
                <h5 className="fw-bold">
                  {filter.end_date ? "Vendas no período:" : "Últimas vendas:"}
                </h5>
              </Card.Title>
              <SalesList
                transactionStartDate={filter.start_date}
                transactionEndDate={filter.end_date}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default SalesReport;
