import React, { Fragment, useState } from "react";
import { maskBr } from "js-brasil";
import moment from "moment";
import { Button, Modal, ProgressBar } from "react-bootstrap";
import Api from "../../../api/api";
import { toast } from "react-toastify";
import PartnerValidationModal from "./PartnerValidationModal";
import { useEffect } from "react";
import { HiLink, HiPencilSquare } from "react-icons/hi2";
import {
  cleanNumberString,
  customerTypesList,
  getStatementParticipantType,
  getTicketAttachmentType,
  getTicketType,
  getTicketTypes,
} from "../../../utils/functions";
import { paymentMethods } from "../../../utils/paymentType";

const ConfirmModal = ({
  order,
  setTicket,
  open,
  handleClose,
  loading,
  readOnly,
}) => {
  const [load, setLoad] = useState(false);
  const [hideTermsButton, setHideTermsButton] = useState(false);
  const [showConfirmButton, setShowConfirmButton] = useState(false);
  const [openValidationModal, setOpenValidationModal] = useState(false);

  useEffect(() => {
    if (!open) {
      setHideTermsButton(false);
      setShowConfirmButton(false);
      setOpenValidationModal(false);
    }
  }, [open]);

  const ticketSubTotal =
    Number(order?.tickets_value || 0) + Number(order?.products_value || 0);

  const handleSignTerms = () => {
    if (
      window.confirm(
        "Confirmar assinatura manual de termos de responsabilidade?"
      )
    ) {
      setLoad(true);
      Api.post(`/orders_tickets/manually_sign_terms/${order.ticket.id}`)
        .then((res) => {
          toast.success("Termos de responsabilidade assinados manualmente!");
          setHideTermsButton(true);
          setShowConfirmButton(true);
          setTicket(res.data.data);
        })
        .catch((err) => {
          toast.error(err.error);
        })
        .finally(() => {
          setLoad(false);
        });
    }
  };

  const handleConfirmUsage = (from_validation_modal) => {
    if (!from_validation_modal && order.validation_text)
      return setOpenValidationModal(true);

    if (window.confirm("Confirmar uso do ticket?")) {
      setLoad(true);
      Api.put(`/orders_tickets/${order.ticket.id}`, {
        ...order.ticket,
        status: 2,
      })
        .then((res) => {
          toast.success("Uso do ticket confirmado com sucesso!");
          setTicket({ ...order.ticket, status: 2 });
        })
        .catch((err) => {
          toast.error(err.message);
        })
        .finally(() => {
          setLoad(false);
        });
    }
  };

  const handleCloseValidationModal = () => {
    setOpenValidationModal(false);
  };

  const ticketTypesList = getTicketTypes().map((type) => ({
    value: type,
    label: getTicketType(type),
  }));

  const price = order.ticket?.player_price || 0;

  const ticketBirthdayPeopleCount =
    order && order.ticket_birthday_people
      ? order.ticket_birthday_people.length
      : 0;

  const birthdayDiscount = price * ticketBirthdayPeopleCount;

  const showBirthdayDiscount = birthdayDiscount > 0;

  const isParty = [
    "single",
    "exlusive",
    "demi_exclusive",
    "pop_night",
  ].includes(order?.ticket?.type);
  const title = readOnly
    ? `Dados d${isParty ? "a festa" : "o ticket"}`
    : `Confirmar uso d${isParty ? "a festa" : "o ticket"}`;

  const getStatus = () => {
    let ticketStatus = order?.transactions
      ? order?.transactions[0]?.payment_status
      : Number(order?.status);
    if (isParty) ticketStatus = Number(order.ticket.status);
    if (!ticketStatus) return "";
    if (!isParty && order?.transactions)
      ticketStatus = ticketStatus.toUpperCase();

    let status = "";

    switch (ticketStatus) {
      case "CREATED":
        status = "CRIADO";
        break;
      case "FAILED":
        status = "FALHA NO PAGAMENTO";
        break;
      case "AUTHORIZED":
        status = "PAGAMENTO CONFIRMADO";
        break;
      case "DELETED":
        status = "DELETADO";
        break;
      case "REMOVED":
        status = "REMOVIDO";
        break;
      case "USED":
        status = "USO CONFIRMADO";
        break;
      case "PENDING":
        status = "AGUARDANDO PAGAMENTO";
        break;
      case "EXPIRED":
        status = "EXPIRADO";
        break;
      case "VOIDED":
        status = "ESTORNADO";
        break;
      case 6:
        status = "FALHA NA TRANSAÇÃO";
        break;
      case 1:
        status = `${ticketTypesList
          .find((type) => type.value === order?.ticket?.type)
          ?.label?.toUpperCase()} PRÉ-RESERVADA`;
        break;
      case 2:
        status = `FESTA ${ticketTypesList
          .find((type) => type.value === order?.ticket?.type)
          ?.label?.toUpperCase()} REALIZADA`;
        break;
      case 3:
        status = `FESTA ${ticketTypesList
          .find((type) => type.value === order?.ticket?.type)
          ?.label?.toUpperCase()} EXCLUÍDA`;
        break;
      case 4:
        status = `${ticketTypesList
          .find((type) => type.value === order?.ticket?.type)
          ?.label?.toUpperCase()} RESERVADA`;
        break;
      default:
        status = "STATUS DESCONHECIDO";
        break;
    }
    return status;
  };

  const participants =
    Number(order?.ticket?.players || 0) +
    Number(order?.ticket?.not_players || 0) +
    Number(order?.ticket?.minors || 0) +
    Number(order?.ticket?.minors_responsibles || 0) +
    Number(order?.ticket?.dps || 0) +
    Number(order?.ticket?.dps_responsibles || 0) +
    Number(order?.ticket?.courtesy_players || 0) +
    Number(order?.ticket?.courtesy_not_players || 0) +
    ticketBirthdayPeopleCount;

  const renderProducts = () => {
    if (!order.products || !order.products[0]) return <></>;

    return order.products.map((item) => {
      return (
        <Fragment key={`product_${item.id}`}>
          <div className="d-flex justify-content-between fw-bold">
            <span>{item?.name}</span>
            <span>{`x${
              item.charge_type === "participants" ? participants : item.amount
            } ${item.total_value.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })}`}</span>
          </div>
          <hr className="m-0 p-0" />
        </Fragment>
      );
    });
  };

  const renderResponsibilityStatements = () => {
    const renderList = () => {
      if (
        !order?.responsibility_statements ||
        !order?.responsibility_statements[0]
      )
        return <span className="text-muted">Nenhum termo adicionado.</span>;

      return order.responsibility_statements.map((rs) => (
        <div
          key={`rs_item_${rs.id}`}
          className="border rounded p-2 border-primary w-100"
        >
          <div className="d-flex align-items-center justify-content-between">
            <span>{`Termo #${rs.id}`}</span>
            <span>
              {rs.signed ? (
                <span className="text-success">Assinatura confirmada</span>
              ) : (
                <span className="text-warning">Assinatura pendente</span>
              )}
            </span>
          </div>
          <hr className="m-0 p-0" />
          {rs.participants.map((participant) => (
            <Fragment key={`participant_item_${participant.id}`}>
              <div className="d-flex justify-content-between">
                <span>{participant?.name}</span>
                <span>{getStatementParticipantType(participant.type)}</span>
              </div>
              <div className="d-flex justify-content-between">
                <span>
                  {moment(participant.birth).format("DD/MM/YYYY") || "-"}
                </span>
                <span>{participant.phone || "-"}</span>
              </div>
              <div className="d-flex justify-content-between">
                <span>
                  {participant.cpf ? maskBr.cpf(participant.cpf) : "-"}
                </span>
                <span>
                  {participant.type === "minor" ? (
                    "-"
                  ) : participant.signed ? (
                    <span className="text-success">Assinatura confirmada</span>
                  ) : (
                    <span className="text-warning">Assinatura pendente</span>
                  )}
                </span>
              </div>
              <hr className="m-0 p-0" />
            </Fragment>
          ))}
          <div className="d-flex justify-content-between text-danger">
            <span>{rs.emergency_name}</span>
            <span>{rs.emergency_phone}</span>
          </div>
        </div>
      ));
    };

    return (
      <div className="d-flex flex-column gap-1 w-100">
        <span>{`Status da assinatura do ticket: ${
          order?.ticket?.terms_signed ? "Assinados" : "Pendente"
        }`}</span>
        {renderList()}
      </div>
    );
  };

  const getCustomerType = () => {
    if (order.customer_type !== "default")
      return (
        <>
          <br />
          <span className="text-info">{`Venda para ${
            customerTypesList.find((ct) => ct.value === order.customer_type)
              ?.label
          }`}</span>
        </>
      );
  };

  const buffetPaidValue =
    order?.buffet_payments?.reduce(
      (acum, item) => (acum += Number(item.value || 0)),
      0
    ) || 0;

  return (
    <Modal show={open} onHide={load ? null : handleClose}>
      {openValidationModal && (
        <PartnerValidationModal
          confirmTicketUsage={handleConfirmUsage}
          open={openValidationModal}
          handleClose={handleCloseValidationModal}
          ticket={order}
        />
      )}
      <Modal.Header closeButton>
        <Modal.Title>
          {title}
          <br />
          <span className="fw-bold">{`ID ${order?.code}`}</span>
          <br />
          {Number(order?.ticket?.status) === 2 ? "USO CONFIRMADO" : getStatus()}
          {getCustomerType()}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && <ProgressBar animated now={100} className="mb-1" />}
        <div className="d-flex justify-content-between fw-bold">
          <span className="fs-4">
            {order?.user_id ? "Cliente" : "Sem cadastro"}
          </span>
        </div>
        {order?.user_id && (
          <>
            <div className="d-flex justify-content-between fw-bold">
              <span>Nome</span>
              <span>
                {order?.user_info?.name}{" "}
                {order?.user_info?.lastName ? order.user_info.lastName : ""}
              </span>
            </div>
            <hr className="m-0 p-0" />
            <div className="d-flex justify-content-between fw-bold">
              <span>E-mail</span>
              <span>{`${order?.user?.email}`}</span>
            </div>
            <hr className="m-0 p-0" />
            <div className="d-flex justify-content-between fw-bold">
              <span>CPF/CNPJ</span>
              <span>{`${
                cleanNumberString(order?.user_info?.cpf)?.length > 11
                  ? maskBr.cnpj(order?.user_info?.cpf)
                  : maskBr.cpf(order?.user_info?.cpf)
              }`}</span>
            </div>
            <hr className="m-0 p-0" />
            <div className="d-flex justify-content-between fw-bold">
              <span>Telefone</span>
              <span>{`${order?.user_info?.phone || "-"}`}</span>
            </div>
            <hr className="m-0 p-0" />
          </>
        )}
        <div className="d-flex justify-content-between fw-bold mt-1">
          <span className="fs-4">Participantes</span>
        </div>
        {order?.ticket?.players > 0 && (
          <>
            <div className="d-flex justify-content-between fw-bold">
              <span>Brincantes</span>
              <span>{`${order?.ticket?.players}`}</span>
            </div>
            <hr className="m-0 p-0" />
          </>
        )}
        {order?.ticket_birthday_people?.length > 0 && (
          <>
            <div className="d-flex justify-content-between fw-bold">
              <span>Brincantes aniversariantes</span>
              <span>{`${order?.ticket_birthday_people?.length}`}</span>
            </div>
            <hr className="m-0 p-0" />
          </>
        )}
        {order?.ticket?.not_players > 0 && (
          <>
            <div className="d-flex justify-content-between fw-bold">
              <span>Não brincantes</span>
              <span>{`${order?.ticket?.not_players}`}</span>
            </div>
            <hr className="m-0 p-0" />
          </>
        )}
        {order?.ticket?.minors > 0 && (
          <>
            <div className="d-flex justify-content-between fw-bold">
              <span>Crianças menores de 5 anos</span>
              <span>{`${order?.ticket?.minors}`}</span>
            </div>
            <hr className="m-0 p-0" />
          </>
        )}
        {order?.ticket?.minors_responsibles > 0 && (
          <>
            <div className="d-flex justify-content-between fw-bold">
              <span>Acompanhantes das crianças</span>
              <span>{`${order?.ticket?.minors_responsibles}`}</span>
            </div>
            <hr className="m-0 p-0" />
          </>
        )}
        {order?.ticket?.dps > 0 && (
          <>
            <div className="d-flex justify-content-between fw-bold">
              <span>PCDs</span>
              <span>{`${order?.ticket?.dps}`}</span>
            </div>
            {order.ticket_disabled_people && (
              <div className="d-flex flex-column text-danger">
                {order.ticket_disabled_people.map((dp) => (
                  <div className="d-flex justify-content-between fw-bold">
                    <span>{dp.type}</span>
                    <span>{`${dp.amount}`}</span>
                  </div>
                ))}
              </div>
            )}

            <hr className="m-0 p-0" />
          </>
        )}

        {order?.ticket?.dps_responsibles > 0 && (
          <>
            <div className="d-flex justify-content-between fw-bold">
              <span>Acompanhantes dos PCDs</span>
              <span>{`${order?.ticket?.dps_responsibles}`}</span>
            </div>
            <hr className="m-0 p-0" />
          </>
        )}

        {order?.ticket?.courtesy_players > 0 &&
          order?.ticket?.courtesy_players > ticketBirthdayPeopleCount && (
            <>
              <div className="d-flex justify-content-between fw-bold">
                <span>Cortesias brincantes</span>
                <span>{`${order?.ticket?.courtesy_players}`}</span>
              </div>
              <hr className="m-0 p-0" />
            </>
          )}
        {order?.ticket?.courtesy_not_players > 0 && (
          <>
            <div className="d-flex justify-content-between fw-bold">
              <span>Cortesias não brincantes</span>
              <span>{`${order?.ticket?.courtesy_not_players}`}</span>
            </div>
            <hr className="m-0 p-0" />
          </>
        )}
        {order?.ticket_birthday_people?.length > 0 && (
          <>
            <div className="d-flex justify-content-between fw-bold mt-1">
              <span className="fs-4">Informações dos Aniversariantes</span>
            </div>
            {order.ticket_birthday_people.map((item) => (
              <Fragment key={`ticket_birthday_people_item_${item.id}`}>
                <div className="d-flex justify-content-between fw-bold">
                  <span>{item?.name}</span>
                  <span>{`${item.age} anos`}</span>
                </div>
                <hr className="m-0 p-0" />
              </Fragment>
            ))}
          </>
        )}
        <div className="d-flex justify-content-between fw-bold mt-1">
          <span className="fs-4">Ingresso</span>
        </div>
        <div className="d-flex justify-content-between fw-bold">
          <span>Unidade</span>
          <span>{`${order?.unit?.name}`}</span>
        </div>
        <hr className="m-0 p-0" />
        <div className="d-flex justify-content-between fw-bold">
          <span>Data</span>
          <span>{`${moment(order?.ticket?.date).format("DD/MM/YYYY")}`}</span>
        </div>
        <hr className="m-0 p-0" />
        {order?.ticket?.duration && (
          <>
            <div className="d-flex justify-content-between fw-bold">
              <span>Duração no parque</span>
              <span>{`${order?.ticket?.duration}h`}</span>
            </div>
            <hr className="m-0 p-0" />
          </>
        )}
        {order?.ticket?.time && (
          <>
            <div className="d-flex justify-content-between fw-bold">
              <span>Hora de entrada</span>
              <span>{`${moment(order?.ticket?.time, "HH:mm:ss").format(
                "HH:mm"
              )}`}</span>
            </div>
            <hr className="m-0 p-0" />
          </>
        )}
        {order?.park_areas && order?.park_areas[0] && (
          <>
            <div className="d-flex justify-content-between fw-bold">
              <span>Espaços do parque</span>
              <span>
                {`${order?.park_areas?.map((item) => item.name).join(", ")}`}
              </span>
            </div>
            <hr className="m-0 p-0" />
          </>
        )}
        {order?.additional_info && (
          <>
            <div className="row">
              <span className="fw-bold">Informação adicional:</span>
              <span>{`${order?.additional_info}`}</span>
            </div>
            <hr className="m-0 p-0" />
          </>
        )}
        {order?.additional_information && (
          <>
            <div className="d-flex flex-column fw-bold">
              <span>Informações adicionais</span>
              <span>{`${order?.additional_information}`}</span>
            </div>
            <hr className="m-0 p-0" />
          </>
        )}
        {order?.products && order?.products[0] && (
          <>
            <div className="d-flex justify-content-between fw-bold mt-1">
              <span className="fs-4">Produtos</span>
            </div>
            {renderProducts()}
          </>
        )}
        {order?.ticket_attachments?.length > 0 && (
          <>
            <div className="d-flex justify-content-between fw-bold mt-1">
              <span className="fs-4">Anexos</span>
            </div>
            {order.ticket_attachments.map((item) => (
              <Fragment key={`ticket_attachments_item_${item.id}`}>
                <div className="d-flex align-items-center justify-content-between fw-bold">
                  <span>{`${getTicketAttachmentType(item.type)} ${moment(
                    item.created_at
                  ).format("DD/MM/YY HH:mm")}`}</span>
                  <Button
                    variant="outline-primary"
                    size="sm"
                    className="rounded-circle"
                    onClick={() => window.open(item.file_url)}
                    data-toggle="tooltip"
                    title="Abrir link"
                  >
                    <HiLink />
                  </Button>
                </div>
                <hr className="m-0 p-0" />
              </Fragment>
            ))}
          </>
        )}
        <div className="d-flex justify-content-between fw-bold mt-1">
          <span className="fs-4">Pagamento</span>
        </div>
        <div className="d-flex justify-content-between fw-bold">
          <span>Valor em tickets</span>
          <span>
            {(order?.tickets_value || 0).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })}
          </span>
        </div>
        <hr className="m-0 p-0" />
        <div className="d-flex justify-content-between fw-bold">
          <span>Valor em produtos</span>
          <span>
            {(order?.products_value || 0).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })}
          </span>
        </div>

        <hr className="m-0 p-0" />
        <div className="d-flex justify-content-between fw-bold">
          <span>Subtotal</span>
          <span>
            {ticketSubTotal.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })}
          </span>
        </div>

        {showBirthdayDiscount && (
          <>
            <hr className="m-0 p-0" />
            <div className="d-flex justify-content-between fw-bold">
              <span>Desconto Aniversariante</span>
              <span>
                -
                {birthdayDiscount.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
              </span>
            </div>
          </>
        )}
        <hr className="m-0 p-0" />
        {order?.coupon_discount_value > 0 && (
          <>
            <div className="d-flex justify-content-between fw-bold">
              <span>Desconto {order.coupon?.code || "Dash"}</span>
              <span>
                -
                {order?.coupon_discount_value.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
              </span>
            </div>
            <hr className="m-0 p-0" />
          </>
        )}
        {order?.points_discount_value > 0 && (
          <>
            <div className="d-flex justify-content-between fw-bold">
              <span>Desconto Pop Pontos</span>
              <span>
                -
                {order?.points_discount_value.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
              </span>
            </div>
            <hr className="m-0 p-0" />
          </>
        )}
        {order?.promotion_discount_value > 0 && (
          <>
            <div className="d-flex justify-content-between fw-bold">
              <span>Desconto {order.promotion?.name}</span>
              <span>
                -
                {order?.promotion_discount_value.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
              </span>
            </div>
            <hr className="m-0 p-0" />
          </>
        )}
        <div className="d-flex justify-content-between fw-bold">
          <span>Valor total</span>
          <span>
            {(order?.value || 0).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })}
          </span>
        </div>
        <hr className="m-0 p-0" />
        {order?.value_with_taxes !== order?.value && (
          <>
            <div className="d-flex justify-content-between fw-bold">
              <span>Taxa parcelamento</span>
              <span>
                {(
                  Number(order?.value_with_taxes || 0) -
                  Number(order?.value || 0)
                ).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
              </span>
            </div>
            <hr className="m-0 p-0" />
          </>
        )}
        {Number(order?.value_with_taxes) !== Number(order?.value) && (
          <>
            <div className="d-flex justify-content-between fw-bold">
              <span>Valor com taxa</span>
              <span>
                {(order?.value_with_taxes || 0).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
              </span>
            </div>
            <hr className="m-0 p-0" />
          </>
        )}
        {order?.points_earned > 0 && (
          <>
            <div className="d-flex justify-content-between fw-bold">
              <span>Pontos ganhos na compra</span>
              <span>{order.points_earned}</span>
            </div>
            <hr className="m-0 p-0" />
          </>
        )}
        {order?.payment_value_missing > 0 && (
          <>
            <div className="d-flex justify-content-between fw-bold text-danger">
              <span>Saldo em aberto</span>
              <span>
                {order.payment_value_missing.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
              </span>
            </div>
            <hr className="m-0 p-0" />
          </>
        )}
        {order?.transactions?.map((transaction, index) => (
          <Fragment key={`transaction_item_${transaction.id}`}>
            <div
              className={
                "d-flex justify-content-between fw-bold" +
                `${transaction.error ? " text-danger" : ""}`
              }
            >
              <span>Transação #{transaction.id}</span>
              <span>{`${transaction.value.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })} ${
                paymentMethods.find(
                  (item) => item.value === transaction.payment_method
                )?.label
              }`}</span>
            </div>
            {transaction.error && (
              <span className="fw-bold text-danger">
                Erro: {transaction.error}
              </span>
            )}
            <hr className="m-0 p-0" />
          </Fragment>
        ))}
        {order?.buffet && (
          <>
            <div className="d-flex justify-content-between fw-bold mt-1">
              <span className="fs-4">Buffet</span>
            </div>
            <div className="d-flex justify-content-between fw-bold">
              <span>{order.buffet?.name}</span>
              <span>
                {order.buffet_value.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
              </span>
            </div>
            <hr className="m-0 p-0" />
            <div className="d-flex justify-content-between fw-bold">
              <span>Total</span>
              <span>
                {(
                  Number(order.buffet_value || 0) * participants
                ).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
              </span>
            </div>
            <hr className="m-0 p-0" />
            {order?.buffet_payments?.map((buffetPayment, index) => (
              <Fragment key={`buffet_payment_item_${buffetPayment.id}`}>
                <div className={"d-flex justify-content-between fw-bold"}>
                  <span>Pagamento #{buffetPayment.id}</span>
                  <span>{`${buffetPayment.value.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })} ${
                    paymentMethods.find(
                      (item) => item.value === buffetPayment.payment_method
                    )?.label
                  }`}</span>
                </div>
                <hr className="m-0 p-0" />
              </Fragment>
            ))}
            <div className="d-flex justify-content-between fw-bold">
              <span>Total pago</span>
              <span>
                {buffetPaidValue.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
              </span>
            </div>
            <hr className="m-0 p-0" />
          </>
        )}
        <div className="d-flex justify-content-between fw-bold mt-1">
          <span className="fs-4">Termos de Responsabilidade</span>
        </div>
        <div className="d-flex justify-content-between fw-bold">
          {renderResponsibilityStatements()}
        </div>
        {order?.seller && (
          <>
            <div className="d-flex justify-content-between fw-bold">
              <span className="fs-4">Vendedor</span>
            </div>
            <div className="d-flex justify-content-between fw-bold">
              <span>Nome</span>
              <span>{`${
                order?.seller_info?.name
                  ? `${order?.seller_info?.name} ${order?.seller_info?.lastName}`
                  : "Indefinido"
              }`}</span>
            </div>
            <hr className="m-0 p-0" />
            <div className="d-flex justify-content-between fw-bold">
              <span>E-mail</span>
              <span>{order?.seller?.email}</span>
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose} disabled={load}>
          {readOnly ? "Fechar" : "Cancelar"}
        </Button>
        {!readOnly && (
          <>
            {!hideTermsButton &&
              !order?.ticket?.terms_signed &&
              !order?.ticket?.terms_manually_signed && (
                <Button
                  variant="warning"
                  onClick={handleSignTerms}
                  disabled={load}
                >
                  Assinar Termos
                </Button>
              )}
            {Number(order?.ticket?.status) !== 2 &&
              order?.transactions?.find(
                (transaction) => transaction.payment_status === "authorized"
              ) &&
              (showConfirmButton ||
                order?.ticket?.terms_signed ||
                order?.ticket?.terms_manually_signed) && (
                <Button onClick={() => handleConfirmUsage()} disabled={load}>
                  Confirmar Uso
                </Button>
              )}
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
