import React, { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AuthLayout from "../../components/Auth";
import { AuthContext } from "../../context/AuthContext";
import Api from "../../api/api";
import { toast } from "react-toastify";
const LoginPage = () => {
  const {
    setAuthenticated,
    setName,
    setDashboardId,
    setEmailLogged,
    setUserUnities,
    dashboardId,
    allowedModules,
  } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  async function handleLogin() {
    setError("");
    Api.post("/dashboard/auth", { email: email, pass: password })
      .then((res) => {
        const token = res.data?.remember_token;
        Api.defaults.headers.Authorization = `Bearer ${token}`;
        localStorage.setItem("token", token);
        localStorage.setItem("name", res.data.user.user_name);
        localStorage.setItem("email", res.data.user.email);
        localStorage.setItem("dashboard_id", res.data.user.id);
        setDashboardId(res.data.user.id);
        setName(res.data.user.user_name);
        setEmailLogged(res.data.user.email);
        setUserUnities(res.data.user.unities);

        setAuthenticated(true);

        if (res.data.user.request_new_password === true) {
          navigate(`/new-password/${res.data.user.id}`);
        } else {
          navigate("/");
        }
      })
      .catch((e) => {
        toast.error(e.message);
      });
  }

  useEffect(() => {
    document.getElementById("topbar")?.scrollIntoView();
    if (dashboardId) {
      if (allowedModules && allowedModules[0])
        navigate("/" + allowedModules[0]);
      else navigate("/");
    }
  }, []);

  return (
    <AuthLayout>
      <form>
        <div className="mb-2 field">
          {error && <p className="text-danger">{error}</p>}
          <label className="form-label">Email</label>
          <input
            className="form-control"
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="field">
          <label className="form-label">Password</label>
          <input
            className="form-control"
            type="password"
            name="pass"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <div className="mb-2 mt-2">
          <div className="form-check">
            <div className="field">
              <input className="form-check-input" type="checkbox" />
              <label className="form-check-label">Remember me</label>
            </div>
          </div>
        </div>
        <div className="actions text-center d-grid">
          <Button onClick={handleLogin}>Login</Button>
        </div>
      </form>
    </AuthLayout>
  );
};
export default LoginPage;
