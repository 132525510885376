import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Col, ProgressBar, Row, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import Api from "../../api/api";
import PageTitle from "../../components/PageTitle";
import { FilialContext } from "../../context/FilialContext";
import EditModal from "./EditModal";
import Table from "../../components/Table";
import { HiPencilSquare } from "react-icons/hi2";
import { getTicketType } from "../../utils/functions";

const Bundles = () => {
  const { selectedUnitId } = useContext(FilialContext);
  const [list, setList] = useState([]);
  const [loadingList, setLoadingList] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [data, setData] = useState(null);

  const getList = () => {
    setLoadingList(true);
    Api.get(`/bundles`, { params: { unit_id: selectedUnitId } })
      .then((res) => {
        setList(res.data.list);
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingList(false));
  };

  useEffect(() => {
    if (selectedUnitId) getList();
  }, [selectedUnitId]);

  const handleNewData = () => {
    setOpenEditModal(true);
  };

  useEffect(() => {
    if (!openEditModal) setData(null);
  }, [openEditModal]);

  const setID = (newID) => {
    setData((prev) => ({ ...prev, id: newID }));
  };

  const columns = [
    {
      field: "name",
      label: "Nome",
    },
    {
      field: "type",
      label: "Tipo de Festa",
      renderValue: (item) => getTicketType(item.type),
    },
    {
      field: "duration",
      label: "Duração",
      renderValue: (item) =>
        `${item.duration} hora${item.duration > 1 ? "s" : ""}`,
    },
    {
      field: "player_price",
      label: "Preço Ingresso",
      renderValue: (item) =>
        item.player_price?.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
    },
    // {
    //   field: "products",
    //   label: "Produtos",
    //   renderValue: (item) =>
    //     item.products
    //       ? item.products
    //           .map((prod) => `${prod.name} x${prod.min_amount || 1}`)
    //           .join(", ")
    //       : "-",
    // },
    {
      field: "buffet",
      label: "Buffet",
      renderValue: (item) => (item.buffet_id ? `${item.buffet_name}` : "-"),
    },
  ];

  const renderRow = (field, item) => {
    if (!item) return null;
    const data = columns.find((col) => col.field === field);
    if (data?.renderValue) return data.renderValue(item);
    return item[field];
  };

  return (
    <>
      {openEditModal && (
        <EditModal
          open={openEditModal}
          setOpen={setOpenEditModal}
          id={data?.id}
          onSuccess={getList}
          setID={setID}
        />
      )}
      <PageTitle>
        <h2>Pacotes de Festas</h2>
        <Button variant="primary" onClick={handleNewData}>
          Novo Pacote
        </Button>
      </PageTitle>
      <Row>
        <Col xs={12} className="d-flex flex-column gap-1">
          {loadingList && <ProgressBar animated now={100} />}
          <Card>
            <Card.Body>
              <Card.Title>
                <h5 className="fw-bold">Lista de Pacotes:</h5>
              </Card.Title>
              <Table
                data={list}
                columns={columns}
                renderRow={renderRow}
                renderRowItem
                actions={(item) => (
                  <div className="d-flex align-items-center gap-1">
                    <Button
                      data-toggle="tooltip"
                      title="Editar"
                      variant="outline-primary"
                      className="rounded-circle"
                      size="sm"
                      onClick={() => {
                        setData(item);
                        setOpenEditModal(true);
                      }}
                    >
                      <HiPencilSquare size={"100%"} />
                    </Button>
                  </div>
                )}
              />
            </Card.Body>
          </Card>
          {!loadingList && (!list || !list[0]) && (
            <span className="text-muted">Nenhum pacote para mostrar.</span>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Bundles;
