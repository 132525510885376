import { HiPencilSquare } from "react-icons/hi2";
import renamePaymentType from "../../../../utils/paymentType";
import AddTransactionModal from "./AddTransactionModal";
import moment from "moment";
import { getTransactionStatus } from "../../../../utils/functions";
import Api from "../../../../api/api";
import { toast } from "react-toastify";
import { FilialContext } from "../../../../context/FilialContext";

const { useState, useEffect, useContext } = require("react");
const { Button, Form } = require("react-bootstrap");

const SaleBuffet = ({ sale, onSuccess }) => {
  const { selectedUnitId } = useContext(FilialContext);
  const [openAddTransactionModal, setOpenAddTransactionModal] = useState(false);
  const [loadingBuffets, setLoadingBuffets] = useState(false);
  const [buffets, setBuffets] = useState([]);
  const [selectedBuffet, setSelectedBuffet] = useState("");
  const [loadingAddingBuffet, setLoadingAddingBuffet] = useState(false);

  useEffect(() => {
    const getBuffets = () => {
      setLoadingBuffets(true);
      Api.get(`buffets`, { params: { unit_id: selectedUnitId } })
        .then((res) => {
          setBuffets(res.data.list);
        })
        .catch((err) => toast.error(err.message))
        .finally(() => setLoadingBuffets(false));
    };

    if (selectedUnitId && !(sale?.order_buffet_id > 0)) getBuffets();
  }, [sale?.order_buffet_id, selectedUnitId]);

  const renderSaleBuffetPayment = (payment) => {
    if (!payment || !payment.id) return <></>;

    const getStatus = () => {
      if (payment.payment_status === "authorized")
        return `PAGO (${moment(payment.authorized_at).format(
          "DD/MM/YYYY HH:mm:SS"
        )})`;
      return getTransactionStatus(payment.payment_status);
    };

    return (
      <div
        key={`buffet_payment_item_${payment.id}`}
        className="border border-primary rounded-box p-1 px-2 d-flex align-items-center justify-content-between"
      >
        <div className="d-flex flex-column">
          <span className="fw-bold">{`#${payment.id} ${renamePaymentType(
            payment.payment_method
          )}`}</span>
          <span>{`Criado em: ${moment(payment.created_at).format(
            "DD/MM/YYYY HH:mm:SS"
          )}`}</span>
        </div>
        <div className="d-flex align-items-center gap-1">
          <div className="d-flex flex-column text-end">
            <span className="fw-bold">
              {payment.value.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })}
            </span>
            {payment.authorized_at && <span>{getStatus()}</span>}
          </div>
          <Button
            variant="outline-secondary"
            size="sm"
            className="rounded-circle"
            onClick={() => handleOpenAddTransactionModal(payment.id)}
            data-toggle="tooltip"
            title="Editar"
          >
            <HiPencilSquare />
          </Button>
        </div>
      </div>
    );
  };

  const renderSaleBuffetPayments = () => {
    if (!sale || !sale.buffet_payments || !sale.buffet_payments[0])
      return <span className="text-muted">Nenhum pagamento de buffet.</span>;

    return (
      <div className="d-flex flex-column gap-1">
        {sale.buffet_payments.map((payment) =>
          renderSaleBuffetPayment(payment)
        )}
      </div>
    );
  };

  const handleOpenAddTransactionModal = (transaction_id) => {
    setOpenAddTransactionModal(transaction_id || true);
  };

  const onTransactionAdded = () => {
    setOpenAddTransactionModal(false);
    if (onSuccess) onSuccess();
  };

  const handleAddBuffetToParty = () => {
    setLoadingAddingBuffet(true);
    Api.post(`orders_buffets`, {
      order_id: sale?.id,
      buffet_id: selectedBuffet,
      buffet_value: buffets.find((b) => Number(b.id) === Number(selectedBuffet))
        .value,
    })
      .then((res) => {
        if (onSuccess) onSuccess();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingAddingBuffet(false));
  };

  return (
    <>
      {openAddTransactionModal && (
        <AddTransactionModal
          open={!!openAddTransactionModal}
          setOpen={setOpenAddTransactionModal}
          unit_id={sale?.unit_id}
          onSuccess={onTransactionAdded}
          order_buffet_id={sale?.order_buffet_id}
          order_buffet_payment_id={
            openAddTransactionModal > 0 ? openAddTransactionModal : null
          }
        />
      )}
      {sale?.order_buffet_id > 0 ? (
        <>
          <span className="fs-5 text-primary fw-bold">
            Pagamentos de buffet
          </span>
          {renderSaleBuffetPayments()}
          <Button
            className="fw-bold"
            variant={"outline-primary"}
            onClick={handleOpenAddTransactionModal}
          >
            Lançar pagamento de buffet
          </Button>
        </>
      ) : (
        <>
          <span className="fs-5 text-primary fw-bold">Buffet</span>
          <Form.Select
            value={selectedBuffet}
            onChange={(e) => setSelectedBuffet(e.target.value)}
            disabled={loadingBuffets || loadingAddingBuffet}
          >
            <option>Selecione um buffet para adicionar à festa</option>
            {buffets.map((b) => (
              <option key={`buffet_item_${b.id}`} value={b.id}>{`${
                b.name
              } (${b.value.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })} por pessoa)`}</option>
            ))}
          </Form.Select>
          <Button
            className="fw-bold"
            variant={"outline-primary"}
            onClick={handleAddBuffetToParty}
          >
            Adicionar buffet à festa
          </Button>
        </>
      )}
    </>
  );
};

export default SaleBuffet;
