import React, { useEffect } from "react";
import PageTitle from "../../components/PageTitle";
import Container from "react-bootstrap/Container";
import { Row, Col, Card } from "react-bootstrap";
import TransactionsList from "./TransactionsList";
import TransactionTotalPerDay from "./TransactionTotalPerDay";
import TransactionTotalPerMonth from "./TransactionTotalPerMonth";
import TicketsPerMonth from "./TicketsPerMonth";
import WeeklyTicketsPerMonth from "./WeeklyTicketsPerMonth";

const PartiesTransactions = () => {
  useEffect(() => {
    document.getElementById("topbar")?.scrollIntoView();
  }, []);

  return (
    <>
      <PageTitle>
        <h2>Pagamentos de Festas</h2>
      </PageTitle>
      <Container fluid>
        <Row>
          <Col xs="12" sm="6">
            <Card>
              <Card.Body>
                <TransactionTotalPerDay />
              </Card.Body>
            </Card>
          </Col>
          <Col xs="12" sm="6">
            <Card>
              <Card.Body>
                <TransactionTotalPerMonth />
              </Card.Body>
            </Card>
          </Col>
          <Col xs="12" sm="6">
            <Card>
              <Card.Body>
                <TicketsPerMonth />
              </Card.Body>
            </Card>
          </Col>
          <Col xs="12" sm="6">
            <Card>
              <Card.Body>
                <WeeklyTicketsPerMonth />
              </Card.Body>
            </Card>
          </Col>
          <Col xs="12" className="mt-2">
            <Card>
              <Card.Body>
                <TransactionsList />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PartiesTransactions;
