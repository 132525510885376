import { useEffect, useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import Api from "../../../../api/api";
import EditModal from "./EditModal";
import List from "./List";

const PromosCarouselContentModal = ({ open, setOpen, values, setValues }) => {
  const [enabled, setEnabled] = useState(false);
  const [checkoutEnabled, setCheckoutEnabled] = useState(false);
  const [list, setList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [load, setLoad] = useState(false);

  const closeModal = () => setOpen(false);

  const handleListChange = (newList) => {
    setList(newList);
  };

  const handleSave = () => {
    setLoad(true);
    Api.put(`website_content`, {
      promo_banner_content: list,
      promo_banner_enabled: enabled,
      checkout_promo_banner_enabled: checkoutEnabled,
    })
      .then((res) => {
        toast.success("Carrossel de promoções atualizado com sucesso!");
        setValues(res.data.data);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => setLoad(false));
  };

  const handleNewItem = () => {
    setSelectedItem("new");
  };

  useEffect(() => {
    setList(open ? [...values.promo_banner_content] : []);
    setEnabled(open ? values.promo_banner_enabled : false);
    setCheckoutEnabled(open ? values.checkout_promo_banner_enabled : false);
  }, [open]);

  const handleSaveItem = (item, index) => {
    if (index === "new") setList((prev) => [...prev, item]);
    else
      setList((prev) => {
        prev[index] = { ...item };
        return [...prev];
      });
    setSelectedItem(null);
  };

  const handleRemoveItem = (index) => {
    const newList = [...list];
    newList.splice(index, 1);
    setList([...newList]);
    setSelectedItem(null);
  };

  const handleEnabledChange = (e) => {
    setEnabled([true, "true"].includes(e.target.checked) ? true : false);
  };

  const handleCheckoutEnabledChange = (e) => {
    setCheckoutEnabled(
      [true, "true"].includes(e.target.checked) ? true : false
    );
  };

  return (
    <>
      <Modal show={open} onHide={closeModal} size={"md"}>
        <EditModal
          open={selectedItem !== null ? true : false}
          setIndex={setSelectedItem}
          index={selectedItem}
          list={list}
          handleSave={handleSaveItem}
          handleRemove={handleRemoveItem}
        />
        <Modal.Title className="align-self-center">
          Conteúdo do Carrossel de Promoções
        </Modal.Title>
        <Modal.Body className="d-flex flex-column gap-1">
          <InputGroup>
            <InputGroup.Text>
              Ativar carrossel de promoções no site
            </InputGroup.Text>
            <div className="form-control p-1">
              <Form.Check
                type="checkbox"
                className="m-0 p-0"
                label="Ativo"
                checked={[true, "true"].includes(enabled)}
                name="promo_banner_enabled"
                onChange={handleEnabledChange}
              />
            </div>
          </InputGroup>
          <InputGroup>
            <InputGroup.Text>
              Ativar carrossel de promoções no checkout
            </InputGroup.Text>
            <div className="form-control p-1">
              <Form.Check
                type="checkbox"
                className="m-0 p-0"
                label="Ativo"
                checked={[true, "true"].includes(checkoutEnabled)}
                name="checkout_promo_banner_enabled"
                onChange={handleCheckoutEnabledChange}
              />
            </div>
          </InputGroup>
          <List
            list={list}
            onChange={handleListChange}
            setSelectedItem={setSelectedItem}
          />
          <Button variant="outline-primary" onClick={handleNewItem}>
            Novo item
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={closeModal}>
            Fechar
          </Button>
          <Button onClick={handleSave} disabled={load}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PromosCarouselContentModal;
