import React, { useState } from "react";
import {
  Button,
  ListGroup,
  ListGroupItem,
  Modal,
  ProgressBar,
} from "react-bootstrap";
import { useEffect } from "react";
import Api from "../../../api/api";
import { toast } from "react-toastify";
import EditModal from "./EditModal";

const LevelConfig = ({ open, setOpen }) => {
  const [list, setList] = useState([]);
  const [load, setLoad] = useState(false);
  const [selectedID, setSelectedID] = useState();

  const getList = () => {
    setLoad(true);
    Api.get(`levels_experiences`)
      .then((res) => {
        setList(res.data.list);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => setLoad(false));
  };

  useEffect(() => {
    getList();
  }, []);

  const handleClose = () => setOpen(false);

  return (
    <Modal onHide={load ? null : handleClose} show={open}>
      <Modal.Title className="align-self-center text-center">
        Configuração Geral
        <br />
        de Níveis de Programas de Pontos
      </Modal.Title>
      {selectedID && (
        <EditModal
          open={selectedID ? true : false}
          setOpen={setSelectedID}
          id={selectedID}
          getList={getList}
        />
      )}
      <Modal.Body>
        {load && <ProgressBar animated now={100} className="mb-1" />}
        <ListGroup>
          {list.map((item) => (
            <ListGroupItem
              key={`level_experience_${item.id}`}
              action
              active={item.id === selectedID}
              variant="primary"
              onClick={() => setSelectedID(item.id)}
              className="d-flex justify-content-between align-items-center"
            >
              <span>{`Nível ${item.level}`}</span>
              <span>{`XP necessária: ${item.experience_required}`}</span>
            </ListGroupItem>
          ))}
        </ListGroup>
        {!load && !list[0] && (
          <div className="w-100 d-flex justify-content-center">
            <span>Sem níveis cadastrados para os programas.</span>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose} disabled={load}>
          Fechar
        </Button>
        <Button
          className="align-self-stretch align-self-sm-end"
          variant="primary"
          onClick={() => setSelectedID("new")}
          disabled={load}
        >
          Novo Nível
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LevelConfig;
