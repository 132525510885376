import styled from "styled-components";

export const ContainerFixed = styled.div`
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  left: 0;
  justify-content: center;

  background: #6658dd;
`;

export const CardContainer = styled.div`
  max-width: 500px;
  min-width: 200px;
  width: 100%;
  padding: 10px 40px;
  margin: 50px;
  height: 460px;
  max-height: 500px;

  margin-top: 100px;
  background-color: white;
  border-radius: 12px;

  div.logo-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      height: 110px;
      width: 200px;
    }
    form {
      margin-top: 0;
      width: 100%;
    }
  }
`;
