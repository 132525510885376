import React from "react";
import { Button, ListGroup } from "react-bootstrap";

const ListRow = ({ item, setSelectedItem = console.log }) => {
  const handleClick = () => {
    setSelectedItem(item);
  };

  return (
    <ListGroup.Item
      variant="primary"
      className="rounded rounded-box user-select-none d-flex flex-column"
    >
      <div className="d-flex p-1 justify-content-between align-items-center">
        <div className="d-flex flex-column gap-1 align-items-center">
          <span>Desktop</span>
          <img
            src={item.desktop}
            alt="home_carousel_desk"
            height={150 / (16 / 9)}
            width={150}
            className="rounded-box"
          />
        </div>
        <div className="d-flex flex-column gap-1 align-items-center">
          <span>Mobile</span>
          <img
            src={item.mobile}
            alt="home_carousel_mob"
            height={150}
            width={150 / (18 / 9)}
            className="rounded-box"
          />
        </div>
        <div className="d-flex gap-1 align-items-center justify-content-center">
          <Button className="" onClick={handleClick}>
            Editar
          </Button>
        </div>
      </div>
      <span className="ms-3 mb-2">{item.tooltip || "Sem tooltip"}</span>
    </ListGroup.Item>
  );
};

export default ListRow;
