import { useContext, useEffect, useState } from "react";
import { Button, Modal, ProgressBar } from "react-bootstrap";
import Table from "../../components/Table";
import moment from "moment";
import Api from "../../api/api";
import { toast } from "react-toastify";
import { FilialContext } from "../../context/FilialContext";
import { getTicketType, getTicketTypes } from "../../utils/functions";

const CalendarDatesHoursUpdateLogModal = ({ open, setOpen, date }) => {
  const { selectedUnitId } = useContext(FilialContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ total: 0, list: [] });
  const [filter, setFilter] = useState({ limit: 10, page: 0 });

  const ticketTypesList = getTicketTypes().map((type) => ({
    value: type,
    label: getTicketType(type),
  }));

  const columns = [
    {
      label: "Alterado em",
      field: "created_at",
      renderValue: (item) =>
        moment(item.created_at).format("DD/MM/YY HH:mm:ss"),
    },
    {
      label: "Horário alterado",
      field: "time",
    },
    {
      label: "Usuário",
      field: "email",
      renderValue: (item) => item.email?.split("@")[0],
    },
    {
      label: "Total de vagas",
      field: "total_spaces",
      renderValue: (item) =>
        item.before?.total_spaces !== item.after?.total_spaces ? (
          <div>
            {item.before?.total_spaces || 0}
            <br />
            {"->"}
            <br />
            {item.after?.total_spaces || 0}
          </div>
        ) : (
          item.after?.total_spaces
        ),
    },
    {
      label: "Abertura",
      field: "open_for",
      renderValue: (item) =>
        item.before?.open_for !== item.after?.open_for ? (
          <div className="overflow-auto">
            {`(${
              item.before
                ? ticketTypesList
                    .filter((tt) => item.before?.open_for.includes(tt.value))
                    .map((tt) => tt.label)
                    .join(", ")
                : "()"
            })`}
            <br />
            {"->"}
            <br />
            {`(${
              item.after
                ? ticketTypesList
                    .filter((tt) => item.after?.open_for.includes(tt.value))
                    .map((tt) => tt.label)
                    .join(", ")
                : "()"
            })`}
          </div>
        ) : (
          ticketTypesList
            .filter((tt) => item.after?.open_for.includes(tt.value))
            .map((tt) => tt.label)
            .join(", ")
        ),
    },
  ];

  const handleClose = () => {
    if (setOpen) setOpen(null);
  };

  const renderRows = (field, item) => {
    if (!item) return null;
    const data = columns.find((col) => col.field === field);
    if (data?.renderValue) return data.renderValue(item);
    return item[field];
  };

  const handleLimitChange = (newLimit) => {
    setFilter((prev) => ({ ...prev, limit: newLimit }));
  };

  const handlePageChange = (newPage) => {
    setFilter((prev) => ({ ...prev, page: newPage }));
  };

  const getData = () => {
    setLoading(true);
    Api.get(`/calendar_dates_hours_update_log`, {
      params: { ...filter, date, unit_id: selectedUnitId },
    })
      .then((res) => setData(res.data))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (selectedUnitId && moment(date).isValid() && open) getData();
  }, [selectedUnitId, date, open, filter]);

  return (
    <Modal show={open} size="xl">
      <Modal.Header closeButton onHide={handleClose}>
        <Modal.Title>
          Log de alterações {moment(date).format("DD/MM/YYYY")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <ProgressBar animated now={100} />
        ) : (
          <div className="d-flex flex-column gap-3">
            <Table
              columns={columns}
              data={data.list}
              paginated
              limit={filter.limit}
              page={filter.page}
              total={data.total}
              handleLimitChange={handleLimitChange}
              handlePageChange={handlePageChange}
              renderRow={renderRows}
              renderRowItem
            />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CalendarDatesHoursUpdateLogModal;
