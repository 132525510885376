import React, { createContext, useContext, useEffect, useState } from "react";
import Api from "../api/api";
import { AuthContext } from "./AuthContext";

export const FilialContext = createContext();

export default function FilialProvider({ children }) {
  const [filial, setFilial] = useState(
    localStorage.getItem("filial") || "TATUAPE"
  );
  const [unities, setUnities] = useState([]);
  const [selectedUnitId, setSelectedUnitId] = useState("");
  const { userUnities } = useContext(AuthContext);

  useEffect(() => {
    Api.get(`/unities`).then((res) => {
      setUnities(res.data.data);
      if (!selectedUnitId)
        setSelectedUnitId(res.data.data.find((i) => i.var_name === filial)?.id);
    });
  }, []);

  useEffect(() => {
    if (userUnities && userUnities[0]) {
      if (!userUnities.find((unit) => unit === filial)) {
        localStorage.setItem("filial", userUnities[0]);
        setSelectedUnitId(
          unities.find((i) => i.var_name === userUnities[0])?.id
        );
        setFilial(userUnities[0]);
      }
    }
  }, [userUnities]);

  useEffect(() => {
    localStorage.setItem("filial", filial);
    setSelectedUnitId(unities.find((i) => i.var_name === filial)?.id);
  }, [filial]);

  return (
    <FilialContext.Provider
      value={{ filial, setFilial, selectedUnitId, unities }}
    >
      {children}
    </FilialContext.Provider>
  );
}
