import { useContext, useEffect, useState } from "react";
import moment from "moment";
import Api from "../../../api/api";
import { FilialContext } from "../../../context/FilialContext";
import { toast } from "react-toastify";
import { ProgressBar } from "react-bootstrap";
import MonthlyPartiesChart from "./MonthlyPartiesChart";

const TicketsPerMonth = () => {
  const { selectedUnitId } = useContext(FilialContext);
  const [ticketsPerMonthFilter, setTicketsPerMonthFilter] = useState({
    start_date: moment()
      .subtract(6, "months")
      .startOf("month")
      .format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
  });
  const [loadingTicketsPerMonth, setLoadingTicketsPerMonth] = useState(false);
  const [ticketsPerMonth, setTicketsPerMonth] = useState([]);
  const [ticketsPerMonthTotalizer, setTicketsPerMonthTotalizer] = useState(0);

  const getTicketsPerMonth = () => {
    setLoadingTicketsPerMonth(true);
    Api.get(
      `orders_tickets/per_month?${new URLSearchParams({
        ...ticketsPerMonthFilter,
        unit_id: selectedUnitId,
        ticket_type: "parties",
      })}`
    )
      .then((res) => {
        setTicketsPerMonth(res.data.list);
        setTicketsPerMonthTotalizer(res.data.total);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => setLoadingTicketsPerMonth(false));
  };

  useEffect(() => {
    if (selectedUnitId) getTicketsPerMonth();
  }, [ticketsPerMonthFilter, selectedUnitId]);

  const handleMonthlyPartiesFilterChange = (e) => {
    setTicketsPerMonthFilter((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <>
      <h3>Festas Realizadas por Mês</h3>
      <div className="d-flex gap-1 mb-1 flex-wrap align-items-center justify-content-center align-items-sm-start justify-content-sm-start">
        <div className="input-group w-auto flex-0">
          <span className="input-group-text">Início</span>
          <input
            value={
              ticketsPerMonthFilter.start_date
                ? moment(ticketsPerMonthFilter.start_date).format("YYYY-MM-DD")
                : null
            }
            onChange={handleMonthlyPartiesFilterChange}
            name="start_date"
            type="date"
            className="form-control"
            placeholder="Data inicial"
          />
        </div>
        <div className="input-group w-auto flex-0">
          <span className="input-group-text">Fim</span>
          <input
            value={
              ticketsPerMonthFilter.end_date
                ? moment(ticketsPerMonthFilter.end_date).format("YYYY-MM-DD")
                : null
            }
            onChange={handleMonthlyPartiesFilterChange}
            name="end_date"
            type="date"
            className="form-control"
            placeholder="Data final"
          />
        </div>
      </div>
      {loadingTicketsPerMonth && (
        <ProgressBar animated now={100} className="mb-1" />
      )}
      <MonthlyPartiesChart data={ticketsPerMonth} />
      <h4>{`Total: ${ticketsPerMonthTotalizer}`}</h4>
    </>
  );
};

export default TicketsPerMonth;
