import { Modal, Button, Form, Col, Row } from "react-bootstrap";
import { useState } from "react";
import Api from "../../../api/api";
import { toast } from "react-toastify";
import { useContext } from "react";
import { FilialContext } from "../../../context/FilialContext";

export const PointsConsult = ({ open, setOpen }) => {
  const { filial } = useContext(FilialContext);
  const [deletePoints, setDeletePoints] = useState("");
  const [pointsList, setPointsList] = useState([]);
  const [totalPoints, setTotalPoints] = useState(null);

  const handleClose = () => {
    setOpen(false);
  };

  const getList = () => {
    if (!deletePoints.email) {
      toast.error("Digite um email");
      return;
    }

    Api.get(`user/points/removable`, {
      params: { filial: filial, email: deletePoints.email },
    })
      .then((res) => {
        setPointsList(res.data.data.list);
        setTotalPoints(res.data.data.points);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const handleDeletePonts = (e) => {
    const { name, value } = e.target;

    setDeletePoints((prev) => ({
      ...prev,
      [name]: name === "email" ? value.toLowerCase() : value,
    }));
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      getList();
    }
  };

  return (
    <>
      <h5 className="align-self-center">{`Consultar Pontos`}</h5>
      <Modal.Body>
        <Row>
          <Col>
            <label>E-mail do cliente:</label>
            <Form.Control
              placeholder="Digite o e-mail"
              onChange={handleDeletePonts}
              onKeyPress={handleKeyPress}
              name="email"
              type="email"
              value={deletePoints.email || ""}
            />
          </Col>
        </Row>
        <Row className="d-flex mt-3">
          {pointsList.length > 0 && (
            <Row>
              <Col>
                <ul className="p-0 item-list">
                  {pointsList.map((point, index) => (
                    <>
                      <li
                        key={index}
                        className="d-flex justify-content-between m-1"
                      >
                        <div className="d-flex align-items-center">
                          Pontos: {point.points}
                        </div>
                        <div className="d-flex align-items-center">
                          Pontos Usados:{" "}
                          {point.points_used !== null ? point.points_used : 0}
                        </div>
                      </li>
                      <hr />
                    </>
                  ))}
                </ul>
              </Col>
            </Row>
          )}
          {totalPoints !== null && (
            <Row>
              <Col>
                <p>Total de pontos: {totalPoints}</p>
              </Col>
            </Row>
          )}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose} variant="light">
          Fechar
        </Button>
        <Button onClick={getList} variant="primary">
          Confirmar
        </Button>
      </Modal.Footer>
    </>
  );
};
