import { useState, useContext, useEffect } from "react";
import colors from "../../vars.scss";
import Api from "../../api/api";
import { toast } from "react-toastify";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import moment from "moment";
import { FilialContext } from "../../context/FilialContext";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const OccupationPerHourChart = ({ date }) => {
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const { selectedUnitId } = useContext(FilialContext);

  const loading = loadingData;

  const getData = () => {
    setLoadingData(true);
    Api.get(`calendar_dates_hours`, {
      params: { unit_id: selectedUnitId, date },
    })
      .then((res) => {
        setData(res.data.list);
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingData(false));
  };

  useEffect(() => {
    if (date && selectedUnitId) getData();
  }, [date, selectedUnitId]);

  if (!data) data = [];

  const labels = data.map((item) =>
    moment(item.time, "HH:mm:SS").format("HH:mm")
  );

  const renderLoader = () => {
    if (!loading) return;
    return (
      <div className="position-absolute bg-primary bg-opacity-50 w-100 h-100 d-flex align-items-center justify-content-center rounded">
        <span className="text-white fs-3">Carregando dados...</span>
      </div>
    );
  };

  let maxAvailableSpaces = 0;

  data.forEach((item) => {
    if (
      item.hasOwnProperty("total_spaces") &&
      typeof item.available_spaces === "number"
    ) {
      maxAvailableSpaces = Math.max(maxAvailableSpaces, item.total_spaces);
    }
  });

  return (
    <div>
      {renderLoader()}
      <div>
        <Line
          options={{
            responsive: true,
            type: "line",
            plugins: {
              tooltip: {},
              legend: {
                display: true,
                labels: {
                  boxWidth: 15, // Adjust as needed
                  usePointStyle: true,
                },
              },
            },
            tension: 0.35,
            scales: {
              x: {
                title: {
                  display: true,
                  text: "Horário",
                },
              },
              y: {
                title: {
                  display: true,
                  text: "Ocupação",
                },
                min: 0,
                max: maxAvailableSpaces + 10,
                ticks: {
                  // stepSize: 20,
                },
              },
            },
          }}
          startsAtZero
          data={{
            labels,
            datasets: [
              {
                label: "Brincantes",
                data: data.map((item) => Number(item.total_players)),
                borderColor: colors.secondary,
                pointBackgroundColor: data.map((item) =>
                  Number(item.total_players) === Number(item.total_spaces)
                    ? colors.danger
                    : colors.secondary
                ),
                borderWidth: 3,
                pointRadius: 5,
              },
              {
                label: "Não Brincantes",
                data: data.map((item) => Number(item.total_not_players)),
                borderColor: colors.info,
                pointBackgroundColor: colors.info,
                borderWidth: 3,
              },
              {
                label: "Total de Vagas",
                data: data.map((item) => Number(item.total_spaces)),
                borderColor: colors.gray,
                borderWidth: 3,
              },
            ],
          }}
        />
      </div>
    </div>
  );
};

export default OccupationPerHourChart;
