import { useEffect, useState } from "react";
import { Button, Modal, ProgressBar } from "react-bootstrap";
import Api from "../../api/api";
import { toast } from "react-toastify";
import Table from "../Table";
import moment from "moment";
import renamePaymentType from "../../utils/paymentType";
import { genderList, getTransactionStatus } from "../../utils/functions";

const SaleUpdateLogModal = ({ open, setOpen, order_id }) => {
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({ limit: 10, page: 0 });
  const [total, setTotal] = useState(0);
  const [list, setList] = useState([]);

  const handleClose = () => {
    if (setOpen) setOpen(false);
  };

  const getUpdateLog = () => {
    setLoading(true);
    Api.get(`sales_update_log/${order_id}`, { params: filter })
      .then((res) => {
        setTotal(res.data.total);
        setList(res.data.list);
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (open && order_id > 0) getUpdateLog();
  }, [open, order_id, filter]);

  const getType = (type, up_table) => {
    if (type === "new_ticket_birthday_people") return "Novo aniversariante";
    if (type === "deleted_ticket_birthday_people")
      return "Aniversariante removido";
    if (type === "new_order_product") return "Novo produto";
    if (type === "deleted_order_product") return "Produto removido";
    if (type === "new_transaction") return "Nova transação";
    if (type === "deleted_transaction") return "Transação removida";
    if (type === "new_ticket_attachment") return "Novo anexo";
    if (type === "deleted_ticket_attachment") return "Anexo removido";
    if (type === "new_order_buffet_payment") return "Novo pagamento de buffet";
    if (type === "deleted_order_buffet_payment")
      return "Pagamento de buffet removido";
    if (type === "total_not_players") return "Não brincantes totais";
    if (type === "total_players") return "Brincantes totais";
    if (type === "courtesy_not_players") return "Não brincantes cortesia";
    if (type === "courtesy_players") return "Brincantes cortesia";
    if (type === "dps_responsibles") return "Acompanhantes PCDs";
    if (type === "dps") return "PCDs";
    if (type === "minors_responsibles") return "Acompanhantes menores";
    if (type === "minors") return "Menores";
    if (type === "players") return "Brincantes";
    if (type === "not_players") return "Não brincantes";
    if (type === "time") return "Horário ingresso";
    if (type === "date") {
      if (up_table === "orders_tickets") return "Data ingresso";
      if (up_table === "orders_buffets_payments")
        return "Data pagamento buffet";
    }
    if (type === "ticket_id") return "ID Ticket";
    if (type === "total_value") return "Valor total";
    if (type === "coupon_discount_value") return "Valor desconto cupom";
    if (type === "value_with_taxes") return "Valor total com taxas";
    if (type === "value") return "Valor total";
    if (type === "payment_method") return "Forma pagamento";
    if (type === "responsible_id") return "ID Responsável";
    if (type === "authorized_at") return "Data pagamento";
    if (type === "payment_status") return "Status pagamento";
    if (type === "file_url") return "URL";
    if (type === "gender") return "Gênero";
    if (type === "birth_date") return "Data aniversário";
    if (type === "created_at") return "Data criação";
    if (type === "player_price") return "Preço brincante";
    if (type === "not_player_price") return "Preço não brincante";
    if (type === "minor_price") return "Preço menor";
    if (type === "minor_responsible_price") return "Preço acompanhante menor";
    if (type === "dp_price") return "Preço PCD";
    if (type === "dp_responsible_price") return "Preço acompanhante PCD";
    if (type === "type") return "Tipo ingresso";
    if (type === "duration") return "Duração ingresso";
    if (type === "event_duration") return "Duração evento";
    if (type === "bundle_id") return "ID Pacote";
    if (type === "bundle_additional_player_price")
      return "Preço brincante pacote";
    if (type === "bundle_players") return "Brincantes pacote";
    if (type === "status") return "Status";
    return type;
  };

  const getValue = (item, return_col) => {
    let val = item[return_col];
    if (item.type === "payment_method") return renamePaymentType(val);
    if (item.type === "payment_status") return getTransactionStatus(val);
    if (["date", "authorized_at", "created_at"].includes(item.type))
      return val ? moment(val).format("DD/MM/YY HH:mm") : "";
    if (item.type === "birth_date")
      return val ? moment(val).format("DD/MM/YY") : "";
    if (item.type === "status") {
      if (Number(val) === 1) return "Pré-reserva/Não confirmado";
      if (Number(val) === 2) return "Realizada/Confirmado";
      if (Number(val) === 4) return "Reservada";
    }
    if (item.type === "gender")
      return genderList.find((g) => g.value === val)?.label || val;
    return val;
  };

  const columns = [
    {
      label: "Data alteração",
      field: "created_at",
      renderValue: (item) => moment(item.created_at).format("DD/MM/YY HH:mm"),
    },
    {
      label: "Tipo alteração",
      field: "type",
      renderValue: (item) => getType(item.type, item.up_table),
    },
    {
      label: "Valor alterado",
      field: "prev_value",
      renderValue: (item) => getValue(item, "prev_value"),
    },
    {
      label: "Novo valor",
      field: "new_value",
      renderValue: (item) => getValue(item, "new_value"),
    },
    {
      label: "Tabela alteração",
      field: "up_table",
    },
    {
      label: "Usuário",
      field: "user_email",
    },
    {
      label: "Pontos Enviados",
      field: "points_sent",
      renderValue: (item) =>
        [true, "true"].includes(item.points_sent)
          ? "Sim"
          : [false, "false"].includes(item.points_sent)
          ? "Não"
          : "",
    },
    {
      label: "Motivo Atualização",
      field: "auth_reason",
    },
  ];

  const renderRows = (field, item) => {
    if (!item) return null;
    const data = columns.find((col) => col.field === field);
    if (data?.renderValue) return data.renderValue(item);
    return item[field];
  };

  const handleLimitChange = (newLimit) => {
    setFilter((prev) => ({ ...prev, limit: newLimit }));
  };

  const handlePageChange = (newPage) => {
    setFilter((prev) => ({ ...prev, page: newPage }));
  };

  return (
    <Modal show={open} onHide={handleClose} size="xl">
      <Modal.Header>
        <Modal.Title>
          <h5 className="fw-bold">Log de alterações da venda</h5>
        </Modal.Title>
      </Modal.Header>
      {loading && <ProgressBar className="mb-1" animated now={100} />}
      <Modal.Body>
        <Table
          emptyListLabel={loading ? null : "Nenhuma alteração para mostrar."}
          limit={filter.limit}
          limitOptions={[10, 30, 50]}
          page={filter.page}
          paginated
          total={total}
          columns={columns}
          renderRow={renderRows}
          renderRowItem
          data={list}
          handleLimitChange={handleLimitChange}
          handlePageChange={handlePageChange}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose} disabled={loading}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SaleUpdateLogModal;
