import React, { useState, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import moment from "moment";
import Api from "../../api/api";
import { toast } from "react-toastify";
import { FilialContext } from "../../context/FilialContext";
import ProgressBar from "react-bootstrap/ProgressBar";
import { CSVLink } from "react-csv";
import { useEffect } from "react";

const TemplatesInsightModal = ({ open, setOpen }) => {
  const [load, setLoad] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [filter, setFilter] = useState({
    start_date: moment().format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
  });
  const [data, setData] = useState([]);

  const { filial } = useContext(FilialContext);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };

  const downloadInsights = () => {
    setLoad(true);

    const query = new URLSearchParams({ ...filter, filial });

    Api.get(`/template/list/date_range?${query}`)
      .then((res) => {
        setData(
          res.data.data.map((template) => {
            const item = { date: moment(template.date).format("DD/MM/YYYY") };

            template.hours.forEach((hour) => {
              item[hour.hour + "_brincantes"] =
                hour.sold_out - hour.not_players;
              item[hour.hour + "_capacidade"] = hour.desired_availability;
              item[hour.hour + "_taxa_ocupacao_%"] = (
                ((hour.sold_out - hour.not_players) /
                  hour.desired_availability) *
                100
              ).toFixed(2);
            });

            return item;
          })
        );
        setLoaded(true);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setLoad(false);
      });
  };

  useEffect(() => {
    if (!open) setLoaded(false);
  }, [open]);

  return (
    <Modal show={open} onHide={load ? null : handleClose}>
      <Modal.Header>
        <Modal.Title>Relatório de Ocupação</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column gap-1">
        <InputGroup>
          <InputGroup.Text>Data Inicial</InputGroup.Text>
          <Form.Control
            value={moment(filter.start_date).format("YYYY-MM-DD")}
            name={"start_date"}
            max={moment().format("YYYY-MM-DD")}
            type={"date"}
            onChange={handleChange}
          />
        </InputGroup>
        <InputGroup>
          <InputGroup.Text>Data Final</InputGroup.Text>
          <Form.Control
            value={moment(filter.end_date).format("YYYY-MM-DD")}
            name={"end_date"}
            max={moment().format("YYYY-MM-DD")}
            type={"date"}
            onChange={handleChange}
          />
        </InputGroup>
        {load && <ProgressBar animated now={100} />}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose} disabled={load}>
          Fechar
        </Button>
        <Button onClick={downloadInsights} disabled={load}>
          Carregar Dados
        </Button>
        {loaded && (
          <CSVLink
            data={data}
            headers={[
              { label: "Data", key: "date" },
              { label: "10h (brincantes)", key: "10_brincantes" },
              { label: "10h (capacidade)", key: "10_capacidade" },
              { label: "10h (% da capacidade)", key: "10_taxa_ocupacao_%" },
              { label: "11h (brincantes)", key: "11_brincantes" },
              { label: "11h (capacidade)", key: "11_capacidade" },
              { label: "11h (% da capacidade)", key: "11_taxa_ocupacao_%" },
              { label: "12h (brincantes)", key: "12_brincantes" },
              { label: "12h (capacidade)", key: "12_capacidade" },
              { label: "12h (% da capacidade)", key: "12_taxa_ocupacao_%" },
              { label: "13h (brincantes)", key: "13_brincantes" },
              { label: "13h (capacidade)", key: "13_capacidade" },
              { label: "13h (% da capacidade)", key: "13_taxa_ocupacao_%" },
              { label: "14h (brincantes)", key: "14_brincantes" },
              { label: "14h (capacidade)", key: "14_capacidade" },
              { label: "14h (% da capacidade)", key: "14_taxa_ocupacao_%" },
              { label: "15h (brincantes)", key: "15_brincantes" },
              { label: "15h (capacidade)", key: "15_capacidade" },
              { label: "15h (% da capacidade)", key: "15_taxa_ocupacao_%" },
              { label: "16h (brincantes)", key: "16_brincantes" },
              { label: "16h (capacidade)", key: "16_capacidade" },
              { label: "16h (% da capacidade)", key: "16_taxa_ocupacao_%" },
              { label: "17h (brincantes)", key: "17_brincantes" },
              { label: "17h (capacidade)", key: "17_capacidade" },
              { label: "17h (% da capacidade)", key: "17_taxa_ocupacao_%" },
              { label: "18h (brincantes)", key: "18_brincantes" },
              { label: "18h (capacidade)", key: "18_capacidade" },
              { label: "18h (% da capacidade)", key: "18_taxa_ocupacao_%" },
              { label: "19h (brincantes)", key: "19_brincantes" },
              { label: "19h (capacidade)", key: "19_capacidade" },
              { label: "19h (% da capacidade)", key: "19_taxa_ocupacao_%" },
              { label: "20h (brincantes)", key: "20_brincantes" },
              { label: "20h (capacidade)", key: "20_capacidade" },
              { label: "20h (% da capacidade)", key: "20_taxa_ocupacao_%" },
              { label: "21h (brincantes)", key: "21_brincantes" },
              { label: "21h (capacidade)", key: "21_capacidade" },
              { label: "21h (% da capacidade)", key: "21_taxa_ocupacao_%" },
              { label: "22h (brincantes)", key: "22_brincantes" },
              { label: "22h (capacidade)", key: "22_capacidade" },
              { label: "22h (% da capacidade)", key: "22_taxa_ocupacao_%" },
            ]}
          >
            Baixar Relatório
          </CSVLink>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default TemplatesInsightModal;
