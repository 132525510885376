import React from "react";
import Button from "react-bootstrap/Button";

const Row = ({ item, handleRemove, loadRemove }) => {
  const handleRemoveItem = (e) => {
    e.stopPropagation();
    handleRemove(item);
  };

  return (
    <ul className="item-list list-unstyled m-0">
      <li>
        <div className="item-info">
          <div className="d-flex flex-column">
            <span>{item.name}</span>
          </div>
          <Button variant="danger" onClick={handleRemoveItem}>
            Deletar
          </Button>
        </div>
      </li>
    </ul>
  );
};

export default Row;
