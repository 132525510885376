import moment from "moment";
import { Button, Col, Form, FormGroup, Modal, Row } from "react-bootstrap";
import "moment/locale/pt-br";
import { useContext, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import Api from "../../api/api";
import { toast } from "react-toastify";

const RescheduleModal = ({ open, setOpen, calendar_date }) => {
  const { emailLogged, dashboardId } = useContext(AuthContext);
  const hoursList = calendar_date.hours;
  const [selectedTimes, setSelectedTimes] = useState([]);
  const [rescheduleLimit, setRescheduleLimit] = useState("");
  const [confirming, setConfirming] = useState(false);
  const [loadingReschedule, setLoadingReschedule] = useState(false);
  const [result, setResult] = useState(null);

  const handleClose = () => {
    if (loadingReschedule) return;
    setOpen(false);
  };

  const renderItem = (item) => {
    if (!item) return;

    const style = {
      flex: 1,
      minHeight: "2rem",
    };

    const className = `fw-bold`;

    const handleChange = (e) => {
      if (["true", true].includes(e.target.checked))
        setSelectedTimes((prev) => [...prev, item.time]);
      else
        setSelectedTimes((prev) => prev.filter((time) => time !== item.time));
    };

    return (
      <Col key={`hour_item_${item.time}`} xs={12}>
        <div className="d-flex align-items-center justify-content-between">
          <span style={style} className={className}>
            <Form.Check
              value={selectedTimes.includes(item.time)}
              disabled={confirming}
              onChange={handleChange}
            />
          </span>
          <span style={style} className={className}>
            {moment(item.time, "HH:mm:SS").format("HH:mm")}
          </span>
          <span style={style} className={className}>
            {item.available_spaces}
          </span>
          <span style={style} className={className}>
            {item.occupied_spaces}
          </span>
          <span style={style} className={className}>
            {item.total_spaces}
          </span>
        </div>
      </Col>
    );
  };

  const renderHours = () => {
    if (!hoursList || !hoursList[0]) return;

    const style = {
      flex: 1,
      minHeight: "2rem",
    };

    const className = `fw-bolder`;

    return (
      <Row>
        <Col key={`hour_item_header`} xs={12}>
          <div className="d-flex align-items-center justify-content-between">
            <span style={style} className={className}>
              Selecionar
            </span>
            <span style={style} className={className}>
              Horário
            </span>
            <span style={style} className={className}>
              Vagas Disponíveis
            </span>
            <span style={style} className={className}>
              Vagas Ocupadas
            </span>
            <span style={style} className={className}>
              Total de Vagas
            </span>
          </div>
        </Col>
        {hoursList.map((item) => renderItem(item))}
      </Row>
    );
  };

  const handleConfirm = () => {
    if (!confirming) return setConfirming(true);
    if (window.confirm("Confirmar liberação de reagendamento?")) {
      setLoadingReschedule(true);
      Api.post(`orders_tickets/mass_reschedule`, {
        times: selectedTimes,
        date: moment(calendar_date.date).format("YYYY-MM-DD"),
        unit_id: calendar_date.unit_id,
        reschedule_limit: rescheduleLimit,
        releaser_id: dashboardId,
      })
        .then((res) => {
          setResult(res.data);
          toast.success(
            `Reagendamento liberado para ${res.data.length} ticket${
              res.data.length > 1 ? "s" : ""
            }!`
          );
        })
        .catch((err) => toast.error(err.message))
        .finally(() => setLoadingReschedule(false));
    }
  };

  const renderConfirmation = () => {
    if (!confirming) return;
    return (
      <div>
        <div className="fw-bold">{`Responsável pelo reagendamento: ${emailLogged}`}</div>
        <div className="fw-bold">{`Prazo limite do reagendamento:`}</div>
        <div>{`Início do prazo: ${moment().format("DD/MM/YYYY")}`}</div>
        <div className="d-flex flex-column flex-sm-row align-items-sm-center gap-1">
          <span className="">Fim do prazo:</span>
          <Form.Control
            value={rescheduleLimit}
            type="date"
            style={{ flex: 0 }}
            onChange={(e) =>
              setRescheduleLimit(moment(e.target.value).format("YYYY-MM-DD"))
            }
            max={moment().add(3, "months").format("YYYY-MM-DD")}
            min={moment(calendar_date.date).add(1, "day").format("YYYY-MM-DD")}
          />
        </div>
      </div>
    );
  };

  const renderResultItem = (item) => {
    return (
      <Col xs={12} key={`result_item_${item.ticket_id}`}>
        <div className="d-flex align-items-center">{`ID: ${item.ticket_id} ${
          item.success ? "SUCESSO" : `${item.error}`
        }`}</div>
      </Col>
    );
  };

  const renderResult = () => {
    if (!result || !result[0]) return;

    return <Row>{result.map((item) => renderResultItem(item))}</Row>;
  };

  return (
    <Modal size={"lg"} show={open} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>
          Liberar reagendamento de tickets de{" "}
          {moment(calendar_date.date).format("dddd, DD/MM/YYYY")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column gap-1">
          {renderHours()}
          {!confirming && (
            <span>
              Selecione os horários que deseja liberar o reagendamento e clique
              em confirmar para selecionar o prazo final de reagendamento.
            </span>
          )}
          {renderConfirmation()}
          {renderResult()}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="light"
          onClick={handleClose}
          disabled={loadingReschedule}
        >
          {confirming ? "Cancelar" : "Fechar"}
        </Button>
        <Button
          variant="primary"
          onClick={handleConfirm}
          disabled={loadingReschedule}
        >
          {`Confirmar${
            confirming ? " liberação de reagendamento em lote" : ""
          }`}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RescheduleModal;
