export function handleFilialOptions(filial) {
  if (filial === "TATUAPE") {
    return filialOptions.options.tatuape.values;
  } else if (filial === "SANTO_AMARO") {
    return filialOptions.options.santoAmaro.values;
  } else if (filial === "BRASILIA") {
    return filialOptions.options.brasilia.values;
  }
}
export function handleFilialHours(filial, exclusive) {
  if (filial === "TATUAPE") {
    return exclusive
      ? filialOptions.options.tatuape.exclusiveHoursSpaces
      : filialOptions.options.tatuape.hoursSpaces;
  } else if (filial === "SANTO_AMARO") {
    return exclusive
      ? filialOptions.options.santoAmaro.exclusiveHoursSpaces
      : filialOptions.options.santoAmaro.hoursSpaces;
  } else if (filial === "BRASILIA") {
    return exclusive
      ? filialOptions.options.brasilia.exclusiveHoursSpaces
      : filialOptions.options.brasilia.hoursSpaces;
  }
}

export const hours = [
  {
    value: "08:00",
    label: "08:00",
  },
  {
    value: "09:00",
    label: "09:00",
  },
  {
    value: "10:00",
    label: "10:00",
  },
  {
    value: "11:00",
    label: "11:00",
  },
  {
    value: "12:00",
    label: "12:00",
  },
  {
    value: "13:00",
    label: "13:00",
  },
  {
    value: "14:00",
    label: "14:00",
  },
  {
    value: "15:00",
    label: "15:00",
  },
  {
    value: "16:00",
    label: "16:00",
  },
  {
    value: "16:00",
    label: "16:00",
  },
  {
    value: "17:00",
    label: "17:00",
  },
  {
    value: "18:00",
    label: "18:00",
  },
];

export const filialOptions = {
  names: [
    {
      value: "TATUAPE",
      label: "TATUAPE",
    },
    {
      value: "BRASILIA",
      label: "BRASILIA",
    },
    {
      value: "SANTO_AMARO",
      label: "SANTO_AMARO",
    },
  ],
  options: {
    tatuape: {
      values: [
        {
          value: "PopIndoor",
          label: "PopIndoor",
        },
        {
          value: "PopOutdoor",
          label: "PopOutdoor",
        },
      ],
      hoursSpaces: [
        {
          value: "1",
          label: "1hr de diversão",
        },
        {
          value: "2",
          label: "2hrs de diversão",
        },
        {
          value: "3",
          label: "3hrs de diversão",
        },
      ],
      exclusiveHoursSpaces: [
        {
          value: "2",
          label: "2hrs de diversão",
        },
        {
          value: "3",
          label: "3hrs de diversão",
        },
        {
          value: "4",
          label: "4hrs de diversão",
        },
      ],
    },
    brasilia: {
      values: [
        {
          value: "PopOutdoor",
          label: "PopOutdoor",
        },
      ],
      hoursSpaces: [
        {
          value: "1",
          label: "1hr de diversão",
        },
        {
          value: "2",
          label: "2hrs de diversão",
        },
        {
          value: "3",
          label: "3hrs de diversão",
        },
        {
          value: "6",
          label: "6hrs de diversão",
        },
      ],
      exclusiveHoursSpaces: [
        {
          value: "3",
          label: "3hrs de diversão",
        },
        {
          value: "4",
          label: "4hrs de diversão",
        },
      ],
    },
    santoAmaro: {
      values: [
        {
          value: "PopIndoor",
          label: "PopIndoor",
        },
        {
          value: "PopOutdoor",
          label: "PopOutdoor",
        },
        {
          value: "PopJump",
          label: "PopJump",
        },
      ],
      hoursSpaces: [
        {
          value: "1",
          label: "1hr de diversão",
        },
        {
          value: "2",
          label: "2hrs de diversão",
        },
        {
          value: "3",
          label: "3hrs de diversão",
        },
        {
          value: "6",
          label: "6hrs de diversão",
        },
      ],
      exclusiveHoursSpaces: [
        {
          value: "3",
          label: "3hrs de diversão",
        },
        {
          value: "4",
          label: "4hrs de diversão",
        },
      ],
    },
  },
};
