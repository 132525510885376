import { useEffect, useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import Api from "../../api/api";
import ImageUpload from "../../components/ImageUpload";
import AttractionsModal from "./AttractionsModal";

const EditModal = ({ open, setOpen, item, getList }) => {
  const [values, setValues] = useState({});
  const [load, setLoad] = useState(false);
  const [openItems, setOpenItems] = useState(false);

  useEffect(() => {
    setValues({ ...item });
  }, [item]);

  const closeModal = () => {
    setOpen(null);
  };

  const handleChange = (e) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSwitchChange = (e) => {
    setValues((prev) => ({
      ...prev,
      [e.target.name]: Boolean(e.target.checked),
    }));
  };

  const remove = () => {
    if (window.confirm("Deseja realmente remover esta área?")) {
      setLoad(true);
      Api.delete(`park_areas/${values.id}`)
        .then((res) => {
          toast.success("Área do parque removida com sucesso!");
          getList();
          closeModal();
        })
        .catch((err) => {
          toast.error(err.message);
        })
        .finally(() => {
          setLoad(false);
        });
    }
  };

  const save = () => {
    if (!values.name) return toast.warning("Nome da área indefinido!");

    setLoad(true);
    if (values.id)
      return Api.put(`park_areas/${values.id}`, values)
        .then((res) => {
          toast.success("Área do parque atualizada com sucesso!");
          getList();
          setValues({ ...res.data.data });
        })
        .catch((err) => {
          toast.error(err.message);
        })
        .finally(() => {
          setLoad(false);
        });
    Api.post(`park_areas`, values)
      .then((res) => {
        toast.success("Área do parque criada com sucesso!");
        getList();
        setValues({ ...res.data.data });
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setLoad(false);
      });
  };

  return (
    <>
      <Modal show={open} onHide={load ? null : closeModal} size={"md"}>
        <Modal.Body className="d-flex flex-column gap-1">
          <h5>{`${values.id ? "Editar" : "Adicionar"} Área do Parque`}</h5>
          <InputGroup>
            <InputGroup.Text>Nome</InputGroup.Text>
            <Form.Control
              value={values.name}
              name={"name"}
              onChange={handleChange}
              autoFocus
            />
          </InputGroup>
          <InputGroup>
            <InputGroup.Text>Mostrar no site</InputGroup.Text>
            <div className="form-control">
              <Form.Check
                type="checkbox"
                label="Ativo"
                checked={[true, "true"].includes(values.display)}
                name="display"
                onChange={handleSwitchChange}
              />
            </div>
          </InputGroup>
          <InputGroup>
            <InputGroup.Text>Mostrar na venda</InputGroup.Text>
            <div className="form-control">
              <Form.Check
                type="checkbox"
                label="Ativo"
                checked={[true, "true"].includes(values.on_sale)}
                name="on_sale"
                onChange={handleSwitchChange}
              />
            </div>
          </InputGroup>
          {[true, "true"].includes(values.on_sale) && (
            <InputGroup>
              <InputGroup.Text>Obrigatório na venda</InputGroup.Text>
              <div className="form-control">
                <Form.Check
                  type="checkbox"
                  label="Ativo"
                  checked={[true, "true"].includes(values.mandatory_on_sale)}
                  name="mandatory_on_sale"
                  onChange={handleSwitchChange}
                />
              </div>
            </InputGroup>
          )}
          <InputGroup>
            <InputGroup.Text>Limite de Ocupação</InputGroup.Text>
            <Form.Control
              value={values.occupation_limit}
              name={"occupation_limit"}
              onChange={handleChange}
              type={"number"}
              min={0}
            />
          </InputGroup>
          <InputGroup className="flex-column align-items-center justify-content-center">
            <InputGroup.Text className="rounded-0 rounded-top w-100 justify-content-center">
              Avatar
            </InputGroup.Text>
            <div
              className={
                values.avatar
                  ? "border border-primary rounded-0 rounded-bottom m-0 w-100 h-100 pt-1 overflow-hidden"
                  : "border border-primary rounded-0 rounded-bottom m-0 w-100 h-100 overflow-hidden"
              }
            >
              <ImageUpload
                value={values.avatar}
                onChange={handleChange}
                name="avatar"
                variant={"avatar"}
                folderName={"park_areas"}
              />
            </div>
          </InputGroup>
          {values.id && (
            <Button onClick={() => setOpenItems(true)}>Atrações da Área</Button>
          )}
          {values.id && (
            <AttractionsModal
              open={openItems}
              setOpen={setOpenItems}
              data={values}
              getParentList={getList}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={load} variant="light" onClick={closeModal}>
            Cancelar
          </Button>
          {values.id && (
            <Button disabled={load} variant="danger" onClick={remove}>
              Remover
            </Button>
          )}
          <Button disabled={load} variant="primary" onClick={save}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditModal;
