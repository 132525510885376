import { useState } from "react";
import Table from "../../../components/Table";
import api from "../../../api/api";
import { toast } from "react-toastify";
import { useEffect } from "react";
import moment from "moment";

const UserPoints = ({ user_id }) => {
  const [list, setList] = useState([]);
  const [loadingList, setLoadingList] = useState(false);

  const getList = () => {
    setLoadingList(true);
    api
      .get("user/points/dash", { params: { user_id } })
      .then((res) => {
        setList(
          res.data.list.map((item) => {
            item.created_at = moment(item.created_at).format("DD/MM/YYYY");
            item.release_date = item.release_date
              ? moment(item.release_date).format("DD/MM/YYYY")
              : "Pendente";
            item.expiration_date = item.expiration_date
              ? moment(item.expiration_date).format("DD/MM/YYYY")
              : "Indefinida";

            return item;
          })
        );
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingList(false));
  };

  useEffect(() => {
    if (user_id) getList();
  }, [user_id]);

  if (!user_id) return console.error("undefined user_id!");

  return (
    <div className="d-flex flex-column pb-2">
      <span className="fw-bold text-primary mb-2">
        {loadingList ? "Carregando " : ""}Pop Pontos
      </span>
      <Table
        columns={[
          { label: "Pontos", field: "points" },
          { label: "Data de aquisição", field: "created_at" },
          { label: "Data de liberação", field: "release_date" },
          { label: "Data de expiração", field: "expiration_date" },
        ]}
        data={list}
      />
      {!loadingList && !list[0] && (
        <p className="text-muted text-center">Sem pontos para mostrar</p>
      )}
    </div>
  );
};

export default UserPoints;
