import React from "react";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";

const PreviewModal = ({ open, setOpen, values }) => {
  const [previewType, setPreviewType] = useState("page");
  const pagePreview = previewType === "page";

  const handleClose = () => {
    setOpen(false);
  };

  const togglePreview = () => {
    setPreviewType((prev) => (prev === "page" ? "mobile" : "page"));
  };

  return (
    <Modal show={open} onHide={handleClose}>
      <Modal.Title className="align-self-center">Prévia da Atração</Modal.Title>
      <Modal.Body>
        Clique na atração para alternar entre os tamanhos de página e modal.
        <div
          className="d-flex align-items-center flex-column p-2 rounded"
          style={{ backgroundColor: "rgb(237, 80, 70)" }}
        >
          <div
            className="d-flex align-items-end"
            style={{
              width: pagePreview ? 200 : 350,
              height: pagePreview ? 400 : 700,
              backgroundImage: `url(${values?.image})`,
              backgroundPosition: "center",
              borderRadius: 20,
              backgroundSize: "cover",
              cursor: "pointer",
            }}
            onClick={togglePreview}
          >
            <div
              style={{
                background: `linear-gradient(0deg, #6F3B8CCC 80%, transparent 100%)`,
                borderRadius: 20,
              }}
              className="w-100 d-flex flex-column text-white align-items-center justify-content-end text-center user-select-none px-2 py-4"
            >
              <strong
                style={{
                  zIndex: 2,
                  marginTop: 40,
                  fontSize: 14,
                }}
              >
                {values?.title}
              </strong>
              <span style={{ zIndex: 2, fontSize: 14 }}>
                {values?.description}
              </span>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="bg-white">
        <Button variant="light" onClick={handleClose}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PreviewModal;
