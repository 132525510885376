import { useEffect, useState } from "react";
import Api from "../../api/api";
import { toast } from "react-toastify";
import Table from "../../components/Table";
import moment from "moment";

const { Modal, Button, ProgressBar } = require("react-bootstrap");

const CartSuggestionsLogModal = ({ open, setOpen, promotionID }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ list: [], total: 0 });
  const [filter, setFilter] = useState({ page: 0, limit: 10 });

  const columns = [
    {
      field: "created_at",
      label: "Data/Horário",
      renderValue: (item) =>
        moment(item.created_at).format("DD/MM/YY HH:mm:ss"),
    },
    {
      field: "unit_name",
      label: "Unidade",
    },
    {
      field: "code",
      label: "ID Venda",
      renderValue: (item) => item.code.split("-")[0],
    },
    {
      field: "email",
      label: "E-mail",
    },
    {
      field: "date",
      label: "Data de ingreso",
      renderValue: (item) => moment(item.date).format("DD/MM/YY"),
    },
    {
      field: "prev_time",
      label: "Horário original",
    },
    {
      field: "time",
      label: "Horário recomendado",
    },
  ];

  useEffect(() => {
    const getData = () => {
      setLoading(true);
      Api.get(`/promotions_cart_suggestion_times_clicks`, {
        params: { ...filter, promotion_id: promotionID },
      })
        .then((res) => setData(res.data))
        .catch((err) => toast.error(err.message))
        .finally(() => setLoading(false));
    };

    getData();
  }, [open, promotionID, filter]);

  const handleClose = () => {
    if (setOpen) setOpen(false);
  };

  const renderRows = (field, item) => {
    if (!item) return null;
    const data = columns.find((col) => col.field === field);
    if (data?.renderValue) return data.renderValue(item);
    return item[field];
  };

  return (
    <Modal show={open} size={"xl"}>
      <Modal.Header closeButton onHide={handleClose}>
        <Modal.Title>Log de Recomendações</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <ProgressBar animated now={100} />
        ) : (
          <Table
            data={data.list}
            columns={columns}
            renderRow={renderRows}
            renderRowItem
            page={filter.page}
            limit={filter.limit}
            total={data.total}
            paginated
            handlePageChange={(page) => setFilter({ ...filter, page })}
            handleLimitChange={(limit) => setFilter({ ...filter, limit })}
            limitOptions={[10, 20, 30]}
            emptyListLabel={"Não há registros para este log."}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CartSuggestionsLogModal;
