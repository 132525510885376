import axios from 'axios';
import { toast } from 'react-toastify';

export const getCPFCNPJData = async (inputValue) => {
  try {
    const url = process.env.REACT_APP_CPFCNPJ_URL;
    const token = process.env.REACT_APP_CPFCNPJ_TOKEN;
    const pacote = process.env.REACT_APP_CPFCNPJ_PACOTE;

    const response = await axios.get(`${url}/${token}/${pacote}/${inputValue}`);

    if (response.data?.erro) {
      const { erroCodigo, erro } = response.data;
      toast.error(`${erroCodigo}: ${erro}`);
      return null;
    }

    return response.data;
  } catch (error) {
    if (
      error.response && 
      (
        error.response.status === 400 ||
        error.response.status === 401 ||
        (error.response.status === 402 && error.response.data?.erro)
      )
    ) {
      const { erroCodigo, erro } = error.response.data;
      toast.error(`${erroCodigo}: ${erro}`);
    } else {
      console.error('Erro ao buscar dados:', error);
      toast.error('Erro ao buscar dados. Por favor, tente novamente mais tarde.');
    }
    return null;
  }
};