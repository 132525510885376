import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Form, InputGroup, Modal, ProgressBar } from "react-bootstrap";
import { toast } from "react-toastify";
import Api from "../../../api/api";

const EditModal = ({ open, setOpen, id, getList }) => {
  const [loadInfo, setLoadInfo] = useState(false);
  const [load, setLoad] = useState(false);
  const [values, setValues] = useState({});

  const getInfo = () => {
    setLoadInfo(true);
    Api.get(`levels_experiences/${id}`)
      .then((res) => {
        setValues(res.data);
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadInfo(false));
  };

  useEffect(() => {
    if (id !== "new") getInfo();
    else setLoad(false);
  }, [id]);

  const handleClose = () => setOpen(null);

  const handleChange = (e) =>
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const save = () => {
    setLoad(true);
    if (id === "new")
      return Api.post(`levels_experiences`, { ...values })
        .then((res) => {
          getList();
          handleClose();
        })
        .catch((err) => toast.error(err.message))
        .finally(() => setLoad(false));
    Api.put(`levels_experiences/${id}`, { ...values })
      .then((res) => {
        getList();
        handleClose();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoad(false));
  };

  const remove = () => {
    if (window.confirm("Deseja realmente remover este nível?")) {
      setLoad(true);
      Api.delete(`levels_experiences/${values.id}`)
        .then((res) => {
          getList();
          handleClose();
        })
        .catch((err) => toast.error(err.message))
        .finally(() => setLoad(false));
    }
  };

  return (
    <Modal show={open} onHide={load ? null : handleClose}>
      <Modal.Title className="align-self-center">{`${
        id === "new" ? "Novo" : "Editar"
      } Nível de Programa de Pontos`}</Modal.Title>
      <Modal.Body>
        {loadInfo ? (
          <ProgressBar animated now={100} />
        ) : (
          <div className="d-flex flex-column gap-1">
            <InputGroup>
              <InputGroup.Text>Nível</InputGroup.Text>
              <Form.Control
                value={values.level}
                name={"level"}
                onChange={handleChange}
                type={"number"}
                min={1}
                autoFocus
              />
            </InputGroup>
            <InputGroup>
              <InputGroup.Text>XP necessária</InputGroup.Text>
              <Form.Control
                value={values.experience_required}
                name={"experience_required"}
                onChange={handleChange}
                type={"number"}
                min={0}
              />
            </InputGroup>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={load} onClick={handleClose} variant="light">
          Fechar
        </Button>
        {values.id > 0 && (
          <>
            <Button onClick={remove} disabled={load} variant="danger">
              Remover
            </Button>
          </>
        )}
        <Button disabled={load} onClick={save} variant="primary">
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditModal;
