import { Modal, Card, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { useState } from "react";
import Api from "../../api/api";

export const ModalDeletSimulator = ({
  userInfo,
  onCloseModalView,
  openModalView,
  setUpdate,
  getAllSimulationsLists,
}) => {
  const [message, setMessage] = useState("");
  function notify(text) {
    toast.success(text);
  }
  function notifyError(text) {
    toast.error(text);
  }

  function handleDeletTicket(id) {
    if (message === "") {
      return notifyError("Por favor, descreva o motivo da exclusão.");
    }
    Api.put(`/party_simulator/delete_party/${id}`, {
      observations: `${message}`,
    })
      .then((res) => {
        notify("Festa Removida");
        getAllSimulationsLists();
        setMessage("");
        onCloseModalView();
      })
      .catch((e) => notifyError("Ocorreu um erro"));
  }
  return (
    <Modal show={openModalView} onHide={onCloseModalView} size="md">
      <Modal.Header className="bg-primary">
        <h4 className="text-white">Deletar Leads</h4>
      </Modal.Header>
      <Modal.Body>
        <Card.Body>
          <h3 className="text-center">
            Atenção! A Simulação de festa será cancelada junto com o agendamento
            no parque.
            <br /> A exclusão não poderá ser revertida.
          </h3>
          <label htmlFor="" className="text-center">
            <small>
              Para finalizar, descreva o motivo do cancelamento e clique em
              Deletar Transação.
            </small>
          </label>
          <input
            type="text"
            className="form-control"
            value={message}
            required
            onChange={(e) => setMessage(e.target.value)}
          />

          <div className="d-flex justify-content-between mt-4">
            <Button
              onClick={() => handleDeletTicket(userInfo?.id)}
              variant="danger"
            >
              Deletar Transação
            </Button>
            <Button onClick={onCloseModalView}>Fechar</Button>
          </div>
        </Card.Body>
      </Modal.Body>
    </Modal>
  );
};
