import { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import Api from "../../api/api";
import { toast } from "react-toastify";
import ReactSelect from "react-select";
import { customerTypesList } from "../../utils/functions";

const CSVModal = ({ handleClose, headers, filter }) => {
  const [loadingCSV, setLoadingCSV] = useState(false);
  const [values, setValues] = useState([]);
  const [statusesFilter, setStatusesFilter] = useState([]);
  const [customerTypesFilter, setCustomerTypesFilter] = useState([]);

  const onClose = () => {
    if (handleClose) handleClose();
  };

  const renderHeader = (header) => {
    if (!header) return <></>;

    const handleItemChange = (e) => {
      const list = [...headers];
      list.find((item) => item.field === header.field).download =
        e.target.checked;

      setValues(list);
    };

    return (
      <Col key={`csv_header_${header.field}`} xs={12} sm={6}>
        <Form.Check
          checked={[true, "true"].includes(
            values.find((h) => h.field === header.field)?.download
          )}
          className="ms-2"
          onChange={handleItemChange}
          label={header.label}
        />
      </Col>
    );
  };

  const renderHeaders = () => {
    if (!headers || !headers[0]) return <></>;

    return (
      <Row className="g-0">{headers.map((header) => renderHeader(header))}</Row>
    );
  };

  const handleSubmit = () => {
    setLoadingCSV(true);
    Api.post(`orders/csv`, {
      headers: values.filter((item) => item.download),
      filter: {
        ...filter,
        statuses: statusesFilter.map((stat) => stat.value),
        customer_types: customerTypesFilter.map((stat) => stat.value),
      },
    })
      .then((res) => {
        const blob = new Blob([res.data], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        window.open(url, "_top");
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingCSV(false));
  };

  const handleCheckAll = () => {
    setValues([...headers.map((h) => ({ ...h, download: true }))]);
  };

  const handleUncheckAll = () => {
    setValues([...headers.map((h) => ({ ...h, download: false }))]);
  };

  const statuses = [
    {
      value: "pending",
      label: "AGUARDANDO PAGAMENTO",
    },
    {
      value: "used",
      label: "USO CONFIRMADO",
    },
    {
      value: "authorized",
      label: "PAGAMENTO CONFIRMADO",
    },
    {
      value: "voided",
      label: "ESTORNADO",
    },
    {
      value: "canceled",
      label: "CANCELADO",
    },
    {
      value: "expired",
      label: "EXPIRADO",
    },
    {
      value: "transaction_failed",
      label: "FALHA NA TRANSAÇÃO",
    },
    {
      value: "failed",
      label: "FALHA NO PAGAMENTO",
    },
  ];

  return (
    <Modal onHide={onClose} show={true} size="lg">
      <Modal.Header>
        <Modal.Title>Baixar CSV das vendas</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column border p-2 mb-2 rounded gap-2 border-primary">
          <span className="fs-5">Filtros:</span>
          <ReactSelect
            placeholder="Status"
            label="Status"
            options={statuses}
            isMulti
            value={statusesFilter}
            onChange={setStatusesFilter}
            classNamePrefix="react-select"
          />
          <ReactSelect
            placeholder="Tipos de Cliente"
            label="Tipos de Cliente"
            options={customerTypesList}
            isMulti
            value={customerTypesFilter}
            onChange={setCustomerTypesFilter}
            classNamePrefix="react-select"
          />
        </div>
        {renderHeaders()}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose} disabled={loadingCSV} variant="light">
          Fechar
        </Button>
        <Button
          variant={"outline-primary"}
          onClick={handleUncheckAll}
          disabled={loadingCSV}
        >
          Desmarcar Tudo
        </Button>
        <Button
          variant={"outline-primary"}
          onClick={handleCheckAll}
          disabled={loadingCSV}
        >
          Marcar Tudo
        </Button>
        <Button onClick={handleSubmit} disabled={loadingCSV}>
          Baixar CSV
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CSVModal;
