import React from "react";
import { Button, Modal } from "react-bootstrap";

const PartnerValidationModal = ({
  open,
  handleClose,
  confirmTicketUsage,
  ticket,
}) => {
  const handleConfirmUsage = () => {
    confirmTicketUsage(true);
    handleClose();
  };

  const handleOpen = () => {
    window.open(ticket.validation_example);
  };

  return (
    <Modal show={open} onHide={null}>
      <Modal.Title className="text-center">Validação de Parceiro</Modal.Title>
      <Modal.Body className="d-flex flex-column align-items-center">
        <div className="d-flex justify-content-between font-weight-bold w-100">
          <h4>Parceiro: {ticket.partner_name}</h4>
        </div>
        <div className="d-flex justify-content-between font-weight-bold">
          <span style={{ whiteSpace: "pre-line" }}>
            {ticket.validation_text ||
              "Confirme o cadastro do cliente com a parceria."}
          </span>
        </div>
        {ticket.validation_example && (
          <img
            src={ticket.validation_example}
            alt="validation_example"
            style={{
              width: "10rem",
              height: "10rem",
              objectFit: "contain",
              cursor: "pointer",
            }}
            onClick={handleOpen}
          />
        )}
        {ticket.validation_url && (
          <Button onClick={() => window.open(ticket.validation_url)}>
            Abrir documento/lista
          </Button>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn-light" onClick={handleClose}>
          Cancelar
        </Button>
        <Button onClick={handleConfirmUsage}>Confirmar Uso</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PartnerValidationModal;
