import colors from "../../vars.scss";
import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import moment from "moment";
import renamePaymentType from "../../utils/paymentType";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const PaymentMethodTotalizersChart = ({ data, loading }) => {
  if (!data) data = [];

  const labels = data.map((item) => renamePaymentType(item.payment_method));

  const getTooltipLabel = (item) => {
    const value = Number(item.dataset.data[item.dataIndex]);
    return ` ${value.toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    })}`;
  };

  const renderLoader = () => {
    if (!loading) return;
    return (
      <div className="position-absolute bg-primary bg-opacity-50 w-100 h-100 d-flex align-items-center justify-content-center rounded">
        <span className="text-white fs-3">Carregando dados...</span>
      </div>
    );
  };

  return (
    <div className="position-relative">
      {renderLoader()}
      <div>
        <Bar
          options={{
            responsive: true,
            type: "bar",
            plugins: {
              tooltip: {
                callbacks: { label: getTooltipLabel },
              },
              legend: { display: false },
            },
            tension: 0.35,
            scales: {
              x: {
                title: {
                  display: true,
                  text: "Forma de pagamento",
                },
              },
              y: {
                title: {
                  display: true,
                  text: "Total em vendas",
                },
                min: 0,
                ticks: {
                  stepSize: 1,
                },
              },
            },
          }}
          startsAtZero
          data={{
            labels,
            datasets: [
              {
                data: data.map((item) => Number(item.total)),
                borderColor: colors.success,
                borderWidth: 5,
              },
            ],
          }}
        />
      </div>
    </div>
  );
};

export default PaymentMethodTotalizersChart;
