import { HiPhoto } from "react-icons/hi2";
import { BsFillCalendarCheckFill, BsPlusCircleFill } from "react-icons/bs";
import { HiTicket } from "react-icons/hi2";
import { RiCouponFill, RiFileEditFill, RiTeamLine } from "react-icons/ri";
import {
  MdOutlineGroupWork,
  MdAttractions,
  MdOutlineAttachMoney,
  MdStars,
} from "react-icons/md";
import { FaTicketAlt } from "react-icons/fa";
import { AiOutlineForm, AiOutlineLineChart } from "react-icons/ai";
import { FiPackage, FiUsers } from "react-icons/fi";
import { IoCalendar } from "react-icons/io5";
import { GiConfirmed, GiPartyHat } from "react-icons/gi";
import { FaFileCsv, FaHandshake, FaQuestionCircle } from "react-icons/fa";
import { BiSolidDiscount } from "react-icons/bi";
import { MdFastfood } from "react-icons/md";

export const routes = [
  {
    title: "Consolidado de Vendas",
    route: "/",
    icon: AiOutlineLineChart,
  },
  {
    title: "Consolidado de Uso",
    route: "/vendas",
    icon: GiConfirmed,
  },
  {
    title: "Tickets",
    route: "/tickets",
    icon: HiTicket,
  },
  {
    title: "Ingressos Vendidos",
    route: "/ingressos-vendidos",
    icon: FaTicketAlt,
  },
  {
    title: "Nova Venda",
    route: "/nova-venda",
    icon: BsPlusCircleFill,
  },
  {
    title: "Calendário",
    route: "/calendar",
    icon: BsFillCalendarCheckFill,
  },
  {
    title: "Gerenciar Calendário",
    route: "/calendar-managerment",
    icon: IoCalendar,
  },
  {
    title: "Pacotes de Festas",
    route: "/pacotes-festas",
    icon: FiPackage,
  },
  {
    title: "Nova Festa",
    route: "/festas",
    icon: AiOutlineForm,
  },
  {
    title: "Gerenciador de Festas",
    route: "/gerenciar-festa",
    icon: RiFileEditFill,
  },
  {
    title: "Pagamentos de Festas",
    route: "/pagamentos-festas",
    icon: MdOutlineAttachMoney,
  },
  {
    title: "Gerenciador de Leads",
    route: "/gerenciar-leads",
    icon: GiPartyHat,
  },
  {
    title: "Usuários",
    route: "/users",
    icon: FiUsers,
  },
  {
    title: "Departamentos",
    route: "/departamentos",
    icon: RiTeamLine,
  },
  {
    title: "Grupos de Permissões",
    route: "/grupos-permissoes",
    icon: MdOutlineGroupWork,
  },
  {
    title: "Relatório de Vendas",
    route: "/csv-page",
    icon: FaFileCsv,
  },
  {
    title: "Gerenciar Buffets",
    route: "/gerenciar-buffets",
    icon: MdFastfood,
  },
  {
    title: "Gerenciar Cupons",
    route: "/gerenciar-cupons",
    icon: RiCouponFill,
  },
  {
    title: "Promoções",
    route: "/promocoes",
    icon: BiSolidDiscount,
  },
  {
    title: "Parceiros",
    route: "/parceiros",
    icon: FaHandshake,
  },
  {
    title: "Áreas do Parque",
    route: "/areas-parque",
    icon: MdAttractions,
  },
  {
    title: "Dúvidas",
    route: "/duvidas",
    icon: FaQuestionCircle,
  },
  {
    title: "Programas de Pontos",
    route: "/programas-pontos",
    icon: MdStars,
  },
  {
    title: "Álbuns",
    route: "/albuns",
    icon: HiPhoto,
  },
];
