import React, { useEffect } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "./Row";
import Button from "react-bootstrap/Button";

const List = ({
  selectedItem,
  setSelectedItem,
  getList,
  list,
  load,
  handleRemove,
  loadRemove,
}) => {
  useEffect(() => {
    getList();
  }, []);

  const handleNewItem = () => {
    setSelectedItem({ name: "" });
  };

  return (
    <div className="d-flex flex-column gap-1">
      <span style={{ display: "flex", alignItems: "center" }}>
        Lista de Categorias de Dúvidas:
      </span>
      <Button className="w-100" onClick={handleNewItem}>
        Nova Categoria de Dúvidas
      </Button>
      {load && <ProgressBar animated now={100} className="mb-1" />}
      {!list[0] && !load && (
        <p style={{ textAlign: "center" }}>Não há categorias de dúvidas.</p>
      )}
      <ListGroup className="rounded-box">
        {list.map((item, index) => (
          <Row
            key={"doubts_category_" + item.id}
            item={item}
            setSelectedItem={setSelectedItem}
            selectedItem={selectedItem}
            handleRemove={handleRemove}
            loadRemove={loadRemove}
          />
        ))}
      </ListGroup>
    </div>
  );
};

export default List;
