import { Modal, Button, Row, Col } from "react-bootstrap";
import { useState } from "react";
import { PointsDelete } from "../PointsDelete";
import { PointsTransfer } from "../PointsTransfer";
import { PointsConsult } from "../PointsConsult";

export const PanelPoints = ({ open, setOpen }) => {
  const [activeButton, setActiveButton] = useState(null);
  const [openPointsDelete, setOpenPointsDelete] = useState(false);
  const [openPointsTransfer, setOpenPointsTransfer] = useState(false);
  const [openPointsConsult, setOpenPointsConsult] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeletePoints = () => {
    setActiveButton('delete');
    setOpenPointsConsult(false);
    setOpenPointsTransfer(false);
    setOpenPointsDelete(true);
  };

  const handlePointsTransfer = () => {
    setActiveButton('transfer');
    setOpenPointsConsult(false);
    setOpenPointsDelete(false);
    setOpenPointsTransfer(true);
  };

  const handlePointsConsult = () => {
    setActiveButton('consult');
    setOpenPointsDelete(false);
    setOpenPointsTransfer(false);
    setOpenPointsConsult(true);
  };

  return (
    <Modal show={open} onHide={handleClose} size={"lg"} centered>
      <Modal.Header closeButton>
        <Modal.Title className="align-self-center">{`O que deseja realizar?`}</Modal.Title>
      </Modal.Header>
      <Row className="d-flex justify-content-center flex-wrap mt-3 gap-2">
        <Col className="text-center">
          <Button
            onClick={handlePointsConsult}
            variant={activeButton !== 'consult' ? 'primary' : 'outline-primary'}
          >
            Consultar pontos
          </Button>
        </Col>
        <Col className="text-center">
          <Button
            onClick={handlePointsTransfer}
            variant={activeButton !== 'transfer' ? 'secondary' : 'outline-secondary'}
          >
            Enviar pontos
          </Button>
        </Col>
        <Col className="text-center">
          <Button
            onClick={handleDeletePoints}
            variant={activeButton !== 'delete' ? 'danger' : 'outline-danger'}
          >
            Apagar pontos
          </Button>
        </Col>
      </Row>
      <Modal.Body>
        {openPointsDelete && (
          <PointsDelete open={openPointsDelete} setOpen={setOpenPointsDelete} />
        )}
        {openPointsTransfer && (
          <PointsTransfer
            open={openPointsTransfer}
            setOpen={setOpenPointsTransfer}
          />
        )}
        {openPointsConsult && (
          <PointsConsult
            open={openPointsConsult}
            setOpen={setOpenPointsConsult}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};
