import { v4 } from "uuid";
import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { HiTrash } from "react-icons/hi2";
import Api from "../../../../api/api";
import { toast } from "react-toastify";
import axios from "axios";

const Attachments = ({ handleNext, handleCancel, sale, setSale }) => {
  const [loadingUpload, setLoadingUpload] = useState({});

  const renderAttachment = (attachment) => {
    const handleChange = (e) => {
      attachment[e.target.name] = e.target.value;
      setSale({ ...sale });
    };

    const handleRemove = () => {
      setSale((prev) => ({
        ...sale,
        ticket_attachments: prev.ticket_attachments.filter(
          (item) => item.id !== attachment.id
        ),
      }));
    };

    const handleFileChange = (e) => {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      formData.append("folder_name", "attachments");

      setLoadingUpload((prev) => ({ ...prev, [attachment.id]: true }));
      const token = localStorage.getItem("token");
      axios
        .post(`${process.env.REACT_APP_BASE_URL}upload/file`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          attachment.file_url = res.data.data;
          setSale({ ...sale });
        })
        .catch((err) => toast.error(err.response.data.message))
        .finally(
          setLoadingUpload((prev) => ({ ...prev, [attachment.id]: false }))
        );
    };

    return (
      <Col xs={12} sm={6} key={`birthday_person_item_${attachment.id}`}>
        <div className="d-flex flex-column gap-1 border border-primary rounded p-1 pt-3">
          <Button
            variant="outline-danger"
            size="sm"
            className="rounded-circle position-absolute end-0 top-0 me-3 mt-1"
            onClick={handleRemove}
          >
            <HiTrash />
          </Button>
          <div className="d-flex flex-column gap-1">
            <span>Tipo de anexo</span>
            <Form.Select
              value={attachment.type}
              name={"type"}
              onChange={handleChange}
            >
              <option hidden>Selecione o tipo do anexo</option>
              <option value="party_contract">Contrato de Festa</option>
              <option value="menu_contract">Contrato de Cardápio</option>
              <option value="guests_list">Lista de Convidados</option>
            </Form.Select>
          </div>
          <div className="d-flex flex-column gap-1">
            <span>Arquivo</span>
            <Form.Control
              value={attachment.file}
              name={"file"}
              type={"file"}
              onChange={handleFileChange}
              disabled={loadingUpload[attachment.id]}
            />
          </div>
        </div>
      </Col>
    );
  };

  const renderAttachments = () => {
    if (!sale || !sale.ticket_attachments) return;

    return sale.ticket_attachments.map((bp) => renderAttachment(bp));
  };

  const handleAddAttachment = () => {
    setSale((prev) => ({
      ...prev,
      ticket_attachments: [
        ...prev.ticket_attachments,
        { id: v4(), type: "", file_url: 1 },
      ],
    }));
  };

  return (
    <div className="d-flex flex-column gap-1 bg-white rounded p-1">
      <Row className="g-4">
        {renderAttachments()}
        <Col xs={12}>
          <Button
            onClick={handleAddAttachment}
            variant="outline-primary"
            className="w-100"
          >
            Adicionar anexo
          </Button>
        </Col>
      </Row>
      {(handleCancel || handleNext) && (
        <div className="d-flex gap-1 ml-auto">
          {handleCancel && (
            <Button variant="light" onClick={handleCancel}>
              Cancelar
            </Button>
          )}
          {handleNext && <Button onClick={handleNext}>Avançar</Button>}
        </div>
      )}
    </div>
  );
};

export default Attachments;
