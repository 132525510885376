import { Modal, Button, Form, Col, Row } from "react-bootstrap";
import { useState } from "react";
import Api from "../../../api/api";
import { toast } from "react-toastify";
import { useContext } from "react";
import { FilialContext } from "../../../context/FilialContext";

export const PointsDelete = ({ open, setOpen }) => {
  const { filial } = useContext(FilialContext);
  const [deletePoints, setDeletePoints] = useState("");
  const [pointsList, setPointsList] = useState([]);
  const [totalPoints, setTotalPoints] = useState(null);

  const handleClose = () => {
    setOpen(false);
  };

  const getList = () => {
    if (!deletePoints.email) {
      toast.error("Digite um email");
      return;
    }

    Api.get(`user/points/removable`, {
      params: { filial: filial, email: deletePoints.email },
    })
      .then((res) => {
        setPointsList(res.data.data.list);
        setTotalPoints(res.data.data.points);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const handleDeletePoints = (id) => {
    console.log("id", id);
    Api.delete(`user/points/${id}`)
      .then((res) => {
        setPointsList(pointsList.filter((point) => point.id !== id));
        toast.success("Pontos deletados com sucesso!");
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const handleDeletePonts = (e) => {
    const { name, value } = e.target;

    setDeletePoints((prev) => ({
      ...prev,
      [name]: name === "email" ? value.toLowerCase() : value,
    }));
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      getList();
    }
  };

  return (
    <>
      <h5 className="align-self-center">Deletar Pontos</h5>
      <Modal.Body>
        <Row>
          <Col>
            <label>E-mail do cliente:</label>
            <Form.Control
              placeholder="Digite o e-mail"
              onChange={handleDeletePonts}
              onKeyPress={handleKeyPress}
              name="email"
              type="email"
              value={deletePoints.email || ""}
            />
          </Col>
        </Row>
        {pointsList.length > 0 && (
          <Row>
            <Col>
              <ul className="p-0 mt-3 item-list">
                {pointsList.map((point, index) => (
                  <>
                    <li
                      key={index}
                      className="d-flex justify-content-between m-1"
                    >
                      <div className="d-flex align-items-center">
                        Pontos: {point.points}
                      </div>
                      <Button
                        onClick={() => handleDeletePoints(point.id)}
                        variant="danger"
                      >
                        Deletar
                      </Button>
                    </li>
                    <hr />
                  </>
                ))}
              </ul>
            </Col>
          </Row>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose} variant="light">
          Fechar
        </Button>
        <Button onClick={getList} variant="primary">
          Confirmar
        </Button>
      </Modal.Footer>
    </>
  );
};
