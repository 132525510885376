import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Api from "../../../api/api";
import { toast } from "react-toastify";
import moment from "moment";

const RescheduleModal = ({ open, setOpen, order_ticket_id }) => {
  const initRescheduleData = {
    reschedule_limit: "",
  };
  const [rescheduleData, setRescheduleData] = useState({
    ...initRescheduleData,
  });
  const [loadingReleaseReschedule, setLoadingReleaseReschedule] =
    useState(false);

  useEffect(() => {
    if (open) setRescheduleData({ ...initRescheduleData });
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleReleaseReschedule = () => {
    setLoadingReleaseReschedule(true);
    Api.put(
      `/orders_tickets/release_reschedule/${order_ticket_id}`,
      rescheduleData
    )
      .then((res) => {
        toast.success("Reagendamento liberado com sucesso!");
        handleClose();
      })
      .catch((err) => {
        toast.error(err.message || "Não foi possível liberar o reagendamento!");
      })
      .finally(() => setLoadingReleaseReschedule(false));
  };

  const handleChange = (e) => {
    if (e.target.name === "reschedule_limit")
      e.target.value = moment(e.target.value).format("YYYY-MM-DD");

    setRescheduleData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <Modal show={open} onHide={loadingReleaseReschedule ? null : handleClose}>
      <Modal.Title>Liberação de Reagendamento de Ingresso</Modal.Title>
      <Modal.Body>
        <Form.Label>Data máxima de ingresso</Form.Label>
        <Form.Control
          value={rescheduleData.reschedule_limit}
          onChange={handleChange}
          name={"reschedule_limit"}
          type={"date"}
          min={moment().toJSON()}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="light"
          onClick={handleClose}
          disabled={loadingReleaseReschedule}
        >
          Cancelar
        </Button>
        <Button
          variant="primary"
          onClick={handleReleaseReschedule}
          disabled={
            loadingReleaseReschedule || !rescheduleData.reschedule_limit
          }
        >
          Liberar Reagendamento
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RescheduleModal;
