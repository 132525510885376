import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import Api from "../../api/api";
import ConfirmModal from "../NewSale2/ConfirmUsage/ConfirmModal";

const ViewSaleModal = ({ open, setOpen, code }) => {
  const [order, setOrder] = useState({});
  const [loadingData, setLoadingData] = useState(false);

  const getData = () => {
    setLoadingData(true);
    Api.get(`orders/code/${code}`)
      .then((res) => setOrder(res.data.data))
      .catch((err) => {
        handleClose();
        toast.error(err.message);
      })
      .finally(() => setLoadingData(false));
  };

  useEffect(() => {
    if (code && open) getData();
  }, [code, open]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ConfirmModal
      open={open}
      setOpen={setOpen}
      order={order}
      setTicket={setOrder}
      handleClose={handleClose}
      loading={loadingData}
      readOnly
    />
  );
};

export default ViewSaleModal;
