import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import ProgressBar from "react-bootstrap/ProgressBar";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "./Row";
import Api from "../../../../api/api";

const List = ({ permissionGroup, getPermissionGroupModules, setOpen }) => {
  const [list, setList] = useState([]);
  const [load, setLoad] = useState(false);

  if (typeof window !== "undefined") {
    injectStyle();
  }

  const getList = () => {
    setLoad(true);
    Api.get(
      `dashboard_modules?${new URLSearchParams({
        not_in_permission_group_id: permissionGroup?.id,
      })}`
    )
      .then((res) => {
        setLoad(false);
        setList(res.data.data);
      })
      .catch((err) => {
        setLoad(false);
        toast.error(err.message);
      });
  };

  useEffect(() => {
    if (permissionGroup?.id) getList();
  }, [permissionGroup]);

  const handleClick = (dashboard_module_id) => {
    setLoad(true);
    Api.post(`permission_groups_modules`, {
      dashboard_module_id,
      permission_group_id: permissionGroup.id,
    })
      .then((res) => {
        setLoad(false);
        setOpen(false);
        getPermissionGroupModules();
      })
      .catch((err) => {
        setLoad(false);
        toast.error(err.message);
      });
  };

  return (
    <>
      <h5>
        {`Lista de módulos disponíveis para adicionar ao grupo ${permissionGroup.name}:`}
      </h5>
      {load && <ProgressBar animated now={100} className="mb-1" />}
      <ListGroup>
        {!list[0] && !load && (
          <p style={{ textAlign: "center" }}>
            Não há mais módulos a serem adicionados ao grupo de permissões.
          </p>
        )}
        {list.map((item, index) => (
          <Row
            key={"not_in_permission_group_module_" + item.id}
            item={item}
            onClick={() => handleClick(item.id)}
          />
        ))}
      </ListGroup>
    </>
  );
};

export default List;
