import { format } from "date-fns";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  FloatingLabel,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import { toast } from "react-toastify";
import Api from "../../api/api";

export function ModalPartyInfoSimulator({
  isOpen,
  onClose,
  user,
  setUpdate,
  getAllSimulationsLists,
}) {
  const [paymentDelete, setPaymentDelete] = useState(false);
  const [indexPayment, setIndexPayment] = useState();
  const [moreInfo, setMoreInfo] = useState("");
  const [moreInfoArray, setMoreinfoArray] = useState([]);
  const [select, setSelect] = useState(0);
  const email = localStorage.getItem("email");
  const [updateList, setUpdateList] = useState(1);
  function dateFormated(date) {
    const dataInput = date;
    const data = new Date(dataInput);
    const dataFormatada = data.toLocaleDateString("pt-BR", { timeZone: "UTC" });
    return dataFormatada;
  }
  function handleValue() {
    let filteredPayments = [];
    const paymentDetails = user?.payment_details;
    const filterPayment = paymentDetails?.filter(
      (payment) => payment.status === "paid"
    );
    const count = filterPayment?.map((item) => item.amount);
    const dataPrice = count?.reduce((value, index) => value + index, 0);
    filteredPayments?.push(filterPayment);
    return dataPrice;
  }
  function handleTime(value) {
    if (value >= "05:00" && value <= "13:00") {
      return "Manhã";
    } else if (value >= "13:00" && value <= "18:00") {
      return "Tarde";
    } else {
      return "Noite";
    }
  }
  // function handleEndParty(value) {
  //   const splitEd = value?.ticket_time.split(":");
  //   const sum = Number(splitEd[0]) + value?.duration;
  //   return <>{sum}:00</>;
  // }
  const openInNewTab = (url) => {
    // 👇️ setting target to _blank with window.open
    window.open(url, "_blank", "noopener,noreferrer");
  };
  function sumAllTickets() {
    const sum =
      user?.regular_tickets +
      user?.plus_one_tickets +
      user?.kids_tickets +
      user?.kids_plus_one_tickets +
      user?.pcd_tickets +
      user?.pcd_plus_one_tickets +
      user?.courtesy_players +
      user?.courtesy_not_players;
    return sum;
  }

  function stringifyProducts(value, value_2, value_3) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >
        <div className="text-success font-weight-bold">
          Meia antiderrapante: {value}X
        </div>
        <div className="text-success font-weight-bold">Meias: {value_2}X</div>
        <div className="text-success font-weight-bold">Toalha: {value_3}X</div>
      </div>
    );
  }

  function handleValueRestant() {
    let filteredPayments = [];
    const paymentDetails = user?.payment_details;
    const filterPayment = paymentDetails?.filter(
      (payment) => payment.status === "paid"
    );
    const count = filterPayment?.map((item) => item.amount);
    const dataPrice = count?.reduce((value, index) => value + index, 0);
    filteredPayments?.push(filterPayment);
    return user?.ticket_total - dataPrice;
  }

  // function namedPartyType(value) {
  //   if (value === "single") {
  //     return "Avulsa";
  //   } else if (value === "exclusive") {
  //     return "Exclusiva";
  //   } else {
  //     return value;
  //   }
  // }
  function handleEndParty(value) {
    const splitEd = value?.time?.split(":");
    const sum = Number(splitEd[0]) + value?.duration;
    return <>{sum}:00</>;
  }
  function handleSpaces(spacesList) {
    const list = [...(spacesList || [{}]).map((space) => space.label)];

    return <p className="text-success font-weight-bold">{list.join(", ")}</p>;
  }

  function handleUpdate(value) {
    if (value === 1 || value === "1") {
      try {
        Api.put(`/party_simulator/update_party/${user?.id}`, {
          crm: false,
          scheduled: false,
          scheduled_by: "",
          additional_info: moreInfo,
          doubts: false,
        })
          .then((res) => {
            if (res.status === 200) {
              setUpdate((prev) => prev + 1);
              toast.success("Festa em Duvidas!");
            } else {
              toast.error("Erro ao agendar festa!");
            }

            onClose();
            getAllSimulationsLists();
          })
          .catch((e) => {
            toast.error("Erro ao agendar festa!");
            onClose();
            return e;
          });
      } catch (e) {
        return e;
      }
    } else if (value === 2 || value === "2") {
      try {
        Api.put(`/party_simulator/update_party/${user?.id}`, {
          crm: false,
          scheduled: true,
          scheduled_by: email,
          additional_info: moreInfo,
          doubts: false,
        })
          .then((res) => {
            if (res.status === 200) {
              setUpdate((prev) => prev + 1);
              toast.success("Festa em agendamento!");
            } else {
              toast.error("Erro ao agendar festa!");
            }

            onClose();
            getAllSimulationsLists();
          })
          .catch((e) => {
            toast.error("Erro ao agendar festa!");
            onClose();
            return e;
          });
      } catch (e) {
        return e;
      }
    } else if (value === 3 || value === "3") {
      try {
        Api.put(`/party_simulator/update_party/${user?.id}`, {
          crm: true,
          scheduled: true,
          scheduled_by: email,
          additional_info: moreInfo,
          doubts: false,
        })
          .then((res) => {
            if (res.status === 200) {
              setUpdate((prev) => prev + 1);
              toast.success("Festa enviado ao crm!");
            } else {
              toast.error("Erro ao agendar festa!");
            }
            onClose();
            getAllSimulationsLists();
          })
          .catch((e) => {
            toast.error("Erro ao agendar festa!");
            onClose();
            return e;
          });
      } catch (e) {
        return e;
      }
    } else if (value === 4 || value === "4") {
      try {
        Api.put(`/party_simulator/update_party/${user?.id}`, {
          crm: false,
          scheduled: false,
          scheduled_by: email,
          additional_info: moreInfo,
          doubts: true,
        })
          .then((res) => {
            if (res.status === 200) {
              setUpdate((prev) => prev + 1);
              toast.success("Festa Enviada para oportunidade perdida");
            } else {
              toast.error("Erro ao agendar festa!");
            }
            onClose();
            getAllSimulationsLists();
          })
          .catch((e) => {
            toast.error("Erro ao agendar festa!");
            onClose();
            return e;
          });
      } catch (e) {
        return e;
      }
    } else if (value === 0) {
      alert("Selecione uma opção!");
    }
  }
  function handleDeletePayment(value) {
    const filteredPayments = user?.payment_details?.filter(
      (payment, index) => index !== value
    );
  }
  function modalConfirm(value) {
    setPaymentDelete(true);
    setIndexPayment(value);
    onClose();
  }

  const handleListMoreInfo = () => {
    return (
      <Row className="gap-1 flex-column">
        {moreInfoArray.length > 0 &&
          moreInfoArray.map((item, i) => (
            <Col
              key={item?.id}
              lg="12"
              style={{
                border: "1px solid black",
                padding: "1rem",
                maxWidth: "25rem",
              }}
              className={`${
                i === moreInfoArray.length - 1
                  ? "bg-primary text-white"
                  : "bg-secondary text-white"
              }`}
            >
              <li>{format(new Date(item?.created_at), "dd/MM/yyyy (H:mm)")}</li>
              <br />
              <li>{item?.info}</li>
            </Col>
          ))}
      </Row>
    );
  };
  // moreInfoArray.length > 0 &&
  //   moreInfoArray.map((item) => (
  //     setMoreInfo(`${item?.info}`)
  //   ));
  return (
    <>
      <Modal
        show={paymentDelete}
        onHide={() => setPaymentDelete(false)}
        size="md"
        className="mt-5"
      >
        <Card.Header className="bg-primary rounded-0">
          <Card.Title className="text-white">Remover Pagamento.</Card.Title>
        </Card.Header>
        <Card.Body>
          <h3 className="text-danger font-weight-bold">
            Tem certeza que vai remover esse pagamento ?
          </h3>
          <div className="d-flex justify-content-between mt-4">
            <Button onClick={() => setPaymentDelete(false)} variant="danger">
              Fechar
            </Button>
            <Button onClick={() => handleDeletePayment(indexPayment)}>
              Confirmar
            </Button>
          </div>
        </Card.Body>
      </Modal>

      <Modal show={isOpen} onHide={onClose} size="xl" centered>
        <Modal.Header className=" rounded-0" closeButton>
          <Modal.Title>
            <h5 className="fw-bold">Resumo Leads</h5>
          </Modal.Title>
        </Modal.Header>
        <Card.Body>
          {user?.status === "REMOVED" && (
            <Col className="m-0 p-0 ">
              <h2 className="text-danger">Festa Excluida</h2>
              <p>Motivo: {user?.observations}</p>
              <hr />
            </Col>
          )}

          <Row>
            <Col lg="6" className="border-right">
              <Row className="d-inline">
                <Col className="d-flex flex-direction-collumn justify-content-between">
                  <p className="text-primary font-weight-bold">
                    Nome do Cliente:{" "}
                  </p>
                  <p>{user?.name}</p>
                </Col>

                <Col className="d-flex flex-direction-collumn justify-content-between">
                  <p className="text-primary font-weight-bold">
                    Telefone do Cliente:{" "}
                  </p>
                  <p>{user?.phone}</p>
                </Col>
                {/* <Col className="d-flex flex-direction-collumn justify-content-between">
                    <p className="text-primary font-weight-bold">
                      Data da Festa:{" "}
                    </p>
                    <p>{dateFormated(user?.date?.substr(0, 10))}</p>
                  </Col> */}
                <Col className="d-flex flex-direction-collumn justify-content-between">
                  <p className="text-primary font-weight-bold">
                    Tipo de Festa:{" "}
                  </p>
                  {user?.bundle?.model}
                </Col>
                {/* {user?.bundle && (
                    <Col className="d-flex flex-direction-collumn justify-content-between">
                      <p className="text-primary font-weight-bold">
                        Pacote Escolhido:{" "}
                      </p>
                      {user.bundle.name}
                    </Col>
                  )} */}
                {/* <Col className="d-flex flex-direction-collumn justify-content-between">
                    <p className="text-primary font-weight-bold">Duração: </p>
                    <p>{user?.bundle?.event_duration}hrs</p>
                  </Col> */}
                {/* <Col className="d-flex flex-direction-collumn justify-content-between">
                    <p className="text-primary font-weight-bold">
                      Espaços da Festa:{" "}
                    </p>
                    {handleSpaces(user?.spaces)}
                  </Col> */}
                {/* <Col className="d-flex flex-direction-collumn justify-content-between">
                    <p className="text-primary font-weight-bold">Período</p>
                    <p>{user?.time}</p>
                  </Col> */}
                {/*  <Col className="d-flex flex-direction-collumn justify-content-between">
                    <p className="text-primary font-weight-bold">Fim: </p>
                    <p>{user && handleEndParty(user)}</p>
                  </Col> */}
                <Col className="d-flex flex-direction-collumn justify-content-between">
                  <p className="text-primary font-weight-bold">
                    Email do Cliente:{" "}
                  </p>
                  <p>{user?.email}</p>
                </Col>
                {/* <Col className="d-flex flex-direction-collumn justify-content-between">
                    <p className="text-primary font-weight-bold">CPF: </p>
                    <p>{user?.legacy_document_number}</p>
                  </Col> */}
                <Col className="d-flex flex-direction-collumn justify-content-between">
                  <p className="text-primary font-weight-bold">
                    Data de Criação:{" "}
                  </p>
                  <p>{dateFormated(user?.created_at.substr(0, 10))}</p>
                </Col>
                <Col className="d-flex flex-direction-collumn justify-content-between">
                  <p className="text-primary font-weight-bold">
                    Responsável pelo Agendamento:{" "}
                  </p>
                  <p>{user?.scheduled_by ? user.scheduled_by : ""}</p>
                </Col>
              </Row>
            </Col>

            {/* <Col lg="6">
                <h4> </h4>
                <Row className="d-inline">
                  <Col className="d-flex flex-direction-collumn justify-content-between">
                    <p className="text-primary font-weight-bold">Quiosques: </p>
                    <p className="text-success font-weight-bold">
                      {user?.bundle?.kiosks?.label}
                    </p>
                  </Col>
                  <Col className="d-flex flex-direction-collumn justify-content-between">
                    <p className="text-primary font-weight-bold">
                      Duração Quiosque:{" "}
                    </p>
                    <p className="text-success font-weight-bold">
                      {user?.duration_kiosk}hrs
                    </p>
                  </Col>
                  <Col className="d-flex flex-direction-collumn justify-content-between">
                    <p className="text-primary font-weight-bold">Buffet: </p>
                    <p className="text-success font-weight-bold">
                      {user?.buffet ? "Sim" : "Não"}
                    </p>
                  </Col>
                  <Col className="d-flex flex-direction-collumn justify-content-between">
                    <p className="text-primary font-weight-bold">Cardápio: </p>
                    <p className="text-success font-weight-bold">
                      Opção da casa
                      {user?.menu_1 && `Cardápio 1 `}
                      {user?.menu_2 && `Cardápio 2 `}
                      {user?.menu_3 && `Cardápio 3 `}
                      {user?.menu_4 && `Cardápio 4 `}
                    </p>
                  </Col>
                  <Col className="d-flex flex-direction-collumn justify-content-between">
                    <p className="text-primary font-weight-bold">Produtos: </p>
                    {stringifyProducts(user?.non_slip_sock,user?.common_sock,user?.towel)}
                    <p className="text-success font-weight-bold">
                      <>
                        {user?.non_slip_sock > 0
                          ? `Meia antiderrapante: ${user?.non_slip_sock}X `
                          : ""}
                        {user?.common_sock > 0
                          ? `Meias: ${user?.common_sock}X `
                          : ""}

                        {user?.towel > 0 ? `Toalha: ${user?.towel}X ` : ""}

                        {user?.cotton_candy > 0
                          ? `Alg.Doce: ${user?.cotton_candy}X `
                          : ""}
                        <br />

                        {user?.cotton_candy_machine > 0
                          ? `Maq.Doce: ${user?.cotton_candy_machine}X `
                          : ""}

                        {user?.cup > 0 ? `Copo: ${user?.cup}X ` : ""}

                        {user?.cabinet_rental > 0
                          ? `Armario: ${user?.cabinet_rental}X `
                          : ""}
                      </>
                    </p>
                  </Col>

                  <Col className="d-flex flex-direction-collumn justify-content-between">
                    <p className="text-primary font-weight-bold">
                      Brincantes:{" "}
                    </p>
                    <p>{user?.regular}</p>
                  </Col>

                  <Col className="d-flex flex-direction-collumn justify-content-between">
                    <p className="text-primary font-weight-bold">
                      Não Brincantes:{" "}
                    </p>
                    <p>{user?.plus_one}</p>
                  </Col>

                  <Col className="d-flex flex-direction-collumn justify-content-between">
                    <p className="text-primary font-weight-bold">
                      Menores de 5 anos:{" "}
                    </p>
                    <p>{user?.kids}</p>
                  </Col>
                  <Col className="d-flex flex-direction-collumn justify-content-between">
                    <p className="text-primary font-weight-bold">
                      Acompanhante da Criança:{" "}
                    </p>
                    <p>{user?.kids_plus_one}</p>
                  </Col>
                  <Col className="d-flex flex-direction-collumn justify-content-between">
                    <p className="text-primary font-weight-bold">
                      Brincante PCD:{" "}
                    </p>
                    <p>{user?.pcd}</p>
                  </Col>
                  <Col className="d-flex flex-direction-collumn justify-content-between">
                    <p className="text-primary font-weight-bold">
                      Acompanhante PCD :{" "}
                    </p>
                    <p>{user?.pcd_plus_one}</p>
                  </Col>
                  <Col className="d-flex flex-direction-collumn justify-content-between">
                    <p className="text-primary font-weight-bold">
                      Total de Convidados:{" "}
                    </p>
                    <p className="font-weight-bold">
                      {user?.total_number_of_guests}
                    </p>
                  </Col>
                  <Col
                    className="d-flex flex-direction-collumn justify-content-between"
                    style={{ fontSize: "1.3rem" }}
                  >
                    <p className="text-primary font-weight-bold">
                      Valor Total:{" "}
                    </p>
                    <p className="text-success font-weight-bold">
                      R$ {user?.bundle?.price}
                    </p>
                  </Col>
                </Row>
              </Col> */}
            <Col lg="6">
              <Row style={{ marginLeft: "1rem" }}>
                <Col lg="6" className="p-0 m-0">
                  <Col className="d-flex flex-direction-collumn justify-content-between">
                    <Form.Label>Informações Adicionais</Form.Label>
                  </Col>
                  <Col className="d-flex flex-direction-collumn justify-content-between">
                    {/* <p className="text-primary font-weight-bold">
                          Brincantes:{" "}
                        </p>
                        <p>{user?.regular}</p> */}

                    <FloatingLabel controlId="floatingTextarea2">
                      <Form.Control
                        value={moreInfo}
                        onChange={(e) => setMoreInfo(e.target.value)}
                        as="textarea"
                        placeholder="Leave a comment here"
                        style={{ height: "100px", width: "480px" }}
                      />
                    </FloatingLabel>
                  </Col>
                </Col>
              </Row>
            </Col>
          </Row>
          {user?.status !== "REMOVED" && (
            <>
              <hr style={{ marginTop: "2rem" }} />
              <Row>
                <Col lg="6">
                  <Row style={{ marginTop: "-2rem" }}>
                    <Col lg="4" className="p-0 m-0">
                      <Button
                        className="mt-4"
                        style={{
                          backgroundColor: "transparent",
                          color: "#6c757d",
                          border: "none",
                        }}
                      >
                        Status
                      </Button>
                    </Col>

                    <Col
                      lg="4"
                      className="p-0 mt-4"
                      style={{ marginLeft: "-5rem" }}
                    >
                      <Form.Select
                        onChange={(e) => setSelect(e.target.value)}
                        style={{ cursor: "pointer" }}
                        value={select}
                      >
                        <option value={0}>Selecione a opção</option>
                        <option value={1}>Deseja tirar dúvidas</option>
                        <option value={2}>Solicitar agendamento</option>
                        <option value={3}>Enviar ao CRM</option>
                        <option value={4}>Oportunidade Perdida</option>
                      </Form.Select>
                    </Col>

                    <Col lg="4" className="p-0 m-0 ml-5">
                      <Button
                        style={{ width: "12rem", marginLeft: "4.2rem" }}
                        className="mt-4"
                        variant="success"
                        onClick={() => handleUpdate(select)}
                      >
                        Atualizar Status
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="">
                {moreInfoArray.length > 0 && (
                  <>
                    <h3>Lista de informações adicionais</h3>
                    {handleListMoreInfo()}
                  </>
                )}
              </Row>
            </>
          )}
        </Card.Body>
      </Modal>
    </>
  );
}
