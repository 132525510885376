import { useContext, useEffect, useState } from "react";
import { Button, FormControl, InputGroup, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import Api from "../../../../api/api";
import Select from "react-select";
import { AuthContext } from "../../../../context/AuthContext";
import { paymentMethods } from "../../../../utils/paymentType";

const selectStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: "1rem",
    borderBottomRightRadius: "1rem",
  }),
  container: (baseStyles, state) => ({
    ...baseStyles,
    alignSelf: "stretch",
    flex: 1,
  }),
};

const paymentStatuses = [
  { value: "pending", label: "Aguardando pagamento" },
  { value: "authorized", label: "Pagamento confirmado" },
];

const AddTransactionModal = ({
  open,
  setOpen,
  onSuccess,
  order_buffet_id,
  order_buffet_payment_id,
}) => {
  const { dashboardId } = useContext(AuthContext);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);

  const loadingLocked = loadingData || loadingSave || loadingRemove;

  const [values, setValues] = useState({
    responsible_id: dashboardId,
    order_buffet_id,
    payment_method: "",
    value: 0,
    date: "",
  });

  const getData = () => {
    setLoadingData(true);
    Api.get(`orders_buffets_payments/${order_buffet_payment_id}`)
      .then((res) => setValues({ ...res.data, responsible_id: dashboardId }))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingData(false));
  };

  useEffect(() => {
    if (open && order_buffet_payment_id > 0) getData();
  }, [order_buffet_payment_id, open]);

  const handleClose = () => {
    if (loadingLocked) return;
    setOpen(false);
  };

  const handleChange = (e) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSelectChange = (newVal, field) => {
    setValues((prev) => ({ ...prev, [field]: newVal }));
  };

  const renderTransactionInputs = () => {
    return (
      <div className="d-flex flex-column gap-1">
        <InputGroup>
          <InputGroup.Text>Data de Pagamento</InputGroup.Text>
          <FormControl
            value={values.date}
            type={"date"}
            onChange={handleChange}
            name="date"
          />
        </InputGroup>
        <InputGroup>
          <InputGroup.Text>Valor</InputGroup.Text>
          <FormControl
            value={values.value}
            min={0}
            type={"number"}
            onChange={handleChange}
            name="value"
          />
        </InputGroup>
        <InputGroup>
          <InputGroup.Text className="rounded-box">
            Forma de Pagamento
          </InputGroup.Text>
          <Select
            classNamePrefix="react-select"
            value={paymentMethods.find(
              (item) => item.value === values.payment_method
            )}
            placeholder="Clique aqui para selecionar"
            onChange={(item) =>
              handleSelectChange(item.value, "payment_method")
            }
            options={paymentMethods}
            styles={selectStyles}
          />
        </InputGroup>
      </div>
    );
  };

  const handleAddTransactionToOrder = () => {
    setLoadingSave(true);
    if (order_buffet_payment_id > 0)
      return Api.put(
        `/orders_buffets_payments/${order_buffet_payment_id}`,
        values
      )
        .then(() => {
          if (onSuccess) onSuccess();
          toast.success("Pagamento atualizado com sucesso!");
        })
        .catch((err) => toast.error(err.message))
        .finally(() => setLoadingSave(false));
    Api.post(`/orders_buffets_payments`, values)
      .then(() => {
        if (onSuccess) onSuccess();
        toast.success("Pagamento lançado com sucesso!");
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingSave(false));
  };

  const handleRemoveTransaction = () => {
    if (window.confirm("Deseja realmente remover este pagamento?")) {
      setLoadingRemove(true);
      Api.delete(`/orders_buffets_payments/${order_buffet_payment_id}`)
        .then(() => {
          if (onSuccess) onSuccess();
          toast.success("Pagamento removido com sucesso!");
        })
        .catch((err) => toast.error(err.message))
        .finally(() => setLoadingRemove(false));
    }
  };

  return (
    <Modal show={open} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Lançar pagamento de buffet à venda</Modal.Title>
      </Modal.Header>
      <Modal.Body>{renderTransactionInputs()}</Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose} disabled={loadingLocked}>
          Cancelar
        </Button>
        {order_buffet_payment_id > 0 && (
          <Button
            onClick={handleRemoveTransaction}
            variant="danger"
            disabled={loadingLocked}
          >
            Remover
          </Button>
        )}
        <Button onClick={handleAddTransactionToOrder} disabled={loadingLocked}>
          {`${order_buffet_payment_id > 0 ? "Atualizar" : "Confirmar"}`}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddTransactionModal;
