import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import List from "./List";

const AddModal = ({ permissionGroup, getPermissionGroupModules }) => {
  const [open, setOpen] = useState(false);

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <>
      <Button className="w-100" onClick={openModal}>
        Adicionar Módulo
      </Button>

      {open && (
        <Modal show={open} onHide={closeModal}>
          <Modal.Body>
            <List
              permissionGroup={permissionGroup}
              getPermissionGroupModules={getPermissionGroupModules}
              setOpen={setOpen}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="light" onClick={closeModal}>
              Cancelar
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default AddModal;
