import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import Api from "../../../api/api";

const PermissionGroupSelect = ({
  containerStyle,
  onChange,
  containerClassName,
  value,
}) => {
  const [list, setList] = useState([]);
  const [load, setLoad] = useState(false);

  if (typeof window !== "undefined") {
    injectStyle();
  }

  useEffect(() => {
    getList();
  }, []);

  const getList = () => {
    setLoad(true);
    Api.get(`/permission_groups`)
      .then((res) => {
        setLoad(false);
        setList(res.data.data);
      })
      .catch((err) => {
        setLoad(false);
        toast.error(err.message);
      });
  };

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <>
      <div style={{ ...(containerStyle || {}) }} className={containerClassName}>
        <Form.Select
          disabled={load || !list[0]}
          onChange={handleChange}
          value={value}
        >
          <option value="">Clique aqui para selecionar</option>
          {list.map((item, index) => (
            <option value={item.id}>{item.name}</option>
          ))}
        </Form.Select>
      </div>
    </>
  );
};

export default PermissionGroupSelect;
