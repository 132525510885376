import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { Button, ButtonGroup, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import Api from "../../../api/api";
import { FilialContext } from "../../../context/FilialContext";
import General from "./General";
import Participants from "./Participants";
import Payment from "./Payment";
import Products from "../../NewSale2/Sale/Products";
import BirthdayPeople from "./BirthdayPeople";
import Attachments from "./Attachments";
import Bundles from "./Bundles";

const Sale = ({
  user,
  handleChangeUser,
  sale,
  initSale,
  setSale,
  parkAreas,
}) => {
  const { selectedUnitId } = useContext(FilialContext);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [tab, setTab] = useState("participants");
  const [load, setLoad] = useState(false);
  const [bundlesList, setBundlesList] = useState([]);
  const yesNo = [
    { value: false, label: "Não" },
    { value: true, label: "Sim" },
  ];
  const [bundle, setBundle] = useState(null);

  const handleConfirmSale = () => {
    if (!sale.payment_method)
      return toast.warning(
        "É necessário informar a forma de pagamento para continuar!"
      );
    if (
      sale.payment_method === "installments" &&
      (!sale.total_breakdown[0].name || !sale.total_breakdown[1].name)
    )
      return toast.warning(
        "É necessário informar as formas de pagamento para continuar!"
      );
    if (
      sale.payment_method === "installments" &&
      Number(sale.total_breakdown[0].value) +
        Number(sale.total_breakdown[1].value) !==
        Number(sale.entry_value)
    )
      return toast.warning(
        "É necessário informar os valores das formas de pagamento para continuar!"
      );

    if (
      window.confirm(
        `Confirmar lançamento de festa para ${
          user.id ? `${user.name} ${user.lastName}` : "Visitante"
        }?`
      )
    ) {
      setLoad(true);
      setTimeout(() => {
        Api.post(`/orders/local`, sale)
          .then((res) => {
            alert("Festa lançada com sucesso!");
            handleChangeUser();
            setSale(JSON.parse(JSON.stringify(initSale)));
          })
          .catch((err) => {
            toast.error(err.message);
          })
          .finally(() => {
            setLoad(false);
          });
      }, 1000);
    }
  };

  const requiredParticipants =
    sale.players +
    sale.not_players +
    sale.minors +
    sale.minors_responsibles +
    sale.dps +
    sale.dps_responsibles +
    sale.courtesy_players +
    sale.courtesy_not_players;

  const validateParticipants = () => {
    if (sale.minors > 0 && !sale.minors_responsibles)
      return (
        toast.warning(
          "As crianças menores de 5 anos devem ter pelo menos 1 acompanhante!"
        ) || true
      );
  };

  const validateGeneral = () => {
    if (requiredParticipants > 0) {
      if (!sale.date)
        return (
          toast.warning(
            "É necessário informar a data do ingresso para continuar!"
          ) || true
        );
      if (!sale.duration)
        return (
          toast.warning(
            "É necessário informar o tempo de duração no parque para continuar!"
          ) || true
        );
      if (!sale.ticket_time)
        return (
          toast.warning(
            "É necessário informar a hora de entrada no parque para continuar!"
          ) || true
        );
      if (
        sale.filial === "SANTO_AMARO" &&
        (!sale.ticket_type || !sale.ticket_type[1])
      )
        return (
          toast.warning(
            "É necessário informar pelo menos dois espaços no parque para continuar!"
          ) || true
        );
    }
  };

  const handleGeneralTab = () => {
    if (validateParticipants()) return;
    setTab(requiredParticipants > 0 ? "general" : "products");
  };

  const handlePaymentTab = () => {
    if (validateParticipants()) return;
    if (validateGeneral()) return;
    setTab("payment");
  };

  const handleCancel = () => {
    setTab("participants");
    handleChangeUser();
    setSale(JSON.parse(JSON.stringify(initSale)));
  };

  const handleProductsTab = () => {
    if (validateParticipants()) return;
    if (validateGeneral()) return;
    setTab("products");
  };

  const handleBirthdayPeopleTab = () => {
    if (validateParticipants()) return;
    setTab(requiredParticipants > 0 ? "birthday_people" : "products");
  };

  const handleAttachmentsTab = () => {
    setTab("attachments");
  };

  const getProducts = () => {
    setLoadingProducts(true);
    Api.get(`products`, {
      params: { unit_id: selectedUnitId, ticket_type: "parties" },
    })
      .then((res) => {
        setSale((prev) => ({ ...prev, products: res.data.data }));
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingProducts(false));
  };

  useEffect(() => {
    if (selectedUnitId) getProducts();
  }, [selectedUnitId]);

  const handleBundleChange = (bundle) => {
    setBundle(bundle);

    setSale((prev) => {
      const products = [
        ...(bundle.products || []).map((bp) => ({
          ...bp,
          value: bp.dont_charge ? 0 : bp.single_value,
          amount: bp.min_amount,
        })),
        ...prev.products.filter((prevProd) => !(prevProd.bundle_id > 0)),
      ];
      return {
        ...prev,
        bundle_id: bundle.id,
        ticket_id: bundle.ticket_id,
        duration: bundle.duration,
        players: prev.players || bundle.players,
        min_players: bundle.players,
        player_price: bundle.player_price,
        type: bundle.type,
        tickets_value: bundle.tickets_value,
        products_value: bundle.products_value,
        total_value: bundle.total_value,
        products,
        bundle_players: bundle.players,
        buffet_id: bundle.buffet_id,
        buffet_value: bundle.buffet_value,
      };
    });
  };

  const handleBundleDateChange = (date) => {
    setSale((prev) => ({ ...prev, date }));
  };

  return (
    <div className="d-flex flex-column gap-1">
      <Card>
        <Card.Body className="d-flex justify-content-around">
          <span className="fs-4">
            {user.id ? `Cliente: ${user.name} ${user.lastName}` : "Sem cliente"}
          </span>
          {handleChangeUser && (
            <Button
              variant="link"
              className="ml-auto p-0"
              onClick={handleChangeUser}
            >
              Trocar cliente
            </Button>
          )}
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <span className="fs-5 fw-bold">Pacotes de festas:</span>
          <Bundles
            onChange={handleBundleChange}
            value={bundle}
            onBundlesList={setBundlesList}
            onDateChange={handleBundleDateChange}
          />
        </Card.Body>
      </Card>
      <ButtonGroup className="w-100">
        <Button
          variant={tab === "participants" ? "primary" : "outline-primary"}
          onClick={() => setTab("participants")}
        >
          Participantes
        </Button>
        <Button
          variant={tab === "birthday_people" ? "primary" : "outline-primary"}
          onClick={() => setTab("birthday_people")}
          disabled={!requiredParticipants}
        >
          Aniversariantes
        </Button>
        <Button
          variant={tab === "general" ? "primary" : "outline-primary"}
          onClick={handleGeneralTab}
          disabled={!requiredParticipants}
        >
          Ingresso
        </Button>
        <Button
          variant={tab === "products" ? "primary" : "outline-primary"}
          onClick={() => setTab("products")}
        >
          Produtos
        </Button>
        <Button
          variant={tab === "attachments" ? "primary" : "outline-primary"}
          onClick={() => setTab("attachments")}
        >
          Anexos
        </Button>
        <Button
          variant={tab === "payment" ? "primary" : "outline-primary"}
          onClick={handlePaymentTab}
        >
          Pagamento
        </Button>
      </ButtonGroup>
      <Card>
        <Card.Body>
          <span className="fs-5 fw-bold mt-4">{`${
            sale.bundle_id > 0
              ? `Configuração do pacote: ${bundle?.name}`
              : "Festa personalizada:"
          }`}</span>
          <div className="overflow-auto p-1 w-100"></div>
          {tab === "participants" && (
            <Participants
              handleNext={handleBirthdayPeopleTab}
              handleCancel={handleCancel}
              sale={sale}
              setSale={setSale}
              yesNo={yesNo}
              bundlesList={bundlesList}
              handleBundleChange={handleBundleChange}
            />
          )}
          {tab === "birthday_people" && (
            <BirthdayPeople
              handleNext={handleGeneralTab}
              handleCancel={handleCancel}
              sale={sale}
              setSale={setSale}
            />
          )}
          {tab === "general" && (
            <General
              handleNext={handleProductsTab}
              handleCancel={handleCancel}
              sale={sale}
              setSale={setSale}
              parkAreas={parkAreas}
            />
          )}
          {tab === "products" && (
            <Products
              handleNext={handleAttachmentsTab}
              handleCancel={handleCancel}
              sale={sale}
              setSale={setSale}
              loading={loadingProducts}
            />
          )}
          {tab === "attachments" && (
            <Attachments
              handleNext={handlePaymentTab}
              handleCancel={handleCancel}
              sale={sale}
              setSale={setSale}
            />
          )}
          {tab === "payment" && (
            <Payment
              nextLabel={"Confirmar Venda"}
              handleCancel={handleCancel}
              handleNext={handleConfirmSale}
              nextDisabled={load}
              sale={sale}
              setSale={setSale}
              user={user}
            />
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default Sale;
