import React from "react";
import { Form, Card, Pagination } from "react-bootstrap";
import UserListRow from "./Row";

const UserList = ({
  users,
  handleSelect,
  filter,
  total,
  handlePageChange,
  handleLimitChange,
}) => {
  const limitOptions = [5, 10, 50];

  const getPagesCount = () => Math.ceil(total / filter.limit);

  const getPaginationItems = () => {
    const pageCount = getPagesCount();
    const items = [];

    for (
      let index = Math.max(0, filter.page - 2);
      index < Math.min(pageCount, filter.page + 3);
      index++
    ) {
      const currentPage = index + 1;
      items.push(
        <Pagination.Item
          key={currentPage}
          onClick={() => handlePageChange(index)}
          active={index === filter.page}
        >
          {currentPage}
        </Pagination.Item>
      );
    }

    return items;
  };

  const renderLimitOptions = () =>
    limitOptions.map((limit) => (
      <option key={`limit_${limit}`} value={limit}>
        {limit}
      </option>
    ));

  return (
    <>
      <Card className="gap-1 p-1 bg-white m-0">
        <Card.Header className="bg-white">
          <Card.Title>
            {handleSelect && (
              <h5 className="fw-bold">Lista ingressos vendidos:</h5>
            )}
          </Card.Title>
        </Card.Header>
        <Card.Body>
          {users.map((item) => (
            <>
              <UserListRow
                key={`users_item_${item.id}_${item.users_info_id}`}
                item={item}
                onClick={handleSelect}
              />
              <hr />
            </>
          ))}
        </Card.Body>
        <Pagination className="ml-2 mt-1">
          {getPaginationItems()}
          {limitOptions.length > 0 && (
            <Form.Select
              value={filter.limit}
              onChange={(e) => handleLimitChange(e.target.value)}
              className="w-auto ml-1"
            >
              {renderLimitOptions()}
            </Form.Select>
          )}
        </Pagination>
      </Card>
    </>
  );
};

export default UserList;
