import { maskBr } from "js-brasil";
import moment from "moment";
import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, ProgressBar, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import Api from "../../api/api";
import Table from "../Table";
import { FilialContext } from "../../context/FilialContext";
import renamePaymentType from "../../utils/paymentType";
import { HiCurrencyDollar } from "react-icons/hi2";
import RefundModal from "./RefundModal";
import { cleanNumberString, getTransactionStatus } from "../../utils/functions";

const TransactionsList = ({
  startDate,
  endDate,
  allUnitiesData,
  ticketStartDate,
  ticketEndDate,
}) => {
  const { selectedUnitId } = useContext(FilialContext);

  const [filter, setFilter] = useState({
    limit: 10,
    page: 0,
    start_date: startDate || "",
    end_date: endDate || "",
    ticket_date_start: ticketStartDate || "",
    ticket_date_end: ticketEndDate || "",
  });

  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [loadingList, setLoadingList] = useState(false);

  const [openRefundTransaction, setOpenRefundTransaction] = useState(false);

  const getList = () => {
    setLoadingList(true);
    Api.get(`/transactions`, {
      params: { ...filter, unit_id: allUnitiesData ? null : selectedUnitId },
    })
      .then((res) => {
        setList(res.data.list);
        setTotal(res.data.total);
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingList(false));
  };

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      start_date: startDate || "",
      end_date: endDate || "",
      ticket_date_start: ticketStartDate || "",
      ticket_date_end: ticketEndDate || "",
    }));
  }, [startDate, endDate, ticketStartDate, ticketEndDate]);

  useEffect(() => {
    if (selectedUnitId || allUnitiesData) getList();
  }, [selectedUnitId, filter, allUnitiesData]);

  const columns = [
    {
      label: "ID",
      field: "id",
    },
    {
      label: "Data Criação",
      field: "created_at",
      renderValue: (item) =>
        moment(item.created_at).format("DD/MM/YYYY HH:mm:SS"),
    },
    {
      label: "TID",
      field: "transaction_id",
      renderValue: (item) => (
        <div
          style={{
            width: 180,
          }}
        >
          {item.transaction_id}
        </div>
      ),
    },
    {
      label: "ID Venda",
      field: "code",
      renderValue: (item) => item.code?.split("-")[0],
    },
    {
      label: "Status",
      field: "status",
      renderValue: (item) => getTransactionStatus(item.payment_status),
    },
    {
      label: "Erro",
      field: "error",
      renderValue: (item) => (
        <div
          style={{
            whiteSpace: "nowrap",
            maxWidth: 300,
            overflowX: "auto",
          }}
        >
          {item.error}
        </div>
      ),
    },
    {
      label: "E-mail",
      field: "email",
      renderValue: (item) => item.email || "-",
    },
    {
      label: "Documento",
      field: "cpf",
      renderValue: (item) =>
        item.cpf
          ? [true, "true"].includes(item.brazilian_doc)
            ? cleanNumberString(item.cpf)?.length > 11
              ? maskBr.cnpj(item.cpf)
              : maskBr.cpf(item.cpf)
            : item.cpf
          : "Indefinido",
    },
    { label: "Vendedor", field: "seller_email" },
    {
      label: "Valor",
      field: "value",
      renderValue: (item) =>
        item.value.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
    },
    {
      label: "Origem",
      field: "online_sale",
      renderValue: (item) => (item.online_sale ? "Online" : "Local"),
    },
    {
      label: "Forma Pagamento",
      field: "payment_method",
      renderValue: (item) => renamePaymentType(item.payment_method),
    },
    {
      label: "Data Pagamento",
      field: "authorized_at",
      renderValue: (item) =>
        moment(item.authorized_at).format("DD/MM/YYYY HH:mm:SS"),
    },
  ];

  if (allUnitiesData)
    columns.unshift({
      label: "Unidade",
      field: "unit.name",
      renderValue: (item) => item.unit,
    });

  const renderRows = (field, item) => {
    if (!item) return null;
    const data = columns.find((col) => col.field === field);
    if (data?.renderValue) return data.renderValue(item);
    return item[field];
  };

  const handleLimitChange = (newLimit) => {
    setFilter((prev) => ({ ...prev, limit: newLimit }));
  };

  const handlePageChange = (newPage) => {
    setFilter((prev) => ({ ...prev, page: newPage }));
  };

  return (
    <>
      {openRefundTransaction > 0 && (
        <RefundModal
          open={!!openRefundTransaction}
          setOpen={setOpenRefundTransaction}
          onSuccess={getList}
          id={openRefundTransaction}
        />
      )}
      {loadingList && <ProgressBar className="mb-1" animated now={100} />}
      <Card>
        <Card.Body>
          <Card.Title>
            <h5 className="fw-bold">Transações:</h5>
          </Card.Title>
          <Table
            emptyListLabel={
              loadingList ? null : "Nenhuma transação com base no filtro."
            }
            limit={filter.limit}
            limitOptions={[10, 30, 50]}
            page={filter.page}
            paginated
            total={total}
            columns={columns}
            renderRow={renderRows}
            renderRowItem
            data={list}
            handleLimitChange={handleLimitChange}
            handlePageChange={handlePageChange}
            containerClassName={"p-1 overflow-hidden d-flex flex-column gap-1"}
            actions={(item) =>
              item.payment_status === "authorized" && (
                <Button
                  data-toggle="tooltip"
                  title="Estornar"
                  variant="outline-danger"
                  className="rounded-circle"
                  size="sm"
                  onClick={() => setOpenRefundTransaction(item.id)}
                >
                  <HiCurrencyDollar size={"100%"} />
                </Button>
              )
            }
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default TransactionsList;
