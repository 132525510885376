import { useContext, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Api from "../../../api/api";
import { toast } from "react-toastify";
import { FilialContext } from "../../../context/FilialContext";

const AddBundleProductModal = ({
  open,
  setOpen,
  bundle_product_id,
  bundle_id,
  ticket_type,
  onSuccess,
}) => {
  const { selectedUnitId } = useContext(FilialContext);
  const [values, setValues] = useState({
    bundle_id,
    unit_product_id: "",
    amount: 0,
    single_value: 0,
    min_amount: 0,
    dont_charge: true,
  });
  const [loadingProduct, setLoadingProduct] = useState(false);
  const [loadingProductList, setLoadingProductList] = useState(false);
  const [productList, setProductList] = useState([]);

  const loadingLocked = loadingProduct || loadingProductList;

  const getProduct = () => {
    setLoadingProduct(true);
    Api.get(`bundles_products/${bundle_product_id}`)
      .then((res) => {
        setValues(res.data);
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingProduct(false));
  };

  useEffect(() => {
    if (bundle_product_id !== true && bundle_product_id > 0) getProduct();
  }, [bundle_product_id]);

  const getProductList = () => {
    setLoadingProductList(true);
    Api.get(`products`, {
      params: { unit_id: selectedUnitId, type: ticket_type },
    })
      .then((res) => {
        setProductList(res.data.data);
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingProductList(false));
  };

  useEffect(() => {
    if (selectedUnitId) getProductList();
  }, [selectedUnitId]);

  const handleClose = () => {
    if (loadingLocked) return;
    setOpen(false);
  };

  const product = productList.find(
    (p) => p.unit_product_id === values.unit_product_id
  );

  const handleChange = (e) => {
    if (e.target.name === "dont_charge")
      return setValues((prev) => ({
        ...prev,
        [e.target.name]: e.target.checked,
      }));

    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    const amount = product?.input_type === "switch" ? 1 : 0;

    setValues((prev) => ({
      ...prev,
      amount,
      single_value: product?.value || 0,
      charge_type: product?.charge_type,
    }));
  }, [product]);

  const handleSave = () => {
    setLoadingProduct(true);
    if (values.id)
      return Api.put(`bundles_products/${values.id}`, values)
        .then((res) => {
          toast.success("Produto do pacote atualizado com sucesso!");
          setValues(res.data);
          if (onSuccess) onSuccess();
          handleClose();
        })
        .catch((err) => toast.error(err.message))
        .finally(() => setLoadingProduct(false));
    Api.post(`bundles_products`, values)
      .then((res) => {
        toast.success("Produto adicionado ao pacote com sucesso!");
        setValues(res.data);
        if (onSuccess) onSuccess();
        handleClose();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingProduct(false));
  };

  const handleRemove = () => {
    if (window.confirm("Deseja realmente remover este produto do pacote?")) {
      setLoadingProduct(true);
      return Api.delete(`bundles_products/${values.id}`)
        .then(() => {
          setLoadingProduct(false);
        })
        .catch((err) => toast.error(err.message))
        .finally(() => {
          if (onSuccess) onSuccess();
          handleClose();
        });
    }
  };

  return (
    <Modal show={open} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>
          {`${values.id ? "Atualizar" : "Adicionar"} produto ao pacote`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column gap-3">
          <div>
            <Form.Label>Produto</Form.Label>
            <Form.Select
              onChange={handleChange}
              value={values.unit_product_id}
              disabled={loadingLocked}
              name="unit_product_id"
            >
              <option hidden>Selecione o produto</option>
              {productList.map((prod) => (
                <option
                  key={`product_item_${prod.unit_product_id}`}
                  value={prod.unit_product_id}
                >
                  {prod.name}
                </option>
              ))}
            </Form.Select>
          </div>
          {product && (
            <>
              <div>
                <Form.Label>Quantidade</Form.Label>
                <Form.Control
                  value={values.min_amount}
                  name={"min_amount"}
                  type={"number"}
                  step={1}
                  onChange={handleChange}
                  disabled={loadingLocked}
                />
              </div>
              <div className="d-flex gap-2">
                <Form.Label>Valor já embutido no pacote?</Form.Label>
                <Form.Label>Não</Form.Label>
                <Form.Switch
                  checked={values.dont_charge}
                  name={"dont_charge"}
                  onChange={handleChange}
                  disabled={loadingLocked}
                />
                <Form.Label>Sim</Form.Label>
              </div>
              <span>{`Valor unitário: ${Number(
                values.single_value || 0
              ).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })}`}</span>
            </>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose} disabled={loadingLocked}>
          Cancelar
        </Button>
        {values.id && (
          <Button
            variant="danger"
            onClick={handleRemove}
            disabled={loadingLocked}
          >
            Remover
          </Button>
        )}
        <Button onClick={handleSave} disabled={loadingLocked}>
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddBundleProductModal;
