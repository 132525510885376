import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import {
  ProgressBar,
  Form,
  Card,
  InputGroup,
  Button,
  Modal,
} from "react-bootstrap";
import PermissionGroupModulesList from "./PermissionGroupModules/List";
import Api from "../../api/api";
import AddModal from "./PermissionGroupModules/AddModal";

const Edit = ({
  item,
  getList,
  setItem,
  shouldCreateGroup,
  setShouldCreateGroup,
}) => {
  const [load, setLoad] = useState(false);
  const [values, setValues] = useState({ ...(item || { name: "" }) });
  const [loadSave, setLoadSave] = useState(false);

  useEffect(() => {
    setValues({ ...(item || { name: "" }) });
  }, [item]);

  if (typeof window !== "undefined") {
    injectStyle();
  }

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const save = () => {
    setShouldCreateGroup(false);
    if (values.id) {
      update();
    } else {
      create();
    }
  };

  const create = () => {
    setLoadSave(true);
    Api.post(`permission_groups`, values)
      .then((res) => {
        setLoadSave(false);
        getList();
        setItem({ ...res.data.data });
      })
      .catch((err) => {
        setLoadSave(false);
        toast.error(err.message);
      });
  };

  const update = () => {
    setLoadSave(true);
    Api.put(`permission_groups/${values.id}`, values)
      .then((res) => {
        setLoadSave(false);
        getList();
        setItem({ ...res.data.data });
      })
      .catch((err) => {
        setLoadSave(false);
        toast.error(err.message);
      });
  };

  const handleCloseModal = (event) => {
    setShouldCreateGroup(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (values.name.trim() === "") {
        toast.warning("Digite o nome do Grupo de permissões");
      } else {
        save();
      }
    }
  };

  const renderGroupNameInput = () => {
    if (!values.id) {
      return (
        <>
          <label>Nome do Grupo</label>
          <input
            autoFocus
            className="form-control"
            type="text"
            name="name"
            value={values.name}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            placeholder={"Digite o nome..."}
          />
          <br />
        </>
      );
    }
    return null;
  };

  return (
    <>
      <Modal show={shouldCreateGroup} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {values.id
              ? "Editar grupo de Permissões:"
              : "Novo grupo de Permissões:"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>{renderGroupNameInput()}</div>
          <div className="mt-2">
            {values.id && <PermissionGroupModulesList permissionGroup={item} />}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={save} disabled={loadSave}>
            Cadastrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Edit;
