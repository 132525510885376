import moment from "moment";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  Button,
  Col,
  Form,
  InputGroup,
  ListGroup,
  Modal,
  ProgressBar,
  Row,
} from "react-bootstrap";
import { toast } from "react-toastify";
import Api from "../../../api/api";
import { FilialContext } from "../../../context/FilialContext";
import "moment/locale/pt-br";
import DefaultTimesModal from "./DefaultTimesModal";
import ReactSelect from "react-select";
import { ticketTypesList, weekDaysList } from "../../../utils/functions";

const selectStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: "1rem",
    borderBottomRightRadius: "1rem",
  }),
  container: (baseStyles, state) => ({
    ...baseStyles,
    alignSelf: "stretch",
    flex: 1,
  }),
  value: (base) => ({ ...base, borderRadius: "1rem" }),
  multiValue: (base) => ({ ...base, borderRadius: "1rem" }),
};

const CalendarDefaultDatesModal = ({ open, setOpen }) => {
  const { selectedUnitId } = useContext(FilialContext);
  const [list, setList] = useState([]);
  const [loadingList, setLoadingList] = useState(false);
  const [values, setValues] = useState({
    id: "new",
    open_for: "",
    week_day: "",
    start_time: "",
    end_time: "",
    total_spaces: 100,
  });
  const [loadingData, setLoadingData] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [openDefaultTimes, setOpenDefaultTimes] = useState(false);

  const handleClose = () => {
    if (loadingSave) return;
    setOpen(false);
  };

  const getData = (id) => {
    setLoadingData(id);
    Api.get(`calendar_default_dates/${id}`)
      .then((res) => setValues(res.data.data))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingData(false));
  };

  const renderList = () => {
    if (!loadingList && (!list || !list[0]))
      return <ListGroup.Item>Nenhuma data para mostrar.</ListGroup.Item>;
    return (list || []).map((item) => {
      const handleClick = () => {
        setValues({ ...item });
        getData(item.id);
      };

      return (
        <ListGroup.Item
          key={`default_date_item_${item.id}`}
          action
          active={item.id === values.id}
          onClick={handleClick}
          disabled={loadingSave || loadingData}
        >
          <div className="d-flex align-items-center justify-content-between">
            {
              weekDaysList.find(
                (wd) => Number(wd.value) === Number(item.week_day)
              )?.label
            }
          </div>
        </ListGroup.Item>
      );
    });
  };

  const handleChange = (e) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    setValues((prev) => ({ ...prev, unit_id: selectedUnitId }));
  }, [selectedUnitId]);

  const getList = () => {
    setLoadingList(true);
    Api.get("calendar_default_dates", { params: { unit_id: selectedUnitId } })
      .then((res) => setList(res.data.list))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingList(false));
  };

  useEffect(() => {
    if (selectedUnitId && open) getList();
  }, [open, selectedUnitId]);

  const handleNewData = () => {
    setValues({
      id: "new",
      week_day: "",
      open_for: "",
      unit_id: selectedUnitId,
      start_time: "",
      end_time: "",
      total_spaces: 100,
    });
  };

  const handleSave = () => {
    setLoadingSave(true);
    if (values.id === "new")
      return Api.post(`calendar_default_dates`, values)
        .then((res) => {
          setValues({ ...res.data });
          toast.success("Data padrão criada com sucesso!");
          getList();
        })
        .catch((err) => toast.error(err.message))
        .finally(() => setLoadingSave(false));
    Api.put(`calendar_default_dates/${values.id}`, values)
      .then((res) => {
        setValues({ ...res.data });
        toast.success("Data padrão atualizada com sucesso!");
        getList();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingSave(false));
  };

  const handleOpenDefaultTimes = () => {
    setOpenDefaultTimes(true);
  };

  const timeOptions = () => {
    const list = [];

    for (let index = 0; index < 24; index++) {
      const hour = index < 10 ? "0" + index : index.toString();
      list.push(<option value={`${hour}:00:00`}>{`${hour}:00`}</option>);
    }

    return list;
  };

  const handleOpenForChange = (types) => {
    setValues((prev) => ({
      ...prev,
      open_for: types.map((item) => item.value),
    }));
  };

  return (
    <Modal size={values?.id ? "lg" : null} show={open} onHide={handleClose}>
      {openDefaultTimes && (
        <DefaultTimesModal
          open={openDefaultTimes}
          setOpen={setOpenDefaultTimes}
          calendar_default_date={values}
        />
      )}
      <Modal.Header>
        <Modal.Title>Datas padrão do calendário</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12} md={values?.id ? 6 : 12}>
            <div className="d-flex align-items-center justify-content-between mb-2">
              <span className="fw-bold">Lista de datas padrão</span>
              <Button onClick={handleNewData}>Nova Data</Button>
            </div>
            {loadingList && <ProgressBar className="mb-1" animated now={100} />}
            <ListGroup>{renderList()}</ListGroup>
          </Col>
          {values?.id && (
            <Col xs={12} md={6}>
              <div className="d-flex flex-column gap-1">
                <span className="fw-bold">
                  {values.id === "new"
                    ? "Nova data padrão"
                    : "Editando data padrão"}
                </span>
                <InputGroup>
                  <InputGroup.Text>Dia da semana</InputGroup.Text>
                  <Form.Select
                    value={values?.week_day}
                    name={"week_day"}
                    onChange={handleChange}
                    disabled={
                      loadingSave ||
                      loadingData ||
                      (values?.id && values.id !== "new")
                    }
                  >
                    <option hidden>Selecione o dia da semana</option>
                    {weekDaysList.map((weekDay) => (
                      <option
                        key={`week_day_item_${weekDay.value}`}
                        value={weekDay.value}
                      >
                        {weekDay?.label}
                      </option>
                    ))}
                  </Form.Select>
                </InputGroup>
                {values?.id === "new" && (
                  <>
                    <InputGroup>
                      <InputGroup.Text>Aberto para</InputGroup.Text>
                      <ReactSelect
                        classNamePrefix="react-select"
                        isMulti
                        onChange={handleOpenForChange}
                        value={(values?.open_for || []).map((type) => ({
                          value: type,
                          label: ticketTypesList.find(
                            (ticketType) => ticketType === type
                          ),
                        }))}
                        name={"open_for"}
                        disabled={loadingSave || loadingData}
                        styles={selectStyles}
                        getOptionLabel={(opt) =>
                          ticketTypesList.find(
                            (type) => type.value === opt.value
                          )?.label
                        }
                        isOptionSelected={(opt) =>
                          (values?.open_for || []).find(
                            (item) => item === opt.value
                          )
                        }
                        options={ticketTypesList}
                      />
                    </InputGroup>
                    <InputGroup>
                      <InputGroup.Text>Horário inicial</InputGroup.Text>
                      <Form.Select
                        value={values?.start_time}
                        name={"start_time"}
                        onChange={handleChange}
                        disabled={
                          loadingSave ||
                          loadingData ||
                          (values?.id && values.id !== "new")
                        }
                      >
                        <option hidden>Selecione o horário inicial</option>
                        {timeOptions()}
                      </Form.Select>
                    </InputGroup>
                    <InputGroup>
                      <InputGroup.Text>Horário final</InputGroup.Text>
                      <Form.Select
                        value={values?.end_time}
                        name={"end_time"}
                        onChange={handleChange}
                        disabled={
                          loadingSave ||
                          loadingData ||
                          (values?.id && values.id !== "new")
                        }
                      >
                        <option hidden>Selecione o horário final</option>
                        {timeOptions()}
                      </Form.Select>
                    </InputGroup>
                    <InputGroup>
                      <InputGroup.Text>Limite de vagas</InputGroup.Text>
                      <Form.Control
                        value={values?.total_spaces}
                        name={"total_spaces"}
                        onChange={handleChange}
                        type="number"
                        min={0}
                        disabled={loadingSave || loadingData}
                      />
                    </InputGroup>
                  </>
                )}
                {values.id !== "new" && (
                  <Button
                    variant="outline-primary"
                    onClick={handleOpenDefaultTimes}
                  >
                    Configurar horários padrão
                  </Button>
                )}
                <div className="d-flex align-items-center align-self-end gap-1">
                  <Button
                    variant="light"
                    disabled={loadingSave}
                    onClick={() => setValues({})}
                  >
                    Cancelar
                  </Button>
                  <Button
                    onClick={handleSave}
                    disabled={loadingSave || loadingData}
                  >
                    Salvar
                  </Button>
                </div>
              </div>
            </Col>
          )}
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="light" onClick={handleClose}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CalendarDefaultDatesModal;
