import { useEffect } from 'react';
import { useContext, useState } from 'react'

import Api from '../../api/api';
import { FilialContext } from "../../context/FilialContext";

export function useTickets(id) {
  const [currentPage, setCurrentPage] = useState(1);
  const { filial } = useContext(FilialContext)
  const [perPage, setPerPage] = useState(15);

  useEffect(() => {
    setCurrentPage(1)
  }, [filial, perPage])

  const [transaction, setTransaction] = useState([]);
  const [ticketsTotal, setTicketsTotal] = useState(0)
  const [loading, setLoading] = useState(false)



  useEffect(() => {
    Api.get(`user/list/value/${id}?per_page=${perPage}`).then((res) => {
      setTicketsTotal(res.data.pagination.lastPage)
    })
  }, [perPage, id])

  function getTickets({ page, perPage, id }) {
    setPerPage(perPage)
    setLoading(true)
    Api.get(`user/list/value/${id}?per_page=${perPage}${page !== 1 ? `&current_page=${page}` : ''}`).then(res => {
      setLoading(false)
      const filialData = res.data.data
      setTransaction(filialData);
    }).catch(() => setLoading(false))
  }



  return {
    getTickets,
    transaction,
    currentPage,
    setCurrentPage,
    perPage,
    setPerPage,
    loading,
    ticketsTotal
  }

}