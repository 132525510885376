import React from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { AiFillEye } from "react-icons/ai";
import { RiImage2Fill } from "react-icons/ri";
const popAvatar = require("../../assets/images/logoPophaus2.png");

const Row = ({ item, setSelectedItem, selectedItem }) => {
  const handleItemClick = () => {
    setSelectedItem(item);
  };

  return (
    <ListGroup.Item
      action
      onClick={handleItemClick}
      style={{ display: "flex", alignItems: "center" }}
      className="gap-1"
      variant="primary"
    >
      <img
        src={item.avatar || popAvatar}
        style={{
          width: 40,
          height: 40,
          borderRadius: 20,
          objectPosition: "center",
          objectFit: item.avatar ? "cover" : "contain",
        }}
        className={item.avatar ? "" : "bg-primary"}
        alt="Avatar da área do parque"
      />
      <span className="font-weight-bold">{item.name}</span>
      <div className="ml-auto d-flex align-items-center justify-content-center gap-2">
        {item.display && (
          <AiFillEye
            style={{ marginTop: -1 }}
            data-toggle="tooltip"
            title="Mostrando no site"
          />
        )}
        <div
          className="d-flex align-items-center justify-content-center gap-1"
          data-toggle="tooltip"
          title="Atrações mostradas no site"
        >
          <RiImage2Fill style={{ marginTop: -1 }} />
          <span>{item.displayed_attractions_count || 0}</span>
        </div>
      </div>
    </ListGroup.Item>
  );
};

export default Row;
