import React, { useState, useEffect, useRef } from "react";
import { Cropper, CircleStencil } from "react-advanced-cropper";
import "react-advanced-cropper/dist/style.css";
import { Button, Modal, ProgressBar } from "react-bootstrap";
import Api from "../../api/api";
import { toast } from "react-toastify";
import moment from "moment";

const ImageUpload = ({
  onChange,
  value,
  name,
  variant,
  folderName,
  aspectRatio = 1,
  resizeOptions,
}) => {
  const [load, setLoad] = useState(false);
  const [previewLoad, setPreviewLoad] = useState(false);
  const [open, setOpen] = useState(false);
  const [fileName, setFileName] = useState("");
  const [image, setImage] = useState(value || undefined);
  const [cropper, setCropper] = useState(null);
  const inputRef = useRef();

  const handleCropperChange = (e) => {
    setCropper(e);
  };

  const handleImageSelected = (e) => {
    const file = e.target.files[0];

    setImage(URL.createObjectURL(file));
    setFileName(file.name);

    if (file.type === "image/gif") {
      setLoad(true);
      const type = file.type;
      const reader = new FileReader();

      reader.onloadend = () => {
        uploadImage(reader.result.split("base64,")[1], type, file.name);
      };

      return setTimeout(() => {
        reader.readAsDataURL(file);
      }, 300);
    }

    setOpen(true);
    if (inputRef?.current) inputRef.current.value = null;
  };

  const handleCancelCrop = () => {
    setOpen(false);
    setImage(value || undefined);
    if (inputRef?.current) inputRef.current.value = null;
  };

  const handleButtonClick = () => {
    inputRef?.current?.click();
  };

  const handleCrop = () => {
    setLoad(true);

    setTimeout(() => {
      const dataURL = cropper?.getCanvas()?.toDataURL("image/jpg");
      let croppedImage = dataURL.split("base64,")[1];
      const type = "image/webp";

      uploadImage(croppedImage, type);
    }, 300);
  };

  const uploadImage = (croppedImage, type, fileNameParam) => {
    Api.post(
      `upload/file/datauri`,
      {
        file: croppedImage,
        file_name: moment().valueOf() + "_" + (fileNameParam || fileName),
        folder_name: folderName,
        file_type: type,
        max_width: variant === "avatar" ? 300 : resizeOptions?.maxWidth,
        max_height: variant === "avatar" ? 300 : resizeOptions?.maxHeight,
        max_size: 200000,
        dont_convert: type === "image/gif",
      },
      { headers: { "Content-Type": "application/json" } }
    )
      .then((res) => {
        if (res?.data?.data) {
          setPreviewLoad(true);
          onChange({
            target: { value: res.data.data, name },
          });
        }
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setLoad(false);
      });
  };

  useEffect(() => {
    setOpen(false);
    setCropper(null);
    setImage(value || undefined);
  }, [value]);

  return (
    <div className="d-flex flex-column align-items-center justify-content-center gap-1">
      {image && (
        <div className="d-flex align-items-center justify-content-center flex-column gap-1">
          {variant !== "avatar" && previewLoad && (
            <span>Carregando prévia...</span>
          )}
          {variant === "avatar" ? (
            <div className="border" style={styles.avatarContainer()}>
              <div
                src={image}
                style={styles.avatar({ image })}
                alt="image_upload_preview"
              />
            </div>
          ) : (
            <img
              src={image}
              style={styles.preview({ previewLoad })}
              alt="image_upload_preview"
              onLoad={() => setPreviewLoad(false)}
            />
          )}
        </div>
      )}
      <input
        type="file"
        onChange={handleImageSelected}
        accept="image/*"
        ref={inputRef}
        className="d-none"
      />
      <div className="w-100 bg-primary">
        <Button
          variant="primary rounded-0 rounded-bottom"
          onClick={handleButtonClick}
          className="w-100"
        >{`${value ? "Alterar" : "Selecionar"} imagem`}</Button>
      </div>
      <Modal show={open} onHide={null}>
        <Modal.Title className="align-self-center">Cortar imagem</Modal.Title>
        <Modal.Body>
          <Cropper
            src={image}
            onChange={handleCropperChange}
            className={"cropper"}
            aspectRatio={aspectRatio}
            stencilComponent={variant === "avatar" ? CircleStencil : undefined}
          />
          {load && <ProgressBar animated now={100} className="mt-1" />}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleCancelCrop}
            disabled={load}
          >
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleCrop} disabled={load}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const styles = {
  avatarContainer: () => ({
    overflow: "hidden",
    borderRadius: "50%",
    width: "5.95rem",
    height: "5.95rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }),
  avatar: ({ image, previewLoad }) => ({
    width: "6.25rem",
    height: "6.25rem",
    borderRadius: "50%",
    backgroundImage: `url(${image})`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
    opacity: previewLoad ? 0.6 : 1,
  }),
  preview: ({ previewLoad }) => ({
    maxWidth: "12.5rem",
    maxHeight: "12.5rem",
    objectFit: "contain",
    objectPosition: "center center",
    opacity: previewLoad ? 0.6 : 1,
  }),
};

export default ImageUpload;
