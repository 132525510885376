import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  Button,
  Col,
  Form,
  InputGroup,
  Modal,
  ProgressBar,
  Row,
  Card,
  ModalHeader,
} from "react-bootstrap";
import { toast } from "react-toastify";
import Api from "../../api/api";
import { FilialContext } from "../../context/FilialContext";
import moment from "moment";
import Table from "../../components/Table";
import CalendarDateHoursChart from "../../components/CalendarDateHoursChart";
import { getTicketType, getTicketTypes } from "../../utils/functions";
import OccupationPerHourChart from "../../components/CalendarDateHoursChart/OccupationPerHourChart";
import AvailableSpaces from "../../components/CalendarDateHoursChart/AvailableSpaces";
import RenderNotPlayers from "../../components/CalendarDateHoursChart/RenderNotPlayers";

const EditModal = ({ open, setOpen, date }) => {
  const { selectedUnitId } = useContext(FilialContext);
  const [values, setValues] = useState({ unit_id: selectedUnitId, date });
  const [loadingData, setLoadingData] = useState(false);
  const [list, setList] = useState([]);
  const [loadingList, setLoadingList] = useState(false);

  const getData = () => {
    setValues(null);
    setLoadingData(true);
    Api.get(`/calendar_dates/date/just_get`, { params: values })
      .then((res) => {
        if (res.data.data) return setValues(res.data.data);
        toast.warning("Data ainda não foi gerada no calendário!");
        setOpen(false);
      })
      .catch((err) => {
        toast.error(err.message);
        if (err.message.includes("não configurada")) setOpen(false);
      })
      .finally(() => setLoadingData(false));
  };

  useEffect(() => {
    getData();
    setValues((prev) => ({ ...prev, unit_id: selectedUnitId }));
  }, [selectedUnitId]);

  const handleClose = () => {
    setOpen(false);
  };

  const getList = () => {
    setLoadingList(true);
    Api.get(`/calendar_dates_hours`, {
      params: { calendar_date_id: values.id },
    })
      .then((res) => {
        setList(res.data.list);
      })
      .catch((err) => {
        toast.error(err.message);
        if (err.message.includes("não configurada")) setOpen(false);
      })
      .finally(() => setLoadingList(false));
  };

  useEffect(() => {
    if (values?.id) getList();
  }, [values?.id]);

  const ticketTypesList = getTicketTypes().map((type) => ({
    value: type,
    label: getTicketType(type),
  }));

  const columns = [
    {
      label: "Horário",
      field: "time",
      renderValue: (item) => moment(item.time, "HH:mm:SS").format("HH:mm"),
    },
    {
      label: "Aberto para",
      field: "open_for",
      renderValue: (item) => (
        <div className="d-flex flex-column">
          {item.open_for[0]
            ? item.open_for.map((typeValue) => (
                <span key={`open_for_item_${typeValue}`}>
                  {
                    ticketTypesList.find((type) => type.value === typeValue)
                      ?.label
                  }
                </span>
              ))
            : "Fechado"}
        </div>
      ),
    },
    {
      label: "Vagas Disponívels",
      field: "available_spaces",
      renderValue: (item) =>
        item.has_exclusive ? `0 (FESTA EXCLUSIVA)` : item.available_spaces,
    },
    { label: "Total de Vagas", field: "total_spaces" },
    { label: "Brincantes", field: "occupied_spaces" },
    { label: "Não Brincantes", field: "total_not_players" },
    {
      label: "Ocupação Total",
      renderValue: (item) =>
        Number(item.occupied_spaces) + Number(item.total_not_players),
    },
    {
      label: "PCDs",
      field: "dps",
      renderValue: (item) => {
        if (!item.ticket_disabled_people || !item.ticket_disabled_people[0])
          return "Nenhum";

        const list = [];

        for (const dp of item.ticket_disabled_people) {
          const itemData = (
            <span
              key={`ticket_dp_item_${dp.id}`}
            >{`${dp.type} x${dp.amount}`}</span>
          );

          list.push(itemData);
        }

        return <div className="d-flex flex-column">{list}</div>;
      },
    },
  ];

  const renderRows = (field, item) => {
    if (!item) return null;
    const data = columns.find((col) => col.field === field);
    if (data?.renderValue) return data.renderValue(item);
    return item[field];
  };

  return (
    <Modal
      show={!!open}
      onHide={loadingData ? null : handleClose}
      size={"xl"}
      closeButton
    >
      <Modal.Header closeButton>
        <Modal.Title className="d-flex justify-content-between">
          {`Horários de ${moment(date).format("DD/MM/YYYY")}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="g-2">
          <Col xs={12} lg={12} className="d-flex flex-column">
            <Row className="justify-content-center">
              <Col xs={12} lg={8}>
                <Card className="text-center">
                  <Card.Body>
                    <span className="d-flex fs-5 fw-bold">
                      {"Ocupação por horário:"}
                    </span>
                    <OccupationPerHourChart
                      date={moment(date).format("YYYY-MM-DD")}
                    />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <AvailableSpaces date={moment(date).format("YYYY-MM-DD")} />
            <RenderNotPlayers date={moment(date).format("YYYY-MM-DD")} />
          </Col>

          <Col xs={12}>
            {(loadingList || loadingData) && <ProgressBar animated now={100} />}
            <Card>
              <Card.Header className="border-0 bg-white">
                <span className="fs-5 fw-bold">{"Tabela de horários:"}</span>
              </Card.Header>
              <Card.Body>
                <Table
                  columns={columns}
                  data={list}
                  renderRowItem
                  renderRow={renderRows}
                  emptyListLabel={
                    !(loadingList || loadingData)
                      ? "Sem horários cadastrados para esta data."
                      : null
                  }
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose} disabled={loadingData}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditModal;
