import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Form, InputGroup, Modal, ProgressBar } from "react-bootstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import Api from "../../api/api";
import ImageUpload from "../../components/ImageUpload";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import CartSuggestions from "./CartSuggestions";
import CartSuggestionsLogModal from "./CartSuggestionsLogModal";

export const ticketTimes = [
  { value: "10:00", label: "10:00" },
  { value: "11:00", label: "11:00" },
  { value: "12:00", label: "12:00" },
  { value: "13:00", label: "13:00" },
  { value: "14:00", label: "14:00" },
  { value: "15:00", label: "15:00" },
  { value: "16:00", label: "16:00" },
  { value: "17:00", label: "17:00" },
  { value: "18:00", label: "18:00" },
  { value: "19:00", label: "19:00" },
  { value: "20:00", label: "20:00" },
  { value: "21:00", label: "21:00" },
  { value: "22:00", label: "22:00" },
];

const EditModal = ({ open, setOpen, item, getList }) => {
  const [values, setValues] = useState({});
  const [load, setLoad] = useState(false);
  const [loadUnities, setLoadUnities] = useState(false);
  const [unities, setUnities] = useState([]);
  const [loadingTickets, setLoadingTickets] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [loadingCoupons, setLoadingCoupons] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [tab, setTab] = useState("general");
  const [openCartSuggestionsLogModal, setOpenCartSuggestionsLogModal] =
    useState(false);

  const creationTimes = [
    { value: "00:00", label: "00:00" },
    { value: "01:00", label: "01:00" },
    { value: "02:00", label: "02:00" },
    { value: "03:00", label: "03:00" },
    { value: "04:00", label: "04:00" },
    { value: "05:00", label: "05:00" },
    { value: "06:00", label: "06:00" },
    { value: "07:00", label: "07:00" },
    { value: "08:00", label: "08:00" },
    { value: "09:00", label: "09:00" },
    { value: "10:00", label: "10:00" },
    { value: "11:00", label: "11:00" },
    { value: "12:00", label: "12:00" },
    { value: "13:00", label: "13:00" },
    { value: "14:00", label: "14:00" },
    { value: "15:00", label: "15:00" },
    { value: "16:00", label: "16:00" },
    { value: "17:00", label: "17:00" },
    { value: "18:00", label: "18:00" },
    { value: "19:00", label: "19:00" },
    { value: "20:00", label: "20:00" },
    { value: "21:00", label: "21:00" },
    { value: "22:00", label: "22:00" },
    { value: "23:00", label: "23:00" },
  ];
  const weekDays = [
    { value: 0, label: "Domingo" },
    { value: 1, label: "Segunda-feira" },
    { value: 2, label: "Terça-feira" },
    { value: 3, label: "Quarta-feira" },
    { value: 4, label: "Quinta-feira" },
    { value: 5, label: "Sexta-feira" },
    { value: 6, label: "Sábado" },
    { value: 7, label: "Feriados" },
  ];
  const discountTypes = [
    {
      value: "PERCENTAGE",
      label: "Porcentagem",
    },
    {
      value: "FIXED",
      label: "Valor Fixo",
    },
    {
      value: "PLAYERS",
      label: "Número de Brincantes",
    },
  ];

  const getUnities = () => {
    setLoadUnities(true);
    Api.get(`unities`)
      .then((res) => {
        setUnities(res.data.data);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setLoadUnities(false);
      });
  };

  const getCoupons = () => {
    setLoadingCoupons(true);
    Api.get(`coupons/all`)
      .then((res) => {
        setCoupons(res.data);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setLoadingCoupons(false);
      });
  };

  useEffect(() => {
    getUnities();
    getCoupons();
  }, []);

  useEffect(() => {
    setValues({ ...item });
  }, [item]);

  const closeModal = () => {
    setOpen(null);
  };

  const handleChange = (e) => {
    if (e.target.type === "number" && e.target.value < 0) e.target.value = 0;

    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleMultiSelectChange = (e, field) => {
    setValues((prev) => ({ ...prev, [field]: e.map((un) => un.value) }));
  };

  const handleSelectChange = (e, field) => {
    if (field === "partner_id") e.value = e.id;
    setValues((prev) => ({ ...prev, [field]: e.value }));
  };

  const handleSwitchChange = (e) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.checked }));
  };

  const handleStatusChange = (e) => {
    setValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked ? 1 : 2,
    }));
  };

  const remove = () => {
    if (window.confirm("Deseja realmente remover esta prmoção?")) {
      setLoad(true);
      Api.delete(`promotions/${values?.id}`)
        .then((res) => {
          toast.success("Promoção removida com sucesso!");
          getList();
          closeModal();
        })
        .catch((err) => {
          toast.error(err.message);
        })
        .finally(() => {
          setLoad(false);
        });
    }
  };

  const save = () => {
    setLoad(true);
    if (values?.id)
      return Api.put(`promotions/${values?.id}`, values)
        .then((res) => {
          toast.success("Promoção atualizada com sucesso!");
          getList();
          closeModal();
        })
        .catch((err) => {
          toast.error(err.message);
        })
        .finally(() => {
          setLoad(false);
        });
    Api.post(`promotions`, { ...values })
      .then((res) => {
        toast.success("Promoção criada com sucesso!");
        getList();
        closeModal();
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setLoad(false);
      });
  };

  const getTickets = () => {
    setLoadingTickets(true);
    Api.get(`tickets`, { params: { type: "ticket" } })
      .then((res) => {
        setTickets(res.data.list);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setLoadingTickets(false);
      });
  };

  useEffect(() => {
    getTickets();
  }, []);

  return (
    <>
      {openCartSuggestionsLogModal && (
        <CartSuggestionsLogModal
          open={openCartSuggestionsLogModal}
          setOpen={setOpenCartSuggestionsLogModal}
          promotionID={values?.id}
        />
      )}
      <Modal show={open} onHide={load ? null : closeModal} size={"xl"}>
        <Modal.Header>
          <Modal.Title>
            {`${values?.id ? "Editar" : "Adicionar"} Promoção`}
          </Modal.Title>
          <ButtonGroup>
            <Button
              variant={tab === "general" ? "primary" : "outline-primary"}
              onClick={() => setTab("general")}
            >
              Geral
            </Button>
            <Button
              variant={
                tab === "suggestion_banner" ? "primary" : "outline-primary"
              }
              onClick={() => setTab("suggestion_banner")}
            >
              Banner sugestão
            </Button>
            <Button
              variant={
                tab === "suggestion_cart" ? "primary" : "outline-primary"
              }
              onClick={() => setTab("suggestion_cart")}
            >
              Sugestão de carrinho
            </Button>
          </ButtonGroup>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column gap-1">
          {tab === "general" ? (
            <>
              <div className="d-flex flex-row gap-1 flex-wrap flex-md-nowrap">
                <InputGroup>
                  <InputGroup.Text>Nome</InputGroup.Text>
                  <Form.Control
                    value={values?.name}
                    name={"name"}
                    type={"text"}
                    onChange={handleChange}
                    autoFocus
                  />
                </InputGroup>
                <InputGroup>
                  <InputGroup.Text>Status</InputGroup.Text>
                  <div className="form-control">
                    <Form.Check
                      type="checkbox"
                      label="Ativo"
                      checked={values?.status === 1}
                      name="status"
                      onChange={handleStatusChange}
                    />
                  </div>
                </InputGroup>
              </div>
              <InputGroup>
                <InputGroup.Text>Descrição</InputGroup.Text>
                <Form.Control
                  value={values?.description}
                  name={"description"}
                  type={"text"}
                  onChange={handleChange}
                  as="textarea"
                  rows={5}
                />
              </InputGroup>
              {loadUnities ? (
                <ProgressBar animated now={100} />
              ) : (
                <InputGroup className="d-flex">
                  <InputGroup.Text>Unidades</InputGroup.Text>
                  <Select
                    classNamePrefix="react-select"
                    closeMenuOnSelect={false}
                    value={values?.unities?.map((unit) => ({
                      value: unit,
                      label: unities?.find((un) => un.var_name === unit)?.name,
                    }))}
                    onChange={(val) => handleMultiSelectChange(val, "unities")}
                    options={unities?.map((unit) => ({
                      value: unit.var_name,
                      label: unit.name,
                    }))}
                    isMulti
                    className="form-control"
                  />
                </InputGroup>
              )}
              <InputGroup className="d-flex">
                <InputGroup.Text>Dias dos ingressos</InputGroup.Text>
                <Select
                  classNamePrefix="react-select"
                  closeMenuOnSelect={false}
                  value={values?.ticket_week_days?.map((ticketWeekDay) => ({
                    value: ticketWeekDay,
                    label: weekDays.find((wDay) => wDay.value === ticketWeekDay)
                      .label,
                  }))}
                  onChange={(val) =>
                    handleMultiSelectChange(val, "ticket_week_days")
                  }
                  options={weekDays}
                  isMulti
                  className="form-control"
                />
              </InputGroup>
              <InputGroup className="d-flex">
                <InputGroup.Text>Horários dos ingressos</InputGroup.Text>
                <Select
                  classNamePrefix="react-select"
                  closeMenuOnSelect={false}
                  value={values?.ticket_times?.map((ticketTime) => ({
                    value: ticketTime,
                    label: ticketTimes.find(
                      (tTime) => tTime.value === ticketTime
                    ).label,
                  }))}
                  onChange={(val) =>
                    handleMultiSelectChange(val, "ticket_times")
                  }
                  options={ticketTimes}
                  isMulti
                  className="form-control"
                />
              </InputGroup>
              <InputGroup className="d-flex">
                <InputGroup.Text>Dias de validação da promo</InputGroup.Text>
                <Select
                  classNamePrefix="react-select"
                  closeMenuOnSelect={false}
                  value={values?.creation_week_days?.map((usageWeekDay) => ({
                    value: usageWeekDay,
                    label: weekDays.find((wDay) => wDay.value === usageWeekDay)
                      .label,
                  }))}
                  onChange={(val) =>
                    handleMultiSelectChange(val, "creation_week_days")
                  }
                  options={weekDays}
                  isMulti
                  className="form-control"
                />
              </InputGroup>
              <InputGroup className="d-flex">
                <InputGroup.Text>
                  Horários de validação da promo
                </InputGroup.Text>
                <Select
                  classNamePrefix="react-select"
                  closeMenuOnSelect={false}
                  value={values?.creation_times?.map((usageTime) => ({
                    value: usageTime,
                    label: creationTimes.find(
                      (uTime) => uTime.value === usageTime
                    ).label,
                  }))}
                  onChange={(val) =>
                    handleMultiSelectChange(val, "creation_times")
                  }
                  options={creationTimes}
                  isMulti
                  className="form-control"
                />
              </InputGroup>
              <div className="d-flex flex-row gap-1 flex-wrap flex-md-nowrap">
                <InputGroup>
                  <InputGroup.Text>Data inicial</InputGroup.Text>
                  <Form.Control
                    value={moment(values?.start_date).format("YYYY-MM-DD")}
                    name={"start_date"}
                    type={"date"}
                    onChange={handleChange}
                  />
                </InputGroup>
                <InputGroup>
                  <InputGroup.Text>Hora inicial</InputGroup.Text>
                  <Form.Control
                    value={values?.start_time}
                    name={"start_time"}
                    type={"time"}
                    onChange={handleChange}
                  />
                </InputGroup>
              </div>
              <div className="d-flex flex-row gap-1 flex-wrap flex-md-nowrap">
                <InputGroup>
                  <InputGroup.Text>Data final</InputGroup.Text>
                  <Form.Control
                    value={moment(values?.end_date).format("YYYY-MM-DD")}
                    name={"end_date"}
                    type={"date"}
                    onChange={handleChange}
                  />
                </InputGroup>
                <InputGroup>
                  <InputGroup.Text>Hora final</InputGroup.Text>
                  <Form.Control
                    value={values?.end_time}
                    name={"end_time"}
                    type={"time"}
                    onChange={handleChange}
                  />
                </InputGroup>
              </div>
              <InputGroup>
                <InputGroup.Text>Valor mínimo da compra</InputGroup.Text>
                <Form.Control
                  value={values?.ticket_min_value}
                  name={"ticket_min_value"}
                  type={"number"}
                  min={0}
                  onChange={handleChange}
                />
              </InputGroup>
              {values?.ticket_min_value > 0 && (
                <InputGroup>
                  <InputGroup.Text>{`${
                    values?.discount_type === "PLAYERS"
                      ? "Gatilho de Desconto"
                      : "Tipo de desconto"
                  }`}</InputGroup.Text>
                  <Select
                    classNamePrefix="react-select"
                    value={discountTypes.find(
                      (dType) => dType.value === values?.discount_type
                    )}
                    onChange={(val) => handleSelectChange(val, "discount_type")}
                    options={discountTypes}
                    className="form-control"
                  />
                </InputGroup>
              )}
              {values?.ticket_min_value > 0 && (
                <div
                  className={`d-flex gap-1 flex-row flex-wrap flex-md-nowrap`}
                >
                  {values?.discount_type === "PLAYERS" && (
                    <InputGroup>
                      <InputGroup.Text>Tipo de desconto</InputGroup.Text>
                      <Select
                        classNamePrefix="react-select"
                        value={discountTypes.find(
                          (dType) =>
                            dType.value === values?.players_discount_type
                        )}
                        onChange={(val) =>
                          handleSelectChange(val, "players_discount_type")
                        }
                        options={discountTypes}
                        className="form-control"
                      />
                    </InputGroup>
                  )}
                  {(values?.discount_type !== "PLAYERS" ||
                    values?.players_discount_type !== "PLAYERS") && (
                    <InputGroup>
                      <InputGroup.Text>
                        {values?.discount_type === "PERCENTAGE"
                          ? "%"
                          : values?.discount_type === "FIXED"
                          ? "$"
                          : values?.players_discount_type === "PERCENTAGE"
                          ? "%"
                          : values?.players_discount_type === "FIXED"
                          ? "$"
                          : "%"}{" "}
                        do desconto
                      </InputGroup.Text>
                      <Form.Control
                        value={values?.discount_value}
                        name={"discount_value"}
                        type={"number"}
                        onChange={handleChange}
                      />
                    </InputGroup>
                  )}
                </div>
              )}
              {values?.discount_type === "PLAYERS" && (
                <div className="d-flex flex-row gap-1 flex-wrap flex-md-nowrap">
                  <InputGroup>
                    <InputGroup.Text>
                      Número de brincantes (gatilho)
                    </InputGroup.Text>
                    <Form.Control
                      value={values?.with_players}
                      name={"with_players"}
                      type={"number"}
                      min={0}
                      onChange={handleChange}
                    />
                  </InputGroup>
                  {values?.players_discount_type === "PLAYERS" && (
                    <InputGroup>
                      <InputGroup.Text>
                        Número de brincantes descontados
                      </InputGroup.Text>
                      <Form.Control
                        value={values?.discount_players}
                        name={"discount_players"}
                        type={"number"}
                        min={0}
                        onChange={handleChange}
                      />
                    </InputGroup>
                  )}
                </div>
              )}
              <div className="d-flex flex-row gap-1 flex-wrap flex-md-nowrap">
                {(values?.discount_type === "PERCENTAGE" ||
                  (values?.discount_type === "PLAYERS" &&
                    values?.players_discount_type === "PERCENTAGE")) &&
                  values?.ticket_min_value > 0 && (
                    <InputGroup>
                      <InputGroup.Text>$ máximo do desconto</InputGroup.Text>
                      <Form.Control
                        value={values?.discount_max_value}
                        name={"discount_max_value"}
                        type={"number"}
                        onChange={handleChange}
                      />
                    </InputGroup>
                  )}
                <InputGroup>
                  <InputGroup.Text>1 uso por CPF</InputGroup.Text>
                  <div className="form-control">
                    <Form.Check
                      type="checkbox"
                      label="Ativo"
                      checked={values?.cpf_lock}
                      name="cpf_lock"
                      onChange={handleSwitchChange}
                    />
                  </div>
                </InputGroup>
              </div>
              <div className="d-flex flex-row gap-1 flex-wrap flex-md-nowrap">
                <InputGroup>
                  <InputGroup.Text>Limite de vendas</InputGroup.Text>
                  <Form.Control
                    value={values?.creation_limit}
                    name={"creation_limit"}
                    type={"number"}
                    min={0}
                    onChange={handleChange}
                  />
                </InputGroup>
                <InputGroup>
                  <InputGroup.Text>
                    Limite de dias para uso dos ingressos após a data final
                  </InputGroup.Text>
                  <Form.Control
                    value={values?.ticket_usage_days_limit_after_end_date}
                    name={"ticket_usage_days_limit_after_end_date"}
                    type={"number"}
                    onChange={handleChange}
                  />
                </InputGroup>
              </div>
              <InputGroup className="d-flex">
                <InputGroup.Text>Tickets</InputGroup.Text>
                <div className="form-control">
                  <Select
                    classNamePrefix="react-select"
                    closeMenuOnSelect={false}
                    value={values?.ticket_ids?.map((ticket_id) => ({
                      value: ticket_id,
                      label: `${
                        tickets?.find((item) => item.id === ticket_id)?.name
                      } - ${
                        tickets?.find((item) => item.id === ticket_id)
                          ?.unit_name
                      }`,
                    }))}
                    onChange={(val) =>
                      handleMultiSelectChange(val, "ticket_ids")
                    }
                    options={tickets.map((ticket) => ({
                      label: `${ticket.name} - ${ticket.unit_name}`,
                      value: ticket.id,
                    }))}
                    isMulti
                  />
                  <small>
                    Deixar em branco para habilitar em todos os tickets
                  </small>
                </div>
              </InputGroup>
              <InputGroup>
                <InputGroup.Text>Disponível na pré venda</InputGroup.Text>
                <div className="form-control">
                  <Form.Check
                    type="checkbox"
                    label="Ativo"
                    checked={[true, "true"].includes(
                      values?.visible_in_pre_order
                    )}
                    name="visible_in_pre_order"
                    onChange={handleSwitchChange}
                  />
                </div>
              </InputGroup>
              {[true, "true"].includes(values?.visible_in_pre_order) && (
                <InputGroup className="flex-column align-items-center justify-content-center">
                  <InputGroup.Text className="rounded-0 rounded-top w-100 justify-content-center">
                    Banner da pré venda
                  </InputGroup.Text>
                  <div
                    className={
                      values.pre_order_banner
                        ? "border border-primary rounded-0 rounded-bottom m-0 w-100 h-100 pt-1 overflow-hidden"
                        : "border border-primary rounded-0 rounded-bottom m-0 w-100 h-100 overflow-hidden"
                    }
                  >
                    <ImageUpload
                      value={values.pre_order_banner}
                      onChange={handleChange}
                      name="pre_order_banner"
                      folderName={"promotions"}
                      aspectRatio={4 / 3}
                    />
                  </div>
                </InputGroup>
              )}
              <InputGroup>
                <InputGroup.Text>Acumular desconto com cupons?</InputGroup.Text>
                <div className="form-control">
                  <Form.Check
                    type="checkbox"
                    label={values.can_stack_coupons ? "Ativado" : "Desativado"}
                    checked={values.can_stack_coupons}
                    name="can_stack_coupons"
                    onChange={handleSwitchChange}
                  />
                </div>
              </InputGroup>
              {[true, "true"].includes(values.can_stack_coupons) && (
                <>
                  {loadingCoupons ? (
                    <ProgressBar animated now={100} />
                  ) : (
                    <InputGroup>
                      <InputGroup.Text>Cupons</InputGroup.Text>
                      <div className="form-control">
                        <Select
                          classNamePrefix="react-select"
                          closeMenuOnSelect={false}
                          value={values?.available_coupons_ids?.map(
                            (coupon_id) => ({
                              value: coupon_id,
                              label: coupons?.find(
                                (item) => item.id === coupon_id
                              )?.code,
                            })
                          )}
                          onChange={(val) =>
                            handleMultiSelectChange(
                              val,
                              "available_coupons_ids"
                            )
                          }
                          options={coupons.map((coupon) => ({
                            label: coupon.code,
                            value: coupon.id,
                          }))}
                          isMulti
                        />
                        <small>
                          Deixar em branco para acumular com todos os cupons
                        </small>
                      </div>
                    </InputGroup>
                  )}
                </>
              )}
            </>
          ) : tab === "suggestion_banner" ? (
            <>
              <div className="d-flex flex-row gap-1 flex-wrap flex-md-nowrap">
                <InputGroup>
                  <InputGroup.Text>Status do banner</InputGroup.Text>
                  <div className="form-control">
                    <Form.Check
                      type="checkbox"
                      label="Ativo"
                      checked={[true, "true"].includes(
                        values?.suggestion_banner_activated
                      )}
                      name="suggestion_banner_activated"
                      onChange={handleSwitchChange}
                    />
                  </div>
                </InputGroup>
                <InputGroup>
                  <InputGroup.Text>
                    Tempo de exibição (segundos)
                  </InputGroup.Text>
                  <Form.Control
                    value={values?.suggestion_banner_time}
                    name={"suggestion_banner_time"}
                    type={"number"}
                    min={0}
                    onChange={handleChange}
                  />
                </InputGroup>
              </div>
              <InputGroup className="flex-column align-items-center justify-content-center">
                <InputGroup.Text className="rounded-0 rounded-top w-100 justify-content-center">
                  Versão mobile
                </InputGroup.Text>
                <div
                  className={
                    values.suggestion_banner_mobile
                      ? "border border-primary rounded-0 rounded-bottom m-0 w-100 h-100 pt-1 overflow-hidden"
                      : "border border-primary rounded-0 rounded-bottom m-0 w-100 h-100 overflow-hidden"
                  }
                >
                  <ImageUpload
                    value={values.suggestion_banner_mobile}
                    onChange={handleChange}
                    name="suggestion_banner_mobile"
                    folderName={"promotions"}
                    aspectRatio={3 / 2}
                  />
                </div>
              </InputGroup>
              <span className="ml-auto text-right me-1 text-muted">{`Dimensões ideais: 300px/200px`}</span>
              <span className="ml-auto text-right me-1 text-muted">{`Tamanho máximo ideal: 200Kb`}</span>
              <InputGroup className="flex-column align-items-center justify-content-center">
                <InputGroup.Text className="rounded-0 rounded-top w-100 justify-content-center">
                  Versão desktop
                </InputGroup.Text>
                <div
                  className={
                    values.suggestion_banner_desktop
                      ? "border border-primary rounded-0 rounded-bottom m-0 w-100 h-100 pt-1 overflow-hidden"
                      : "border border-primary rounded-0 rounded-bottom m-0 w-100 h-100 overflow-hidden"
                  }
                >
                  <ImageUpload
                    value={values.suggestion_banner_desktop}
                    onChange={handleChange}
                    name="suggestion_banner_desktop"
                    folderName={"promotions"}
                    aspectRatio={10}
                  />
                </div>
              </InputGroup>
              <span className="ml-auto text-right me-1 text-muted">{`Dimensões ideais: 1000px/100px`}</span>
              <span className="ml-auto text-right me-1 text-muted">{`Tamanho máximo ideal: 200Kb`}</span>
            </>
          ) : (
            <>
              <div className="d-flex flex-row gap-1 flex-wrap flex-md-nowrap">
                <InputGroup>
                  <InputGroup.Text>Título</InputGroup.Text>
                  <Form.Control
                    value={values?.cart_suggestion_title}
                    name={"cart_suggestion_title"}
                    onChange={handleChange}
                  />
                </InputGroup>
                <InputGroup>
                  <InputGroup.Text>Status</InputGroup.Text>
                  <div className="form-control">
                    <Form.Check
                      type="checkbox"
                      label="Ativo"
                      checked={[true, "true"].includes(
                        values?.cart_suggestion_activated
                      )}
                      name="cart_suggestion_activated"
                      onChange={handleSwitchChange}
                    />
                  </div>
                </InputGroup>
              </div>
              <InputGroup>
                <InputGroup.Text>Descrição</InputGroup.Text>
                <Form.Control
                  value={values?.cart_suggestion_description}
                  name={"cart_suggestion_description"}
                  onChange={handleChange}
                  as="textarea"
                />
              </InputGroup>
              <InputGroup className="d-flex">
                <InputGroup.Text>Horários gatilho</InputGroup.Text>
                <Select
                  classNamePrefix="react-select"
                  closeMenuOnSelect={false}
                  value={values?.cart_suggestion_trigger_times?.map(
                    (ticketTime) => ({
                      value: ticketTime,
                      label: ticketTimes.find(
                        (tTime) => tTime.value === ticketTime
                      ).label,
                    })
                  )}
                  onChange={(val) =>
                    handleMultiSelectChange(
                      val,
                      "cart_suggestion_trigger_times"
                    )
                  }
                  options={ticketTimes}
                  isMulti
                  className="form-control"
                />
              </InputGroup>
              {values?.id && <CartSuggestions promotionID={values?.id} />}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={load} variant="light" onClick={closeModal}>
            Cancelar
          </Button>
          {values?.id && tab === "suggestion_cart" && (
            <Button
              disabled={load}
              variant="outline-secondary"
              onClick={() => setOpenCartSuggestionsLogModal(true)}
            >
              Log de Recomendação
            </Button>
          )}
          {values?.id && (
            <Button disabled={load} variant="danger" onClick={remove}>
              Remover
            </Button>
          )}
          <Button disabled={load} variant="primary" onClick={save}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditModal;
