export const paymentMethods = [
  {
    value: "credit_online",
    label: "E-Crédito",
    show_in: ["local", "online"],
    ticket_types: ["parties"],
  },
  {
    value: "pix_online",
    label: "E-Pix",
    show_in: ["local", "online"],
    ticket_types: ["parties"],
  },
  {
    value: "credit_local",
    label: "PDV-Crédito",
    show_in: ["local", "online"],
    ticket_types: ["ticket", "parties"],
  },
  {
    value: "debit",
    label: "PDV-Débito",
    show_in: ["local", "online"],
    ticket_types: ["ticket", "parties"],
  },
  {
    value: "pix_local",
    label: "PDV-Pix",
    show_in: ["local", "online"],
    ticket_types: ["ticket", "parties"],
  },
  {
    value: "credit_link",
    label: "Link Crédito",
    show_in: ["local", "online"],
    ticket_types: ["ticket", "parties"],
  },
  {
    value: "pix_link",
    label: "Link Pix",
    show_in: ["local", "online"],
    ticket_types: ["ticket", "parties"],
  },
  {
    value: "billet",
    label: "Boleto",
    show_in: ["local", "online"],
    ticket_types: ["ticket", "parties"],
  },
  {
    value: "money",
    label: "Dinheiro",
    show_in: ["local", "online"],
    ticket_types: ["ticket", "parties"],
  },
  {
    value: "transfer",
    label: "Transferência Conta",
    show_in: ["local", "online"],
    ticket_types: ["ticket", "parties"],
  },
  {
    value: "funpass",
    label: "FunPass",
    show_in: ["local"],
    ticket_types: ["ticket"],
  },
  {
    value: "courtesy",
    label: "Cortesia",
    show_in: ["local", "online"],
    ticket_types: ["ticket", "parties"],
  },
  {
    value: "installment_pix_online",
    label: "E-Pix parcelado",
    show_in: [],
    ticket_types: [],
  },
  {
    value: "installments",
    label: "Parcelado",
    show_in: [],
    ticket_types: [],
  },
  {
    value: "points",
    label: "Pontos",
    show_in: [],
    ticket_types: [],
  },
  {
    value: "total",
    label: "Valor Total",
    show_in: [],
    ticket_types: [],
  },
];

export const getPaymentMethods = (from, ticket_type) => {
  return paymentMethods.filter(
    (pm) =>
      (from ? pm.show_in.includes(from) : true) &&
      (ticket_type ? pm.ticket_types.includes(ticket_type) : true)
  );
};

export const renamePaymentType = (value) => {
  if (!value) return "Indefinido";

  const mixedMethod = [];

  for (const method of paymentMethods) {
    if (value.includes(method.value)) mixedMethod.push(method.label);
  }

  return mixedMethod.join(", ");
};

export default renamePaymentType;
