import React, { useEffect } from "react";
import { BiSearchAlt } from "react-icons/bi";
import { RiLockPasswordFill } from "react-icons/ri";
import { GiReceiveMoney } from "react-icons/gi";
import { useState } from "react";
import {
  Card,
  Col,
  Row,
  Button,
  Form,
  InputGroup,
  ProgressBar,
} from "react-bootstrap";
import PageTitle from "../../components/PageTitle";
import Table from "../../components/Table";
import UserViewModal from "./userViewModal";
import { useNavigate } from "react-router-dom";
import { useTickets } from "../../components/Pagination/useUsers";
import { dateFormated } from "../../utils/dateFormated";
import { PagesBtn } from "../../components/Pagination/PagesBtn";
import { EditUserModal } from "./userEditModal";
import UserNewPassModal from "./userNewPassModal";
import { CreateUser } from "./createUser";
import DeletUser from "./deletUser";
import { HiEye, HiPencilSquare, HiTrash } from "react-icons/hi2";
import moment from "moment";
import { genderList } from "../../utils/functions";

export default function Users() {
  const navigate = useNavigate();
  const {
    getTickets,
    users,
    currentPage,
    setCurrentPage,
    ticketsTotal,
    perPage,
    setPerPage,
    getTicketsSearch,
    loading,
  } = useTickets();

  const [usersDataTable, setUsersDataTable] = useState([]);
  const [updated, setUpdated] = useState(0);
  const [search, setSearch] = useState("");
  const [isAdmin, setIsAdmin] = useState("");
  const [userTarget, setUserTarget] = useState("");

  //MODALS ACTIONS
  const [viewUser, setViewUser] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [sendNewPasswordUser, setSendNewPasswordUser] = useState(false);
  const [createUserModal, setCreateUserModal] = useState(false);
  const [modalDeletUser, setModalDeletUser] = useState(false);
  const [creationDate, setCreationDate] = useState("");
  const [loadingList, setLoadingList] = useState(false);

  useEffect(() => {
    handleSearch();
  }, [perPage, currentPage, updated, isAdmin, updated, creationDate]);

  const handleSearch = () => {
    search || isAdmin
      ? getTicketsSearch({
          page: currentPage,
          perPage: perPage,
          searchPage: `${search}${
            isAdmin !== "" ? `&is_admin=${isAdmin}` : ""
          }`,
          creationDate,
        })
      : getTickets({ page: currentPage, perPage: perPage, creationDate });
  };

  const renderRows = (field, item) => {
    if (!item) return null;
    const data = columns.find((col) => col.field === field);
    if (data?.renderValue) return data.renderValue(item);
    return item[field];
  };

  const formatDocument = (doc) => {
    if (!doc) return "";

    const cleanDoc = doc.replace(/\D/g, "");

    if (cleanDoc.length === 11) {
      return cleanDoc.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    } else if (cleanDoc.length === 14) {
      return cleanDoc.replace(/(\d{2})(\d{4})(\d{4})(\d{2})/, "$1.$2/$3-$4");
    } else {
      return doc;
    }
  };

  const columns = [
    {
      label: "ID",
      field: "id",
      renderValue: (user) =>
        !user.id ? "" : user.id.split("-").slice(0, 1).join(" "),
    },
    {
      label: "Nome",
      field: "name",
    },
    {
      label: "Sobrenome",
      field: "lastName",
    },
    {
      label: "Gênero",
      field: "gender",
      renderValue: (user) =>
        !user?.gender
          ? ""
          : genderList.find((g) => g.value == user.gender)?.label,
    },
    {
      label: "Email",
      field: "email",
    },
    {
      label: "Data de Nascimento",
      field: "birth",
      renderValue: (user) =>
        !user?.birth
          ? ""
          : moment(user?.birth).isValid()
          ? moment(user?.birth).format("DD/MM/YYYY")
          : moment(user?.birth, "DD/MM/YYYY").format("DD/MM/YYYY"),
    },

    {
      label: "Documento",
      field: "cpf",
      renderValue: (user) => (!user.cpf ? "" : formatDocument(user.cpf)),
    },

    {
      label: "Nº Telefone",
      field: "phone",
    },
    {
      label: "Data de Criação",
      field: "created_at",
      renderValue: (user) =>
        !user?.created_at
          ? ""
          : moment(user?.created_at).isValid()
          ? moment(user?.created_at).format("DD/MM/YYYY")
          : moment(user?.created_at, "DD/MM/YYYY").format("DD/MM/YYYY"),
    },
  ];

  const onSearchKeyDown = (e, x) => {
    const enter = 13;
    if (e.keyCode === enter) handleSearch();
  };

  useEffect(() => {
    document.getElementById("topbar")?.scrollIntoView();
  }, []);

  return (
    <>
      {/* MODALS USER ACTIONS*/}
      <UserViewModal
        close={() => setViewUser(false)}
        open={viewUser}
        user={userTarget}
      />

      <CreateUser
        userData={userTarget}
        open={editUser}
        handleClose={() => setEditUser(false)}
        handleUserCreated={handleSearch}
        modalTitle={"Editar Usuário"}
      />
      <DeletUser
        close={() => setModalDeletUser(false)}
        open={modalDeletUser}
        user={userTarget}
        setUpdated={setUpdated}
      />
      <CreateUser
        handleClose={() => setCreateUserModal(false)}
        open={createUserModal}
        handleUserCreated={handleSearch}
        modalTitle={"Novo Usuário"}
      />
      <UserNewPassModal
        close={() => setSendNewPasswordUser(false)}
        open={sendNewPasswordUser}
        user={userTarget}
        setUpdated={setUpdated}
      />
      {/* END MODALS USER ACTIONS*/}
      <PageTitle>
        <h2>Usuários</h2>
        <Button variant="primary" onClick={() => setCreateUserModal(true)}>
          Novo Usuário
        </Button>
      </PageTitle>

      <Row className="g-4">
        <Col xs={12} md={8}>
          <InputGroup>
            <InputGroup.Text>Buscar</InputGroup.Text>
            <Form.Control
              value={search}
              name={"search"}
              placeholder="Pesquisar por nome, e-mail, documento"
              onChange={(e) => setSearch(e.target.value)}
              onKeyDown={onSearchKeyDown}
            />
            <span
              className="input-group-text cursor-pointer"
              onClick={handleSearch}
            >
              <BiSearchAlt />
            </span>
          </InputGroup>
        </Col>
      </Row>
      <Row className="g-4 mt-2">
        <Col xs={12} md={6} xl={6}>
          <h5 className="fw-bold">Data de Criação: </h5>
          <div
            className=" mb-3 input-group"
            onChange={(e) => setCreationDate(e.target.value)}
          >
            <input
              type="date"
              className="form-control"
              placeholder="Procurar"
            />
            <span className="input-group-text ">
              <BiSearchAlt />
            </span>
          </div>
        </Col>

        <Col xs={12} md={6} xl={6}>
          <h5 className="fw-bold">Filtrar Por: </h5>
          <select
            name=""
            className="form-select"
            onChange={(e) => setIsAdmin(e.target.value)}
            id=""
          >
            <option value="" defaultValue={true}>
              Todos Usuários
            </option>
            <option value="false">Usuários Comuns</option>
            <option value="true">Usuários Admin</option>
          </select>
        </Col>
      </Row>

      <Row>
        <Col xl={12}>
          {loading && <ProgressBar className="mb-1" animated now={100} />}
          <Card className="mt-3">
            <Card.Body>
              <Card.Title className="mb-1">
                <h5 className="fw-bold">Lista de usuários:</h5>
              </Card.Title>
              {users.length === 0 ? (
                <div className="d-flex justify-content-center align-items-center">
                  <p className="text-center fw-bold">Usuário não encontrado</p>
                </div>
              ) : (
                <Table
                  data={users}
                  columns={columns}
                  renderRow={renderRows}
                  renderRowItem
                  actions={(item) => (
                    <div className="d-flex align-items-center gap-1">
                      <Button
                        data-toggle="tooltip"
                        title="Visualizar"
                        variant="outline-primary"
                        className="rounded-circle"
                        size="sm"
                        onClick={() => {
                          setUserTarget(item);
                          setViewUser(true);
                        }}
                      >
                        <HiEye size={"100%"} />
                      </Button>

                      <Button
                        data-toggle="tooltip"
                        title="Editar"
                        variant="outline-secondary"
                        className="rounded-circle"
                        size="sm"
                        onClick={() => {
                          setUserTarget(item);
                          setEditUser(true);
                        }}
                      >
                        <HiPencilSquare size={"100%"} />
                      </Button>

                      <Button
                        data-toggle="tooltip"
                        title="Alterar Senha"
                        variant="outline-info"
                        className="rounded-circle"
                        size="sm"
                        onClick={() => {
                          setUserTarget(item);
                          setSendNewPasswordUser(true);
                        }}
                      >
                        <RiLockPasswordFill size={"100%"} />
                      </Button>

                      <Button
                        data-toggle="tooltip"
                        title="Transações"
                        variant="outline-success"
                        className="rounded-circle"
                        size="sm"
                        onClick={() => navigate(`/users/${item.id}`)}
                      >
                        <GiReceiveMoney />
                      </Button>

                      <Button
                        data-toggle="tooltip"
                        title="Remover"
                        variant="outline-danger"
                        className="rounded-circle"
                        size="sm"
                        onClick={() => {
                          setUserTarget(item);
                          setModalDeletUser(true);
                        }}
                      >
                        <HiTrash size={"100%"} />
                      </Button>
                    </div>
                  )}
                />
              )}
              <PagesBtn
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                ticketsTotal={ticketsTotal}
                perPage={perPage}
                setPerPage={setPerPage}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}
