import { useContext, useEffect, useState } from "react";
import Api from "../../api/api";
import { FilialContext } from "../../context/FilialContext";
import { toast } from "react-toastify";
import PaymentMethodTotalizersChart from "./PaymentMethodTotalizersChart";
import moment from "moment";

const SalesReportByPaymentMethod = ({ filter }) => {
  const [loadingData, setLoadingData] = useState(false);
  const [data, setData] = useState([]);
  const { selectedUnitId } = useContext(FilialContext);

  const getData = () => {
    setLoadingData(true);
    Api.get(`sales/report/payment_method`, {
      params: { ...filter, unit_id: selectedUnitId },
    })
      .then((res) => setData(res.data))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingData(false));
  };

  useEffect(() => {
    if (filter) getData();
  }, [filter]);

  const renderHeader = (title) => {
    return <span className="fw-bold fs-5">{title}</span>;
  };

  const renderItem = (item, className) => {
    return (
      <div className="d-flex flex-column align-items-center justify-content-center p-1">
        <span className="fw-bold">{item.time}</span>
        <div
          className={
            "text-center text-white fs-5 fw-bold rounded-pill w-100 p-1 px-3 " +
            (className || "")
          }
        >
          {item.spaces}
        </div>
      </div>
    );
  };

  const renderAvailableSpaces = () => {
    return (
      <div className="w-100">
        {renderHeader("Vagas para Brincantes:")}
        <div className="d-flex align-items-center justify-content-between w-100 flex-wrap">
          {data.map((item) =>
            renderItem(
              {
                time: moment(item.time, "HH:mm:SS").format("HH:mm"),
                spaces: item.available_spaces,
              },
              "bg-success"
            )
          )}
        </div>
      </div>
    );
  };

  const renderNotPlayers = () => {
    return (
      <div className="w-100">
        {renderHeader("Não Brincantes:")}
        <div className="d-flex align-items-center justify-content-between w-100 flex-wrap">
          {data.map((item) =>
            renderItem(
              {
                time: moment(item.time, "HH:mm:SS").format("HH:mm"),
                spaces: item.total_not_players,
              },
              "bg-info"
            )
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="d-flex flex-column w-100 h-100">
      <PaymentMethodTotalizersChart data={data} loading={loadingData} />
    </div>
  );
};

export default SalesReportByPaymentMethod;
