import React from "react";
import ListGroup from "react-bootstrap/ListGroup";

const Row = ({ item, onClick }) => {
  return (
    <ListGroup.Item action={onClick ? true : false} onClick={onClick}>
      {item.name}
    </ListGroup.Item>
  );
};

export default Row;
