import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { dateFormated } from "../../utils/dateFormated";
import UserPoints from "./UserPoints";
import moment from "moment";

export default function UserViewModal({ open, close, user }) {
  const navigate = useNavigate();

  return (
    <Modal show={open} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title closeButton>Usuário</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {user?.id && (
          <>
          <div className="d-flex justify-content-between mb-0">
            <p className="font-weight-bold">ID: </p>
            <p>{user.id}</p>
          </div>
          <hr className="m-0 p-0" />
        </>
         
        )}
       
        {user?.name && (
           <>
          <div className="d-flex justify-content-between">
            <p className=" font-weight-bold">Nome: </p>
            <p>{user?.name}</p>
          </div>
          <hr className="m-0 p-0" />
          </>
        )}
       
        {user?.lastName && (
          <>
          <div className="d-flex justify-content-between">
            <p className=" font-weight-bold">Sobrenome: </p>
            <p>{user?.lastName}</p>
          </div>
          <hr className="m-0 p-0" />
          </>
        )}
        {user?.email && (
          <>
          <div className="d-flex justify-content-between">
            <p className=" font-weight-bold">Email: </p>
            <p>{user?.email}</p>
          </div>
          <hr className="m-0 p-0" />
          </>
        )}

        {user?.cpf && (
          <>
          <div className="d-flex justify-content-between">
            <p className=" font-weight-bold">CPF: </p>
            <p>{user?.cpf}</p>
          </div>
          <hr className="m-0 p-0" />
          </>
        )}

        {user?.created_at && (
          <>
          <div className="d-flex justify-content-between">
            <p className=" font-weight-bold">Data de Criação: </p>
            <p>
              {dateFormated(user?.created_at)}
            </p>
          </div>
          <hr className="m-0 p-0" />
          </>
        )}
        {user?.phone && (
          <>
          <div className="d-flex justify-content-between">
            <p className=" font-weight-bold">Telefone: </p>
            <p>{user?.phone}</p>
          </div>
          <hr className="m-0 p-0" />
          </>
        )}
        {user?.birth && (
          <>
          <div className="d-flex justify-content-between">
            <p className=" font-weight-bold">
              Data de Nascimento:{" "}
            </p>
            <p>{user?.birth}</p>
          </div>
          <hr className="m-0 p-0" />
          </>
        )}
        {user?.gender && (
          <>
          <div className="d-flex justify-content-between">
            <p className=" font-weight-bold">Gênero: </p>
            <p>{user?.gender}</p>
          </div>
          <hr className="m-0 p-0" />
          </>
        )}
        <div className="mt-4">
        <UserPoints user_id={user?.id} />
        </div>

      </Modal.Body>
      <Modal.Footer>
      <Button
            variant="secondary"
            onClick={close}>
            Cancelar
          </Button>
        <Button
          variant="primary"
          onClick={() => navigate(`/users/${user.id}`)}
        >
          Verificar Transações
        </Button>

      </Modal.Footer>
    </Modal>
  );
}
