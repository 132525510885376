import { toast } from "react-toastify";
import Api from "../../../api/api";
import { useContext, useEffect, useState } from "react";
import { FilialContext } from "../../../context/FilialContext";
import moment from "moment";
import { getTicketType, ticketTimes } from "../../../utils/functions";
import { Button, Form, InputGroup, ProgressBar } from "react-bootstrap";

const BundleTicket = ({
  bundle_id,
  onSuccess,
  onTicketsTotalValueChange,
  selectedBuffet,
  onBuffetTotalValueChange,
}) => {
  const { selectedUnitId } = useContext(FilialContext);
  const [loadingTicket, setLoadingTicket] = useState(false);
  const [ticket, setTicket] = useState({
    bundle_id,
    ticket_id: "",
    week_days: [],
    areas_amount: 0,
    players: 0,
    player_price: 0,
    total_value: 0,
    type: "",
    duration: 0,
    event_duration: 0,
    time: "",
    additional_player_price: 0,
  });
  const [ticketList, setTicketList] = useState([]);
  const [loadingTicketList, setLoadingTicketList] = useState(false);

  const loadingLocked = loadingTicket || loadingTicketList;

  const getTicket = () => {
    setLoadingTicket(true);
    Api.get(`bundle/ticket/${bundle_id}`)
      .then((res) => {
        if (res.data) return setTicket(res.data);
        toast.warning("Este pacote ainda não possui um ticket!");
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingTicket(false));
  };

  useEffect(() => {
    if (bundle_id > 0) getTicket();
  }, [bundle_id]);

  const getTicketList = () => {
    setLoadingTicketList(true);
    Api.get("tickets", { params: { unit_id: selectedUnitId, type: "parties" } })
      .then((res) => setTicketList(res.data.list))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingTicketList(false));
  };

  useEffect(() => {
    if (selectedUnitId) getTicketList();
  }, [selectedUnitId]);

  const handleChange = (e) => {
    if (e.target.type === "number" && e.target.value < 0) e.target.value = 0;

    if (e.target.name === "ticket_id") {
      const t = ticketList.find(
        (ticket) => Number(ticket.id) === Number(e.target.value)
      );

      return setTicket((prev) => ({
        ...prev,
        ticket_id: t.id,
        week_days: t.week_days,
        areas_amount: t.areas_amount,
        player_price: t.player_price,
        type: t.type,
        duration: t.duration,
        event_duration: t.event_duration,
      }));
    }

    setTicket((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSaveTicket = () => {
    setLoadingTicket(true);
    if (ticket?.id)
      return Api.put(`bundles_tickets/${ticket.id}`, ticket)
        .then((res) => {
          setTicket(res.data);
          toast.success("Ticket do pacote salvo com sucesso!");
          if (onSuccess) onSuccess();
        })
        .catch((err) => toast.error(err.message))
        .finally(() => setLoadingTicket(false));
    Api.post(`bundles_tickets/${bundle_id}`, ticket)
      .then((res) => {
        setTicket(res.data);
        toast.success("Ticket do pacote criado com sucesso!");
        if (onSuccess) onSuccess();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingTicket(false));
  };

  const selectedTicket = ticketList.find((t) => t.id === ticket?.ticket_id);

  const ticketsTotalValue =
    Number(ticket?.players || 0) * Number(selectedTicket?.player_price || 0);

  const buffetsValue =
    Number(ticket?.players || 0) * Number(selectedBuffet?.value || 0);

  useEffect(() => {
    if (onTicketsTotalValueChange) onTicketsTotalValueChange(ticketsTotalValue);
  }, [ticketsTotalValue]);

  useEffect(() => {
    if (onBuffetTotalValueChange) onBuffetTotalValueChange(buffetsValue);
  }, [buffetsValue]);

  return (
    <div className="d-flex flex-column gap-1">
      <span className="fs-5 fw-bold">Ticket do pacote</span>
      {!ticket.ticket_id && (
        <span className="text-muted">
          Selecione um ticket abaixo para configurar o pacote!
        </span>
      )}
      <div className="d-flex flex-column gap-1">
        <InputGroup>
          <InputGroup.Text>Ticket</InputGroup.Text>
          <Form.Select
            value={ticket?.ticket_id}
            name={"ticket_id"}
            onChange={handleChange}
            disabled={loadingLocked}
          >
            <option hidden>Selecione a configuração de ticket</option>
            {ticketList.map((ticket) => (
              <option key={`ticket_item_${ticket.id}`} value={ticket.id}>
                {ticket.name}
              </option>
            ))}
          </Form.Select>
        </InputGroup>
        {ticket?.ticket_id && (
          <>
            <InputGroup>
              <InputGroup.Text>Brincantes</InputGroup.Text>
              <Form.Control
                value={ticket?.players}
                name={"players"}
                type={"number"}
                step={1}
                onChange={handleChange}
                disabled={loadingLocked}
              />
            </InputGroup>
          </>
        )}
        <span className="fw-bold">{`Valor total em tickets: ${ticketsTotalValue.toLocaleString(
          "pt-br",
          {
            style: "currency",
            currency: "BRL",
          }
        )}`}</span>
        {buffetsValue > 0 && (
          <span className="fw-bold">{`Valor total em buffet: ${buffetsValue.toLocaleString(
            "pt-br",
            {
              style: "currency",
              currency: "BRL",
            }
          )}`}</span>
        )}
        <Button
          variant="outline-primary"
          disabled={loadingLocked}
          onClick={handleSaveTicket}
        >
          Salvar Configuração de Ingresso
        </Button>
      </div>
    </div>
  );
};

export default BundleTicket;
