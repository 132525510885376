import "./assets/scss/Default.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import "react-toastify/dist/ReactToastify.css";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import AuthProvider from "./context/AuthContext";
import FilialProvider from "./context/FilialContext";
import history from "./history";
import moment from "moment";
import "moment/dist/locale/pt-br";

moment.locale("pt-br");

const production =
  process.env.REACT_APP_BASE_URL === "https://prodapi.pophaus.com.br/";

if (production) console.log = () => {};

ChartJS.register(ArcElement, Tooltip, Legend);

ReactDOM.render(
  <BrowserRouter history={history}>
    <AuthProvider>
      <FilialProvider>
        <App />
      </FilialProvider>
    </AuthProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
