import { useEffect, useState } from "react";
import { Button, Form, InputGroup, Modal, ProgressBar } from "react-bootstrap";
import { toast } from "react-toastify";
import Api from "../../../api/api";
import ImageUpload from "../../../components/ImageUpload";
import PreviewModal from "./PreviewModal";

const EditModal = ({ open, setOpen, item, getList }) => {
  const [values, setValues] = useState({});
  const [load, setLoad] = useState(false);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);

  useEffect(() => {
    if (open) setValues(item ? { ...item } : {});
  }, [item, open]);

  const closeModal = () => {
    setOpen(null);
  };

  const handleChange = (e) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSwitchChange = (e) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.checked }));
  };

  const remove = () => {
    if (window.confirm("Deseja realmente remover esta atração?")) {
      setLoad(true);
      Api.delete(`attractions/${values.id}`)
        .then((res) => {
          toast.success("Atração removida com sucesso!");
          getList();
          closeModal();
        })
        .catch((err) => {
          toast.error(err.message);
        })
        .finally(() => {
          setLoad(false);
        });
    }
  };

  const save = () => {
    if (!values.title) return toast.warning("Título da atração indefinido!");
    if (!values.image) return toast.warning("Imagem da atração indefinida!");
    if (!values.description)
      return toast.warning("Descrição da atração indefinida!");

    setLoad(true);
    if (values.id)
      return Api.put(`attractions/${values.id}`, values)
        .then((res) => {
          toast.success("Atração atualizada com sucesso!");
          getList();
          closeModal();
        })
        .catch((err) => {
          toast.error(err.message);
        })
        .finally(() => {
          setLoad(false);
        });
    Api.post(`attractions`, values)
      .then((res) => {
        toast.success("Atração criada com sucesso!");
        getList();
        closeModal();
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setLoad(false);
      });
  };

  return (
    <>
      <Modal show={open} onHide={load ? null : closeModal} size={"md"}>
        <PreviewModal
          open={openPreviewModal}
          setOpen={setOpenPreviewModal}
          values={values}
        />
        <Modal.Body className="d-flex flex-column gap-1">
          <h5>{`${values.id ? "Editar" : "Adicionar"} Atração`}</h5>
          <div className="d-flex flex-column">
            <InputGroup>
              <InputGroup.Text>Nome da Atração</InputGroup.Text>
              <Form.Control
                value={values.title}
                name={"title"}
                onChange={handleChange}
                autoFocus
                maxLength={20}
              />
            </InputGroup>
            <span className="ml-auto">{`${
              values?.title?.length || 0
            }/20 caracteres`}</span>
          </div>
          <div className="d-flex flex-column">
            <InputGroup>
              <InputGroup.Text className="rounded-box">
                Descrição
              </InputGroup.Text>
              <Form.Control
                value={values.description}
                name={"description"}
                onChange={handleChange}
                as="textarea"
                maxLength={200}
                className="rounded-box"
              />
            </InputGroup>
            <span className="ml-auto">{`${
              values?.description?.length || 0
            }/200 caracteres`}</span>
          </div>
          <InputGroup>
            <InputGroup.Text>Mostrar no site</InputGroup.Text>
            <div className="form-control">
              <Form.Check
                type="checkbox"
                label="Ativo"
                checked={values.display}
                name="display"
                onChange={handleSwitchChange}
              />
            </div>
          </InputGroup>
          <div className="d-flex flex-column">
            <InputGroup className="flex-column align-items-center justify-content-center">
              <InputGroup.Text className="rounded-0 rounded-top w-100 justify-content-center">
                Imagem
              </InputGroup.Text>
              <div
                className={
                  values.image
                    ? "border border-primary rounded-0 rounded-bottom m-0 w-100 h-100 pt-1 overflow-hidden"
                    : "border border-primary rounded-0 rounded-bottom m-0 w-100 h-100 overflow-hidden"
                }
              >
                <ImageUpload
                  value={values.image}
                  onChange={handleChange}
                  name="image"
                  folderName={"attractions"}
                  aspectRatio={1 / 2}
                  resizeOptions={{ maxHeight: 1000 }}
                />
              </div>
            </InputGroup>
            <span className="ml-auto text-right">{`Dimensões ideais: 500px/1000px`}</span>
            <span className="ml-auto text-right">{`Tamanho ideal: 200Kb`}</span>
            <span className="ml-auto text-right">{`Imagens com dimensões e tamanho maiores serão reduzidas até o tamanho ideal`}</span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={load} variant="light" onClick={closeModal}>
            Cancelar
          </Button>
          {values.id && (
            <Button disabled={load} variant="danger" onClick={remove}>
              Remover
            </Button>
          )}
          <Button
            disabled={load}
            variant="outline-primary"
            onClick={() => {
              setOpenPreviewModal(true);
            }}
          >
            Prévia
          </Button>
          <Button disabled={load} variant="primary" onClick={save}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditModal;
