import React, { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import Calendar2 from "../pages/Calendar2";
import Home2 from "../pages/Home2";
import TicketsSales2 from "../pages/TicketsSales2";
import LoginPage from "../pages/Login";
import Users from "../pages/Users";
import UserTransactions from "../pages/UserTransactions";
import NewPassword from "../pages/newPassword";
import CalendarManagement2 from "../pages/CalendarManagement2";
import CsvPage from "../pages/CsvPage2";
import { Simulator } from "../pages/Simulator";
import PermissionsGroups from "../pages/PermissionsGroups";
import { AuthContext } from "../context/AuthContext";
import CouponsManagement from "../pages/CouponsManagement";
import Departments from "../pages/Departments";
import NewSale2 from "../pages/NewSale2";
import Partners from "../pages/Partners";
import ParkAreas from "../pages/ParkAreas";
import Doubts from "../pages/Doubts";
import PartiesTransactions from "../pages/PartiesPayments2";
import PointsPrograms from "../pages/PointsPrograms";
import Tickets from "../pages/Tickets";
import Albums from "../pages/Albums";
import Promotions from "../pages/Promotions";
import NewPartySale from "../pages/NewPartySale";
import ManagerParty2 from "../pages/ManagerParty2";
import UsageReport from "../pages/UsageReport";
import Bundles from "../pages/Bundles";
import { Buffets } from "../pages/Buffets";

export default function AllRoutes() {
  const { allowedModules } = useContext(AuthContext);

  return (
    <Routes>
      {allowedModules.includes("Consolidado de Vendas") && (
        <Route path="/" element={<Home2 />} />
      )}
      {allowedModules.includes("Gerenciador de Leads") && (
        <Route path="/gerenciar-leads" element={<Simulator />} />
      )}
      {allowedModules.includes("Consolidado de Uso") && (
        <Route path="/vendas" element={<UsageReport />} />
      )}
      {allowedModules.includes("Relatório de Vendas") && (
        <Route path="/csv-page" element={<CsvPage />} />
      )}
      {allowedModules.includes("Gerenciar Calendário") && (
        <Route path="/calendar-managerment" element={<CalendarManagement2 />} />
      )}
      {allowedModules.includes("Usuários") && (
        <Route path="/users" element={<Users />} />
      )}
      {allowedModules.includes("Grupos de Permissões") && (
        <Route path="/grupos-permissoes" element={<PermissionsGroups />} />
      )}
      {allowedModules.includes("Departamentos") && (
        <Route path="/departamentos" element={<Departments />} />
      )}
      {allowedModules.includes("Usuários") && (
        <Route path="/users/:id" element={<UserTransactions />} />
      )}
      {allowedModules.includes("Pacotes de Festas") && (
        <Route path="/pacotes-festas" element={<Bundles />} />
      )}
      {allowedModules.includes("Nova Festa") && (
        <Route path="/festas" element={<NewPartySale />} />
      )}
      {allowedModules.includes("Gerenciador de Festas") && (
        <Route path="/gerenciar-festa" element={<ManagerParty2 />} />
      )}
      {allowedModules.includes("Pagamentos de Festas") && (
        <Route path="/pagamentos-festas" element={<PartiesTransactions />} />
      )}
      {allowedModules.includes("Calendário") && (
        <Route path="/calendar" element={<Calendar2 />} />
      )}
      {allowedModules.includes("Ingressos Vendidos") && (
        <Route path="/ingressos-vendidos" element={<TicketsSales2 />} />
      )}
      {allowedModules.includes("Nova Venda") && (
        <Route path="/nova-venda" element={<NewSale2 />} />
      )}
      {allowedModules.includes("Gerenciar Cupons") && (
        <Route path="/gerenciar-cupons" element={<CouponsManagement />} />
      )}
      {allowedModules.includes("Parceiros") && (
        <Route path="/parceiros" element={<Partners />} />
      )}
      {allowedModules.includes("Áreas do Parque") && (
        <Route path="/areas-parque" element={<ParkAreas />} />
      )}
      {allowedModules.includes("Dúvidas") && (
        <Route path="/duvidas" element={<Doubts />} />
      )}
      {allowedModules.includes("Programas de Pontos") && (
        <Route path="/programas-pontos" element={<PointsPrograms />} />
      )}
      {allowedModules.includes("Tickets") && (
        <Route path="/tickets" element={<Tickets />} />
      )}
      {allowedModules.includes("Álbuns") && (
        <Route path="/albuns" element={<Albums />} />
      )}
      {allowedModules.includes("Promoções") && (
        <Route path="/promocoes" element={<Promotions />} />
      )}
      {allowedModules.includes("Gerenciar Buffets") && (
        <Route path="/gerenciar-buffets" element={<Buffets />} />
      )}
      {!allowedModules[0] && (
        <>
          <Route path="/" element={<Home2 />} />
          <Route path="/gerenciar-leads" element={<Simulator />} />
          <Route path="/vendas" element={<UsageReport />} />
          <Route path="/csv-page" element={<CsvPage />} />
          <Route
            path="/calendar-managerment"
            element={<CalendarManagement2 />}
          />
          <Route path="/users" element={<Users />} />
          <Route path="/grupos-permissoes" element={<PermissionsGroups />} />
          <Route path="/gerenciar-buffets" element={<Buffets />} />
          <Route path="/departamentos" element={<Departments />} />
          <Route path="/users/:id" element={<UserTransactions />} />
          <Route path="/festas" element={<NewPartySale />} />
          <Route path="/gerenciar-festa" element={<ManagerParty2 />} />
          <Route path="/pagamentos-festas" element={<PartiesTransactions />} />
          <Route path="/calendar" element={<Calendar2 />} />
          <Route path="/ingressos-vendidos" element={<TicketsSales2 />} />
          <Route path="/nova-venda" element={<NewSale2 />} />
          <Route path="/gerenciar-cupons" element={<CouponsManagement />} />
          <Route path="/parceiros" element={<Partners />} />
          <Route path="/areas-parque" element={<ParkAreas />} />
          <Route path="/duvidas" element={<Doubts />} />
          <Route path="/programas-pontos" element={<PointsPrograms />} />
          <Route path="/tickets" element={<Tickets />} />
          <Route path="/albuns" element={<Albums />} />
          <Route path="/promocoes" element={<Promotions />} />
          <Route path="/pacotes-festas" element={<Bundles />} />
        </>
      )}
      <Route path="/new-password/:id" element={<NewPassword />} />
      <Route path="/login" element={<LoginPage />} />
    </Routes>
  );
}
