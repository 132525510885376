import styled from "styled-components";

const Container = styled.div`
  margin: 0px;
  background-repeat: no-repeat;
  min-height: 650px;
  ${({ mobile }) =>
    mobile ? "height: calc(100vh - 97px);" : "height: calc(100vh - 97px);"}
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: rgba(111, 59, 140, 0.6);
  position: relative;
  z-index: 3;
`;

const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  ${({ mobile }) => (mobile ? "justify-content: flex-end;" : "")}
`;

const TitleWrapper = styled.h1`
  color: white;
  font-weight: 700;
  margin-top: 0px;
  text-align: center;
  max-width: 800px;
  font-size: 48px;

  ${({ mobile }) =>
    mobile
      ? `
    font-size: 24px;
    max-width: 300px;
  `
      : "line-height: 75px;"}
`;
export { Container, CenterContainer, TitleWrapper };
