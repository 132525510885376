import { useContext, useEffect, useState } from "react";
import moment from "moment";
import Api from "../../../api/api";
import { FilialContext } from "../../../context/FilialContext";
import { toast } from "react-toastify";
import MonthlyInsightChart from "./MonthlyInsightChart";
import { ProgressBar } from "react-bootstrap";

const TransactionTotalPerMonth = () => {
  const { selectedUnitId } = useContext(FilialContext);
  const [transactionTotalPerMonthFilter, setTransactionTotalPerMonthFilter] =
    useState({
      authorized_at_start_date: moment()
        .subtract(6, "months")
        .startOf("month")
        .format("YYYY-MM-DD"),
      authorized_at_end_date: moment().format("YYYY-MM-DD"),
    });
  const [loadingTransactionTotalPerMonth, setLoadingTransactionTotalPerMonth] =
    useState(false);
  const [transactionTotalPerMonth, setTransactionTotalPerMonth] = useState([]);
  const [
    transactionTotalPerMonthTotalizer,
    setTransactionTotalPerMonthTotalizer,
  ] = useState(0);

  const getTransactionTotalPerMonth = () => {
    setLoadingTransactionTotalPerMonth(true);
    Api.get(
      `transactions/value_per_month?${new URLSearchParams({
        ...transactionTotalPerMonthFilter,
        unit_id: selectedUnitId,
        ticket_type: "parties",
      })}`
    )
      .then((res) => {
        setTransactionTotalPerMonth(res.data.list);
        setTransactionTotalPerMonthTotalizer(res.data.total);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => setLoadingTransactionTotalPerMonth(false));
  };

  useEffect(() => {
    if (selectedUnitId) getTransactionTotalPerMonth();
  }, [transactionTotalPerMonthFilter, selectedUnitId]);

  const handleMonthlyFilterChange = (e) => {
    setTransactionTotalPerMonthFilter((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <>
      <h3>Totalizadores Mensais</h3>
      <div className="d-flex gap-1 mb-1 flex-wrap align-items-center justify-content-center align-items-sm-start justify-content-sm-start">
        <div className="input-group w-auto flex-0">
          <span className="input-group-text">Início</span>
          <input
            value={
              transactionTotalPerMonthFilter.authorized_at_start_date
                ? moment(
                    transactionTotalPerMonthFilter.authorized_at_start_date
                  ).format("YYYY-MM-DD")
                : null
            }
            onChange={handleMonthlyFilterChange}
            name="authorized_at_start_date"
            type="date"
            className="form-control"
            placeholder="Data inicial"
          />
        </div>
        <div className="input-group w-auto flex-0">
          <span className="input-group-text">Fim</span>
          <input
            value={
              transactionTotalPerMonthFilter.authorized_at_end_date
                ? moment(
                    transactionTotalPerMonthFilter.authorized_at_end_date
                  ).format("YYYY-MM-DD")
                : null
            }
            onChange={handleMonthlyFilterChange}
            name="authorized_at_end_date"
            type="date"
            className="form-control"
            placeholder="Data final"
          />
        </div>
      </div>
      {loadingTransactionTotalPerMonth && (
        <ProgressBar animated now={100} className="mb-1" />
      )}
      <MonthlyInsightChart data={transactionTotalPerMonth} />
      <h4>{`Total: ${(transactionTotalPerMonthTotalizer || 0).toLocaleString(
        "pt-br",
        {
          style: "currency",
          currency: "BRL",
        }
      )}`}</h4>
    </>
  );
};

export default TransactionTotalPerMonth;
