import { ProgressBar } from "react-bootstrap";
import Table from "../../../components/Table";
import moment from "moment";
import { BiSearchAlt } from "react-icons/bi";
import { useState } from "react";
import renamePaymentType from "../../../utils/paymentType";
import { maskBr } from "js-brasil";
import {
  cleanNumberString,
  getTransactionStatus,
} from "../../../utils/functions";

const List = ({ list, total, load, filter, setFilter }) => {
  const [search, setSearch] = useState("");

  const columns = [
    { field: "id", label: "ID" },
    { field: "name", label: "Nome", lastName: "lastName" },
    { field: "code", label: "ID da Festa" },
    { field: "created_at", label: "Criado em" },
    { field: "payment_method", label: "Forma de Pagamento" },
    { field: "payment_status", label: "status" },
    { field: "authorized_at", label: "Data de Pagamento" },
    { field: "value", label: "Valor" },
    { field: "email", label: "E-mail" },
    { field: "cpf", label: "CPF/CNPJ" },
    { field: "responsible_mail", label: "E-mail do Responsável" },
  ];

  const renderRow = (field, item) => {
    let rowValue = null;

    switch (field) {
      case "code":
        rowValue = item.code.split("-")[0];
        break;
      case "created_at":
        rowValue = moment(item.created_at).format("DD/MM/YYYY HH:mm:SS");
        break;
      case "authorized_at":
        rowValue = item.authorized_at
          ? moment(item.authorized_at).format("DD/MM/YYYY HH:mm:SS")
          : "-";
        break;
      case "payment_method":
        rowValue = renamePaymentType(item.payment_method);
        break;
      case "payment_status":
        rowValue = getTransactionStatus(item.payment_status);
        break;
      case "value":
        rowValue = Number(item.value || 0).toLocaleString("pt-BR", {
          currency: "BRL",
          style: "currency",
        });
        break;
      case "cpf":
        rowValue =
          cleanNumberString(item.cpf)?.length > 11
            ? maskBr.cnpj(item.cpf)
            : maskBr.cpf(item.cpf);
        break;
      case "name":
        rowValue = item.name + " " + item.lastName; // Concatena name e lastName
        break;
      default:
        rowValue = item[field];
        break;
    }

    return rowValue;
  };

  const handleSearch = () => {
    setFilter((prev) => ({ ...prev, search }));
  };

  const onSearchKeyDown = (e, x) => {
    const enter = 13;
    if (e.keyCode === enter) handleSearch();
  };

  const handleFilterChange = (e) => {
    setFilter((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  return (
    <>
      <div className="d-flex gap-1 mb-1 flex-wrap align-items-center justify-content-center align-items-sm-start justify-content-sm-start w-100">
        <h5>Lista de Pagamentos de Festas:</h5>
        <div className="input-group w-auto flex-0">
          <input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="form-control"
            placeholder="Pesquisar (ID, nome, e-mail, cpf, responsável)"
            onKeyDown={onSearchKeyDown}
          />
          <span
            className="input-group-text cursor-pointer"
            onClick={handleSearch}
          >
            <BiSearchAlt />
          </span>
        </div>
      </div>
      <div className="d-flex gap-1 mb-1 flex-wrap align-items-center justify-content-center justify-content-sm-start">
        <span className="fs-5">Filtrar data de pagamento:</span>
        <div className="input-group w-auto flex-0">
          <span className="input-group-text">Início</span>
          <input
            value={
              filter.authorized_at_start_date
                ? moment(filter.authorized_at_start_date).format("YYYY-MM-DD")
                : null
            }
            onChange={handleFilterChange}
            name="authorized_at_start_date"
            type="date"
            className="form-control"
            placeholder="Data inicial"
          />
        </div>
        <div className="input-group w-auto flex-0">
          <span className="input-group-text">Fim</span>
          <input
            value={
              filter.authorized_at_end_date
                ? moment(filter.authorized_at_end_date).format("YYYY-MM-DD")
                : null
            }
            onChange={handleFilterChange}
            name="authorized_at_end_date"
            type="date"
            className="form-control"
            placeholder="Data final"
          />
        </div>
      </div>
      {load && <ProgressBar animated now={100} className="mb-1" />}
      <Table
        data={list}
        columns={columns}
        renderRow={renderRow}
        renderRowItem
        page={filter.page}
        limit={filter.limit}
        total={total}
        paginated
        handlePageChange={(page) => setFilter({ ...filter, page })}
        handleLimitChange={(limit) => setFilter({ ...filter, limit })}
        limitOptions={[10, 20, 30]}
        emptyListLabel={"Não há pagamentos lançados no período."}
      />
    </>
  );
};

export default List;
