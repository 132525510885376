import { toast } from "react-toastify";
import Api from "../../../../api/api";
import EditSaleProductsModal from "../../../TicketsSales2/EditSaleModal/EditSaleProductsModal";
import { HiTrash } from "react-icons/hi2";
const { useState } = require("react");
const { Button } = require("react-bootstrap");

const SaleProducts = ({ sale, onSuccess, participants }) => {
  const [openEditSaleProductsModal, setOpenEditSaleProductsModal] =
    useState(false);
  const [loadingRemoveProduct, setLoadingRemoveProduct] = useState(false);
  participants = Number(participants || 0);

  const renderSaleProduct = (product) => {
    if (!product || !product.id) return <></>;

    const handleRemove = () => {
      if (window.confirm("Deseja realmente remover este produto da venda?")) {
        setLoadingRemoveProduct(true);
        Api.delete(`/orders/${sale.id}/remove_product/${product.id}`)
          .then((res) => {
            if (res.data.message) toast.success(res.data.message);
            if (onSuccess) onSuccess();
          })
          .catch((err) => toast.error(err.message))
          .finally(() => setLoadingRemoveProduct(false));
      }
    };

    return (
      <div className="border border-primary rounded p-1 px-2 d-flex align-items-center justify-content-between">
        <span className="fw-bold">{`${product.name} x${
          product.charge_type === "participants"
            ? Number(sale?.total_players || 0) +
              Number(sale?.total_not_players || 0)
            : product.amount
        }`}</span>
        <div className="d-flex align-items-center gap-3">
          <span className="fw-bold">
            {product.total_value.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })}
          </span>
          {!product.gateway_transaction && !product.coupon_id && (
            <Button
              variant="danger"
              size="sm"
              className="rounded-circle"
              onClick={handleRemove}
              disabled={loadingRemoveProduct}
            >
              <HiTrash />
            </Button>
          )}
        </div>
      </div>
    );
  };

  const renderSaleProducts = () => {
    if (!sale || !sale.products || !sale.products[0])
      return <span className="text-muted">Nenhum produto na venda.</span>;

    return (
      <div className="d-flex flex-column gap-1">
        {sale.products.map((product) => renderSaleProduct(product))}
      </div>
    );
  };

  const handleOpenEditSaleProductsModal = () => {
    setOpenEditSaleProductsModal(true);
  };

  const onProductsEditted = () => {
    setOpenEditSaleProductsModal(false);
    if (onSuccess) onSuccess();
  };

  return (
    <>
      {openEditSaleProductsModal && (
        <EditSaleProductsModal
          open={openEditSaleProductsModal}
          setOpen={setOpenEditSaleProductsModal}
          unit_id={sale?.unit_id}
          onSuccess={onProductsEditted}
          order_id={sale?.id}
          participants={participants}
        />
      )}
      <span className="fs-5 text-primary fw-bold">Produtos</span>
      {renderSaleProducts()}
      <Button
        className="fw-bold"
        variant={"outline-primary"}
        onClick={handleOpenEditSaleProductsModal}
      >
        Adicionar produtos à venda
      </Button>
    </>
  );
};

export default SaleProducts;
