import React, { useState } from "react";
import { BiSearchAlt } from "react-icons/bi";
import Api from "../../../api/api";
import { toast } from "react-toastify";
import { Button, ProgressBar } from "react-bootstrap";
import UserDetails from "./UserDetails";
import { useEffect } from "react";
import UserList from "./UserList";
import NewUserModal from "./NewUserModal";
import validator from "validator";
import { CreateUser } from "../../Users/createUser";

const FindUser = ({
  onUserAccepted,
  hideSellWithoutUser,
  openModal,
  setOpenModal,
  setShowButton,
}) => {
  const [filter, setFilter] = useState({ search: "", limit: 5, page: 0 });
  const [load, setLoad] = useState(false);
  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState([]);
  const [user, setUser] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [userAccepted, setUserAccepted] = useState(false);
  const [userDataModal, setUserDataModal] = useState();

  const handleSearchChange = (e) => {
    setFilter((prev) => ({ ...prev, search: e.target.value }));
  };

  const handleEmailKeyDown = (e) => {
    if (e.keyCode === 13) getUsers();
  };

  const getUsers = () => {
    if (!filter.search) {
      setTotal(0);
      return setUsers([]);
    }
    if (filter.search.length < 3)
      return toast.warning("A pesquisa precisa ter no mínimo 3 caracteres!");

    setLoad(true);
    Api.get(`users`, { params: filter })
      .then((res) => {
        setUsers(res.data.data);
        setTotal(res.data.total);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setLoad(false);
      });
  };

  const handleRejectUser = () => {
    setUser(null);
  };

  const handleAcceptUser = () => {
    if (!user.cpf || userInfo.zipcode) {
      if (!user.cpf && !userInfo.cpf)
        return toast.warning("O usuário precisa do documento para continuar!");
      if (
        [true, "true"].includes(userInfo.brazilian_doc) &&
        !validator.isTaxID(userInfo.cpf || "", "pt-BR")
      )
        return toast.warning(
          "O usuário precisa de um documento válido para continuar!"
        );
      if (
        userInfo.zipcode &&
        !validator.isPostalCode(userInfo.zipcode || "", "BR")
      )
        return toast.warning(
          "O usuário precisa de um CEP válido para continuar!"
        );

      return Api.post(`users_info/create_or_update`, {
        ...userInfo,
        id_users: user.id,
      })
        .then((res) => {
          setUser((prev) => ({
            ...prev,
            ...userInfo,
            users_info_id: res.data.data.id,
          }));
          setUserAccepted(true);
        })
        .catch((err) => {
          toast.error(err.message);
        })
        .finally(() => {});
    }

    setUserAccepted(true);
  };

  useEffect(() => {
    setShowButton(!user);
    if (!user) return;
    const userData = { ...user };
    if (userAccepted) {
      onUserAccepted(userData);
      setUser(null);
      setFilter({ search: "", page: 0, limit: 10 });
    }
  }, [userAccepted, onUserAccepted, setUser, setFilter, user]);

  const handleSelectUser = (selected) => {
    setUser(selected);
    setUserDataModal(selected);
    setUserInfo({ id: selected.users_info_id });
  };

  const handlePageChange = (page) => setFilter((prev) => ({ ...prev, page }));

  const handleLimitChange = (limit) =>
    setFilter((prev) => ({ ...prev, limit }));

  useEffect(() => {
    if (filter.search) getUsers();
  }, [filter.limit, filter.page]);

  const handleUserInfoChange = (e) => {
    setUserInfo((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleModal = () => {
    setOpenModal(!openModal);
  };

  const handleUserCreated = (newUser) => {
    handleSelectUser({ ...newUser });
  };

  const handleEditUser = () => {
    handleModal();
    setUserDataModal({ ...user });
  };

  const handleSellWithoutUser = () => {
    handleSelectUser({});
    setUserAccepted(true);
  };

  useEffect(() => {
    if (!openModal) setUserDataModal();
  }, [openModal]);

  return (
    <div className="d-flex flex-column gap-2">
      {!user && (
        <div className="d-flex flex-column gap-1">
          <div className="input-group">
            <input
              className="form-control"
              placeholder="Pesquisar cliente (nome, e-mail, cpf)"
              value={filter.search}
              onChange={handleSearchChange}
              autoFocus
              type="email"
              onKeyDown={handleEmailKeyDown}
            />
            <span
              className="input-group-text cursor-pointer"
              onClick={getUsers}
            >
              <BiSearchAlt />
            </span>
          </div>
          {!hideSellWithoutUser && (
            <Button
              onClick={handleSellWithoutUser}
              variant="outline-primary"
              className="align-self-end"
            >
              Lançar venda sem cliente
            </Button>
          )}
        </div>
      )}
      <div className="load-fixed">
        {load && <ProgressBar animated now={100} />}
      </div>
      {!user && users[0] && (
        <UserList
          users={users}
          handleSelect={handleSelectUser}
          filter={filter}
          total={total}
          handlePageChange={handlePageChange}
          handleLimitChange={handleLimitChange}
        />
      )}
      <CreateUser
        user={user}
        open={openModal}
        userData={userDataModal}
        handleClose={handleModal}
        handleUserCreated={handleUserCreated}
        modalTitle={userDataModal ? "Editar Cliente" : "Novo Cliente"}
      />
      {user && (
        <UserDetails
          user={user}
          handleAccept={handleAcceptUser}
          handleReject={handleRejectUser}
          userInfo={userInfo}
          handleUserInfoChange={handleUserInfoChange}
          handleUserCreated={handleUserCreated}
          handleEdit={handleEditUser}
        />
      )}
    </div>
  );
};

export default FindUser;
