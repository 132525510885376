import React, { useEffect, useState, useContext } from "react";
import PageTitle from "../../components/PageTitle";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import Api from "../../api/api";
import List from "./List";
import EditModal from "./EditModal";
import { FilialContext } from "../../context/FilialContext";
import WebsiteContent from "./WebsiteContent";

const ParkAreas = () => {
  const { selectedUnitId } = useContext(FilialContext);
  const [selectedItem, setSelectedItem] = useState(null);
  const [filter, setFilter] = useState({
    limit: 10,
    page: 0,
    unit_id: selectedUnitId,
  });
  const [list, setList] = useState([]);
  const [loadList, setLoadList] = useState(false);
  const [total, setTotal] = useState(0);

  if (typeof window !== "undefined") {
    injectStyle();
  }

  const getList = () => {
    setLoadList(true);
    Api.get(`park_areas?${new URLSearchParams(filter)}`)
      .then((res) => {
        setLoadList(false);
        setList(res.data.list);
        setTotal(res.data.total);
      })
      .catch((err) => {
        setLoadList(false);
        toast.error(err.message);
      });
  };

  useEffect(() => {
    filter.unit_id = selectedUnitId;
    if (selectedUnitId) getList();
  }, [filter, selectedUnitId]);

  useEffect(() => {
    document.getElementById("topbar")?.scrollIntoView();
  }, []);

  return (
    <>
      <PageTitle>
        <h2 className="font-weight-bold">Áreas do Parque</h2>
      </PageTitle>
      {selectedItem && (
        <EditModal
          open={selectedItem ? true : false}
          setOpen={setSelectedItem}
          item={selectedItem}
          getList={getList}
        />
      )}
      <Container fluid>
        <Row>
          <Col xs={12} md={10} xl={7} xxl={6}>
            <List
              list={list}
              total={total}
              load={loadList}
              setSelectedItem={setSelectedItem}
              filter={filter}
              setFilter={setFilter}
            />
          </Col>
        </Row>
      </Container>
      <WebsiteContent />
    </>
  );
};

export default ParkAreas;
