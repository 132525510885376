import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const SimulatorMetricsTotalizerChart = ({ data }) => {
  const labels = [
    "Iniciaram",
    "Terminaram",
    "Enviaram para agendamento",
    "Querem tirar dúvidas",
    "Não terminaram",
    "Oportunidade perdida",
  ];

  const simulations = data
    .map((item) => item.simulations)
    .reduce((acum, item) => (acum = acum + Number(item)), 0);
  const unfinished = data
    .map((item) => item.unfinished)
    .reduce((acum, item) => (acum = acum + Number(item)), 0);
  const finished = data
    .map((item) => item.finished)
    .reduce((acum, item) => (acum = acum + Number(item)), 0);
  const sent = data
    .map((item) => item.sent)
    .reduce((acum, item) => (acum = acum + Number(item)), 0);
  const sentDoubts = data
    .map((item) => item.sent_doubts)
    .reduce((acum, item) => (acum = acum + Number(item)), 0);
  const sentSchedule = data
    .map((item) => item.sent_schedule)
    .reduce((acum, item) => (acum = acum + Number(item)), 0);
  const finishedDidntSent = data
    .map((item) => item.finished_didnt_sent)
    .reduce((acum, item) => (acum = acum + Number(item)), 0);

  const getTooltipLabel = (item) => {
    if (item.dataIndex === 0) return `Iniciaram: ${item.formattedValue}`;
    if (item.dataIndex === 1)
      return `Terminaram: ${item.formattedValue} (${(
        (finished / simulations) *
        100
      ).toFixed(2)}%)`;
    if (item.dataIndex === 2)
      return `Enviaram para agendamento: ${item.formattedValue} (${(
        (sentSchedule / simulations) *
        100
      ).toFixed(2)}%)`;
    if (item.dataIndex === 3)
      return `Querem tirar dúvidas: ${item.formattedValue} (${(
        (sentDoubts / simulations) *
        100
      ).toFixed(2)}%)`;
    if (item.dataIndex === 4)
      return `Não terminaram: ${item.formattedValue} (${(
        (unfinished / simulations) *
        100
      ).toFixed(2)}%)`;
    if (item.dataIndex === 5)
      return `Oportunidade perdida: ${item.formattedValue} (${(
        (finishedDidntSent / simulations) *
        100
      ).toFixed(2)}%)`;
  };

  return (
    <Bar
      options={{
        type: "bar",
        plugins: {
          tooltip: {
            callbacks: { label: getTooltipLabel },
          },
          legend: { display: false },
        },
        scales: {
          yAxis: {
            type: "linear",
            ticks: {
              stepSize: 1,
            },
          },
        },
      }}
      data={{
        labels,
        datasets: [
          {
            data: [
              data
                .map((item) => item.simulations)
                .reduce((acum, item) => (acum = acum + Number(item)), 0),
              data
                .map((item) => item.finished)
                .reduce((acum, item) => (acum = acum + Number(item)), 0),
              data
                .map((item) => item.sent_schedule)
                .reduce((acum, item) => (acum = acum + Number(item)), 0),
              data
                .map((item) => item.sent_doubts)
                .reduce((acum, item) => (acum = acum + Number(item)), 0),
              data
                .map((item) => item.unfinished)
                .reduce((acum, item) => (acum = acum + Number(item)), 0),
              data
                .map((item) => item.finished_didnt_sent)
                .reduce((acum, item) => (acum = acum + Number(item)), 0),
            ],
            backgroundColor: [
              "#00f",
              "#F47D41",
              "#6F3B8C",
              "#FDC028",
              "#aaa",
              "#f00",
            ],
          },
        ],
      }}
    />
  );
};

export default SimulatorMetricsTotalizerChart;
